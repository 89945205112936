import ApplicationKeys from '@atlassian/jira-common-constants/src/application-keys';
import {
	CORE_PROJECT,
	SOFTWARE_PROJECT,
	SERVICE_DESK_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types';
import type { IssueAppConfiguration, ProjectConfig } from './types';

// Issue actions
export const FLAG_ACTIONS = 'flagActions';
export const CARD_COVER_ACTIONS = 'cardCoverActions';

export const emptyConfiguration: () => IssueAppConfiguration = () => ({
	issueActions: [],
});

export const coreConfiguration: () => IssueAppConfiguration = () => ({
	applicationKey: ApplicationKeys.CORE_ISSUE,
	issueActions: [],
	ecosystemEnabled: true,
});

export const softwareConfiguration: () => IssueAppConfiguration = () => ({
	applicationKey: ApplicationKeys.SOFTWARE_ISSUE,
	issueActions: [FLAG_ACTIONS],
	ecosystemEnabled: true,
});

export const simpleSoftwareBoardConfiguration: () => IssueAppConfiguration = () => ({
	applicationKey: ApplicationKeys.SIMPLE_SOFTWARE_BOARD,
	issueActions: [FLAG_ACTIONS, CARD_COVER_ACTIONS],
	ecosystemEnabled: true,
});

export const standardRoadmapConfiguration: () => IssueAppConfiguration = () => ({
	applicationKey: ApplicationKeys.STANDARD_ROADMAP_ISSUE,
	issueActions: [FLAG_ACTIONS],
	ecosystemEnabled: true,
});

export const bitbucketConfiguration: () => IssueAppConfiguration = () => ({
	applicationKey: ApplicationKeys.BITBUCKET_ISSUE,
	issueActions: [],
});

export const mobileConfiguration: () => IssueAppConfiguration = () => ({
	applicationKey: ApplicationKeys.MOBILE_ISSUE,
	issueActions: [],
});

export const serviceDeskConfiguration: () => IssueAppConfiguration = () => ({
	applicationKey: ApplicationKeys.SERVICE_DESK,
	issueActions: [],
	ecosystemEnabled: true,
});

export const getIssueConfiguration = (config: ProjectConfig | null) => {
	if (config) {
		switch (config.projectType) {
			case SOFTWARE_PROJECT:
				if (config.isSimplified) {
					return simpleSoftwareBoardConfiguration();
				}
				return softwareConfiguration();
			case CORE_PROJECT:
				return coreConfiguration();
			case SERVICE_DESK_PROJECT:
				return serviceDeskConfiguration();
			default:
				// fallback to sofware config
				return softwareConfiguration();
		}
	}
	return softwareConfiguration();
};
