import { insightsSettingsDefaults } from '../constants';
import type { BoardInsightsSettings, BoardInsightsSettingsResponse } from '../types';

const insightsSettingsDefaultsKeys = Object.keys(insightsSettingsDefaults);

export const withDefaults = (
	settings: // eslint-disable-next-line @typescript-eslint/no-explicit-any
	Partial<Flow.Diff<BoardInsightsSettings, Record<any, any>>> | BoardInsightsSettingsResponse,
): BoardInsightsSettings => {
	const insightKeys = [...Object.keys(settings), ...insightsSettingsDefaultsKeys];
	const uniqueKeys = [...new Set(insightKeys)];

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const merged = uniqueKeys.reduce<Record<string, any>>((result, key) => {
		/* eslint-disable no-param-reassign */
		// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'BoardInsightsSettingsResponse | Partial<Diff<BoardInsightsSettings, Record<any, any>>>'.
		result[key] = { ...insightsSettingsDefaults[key], ...settings[key] };
		/* eslint-enable no-param-reassign */
		return result;
	}, {});

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return merged as BoardInsightsSettings;
};
