import { useEffect, useState } from 'react';
import { isPageVisible } from '@atlassian/jira-common-page-visibility';

const usePageVisibility = () => {
	const [isVisible, setIsVisible] = useState(isPageVisible());

	useEffect(() => {
		const onVisibilityChange = () => setIsVisible(isPageVisible());

		document.addEventListener('visibilitychange', onVisibilityChange);

		return () => {
			document.removeEventListener('visibilitychange', onVisibilityChange);
		};
	}, []);

	return isVisible;
};

export { usePageVisibility };
