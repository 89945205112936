import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const getKnowledgeHubBaseUrl = (projectKey: string) =>
	`/jira/servicedesk/projects/${projectKey}/knowledge`;

export const knowledgeHubLandingRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.KNOWLEDGE_HUB_LANDING,
	path: '/jira/servicedesk/projects/:projectKey/knowledge',
	exact: true,
};

export const knowledgeHubBrowseAllArticlesRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.KNOWLEDGE_HUB_BROWSE_ALL_ARTICLES,
	path: '/jira/servicedesk/projects/:projectKey/knowledge/articles',
	exact: true,
};

export const knowledgeHubDraftsRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.KNOWLEDGE_HUB_DRAFTS,
	path: '/jira/servicedesk/projects/:projectKey/knowledge/drafts',
	exact: true,
};

export const knowledgeHubBrowseAllCategoriesRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.KNOWLEDGE_HUB_BROWSE_ALL_CATEGORIES,
	path: '/jira/servicedesk/projects/:projectKey/knowledge/categories',
	exact: true,
};

export const knowledgeHubSuggestedArticlesRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.KNOWLEDGE_HUB_KNOWLEDGE_GAPS,
	path: '/jira/servicedesk/projects/:projectKey/knowledge/knowledge-gaps',
	exact: true,
};

export const knowledgeHubBrowseViewArticleRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.KNOWLEDGE_HUB_BROWSE_VIEW_ARTICLE,
	path: '/jira/servicedesk/projects/:projectKey/knowledge/articles/:articleId',
};

export const knowledgeHubArticleEmbedEditorRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.KNOWLEDGE_HUB_ARTICLE_EMBED_EDITOR,
	path: '/jira/servicedesk/projects/:projectKey/knowledge/space/:spaceKey/articles/:articleId/edit',
	query: ['draftShareId?'],
};

export const knowledgeHubAllArticlesRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.KNOWLEDGE_HUB_ALL_ARTICLES,
	path: '/jira/servicedesk/projects/:projectKey/knowledge/browse/page/:pageId',
};

export const knowledgeHubArticleInCategoryRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.KNOWLEDGE_HUB_ARTICLE_IN_CATEGORY,
	path: '/jira/servicedesk/projects/:projectKey/knowledge/categories/:categoryId/articles/:articleId',
};

export const knowledgeHubCategoryPageRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.KNOWLEDGE_HUB_CATEGORY_PAGE,
	path: '/jira/servicedesk/projects/:projectKey/knowledge/categories/:categoryId/browse/page/:pageId',
};

export const knowledgeHubCategoryRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.KNOWLEDGE_HUB_CATEGORY,
	path: '/jira/servicedesk/projects/:projectKey/knowledge/categories/:categoryId',
};

export const knowledgeHubSearchRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.KNOWLEDGE_HUB_SEARCH,
	path: '/jira/servicedesk/projects/:projectKey/knowledge/search/articles/:articleId',
};

export const knowledgeHubSetupRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.KNOWLEDGE_HUB_SETUP,
	path: '/jira/servicedesk/projects/:projectKey/knowledge/setup',
};
