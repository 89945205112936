import { performGetRequest } from '@atlassian/jira-fetch';
import type { DefaultPINFeatures, FiltersResponse } from './types';

const extractNavIds = (result: FiltersResponse): Set<string> =>
	new Set(
		(result['com.atlassian.jira.jira-projects-issue-navigator:generic-filters'] || []).map(
			(filterNavItem) => filterNavItem.id,
		),
	);

export const fetchDefaultPINFiltersNavData = async (
	projectKey: string,
): Promise<DefaultPINFeatures> => {
	if (projectKey == null) {
		throw new Error('invalid project id or key');
	}
	const result: FiltersResponse = await performGetRequest(
		`/rest/filters/1.0/filters?projectKey=${projectKey}`,
	);
	const filterNavIds = extractNavIds(result);
	return {
		hasMyOpenIssues: filterNavIds.has('myopenissues'),
		hasReportedByMe: filterNavIds.has('reportedbyme'),
		hasAllIssues: filterNavIds.has('allissues'),
		hasAllOpenIssues: filterNavIds.has('allopenissues'),
		hasDoneIssues: filterNavIds.has('doneissues'),
		hasViewedRecently: filterNavIds.has('recentlyviewed'),
		hasCreatedRecently: filterNavIds.has('addedrecently'),
		hasResolvedRecently: filterNavIds.has('resolvedrecently'),
		hasUpdatedRecently: filterNavIds.has('updatedrecently'),
	};
};
