import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type BulkEmailType from '@atlassian/jira-admin-pages-bulk-email';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyBulkEmail = lazyForPaint<typeof BulkEmailType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-jira-admin-pages-bulk-email", jiraSpaEntry: "jira-admin-pages-bulk-email" */ '@atlassian/jira-admin-pages-bulk-email'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazyBulkEmail} pageId="jira-admin-pages-bulk-email" shouldShowSpinner />
);
