import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type CMDBFieldConfigAdminType from '@atlassian/jira-spa-apps-admin-pages-cmdb-field-config';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyCMDBFieldConfigAdmin = lazyForPaint<typeof CMDBFieldConfigAdminType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-admin-pages-cmdb-field-config", jiraSpaEntry: "async-admin-pages-cmdb-field-config" */ '@atlassian/jira-spa-apps-admin-pages-cmdb-field-config'
		),
	),
);

export const AsyncCmdbFieldConfigPage = () => (
	<LazyPage
		Page={LazyCMDBFieldConfigAdmin}
		pageId="admin-pages-cmdb-field-config"
		shouldShowSpinner
	/>
);
