import { NOT_FOUND } from '@atlassian/jira-common-constants/src/http-status-codes';
import { performPutRequest, performDeleteRequest, performGetRequest } from '@atlassian/jira-fetch';

const url = (key: string) => `/rest/api/3/mypreferences?key=${key}`;

const get = async <T = string,>(key: string): Promise<T | null> => {
	try {
		const result = await performGetRequest(url(key));
		return result?.value;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		if (error.statusCode === NOT_FOUND) {
			return null;
		}
		throw error;
	}
};

const set = <T = string,>(key: string, value: T): Promise<void> =>
	performPutRequest(url(key), { body: JSON.stringify({ value }) });

const remove = (key: string): Promise<void> => performDeleteRequest(url(key));

export const userPreferenceAPI = {
	get,
	set,
	remove,
};
