import React, { createContext, useContext } from 'react';

import type { MessageEventDismissPayload, MessageEventReadPayload } from './types';

type DismissCallback = (payload: MessageEventDismissPayload) => void;

type ReadCallback = (payload: MessageEventReadPayload) => void;

type Callback = ReadCallback | DismissCallback;

type Unsubscribe = () => void;

type Subscribe = (subscriber: Callback) => Unsubscribe;

type Publish = Callback;

/**
 * Represents values related to message placement.
 * @typedef {Object} MessagePlacementValues
 * @property {(callback: Callback) => void} onMessageDismiss - Callback function for dismissing a message.
 * @property {Callback} dismissMessageFromPlacement - Callback for dismissing a message from placement.
 * @property {(callback: Callback) => void} onMessageRead - Callback function for marking a message as read.
 * @property {Callback} markReadFromPlacement - Callback for marking a message as read.
 *
 */
export type MessagePlacementValues = {
	onMessageDismiss: (callback: Callback) => void;
	dismissMessageFromPlacement: Callback;
	onMessageRead: (callback: Callback) => void;
	markReadFromPlacement: Callback;
};

/**
 * Creates a Publish-Subscribe pattern for managing callbacks.
 * @returns {Array<Subscribe, Publish>} An array containing a Subscribe function and a Publish function.
 */
const createPubSub = (): [Subscribe, Publish] => {
	const subscribers: Map<Callback, Callback> = new Map();

	/**
	 * Subscribes a callback function to the pub-sub system.
	 * @param {Callback} subscriber - The callback function to subscribe.
	 * @returns A function to unsubscribe the callback.
	 */
	const subscribe: Subscribe = (subscriber) => {
		subscribers.set(subscriber, subscriber);

		return () => {
			subscribers.delete(subscriber);
		};
	};

	/**
	 * Publishes the payload to all subscribed callbacks.
	 * @param {Callback} payload - The payload to publish to the subscribers.
	 */
	const publish: Publish = (payload) => {
		subscribers.forEach((cb) => cb(payload));
	};

	return [subscribe, publish];
};

/**
 * Creates the message placement values including callbacks for message dismiss and read.
 * @returns {MessagePlacementValues} The message placement values with callback functions.
 */
export const createMessagePlacementValues = (): MessagePlacementValues => {
	const [onMessageDismiss, dismissMessageFromPlacement] = createPubSub();
	const [onMessageRead, markReadFromPlacement] = createPubSub();

	return {
		/**
		 * Callback function for handling message dismiss.
		 * @param {Callback} callback - The callback function to be invoked on message dismiss.
		 */
		onMessageDismiss,

		/**
		 * Callback function to dismiss a message from placement.
		 * @type {Callback}
		 */
		dismissMessageFromPlacement,

		/**
		 * Callback function for handling message read.
		 * @param {Callback} callback - The callback function to be invoked on message read.
		 */
		onMessageRead,

		/**
		 * Callback function to mark a message as read from placement.
		 * @type {Callback}
		 */
		markReadFromPlacement,
	};
};

/**
 * Context for message placement with callbacks for message dismiss and read.
 */
export const MessagePlacement = createContext<{
	onMessageDismiss: (callback: DismissCallback) => void;
	dismissMessageFromPlacement: DismissCallback;
	onMessageRead: (callback: ReadCallback) => void;
	markReadFromPlacement: Callback;
}>(createMessagePlacementValues());

/**
 * Provider component for the message placement context.
 */
export const MessagePlacementProvider = MessagePlacement.Provider;

/**
 * Creates a MessagePlacementProvider component with the provided value.
 * @returns A functional component that wraps child components to pass down context.
 */
export const createMessagePlacementProvider =
	() =>
	({ children }: { children: React.ReactNode }) => (
		<MessagePlacementProvider value={createMessagePlacementValues()}>
			{children}
		</MessagePlacementProvider>
	);

/**
 * Custom hook that returns the context value for message placement.
 * @returns {MessagePlacementValues} -  context for message placement
 */
export const useMessagePlacementHooks = () => useContext(MessagePlacement);
