import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ServicedeskQueuesCreateViewType from '@atlassian/jira-spa-apps-servicedesk-queues-create-view';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyServicedeskQueuesCreateView = lazyForPaint<typeof ServicedeskQueuesCreateViewType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-servicedesk-queues-create-view", jiraSpaEntry: "async-servicedesk-queues-create-view" */ '@atlassian/jira-spa-apps-servicedesk-queues-create-view'
			),
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyServicedeskQueuesCreateView}
		pageId="spa-apps/servicedesk/queues/create"
		shouldShowSpinner
	/>
);
