import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarGlobalSettingsSystem from '@atlassian/jira-navigation-apps-sidebar-global-settings-system/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { externalImportRoute } from '@atlassian/jira-router-routes-admin-pages-external-import-routes';
import ExternalImport from './ui/async';

export const externalImportRouteEntries: Route[] = [
	createEntry(externalImportRoute, {
		component: ExternalImport,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsSystem,
		},

		resources: [...getNavigationResources(), navigationSidebarGlobalResource],

		forPaint: [LazyAtlassianNavigation],
	}),
];
