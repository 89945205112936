// eslint-disable-next-line jira/restricted/@atlassian/jira-router
import { createResource } from '@atlassian/jira-router';
import { getBoardId } from '@atlassian/jira-router-resources-utils';
import {
	fetchJSWReports,
	type JiraSoftwareReport,
} from '../../../services/sidebar/jsw-reports-sidebar-navigation';

export const staticSidebarJSWReportsResource = createResource<JiraSoftwareReport[] | null>({
	type: 'JIRA_SOFTWARE_REPORTS',
	getKey: ({ match, query }) => {
		const boardId = getBoardId(match, query);
		return boardId ? `board-${boardId}` : 'none';
	},
	getData: async ({ match, query }) => {
		const boardId = getBoardId(match, query);
		if (boardId == null) {
			return null;
		}
		const response = await fetchJSWReports(boardId);
		return response?.reports;
	},
	maxAge: 5 * 60 * 1000,
});
