import { fg } from '@atlassian/jira-feature-gating';
import { getIsNav4UserOptOut } from '../../get-meta-boolean';
import { getIsRouteExcludedForDogfoodingPhase1 } from './get-is-route-excluded-for-dogfooding-phase-1';

// All dogfooding feature gates will remain in dogfooding and not advance.
// They will allow the dogfooding audience to continue to have nav4 while other cohorts are enabled or disabled.
// We don't inline usage of feature flags/gates as our logic to show or no show will become complex at times and should not be duplicated.
// See https://hello.atlassian.net/wiki/spaces/JNR/pages/3699574590/Rollout+technical+design+draft#Decision-points
export const getWillShowNav4ForDogfooding = () => {
	if (!fg('jira_nav4_dogfooding')) return false;

	const phase1 = fg('jira_nav4_dogfooding_phase-1');
	const phase2 = fg('jira_nav4_dogfooding_phase-2');
	const phase3 = !(phase1 || phase2);

	return (
		phase3 ||
		(phase2 && !getIsNav4UserOptOut()) ||
		(phase1 && !getIsNav4UserOptOut() && !getIsRouteExcludedForDogfoodingPhase1())
	);
};
