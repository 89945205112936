import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ProductsJsmIncidentManagementToolsType from '@atlassian/jira-spa-apps-products-jsm-incident-management-tools';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyProductsJsmIncidentManagementTools = lazyForPaint<
	typeof ProductsJsmIncidentManagementToolsType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-products-jsm-incident-management-tools", jiraSpaEntry: "async-products-jsm-incident-management-tools" */ '@atlassian/jira-spa-apps-products-jsm-incident-management-tools'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyProductsJsmIncidentManagementTools}
		pageId="products-jsm-incident-management-tools"
		shouldShowSpinner={false}
	/>
);
