import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { performPutRequest } from '@atlassian/jira-fetch';

export type UserPropertiesJsonResponse<T> = {
	key: string;
	value: T;
};

const getUserUrl = (accountId: string, propertyKey: string) =>
	`/rest/api/3/user/properties/${propertyKey}?accountId=${accountId}`;

export const getUserProperty = async <T,>({
	accountId,
	propertyKey,
	defaultValue,
}: {
	accountId: string;
	propertyKey: string;
	defaultValue: T;
}): Promise<UserPropertiesJsonResponse<T>> => {
	const url = getUserUrl(accountId, propertyKey);
	try {
		const response = await fetch(url, {
			headers: {
				'Content-Type': 'application/json',
			},
		});
		// The API deliberately returns 404 if user property is not found - we will return default value
		if (response.status === 404) {
			return { key: propertyKey, value: defaultValue };
		}
		if (response.ok) {
			return await response.json();
		}
		throw new Error(`Fetching ${propertyKey} user property failed.`);

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			error,
			meta: {
				id: 'getUserProperty',
				packageName: 'jiraAdminPagesPerformance',
				teamName: 'cherenkov',
			},
			sendToPrivacyUnsafeSplunk: true,
		});
		return { key: propertyKey, value: defaultValue };
	}
};

export const setUserProperty = async <T,>({
	accountId,
	propertyKey,
	propertyValue,
}: {
	accountId: string;
	propertyKey: string;
	propertyValue: T;
}): Promise<void> => {
	const url = getUserUrl(accountId, propertyKey);

	try {
		await performPutRequest(url, {
			body: JSON.stringify(propertyValue),
		});
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			error,
			meta: {
				id: 'setUserProperty',
				packageName: 'jiraAdminPagesPerformance',
				teamName: 'cherenkov',
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}
};
