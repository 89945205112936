import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { HorizontalOperationsNav, OverviewNav } from './ui';

export const LazyHorizontalOperationsNav = lazyForPaint<typeof HorizontalOperationsNav>(() =>
	import(/* webpackChunkName: "jira-operations-horizontal-nav" */ './ui').then(
		({ HorizontalOperationsNav }) => HorizontalOperationsNav,
	),
);

export const LazyHorizontalOperationsOverviewNav = lazyForPaint<typeof OverviewNav>(() =>
	import(/* webpackChunkName: "jira-operations-horizontal-overview-nav" */ './ui').then(
		({ OverviewNav }) => OverviewNav,
	),
);

export const AsyncHorizontalOperationsNav = () => (
	<Placeholder name="horizontal-operations-nav" fallback={null}>
		<LazyHorizontalOperationsNav />
	</Placeholder>
);

export const AsyncHorizontalOperationsOverviewNav = () => (
	<Placeholder name="horizontal-operations-overview-nav" fallback={null}>
		<LazyHorizontalOperationsOverviewNav />
	</Placeholder>
);
