import React, { useEffect } from 'react';
import { useEntryPoint } from '@atlassian/jira-entry-point';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container';
import { migrationMessageEntrypoint } from './entrypoint';

const runtimeProps = {};
const entryPointParams = {};
export const AsyncMigrationMessage = () => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		migrationMessageEntrypoint,
		entryPointParams,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	return (
		<JiraEntryPointContainer
			id="overviews-plans-onboarding-migration-message"
			packageName="jiraBusinessOnboarding"
			entryPointReferenceSubject={entryPointReferenceSubject}
			runtimeProps={runtimeProps}
		/>
	);
};
