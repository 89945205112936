import type { JiraNavigationItemTypeKey } from '@atlassian/jira-relay/src/__generated__/ui_navigationKitTabList_TabList_tabsFragment.graphql';

const JIRA_BUSINESS_BASE_PROJECT_URL = '/jira/core/projects';

export const getRouteForViewType = (
	projectKey: string,
	itemTypeKey: JiraNavigationItemTypeKey,
): string | undefined => {
	switch (itemTypeKey) {
		case 'SUMMARY':
			return `${JIRA_BUSINESS_BASE_PROJECT_URL}/${projectKey}/summary`;
		case 'BOARD':
			return `${JIRA_BUSINESS_BASE_PROJECT_URL}/${projectKey}/board`;
		case 'LIST':
			return `${JIRA_BUSINESS_BASE_PROJECT_URL}/${projectKey}/list`;
		case 'TIMELINE':
			return `${JIRA_BUSINESS_BASE_PROJECT_URL}/${projectKey}/timeline`;
		case 'CALENDAR':
			return `${JIRA_BUSINESS_BASE_PROJECT_URL}/${projectKey}/calendar`;
		case 'FORMS':
			return `${JIRA_BUSINESS_BASE_PROJECT_URL}/${projectKey}/form`;
		case 'APPROVALS':
			return `${JIRA_BUSINESS_BASE_PROJECT_URL}/${projectKey}/approvals`;
		case 'ATTACHMENTS':
			return `${JIRA_BUSINESS_BASE_PROJECT_URL}/${projectKey}/attachments`;
		case 'PAGES':
			return `${JIRA_BUSINESS_BASE_PROJECT_URL}/${projectKey}/pages`;
		case 'ISSUES':
			return `${JIRA_BUSINESS_BASE_PROJECT_URL}/${projectKey}/issues`;
		case 'REPORTS':
			return `/projects/${projectKey}?selectedItem=com.atlassian.jira.jira-projects-plugin:report-page`;
		case 'COMPONENTS':
			return `${JIRA_BUSINESS_BASE_PROJECT_URL}/${projectKey}/components`;
		default:
			return undefined;
	}
};
