import { createAri } from '@atlassian/jira-platform-ari';
import { createRelayResource } from '@atlassian/jira-relay-utils';
import QUERY, {
	type roadmapSettingsQuery,
} from '@atlassian/jira-relay/src/__generated__/roadmapSettingsQuery.graphql';
import { RESOURCE_TYPE_BOARD_CONTEXT_ROADMAP_SETTINGS } from '../../constants';

export const roadmapSettingsResource = createRelayResource<roadmapSettingsQuery>({
	type: RESOURCE_TYPE_BOARD_CONTEXT_ROADMAP_SETTINGS,
	getQuery: ({ match }, { tenantContext }) => {
		const { cloudId } = tenantContext;
		return {
			parameters: QUERY,
			variables: {
				sourceAri: createAri({
					resourceOwner: 'jira-software',
					cloudId,
					resourceType: 'board',
					resourceId: String(match.params.boardId),
				}),
				locationAri: undefined,
			},
			options: { fetchPolicy: 'store-and-network' },
		};
	},
});
