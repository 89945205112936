import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

const CORE_PRODUCT = 'core';
const SOFTWARE_PRODUCT = 'software';
const SERVICE_DESK_PRODUCT = 'servicedesk';
const jiraIssueFormCreateEndpoint =
	'projects/:projectKey/forms/form/direct/:templateFormId/:issueTypeId?'; // the 1 query param is "requestTypeId"

const EXPORT_URL = '/jira/forms/export';
const ISSUE_FORMS_GENERAL_URL = '/jira/forms/create';
export const routeGroupsServicedeskRouteNamesProformaExportRoute = {
	group: ROUTE_GROUPS.PROFORMA,
	name: `${ROUTE_GROUPS.SERVICEDESK}-${ROUTE_NAMES.PROFORMA_EXPORT}`,
	path: EXPORT_URL,
	exact: true,
};

export const routeGroupsServicedeskRouteNamesProformaIssueFormsGeneralRoute = {
	group: ROUTE_GROUPS.PROFORMA,
	name: `${ROUTE_GROUPS.SERVICEDESK}-${ROUTE_NAMES.PROFORMA_ISSUE_FORMS_GENERAL}`,
	path: ISSUE_FORMS_GENERAL_URL,
	exact: true,
};

export const routeGroupsServicedeskRouteNamesProformaIssueFormsDirectRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: `${ROUTE_GROUPS.SERVICEDESK}-${ROUTE_NAMES.PROFORMA_ISSUE_FORMS_DIRECT}`,
	path: `/jira/${SERVICE_DESK_PRODUCT}/${jiraIssueFormCreateEndpoint}`,
	exact: true,
};

export const routeGroupsServicedeskRouteNamesProformaIssueFormsDirectEmbedRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: `${ROUTE_GROUPS.SERVICEDESK}-${ROUTE_NAMES.PROFORMA_ISSUE_FORMS_DIRECT_EMBED}`,
	path: `/jira/${SERVICE_DESK_PRODUCT}/${jiraIssueFormCreateEndpoint}/embed`,
	exact: true,
};

export const routeGroupsClassicBusinessRouteNamesProformaExportRoute = {
	group: ROUTE_GROUPS.PROFORMA,
	name: `${ROUTE_GROUPS.CLASSIC_BUSINESS}-${ROUTE_NAMES.PROFORMA_EXPORT}`,
	path: EXPORT_URL,
	exact: true,
};

export const routeGroupsClassicBusinessRouteNamesProformaIssueFormsGeneralRoute = {
	group: ROUTE_GROUPS.PROFORMA,
	name: `${ROUTE_GROUPS.CLASSIC_BUSINESS}-${ROUTE_NAMES.PROFORMA_ISSUE_FORMS_GENERAL}`,
	path: ISSUE_FORMS_GENERAL_URL,
	exact: true,
};

export const routeGroupsClassicBusinessRouteNamesProformaIssueFormsDirectRoute = {
	group: ROUTE_GROUPS.CLASSIC_BUSINESS,
	name: `${ROUTE_GROUPS.CLASSIC_BUSINESS}-${ROUTE_NAMES.PROFORMA_ISSUE_FORMS_DIRECT}`,
	path: `/jira/${CORE_PRODUCT}/${jiraIssueFormCreateEndpoint}`,
	exact: true,
};

export const routeGroupsClassicBusinessRouteNamesProformaIssueFormsDirectEmbedRoute = {
	group: ROUTE_GROUPS.CLASSIC_BUSINESS,
	name: `${ROUTE_GROUPS.CLASSIC_BUSINESS}-${ROUTE_NAMES.PROFORMA_ISSUE_FORMS_DIRECT_EMBED}`,
	path: `/jira/${CORE_PRODUCT}/${jiraIssueFormCreateEndpoint}/embed`,
	exact: true,
};

export const routeGroupsSoftwareRouteNamesProformaExportRoute = {
	group: ROUTE_GROUPS.PROFORMA,
	name: `${ROUTE_GROUPS.SOFTWARE}-${ROUTE_NAMES.PROFORMA_EXPORT}`,
	path: EXPORT_URL,
	exact: true,
};

export const routeGroupsSoftwareRouteNamesProformaIssueFormsGeneralRoute = {
	group: ROUTE_GROUPS.PROFORMA,
	name: `${ROUTE_GROUPS.SOFTWARE}-${ROUTE_NAMES.PROFORMA_ISSUE_FORMS_GENERAL}`,
	path: ISSUE_FORMS_GENERAL_URL,
	exact: true,
};

export const routeGroupsSoftwareRouteNamesProformaIssueFormsDirectRoute = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: `${ROUTE_GROUPS.SOFTWARE}-${ROUTE_NAMES.PROFORMA_ISSUE_FORMS_DIRECT}`,
	path: `/jira/${SOFTWARE_PRODUCT}/${jiraIssueFormCreateEndpoint}`,
	exact: true,
};

export const routeGroupsSoftwareRouteNamesProformaIssueFormsDirectEmbedRoute = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: `${ROUTE_GROUPS.SOFTWARE}-${ROUTE_NAMES.PROFORMA_ISSUE_FORMS_DIRECT_EMBED}`,
	path: `/jira/${SOFTWARE_PRODUCT}/${jiraIssueFormCreateEndpoint}/embed`,
	exact: true,
};
