export const ConsolidationState = {
	NOT_CONSOLIDATED: 'NOT_CONSOLIDATED',
	TRANSITIONING: 'TRANSITIONING',
	TRANSITIONED: 'TRANSITIONED',
	CONSOLIDATED: 'CONSOLIDATED',
} as const;

export type ConsolidationState = (typeof ConsolidationState)[keyof typeof ConsolidationState];

type Cohort = 'cohort1' | 'cohort2' | 'cohort3' | 'cohort3.5';

export interface TenantInfoClientResponse {
	data: {
		consolidationState: ConsolidationState;
		cohortInfo?: Cohort | null;
	};
	requestId?: string;
	took?: number;
}

export interface TenantInfoServerResponse {
	consolidationState: ConsolidationState;
	cohortInfo?: Cohort | null;
}

export interface TenantInfo {
	consolidationState: ConsolidationState;
	cohortInfo?: Cohort | null;
}
