import React, { memo, useState, useEffect } from 'react';
import { styled } from '@compiled/react';
import {
	ROUTE_GROUPS_PROJECT_SETTINGS_CORE,
	ROUTE_NAMES_FORGE_PROJECT,
} from '@atlassian/jira-common-constants/src/spa-routes';
import { CONNECT_ROUTES_SET } from '@atlassian/jira-common-constants/src/spa-routes-sets';
import { ContextualAnalyticsData } from '@atlassian/jira-navigation-apps-sidebar-common';
import { useProjectForgeApps } from '@atlassian/jira-navigation-apps-sidebar-forge';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils';
import type { Route } from '@atlassian/jira-router';
import {
	MENU_ID,
	FORGE_ROUTES_SET,
	ISSUES_ROUTES_SET,
	REPORTS_ROUTES_SET,
	SETTINGS_ROUTES_SET,
} from '../../../../common/constants';
import {
	useFeatures,
	useProject,
	useProjectConnectItems,
	useProjectReportsConnectItems,
	useProjectSettingsConnectItems,
} from '../../../../controllers';
import ConnectAppMenu from './connect-app-menu';
import ForgeMenu from './forge-menu';
import IssuesMenu from './issues-menu';
import ReportsMenu from './reports-menu';
import SettingsMenu from './settings-menu';

const calculateStack = (
	route: Route,
	connectStack: null | Array<string>,
	forgeStack: null | Array<string>,
) => {
	const routeName = (route && route.name) || '';

	// should be handled above generic PROJECT_SETTINGS_CORE logic
	if (FORGE_ROUTES_SET.has(routeName) && forgeStack) {
		return forgeStack;
	}

	if (CONNECT_ROUTES_SET.has(routeName) && connectStack) {
		return connectStack;
	}

	return [];
};

const ProjectMenu = () => {
	const route = useCurrentRoute();
	const { data: project } = useProject();
	const { data: features, loading: isFeaturesLoading } = useFeatures();
	const { stack: connectProjectStack } = useProjectConnectItems();
	const { stack: connectReportsStack } = useProjectReportsConnectItems();
	const { stack: connectSettingsStack } = useProjectSettingsConnectItems();

	const connectStack = connectProjectStack || connectReportsStack || connectSettingsStack;

	const { loading: projectForgeAppsLoading, stack: forgeStack } =
		useProjectForgeApps(ROUTE_NAMES_FORGE_PROJECT);

	const [stack, setStack] = useState<string[]>(() =>
		calculateStack(route, connectStack, forgeStack),
	);

	useEffect(() => {
		setStack(calculateStack(route, connectStack, forgeStack));
	}, [route, connectStack, forgeStack]);

	if (project == null || isFeaturesLoading || features == null || projectForgeAppsLoading) {
		return null;
	}

	let content = null;

	if (ISSUES_ROUTES_SET.has(route.name)) {
		content = <IssuesMenu project={project} />;
	} else if (REPORTS_ROUTES_SET.has(route.name) || connectReportsStack) {
		content = <ReportsMenu />;
	} else if (
		SETTINGS_ROUTES_SET.has(route.name) ||
		route?.group === ROUTE_GROUPS_PROJECT_SETTINGS_CORE ||
		connectSettingsStack
	) {
		content = <SettingsMenu project={project} />;
	}
	// Connect and Forge Apps should be handled last since they could be nested inside above routes.
	else if (FORGE_ROUTES_SET.has(route.name) || forgeStack) {
		content = <ForgeMenu />;
	} else if (CONNECT_ROUTES_SET.has(route.name) || connectProjectStack) {
		content = <ConnectAppMenu stack={stack} onStackChange={setStack} project={project} />;
	} else {
		return null;
	}

	return (
		<ContextualAnalyticsData menuId={MENU_ID} stack={stack}>
			<Container>{content}</Container>
		</ContextualAnalyticsData>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Container = styled.div({
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div > div': {
		position: 'relative',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'> div::after': {
			// removes the absolute positioning of the nestable navigation menu
			display: 'none',
		},
	},
});

export default memo(ProjectMenu);
