import { metrics } from '@atlassian/browser-metrics';
import { extractProjectKey } from '@atlassian/jira-issue-fetch-services-common';
import {
	buildIssueQuery,
	ISSUE_VIEW_INTERACTIVE_QUERY,
} from '@atlassian/jira-issue-fetch-services-common/src/services/issue-gira-data';
import { isInSample } from '@atlassian/jira-issue-sample-utils';
import type { BaseUrl, IssueKey } from '@atlassian/jira-shared-types';
import { getClient } from '../../common/utils/client';
import type { GraphQlResponse } from '../../types';

const ISSUE_GIRA_DATA = 'issue-gira-data';
const timeToFetchIssueGiraDataMetric = metrics.custom({
	key: ISSUE_GIRA_DATA,
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default async (baseUrl: BaseUrl, issueKey: IssueKey) => {
	const projectKey = extractProjectKey(issueKey);
	const variables = {
		issueKey,
		projectKey,
	};

	const payload = {
		query: buildIssueQuery(),
		variables,
		operationName: ISSUE_VIEW_INTERACTIVE_QUERY,
	};

	if (isInSample()) {
		timeToFetchIssueGiraDataMetric.start();
	}

	try {
		const response: GraphQlResponse = await getClient(baseUrl).query(payload);

		// Best-effort attempt to provide `issueId` and is not guaranteed
		const issueId = response.data?.viewIssue?.issueId;

		timeToFetchIssueGiraDataMetric.stop({
			customData: issueId ? { issueId } : null,
		});

		return response;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		// Cancel the metric and re-throw the error
		timeToFetchIssueGiraDataMetric.cancel();
		throw error;
	}
};
