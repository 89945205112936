/** @jsx jsx */
import React from 'react';
import { cssMap, jsx } from '@compiled/react';
import Heading from '@atlaskit/heading';
import { token } from '@atlaskit/tokens';
import { getErrorHash } from '@atlassian/jira-errors-handling';
import { useIntl } from '@atlassian/jira-intl';
import errorImg from './assets/generic-error.svg';
import messages from './messages';

const styles = cssMap({
	wrapper: {
		backgroundColor: 'color.background.neutral',
		color: 'color.text.subtle',
		padding: token('space.200'),
		textAlign: 'center',
		alignItems: 'center',
		gap: token('space.100'),
		display: 'flex',
		flexDirection: 'column',
	},
	error: {
		borderRadius: 'border.radius.050',
		fontSize: token('font.body.UNSAFE_small'),
		color: token('color.text.subtlest'),
		backgroundColor: token('color.background.accent.gray.subtler'),
		paddingTop: token('space.025'),
		paddingBottom: token('space.025'),
		paddingLeft: token('space.050'),
		paddingRight: token('space.050'),
	},
	image: {
		height: '50px',
	},
});

type FallbackProps = {
	error: Error;
};

export const Fallback = (props: FallbackProps) => {
	const { error } = props;
	const { formatMessage } = useIntl();

	return (
		<div css={styles.wrapper}>
			<img css={styles.image} alt="" src={errorImg} />
			<Heading size="xsmall">{formatMessage(messages.errorHeading)}</Heading>
			<>{formatMessage(messages.errorBody)}</>
			<code css={styles.error}>ERROR: {getErrorHash(error)}</code>
		</div>
	);
};
