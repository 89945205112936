import { createHook } from 'react-sweet-state';
import { SIDEBAR, TOP_NAV } from '../../common/constants';
import Store from '../../store';
import type { State } from '../../types';

type LoadedState = {
	hasLoaded: boolean;
};
/**
 * Determines if the current page is considered loaded based on the presence and
 * loading state of apps within the page. It accounts for special cases in SPA
 * transitions and legacy page loading, ensuring compatibility across different
 * page types within a single-page application environment.
 */
export const isLoadedSelector = ({ currentPage }: State): LoadedState => {
	const { apps } = currentPage;
	const appIds = Object.keys(apps);
	const determineHasLoaded = () => {
		/*
		 * During a transition, the top navigation is the only app loading. Additionally, legacy pages generally
		 * render a LegacyPage component, analytics, or nothing at all.
		 *
		 * This code ensures that the page is considered loaded when the content has finished loading in the
		 * SPA, or when the top navigation has finished loading for legacy pages.
		 *
		 * Ideally the LegacyPage component should include an app for the content, but the usage of route components
		 * in legacy routes is inconsistent and would need thorough testing. This should be good enough for now.
		 */
		if (appIds.length === 1 && appIds[0] === TOP_NAV) {
			return !__SPA__;
		}
		return appIds.length
			? appIds
					.filter((id) => id !== SIDEBAR)
					.every((id) => apps[id].endTime != null || apps[id].timeoutTime)
			: false;
	};
	const hasLoaded = determineHasLoaded();
	return {
		hasLoaded,
	};
};
const usePageLoadedSubscriber = createHook(Store, {
	selector: isLoadedSelector,
});
export default usePageLoadedSubscriber;
