import { useRef, useMemo, useEffect, useCallback } from 'react';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils';
import type { ExperienceAttributes } from '../../common/types';
import { useExperienceContext } from '../../common/ui/use-experience-context';

export type ExperienceSuccessProps = {
	experience: string;
	attributes?: ExperienceAttributes;
};

const STABLE_EMPTY_OBJECT = {};
export const useExperienceSuccess = ({
	experience,
	attributes = STABLE_EMPTY_OBJECT,
}: ExperienceSuccessProps) => {
	const experienceTracker = useExperienceContext();
	const route = useCurrentRoute();

	const self = useRef({
		experience,
		attributes,
		experienceTracker,
		route,
	});

	self.current.route = route;
	self.current.attributes = attributes;
	self.current.experienceTracker = experienceTracker;
	self.current.experience = experience;

	return useCallback(
		(extraAttributes: ExperienceAttributes = {}) => {
			const { current } = self;

			if (current.route && current.route.isRedirect === true) {
				return;
			}

			current.experienceTracker.succeed({
				name: current.experience,
				attributes: {
					...current.attributes,
					...extraAttributes,
				},
			});
		},
		[self],
	);
};

export const ExperienceSuccess = ({
	experience,
	attributes = STABLE_EMPTY_OBJECT,
}: ExperienceSuccessProps) => {
	const onExperienceSuccess = useExperienceSuccess({
		experience,
		attributes,
	});

	useEffect(() => {
		onExperienceSuccess();
	}, [attributes, onExperienceSuccess]);

	useMemo(() => {
		if (__SERVER__) {
			onExperienceSuccess();
		}
	}, [onExperienceSuccess]);

	return null;
};
