import { JS_EVAL_START, JS_EVAL_STOP } from '@atlassian/jira-common-constants/src/analytics-marks';
import type { BundleEvalTimingsConfig } from '@atlassian/ufo-bundle-eval-timing';

export const jiraBundleEvalTimingsConfig: BundleEvalTimingsConfig = {
	mapPerformanceMark: (mark: string) => {
		if (mark.endsWith(JS_EVAL_START)) {
			return {
				name: mark.substring(0, mark.indexOf(JS_EVAL_START)),
				type: 'start',
			};
		}
		if (mark.endsWith(JS_EVAL_STOP)) {
			return {
				name: mark.substring(0, mark.indexOf(JS_EVAL_STOP)),
				type: 'end',
			};
		}
		return null;
	},
} as const;
