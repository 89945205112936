import React, { useEffect } from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import {
	useQueryParam as useQueryParamDI,
	useCurrentRoute as useCurrentRouteDI,
} from '@atlassian/jira-platform-router-utils';
import {
	useAnalyticsEvents as useAnalyticsEventsDI,
	fireScreenAnalytics as fireScreenAnalyticsDI,
} from '@atlassian/jira-product-analytics-bridge';
import { Redirect as RedirectDI, useResource } from '@atlassian/jira-router';
import { isNinGlobalScopeEnrolmentEnabledResource } from '@atlassian/jira-router-resources-is-nin-global-scope-enrolment-enabled';
import type { QuickSearchToGlobalIssueNavigatorRedirectProps } from './types';

export const QuickSearchToGlobalIssueNavigatorRedirect = ({
	Redirect = RedirectDI,
	fireScreenAnalytics = fireScreenAnalyticsDI,
	useAnalyticsEvents = useAnalyticsEventsDI,
	useCurrentRoute = useCurrentRouteDI,
	useQueryParam = useQueryParamDI,
}: QuickSearchToGlobalIssueNavigatorRedirectProps) => {
	const route = useCurrentRoute();
	const [searchString] = useQueryParam('searchString');

	const { createAnalyticsEvent } = useAnalyticsEvents();
	let isNinEnabled: boolean | null | undefined;
	if (ff('text_fix_in_gin_oypo9')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ data: isNinEnabled } = useResource(isNinGlobalScopeEnrolmentEnabledResource));
	} else {
		isNinEnabled = true;
	}

	useEffect(() => {
		if (!route) return;

		const screenEvent = createAnalyticsEvent({
			screenName: 'quickSearchRedirect',
			attributes: {
				type: route.name,
			},
		});
		fireScreenAnalytics(screenEvent);
	}, [createAnalyticsEvent, fireScreenAnalytics, route]);

	let jql: string;
	if (isNinEnabled && ff('nin_expose_text_option_jql_builder_4kwzo')) {
		jql =
			searchString != null
				? `summary ~ "${searchString}" OR description ~ "${searchString}" ORDER BY created DESC`
				: 'ORDER BY created DESC';
	} else {
		jql = searchString != null ? `text ~ "${searchString}"` : 'order by created DESC';
	}

	const redirectUrl = `/issues/?jql=${encodeURIComponent(jql)}`;

	return <Redirect to={redirectUrl} />;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => <QuickSearchToGlobalIssueNavigatorRedirect />;
