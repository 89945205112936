import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ProjectSettingsServiceDeskLanguageSupportType from '@atlassian/jira-spa-apps-project-settings-service-desk-language-support';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyProjectSettingsServiceDeskLanguageSupport = lazyForPaint<
	typeof ProjectSettingsServiceDeskLanguageSupportType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-project-settings-service-desk-language-support", jiraSpaEntry: "async-project-settings-service-desk-language-support" */ '@atlassian/jira-spa-apps-project-settings-service-desk-language-support'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyProjectSettingsServiceDeskLanguageSupport}
		pageId="project-settings-language-support"
		shouldShowSpinner
	/>
);
