import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type CustomFieldDetail from '@atlassian/jira-spa-apps-admin-pages-performance-custom-field';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyCustomFieldDetail = lazyForPaint<typeof CustomFieldDetail>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-system-performance-metric-detail", jiraSpaEntry: "async-system-performance-custom-field" */ '@atlassian/jira-spa-apps-admin-pages-performance-custom-field'
		),
	),
);

export const CustomFieldMetricDetail = () => (
	<LazyPage
		Page={LazyCustomFieldDetail}
		pageId="system-performance-metric-detail-custom-field"
		shouldShowSpinner
	/>
);
