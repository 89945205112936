import { createActionsHook, createStateHook } from 'react-sweet-state';
import { layoutStore } from '../../store';
import type { LayoutState, LayoutActions } from '../../store/types';

export const useBottomRightCornerContainer = createStateHook<
	LayoutState,
	LayoutActions,
	HTMLElement | undefined,
	undefined
>(layoutStore, {
	selector: (state) => state.bottomRightCorner.container,
});

export const useBottomRightCornerActions = createActionsHook<LayoutState, LayoutActions>(
	layoutStore,
);
