import { useContext, useEffect } from 'react';
import pickBy from 'lodash/pickBy';
import {
	FavouriteChangeContext,
	type ItemType,
	type ItemMap,
} from '@atlassian/jira-favourite-change-provider/src';
import { usePreviousWithInitial } from '@atlassian/jira-platform-react-hooks-use-previous';

type FavouritesChangeEffect = (
	hasPendingChanges: boolean,
	previousHasPendingChanges: boolean,
) => void;

export const useFavouritesChangeEffect = (type: ItemType, effect: FavouritesChangeEffect) => {
	const { items } = useContext(FavouriteChangeContext);
	// @ts-expect-error - TS2322 - Type 'ItemMap | undefined' is not assignable to type 'ItemMap'.
	const typeItems: ItemMap = items[type];

	const pendingChanges = pickBy(typeItems, (item) => item.pending);
	const hasPendingChanges = Boolean(Object.keys(pendingChanges).length);
	const previousHasPendingChanges = usePreviousWithInitial(hasPendingChanges);

	useEffect(() => {
		effect(hasPendingChanges, previousHasPendingChanges);
	}, [effect, hasPendingChanges, previousHasPendingChanges]);
};
