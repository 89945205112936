import type { ProjectSidebarAddonNavItem } from '@atlassian/jira-navigation-apps-resources';
import type { NavLink } from '@atlassian/jira-navigation-apps-sidebar-common/src/types';
import type { ConnectDataItem } from '../../types';

export const transformLink = (link: NavLink): ConnectDataItem | null => {
	const items =
		link.links && link.links.length ? link.links.map(transformLink).filter(Boolean) : undefined;

	// section item
	if (link.section === true) {
		// sometimes sections might be empty, is so remove them
		if (!items || !items.length) return null;

		// avoid nested groups with multiple headings
		if (items.length === 1 && items[0]?.section === true) {
			return {
				...items[0],
				name: items[0].name || String(link.name),
			};
		}
		return {
			id: link.id,
			name: String(link.name),
			section: !!link.section,
			items,
		};
	}

	// normal item (plain or with nest)
	return {
		id: link.id,
		name: String(link.name),
		url: link.url,
		iconUrl: link.iconUrl || '',
		styleClass: link.styleClass || '',
		items,
	};
};

export const transformLinkV4 = (data: ProjectSidebarAddonNavItem): ConnectDataItem | null => {
	const items =
		data.links && data.links.length ? data.links.map(transformLinkV4).filter(Boolean) : undefined;

	// section item
	if (data.section === true) {
		// sometimes sections might be empty, is so remove them
		if (!items || !items.length) return null;

		// avoid nested groups with multiple headings
		if (items.length === 1 && items[0]?.section === true) {
			return {
				...items[0],
				name: items[0].name || String(data.name),
			};
		}
		return {
			id: data.id,
			name: String(data.name),
			section: !!data.section,
			items,
		};
	}

	// normal item (plain or with nest)
	return {
		id: data.id,
		name: String(data.name),
		url: data.url,
		iconUrl: data.iconUrl || '',
		styleClass: data.styleClass || '',
		items,
	};
};
