import type { DragLocationHistory } from '@atlaskit/pragmatic-drag-and-drop/types';

export const getWidthFromDragLocation = ({
	initialWidth,
	location,
	direction,
}: {
	initialWidth: number;
	location: DragLocationHistory;
	direction: 'ltr' | 'rtl';
}): number => {
	const diffX = location.current.input.clientX - location.initial.input.clientX;
	return direction === 'rtl' ? initialWidth - diffX : initialWidth + diffX;
};

/**
 * Returns the computed width of an element in pixels.
 */
export const getComputedWidth = (element: HTMLElement): number => {
	const { width } = window.getComputedStyle(element);

	return parseInt(width);
};
