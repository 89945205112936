import { fetchQuery, graphql } from 'react-relay';
import { JiraFilterAri } from '@atlassian/ari/jira/filter';
import { fg } from '@atlassian/jira-feature-gating';
import { MAX_COLUMNS } from '@atlassian/jira-native-issue-table';
import getRelayEnvironment from '@atlassian/jira-relay-environment';
import type { issueTableView_reactIssueTableGadgets_IssueTableRefetchableQuery$variables as IssueTableViewRefetchableQueryVariablesType } from '@atlassian/jira-relay/src/__generated__/issueTableView_reactIssueTableGadgets_IssueTableRefetchableQuery.graphql';
import type { transformToVariables_filterResultFilterQuery as FilterResultsFilterQueryType } from '@atlassian/jira-relay/src/__generated__/transformToVariables_filterResultFilterQuery.graphql';
import type { UserPrefsForIssueTableQuery } from '../../types';
import { convertFieldToJqlTerm } from './convert-field-to-jql-term';
import { getAliasesForField } from './get-aliases-for-field';

export const transformToVariables = async ({
	userPrefs,
	cloudId,
	activationId,
	jql,
}: {
	userPrefs: UserPrefsForIssueTableQuery;
	cloudId: string;
	activationId: string;
	/**
	 * provide jql if we already have it (must match the filterId) or else we will fetch it
	 */
	jql?: string | null;
}): Promise<{
	variables: IssueTableViewRefetchableQueryVariablesType;
	fieldToJqlTermMap: Map<string, string>;
}> => {
	const { filterId, columnNames, num, sortField, sortDirection, showResolved } = userPrefs;

	const filterAri =
		!jql && filterId
			? JiraFilterAri.create({
					filterId,
					siteId: cloudId,
					activationId,
				}).toString()
			: '';
	const variables = {
		cloudId,
		issueSearchInput: { ...(jql ? { jql } : { filterId }) },
		after: null,
		first: num,
		fieldSetIds: columnNames,
		amountOfColumns: MAX_COLUMNS,
		shouldQueryFieldSetsById: true,
		isNotFilterFetch: !filterAri,
		filterAri,
		// This can be removed on clean up
		isDensityFull: fg('endeavour_gadget_enable_density_on_filter_results'),
	};
	const fieldToJqlTermMap: Map<string, string> = new Map<string, string>();
	if (!sortField || !sortDirection || !['ASC', 'DESC'].includes(sortDirection)) {
		return {
			variables,
			fieldToJqlTermMap,
		};
	}

	let jqlToModify = jql;

	if (!jqlToModify && filterAri) {
		const result = await fetchQuery<FilterResultsFilterQueryType>(
			getRelayEnvironment(),
			graphql`
				query transformToVariables_filterResultFilterQuery(
					$filterAri: ID!
					$fieldSetIds: [String!]!
					$cloudId: ID!
				) {
					jira @required(action: THROW) {
						filter(id: $filterAri) @required(action: THROW) {
							jql
						}
						fieldSetsById(fieldSetIds: $fieldSetIds, cloudId: $cloudId)
							@required(action: THROW)
							@optIn(to: "JiraFieldSetsById") {
							edges @required(action: THROW) {
								node @required(action: THROW) {
									fieldSetId @required(action: THROW)
									jqlTerm @required(action: THROW)
									aliases
									isSortable
								}
							}
						}
					}
				}
			`,
			{
				filterAri,
				fieldSetIds: variables.fieldSetIds,
				cloudId: variables.cloudId,
			},
			{
				fetchPolicy: 'store-or-network',
			},
		).toPromise();
		jqlToModify = result?.jira.filter.jql;

		if (result?.jira.fieldSetsById.edges) {
			result.jira.fieldSetsById.edges.forEach((edge) => {
				const node = edge?.node;
				if (node && node.isSortable) {
					fieldToJqlTermMap.set(node.fieldSetId, node.jqlTerm);
					node.aliases?.forEach((alias) => {
						fieldToJqlTermMap.set(alias, node.jqlTerm);
					});
				}
			});
		}
	}

	if (!jqlToModify) {
		return {
			variables,
			fieldToJqlTermMap,
		};
	}

	// This pulls in over 600KB of code, and if it's not deferred it's eagerly loaded as
	// part of the main jira-spa route map
	const { JqlUtils, findClauseInAst, removeFieldClauseFromJql } = await import(
		/* webpackChunkName: "async-jira-jql-utils" */ '@atlassian/jira-jql-utils'
	);

	const jqlUtils = new JqlUtils();
	const currentSortField = convertFieldToJqlTerm(sortField);
	const jqlTermForSortField = fieldToJqlTermMap.get(currentSortField) ?? currentSortField;
	const modifiedJql = jqlUtils.jql(jqlToModify);
	const oldPrimarySortField = modifiedJql.getPrimarySortField();
	if (oldPrimarySortField) {
		// if the sort field in original filter jql is using alias, we need to pass all possible aliases in
		// so that jqlUtils knows to replace it instead of append it as a new field
		const aliases = getAliasesForField(jqlTermForSortField, fieldToJqlTermMap);
		// Sometimes, oldPrimarySortField from the JQL has
		// quotations around it which we should include
		const inclusiveAliases = Array.from(new Set([...aliases, oldPrimarySortField]));
		modifiedJql.setPrimarySortField(jqlTermForSortField, sortDirection, inclusiveAliases);
	} else {
		modifiedJql.setPrimarySortField(jqlTermForSortField, sortDirection, []);
	}

	let finalJql = modifiedJql.jqlString;
	if (showResolved) {
		const jast = modifiedJql.jast;
		if (jast) {
			const statusClause = findClauseInAst(jast, 'statusCategory');
			if (statusClause) {
				finalJql = removeFieldClauseFromJql(jast, statusClause);
			}
		}
	}
	return {
		variables: { ...variables, issueSearchInput: { jql: finalJql } },
		fieldToJqlTermMap,
	};
};
