import { metrics } from '@atlassian/browser-metrics';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import { DEFAULT_HISTOGRAM_BUCKETS } from '../constants';

export const trackBrowserMetricsForJira = ({
	browserMetricsKey,
	attributes,

	// Define bucket sizes for histogram. e.g.: '100_500_1000_2500_5000_10000'. Default to '500_1000_1500_4000_7000'.
	histogram,
}: {
	browserMetricsKey?: string;
	histogram?: string;
	attributes?: Attributes;
}) => {
	if (browserMetricsKey === null || browserMetricsKey === undefined) {
		return {
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			start: () => {},
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			end: () => {},
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			cancel: () => {},
		};
	}

	const bm3MetricsTracker = metrics.interaction({
		key: browserMetricsKey,
		// @ts-expect-error - TS2741 - Property '[BMInteractionMetrics.response]' is missing in type '{ result: string | undefined; }' but required in type 'InteractionHistogramConfig'.
		histogram: {
			result: histogram || DEFAULT_HISTOGRAM_BUCKETS,
		},
	});

	const startHandler = () => {
		bm3MetricsTracker.start();
	};

	const endHandler = () => {
		bm3MetricsTracker.stop({
			customData: attributes,
		});
	};

	const cancelHandler = () => {
		bm3MetricsTracker.cancel();
	};

	return {
		start: startHandler,
		end: endHandler,
		cancel: cancelHandler,
	};
};
