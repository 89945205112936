import React, { type KeyboardEvent, type MouseEvent, useCallback } from 'react';
import AddonIcon from '@atlaskit/icon/glyph/addon';
import type { CSSFn } from '@atlaskit/menu';
import { Section } from '@atlaskit/side-navigation';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize } from '@atlassian/jira-common-styles/src';
import { forgeModulesConcurrentLoadTime } from '@atlassian/jira-forge-ui-analytics/src/common/utils/performance-analytics';
import {
	EnvironmentLozenge,
	nameWithCustomEnvironment,
} from '@atlassian/jira-forge-ui-extension-title';
import type { Section as SectionType } from '@atlassian/jira-forge-ui-types';
import {
	ForgePageHeader as ForgePageHeaderDI,
	matchPartialUrlPathname,
	MenuItem,
	NestingMenuItem,
	SectionHeader,
	type MatcherLocation,
} from '@atlassian/jira-navigation-apps-sidebar-common';
import { ForgeIcon, ForgeSubpagesContainer } from './styled';
import type { ForgeItem as ForgeItemProps, ForgeSubpagesViewProps } from './types';

export const createPageUrl = (extensionBaseUrl: string, route: string) =>
	route ? `${extensionBaseUrl}/${String(route).trim().replace(/^\/+/, '')}` : extensionBaseUrl;

export const Icon = ({ url }: { url?: string }) =>
	url != null && String(url).trim().length > 0 ? <ForgeIcon src={url} /> : <AddonIcon label="" />;

export const ForgeItemSimple = (props: ForgeItemProps) => {
	const {
		showIcon,
		id: itemId,
		iconUrl,
		environmentType,
		environmentKey,
		url,
		name,
		moduleName,
		overrides,
	} = props;

	if (overrides?.showSelectedAppMenuOnly) {
		return null;
	}

	return (
		<MenuItem
			iconBefore={showIcon ? <Icon url={iconUrl} /> : null}
			iconAfter={<EnvironmentLozenge environmentType={environmentType} />}
			href={url}
			selectedOn={matchPartialUrlPathname()}
			analytics={{ itemId, itemType: 'forge', module: moduleName }}
			onClick={() => {
				forgeModulesConcurrentLoadTime(itemId).start({ startTime: performance.now() });
			}}
		>
			{nameWithCustomEnvironment(name, environmentType, environmentKey)}
		</MenuItem>
	);
};

const nestedHeaderAlignmentFix: CSSFn = () => ({
	paddingLeft: `${gridSize * 2.625}px`,
});

export const ForgeSubpagesProjectHeader = ({
	ForgePageHeader = ForgePageHeaderDI,
	iconUrl,
	name,
	environmentType,
	environmentKey,
	nestedMenuActive,
}: ForgeSubpagesViewProps) => (
	<ForgePageHeader
		avatarUrl={iconUrl || ''}
		name={name}
		environmentType={environmentType}
		environmentKey={environmentKey}
		cssFn={
			// this to avoid sketchy-null-bool linting issue
			typeof nestedMenuActive === 'boolean' && Boolean(nestedMenuActive)
				? nestedHeaderAlignmentFix
				: undefined
		}
	/>
);

export const ForgeSubpagesView = (props: ForgeSubpagesViewProps) => {
	const { showIcon, id: itemId, url, sections, moduleName } = props;

	const selectedOn = useCallback(
		(route: string) =>
			['', '/'].includes(String(route || '').trim())
				? // eslint-disable-next-line @typescript-eslint/no-explicit-any
					(currentLocation: any, itemLocation: any) =>
						currentLocation.pathname.replace(/\/$/, '') === itemLocation.pathname.replace(/\/$/, '')
				: matchPartialUrlPathname(),
		[],
	);

	return (
		<ForgeSubpagesContainer>
			<ForgeSubpagesProjectHeader {...props} />
			{sections?.map((section: SectionType, index: number) => (
				<Section key={index}>
					{typeof section.header === 'string' ? (
						<SectionHeader>{section.header}</SectionHeader>
					) : null}
					{section.pages.map((page) => (
						<MenuItem
							key={page.route}
							iconBefore={showIcon ? <Icon url={page.icon} /> : null}
							href={createPageUrl(url, page.route)}
							selectedOn={selectedOn(page.route)}
							analytics={{ itemId, itemType: 'forge', module: moduleName }}
							onClick={() => {
								forgeModulesConcurrentLoadTime(itemId).start({ startTime: performance.now() });
							}}
						>
							{page.title}
						</MenuItem>
					))}
				</Section>
			))}
		</ForgeSubpagesContainer>
	);
};

export const ForgeItem = (props: ForgeItemProps) => {
	const {
		showIcon,
		id: itemId,
		url,
		iconUrl,
		environmentType,
		environmentKey,
		name,
		sections,
		overrides,
	} = props;

	const selectedOn = useCallback(
		(currentLocation: MatcherLocation) => currentLocation.pathname.includes(url),
		[url],
	);

	const onNestedMenuItemClick = useCallback(
		(event: MouseEvent | KeyboardEvent) => {
			if (overrides?.ParentMenuItem?.onClick) {
				overrides.ParentMenuItem.onClick(event);
			}
			return () => null;
		},
		[overrides],
	);

	if (!sections?.length) {
		return <ForgeItemSimple {...props} />;
	}

	if (overrides?.showSelectedAppMenuOnly) {
		return <ForgeSubpagesView {...props} />;
	}

	return (
		<NestingMenuItem
			analytics={{ itemId: 'subpages' }}
			id={`${itemId}-subpages`}
			iconBefore={showIcon ? <Icon url={iconUrl} /> : null}
			iconAfter={<EnvironmentLozenge environmentType={environmentType} />}
			title={nameWithCustomEnvironment(name, environmentType, environmentKey)}
			selectedOn={selectedOn}
			onClick={onNestedMenuItemClick}
			// @ts-expect-error - overrides types mismatch
			overrides={overrides}
		>
			<ForgeSubpagesView {...props} />
		</NestingMenuItem>
	);
};
