/**
 * Token types
 */

export const TOKEN_TYPES = [
	'WHITE_SPACE',
	'SEPARATOR',
	'IDENTIFIER',
	'MODIFIER',
	'KEYWORD',
	'LOGICAL_OPERATOR',
	'STRING_LITERAL',
	'NUMBER_LITERAL',
	'BOOLEAN_LITERAL',
	'NULL_LITERAL',
	'OPERATOR',
] as const;

/**
 * Tokens
 *
 * As seen in the TokenTypes
 */

// 1- White space token
// Also called WS (\\s)

// 2- Separator tokens
export const LEFT_PARENTHESIS = '(';
export const RIGHT_PARENTHESIS = ')';

// 3- Keyword tokens
// is null and is not null

// 4- Operator tokens
export const OPERATOR_EQUAL = '=';
export const OPERATOR_NOT_EQUAL = '!=';
export const OPERATOR_EQUAL_TO = ':';
export const OPERATOR_NOT_EQUAL_TO = '!:';
export const OPERATOR_LESS_THAN = '<';
export const OPERATOR_LESS_THAN_OR_EQUAL = '<=';
export const OPERATOR_GREATER_THAN = '>';
export const OPERATOR_GREATER_THAN_OR_EQUAL = '>=';
export const OPERATOR_GROUP_FOR_ASSISTANT = [
	OPERATOR_EQUAL,
	OPERATOR_LESS_THAN,
	OPERATOR_LESS_THAN_OR_EQUAL,
	OPERATOR_GREATER_THAN,
	OPERATOR_GREATER_THAN_OR_EQUAL,
] as const;

// 5- Logical operator tokens
export const LOGICAL_OPERATOR_AND = 'AND';
export const LOGICAL_OPERATOR_OR = 'OR';

// 6- Modifier token
export const MODIFIER_NOT = 'not';

// 7- Boolean literal tokens
export const BOOLEAN_LITERAL_TRUE = 'true';
export const BOOLEAN_LITERAL_FALSE = 'false';
export const BOOLEAN_LITERAL_GROUP = [BOOLEAN_LITERAL_TRUE, BOOLEAN_LITERAL_FALSE] as const;

// 8- String literal tokens
// Any free text
export const STRING_LITERAL_DOUBLE_QUOTE = '"';
export const STRING_LITERAL_SINGLE_QUOTE = "'";

// Static string literals for autocomplete and search assistant cases:
export const STRING_LITERAL_STATUS_OPEN = `${STRING_LITERAL_DOUBLE_QUOTE}open${STRING_LITERAL_DOUBLE_QUOTE}`;
export const STRING_LITERAL_STATUS_CLOSED = `${STRING_LITERAL_DOUBLE_QUOTE}closed${STRING_LITERAL_DOUBLE_QUOTE}`;
export const STRING_LITERAL_STATUS_ACKED = `${STRING_LITERAL_DOUBLE_QUOTE}acknowledged${STRING_LITERAL_DOUBLE_QUOTE}`;
export const STRING_LITERAL_STATUS_SNOOZED = `${STRING_LITERAL_DOUBLE_QUOTE}snoozed${STRING_LITERAL_DOUBLE_QUOTE}`;
export const STRING_LITERAL_STATUS_GROUP = [
	STRING_LITERAL_STATUS_OPEN,
	STRING_LITERAL_STATUS_CLOSED,
	STRING_LITERAL_STATUS_ACKED,
	STRING_LITERAL_STATUS_SNOOZED,
] as const;

export const STRING_LITERAL_P1 = 'P1';
export const STRING_LITERAL_P2 = 'P2';
export const STRING_LITERAL_P3 = 'P3';
export const STRING_LITERAL_P4 = 'P4';
export const STRING_LITERAL_P5 = 'P5';
export const STRING_LITERAL_PRIORITY_GROUP = [
	STRING_LITERAL_P1,
	STRING_LITERAL_P2,
	STRING_LITERAL_P3,
	STRING_LITERAL_P4,
	STRING_LITERAL_P5,
] as const;

// 9- Number literal tokens
// Any whole number

// 10- Null literal token
export const NULL_LITERAL = 'null';

// 11- Identifiers
// Alert domain specific literals
// Copied from ANTLR source at opsgenie-apps/src/master/antlr4/src/main/antlr4/AlertSearchQueryLang.g4
export const IDENTIFIER_ACTIONS = 'actions';
export const IDENTIFIER_ALIAS = 'alias';
export const IDENTIFIER_CLOSED_BY = 'closedBy';
export const IDENTIFIER_DEDUPLICATION = 'count';
export const IDENTIFIER_CREATED_AT = 'createdAt';
export const IDENTIFIER_DESCRIPTION = 'description';
export const IDENTIFIER_DETAILS_KEY = 'details.key';
export const IDENTIFIER_DETAILS_VALUE = 'details.value';
export const IDENTIFIER_DETAILS_PAIR = 'detailsPair';
export const IDENTIFIER_ENTITY = 'entity';
export const IDENTIFIER_INTEGRATION_NAME = 'integration.name';
export const IDENTIFIER_INTEGRATION_TYPE = 'integration.type';
export const IDENTIFIER_IS_SEEN = 'isSeen';
export const IDENTIFIER_MESSAGE = 'message';
export const IDENTIFIER_ASSIGNEE = 'owner';
export const IDENTIFIER_PRIORITY = 'priority';
export const IDENTIFIER_RESPONDERS = 'responders';
export const IDENTIFIER_SOURCE = 'source';
export const IDENTIFIER_STATUS = 'status';
export const IDENTIFIER_SYNC_NAME = 'sync.name';
export const IDENTIFIER_SYNC_TYPE = 'sync.type';
export const IDENTIFIER_TAG = 'tags';
export const IDENTIFIER_TINY_ID = 'tinyId';
export const IDENTIFIER_UPDATED_AT = 'updatedAt';
export const IDENTIFIER_ACKNOWLEDGED = 'acknowledged';
export const IDENTIFIER_ACKNOWLEDGED_BY = 'acknowledgedBy';
export const IDENTIFIER_AGGREGATOR_IDS = 'aggregatorIds';
export const IDENTIFIER_ALERT_ID = 'alertId';
export const IDENTIFIER_ALERT_TYPE = 'alertType';
export const IDENTIFIER_ENCRYPTED = 'encrypted';
export const IDENTIFIER_ENCRYPTION_ID = 'encryptionId';
export const IDENTIFIER_LAST_OCCURRED_AT = 'lastOccurredAt';
export const IDENTIFIER_NOTIFY = 'notify';
export const IDENTIFIER_SNOOZED_UNTIL = 'snoozedUntil';
export const IDENTIFIER_TEAMS = 'teams';

export const IDENTIFIER_GROUP_ALERT = [
	IDENTIFIER_ACKNOWLEDGED,
	IDENTIFIER_ACKNOWLEDGED_BY,
	IDENTIFIER_AGGREGATOR_IDS,
	IDENTIFIER_ALERT_ID,
	IDENTIFIER_ALERT_TYPE,
	IDENTIFIER_ENCRYPTED,
	IDENTIFIER_ENCRYPTION_ID,
	IDENTIFIER_LAST_OCCURRED_AT,
	IDENTIFIER_NOTIFY,
	IDENTIFIER_SNOOZED_UNTIL,
	IDENTIFIER_TEAMS,
	IDENTIFIER_ACTIONS,
	IDENTIFIER_ALIAS,
	IDENTIFIER_CLOSED_BY,
	IDENTIFIER_DEDUPLICATION,
	IDENTIFIER_CREATED_AT,
	IDENTIFIER_DESCRIPTION,
	IDENTIFIER_DETAILS_KEY,
	IDENTIFIER_DETAILS_VALUE,
	IDENTIFIER_DETAILS_PAIR,
	IDENTIFIER_ENTITY,
	IDENTIFIER_INTEGRATION_NAME,
	IDENTIFIER_INTEGRATION_TYPE,
	IDENTIFIER_IS_SEEN,
	IDENTIFIER_MESSAGE,
	IDENTIFIER_ASSIGNEE,
	IDENTIFIER_PRIORITY,
	IDENTIFIER_RESPONDERS,
	IDENTIFIER_SOURCE,
	IDENTIFIER_STATUS,
	IDENTIFIER_SYNC_NAME,
	IDENTIFIER_SYNC_TYPE,
	IDENTIFIER_TAG,
	IDENTIFIER_TINY_ID,
	IDENTIFIER_UPDATED_AT,
] as const;

export const IDENTIFIER_BOOLEAN_GROUP = [IDENTIFIER_ACKNOWLEDGED, IDENTIFIER_IS_SEEN];

// All identifiers in an array
export const IDENTIFIER_GROUP_ALL = [...IDENTIFIER_GROUP_ALERT];

/**
 * Regex definitions
 */

// The main regex to split any given query into smaller tokens.
// Please be aware that token order has a meaning here.
const splitToTokensCases = [
	// 1. Split single and double quotes
	...[STRING_LITERAL_SINGLE_QUOTE, STRING_LITERAL_DOUBLE_QUOTE].map((i) => `(${i}.*?${i})`),
	...[STRING_LITERAL_SINGLE_QUOTE, STRING_LITERAL_DOUBLE_QUOTE].map((i) => `(${i}.*)`),
	// 2. Split operators and separators
	...[
		LEFT_PARENTHESIS,
		RIGHT_PARENTHESIS,
		OPERATOR_EQUAL,
		OPERATOR_NOT_EQUAL,
		OPERATOR_EQUAL_TO,
		OPERATOR_NOT_EQUAL_TO,
		OPERATOR_GREATER_THAN_OR_EQUAL,
		OPERATOR_GREATER_THAN,
		OPERATOR_LESS_THAN_OR_EQUAL,
		OPERATOR_LESS_THAN,
	].map((i) => `(\\${i})`),

	// 3. Split the exact keywords when it's a known literal
	// Known issue: The word boundary in the beginning does not capture letters except latin chars, digits and underscore, i.e. "+and" results to STRING_LITERAL + BOOLEAN_LITERAL
	...[
		LOGICAL_OPERATOR_AND,
		LOGICAL_OPERATOR_OR,
		BOOLEAN_LITERAL_TRUE,
		BOOLEAN_LITERAL_FALSE,
		MODIFIER_NOT,
		`is\\s*null`,
		`is\\s*not\\s*null`,
	].map((i) => `(\\b${i}(?=\\s|$))`),

	// 4. Empty spaces
	'(\\s+)',
];

export const splitToTokensRegex = new RegExp(splitToTokensCases.join('|'), 'gi');

//definition: accepts 'is null' and 'is not null' with one or more spaces
export const isKeywordRegex = /^(is(  *)not(  *)null)$|^(is(  *)null)$/i;

//definition: does exact matching for "not" keyword
export const isModifierRegex = /^not$/i;

//definition: accepts one of the following <=, >=, !:, !=
export const isComplexOperatorRegex = /^<=$|^>=$|^!=$|^!:$/;

//definition: accepts one or more white spaces
// Safari inserts nbsp (160) instead of regular space (32), which we should also match, so don't use " " here.
// For reference UTF-16 code point for nbsp is \u00A0
export const isWSRegex = /^\s+$/;

//definition: accepts if given value is one of the defined identifiers.
export const isIdentifierRegex = new RegExp(IDENTIFIER_GROUP_ALL.map((i) => `^${i}$`).join('|'));

//definition: accepts "and" "or" values, this check case insensitive
export const isLogicalOperatorRegex = /^and$|^or$/i;

//definition: accepts one of the following  (=, :, <, >)
export const isOperatorRegex = /^[=:<>]$/;
