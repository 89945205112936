import {
	API_ORIGIN_LOCAL,
	API_PATHNAME_LOCAL,
	REMOTE_MODULE_URI_LOCAL,
	REMOTE_MODULE_URI_PROD,
	REMOTE_MODULE_URI_STG,
} from './constants';
import type { PresetEnvironment } from './types';
import type { Env } from '../../post-office/types';
import { type PostOfficeEnvironmentValue } from '../../post-office-environment/types';

export const getEnvironmentPaths = (env: Env): PostOfficeEnvironmentValue => {
	const envs: PresetEnvironment = {
		test: {
			env: 'test',
			remoteModule: {
				origin: REMOTE_MODULE_URI_LOCAL,
				pathname: undefined,
				searchParams: undefined,
			},
			api: {
				origin: API_ORIGIN_LOCAL,
				pathname: API_PATHNAME_LOCAL,
				searchParams: undefined,
			},
		},
		dev: {
			env: 'dev',
			remoteModule: {
				origin: REMOTE_MODULE_URI_LOCAL,
				pathname: undefined,
				searchParams: undefined,
			},
			api: {
				origin: API_ORIGIN_LOCAL,
				pathname: API_PATHNAME_LOCAL,
				searchParams: undefined,
			},
		},
		local: {
			env: 'local',
			remoteModule: {
				origin: REMOTE_MODULE_URI_LOCAL,
				pathname: undefined,
				searchParams: undefined,
			},
			api: {
				origin: undefined,
				pathname: undefined,
				searchParams: undefined,
			},
		},
		staging: {
			env: 'staging',
			remoteModule: {
				origin: REMOTE_MODULE_URI_STG,
				pathname: undefined,
				searchParams: undefined,
			},
			api: {
				origin: undefined,
				pathname: undefined,
				searchParams: undefined,
			},
		},
		'stg-fedm-east': {
			env: 'stg-fedm-east',
			remoteModule: {
				origin: REMOTE_MODULE_URI_STG,
				pathname: undefined,
				searchParams: undefined,
			},
			api: {
				origin: undefined,
				pathname: undefined,
				searchParams: undefined,
			},
		},
		production: {
			env: 'production',
			remoteModule: {
				origin: REMOTE_MODULE_URI_PROD,
				pathname: undefined,
				searchParams: undefined,
			},
			api: {
				origin: undefined,
				pathname: undefined,
				searchParams: undefined,
			},
		},
		'prod-fedm-east': {
			env: 'prod-fedm-east',
			remoteModule: {
				origin: REMOTE_MODULE_URI_PROD,
				pathname: undefined,
				searchParams: undefined,
			},
			api: {
				origin: undefined,
				pathname: undefined,
				searchParams: undefined,
			},
		},
	};

	return envs[env];
};
