// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getMetaNode = (name: any) => document.querySelector(`meta[name="${name}"]`);

const getHead = () => document.head || document.querySelector('head');
/**
 * add the meta tag element if it does not exist, or replace the old one if it exist
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addMetaTag = (name: any, content: any) => {
	if (!name || !content) {
		return;
	}
	const existingMeta = getMetaNode(name);
	const meta = document.createElement('meta');
	meta.name = name;
	meta.content = content;
	if (existingMeta) {
		existingMeta.parentNode?.replaceChild(existingMeta, meta);
	} else {
		const head = getHead();
		if (head) {
			head.appendChild(meta);
		}
	}
};

/**
 * remove meta tag if exists
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeMetaTag = (name: any) => {
	const meta = getMetaNode(name);
	if (meta) {
		meta.parentNode?.removeChild(meta);
	}
};
