import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { issueLinkInformationResource } from '@atlassian/jira-router-resources-advanced-roadmaps-settings/src/services/issue-link';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation';
import {
	softwareArjSettingsDependenciesRoute,
	softwareArjSettingsDependenciesRedirectRoute,
} from '@atlassian/jira-router-routes-advanced-roadmaps-routes';
import type ArjDependencySettingsType from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/settings/dependencies';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import { redirectTo } from '../../../common/ui/redirect/settings';

/**
 * Plans Settings - Dependencies
 */
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyArjSettingsDependencies = lazyForPaint<typeof ArjDependencySettingsType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-arj-settings-dependencies", jiraSpaEntry: "async-arj-settings-dependencies" */ '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/settings/dependencies'
			),
		),
	{ ssr: false },
);

export const ArjSettingsDependencies = () => (
	<LazyPage
		Page={LazyArjSettingsDependencies}
		pageId="arj-settings-dependencies"
		shouldShowSpinner
	/>
);

export const arjSettingsDependenciesRouteEntry: Route = createEntry(
	softwareArjSettingsDependenciesRoute,
	{
		component: ArjSettingsDependencies,
		layout: globalSettingsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProductsSettingResource(),
			issueLinkInformationResource,
		],
		forPaint: [LazyAtlassianNavigation, LazyArjSettingsDependencies],
	},
);

export const arjSettingsDependenciesLegacyRedirectRouteEntry: Route = createEntry(
	softwareArjSettingsDependenciesRedirectRoute,
	{
		component: redirectTo('/jira/plans/settings/dependencies'),
		isRedirect: true,
		layout: globalSettingsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProductsSettingResource(),
			issueLinkInformationResource,
		],
		forPaint: [LazyAtlassianNavigation, LazyArjSettingsDependencies],
	},
);
