import React, { useCallback, useMemo, type ReactNode } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import { useExperienceFail, useExperienceAbort } from '@atlassian/jira-experience-tracker';
import { getUserLocation } from '@atlassian/jira-platform-router-utils';
import { SidebarStart, useSidebarExperience } from '../../controllers/metrics';
import { Fallback } from './fallback';

type Props = {
	children: ReactNode;
	id: string;
	packageName: string;
	teamName?: string;
};

export const SidebarBoundary = ({ children, id, packageName, teamName }: Props) => {
	const { experience, attributes } = useSidebarExperience();
	const onFail = useExperienceFail({ experience, attributes });
	const onAbort = useExperienceAbort({ experience, attributes });

	const userLocation = getUserLocation();
	const isNotSSR = !__SERVER__;

	const extraEventData = useMemo(() => ({ userLocation }), [userLocation]);

	const fallback = useCallback(({ error }: { error: Error }) => <Fallback error={error} />, []);

	const handleError = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(location: string, error: any) => {
			const { statusCode } = error;

			if (statusCode >= 400 && statusCode < 500) {
				onAbort('Sidebar failed with validation error', { error });
			} else {
				onFail(location, error);
			}
		},
		[onAbort, onFail],
	);

	return (
		<JSErrorBoundary
			id={id}
			packageName={packageName}
			extraEventData={extraEventData}
			fallback={fallback}
			teamName={teamName}
			onError={handleError}
		>
			{isNotSSR && <SidebarStart />}
			{children}
		</JSErrorBoundary>
	);
};
