import React from 'react';
import { lazy } from 'react-loosely-lazy';
import { Box, xcss } from '@atlaskit/primitives';
import Placeholder from '@atlassian/jira-placeholder';
import type { CookieConsentBanner } from './banner';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyCookieConsentBanner = lazy<typeof CookieConsentBanner>(() =>
	import(/* webpackChunkName: "async-cookie-consent-banner" */ './banner').then(
		({ CookieConsentBanner }) => CookieConsentBanner,
	),
);

export const CookieConsentBannerAsync = () => (
	<Box xcss={boxStyles}>
		<Placeholder name="async-cookie-consent-banner" fallback={null}>
			<LazyCookieConsentBanner />
		</Placeholder>
	</Box>
);

const boxStyles = xcss({
	backgroundColor: 'elevation.surface.sunken',
	height: '100%',
});
