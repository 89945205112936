import type { CSSFn } from '@atlaskit/menu';
import type { LegacyEmotionCssObject, NavigationTheme } from '../../../../../types';

export const generateBackButtonCssFn =
	(theme: NavigationTheme | null): CSSFn =>
	(): LegacyEmotionCssObject => {
		if (theme?.navigation) {
			const { icon } = theme.navigation;

			return {
				'& [data-item-elem-before], &:active span[data-item-elem-before]': {
					color: icon?.secondary?.color?.active ?? undefined,
				},
			};
		}

		return {};
	};
