import { GraphQLErrors } from '@atlassian/jira-business-graphql-errors';
import { log } from '@atlassian/jira-common-util-logging';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { performPostRequest } from '@atlassian/jira-fetch';
import { PROJECT_PAGE_SIZE } from '../../constants';
import type { NavigationResourceType } from '../../types';
import type { NavigationQueryResponse } from './types';
import { transformData } from './utils';

const EMPTY_RESPONSE: NavigationResourceType = {
	recents: [],
	favorites: [],
	hasMoreFavorite: false,
	overviews: [],
	overviewsError: null,
	isPremiumUserSeat: false,
	showPlansOnboarding: false,
	shouldHideOverviews: false,
};

type FetchNavigationParams = {
	cloudId: string;
	projectKey?: string;
	projectId?: string;
};

export const fetchNavigation = async (query: string, params: FetchNavigationParams) => {
	try {
		const response = await performPostRequest<NavigationQueryResponse>('/gateway/api/graphql', {
			body: JSON.stringify({
				query,
				variables: {
					first: PROJECT_PAGE_SIZE,
					...params,
				},
			}),
			headers: {
				'content-type': 'application/json',
				'X-ExperimentalApi': 'projectStyle',
			},
		});

		// checking only for undefined, as null is a valid value (not applicable)
		if (response.data?.jira?.navigation === undefined) {
			throw new GraphQLErrors(response.errors);
		}

		let areOverviewsGone = false;
		if (Array.isArray(response.errors)) {
			// Overviews have been migrated to plans and should no longer be shown to the user
			areOverviewsGone = response.errors.some((error) => error.extensions?.statusCode === 410);
		}

		return transformData(response.data.jira.navigation, areOverviewsGone);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		log.safeErrorWithoutCustomerData(
			'jiraRouterResourcesBusinessNavigation',
			'Something went wrong while fetching the navigation items',
		);

		const isClientError = error?.statusCode >= 400 && error?.statusCode < 500;
		if (!isClientError) {
			fireErrorAnalytics({
				meta: {
					id: 'navigationResource',
					packageName: 'jiraRouterResourcesBusinessNavigation',
					teamName: 'wanjel',
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});
		}
	}

	return EMPTY_RESPONSE;
};
