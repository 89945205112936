import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common';
import { getNavigationSidebarGlobalSettingsResources } from '@atlassian/jira-navigation-apps-resources';
import AsyncSidebarGlobalSettingsApps from '@atlassian/jira-navigation-apps-sidebar-global-settings-apps/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { adminOauthCredentialsResource } from '@atlassian/jira-router-resources-admin-oauth-credentials';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { settingsOauthCredentialsRoute } from '@atlassian/jira-router-routes-settings-oauth-credentials-routes';
import AdminOAuthCredentials, { LazyOAuthCredentialsAdmin } from './ui';

export const settingsOauthCredentialsRouteEntry = createEntry(settingsOauthCredentialsRoute, {
	component: AdminOAuthCredentials,
	layout: globalSettingsLayout,

	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_APPS_SETTINGS,
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarGlobalSettingsApps,
	},

	resources: [
		...getNavigationResources(),
		...getNavigationSidebarGlobalSettingsResources(),
		navigationSidebarGlobalResource,
		adminOauthCredentialsResource,
	],

	forPaint: [LazyAtlassianNavigation, LazyOAuthCredentialsAdmin],
	ufoName: 'admin.oauth-credentials',
});
