import React from 'react';
import { Consumer as ItemManagersConsumer } from '@atlassian/jira-providers-item-managers/src';
import { ImperativeAPI as ImperativeAPIWrapped } from './controllers';

const ImperativeAPI = () => (
	<ItemManagersConsumer>
		{({ getManager }) => <ImperativeAPIWrapped getItemManager={getManager} />}
	</ItemManagersConsumer>
);

export { ImperativeAPI };
