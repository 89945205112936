import React from 'react';
import { Flex, Box, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

export const Skeleton = () => (
	<>
		<Flex gap="space.100" alignItems="center">
			<Box xcss={backButton} />
			<Box xcss={headingSkeleton} />
		</Flex>

		<Stack space="space.100" grow="hug">
			<Box xcss={menuItem} />
			<Box xcss={menuItem} />
		</Stack>
	</>
);

const backButton = xcss({
	backgroundColor: 'color.skeleton',
	width: token('space.300'),
	height: token('space.300'),
	borderRadius: 'border.radius.100',
});

const headingSkeleton = xcss({
	backgroundColor: 'color.skeleton',
	height: token('space.300'),
	borderRadius: 'border.radius.100',
	flex: 1,
});

const menuItem = xcss({
	backgroundColor: 'color.skeleton',
	height: token('space.400'),
	borderRadius: 'border.radius.100',
});
