import { useEffect } from 'react';
import { useAccountId } from '@atlassian/jira-tenant-context-controller';
import cacheScope from '../common/utils/scope';

const CacheRegistry = () => {
	const accountIdLoggedInUser = useAccountId();

	useEffect(() => {
		cacheScope.set(accountIdLoggedInUser ?? 'anonymous');
	}, [accountIdLoggedInUser]);

	return null;
};

export default CacheRegistry;
