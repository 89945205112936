/* eslint-disable jira/react-magnetic-di/no-unneeded */
import React, { Fragment } from 'react';
import { di } from 'react-magnetic-di';
import { BreadcrumbManager } from '@atlassian/jira-breadcrumbs';
import { LayoutManager } from '@atlassian/jira-layout-controller';
import { ClientUiManagers as ClientUiManagersComponent } from './client-ui-managers';
import type { UiManagersProps } from './types';

let ClientUiManagers = Fragment;
if (!__SERVER__) {
	// @ts-expect-error - TS2741 - Property '$$typeof' is missing in type '({ children }: ClientUiManagersProps) => JSX.Element' but required in type 'ExoticComponent<{ children?: ReactNode; }>'.
	ClientUiManagers = ClientUiManagersComponent;
}

/**
 * If your component or data provider is not being used inside SSR,
 * put it into `NonCriticalUiManager`, only browser will run those
 * code.
 */

export const UiManagers = ({ children }: UiManagersProps) => {
	di(ClientUiManagers);

	return (
		<LayoutManager>
			<BreadcrumbManager>
				<ClientUiManagers>{children}</ClientUiManagers>
			</BreadcrumbManager>
		</LayoutManager>
	);
};
