import {
	createRouterSelector,
	useRouter,
	type Route,
	type EntireRouterState,
	// @ts-expect-error - TS2305 - Module '"@atlassian/jira-router"' has no exported member 'InvariantRoute'.
	type InvariantRoute,
} from 'react-resource-router';
import { getPreviousRouterState, type RouterState } from './common/utils';

export { usePathParam, useQueryParam } from 'react-resource-router';

export {
	setMatchedRoute,
	getMatchedRoute,
	getPreviousSubProduct,
	setPreviousRouterState,
	getRouteName,
	getUserLocation,
	routeToUrlPath,
	setSubProduct,
	setPreviousSubProduct,
} from './common/utils';

export const useCurrentRoute = createRouterSelector<Route>((state) => state.route);

// Use this only in situations where directly importing the route would cause a circular dependency
export const useRouteByName = createRouterSelector<Route | null, string>(
	(state, routeName) =>
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		(state as EntireRouterState).routes.find(({ name }) => name === routeName) ?? null,
);

export const toInvariantRoute = ({
	name,
	path,
	query = undefined,
	exact = undefined,
}: Route): InvariantRoute => ({ name, path, query, exact });

export const usePreviousRouterState = (): RouterState | null => {
	// Force updates whenever the current route changes
	useRouter();

	return getPreviousRouterState();
};

export type { RouterState };
