import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info';
import {
	routeGroupsProjectSettingsServicedeskCustomerNotificationsClassicRoute,
	routeGroupsProjectSettingsServicedeskNotificationsCustomerNotificationsRoute,
} from '@atlassian/jira-router-routes-project-settings-service-desk-customer-notifications-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import { getSettingsNavigationResources, SERVICE_DESK_PRODUCT } from './ui/product-routes/common';
import ServiceDeskCustomerNotifications, {
	LazyProjectSettingsServiceDeskCustomerNotifications,
} from './ui/service-desk-customer-notifications';

export { ServiceDeskCustomerNotifications, LazyProjectSettingsServiceDeskCustomerNotifications };

export const customerNotificationsSettingsRouteEntries: Route[] = [
	createEntry(routeGroupsProjectSettingsServicedeskCustomerNotificationsClassicRoute, {
		component: ServiceDeskCustomerNotifications,
		layout: serviceProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],

		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskCustomerNotifications],
	}),
	createEntry(routeGroupsProjectSettingsServicedeskNotificationsCustomerNotificationsRoute, {
		component: ServiceDeskCustomerNotifications,
		layout: serviceProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],

		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskCustomerNotifications],
	}),
];
