export type CmdbObjectId = string;
export type CmdbObjectTypeId = string;
export type ObjectCommentId = string;
export type WorkspaceId = string;
export type SchemaId = string;
/** @deprecated assets-rearch-deprecate-attribute-value-id_ykukg */
export type AttributeId = string;
export type ObjectTypeAttributeId = string;
export type ImportSourceId = string;

export const toSchemaId = (id: string): SchemaId => id;
export const toCmdbObjectId = (id: string): CmdbObjectId => id;
export const toCmdbObjectTypeId = (id: string): CmdbObjectTypeId => id;
export const toObjectCommentId = (id: string): ObjectCommentId => id;
export const toWorkspaceId = (id: string): WorkspaceId => id;
/** @deprecated assets-rearch-deprecate-attribute-value-id_ykukg */
export const toAttributeId = (id: string): AttributeId => id;
export const toObjectTypeAttributeId = (id: string): ObjectTypeAttributeId => id;

export type SortDirection = 'ASC' | 'DESC';

export type CommentRoleType = 'users' | 'administrators' | 'managers' | 'developers' | 'unknown';

export type PaginatedListResponse<T> = {
	startAt: number;
	maxResults: number;
	total: number;
	isLast: boolean;
	values: T[];
};

export type FilterTarget =
	| {
			type: 'attribute';
			objectTypeAttributeId: ObjectTypeAttributeId;
	  }
	| {
			type: 'object-type';
	  };
