import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { composeLayouts } from '@atlassian/jira-route-layout';
import type { RouteContext } from '@atlassian/jira-router';
import { newIssueViewLockInStatusResource } from '@atlassian/jira-router-resources-classic-projects';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info';
import {
	getQueuesResources,
	getKnowledgeBaseResources,
	getInsightResources,
} from '@atlassian/jira-router-resources-service-desk-common';
import { LazyIssueApp } from '@atlassian/jira-router-routes-common';
import {
	customQueueRoute,
	customPracticeQueueRoute,
} from '@atlassian/jira-router-routes-servicedesk-queue-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import type ServicedeskQueuesAgentAndIssueViewType from '@atlassian/jira-spa-apps-servicedesk-queues-agent-and-issue-view';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

const LazyServicedeskQueuesAgentAndIssueView = lazyForPaint<
	typeof ServicedeskQueuesAgentAndIssueViewType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-servicedesk-queues-agent-and-issue-view", jiraSpaEntry: "async-servicedesk-queues-agent-and-issue-view" */ '@atlassian/jira-spa-apps-servicedesk-queues-agent-and-issue-view'
		),
	),
);

type Props = RouteContext;

export const CustomQueueRouteComponent = ({ match }: Props) => {
	const queueId = (match.params && match.params.queueId) || '';
	const issueKey = (match.params && match.params.issueKey) || '';
	const oldIssueView = `/${(match.query && match.query.oldIssueView) || ''}`;
	return (
		<LazyPage
			Page={LazyServicedeskQueuesAgentAndIssueView}
			pageId={`spa-apps/servicedesk/queues/${queueId}/${issueKey}/${oldIssueView}`}
			shouldShowSpinner
		/>
	);
};

export const customQueueRouteEntry = createEntry(customQueueRoute, {
	query: ['oldIssueView?'],
	exact: true,
	component: CustomQueueRouteComponent,
	layout: composeLayouts(
		serviceProjectLayout,
		embeddedIssuePreloadLayoutBuilder([{ params: [{ key: 'issueKey' }] }]),
	),
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
	},
	resources: [
		...getQueuesResources(),
		...getInsightResources(),
		newIssueViewLockInStatusResource,
		getConsolidationStateResource(),
	],
	afterPaint: [LazyIssueApp],
	forPaint: [LazyAtlassianNavigation, LazyServicedeskQueuesAgentAndIssueView],
});

export const customPracticeQueueRouteEntry = createEntry(customPracticeQueueRoute, {
	query: ['oldIssueView?'],
	exact: true,
	component: CustomQueueRouteComponent,
	layout: composeLayouts(
		serviceProjectLayout,
		embeddedIssuePreloadLayoutBuilder([{ params: [{ key: 'issueKey' }] }]),
	),
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
	},
	resources: [
		...getQueuesResources(),
		...getKnowledgeBaseResources(),
		...getInsightResources(),
		newIssueViewLockInStatusResource,
		getConsolidationStateResource(),
	],
	afterPaint: [LazyIssueApp],
	forPaint: [LazyAtlassianNavigation, LazyServicedeskQueuesAgentAndIssueView],
});
