import { configurationResource } from '@atlassian/jira-router-resources-business-configuration';
import { fieldsResource } from '@atlassian/jira-router-resources-business-fields';
import { navigationResource } from '@atlassian/jira-router-resources-business-navigation';
import {
	navigationItemsResourceWithCondition,
	navigationItemsResourceOldWithCondition,
} from '@atlassian/jira-router-resources-business-navigation-items';
import { overviewDetailsResource } from '@atlassian/jira-router-resources-business-overview-details';
import { projectDetailsResource } from '@atlassian/jira-router-resources-business-project-details';
import { storageDetailsResource } from '@atlassian/jira-router-resources-business-storage-details';
import {
	getNavigationSidebarProjectResource,
	getNavigationResources,
} from '@atlassian/jira-router-resources-navigation';
import { projectCreatePermissionsResource } from '@atlassian/jira-router-resources-project-permissions';

const commonResources = [projectCreatePermissionsResource, navigationResource];

export const getBusinessProjectResources = () => [
	...commonResources,
	projectDetailsResource,
	storageDetailsResource,
	navigationItemsResourceWithCondition,
	navigationItemsResourceOldWithCondition,
];

export const getBusinessOverviewResources = () => [...commonResources, overviewDetailsResource];

export const getProjectViewResources = () => [
	...getBusinessProjectResources(),
	...getNavigationResources(),
	...getNavigationSidebarProjectResource(),
	configurationResource,
	fieldsResource,
];

export const getOverviewViewResources = () => [
	...getBusinessOverviewResources(),
	...getNavigationResources(),
	configurationResource,
	fieldsResource,
];
