import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const projectSettingsVersionsRoute = {
	group: ROUTE_GROUPS.COMPONENTS,
	name: ROUTE_NAMES.PROJECT_SETTINGS_VERSIONS,
	path: '/plugins/servlet/project-config/:projectKey/administer-versions',
};
