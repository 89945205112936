/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { AppSkeletonImage } from '../../common/ui';
import imgSVG from './assets/project-list-sidebar-app-skeleton.tpl.svg';

const customCssStyles = css({
	borderLeftWidth: 0,
	borderRightWidth: 0,
});

const sidebarCssStyles = css({
	height: '100%',
	width: '360px',
	padding: `${token('space.300', '24px')}`,
	borderLeft: '1px solid',
	borderColor: `${token('color.border', '#DFE1E6')}`,
	backgroundColor: `${token('elevation.surface', '#FFFFFF')}`,
	boxSizing: 'border-box',
});

export const ProjectListSidebarSkeleton = () => (
	<div css={sidebarCssStyles}>
		<AppSkeletonImage src={imgSVG} css={customCssStyles} />
	</div>
);
