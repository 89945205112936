import type { ConfigurationResource, Field } from '../../types';
import type { Response } from './types';

export const transformData = (response: Response): ConfigurationResource => {
	let rankField: Field | null = null;
	const rankFieldNode = response.data?.jira?.rankField;

	if (rankFieldNode != null) {
		rankField = {
			id: rankFieldNode.fieldId,
			name: rankFieldNode.name,
		};
	}

	let startDateField: Field | null = null;
	const startDateFieldNode = response.data?.jira?.startDateField;

	if (startDateFieldNode != null) {
		startDateField = {
			id: startDateFieldNode.fieldId,
			name: startDateFieldNode.name,
		};
	}

	const isLinkingEnabled = (response.data?.jira?.issueLinkTypes?.totalCount ?? 0) !== 0;

	const isSubtasksEnabled = response.data?.jira?.isSubtasksEnabled ?? false;

	return {
		rankField,
		startDateField,
		isLinkingEnabled,
		isSubtasksEnabled,
	};
};
