import { createResource } from '@atlassian/jira-router';
import { RESOURCE_PERSISTENT_ISSUE_CREATE_MODAL_VIEW } from '../../common/constants';
import type { PersistentIssueCreateModalViewResource } from '../types';

export const persistentIssueCreateModalViewResource =
	createResource<PersistentIssueCreateModalViewResource>({
		type: RESOURCE_PERSISTENT_ISSUE_CREATE_MODAL_VIEW,
		getKey: () => 'state',
		getDataLoader: () =>
			import(
				/* webpackChunkName: "async-router-resources-persistent-issue-create-modal-view", jiraSpaEntry: "async-router-resources-persistent-issue-create-modal-view" */ './get-data'
			),
		maxAge: Number.MAX_VALUE,
	});
