import { performGetRequest } from '@atlassian/jira-fetch';
import { getErrorType } from '@atlassian/jira-forge-ui-analytics';
import { DATA_CLASSIFICATION_NO_ACCESS } from '@atlassian/jira-forge-ui-analytics/src/common/utils/get-error-type';
import { fireInitializationFetchFailedEvent } from '@atlassian/jira-forge-ui-analytics/src/services/fetch-modules';
import type { Source } from '@atlassian/jira-forge-ui-types';
import {
	hasIssueKey,
	hasPrefetchedData,
	hasProjectIdOrKey,
	type DataClassificationProps,
	type DeferredDataClassificationForIssue,
	type DeferredDataClassificationForProject,
} from '../../../types';
import { DataClassificationTagsCache, TTL, issueKeyPrefix, projectKeyPrefix } from './constants';

const handleError = (err: unknown, source: Source) => {
	const errorMessage = err instanceof Error ? err.message : String(err);
	const error = Error(`Data classification request failed: ${errorMessage}`);

	const errorType = getErrorType(error);

	// prevent fetching extensions in case of no access
	if (errorType === DATA_CLASSIFICATION_NO_ACCESS) {
		throw error;
	}

	fireInitializationFetchFailedEvent(source, {
		isSSR: __SERVER__,
		errorType,
		error,
	});

	// otherwise, optimistically fetch the extensions
	return [];
};

type DataClassificationRequestReturn = {
	tags: Array<string>;
};

async function getDataClassificationIssue(
	props: DeferredDataClassificationForIssue,
	source: Source,
) {
	try {
		const now = Date.now();
		const cachedResult = DataClassificationTagsCache.get(`${issueKeyPrefix}-${props.issueKey}`);

		const isCacheResultTimePassed = Boolean(cachedResult && TTL < now - cachedResult.timestamp);

		if (isCacheResultTimePassed || !cachedResult) {
			const data = await performGetRequest<DataClassificationRequestReturn>(
				`/rest/internal/3/data-classification/resource/issue/${props.issueKey}`,
			);

			DataClassificationTagsCache.set(`${issueKeyPrefix}-${props.issueKey}`, {
				tags: data.tags,
				timestamp: now,
			});

			return data.tags;
		}

		return cachedResult.tags;
	} catch (err) {
		return handleError(err, source);
	}
}

async function getDataClassificationProject(
	props: DeferredDataClassificationForProject,
	source: Source,
) {
	try {
		const now = Date.now();
		const cachedResult = DataClassificationTagsCache.get(
			`${projectKeyPrefix}-${props.projectIdOrKey}`,
		);

		const isCacheResultTimePassed = Boolean(cachedResult && TTL < now - cachedResult.timestamp);

		if (isCacheResultTimePassed || !cachedResult) {
			const data = await performGetRequest<DataClassificationRequestReturn>(
				`/rest/internal/3/data-classification/resource/container/${props.projectIdOrKey}`,
			);
			DataClassificationTagsCache.set(`${projectKeyPrefix}-${props.projectIdOrKey}`, {
				tags: data.tags,
				timestamp: now,
			});
			return data.tags;
		}

		return cachedResult.tags;
	} catch (err) {
		return handleError(err, source);
	}
}

export const getDataClassification = async (
	props: DataClassificationProps,
	source: Source,
): Promise<string[]> => {
	if (props !== null) {
		if (hasPrefetchedData(props)) {
			return props.tags;
		}

		if (hasProjectIdOrKey(props)) {
			const tags = await getDataClassificationProject(props, source);
			return tags;
		}

		if (hasIssueKey(props)) {
			const tags = await getDataClassificationIssue(props, source);
			return tags;
		}
	}

	return [];
};
