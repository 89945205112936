import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { useIsCrossProjectViewsEnabled } from '@atlassian/jira-polaris-lib-entitlement-utils';
import { Redirect } from '@atlassian/jira-router';
import type PolarisCollectionsPageType from '@atlassian/jira-spa-apps-polaris-collections';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyCollectionsPage = lazyForPaint<typeof PolarisCollectionsPageType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-polaris-collections", jiraSpaEntry: "async-polaris-collections" */ '@atlassian/jira-spa-apps-polaris-collections'
			),
		),
	{ ssr: false },
);

const useJpdLicensed = () => {
	const context = useTenantContext();
	return context.licensedProducts['jira-product-discovery'];
};

export const RedirectingCollectionsPage = () => {
	const jpdLicensed = useJpdLicensed();
	const crossProjectViewsEnabled = useIsCrossProjectViewsEnabled();

	if (!jpdLicensed) {
		return <Redirect to="/jira/polaris/unlicensed" />;
	}

	if (!crossProjectViewsEnabled) {
		return <Redirect to="/jira" />;
	}

	return (
		<LazyPage
			Page={LazyCollectionsPage}
			pageId="async-polaris-collections"
			shouldShowSpinner={false}
		/>
	);
};

export default RedirectingCollectionsPage;
