import { SESSION_STORAGE_KEY } from './constants';

export const getOptInModalStatus = () =>
	JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY) || 'false');

export const setOptInModalStatus = (value: boolean) =>
	sessionStorage.setItem(SESSION_STORAGE_KEY, String(value));

export const getCohortStatusUrl = (cloudId: string): string =>
	`/gateway/api/migrations/c2c/v1/change-management/cloud-id/${cloudId}/status`;
