import React, { memo } from 'react';
import { styled } from '@compiled/react';
import Spinner, { type SpinnerProps } from '@atlaskit/spinner';
import { Tokens } from '@atlassian/jira-custom-theme-constants';

export const ThemedSpinner = memo<SpinnerProps>((props: SpinnerProps) => (
	<Wrapper>
		<Spinner size="large" {...props} testId="business-theme-components.ui.themed-spinner" />
	</Wrapper>
));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-icon-subtle': Tokens.COLOR_TEXT_SUBTLE,
});
