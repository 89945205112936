import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { servicedeskItsmSampleSpaceRoute } from '@atlassian/jira-router-routes-servicedesk-itsm-sample-space-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import { APP_NAMES, toPackageName } from '@atlassian/jira-servicedesk-common/src/utils/app-names';
import type ServiceDeskItsmSampleSpaceType from '@atlassian/jira-spa-apps-servicedesk-itsm-sample-space';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyItsmSampleSpace = lazyForPaint<typeof ServiceDeskItsmSampleSpaceType>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-itsm-sample-space", jiraSpaEntry: "async-servicedesk-itsm-sample-space" */ '@atlassian/jira-spa-apps-servicedesk-itsm-sample-space'
		),
);

export const ItsmSampleSpace = () => (
	<LazyPage
		Page={LazyItsmSampleSpace}
		pageId="spa-apps/servicedesk/itsm-sample-space"
		shouldShowSpinner
	/>
);

export const servicedeskItsmSampleSpaceRouteEntry = createEntry(servicedeskItsmSampleSpaceRoute, {
	isRedirect: true,
	component: ItsmSampleSpace,
	layout: serviceProjectLayout,
	resources: [...getNavigationResources(), ...getNavigationSidebarProjectResource()],
	forPaint: [LazyAtlassianNavigation, LazyItsmSampleSpace],
	perfMetricKey: 'jsm-sample-space-landing-page',

	meta: {
		reporting: {
			id: APP_NAMES.ITSM_SAMPLE_SPACE,
			packageName: toPackageName(APP_NAMES.ITSM_SAMPLE_SPACE),
			teamName: 'itops-mithril',
		},
	},
});
