// eslint-disable-next-line jira/import-whitelist
import { announcementBannerSettingsV4Resource } from '@atlassian/jira-admin-pages-system-ui-announcement-banner-resources';
import { ff, getMultivariateFeatureFlag } from '@atlassian/jira-feature-flagging';
// eslint-disable-next-line jira/import-whitelist
import {
	forgeProjectModuleResource,
	forgeProjectSettingsModuleResource,
	sidebarNav4Resource,
	sidebarNav4UiStateResource,
	staticJSWUserBoardSidebarNavigationResource,
	staticPlanSidebarNavigationResource,
	staticProjectSettingsSidebarConnectAddonsNavigationResource,
	staticProjectSidebarConnectAddonsNavigationResource,
	staticProjectSidebarNavigationResource,
	staticSidebarShortcutsResource,
	staticSoftwareProjectHorizontalNavResource,
} from '@atlassian/jira-navigation-apps-resources';
// eslint-disable-next-line jira/import-whitelist
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout';
// eslint-disable-next-line jira/import-whitelist
import { navigationPlansVisibilityResource } from '@atlassian/jira-plans-item-visibility';
import { planSidebarNavigationOptInStatusResources } from '@atlassian/jira-router-resources-advanced-roadmaps-nav';
import { consentBannerUserPreferencesResource } from '@atlassian/jira-router-resources-cookie-consent';
import { checkApiKeyManagementKeysResource } from '@atlassian/jira-router-resources-eoc-api-key-management';
import { consolidationUserStateResource } from '@atlassian/jira-router-resources-eoc-og-consolidation-user-state';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info';
import { gicSmartFieldsFeatureResource } from '@atlassian/jira-router-resources-gic-smart-fields';
import { gicOptInStatusResources } from '@atlassian/jira-router-resources-global-issue-create-switch';
import { incomingMailServerExpiryStatusResource } from '@atlassian/jira-router-resources-incoming-mail-expiry-status';
import { issueTransitionChangeboardingPreferencesResource } from '@atlassian/jira-router-resources-issue-transition-changeboarding-preferences';
import { issueTransitionOptInStatusResource } from '@atlassian/jira-router-resources-issue-transition-labs-opt-in';
import { organizationIdResource } from '@atlassian/jira-router-resources-navigation-org-id';
import { navigationSidebarAutomationLegacyEnabledResource } from '@atlassian/jira-router-resources-navigation-sidebar-automation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { navigationUiStateResource } from '@atlassian/jira-router-resources-navigation-ui-state';
import { newIssueViewTransitionLockInStatusResource } from '@atlassian/jira-router-resources-new-issue-view-transition-lock-in-status';
import { persistentIssueCreateModalViewResource } from '@atlassian/jira-router-resources-persistent-issue-create-modal-view';
import {
	projectContextResource,
	projectContextResourceWithCache,
} from '@atlassian/jira-router-resources-project-context';
import { rovoEntitlementResource } from '@atlassian/jira-router-resources-rovo-entitlement';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference';
import { userHomepageResource } from '@atlassian/jira-router-resources-user-homepage';
import {
	resourceWithCondition,
	resourceWithCondition2,
} from '@atlassian/jira-router-resources-utils';

const getNewIssueTransitionExperienceInJiraLabsVariation = () =>
	getMultivariateFeatureFlag('jira-issue-transition-screen-modernisation-toggle', 'OFF', [
		'OFF',
		'BY_DEFAULT_USER_OPT_IN',
		'BY_DEFAULT_USER_OPT_OUT',
	]);

const isModernIssueTransitionSettingEnabled = () => {
	const variation = getNewIssueTransitionExperienceInJiraLabsVariation();
	return variation !== 'OFF';
};

const OFF = 'Off';
const EAP = 'EAP';
const GA = 'GA';

const getPlanSidebarNavigationJiraLabsVariation = () =>
	getMultivariateFeatureFlag('com.atlassian.rm.jpo.sidebar-navigation-jira-labs-variations', OFF, [
		OFF,
		EAP,
		GA,
	]);

const isPlanSidebarNavigationEnabled = () => {
	const variation = getPlanSidebarNavigationJiraLabsVariation();
	return variation === EAP || variation === GA;
};

const conditionalGicOptInStatusResource = resourceWithCondition(
	() => ff('project.config.pce.modern.global-issue-create'),
	gicOptInStatusResources,
);

const conditionalPlanNavigationSidebarOptInStatusResource = resourceWithCondition(
	isPlanSidebarNavigationEnabled,
	planSidebarNavigationOptInStatusResources,
);

const conditionalIssueTransitionOptInStatusResource = resourceWithCondition(
	isModernIssueTransitionSettingEnabled,
	issueTransitionOptInStatusResource,
);

const conditionalGicSmartFieldsOptoutResource = resourceWithCondition2(
	() => ff('gic-smart-fields-jira-labs_8uwvr'),
	gicSmartFieldsFeatureResource,
);

const conditionalConsentBannerUserPreferencesResource = resourceWithCondition2(
	() => ff('platform.moonjelly.browser-storage-controls-v2'),
	consentBannerUserPreferencesResource,
);

const conditionalIncomingMailServerExpiryStatusResource = resourceWithCondition2(
	() => ff('jira-banner-for-incoming-mail-expiry-notification_y2w1w'),
	incomingMailServerExpiryStatusResource,
);

export const getModernIssueTransitionResources = () => [
	conditionalIssueTransitionOptInStatusResource,
	issueTransitionChangeboardingPreferencesResource,
];

// There is a best effort duplicate removal in the `getRoutes` function but it
// only works if the resources have the same instance. b/c resourceWithCondition2
// will create a new reference each time it is run, by keeping this outside the
// route factory, we can ensure that the duplicate removal works when it is used
// in multiple places.
const dedupableConditionalResources = [
	resourceWithCondition2(
		() => ff('opsgenie-consolidation-transitioning-state_d7vm9'),
		consolidationUserStateResource,
	),
	resourceWithCondition2(getWillShowNav4, sidebarNav4Resource),
	resourceWithCondition2(getWillShowNav4, sidebarNav4UiStateResource),
];

export const getNavigationResources = () => [
	navigationUiStateResource,
	navigationPlansVisibilityResource,
	userHomepageResource,
	conditionalGicOptInStatusResource,
	announcementBannerSettingsV4Resource,
	conditionalConsentBannerUserPreferencesResource,
	...dedupableConditionalResources,
	organizationIdResource,
	themePreferenceResource,
	...getModernIssueTransitionResources(),
	conditionalPlanNavigationSidebarOptInStatusResource,
	persistentIssueCreateModalViewResource,
	conditionalGicSmartFieldsOptoutResource,
	conditionalIncomingMailServerExpiryStatusResource,
	rovoEntitlementResource,
];

export const getUserBoardNavigationResources = () => [staticJSWUserBoardSidebarNavigationResource];

export const getNavigationSidebarProjectResource = () => [
	incidentExperimentTraitResource,
	projectContextResource,
	forgeProjectModuleResource,
	staticSidebarShortcutsResource,
	staticProjectSidebarNavigationResource,
	staticProjectSidebarConnectAddonsNavigationResource,
	staticSoftwareProjectHorizontalNavResource,
];

export const getNavigationSidebarPlanResources = () => [
	resourceWithCondition2(isPlanSidebarNavigationEnabled, staticPlanSidebarNavigationResource),
];

export const getNavigationSidebarProductsSettingResource = () => [
	navigationSidebarGlobalResource,
	getConsolidationStateResource(),
	resourceWithCondition2(
		() => ff('eoc-api-key-management-feature-jira_8ox4z'),
		checkApiKeyManagementKeysResource(),
	),
];

export const getNavigationSidebarProjectSettingsResource = () => [
	newIssueViewTransitionLockInStatusResource,
	navigationSidebarAutomationLegacyEnabledResource,
	projectContextResourceWithCache,
	forgeProjectSettingsModuleResource,
	staticProjectSettingsSidebarConnectAddonsNavigationResource,
];
