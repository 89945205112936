import type { Epic } from 'redux-observable';
import initiateStore from '@atlassian/jira-common-tangerine/src/state/initiate';
import rootReducer from './reducer';
import type { Action, State } from './types';

export const createStore = (rootEpic?: Epic<Action, State>) =>
	initiateStore<State, Action, undefined>({
		appName: 'flags',
		rootReducer,
		rootEpic,
	});
