/**
 * @generated SignedSource<<122633496c89d816800ac0cc7ad197d8>>
 * @relayHash 1fff16012e9e82d351a4809512dd028b
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 45bdb9c30dff6291e9de65ae33f52f1c1b2c01aacaf0e50196e285c9c83f41d7

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcVirtualAgentViewIntentQuery } from './srcVirtualAgentViewIntentQuery.graphql';

const node: PreloadableConcreteRequest<srcVirtualAgentViewIntentQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "45bdb9c30dff6291e9de65ae33f52f1c1b2c01aacaf0e50196e285c9c83f41d7",
    "metadata": {},
    "name": "srcVirtualAgentViewIntentQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
