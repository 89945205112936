import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ProjectSettingsServiceDeskSlaSettingsType from '@atlassian/jira-spa-apps-project-settings-service-desk-sla-settings';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import UFOSegment from '@atlassian/jira-ufo-segment';

export const LazyProjectSettingsServiceDeskSlaSettings = lazyForPaint<
	typeof ProjectSettingsServiceDeskSlaSettingsType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-project-settings-service-desk-sla-settings", jiraSpaEntry: "async-project-settings-service-desk-sla-settings" */ '@atlassian/jira-spa-apps-project-settings-service-desk-sla-settings'
		),
	),
);

export const ServiceDeskSlaSettings = () => (
	<UFOSegment name="project-settings-service-desk-sla-settings">
		<LazyPage
			Page={LazyProjectSettingsServiceDeskSlaSettings}
			pageId="project-settings-service-desk-sla-settings"
			shouldShowSpinner
		/>
	</UFOSegment>
);
