import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { useRouter, Redirect } from '@atlassian/jira-router';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const OnCallRedirect = () => {
	const [routeContext] = useRouter();
	const redirectionUrl = `${routeContext.location.pathname}/on-call`;
	return <Redirect to={redirectionUrl} />;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LazyOnCall = lazyForPaint<any>(
	() =>
		import(
			/* webpackChunkName: "async-jira-eoc-on-call", jiraSpaEntry: "async-jira-eoc-on-call" */ '@atlassian/jira-eoc-on-call'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazyOnCall} pageId="async-jira-eoc-on-call" shouldShowSpinner={false} />
);
