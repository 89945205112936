import {
	type MatcherLocation,
	matchUrlExact,
} from '@atlassian/jira-navigation-apps-sidebar-common';

const APPLICATION_PROPERTIES_PATH_REGEXP = /\/admin\/(\w+)ApplicationProperties/i;

export const isGeneralConfigurationSelected = (
	currentLocation: MatcherLocation,
	itemLocation: MatcherLocation,
): boolean =>
	matchUrlExact()(currentLocation, itemLocation) ||
	APPLICATION_PROPERTIES_PATH_REGEXP.test(currentLocation.pathname);
