import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ProjectRoleActorDetail from '@atlassian/jira-spa-apps-admin-pages-performance-project-role-actor';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyProjectRoleActorDetail = lazyForPaint<typeof ProjectRoleActorDetail>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-system-performance-metric-detail", jiraSpaEntry: "async-system-performance-project-role-actor" */ '@atlassian/jira-spa-apps-admin-pages-performance-project-role-actor'
		),
	),
);

export const ProjectRoleActorMetricDetail = () => (
	<LazyPage
		Page={LazyProjectRoleActorDetail}
		pageId="system-performance-metric-detail-project-role-actor"
		shouldShowSpinner
	/>
);
