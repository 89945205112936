import { useEffect } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import type { UseResourceReturn } from '../types';

/**
 * Call refresh() of the `resource`, to trigger the (initial) data fetch.
 * @param resource
 * @param skipInitFetch - to be able to call this hook conditionally. E.g. for resources without customRouterContext, we don't want to trigger an extra fetch/refresh() call.
 */
export const useInitFetchResource = <TResource,>(
	resource: UseResourceReturn<TResource>,
	skipInitFetch = false,
): UseResourceReturn<TResource> => {
	const { data, loading, error, refresh, ...rest } = resource;

	useEffect(() => {
		if (skipInitFetch) {
			return;
		}

		// have nested the if block as eslint forces fg to be at the end and that makes testing cases difficult.
		if (fg('fix_useinitresource_data_bug')) {
			// "false" is a valid value for data here. We only want to trigger a refresh if data is null or undefined.
			if ((data === null || data === undefined) && !loading && !error && refresh) {
				refresh();
			}
		} else if (!data && !loading && !error && refresh) {
			refresh();
		}
		// we only need this to happen only once at initial time. In addition, some resources return `data` = null when user does not have permissions etc., which makes all `data`, `loading` and `error` have falsy values.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		data,
		loading,
		error,
		refresh,
		...rest,
	};
};
