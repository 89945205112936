import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const issueCreateEmbedRoute = {
	group: ROUTE_GROUPS.ERRORS,
	name: ROUTE_NAMES.ISSUE_CREATE_EMBED,
	path: '/jira/issues/create/embed',
};
