import { useMemo } from 'react';
import memoizeOne from 'memoize-one';
import { useProjectSettingsSidebarConnectNavigation } from '@atlassian/jira-navigation-apps-sidebar-common';
import { useRouter } from '@atlassian/jira-router';
import type { ConnectDataItem } from '../../common/types';
import { findItemAndStack, transformLinkV4 } from '../../common/utils';
import type { UseProjectSettingsConnectItemsReturn } from './types';

// use memoised functions per hook to avoid re-computation between same hooks
const findItemAndStackMemo = memoizeOne(findItemAndStack);

export const useProjectSettingsConnectItems = (): UseProjectSettingsConnectItemsReturn => {
	const [{ route, location }] = useRouter();
	const { data, loading, error } = useProjectSettingsSidebarConnectNavigation();

	const items: ConnectDataItem[] | null = useMemo(
		() => data && data.map(transformLinkV4).filter(Boolean),
		[data],
	);
	const found = useMemo(
		() => route && items && findItemAndStackMemo(items, route.name, location, ['settings']),
		[items, route, location],
	);

	return {
		data: items,
		loading,
		error,
		selected: found && found.selected,
		stack: found && found.stack,
	};
};
