import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const devopsConnectedProjectRoute = {
	group: ROUTE_GROUPS.DEVOPS,
	name: ROUTE_NAMES.DEVOPS_CONNECTED_PROJECT,
	path: '/jira/devops/connected-project',
};
