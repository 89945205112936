/**
 * @generated SignedSource<<8e0a88768282ca9d8738ee82929f912b>>
 * @relayHash 466ba1a48208a307ec7cbcb69189d626
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a54bd1389b8d2966f644c4faa4660ac4e139d4cbde26512693f8cb852cc091a9

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcVirtualAgentBrowseIntentsQuery } from './srcVirtualAgentBrowseIntentsQuery.graphql';

const node: PreloadableConcreteRequest<srcVirtualAgentBrowseIntentsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "a54bd1389b8d2966f644c4faa4660ac4e139d4cbde26512693f8cb852cc091a9",
    "metadata": {},
    "name": "srcVirtualAgentBrowseIntentsQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
