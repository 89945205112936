import {
	SERVICE_DESK,
	SERVICE_DESK_AUTOMATION_SUB_NAV,
	SERVICE_DESK_ISSUES,
	SERVICE_DESK_ITEMS,
	SERVICE_DESK_KNOWLEDGE_HUB,
	SERVICE_DESK_LANGUAGES,
	SERVICE_DESK_LEGACY_TICKET_TYPES,
	SERVICE_DESK_QUEUES,
	SERVICE_DESK_REPORTS,
	SERVICE_DESK_SETTINGS,
	SERVICE_DESK_TRANSLATIONS,
} from '@atlassian/jira-servicedesk-common/src/constants';

const ROOT = 'root/index' as const;
const ISSUES = 'root/issues' as const;
const DASHBOARDS = 'root/dashboards' as const;
const PORTFOLIO = 'root/portfolio' as const;

const WORK_SCRUM = 'work-scrum' as const;
const PLAN_SCRUM = 'plan-scrum' as const;
const WORK_KANBAN = 'work-kanban' as const;
const PLAN_KANBAN = 'plan-kanban' as const;

const PROJECT_ISSUE_NAVIGATOR_MY_OPEN_ISSUES = 'pin-filter-myopenissues' as const;
const PROJECT_ISSUE_NAVIGATOR_REPORTED_BY_ME_ISSUES = 'pin-filter-reportedbyme' as const;
const PROJECT_ISSUE_NAVIGATOR_ALL_ISSUES = 'pin-filter-allissues' as const;
const PROJECT_ISSUE_NAVIGATOR_ALL_OPEN_ISSUES = 'pin-filter-allopenissues' as const;
const PROJECT_ISSUE_NAVIGATOR_DONE_ISSUES = 'pin-filter-doneissues' as const;
const PROJECT_ISSUE_NAVIGATOR_RECENTLY_VIEWED_ISSUES = 'pin-filter-recentlyviewed' as const;
const PROJECT_ISSUE_NAVIGATOR_ADDED_RECENTLY_ISSUES = 'pin-filter-addedrecently' as const;
const PROJECT_ISSUE_NAVIGATOR_RESOLVED_RECENTLY_ISSUES = 'pin-filter-resolvedrecently' as const;
const PROJECT_ISSUE_NAVIGATOR_UPDATED_RECENTLY_ISSUES = 'pin-filter-updatedrecently' as const;

const AGILITY = 'project/agility/index' as const;
const BUSINESS = 'project/business/index' as const;
const OPS = 'project/ops/index' as const;

const SOFTWARE = 'project/software/index' as const;
const SOFTWARE_ISSUES = 'project/software/issues' as const;
const SOFTWARE_REPORTS = 'project/software/reports' as const;

const USER = 'user/index' as const;
const USER_REPORTS = 'user/reports' as const;

const rootProjectViewIds = {
	AGILITY,
	SERVICE_DESK,
	OPS,
	BUSINESS,
	SOFTWARE,
} as const;

const rootViewIds = {
	ROOT,
	ISSUES,
	DASHBOARDS,
	PORTFOLIO,
} as const;

export const itemIds = {
	WORK_SCRUM,
	PLAN_SCRUM,
	WORK_KANBAN,
	PLAN_KANBAN,
	PROJECT_ISSUE_NAVIGATOR_MY_OPEN_ISSUES,
	PROJECT_ISSUE_NAVIGATOR_REPORTED_BY_ME_ISSUES,
	PROJECT_ISSUE_NAVIGATOR_ALL_ISSUES,
	PROJECT_ISSUE_NAVIGATOR_ALL_OPEN_ISSUES,
	PROJECT_ISSUE_NAVIGATOR_DONE_ISSUES,
	PROJECT_ISSUE_NAVIGATOR_RECENTLY_VIEWED_ISSUES,
	PROJECT_ISSUE_NAVIGATOR_ADDED_RECENTLY_ISSUES,
	PROJECT_ISSUE_NAVIGATOR_RESOLVED_RECENTLY_ISSUES,
	PROJECT_ISSUE_NAVIGATOR_UPDATED_RECENTLY_ISSUES,
	...SERVICE_DESK_ITEMS,
} as const;

export const viewIds = {
	...rootViewIds,
	...rootProjectViewIds,
	SOFTWARE_ISSUES,
	SOFTWARE_REPORTS,
	SERVICE_DESK_ISSUES,
	SERVICE_DESK_QUEUES,
	SERVICE_DESK_REPORTS,
	SERVICE_DESK_SETTINGS,
	SERVICE_DESK_LANGUAGES,
	SERVICE_DESK_LEGACY_TICKET_TYPES,
	SERVICE_DESK_TRANSLATIONS,
	SERVICE_DESK_AUTOMATION_SUB_NAV,
	SERVICE_DESK_KNOWLEDGE_HUB,
	USER,
	USER_REPORTS,
} as const;
