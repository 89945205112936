import type { GraphQLError } from 'graphql';
import type { OverviewFragmentResponse } from '@atlassian/jira-business-entity-overview-utils';

export const SUCCESS_RESPONSE_TYPE = 'JiraWorkManagementGiraOverview';

export type OverviewSuccess = {
	__typename: typeof SUCCESS_RESPONSE_TYPE;
} & OverviewFragmentResponse;

export type OverviewError = { __typename: string } & GraphQLError;

export type OverviewDetailsResponse = {
	data?: {
		jira?: {
			overview?: OverviewSuccess | OverviewError | null;
		} | null;
	} | null;
	errors?: ReadonlyArray<GraphQLError>;
};
