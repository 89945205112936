import type { SubscribePayload } from 'graphql-ws';
import {
	type SubscribeFunction,
	Observable,
	type RequestParameters,
	type Variables,
} from 'relay-runtime';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { type SinkError, getClient, getSinkError } from '@atlassian/jira-graphql-subscription';
import { getUserLocation } from '@atlassian/jira-platform-router-utils';

export const subscribe: SubscribeFunction = (
	{ id, name: operationName }: RequestParameters,
	variables: Variables,
) =>
	Observable.create((sink) => {
		const client = getClient();
		if (!client) {
			return sink.error(new Error('Unable to create subscription client'));
		}

		const payload: SubscribePayload = {
			query: '',
			operationName,
			variables,
			extensions: {
				pq: id,
			},
		};

		let requestId: string | null;

		return client.subscribe(payload, {
			...sink,
			next: (value) => {
				// @ts-expect-error agg prop is missing on generic "extensions" type
				const aggRequestId = value?.extensions?.agg?.request_id;
				requestId = aggRequestId ?? requestId;
				// @ts-expect-error incompatible types between two libraries
				sink.next(value);
			},
			error: (sinkError: SinkError) => {
				const errorInfo = getSinkError(sinkError);
				const attributes = { operationName, requestId, userLocation: getUserLocation() };

				sink.error(errorInfo);

				fireErrorAnalytics({
					meta: {
						id: 'subscription',
						packageName: 'jiraRelaySubscription',
						teamName: 'uip-graphql',
					},
					error: errorInfo,
					attributes,
					sendToPrivacyUnsafeSplunk: true,
				});
			},
		});
	});
