import { fetchJson } from '@atlassian/jira-fetch';
import {
	type MatchParams,
	createResource,
	type RouterContext,
	type Query,
} from '@atlassian/jira-router';
import { type CHART_TYPES, AVAILABLE_CHARTS } from '../../common/constants';
import type { SummaryPageDataResponse } from './types';

// we have to use location.search as we want to get params dynamically and rrr doesn't support it
// more info: https://atlassian-labs.github.io/react-resource-router/#/resources/usage?id=accessing-resource-state-for-another-route-or-url
const getQueryParams = (routerQuery: Query) => {
	if (typeof window !== 'undefined') {
		return Object.fromEntries(new URLSearchParams(window.location.search));
	}

	return routerQuery;
};

export const advancedRoadmapsSummaryDataResource = createResource({
	type: 'ADVANCED_ROADMAPS_SUMMARY_DATA',
	getKey: ({ match }: RouterContext) => {
		const { params } = match;

		if (params.planId != null && params.scenarioId != null) {
			return `${params.planId}:${params.scenarioId}`;
		}

		// Just return fallback string if on a page that doesn't have a planId or scenarioId
		return 'none';
	},
	getData: async ({ match, query }: RouterContext): Promise<SummaryPageDataResponse> => {
		if (window.__ARJ_SUMMARY_PAGE_PROMISE__) {
			const response = window.__ARJ_SUMMARY_PAGE_PROMISE__;
			window.__ARJ_SUMMARY_PAGE_PROMISE__ = null;
			return response;
		}

		const { params } = match;

		return fetchAdvancedRoadmapsSummaryData(params, query, AVAILABLE_CHARTS);
	},
	maxAge: Infinity,
	isBrowserOnly: true,
});

export const fetchAdvancedRoadmapsSummaryData = async (
	params: MatchParams,
	query: Query,
	chartTypes: CHART_TYPES[],
): Promise<SummaryPageDataResponse> => {
	if (params.planId == null) {
		throw new Error(
			'Unable to get fetch data for ADVANCED_ROADMAPS_SUMMARY_DATA. planId is not defined',
		);
	}

	const { startDate, endDate } = getQueryParams(query);
	const customDateRangeParams = startDate && endDate ? { startDate, endDate } : {};

	const data = await fetchJson('/rest/jpo/1.0/summary/data', {
		method: 'POST',
		body: JSON.stringify({
			planId: params.planId,
			scenarioId: params.scenarioId,
			chartTypes,
			...customDateRangeParams,
		}),
	});

	return data;
};
