import type { ComponentType } from 'react';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants';
import { composeLayouts, withProjectContext, type Layout } from '@atlassian/jira-route-layout';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import IntentTemplatesSidebar from './ui/right-sidebars';

// TODO Remove this whenever all service project route paths are no longer ambiguous
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withServiceProjectContext = (Component: ComponentType<Record<any, any>>) =>
	withProjectContext(Component, {
		project: {
			style: 'any',
			type: SERVICE_DESK_PROJECT,
		},
	});

export const browseIntentsLayout: Layout = composeLayouts(serviceProjectLayout, {
	rightSidebars: [withServiceProjectContext(IntentTemplatesSidebar)],
});
