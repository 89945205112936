import { loadBridgeBail } from './load-bridge-bail';
import { loadBridgeWeak } from './load-bridge-weak';
import { DEFAULT_TIMEOUT, SHOULD_LOG_ERROR } from './shared';

export type LoadBridgeRaceOptions = {
	/** AMD name of the resource being loaded */
	name: string;
	timeout?: number;
	shouldLogError?: boolean;
};

/**
 * Load an AMD module if the system has already loaded it, otherwise throw.
 * This is a weak load that races the given timeout. When timeout expires it bails.
 * It is only safe to use where there is some previous load of WRM that includes the required AMD
 * module.
 *
 * IMPORTANT - Supports modules with nested definitions of the form given below.
 * ```
 * require(['jquery'], function () {
 *   define('some/bridge', ...);
 * });
 * ```
 * In this case requirejs will `setTimeout(..., 4)` between encountering the `require` and calling
 * the implementation implementation function. This means the defined module is not always
 * immediately available. The failure is generally unlikely but depends on connection speed. Using a
 * nonzero timeout will allow these modules to load more robustly.
 * @throws Error
 */
export const loadBridgeRace = <T,>({
	name,
	timeout = DEFAULT_TIMEOUT,
	shouldLogError = SHOULD_LOG_ERROR,
}: LoadBridgeRaceOptions): Promise<T> =>
	Promise.race([
		loadBridgeWeak<T>({ name }),
		new Promise<void>((resolve) => setTimeout(resolve, timeout)).then(() =>
			loadBridgeBail<T>({ name, shouldLogError }),
		),
	]);
