import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const eocApiKeyManagementRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_API_KEY_MANAGEMENT,
	path: '/jira/settings/products/ops/api-key-management',
	exact: true,
};
