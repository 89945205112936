import { INSIGHTS_PACKAGE } from '@atlassian/jira-insights-common-constants/src/common/constants/package';

export const STORE_NAME = `${INSIGHTS_PACKAGE}.experience-tracker-store`;

export const experiences = {
	RENDER: 'render',
} as const;

export const TIMEOUT_IN_MS = 7000;

export const DEFAULT_HISTOGRAM_BUCKETS = '500_1000_1500_4000_7000';
