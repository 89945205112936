import { createResource } from '@atlassian/jira-router';
import { JSM_EMAIL_DOMAINS_LIST } from './constants';
import type { GetEmailDomainsListResponse } from './services/types';

export const jsmEmailDomainsListResource = createResource<GetEmailDomainsListResponse>({
	type: JSM_EMAIL_DOMAINS_LIST,
	getKey: () => 'email-domains-list',
	isBrowserOnly: true,
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-jsm-email-domains-list", jiraSpaEntry: "async-resource-jsm-email-domains-list" */ './services/get-data'
		),
});
