import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point';
import { ff } from '@atlassian/jira-feature-flagging';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common';
import { servicedeskIssueTypesResource } from '@atlassian/jira-router-resources-service-desk-issue-types';
import { ErrorPagesNotFound } from '@atlassian/jira-router-routes-common';
import { gspPageRoute } from '@atlassian/jira-router-routes-servicedesk-getting-started-page-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import { GettingStartedPageSkeleton } from '@atlassian/jira-skeletons';
import { gettingStartedEntryPoint } from '@atlassian/jira-spa-apps-servicedesk-getting-started-page/entrypoint';

const gettingStartedPageEntryPoint = createPageEntryPoint({
	main: gettingStartedEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

export const jsmGSPPageEntry = createEntry(gspPageRoute, {
	exact: true,
	layout: serviceProjectLayout,
	perfMetricKey: 'servicedesk-getting-started-page-load',
	skeleton: GettingStartedPageSkeleton,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
	},
	resources: [
		...getServiceDeskNavigationResources(),
		...getServicedeskPageContextResources(),
		staticProjectSidebarNavigationResource,
		servicedeskIssueTypesResource,
	],
	entryPoint() {
		return ff('getting_started_page_production_rollout') ? gettingStartedPageEntryPoint : undefined;
	},
	component: ErrorPagesNotFound,
});

export const gettingStartedPageRouteEntries: Route[] = [jsmGSPPageEntry];
