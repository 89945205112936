import React, { useMemo, type ReactNode } from 'react';
import { SkeletonItem } from '@atlaskit/side-navigation';
import { CORE_PROJECT } from '@atlassian/jira-common-constants';
import { ForgeScreenEvent } from '@atlassian/jira-forge-ui-analytics';
import { TriggerAnalyticsWrapper } from '@atlassian/jira-forge-ui-analytics/src/ui/project-settings-page';
import { PROJECT_SETTINGS_PAGE_MODULE } from '@atlassian/jira-forge-ui-constants';
import { useIntl } from '@atlassian/jira-intl';
import {
	MenuItem,
	matchUrlPathname,
	useProject,
} from '@atlassian/jira-navigation-apps-sidebar-common';
import type { ForgeProjectSectionProps } from '@atlassian/jira-navigation-apps-sidebar-forge/src/common/types';
import { ForgeItem } from '@atlassian/jira-navigation-apps-sidebar-forge/src/common/ui/forge-item';
import { useProjectSettingsForgeAppsForMenu } from '@atlassian/jira-navigation-apps-sidebar-forge/src/controllers/project-settings';
import { getNextgenSettingsUrl } from '@atlassian/jira-project-settings-apps-common/src/urls';
import {
	ConnectAppsContainer,
	useConnectApps,
} from '@atlassian/jira-project-settings-apps-connect-apps/src/baskets/connect-apps';
import type { App } from '@atlassian/jira-project-settings-apps-connect-apps/src/model/connect-apps/types';
import { JIRA_AUTOMATION_ADDON_KEY } from '../../../../../../../common/constants';
import { messages } from './messages';

export const EcosystemApps = ({
	baseSettingsUrl = '',
	projectKey,
}: {
	baseSettingsUrl?: string;
	projectKey: string;
}) => {
	const { formatMessage } = useIntl();
	const [{ data: connectAppsData, loading: connectLoading }] = useConnectApps();
	const { data: forgeApps, loading: forgeLoading } = useProjectSettingsForgeAppsForMenu();

	const filteredConnectAppsData = useMemo(
		() =>
			connectAppsData?.filter(
				(app: App) => app?.connectIframeProps?.addon_key !== JIRA_AUTOMATION_ADDON_KEY,
			),
		[connectAppsData],
	);

	const sortedApps = useMemo(
		() =>
			[
				...(forgeApps || []).map((app) => ({
					type: 'forge' as const,
					name: app.name,
					app,
				})),
				...(filteredConnectAppsData || []).map((app) => ({
					type: 'connect' as const,
					name: app.title,
					app,
				})),
			].sort((a, b) => a.name.localeCompare(b.name)),
		[forgeApps, filteredConnectAppsData],
	);

	const overrides = useMemo<ForgeProjectSectionProps['overrides']>(
		() => ({
			BackMenuItem: {
				href: `/jira/core/projects/${projectKey}/settings`,
				title: formatMessage(messages.backItem),
				onClick: (ev) => ev.preventDefault(),
			},
		}),
		[projectKey, formatMessage],
	);

	if (connectLoading || forgeLoading) return <SkeletonItem />;

	return (
		<>
			<MenuItem
				analytics={{ itemId: 'app-fields' }}
				href={`${baseSettingsUrl}/apps/app-fields`}
				selectedOn={matchUrlPathname()}
			>
				{formatMessage(messages.appFields)}
			</MenuItem>

			{sortedApps.map((ecosystemItem) => {
				const { type, app } = ecosystemItem;

				if (type === 'forge') {
					const item = app;

					return <ForgeItem key={item.id} {...item} showIcon={false} overrides={overrides} />;
				}
				const connectApp = app;

				return (
					<MenuItem
						analytics={{ itemId: connectApp.id }}
						key={connectApp.id}
						href={`${baseSettingsUrl}/apps/${connectApp.id}`}
						selectedOn={matchUrlPathname()}
					>
						{connectApp.title}
					</MenuItem>
				);
			})}
			{forgeApps?.length ? (
				<ForgeScreenEvent
					attributes={{
						moduleType: PROJECT_SETTINGS_PAGE_MODULE,
					}}
				/>
			) : null}
		</>
	);
};

export type EcosystemAppsType = {
	children?: ReactNode;
};

export const EcosystemAppsMenu = (props: EcosystemAppsType) => {
	const { children } = props;
	const { data: project } = useProject();
	const projectKey = (project && project.key) || ''; // flow
	const baseSettingsUrl = getNextgenSettingsUrl(CORE_PROJECT, projectKey);

	return (
		<TriggerAnalyticsWrapper>
			<ConnectAppsContainer onError={undefined} scope={projectKey}>
				<EcosystemApps baseSettingsUrl={baseSettingsUrl} projectKey={projectKey} />
				{children != null && children}
			</ConnectAppsContainer>
		</TriggerAnalyticsWrapper>
	);
};
