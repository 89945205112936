import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const eocTeamAlertListRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_TEAM_ALERTS,
	path: '/jira/ops/teams/:teamId/alerts',
	exact: true,
};

export const eocTeamAlertDetailRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_TEAM_ALERT_DETAILS,
	path: '/jira/ops/teams/:teamId/alerts/:id',
	exact: true,
};
