/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { AppSkeletonImage } from '../../../common/ui';
import browseIntentsSkeletonSVG from './assets/virtual-agent-browse-intents-app-skeleton.tpl.svg';
import intentsTableSkeletonSVG from './assets/virtual-agent-intents-table-app-skeleton.tpl.svg';
import virtualAgentStatisticsSkeletonSVG from './assets/virtual-agent-virtual-agent-statistics-app-skeleton.tpl.svg';

const customCssStyles = css({
	margin: '0 auto',
	maxWidth: '960px',
});

export const VirtualAgentBrowseIntentsSkeleton = () => (
	<AppSkeletonImage src={browseIntentsSkeletonSVG} css={customCssStyles} />
);

export const VirtualAgentIntentsTableSkeleton = () => (
	<AppSkeletonImage src={intentsTableSkeletonSVG} css={customCssStyles} />
);

export const VirtualAgentStatisticsSkeleton = () => (
	<AppSkeletonImage src={virtualAgentStatisticsSkeletonSVG} css={customCssStyles} />
);
