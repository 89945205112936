import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const calendarEapEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-servicedesk-calendar-eap", jiraSpaEntry: "async-servicedesk-calendar-eap" */ './src'
			),
	),
	getPreloadProps: () => ({}),
});
