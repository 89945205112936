import { createStore } from 'react-sweet-state';
import { createSessionStorageProvider } from '@atlassian/jira-browser-storage-providers';
import * as actions from './actions';
import type { State, Actions } from './types';

const iphStorage = createSessionStorageProvider('iph');

const initialState: State = {
	navigationData: {
		// @ts-expect-error - TS2322 - Type '"HELP_ARTICLE"' is not assignable to type 'ARTICLE_TYPE'.
		articleId: { id: '', type: 'HELP_ARTICLE' },
		history: iphStorage.get('history') || [],
	},
	releaseNotesNotifications: 100,
};

export default createStore<State, Actions>({
	name: 'help-panel',
	initialState,
	actions,
});
