import React, { type ReactNode, useMemo, useRef, useState } from 'react';
import { ModalTransition } from '@atlaskit/modal-dialog';
import { type ModalComponent, ModalContext } from '@atlassian/jira-modal-context';

type Modal = {
	close: () => void;
	component: ModalComponent;
	key: string;
};

export function ModalContextProvider({ children }: { children: ReactNode }) {
	const id = useRef(1);
	const [modals, setModals] = useState<Modal[]>([]);

	const modalContext = useMemo(
		() => ({
			openModal: (component: ModalComponent) => {
				const key = String(id.current++);
				const close = () => {
					setModals((mdls) => mdls.filter((d) => d.key !== key));
				};

				setModals((mdls) => [...mdls, { close, component, key }]);
			},
		}),
		[],
	);

	return (
		<ModalContext.Provider value={modalContext}>
			{children}
			<ModalTransition>
				{modals.map((modal) => (
					<modal.component key={modal.key} onClose={modal.close} />
				))}
			</ModalTransition>
		</ModalContext.Provider>
	);
}
