import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const routeNamesEocOnCallRedirectRoute = {
	group: ROUTE_GROUPS.EOC,
	name: `${ROUTE_NAMES.EOC_ON_CALL}-redirect`,
	path: '/jira/ops/teams/:teamId',
	exact: true,
};

export const eocOnCallRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_ON_CALL,
	path: '/jira/ops/teams/:teamId/on-call',
	exact: true,
};
