// eslint-disable-next-line jira/import-whitelist
import qs from 'query-string';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { performGetRequestWithRetry } from '@atlassian/jira-fetch';
import type { Project } from './types';

export type GetProjectResponse = {
	values: Project[];
	total: number;
};

export const getLatestJSMProjectRequest = async () => {
	try {
		const queryParams = qs.stringify({
			maxResults: '1',
			orderBy: 'lastIssueUpdatedTime',
			typeKey: 'service_desk',
		});
		const response = await performGetRequestWithRetry<GetProjectResponse>(
			`/rest/api/3/project/search?${queryParams}`,
		);

		return response;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		// TO-DO rename the package name and get confirmation with @mantripat about error events
		fireErrorAnalytics({
			meta: {
				id: 'getLatestJSMProjectRequest',
				packageName: 'jiraRouterResourcesSoftwareRequests',
			},
			error,
			sendToPrivacyUnsafeSplunk: true,
		});
		throw error;
	}
};
