import {
	useAppEditions,
	useApplicationPermissions,
} from '@atlassian/jira-tenant-context-controller';

export const useHasActiveSoftwareAccess = (): boolean => {
	const { software } = useAppEditions();
	const { hasSoftwareAccess } = useApplicationPermissions();
	return software !== 'UNLICENSED' && hasSoftwareAccess === true;
};
