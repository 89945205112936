import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import type { GadgetData } from '../types';

/**
 * This is a utility to allow us to map the existing gadgets to React gadgets so that we can replace them.
 * Note that it does not work on initial load if you don't run local SSR, because this piece of code is run during SSR on initial page load.
 */
export const mapToReactGadgets = (gadgets: GadgetData[]) => {
	const gadgetMapping = {
		...(ff('endeavour.gadget.nin-filter-results-gadget') ||
		fg('endeavour_nin_filter_results_gadget')
			? { 'jira-dashboard-items/filter-results': 'react/filter-results' as const }
			: {}),
		'servicedesk/internal/reports/feature/custom-report-dashboard-item/custom-report-dashboard-item':
			'react/custom-report' as const,
		...(fg('endeavour_nin_assigned_to_me_gadget')
			? { 'jira-dashboard-items/assigned-to-me': 'react/assigned-to-me' as const }
			: {}),
		...(fg('endeavour_nin_issues_in_progress_gadget')
			? { 'jira-dashboard-items/in-progress': 'react/issues-in-progress' as const }
			: {}),
		...(fg('endeavour_nin_voted_issues_gadget')
			? { 'jira-dashboard-items/voted': 'react/voted-issues' as const }
			: {}),
		...(fg('endeavour_nin_watched_issues_gadget')
			? { 'jira-dashboard-items/watched': 'react/watched-issues' as const }
			: {}),
	}; // satisfies Record<string, ReactKey>; SSR image build Thread Loader doesn't understand 'satisfies'

	const isMapped = (module: string | undefined): module is keyof typeof gadgetMapping =>
		module !== undefined && module in gadgetMapping;

	return gadgets.map((gadget) => {
		if (isMapped(gadget.amdModule)) {
			return {
				...gadget,
				reactKey: gadgetMapping[gadget.amdModule],
				amdModule: undefined,
				configurable: false,
				inlineHtml: undefined,
			};
		}
		return gadget;
	});
};
