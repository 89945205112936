import { ff } from '@atlassian/jira-feature-flagging';
import type { IssueAggData } from '@atlassian/jira-issue-fetch-services';
// eslint-disable-next-line jira/import-whitelist
import {
	extractProjectKey,
	getMainIssueViewAggQueryFeatureFlagVariables,
} from '@atlassian/jira-issue-fetch-services-common';
import { createRelayResource, useRelayResource } from '@atlassian/jira-relay-utils';
// eslint-disable-next-line jira/import-whitelist
import mainIssueAggQuery from '@atlassian/jira-relay/src/__generated__/mainIssueAggQuery$parameters';
import type { mainIssueAggQuery as mainIssueAggQueryType } from '@atlassian/jira-relay/src/__generated__/mainIssueAggQuery.graphql';
import { createResource } from '@atlassian/jira-router';
import { getIssueKey } from '@atlassian/jira-router-resources-utils';
import { MIN_MAX_AGE, withResourceEarlyData } from '../../utils';

const getDataLoader = () =>
	import(
		/* webpackChunkName: "async-resource-issue-agg", jiraSpaEntry: "async-resource-issue-agg" */ './get-data'
	);

const shouldCaptureTraceIds = () =>
	__SERVER__ ? false : ff('issue.view-add-traceids-to-issue-view-relay-errors_vm8h2');

export const issueAggDataResource = createResource<IssueAggData>(
	withResourceEarlyData({
		type: 'ISSUE_AGG_DATA',
		getKey: ({ match }) => {
			const issueKey = getIssueKey(match);
			return issueKey || '';
		},
		getDataLoader,
		maxAge: MIN_MAX_AGE,
		isBrowserOnly: true,
	}),
);

export const issueViewRelayResource = createRelayResource<mainIssueAggQueryType>({
	type: 'ISSUE_RELAY_DATA',
	isBrowserOnly: true,
	captureErrors: true,
	captureTraceIds: shouldCaptureTraceIds(),
	getQuery: (routerContext, { tenantContext: { cloudId } }) => {
		const { match } = routerContext;
		const issueKey = getIssueKey(match);

		const projectKey = issueKey ? extractProjectKey(issueKey) : '';

		return {
			parameters: mainIssueAggQuery,
			variables: {
				cloudId,
				issueKey: issueKey || '',
				projectKey,
				...getMainIssueViewAggQueryFeatureFlagVariables(),
			},
			options: { fetchPolicy: 'store-and-network' },
		};
	},
});

// No need to FF usage of this hook, the resource is internally FF'd
export const useIssueViewRelayRouteResource = (options: { suspendWhenLoading?: boolean }) =>
	useRelayResource<mainIssueAggQueryType>(issueViewRelayResource, options);
