import defaultOptions from '@atlassian/jira-common-constants/src/fetch-default-options-with-strict-mode';
import FetchError from '@atlassian/jira-fetch/src/utils';
import type { AccountId } from '@atlassian/jira-shared-types';

export const getUserPropertiesUrl = (accountId: AccountId, propertyKey: string): string =>
	`/rest/api/latest/user/properties/${propertyKey}?accountId=${encodeURIComponent(accountId)}`;

export const setUserProperties = async (
	accountId: AccountId,
	propertyKey: string,
	data: string, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> =>
	fetch(getUserPropertiesUrl(accountId, propertyKey), {
		...defaultOptions,
		method: 'PUT',
		body: data,
	});

export const getUserProperty = <T,>(accountId: AccountId, propertyKey: string): Promise<T> =>
	fetch(getUserPropertiesUrl(accountId, propertyKey), defaultOptions)
		.then((res) => {
			if (res.status === 200) {
				return res.json();
			}

			return res.text().then((errResp) => {
				throw new FetchError(res.status, errResp);
			});
		})
		.then((data) => data.value);
