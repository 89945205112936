import type { Dispatch } from 'redux';
import type { BatchAction } from 'redux-batched-actions';
import uuid from 'uuid';
import { show } from '../store/constants';
import type { Action } from '../store/types';
import { type Flag, toFlagId, type FlagId } from '../types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (dispatch: Dispatch<Action | BatchAction>) => (flag: Flag) => {
	// Flag is of type FlagComponent
	if (typeof flag === 'function') {
		dispatch(show(flag));
		return undefined;
	}

	const flagId: FlagId = flag.id || toFlagId(uuid.v4());
	const newFlag = {
		...flag,
		id: flagId,
	};

	dispatch(show(newFlag));

	return flagId;
};
