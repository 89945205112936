import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ServicedeskReportsWorkloadType from '@atlassian/jira-spa-apps-servicedesk-reports-workload';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import { WORKLOAD_ROUTE_SUFFIX } from '../../constants';

export const LazyServicedeskReportsWorkload = lazyForPaint<typeof ServicedeskReportsWorkloadType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-servicedesk-reports-workload", jiraSpaEntry: "async-servicedesk-reports-workload" */ '@atlassian/jira-spa-apps-servicedesk-reports-workload'
			),
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyServicedeskReportsWorkload}
		pageId={`spa-apps/servicedesk${WORKLOAD_ROUTE_SUFFIX}}`}
		shouldShowSpinner
	/>
);
