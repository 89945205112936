import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const SERVICE_DESK_ROUTE_PREFIX = '/jira/servicedesk/projects/:projectKey';
export const servicedeskOncallScheduleRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_ONCALL_SCHEDULE,
	path: `${SERVICE_DESK_ROUTE_PREFIX}/on-call-schedule`,
	exact: true,
};
