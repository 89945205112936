import { useState, useEffect } from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import { performGetRequest } from '@atlassian/jira-fetch';

export const fetchLicenceCheck = () => performGetRequest('/rest/internal/psi/latest/licence/check');

export const useSiteOptimizerCheck = () => {
	const [loading, setLoading] = useState(false);
	const [hasEntitlement, setHasEntitlement] = useState(false);
	const [error, setError] = useState<unknown>();

	const fetch = async () => {
		try {
			setLoading(true);
			setError(undefined);
			if (ff('override-enable-performance-and-scale-admin-menu')) {
				setHasEntitlement(true);
			} else {
				const data = await fetchLicenceCheck();
				setHasEntitlement(data?.isVisible ?? false);
			}
			setLoading(false);
		} catch (apiError: unknown) {
			setLoading(false);
			setError(apiError);
		}
	};

	useEffect(() => {
		fetch();
	}, []);

	return { loading, hasSiteOptimizerEntitlement: hasEntitlement, error };
};
