// eslint-disable-next-line jira/restricted/@atlassian+jira-relay-utils
import { createRelayResource } from '@atlassian/jira-relay-utils';
import QUERY, {
	type mainIssueHierarchyQuery,
} from '@atlassian/jira-relay/src/__generated__/mainIssueHierarchyQuery.graphql';

export const issueHierarchyPageResource = createRelayResource<mainIssueHierarchyQuery>({
	type: 'RESOURCE1',
	getQuery: (_, { tenantContext }) => ({
		parameters: QUERY,
		variables: {
			cloudId: tenantContext.cloudId,
		},
	}),
});
