import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type WorkflowNavigationSettingsType from '@atlassian/jira-spa-apps-project-settings-workflow-navigation';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyWorkflowNavigationSettings = lazyForPaint<typeof WorkflowNavigationSettingsType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-project-settings-workflow-navigation", jiraSpaEntry: "async-project-settings-workflow-navigation" */ '@atlassian/jira-spa-apps-project-settings-workflow-navigation'
			),
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyWorkflowNavigationSettings}
		pageId="project-settings-workflow-navigation"
		shouldShowSpinner={false}
	/>
);
