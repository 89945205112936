import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type { RouteContext } from '@atlassian/jira-router';
import type ServicedeskReportsIssueViewType from '@atlassian/jira-spa-apps-servicedesk-reports-issue-view';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyServicedeskReportsIssueView = lazyForPaint<typeof ServicedeskReportsIssueViewType>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-reports-issue-view", jiraSpaEntry: "async-servicedesk-reports-issue-view" */ '@atlassian/jira-spa-apps-servicedesk-reports-issue-view'
		),
);
type Props = RouteContext;
/**
 * Dynamically constructs and renders a lazy-loaded page tailored to specific Servicedesk report views. By leveraging URL parameters such as issue key and timescale ID, it enhances user navigation within the Servicedesk environment by directly accommodating varied user inquiries and historical data perspectives.
 */
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ match }: Props) => {
	const issueKey = `/${(match.params && match.params.issueKey) || ''}`;
	const timescaleId = `/${(match.params && match.params.timescaleId) || ''}`;
	const oldIssueView = `/${(match.query && match.query.oldIssueView) || ''}`;
	return (
		<LazyPage
			Page={LazyServicedeskReportsIssueView}
			pageId={`spa-apps/servicedesk/reports/issue${issueKey}${timescaleId}${oldIssueView}`}
			shouldShowSpinner
		/>
	);
};
