import type { PreloadableConcreteRequest } from 'react-relay';
import type { RequestParameters, ConcreteRequest, Variables, OperationType } from 'relay-runtime';
import type { CAPABILITY_HEADER_NAME_TYPE } from '@atlassian/jira-capabilities';
import { setMark } from '@atlassian/jira-early-script-utils';
import { getAggEndpoint, getRequestBody } from '@atlassian/relay-endpoint';
import { getActiveTraceHttpRequestHeaders } from '@atlassian/ufo-experience-trace-id-context';

const CAPABILITY_HEADER_NAME: CAPABILITY_HEADER_NAME_TYPE = 'X-Atlassian-Capability';

export type RelayPromiseKey = string;
export const toRelayPromiseKey = (value: string): RelayPromiseKey => value;

export const EarlyScriptQueryPromisesMap = new Map<RelayPromiseKey, Promise<Response>>();

export const getEarlyScriptPromiseKey = <TVariables extends Variables>(
	params: RequestParameters,
	variables: TVariables,
): RelayPromiseKey => {
	const queryId = 'cacheID' in params && params.cacheID != null ? params.cacheID : params.id;

	const serializedVariables =
		variables != null ? JSON.stringify(variables, Object.keys(variables).sort()) : '';

	return `${params.name}|${String(queryId)}|${serializedVariables}`;
};

// Due to the expected usecase that this map only be used for early script promises, to prevent abuse it is restricted to single use only. Once a key is claimed it may not be re-used
export const claimEarlyScriptPromise = (key: RelayPromiseKey): Promise<Response> | undefined => {
	const result = EarlyScriptQueryPromisesMap.get(key);
	if (result != null) {
		EarlyScriptQueryPromisesMap.delete(key);
	}
	return result;
};

// Due to the expected usecase that this map only be used for early script promises, to prevent abuse it is restricted to single use only. Once a key is claimed it may not be re-used
export const putEarlyScriptPromise = (key: RelayPromiseKey, promise: Promise<Response>) => {
	if (!EarlyScriptQueryPromisesMap.has(key)) {
		EarlyScriptQueryPromisesMap.set(key, promise);
	}
};

export const earlyFetch = <TVariables extends Variables>(
	compiledQuery: ConcreteRequest | PreloadableConcreteRequest<OperationType>,
	variables: TVariables,
	experimentalApis: string[] = [],
	capability?: string,
) => {
	setMark('jira.fe-spa.early-preload:start');

	const endpoint = getAggEndpoint(compiledQuery.params);
	const body = getRequestBody(compiledQuery.params, variables);

	putEarlyScriptPromise(
		getEarlyScriptPromiseKey(compiledQuery.params, variables),
		fetch(endpoint, {
			credentials: 'same-origin',
			headers: {
				'atl-client-name': 'jira-frontend',
				'atl-client-version': `${window?.BUILD_KEY ?? ''}::${window?.SSR_BUILD_KEY ?? ''}`,
				'Content-Type': 'application/json',
				Accept: 'application/json,text/javascript,*/*',
				'X-ExperimentalApi': experimentalApis.join(','),
				...getActiveTraceHttpRequestHeaders(endpoint),
				...(capability ? { [CAPABILITY_HEADER_NAME]: capability } : {}),
			},
			method: 'POST',
			body,
		}).then((response) => {
			setMark('jira.fe-spa.early-preload:end');

			return response;
		}),
	);
};
