import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithFF, componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { AsyncViewsNav } from '@atlassian/jira-horizontal-nav-jsm-views';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common';
import { getCalendarFilterFieldsResource } from '@atlassian/jira-router-resources-servicedesk-calendar';
import { ErrorPagesNotFound } from '@atlassian/jira-router-routes-common';
import {
	servicedeskCalendarEapRoute,
	servicedeskCalendarRoute,
} from '@atlassian/jira-router-routes-servicedesk-calendar-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import { APP_NAMES, toPackageName } from '@atlassian/jira-servicedesk-common/src/utils/app-names';
import { ClassicCalendarSkeleton } from '@atlassian/jira-skeletons';
import { calendarEapPageEntryPoint, calendarPageEntryPoint } from './ui/calendar';

export const calendarRouteEntries: Route[] = [
	createEntry(servicedeskCalendarEapRoute, {
		ufoName: 'jsm.calendar',
		component: componentWithCondition(
			() => ff('jsm-calendar-view_bplvu') || ff('jsm-calendar-ga_81o9m'),
			() => null,
			ErrorPagesNotFound,
		),
		skeleton: ClassicCalendarSkeleton,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

		layout: serviceProjectLayout,

		resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			getConsolidationStateResource(),
		],

		entryPoint: () =>
			ff('jsm-calendar-view_bplvu') || ff('jsm-calendar-ga_81o9m')
				? calendarEapPageEntryPoint
				: undefined,

		meta: {
			reporting: {
				id: 'calendar',
				packageName: toPackageName(APP_NAMES.CALENDAR),
				teamName: 'jsd-shield',
			},
		},
	}),
	createEntry(servicedeskCalendarRoute, {
		ufoName: 'jsm-calendar',
		component: componentWithFF('jsm-calendar-ga_81o9m', () => null, ErrorPagesNotFound),
		skeleton: ClassicCalendarSkeleton,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			horizontal: AsyncViewsNav,
			horizontalFeatureFlag: 'jsm-views-of-work-phase-1_6wljy',
		},

		layout: serviceProjectLayout,

		resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			getConsolidationStateResource(),
			getCalendarFilterFieldsResource(),
		],

		entryPoint: () => (ff('jsm-calendar-ga_81o9m') ? calendarPageEntryPoint : undefined),

		meta: {
			reporting: {
				id: 'calendar',
				packageName: toPackageName(APP_NAMES.CALENDAR),
				teamName: 'jsd-shield',
			},
		},
	}),
];
