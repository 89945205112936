import React from 'react';
import camelCase from 'lodash/camelCase';
import queryString from 'query-string';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { ROUTE_NAMES } from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';
import componentWithCondition from '@atlassian/jira-common-util-component-with-condition';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { Redirect, type RouteContext, type Route } from '@atlassian/jira-router';
import { devOpsProviderResource } from '@atlassian/jira-router-resources-devops-provider';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { codePageResource } from '@atlassian/jira-router-resources-software-code';
import { ErrorPagesNotFound, LazyIssueApp } from '@atlassian/jira-router-routes-common';
import { softwareIncidentsNextGenRouteEntry } from '@atlassian/jira-router-routes-incidents-entries';
import {
	softwareReportsBurnupRoute,
	softwareReportsVelocityRoute,
	softwareReportsCumulativeRoute,
	softwareReportsBurndownRoute,
	softwareReportsCycleTimeRoute,
	softwareReportsDeploymentFrequencyRoute,
	softwareReportsRoute,
	softwareNextgenRedirectRoute,
	softwareCodeRoute,
	softwareRoadmapRedirectRoute,
} from '@atlassian/jira-router-routes-next-gen-routes';
import { softwareBacklogRouteEntry } from '@atlassian/jira-router-routes-software-backlog-entries';
import {
	softwareBoardRouteEntry,
	LazyBoardPage,
} from '@atlassian/jira-router-routes-software-board-entries';
import {
	softwareCalendarRouteEntry,
	softwareCalendarEmbedRouteEntry,
} from '@atlassian/jira-router-routes-software-calendar-entries';
import { softwareDeploymentsNextGenRouteEntry } from '@atlassian/jira-router-routes-software-deployments-entries';
import { softwareGoalsRouteEntry } from '@atlassian/jira-router-routes-software-goals-entries';
import {
	softwareListRouteEntry,
	softwareListEmbedRouteEntry,
} from '@atlassian/jira-router-routes-software-list-entries';
import { softwarePeriscopeNextGenRouteEntry } from '@atlassian/jira-router-routes-software-periscope-entries';
import { softwarePresentationToolkitReportRouteEntry } from '@atlassian/jira-router-routes-software-presentation-toolkit-report-entries';
import {
	softwareRequestsClassicRouteEntry,
	softwareRequestsRouteEntry,
} from '@atlassian/jira-router-routes-software-requests-entries';
import { softwareRoadmapRouteEntry } from '@atlassian/jira-router-routes-software-roadmap-entries';
import { softwareSecurityNextGenRouteEntry } from '@atlassian/jira-router-routes-software-security-entries';
import { softwareTimelineRouteEntry } from '@atlassian/jira-router-routes-software-timeline-entries';
import {
	softwareVersionDetailRouteEntry,
	softwareVersionDetailNoTabPathRedirectRouteEntry,
	softwareVersionDetailLegacyRedirectRouteEntry,
} from '@atlassian/jira-router-routes-software-versions-entries';
import { isDevopsFeatureDisabledInFedRamp } from '@atlassian/jira-software-devops-fedramp-utils';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav';
import {
	softwareProjectLayout,
	softwareProjectLayoutNoOnboarding,
	softwareProjectLayoutCode,
} from '@atlassian/jira-software-project-layout';
import { getTMPReportsCommonResource } from './common/ui/resources/reports';
import { pickNextGenView } from './ui/next-gen';
import { LazyNextGenRoadmap } from './ui/roadmap';
import { CodePageNextGen, LazyCode } from './ui/software/code';
import BurndownPage, { LazyNextGenBurndownReport } from './ui/software/reports/burndown';
import BurnupPage, { LazyNextGenBurnupReport } from './ui/software/reports/burnup';
import CumulativePage, { LazyNextGenCumulativeReport } from './ui/software/reports/cumulative';
import {
	CycleTimeReportNextGen,
	LazyCycleTimeReport,
} from './ui/software/reports/cycle-time-report';
import {
	DeploymentFrequencyReportNextGen,
	LazyDeploymentFrequencyReport,
} from './ui/software/reports/deployment-frequency';
import OverviewPage, { LazyNextGenReportsOverview } from './ui/software/reports/overview';
import VelocityPage, { LazyNextGenVelocityReport } from './ui/software/reports/velocity';

const SoftwareNextgenRedirectComponent = ({ location }: RouteContext) => (
	<Redirect to={pickNextGenView(location)} />
);

const SoftwareTimelineLegacyRedirect = ({ query }: RouteContext) => {
	const { projectKey, rapidView, ...restQuery } = query;
	return (
		<Redirect
			to={{
				pathname: `/jira/software/projects/${projectKey}/boards/${rapidView}/timeline`,
				hash: '',
				search: queryString.stringify(restQuery),
			}}
		/>
	);
};

export const nextGenRouteEntries: Route[] = [
	softwareBoardRouteEntry,
	softwareBacklogRouteEntry,
	softwareGoalsRouteEntry,
	createEntry(softwareReportsBurnupRoute, {
		ufoName: 'software-reports.burnup-chart',
		component: BurnupPage,
		layout: softwareProjectLayoutNoOnboarding,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			horizontalFeatureFlag: 'sea-2744-jsw-tabs-nav',
		},

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getTMPReportsCommonResource(),
		],

		forPaint: [LazyAtlassianNavigation, LazyNextGenBurnupReport],
	}),
	createEntry(softwareReportsVelocityRoute, {
		ufoName: 'software-reports.velocity-report',
		component: VelocityPage,
		layout: softwareProjectLayoutNoOnboarding,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			horizontalFeatureFlag: 'sea-2744-jsw-tabs-nav',
		},

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getTMPReportsCommonResource(),
		],

		forPaint: [LazyAtlassianNavigation, LazyNextGenVelocityReport],
	}),
	createEntry(softwareReportsCumulativeRoute, {
		ufoName: 'software-reports.cumulative-flow-diagram',
		component: CumulativePage,
		layout: softwareProjectLayoutNoOnboarding,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			horizontalFeatureFlag: 'sea-2744-jsw-tabs-nav',
		},

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getTMPReportsCommonResource(),
		],

		forPaint: [LazyAtlassianNavigation, LazyNextGenCumulativeReport],
	}),
	createEntry(softwareReportsBurndownRoute, {
		ufoName: 'software-reports.burndown-chart',
		component: BurndownPage,
		layout: softwareProjectLayoutNoOnboarding,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			horizontalFeatureFlag: 'sea-2744-jsw-tabs-nav',
		},

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getTMPReportsCommonResource(),
		],

		forPaint: [LazyAtlassianNavigation, LazyNextGenBurndownReport],
	}),
	createEntry(softwareReportsCycleTimeRoute, {
		component: componentWithCondition(
			isDevopsFeatureDisabledInFedRamp,
			ErrorPagesNotFound,
			CycleTimeReportNextGen,
		),

		layout: softwareProjectLayoutNoOnboarding,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			horizontalFeatureFlag: 'sea-2744-jsw-tabs-nav',
		},

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getTMPReportsCommonResource(),
			devOpsProviderResource,
		],

		forPaint: [LazyAtlassianNavigation, LazyCycleTimeReport],

		meta: {
			reporting: {
				id: ROUTE_NAMES.SOFTWARE_REPORTS_CYCLE_TIME,
				packageName: camelCase(ROUTE_NAMES.SOFTWARE_REPORTS_CYCLE_TIME),
				teamName: 'fusion-solaris',
			},
		},
	}),
	createEntry(softwareReportsDeploymentFrequencyRoute, {
		component: componentWithCondition(
			isDevopsFeatureDisabledInFedRamp,
			ErrorPagesNotFound,
			DeploymentFrequencyReportNextGen,
		),

		layout: softwareProjectLayoutNoOnboarding,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			horizontalFeatureFlag: 'sea-2744-jsw-tabs-nav',
		},

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getTMPReportsCommonResource(),
			devOpsProviderResource,
		],

		forPaint: [LazyAtlassianNavigation, LazyDeploymentFrequencyReport],

		meta: {
			reporting: {
				id: ROUTE_NAMES.SOFTWARE_REPORTS_DEPLOYMENT_FREQUENCY,
				packageName: camelCase(ROUTE_NAMES.SOFTWARE_REPORTS_DEPLOYMENT_FREQUENCY),
				teamName: 'fusion-solaris',
			},
		},
	}),
	createEntry(softwareReportsRoute, {
		component: OverviewPage,
		layout: softwareProjectLayoutNoOnboarding,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			horizontalFeatureFlag: 'sea-2744-jsw-tabs-nav',
		},

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getTMPReportsCommonResource(),
		],

		forPaint: [LazyAtlassianNavigation, LazyNextGenReportsOverview],
		ufoName: 'software-reports.overview',
	}),
	createEntry(softwareNextgenRedirectRoute, {
		component: SoftwareNextgenRedirectComponent,
		layout: softwareProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			horizontalFeatureFlag: 'sea-2744-jsw-tabs-nav',
		},

		resources: [...getNavigationResources()],
		afterPaint: [LazyIssueApp],
		forPaint: [LazyAtlassianNavigation, LazyBoardPage],
	}),
	createEntry(softwareCodeRoute, {
		component: componentWithCondition(
			isDevopsFeatureDisabledInFedRamp,
			ErrorPagesNotFound,
			CodePageNextGen,
		),

		layout: softwareProjectLayoutCode,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			horizontalFeatureFlag: 'sea-2744-jsw-tabs-nav',
		},

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			codePageResource,
		],

		forPaint: [LazyAtlassianNavigation, LazyCode],
		ufoName: 'next-gen-code',
	}),
	softwareSecurityNextGenRouteEntry,
	softwareDeploymentsNextGenRouteEntry,
	softwarePeriscopeNextGenRouteEntry,
	softwareVersionDetailRouteEntry,
	softwareVersionDetailNoTabPathRedirectRouteEntry,
	softwareVersionDetailLegacyRedirectRouteEntry,
	softwareRequestsRouteEntry,
	softwareRequestsClassicRouteEntry,
	softwareTimelineRouteEntry,
	softwareRoadmapRouteEntry,
	softwareCalendarEmbedRouteEntry,
	softwareCalendarRouteEntry,
	softwareListEmbedRouteEntry,
	softwareListRouteEntry,
	createEntry(softwareRoadmapRedirectRoute, {
		component: SoftwareTimelineLegacyRedirect,
		layout: softwareProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			horizontalFeatureFlag: 'sea-2744-jsw-tabs-nav',
		},

		resources: [...getNavigationResources()],
		afterPaint: [LazyIssueApp],
		forPaint: [LazyAtlassianNavigation, LazyNextGenRoadmap],
	}),
	softwareIncidentsNextGenRouteEntry,
	softwarePresentationToolkitReportRouteEntry,
];
