import { ff } from '@atlassian/jira-feature-flagging';
import { useResource } from '@atlassian/jira-router';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info';
import { useApplicationPermissions } from '@atlassian/jira-tenant-context-controller';
import {
	checkIsConsolidated,
	checkIsTransitionEligible,
	checkIsTransitioned,
	checkIsTransitioning,
	checkShouldAccessUnlockOperations,
	checkShouldUseJSM,
} from './utils';

export const useOgTenantInfo = () => {
	const tenantInfoResource = useResource(getConsolidationStateResource());
	const applicationPermissions = useApplicationPermissions();

	if (
		tenantInfoResource.data === null &&
		tenantInfoResource.loading === false &&
		tenantInfoResource.error === null
	) {
		tenantInfoResource.refresh();
	}
	return {
		loading: tenantInfoResource.loading,
		error: tenantInfoResource.error,
		shouldUseJSM: checkShouldUseJSM(tenantInfoResource.data),
		isConsolidated: checkIsConsolidated(tenantInfoResource.data),
		isTransitioning:
			ff('opsgenie-consolidation-transitioning-state_d7vm9') &&
			checkIsTransitioning(tenantInfoResource.data),
		isTransitioned:
			ff('opsgenie-consolidation-transitioning-state_d7vm9') &&
			checkIsTransitioned(tenantInfoResource.data),
		isTransitionEligible:
			ff('opsgenie-consolidation-transitioning-state_d7vm9') &&
			applicationPermissions.hasServiceDeskAccess &&
			checkIsTransitionEligible(tenantInfoResource.data),
		shouldAccessUnlockOperations:
			ff('opsgenie-consolidation-transitioning-state_d7vm9') &&
			applicationPermissions.hasServiceDeskAccess &&
			checkShouldAccessUnlockOperations(tenantInfoResource.data),
		cohort:
			typeof tenantInfoResource.data === 'string' ? undefined : tenantInfoResource.data?.cohortInfo,
	};
};
