import type { RouterContext } from '@atlassian/jira-router';
import type { SelectedPath } from '../types';
import { dashboardConverter } from './route-converters/dashboards';
import { filterConverter } from './route-converters/filters';
import { topLevelConverter } from './route-converters/others';
import { projectConverter } from './route-converters/projects';

/**
 * This function returns the path to the selected __menu item__ in the sidebar
 * The sidebar has a tree-structure. Knowing the routeContext and the sidebar's
 * component hierarchy, this function can determine what nodes in the sidebar
 * tree should be "selected".
 *
 * Each child-function (e.g. getFilters()) is responsible for determining the
 * selected path for a set of route names. Sometimes a route name, on it's own,
 * is not enough to determine which parent-path should be selected.
 *
 * However, to keep the overall logic simple, A ROUTE_NAME SHOULD APPEAR IN ONLY
 * ONE Set(). __It then becomes the responsibility for the child-function to handle
 * all the possible selected-paths that could occur in response to the route name.__
 *
 * To help ensure that the sets contain non-overlapping route names, the unit test will
 * check this.
 */
export function routeToSelectedPath(routeContext: RouterContext): SelectedPath {
	const routeName = routeContext.route.name;

	if (topLevelConverter.routes.has(routeName)) {
		return topLevelConverter.convert(routeContext);
	}

	if (dashboardConverter.routes.has(routeName)) {
		return dashboardConverter.convert(routeContext);
	}

	if (filterConverter.routes.has(routeName)) {
		return filterConverter.convert(routeContext);
	}

	if (projectConverter.routes.has(routeName)) {
		return projectConverter.convert(routeContext);
	}

	return [];
}
