import {
	useRouter,
	type RouterContext,
	type RouterDataContext,
	type Match,
} from '@atlassian/jira-router';

export function useIsOperationsUrl() {
	const [{ match }] = useRouter();

	return isOperationsURL(match);
}

export function isOperationsRoute(router: RouterContext | RouterDataContext) {
	return isOperationsURL(router.match);
}

function isOperationsURL(match: Match) {
	const { path } = match;
	return path.startsWith('/jira/ops');
}
