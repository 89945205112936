import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type { RouteContext } from '@atlassian/jira-router';
import type ServicedeskQueuesEditViewType from '@atlassian/jira-spa-apps-servicedesk-queues-edit-view';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyServicedeskQueuesEditView = lazyForPaint<typeof ServicedeskQueuesEditViewType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-servicedesk-queues-edit-view", jiraSpaEntry: "async-servicedesk-queues-edit-view" */ '@atlassian/jira-spa-apps-servicedesk-queues-edit-view'
			),
		),
);

type Props = RouteContext;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ match }: Props) => {
	const queueId = (match.params && match.params.queueId) || '';

	return (
		<LazyPage
			Page={LazyServicedeskQueuesEditView}
			pageId={`spa-apps/servicedesk/queues/edit/${queueId}`}
			shouldShowSpinner
		/>
	);
};
