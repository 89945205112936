import React from 'react';
import { Provider as ItemManagersProvider } from '@atlassian/jira-providers-item-managers/src';
import type { ClientOnlyNavigationContainersProps } from './types';

export const ClientOnlyNavigationContainers = ({
	children,
}: ClientOnlyNavigationContainersProps) => {
	if (!__SERVER__) {
		return <ItemManagersProvider>{children}</ItemManagersProvider>;
	}

	return null;
};
