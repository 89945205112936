import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { componentWithFF } from '@atlassian/jira-feature-flagging-utils';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import {
	AsyncHorizontalOperationsConfigurationNav,
	LazyHorizontalOperationsConfigurationNav,
} from '@atlassian/jira-operations-configurations-horizontal-nav';
import { createEntry } from '@atlassian/jira-route-entry';
import {
	resources as customerLogResources,
	downloadResources as customerLogsDownloadResources,
} from '@atlassian/jira-router-resources-eoc-customer-logs';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation';
import { ErrorPagesNotFound } from '@atlassian/jira-router-routes-common';
import {
	eocTeamCustomerLogsDownloadRoute,
	eocTeamCustomerLogsRoute,
} from '@atlassian/jira-router-routes-eoc-team-customer-logs-routes';
import CustomerLogs, {
	CustomerLogsDownloadPage,
	LazyCustomerLogs,
	LazyCustomerLogsDownLoad,
} from './ui/spa/eoc/customer-logs';

export const eocTeamCustomerLogsRouteEntry = createEntry(eocTeamCustomerLogsRoute, {
	component: CustomerLogs,
	layout: globalSettingsLayout,

	navigation: {
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
		horizontal: AsyncHorizontalOperationsConfigurationNav,
		horizontalFeatureFlag: 'platform.50cent.operations-general-configurations-sidebar-item_dqyta',
	},

	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		...customerLogResources,
	],

	forPaint: [LazyHorizontalOperationsConfigurationNav, LazyAtlassianNavigation, LazyCustomerLogs],
});

export const eocTeamCustomerLogsDownloadRouteEntry = createEntry(eocTeamCustomerLogsDownloadRoute, {
	component: componentWithFF(
		'platform.pun.audit-logs-export-log-release_dvp2f',
		CustomerLogsDownloadPage,
		ErrorPagesNotFound,
	),
	layout: globalSettingsLayout,

	resources: [getConsolidationStateResource(), ...customerLogsDownloadResources],

	forPaint: [LazyCustomerLogsDownLoad],
});
