import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LazyUnlockOperations = lazyForPaint<any>(
	() =>
		import(
			/* webpackChunkName: "async-jira-eoc-unlock-operations", jiraSpaEntry: "async-jira-eoc-unlock-operations" */ '@atlassian/jira-eoc-unlock-operations'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyUnlockOperations}
		pageId="eoc-products-unlock-operations"
		shouldShowSpinner={false}
	/>
);
