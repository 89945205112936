import isValidDate from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import sub from 'date-fns/sub';
import { type IntlShape } from 'react-intl-next';

import { getBooleanFF } from '@atlaskit/platform-feature-flags';
import { type OptionType } from '@atlaskit/select';
import {
	type Alert,
	type GroupAlertType,
	type GroupAndRegularAlert,
} from '@atlassian/eoc-alert-common';
import { type AutoCompleteItemResponse } from '@atlassian/eoc-auto-complete';
import { getMeta } from '@atlassian/eoc-fetch';
import {
	hasColonRegex,
	hasSpaceRegEx,
	IDENTIFIER_RESPONDERS,
	LOGICAL_OPERATOR_AND,
	STRING_LITERAL_DOUBLE_QUOTE,
} from '@atlassian/eoc-query-editor';

import { type AlertListTime, type DateTimeValue } from '../types';
import { type TimeUnit } from '../ui/date-time-inputs/time-input/types';

import messages from './messages';

// jira-frontend/src/packages/jql-builder-basic-datetime/src/ui/utils/time-functions.tsx
export const validateTimeInput = (
	intl: IntlShape,
	timeDigits?: string,
	timeUnit?: TimeUnit,
): string | undefined => {
	if (!timeDigits) {
		return;
	}

	const timeDigitsNumber = parseInt(timeDigits);

	if (isNaN(timeDigitsNumber)) {
		return intl.formatMessage(messages.validNumber);
	}
	if (timeUnit === 'hours' && (timeDigitsNumber < 1 || timeDigitsNumber > 99999)) {
		return intl.formatMessage(messages.hoursNotInRange);
	}
	if (timeUnit === 'days' && (timeDigitsNumber < 1 || timeDigitsNumber > 9999)) {
		return intl.formatMessage(messages.daysNotInRange);
	}
	if (timeUnit === 'weeks' && (timeDigitsNumber < 1 || timeDigitsNumber > 999)) {
		return intl.formatMessage(messages.weeksNotInRange);
	}
	if (timeUnit === 'months' && (timeDigitsNumber < 1 || timeDigitsNumber > 99)) {
		return intl.formatMessage(messages.monthsNotInRange);
	}
	if (timeUnit === 'years' && (timeDigitsNumber < 1 || timeDigitsNumber > 9)) {
		return intl.formatMessage(messages.yearsNotInRange);
	}
};

// jira-frontend/src/packages/jql-builder-basic-datetime/src/ui/utils/between-config.tsx
export const validateBetweenInput = (
	intl: IntlShape,
	value?: DateTimeValue,
): string | undefined => {
	if (value) {
		const { startDate, endDate } = value;

		if (!startDate && !endDate) {
			return;
		}

		if (startDate && endDate && new Date(startDate) > new Date(endDate)) {
			return intl.formatMessage(messages.validationMessageBeforeGreaterThanAfter);
		}

		if (
			value &&
			((startDate !== '' && !isValidDate(new Date(startDate))) ||
				(endDate !== '' && !isValidDate(new Date(endDate))))
		) {
			return intl.formatMessage(messages.validationMessageInvalidDateFormat);
		}
	}

	return;
};

export const isInvalidDateValue = (value: string) => {
	return !isValidDate(parseISO(value)) && value !== '';
};

export const removeQuotes = (value: string) => {
	return value.replace(/^"(.*)"$/, '$1');
};

export const autoCompleteToOption = (data: AutoCompleteItemResponse): OptionType => {
	return { ...data, label: data.name, value: data.id };
};

export const withQuote = (value: string) => {
	if (hasSpaceRegEx.test(value) || hasColonRegex.test(value)) {
		return `${STRING_LITERAL_DOUBLE_QUOTE}${value}${STRING_LITERAL_DOUBLE_QUOTE}`;
	} else {
		return value;
	}
};

export const calculateTimes = (time?: AlertListTime) => {
	if (!time) {
		return {
			from: undefined,
			to: undefined,
		};
	}
	if (time.range === 'all') {
		return {
			from: undefined,
			to: undefined,
		};
	}
	if (time.range === 'custom') {
		return {
			from: time.from,
			to: time.to,
		};
	}
	const now = new Date();

	const subAmount = {
		[time.range]: time.value,
	};
	return {
		from: sub(now, subAmount).getTime(),
		to: now.getTime(),
	};
};

export const getDefaultFilterQuery = (query: string, teamId?: string) => {
	if (teamId) {
		if (query) {
			return `${IDENTIFIER_RESPONDERS}: ${teamId} ${LOGICAL_OPERATOR_AND} ${query}`;
		}
		return `${IDENTIFIER_RESPONDERS}: ${teamId}`;
	}
	return query;
};

export const isResponderAlert = (alert: Alert | undefined) =>
	alert && alert.alertType === 'responder';

export const isGroupAlert = (alert: GroupAndRegularAlert): alert is GroupAlertType =>
	alert.entityType === 'group';

export const withAIOpsDomainPrefix = (input: string): string => {
	const cloudId = getMeta('ajs-cloud-id');
	if (cloudId) {
		return getBooleanFF('platform.aiops-route-prefix-change_qwnpb')
			? `/gateway/api/jira/aiops/cloudId/${cloudId}/api` + input
			: `/gateway/api/jsm/aiops/cloudId/${cloudId}/api` + input;
	}

	return input;
};
