import { getBooleanFF } from '@atlaskit/platform-feature-flags';
import { HTTPError, isNetworkError, NetworkError } from '@atlassian/eoc-global-context';
import { addSpanToAll } from '@atlassian/ufo-interaction-metrics';

import { type EOCFetch, type EocRequestInit } from './types';
import { defaultErrorTransformer, getRequestUrl, sanitizeUrlString, withPrefix } from './utils';

export const eocFetch: EOCFetch = (input: RequestInfo, init?: EocRequestInit) => {
	let headers = (init?.headers as Record<string, string>) ?? {};
	let body = init?.body;
	// If there is no Content-Type we should add application/json
	// and if the body is not in the BodyInit format we should stringify it
	// Native fetch accepts headers as [['key1','value1'],['key2', 'value2']] so we don't want to modify header or body in this case
	if (
		!(
			(window.Blob && body instanceof Blob) ||
			(window.Int8Array && body instanceof Int8Array) ||
			(window.Uint8Array && body instanceof Uint8Array) ||
			(window.Uint8ClampedArray && body instanceof Uint8ClampedArray) ||
			(window.Int16Array && body instanceof Int16Array) ||
			(window.Uint16Array && body instanceof Uint16Array) ||
			(window.Int32Array && body instanceof Int32Array) ||
			(window.Uint32Array && body instanceof Uint32Array) ||
			(window.Float32Array && body instanceof Float32Array) ||
			(window.Float64Array && body instanceof Float64Array) ||
			(window.ArrayBuffer && body instanceof ArrayBuffer) ||
			(window.BigInt64Array && body instanceof BigInt64Array) ||
			(window.BigUint64Array && body instanceof BigUint64Array) ||
			(window.FormData && body instanceof FormData) ||
			(window.URLSearchParams && body instanceof URLSearchParams) ||
			(window.ReadableStream && body instanceof ReadableStream) ||
			(window.Array && headers instanceof Array) ||
			typeof body === 'string'
		) &&
		headers['Content-Type'] === undefined &&
		headers['content-type'] === undefined
	) {
		body = JSON.stringify(body);
		headers['Content-Type'] = 'application/json';
	}

	if (typeof input === 'string') {
		input = withPrefix(input, init);
	} else {
		input = {
			...input,
			url: withPrefix(input.url, init),
		};
	}

	const errorTransformer = init?.errorTransformer ?? defaultErrorTransformer;

	const startTime = performance.now();

	return fetch(input, {
		headers,
		...init,
		body: body as BodyInit,
	})
		.catch((error) => {
			throw new NetworkError(error.name, error);
		})
		.then((response) => {
			if (!response.ok) {
				throw new HTTPError(response);
			}

			if (getBooleanFF('platform.send-eoc-fetch-metrics-to-performance-portal_1u3l0')) {
				const sanitizedUrl = sanitizeUrlString(getRequestUrl(input));

				addSpanToAll('fetch', sanitizedUrl, [{ name: 'network' }], startTime, performance.now());
			}

			switch (init?.responseType) {
				case undefined:
				case 'json':
					return response.json();
				case 'blob':
					return response.blob();
				case 'text':
					return response.text();
				case 'headers':
					return response;
			}
		})
		.catch((error) => {
			if (isNetworkError(error)) {
				// Put it through the transformer and throw it when ready. Note that
				// it is still safe to throw in the transformer, it will just chain up
				// on the following handlers as usual.
				const transformerResult = errorTransformer(error);
				if (transformerResult instanceof Promise) {
					return transformerResult.then((finalError: NetworkError) => {
						throw finalError;
					});
				} else {
					throw transformerResult;
				}
			}
			throw error;
		});
};
