import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ForgeServiceDeskQueuePageType from '@atlassian/jira-spa-apps-forge-servicedesk-queue';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyForgeServiceDeskQueuePage = lazyForPaint<typeof ForgeServiceDeskQueuePageType>(
	() =>
		import(
			/* webpackChunkName: "async-forge-spa-servicedesk-queue", jiraSpaEntry: "async-forge-spa-servicedesk-queue" */ '@atlassian/jira-spa-apps-forge-servicedesk-queue'
		),
);

export const ForgeServiceDeskQueuePage = () => (
	<LazyPage
		Page={LazyForgeServiceDeskQueuePage}
		pageId="forge-spa-servicedesk-queue"
		shouldShowSpinner
	/>
);

export default ForgeServiceDeskQueuePage;
