// eslint-disable-next-line jira/restricted/@atlassian+jira-relay-utils
import { createRelayResource } from '@atlassian/jira-relay-utils';
import STARRED_FILTERS_QUERY, {
	type starredFiltersContainer_starredFilters_mainStarredFilters_NavigationAppsSidebarQuery,
} from '@atlassian/jira-relay/src/__generated__/starredFiltersContainer_starredFilters_mainStarredFilters_NavigationAppsSidebarQuery.graphql';
import { withGiraAggAnonSsrFix } from '../../../common/utils';

export const sidebarProjectIssuesResource = withGiraAggAnonSsrFix(
	createRelayResource<starredFiltersContainer_starredFilters_mainStarredFilters_NavigationAppsSidebarQuery>(
		{
			type: 'NAVIGATION_SIDEBAR_STARRED_FILTERS',
			getQuery: (_, { tenantContext }) => ({
				parameters: STARRED_FILTERS_QUERY,
				variables: {
					cloudId: tenantContext.cloudId,
				},
				options: { fetchPolicy: 'store-and-network' },
			}),
		},
	),
);
