import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type EditWorkflow from '@atlassian/jira-admin-pages-edit-workflow';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyEditWorkflow = lazyForPaint<typeof EditWorkflow>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-admin-pages-edit-workflow", jiraSpaEntry: "async-admin-pages-edit-workflow" */ '@atlassian/jira-admin-pages-edit-workflow'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazyEditWorkflow} pageId="jira-admin-pages-edit-workflow" shouldShowSpinner />
);
