import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type SystemMigrationsHomeType from '@atlassian/jira-spa-apps-c2c-migrations-home';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazySystemMigrationsHome = lazyForPaint<typeof SystemMigrationsHomeType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-system-migrations-home", jiraSpaEntry: "async-system-migrations-home" */ '@atlassian/jira-spa-apps-c2c-migrations-home'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazySystemMigrationsHome} pageId="system-migrations-home" shouldShowSpinner />
);
