import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarGlobalSettingsIssues from '@atlassian/jira-navigation-apps-sidebar-global-settings-issues/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import {
	globalSettingsIssueFieldScreensRoute,
	globalSettingsIssueFieldSchemesRoute,
	globalSettingsIssueTypesScreenSchemesRoute,
} from '@atlassian/jira-router-routes-admin-pages-screens-routes';
import IssueTypeScreenSchemesPage, {
	LazyIssueTypeScreenSchemesPage,
} from './ui/spa/admin-pages/screens/issue-type-screen-schemes-page';
import ScreenSchemesPage, {
	LazyScreenSchemesPage,
} from './ui/spa/admin-pages/screens/screen-schemes-page';
import ScreensPage, { LazyScreensPage } from './ui/spa/admin-pages/screens/screens-page';

export const screensPageRouteEntries: Route[] = [
	createEntry(globalSettingsIssueFieldScreensRoute, {
		ufoName: 'global-admin.issues.screens',
		component: ScreensPage,
		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsIssues,
		},

		resources: [...getNavigationResources(), navigationSidebarGlobalResource],
		forPaint: [LazyAtlassianNavigation, LazyScreensPage],
	}),
	createEntry(globalSettingsIssueFieldSchemesRoute, {
		ufoName: 'global-admin.issues.screen-schemes',
		component: ScreenSchemesPage,
		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsIssues,
		},

		resources: [...getNavigationResources(), navigationSidebarGlobalResource],
		forPaint: [LazyAtlassianNavigation, LazyScreenSchemesPage],
	}),
	createEntry(globalSettingsIssueTypesScreenSchemesRoute, {
		ufoName: 'global-admin.issues.issue-type-screen-schemes',
		component: IssueTypeScreenSchemesPage,
		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsIssues,
		},

		resources: [...getNavigationResources(), navigationSidebarGlobalResource],
		forPaint: [LazyAtlassianNavigation, LazyIssueTypeScreenSchemesPage],
	}),
];
