import { ROUTE_GROUPS } from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const SERVICE_DESK_PRODUCT = 'servicedesk';
const projectSettingsUrl = (product: string) => `/jira/${product}/projects/:projectKey/settings`;
export const routeGroupsProjectSettingsServicedeskAutomationEditRuleRoute = {
	name: `${ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK}-automation-edit-rule`,
	path: `${projectSettingsUrl(SERVICE_DESK_PRODUCT)}/automation/ruleset/:ruleId`,
};

export const routeGroupsProjectSettingsServicedeskAutomationCopyRuleRoute = {
	name: `${ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK}-automation-copy-rule`,
	path: `${projectSettingsUrl(SERVICE_DESK_PRODUCT)}/automation/copy/:ruleId`,
};

export const routeGroupsProjectSettingsServicedeskAutomationCreateRoute = {
	name: `${ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK}-automation-create`,
	path: `${projectSettingsUrl(SERVICE_DESK_PRODUCT)}/automation/blueprint/:blueprintKey`,
};

export const routeGroupsProjectSettingsServicedeskAutomationViewLogRoute = {
	name: `${ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK}-automation-view-log`,
	path: `${projectSettingsUrl(SERVICE_DESK_PRODUCT)}/automation/log/:ruleId`,
};

export const routeGroupsProjectSettingsServicedeskAutomationSummaryRoute = {
	name: `${ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK}-automation-summary`,
	path: `${projectSettingsUrl(SERVICE_DESK_PRODUCT)}/automation`,
};
