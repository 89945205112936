import { useMemo } from 'react';
import { useGlobalForgeApps } from '@atlassian/jira-atlassian-navigation/src/controllers/addons';
import type { GlobalPage } from '@atlassian/jira-forge-ui-types';
import { toSections } from '@atlassian/jira-navigation-apps-sidebar-forge/src/common/utils';
import { useRouter } from '@atlassian/jira-router';
import { useMatchedExtension } from '@atlassian/jira-spa-apps-forge-common';

export const useForgeGlobalPageItems = () => {
	const [{ match }] = useRouter();
	const { data: modules, loading, error } = useGlobalForgeApps();
	const extension = useMatchedExtension<GlobalPage>({ modules, match });

	const sections = useMemo(() => extension && toSections(extension), [extension]);

	return { extension, sections, loading, error };
};
