import React from 'react';
import { parse as fromQueryString } from 'query-string';
import { Section } from '@atlaskit/side-navigation';
import { useIntl } from '@atlassian/jira-intl';
import { useRouter } from '@atlassian/jira-router';
import { MenuItem } from '../../../common/ui/menu-item';
import type { PinFeatures } from '../../../controllers/pin-filters/types';
import { matchUrlParams } from '../../../utils';
import { messages } from './messages';
import { itemMap } from './utils';

interface DefaultFiltersProps {
	features: PinFeatures;
	projectKey: string;
	pinUrl?: string;
	titleHeaderText?: string;
}

export const DefaultFilters = ({
	features,
	projectKey,
	pinUrl,
	titleHeaderText,
}: DefaultFiltersProps) => {
	const { formatMessage } = useIntl();
	const [{ match, location }] = useRouter();

	// @ts-expect-error - TS2322 - Type '"comma"' is not assignable to type '"none" | "bracket" | "index" | undefined'.
	const { jql } = fromQueryString(location.search, { arrayFormat: 'comma' });

	const isOnLegacyPinRoute = !!match.url.match(/^\/projects.*$/);

	const shouldUseNinLayout = !isOnLegacyPinRoute;

	const pinUrlPrefix =
		pinUrl === null || pinUrl === undefined || isOnLegacyPinRoute // Force legacy PIN route everywhere if user browsed here themselves
			? `/projects/${projectKey}/issues/?filter=`
			: pinUrl;

	const title =
		isOnLegacyPinRoute || titleHeaderText == null
			? formatMessage(messages.issues)
			: titleHeaderText;

	const allOpenIssuesSelector = !shouldUseNinLayout
		? // eslint-disable-next-line @typescript-eslint/no-explicit-any
			(currentLocation: any, itemLocation: any) =>
				matchUrlParams(['filter'], false)(currentLocation, itemLocation) ||
				(!currentLocation.query.filter &&
					currentLocation.pathname.includes(`/projects/${projectKey}/issues`))
		: matchUrlParams(['filter'], false);

	// NIN appends the corresponding jql to the URL for the selected filter so that it can
	// be read by integrated marketplace apps. So when determining if a MenuItem is selected, the
	// ?jql query URL param should be ignored unless this is for legacy PIN.
	// When we can be sure legacy is no longer in use, this const can be deleted.
	const shouldIgnoreJqlUrlParam = shouldUseNinLayout;

	return (
		<Section title={title}>
			{shouldUseNinLayout && features.hasAllIssues && (
				<MenuItem
					analytics={itemMap.ninLayoutAllIssues.analytics}
					selectedOn={
						shouldIgnoreJqlUrlParam
							? matchUrlParams(['filter'], false)
							: itemMap.ninLayoutAllIssues.selectedOn(jql)
					}
					href={`${pinUrlPrefix}allissues`}
				>
					{formatMessage(messages.allIssues)}
				</MenuItem>
			)}
			{features.hasMyOpenIssues && (
				<MenuItem
					analytics={itemMap.myOpenIssues.analytics}
					selectedOn={
						shouldIgnoreJqlUrlParam
							? matchUrlParams(['filter'], false)
							: itemMap.myOpenIssues.selectedOn(jql)
					}
					href={`${pinUrlPrefix}myopenissues`}
				>
					{formatMessage(messages.myOpenIssues)}
				</MenuItem>
			)}
			{features.hasReportedByMe && (
				<MenuItem
					analytics={itemMap.reportedByMe.analytics}
					selectedOn={
						shouldIgnoreJqlUrlParam
							? matchUrlParams(['filter'], false)
							: itemMap.reportedByMe.selectedOn(jql)
					}
					href={`${pinUrlPrefix}reportedbyme`}
				>
					{formatMessage(messages.reportedByMe)}
				</MenuItem>
			)}
			{!shouldUseNinLayout && features.hasAllIssues && (
				<MenuItem
					analytics={itemMap.notNinLayoutAllIssues.analytics}
					selectedOn={itemMap.notNinLayoutAllIssues.selectedOn(jql)}
					href={`${pinUrlPrefix}allissues`}
				>
					{formatMessage(messages.allIssues)}
				</MenuItem>
			)}
			{features.hasAllOpenIssues && (
				<MenuItem
					analytics={itemMap.allOpenIssues.analytics}
					selectedOn={
						shouldIgnoreJqlUrlParam
							? matchUrlParams(['filter'], false)
							: itemMap.allOpenIssues.selectedOn(jql, allOpenIssuesSelector)
					}
					href={`${pinUrlPrefix}allopenissues`}
				>
					{formatMessage(messages.allOpenIssues)}
				</MenuItem>
			)}
			{features.hasDoneIssues && (
				<MenuItem
					analytics={itemMap.doneIssues.analytics}
					selectedOn={
						shouldIgnoreJqlUrlParam
							? matchUrlParams(['filter'], false)
							: itemMap.doneIssues.selectedOn(jql)
					}
					href={`${pinUrlPrefix}doneissues`}
				>
					{formatMessage(messages.doneIssues)}
				</MenuItem>
			)}
			{features.hasViewedRecently && (
				<MenuItem
					analytics={itemMap.viewedRecently.analytics}
					selectedOn={
						shouldIgnoreJqlUrlParam
							? matchUrlParams(['filter'], false)
							: itemMap.viewedRecently.selectedOn(jql)
					}
					href={`${pinUrlPrefix}recentlyviewed`}
				>
					{formatMessage(messages.viewedRecently)}
				</MenuItem>
			)}
			{!shouldUseNinLayout && features.hasCreatedRecently && (
				<MenuItem
					analytics={itemMap.createdRecently.analytics}
					selectedOn={itemMap.createdRecently.selectedOn(jql)}
					href={`${pinUrlPrefix}addedrecently`}
				>
					{formatMessage(messages.createdRecently)}
				</MenuItem>
			)}
			{features.hasResolvedRecently && (
				<MenuItem
					analytics={itemMap.resolvedRecently.analytics}
					selectedOn={
						shouldIgnoreJqlUrlParam
							? matchUrlParams(['filter'], false)
							: itemMap.resolvedRecently.selectedOn(jql)
					}
					href={`${pinUrlPrefix}resolvedrecently`}
				>
					{formatMessage(messages.resolvedRecently)}
				</MenuItem>
			)}
			{features.hasUpdatedRecently && (
				<MenuItem
					analytics={itemMap.updatedRecently.analytics}
					selectedOn={
						shouldIgnoreJqlUrlParam
							? matchUrlParams(['filter'], false)
							: itemMap.updatedRecently.selectedOn(jql)
					}
					href={`${pinUrlPrefix}updatedrecently`}
				>
					{formatMessage(messages.updatedRecently)}
				</MenuItem>
			)}
		</Section>
	);
};
