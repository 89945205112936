import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ProjectSettingsServiceDeskExternalLinksType from '@atlassian/jira-spa-apps-project-settings-service-desk-external-links';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyProjectSettingsServiceDeskExternalLinks = lazyForPaint<
	typeof ProjectSettingsServiceDeskExternalLinksType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-project-settings-service-desk-external-links", jiraSpaEntry: "async-project-settings-service-desk-external-links" */ '@atlassian/jira-spa-apps-project-settings-service-desk-external-links'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyProjectSettingsServiceDeskExternalLinks}
		pageId="project-settings-external-links"
		shouldShowSpinner
	/>
);
