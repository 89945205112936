import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import type { Extension, ForgeUIType } from './extension';
import type { ExtensionPointModule } from './module';

export type ModuleType = 'trigger' | 'extension';

export type Source =
	| 'unknownSource'
	| 'global-issue-create'
	| 'transition-view'
	| 'issue-view'
	| 'navigation'
	| 'full-page-app'
	| 'router'
	| 'legacy'
	| 'organization-panel'
	| string
	| undefined;

export type EventId = string;
export const toEventId = (value: string): EventId => value;

type EnvironmentType = Extension['environmentType'];
export type ExtensionModuleType = `jira:${string}` | `jiraServiceManagement:${string}`;
export type ForgeUiAnalyticsAttributes = {
	envId: string;
	appId: string;
	source?: Source;
	forgeType?: ForgeUIType;
	envType?: EnvironmentType;
	moduleType?: ExtensionPointModule;
} & Attributes;
