import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalLayout } from '@atlassian/jira-global-layout';
import { createEntry } from '@atlassian/jira-route-entry';
import { legacyRedirectRoute } from '@atlassian/jira-router-routes-legacy-redirect-routes';
import RedirectToLegacyRouting from '@atlassian/jira-spa-apps-common/src/redirect/legacy-routing';

const RedirectComponent = () => <RedirectToLegacyRouting />;

export const legacyRedirectRouteEntry = createEntry(legacyRedirectRoute, {
	component: RedirectComponent,
	layout: globalLayout,
	resources: [],
	isRedirect: true,
	canTransitionIn: () => false,
	forPaint: [LazyAtlassianNavigation],
});
