import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const softwareSecurityRoute = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_SECURITY,
	path: '/jira/software/projects/:projectKey/security',
};

export const softwareSecurityClassicRoute = {
	group: ROUTE_GROUPS.CLASSIC_SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_SECURITY_CLASSIC,
	path: '/jira/software/c/projects/:projectKey/security',
};
