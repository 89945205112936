/**
 * @generated SignedSource<<70a2154df025caf2a523ef83836c6173>>
 * @relayHash c2276eb6d622d38aff0792648000e29a
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 77b90ac2f39da150b63df5a655a306ebcd641d8a8b8bed91593f5ba728f5cf77

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraSoftwareIssueSearchCustomInputContext = "BACKLOG" | "BOARD" | "NONE" | "%future added value";
export type JiraIssueSearchInput = {
  customInput?: JiraIssueSearchCustomInput | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
};
export type JiraIssueSearchCustomInput = {
  jiraSoftwareInput?: JiraSoftwareIssueSearchCustomInput | null | undefined;
};
export type JiraSoftwareIssueSearchCustomInput = {
  additionalJql?: string | null | undefined;
  context?: JiraSoftwareIssueSearchCustomInputContext | null | undefined;
  jiraEntityId: string;
};
export type issueTableViewQuery$variables = {
  after?: string | null | undefined;
  amountOfColumns: number;
  cloudId: string;
  fieldSetIds: ReadonlyArray<string>;
  filterAri: string;
  filterId?: string | null | undefined;
  first?: number | null | undefined;
  isNotFilterFetch: boolean;
  issueSearchInput: JiraIssueSearchInput;
  namespace?: string | null | undefined;
  shouldQueryFieldSetsById: boolean;
  viewId?: string | null | undefined;
};
export type issueTableViewQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"issueTableView_reactIssueTableGadgets">;
};
export type issueTableViewQuery = {
  response: issueTableViewQuery$data;
  variables: issueTableViewQuery$variables;
};
({
  "__relay_internal__pv__atlassianjirarelayprovidersrcisdensityenabledrelayprovider": isDensityFullFFEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcischildissuestotalcountenabledrelayprovider": isChildIssueTotalCountEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider": isJscM1ApiUpdatesEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcstatusinlineeditrelayprovider": isSpreadsheetUIEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcparentfromissueviewrelayprovider": isIssueViewParentComponentEnabled_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcischildissuestotalcountenabledrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcisdensityenabledrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcparentfromissueviewrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcstatusinlineeditrelayprovider: {
    readonly get: () => boolean;
  };
});

import isChildIssueTotalCountEnabled_provider from '@atlassian/jira-relay-provider/src/is-child-issues-total-count-enabled.relayprovider';
import isDensityFullFFEnabled_provider from '@atlassian/jira-relay-provider/src/is-density-enabled.relayprovider';
import isJscM1ApiUpdatesEnabled_provider from '@atlassian/jira-relay-provider/src/jsc-m1-api-updates.relayprovider';
import isIssueViewParentComponentEnabled_provider from '@atlassian/jira-relay-provider/src/parent-from-issue-view.relayprovider';
import isSpreadsheetUIEnabled_provider from '@atlassian/jira-relay-provider/src/status-inline-edit.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amountOfColumns"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetIds"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterAri"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isNotFilterFetch"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueSearchInput"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "namespace"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldQueryFieldSetsById"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewId"
},
v12 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v13 = {
  "kind": "ScalarField",
  "name": "key"
},
v14 = {
  "kind": "ScalarField",
  "name": "id"
},
v15 = {
  "kind": "Variable",
  "name": "fieldSetIds",
  "variableName": "fieldSetIds"
},
v16 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "amountOfColumns"
},
v17 = [
  (v15/*: any*/),
  (v16/*: any*/)
],
v18 = {
  "kind": "ScalarField",
  "name": "type"
},
v19 = {
  "kind": "ScalarField",
  "name": "fieldSetId"
},
v20 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v21 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v22 = {
  "kind": "ScalarField",
  "name": "name"
},
v23 = [
  (v22/*: any*/),
  (v14/*: any*/)
],
v24 = {
  "concreteType": "JiraFieldConfig",
  "kind": "LinkedField",
  "name": "fieldConfig",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isRequired"
    }
  ]
},
v25 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "lazyIsEditableInIssueView"
    }
  ]
},
v26 = {
  "kind": "ScalarField",
  "name": "text"
},
v27 = {
  "kind": "ScalarField",
  "name": "statusCategoryId"
},
v28 = {
  "concreteType": "JiraStatusCategory",
  "kind": "LinkedField",
  "name": "statusCategory",
  "plural": false,
  "selections": [
    (v27/*: any*/),
    (v14/*: any*/)
  ]
},
v29 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
],
v30 = {
  "kind": "ScalarField",
  "name": "totalCount"
},
v31 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraAvatar",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "small"
            }
          ]
        },
        (v22/*: any*/),
        (v14/*: any*/)
      ]
    }
  ],
  "type": "JiraIssueTypeField"
},
v32 = {
  "concreteType": "JiraSingleLineTextField",
  "kind": "LinkedField",
  "name": "summaryField",
  "plural": false,
  "selections": [
    (v26/*: any*/),
    (v14/*: any*/)
  ]
},
v33 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "xsmall"
    }
  ]
},
v34 = [
  {
    "concreteType": "JiraAdfToConvertedPlainText",
    "kind": "LinkedField",
    "name": "convertedPlainText",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "plainText"
      }
    ]
  }
],
v35 = {
  "kind": "ScalarField",
  "name": "hasNextPage"
},
v36 = {
  "concreteType": "JiraIssueFieldConnection",
  "kind": "LinkedField",
  "name": "fields",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraIssueFieldEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v20/*: any*/),
            (v21/*: any*/),
            (v18/*: any*/),
            {
              "kind": "TypeDiscriminator",
              "abstractKey": "__isJiraIssueField"
            },
            (v14/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "concreteType": "JiraAffectedServiceConnection",
                  "kind": "LinkedField",
                  "name": "selectedAffectedServicesConnection",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraAffectedServiceEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "concreteType": "JiraAffectedService",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "name": "serviceId"
                            },
                            (v22/*: any*/)
                          ]
                        }
                      ]
                    }
                  ]
                }
              ],
              "type": "JiraAffectedServicesField"
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "concreteType": "JiraCmdbObjectConnection",
                  "kind": "LinkedField",
                  "name": "selectedCmdbObjectsConnection",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraCmdbObjectEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "concreteType": "JiraCmdbObject",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "name": "objectId"
                            },
                            {
                              "kind": "ScalarField",
                              "name": "label"
                            },
                            {
                              "concreteType": "JiraCmdbAvatar",
                              "kind": "LinkedField",
                              "name": "avatar",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "ScalarField",
                                  "name": "url48"
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "concreteType": "QueryError",
                      "kind": "LinkedField",
                      "name": "errors",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "name": "message"
                        }
                      ]
                    }
                  ]
                }
              ],
              "type": "JiraCMDBField"
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "concreteType": "JiraComponentConnection",
                  "kind": "LinkedField",
                  "name": "selectedComponentsConnection",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraComponentEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "concreteType": "JiraComponent",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": (v23/*: any*/)
                        }
                      ]
                    }
                  ]
                }
              ],
              "type": "JiraComponentsField"
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "date"
                },
                (v22/*: any*/),
                (v24/*: any*/),
                (v25/*: any*/)
              ],
              "type": "JiraDatePickerField"
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "dateTime"
                }
              ],
              "type": "JiraDateTimePickerField"
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "concreteType": "JiraEpic",
                  "kind": "LinkedField",
                  "name": "epic",
                  "plural": false,
                  "selections": [
                    (v13/*: any*/),
                    {
                      "kind": "ScalarField",
                      "name": "summary"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "color"
                    },
                    (v14/*: any*/)
                  ]
                }
              ],
              "type": "JiraEpicLinkField"
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "renderedFieldHtml"
                }
              ],
              "type": "JiraFallbackField"
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v26/*: any*/),
                (v22/*: any*/),
                (v25/*: any*/)
              ],
              "type": "JiraSingleLineTextField"
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "concreteType": "JiraStatusCategory",
                  "kind": "LinkedField",
                  "name": "statusCategory",
                  "plural": false,
                  "selections": [
                    (v22/*: any*/),
                    (v27/*: any*/),
                    (v14/*: any*/)
                  ]
                }
              ],
              "type": "JiraStatusCategoryField"
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "concreteType": "JiraStatus",
                  "kind": "LinkedField",
                  "name": "status",
                  "plural": false,
                  "selections": [
                    (v22/*: any*/),
                    {
                      "kind": "ScalarField",
                      "name": "statusId"
                    },
                    (v28/*: any*/),
                    (v14/*: any*/)
                  ]
                }
              ],
              "type": "JiraStatusField"
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "args": (v29/*: any*/),
                  "concreteType": "JiraLabelConnection",
                  "kind": "LinkedField",
                  "name": "selectedLabelsConnection",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraLabelEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "concreteType": "JiraLabel",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v22/*: any*/)
                          ]
                        }
                      ]
                    },
                    (v30/*: any*/)
                  ],
                  "storageKey": "selectedLabelsConnection(first:1000)"
                },
                (v22/*: any*/),
                (v25/*: any*/)
              ],
              "type": "JiraLabelsField"
            },
            (v31/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "concreteType": "JiraIssue",
                  "kind": "LinkedField",
                  "name": "parentIssue",
                  "plural": false,
                  "selections": [
                    (v13/*: any*/),
                    {
                      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcparentfromissueviewrelayprovider",
                      "kind": "Condition",
                      "passingValue": true,
                      "selections": [
                        (v32/*: any*/)
                      ]
                    },
                    {
                      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcparentfromissueviewrelayprovider",
                      "kind": "Condition",
                      "passingValue": false,
                      "selections": [
                        {
                          "args": [
                            {
                              "kind": "Literal",
                              "name": "ids",
                              "value": [
                                "issuetype",
                                "summary"
                              ]
                            }
                          ],
                          "concreteType": "JiraIssueFieldConnection",
                          "kind": "LinkedField",
                          "name": "fieldsById",
                          "plural": false,
                          "selections": [
                            {
                              "concreteType": "JiraIssueFieldEdge",
                              "kind": "LinkedField",
                              "name": "edges",
                              "plural": true,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": [
                                    (v20/*: any*/),
                                    (v21/*: any*/),
                                    (v31/*: any*/),
                                    {
                                      "kind": "InlineFragment",
                                      "selections": [
                                        (v26/*: any*/)
                                      ],
                                      "type": "JiraSingleLineTextField"
                                    },
                                    (v14/*: any*/)
                                  ]
                                }
                              ]
                            }
                          ],
                          "storageKey": "fieldsById(ids:[\"issuetype\",\"summary\"])"
                        }
                      ]
                    },
                    (v14/*: any*/)
                  ]
                },
                {
                  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcparentfromissueviewrelayprovider",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "concreteType": "JiraIssue",
                      "kind": "LinkedField",
                      "name": "parentIssue",
                      "plural": false,
                      "selections": [
                        {
                          "concreteType": "JiraColorField",
                          "kind": "LinkedField",
                          "name": "issueColorField",
                          "plural": false,
                          "selections": [
                            {
                              "concreteType": "JiraColor",
                              "kind": "LinkedField",
                              "name": "color",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "ScalarField",
                                  "name": "colorKey"
                                },
                                (v14/*: any*/)
                              ]
                            },
                            (v14/*: any*/)
                          ]
                        },
                        {
                          "concreteType": "JiraIssueTypeField",
                          "kind": "LinkedField",
                          "name": "issueTypeField",
                          "plural": false,
                          "selections": [
                            {
                              "concreteType": "JiraIssueType",
                              "kind": "LinkedField",
                              "name": "issueType",
                              "plural": false,
                              "selections": [
                                (v33/*: any*/),
                                (v22/*: any*/),
                                (v14/*: any*/)
                              ]
                            },
                            (v14/*: any*/)
                          ]
                        },
                        (v32/*: any*/)
                      ]
                    }
                  ]
                }
              ],
              "type": "JiraParentIssueField"
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "concreteType": "JiraPriority",
                  "kind": "LinkedField",
                  "name": "priority",
                  "plural": false,
                  "selections": [
                    (v22/*: any*/),
                    {
                      "kind": "ScalarField",
                      "name": "iconUrl"
                    },
                    (v14/*: any*/)
                  ]
                },
                (v22/*: any*/),
                (v25/*: any*/)
              ],
              "type": "JiraPriorityField"
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "concreteType": "JiraProject",
                  "kind": "LinkedField",
                  "name": "project",
                  "plural": false,
                  "selections": [
                    (v22/*: any*/),
                    (v13/*: any*/),
                    {
                      "concreteType": "JiraAvatar",
                      "kind": "LinkedField",
                      "name": "avatar",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "name": "large"
                        }
                      ]
                    },
                    (v14/*: any*/)
                  ]
                }
              ],
              "type": "JiraProjectField"
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "concreteType": "JiraResolution",
                  "kind": "LinkedField",
                  "name": "resolution",
                  "plural": false,
                  "selections": (v23/*: any*/)
                }
              ],
              "type": "JiraResolutionField"
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "concreteType": "JiraSprintConnection",
                  "kind": "LinkedField",
                  "name": "selectedSprintsConnection",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraSprintEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "concreteType": "JiraSprint",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v14/*: any*/),
                            {
                              "kind": "ScalarField",
                              "name": "sprintId"
                            },
                            (v22/*: any*/),
                            {
                              "kind": "ScalarField",
                              "name": "state"
                            },
                            {
                              "kind": "ScalarField",
                              "name": "endDate"
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                (v22/*: any*/),
                (v25/*: any*/)
              ],
              "type": "JiraSprintField"
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v20/*: any*/),
                    {
                      "kind": "ScalarField",
                      "name": "accountId"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "picture"
                    },
                    (v22/*: any*/),
                    (v14/*: any*/),
                    {
                      "kind": "ScalarField",
                      "name": "accountStatus"
                    }
                  ]
                },
                (v22/*: any*/),
                (v24/*: any*/),
                (v25/*: any*/)
              ],
              "type": "JiraSingleSelectUserPickerField"
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v22/*: any*/),
                {
                  "concreteType": "JiraVersionConnection",
                  "kind": "LinkedField",
                  "name": "selectedVersionsConnection",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraVersionEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "concreteType": "JiraVersion",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": (v23/*: any*/)
                        }
                      ]
                    }
                  ]
                }
              ],
              "type": "JiraMultipleVersionPickerField"
            },
            {
              "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisdensityenabledrelayprovider",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "concreteType": "JiraRichText",
                      "kind": "LinkedField",
                      "name": "richText",
                      "plural": false,
                      "selections": [
                        {
                          "concreteType": "JiraADF",
                          "kind": "LinkedField",
                          "name": "adfValue",
                          "plural": false,
                          "selections": (v34/*: any*/)
                        }
                      ]
                    }
                  ],
                  "type": "JiraRichTextField"
                }
              ]
            },
            {
              "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisdensityenabledrelayprovider",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "concreteType": "JiraRichText",
                      "kind": "LinkedField",
                      "name": "richText",
                      "plural": false,
                      "selections": [
                        {
                          "concreteType": "JiraADF",
                          "kind": "LinkedField",
                          "name": "adfValue",
                          "plural": false,
                          "selections": [
                            {
                              "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisdensityenabledrelayprovider",
                              "kind": "Condition",
                              "passingValue": false,
                              "selections": (v34/*: any*/)
                            },
                            {
                              "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisdensityenabledrelayprovider",
                              "kind": "Condition",
                              "passingValue": true,
                              "selections": [
                                {
                                  "kind": "ScalarField",
                                  "name": "json"
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ],
                  "type": "JiraRichTextField"
                }
              ]
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "concreteType": "JiraServiceManagementRequestType",
                  "kind": "LinkedField",
                  "name": "requestType",
                  "plural": false,
                  "selections": [
                    (v22/*: any*/),
                    (v33/*: any*/),
                    (v14/*: any*/)
                  ]
                }
              ],
              "type": "JiraServiceManagementRequestTypeField"
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "args": (v29/*: any*/),
                  "concreteType": "JiraIssueLinkConnection",
                  "kind": "LinkedField",
                  "name": "issueLinkConnection",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "PageInfo",
                      "kind": "LinkedField",
                      "name": "pageInfo",
                      "plural": false,
                      "selections": [
                        (v35/*: any*/)
                      ]
                    },
                    {
                      "concreteType": "JiraIssueLinkEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "concreteType": "JiraIssueLink",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "concreteType": "JiraIssue",
                              "kind": "LinkedField",
                              "name": "issue",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "ScalarField",
                                  "name": "webUrl"
                                },
                                (v13/*: any*/),
                                {
                                  "args": [
                                    {
                                      "kind": "Literal",
                                      "name": "ids",
                                      "value": [
                                        "statusCategory"
                                      ]
                                    }
                                  ],
                                  "concreteType": "JiraIssueFieldConnection",
                                  "kind": "LinkedField",
                                  "name": "fieldsById",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "concreteType": "JiraIssueFieldEdge",
                                      "kind": "LinkedField",
                                      "name": "edges",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "kind": "LinkedField",
                                          "name": "node",
                                          "plural": false,
                                          "selections": [
                                            (v20/*: any*/),
                                            {
                                              "kind": "InlineFragment",
                                              "selections": [
                                                (v28/*: any*/)
                                              ],
                                              "type": "JiraStatusCategoryField"
                                            },
                                            (v14/*: any*/)
                                          ]
                                        }
                                      ]
                                    }
                                  ],
                                  "storageKey": "fieldsById(ids:[\"statusCategory\"])"
                                },
                                (v14/*: any*/)
                              ]
                            },
                            {
                              "kind": "ScalarField",
                              "name": "relationName"
                            },
                            (v14/*: any*/)
                          ]
                        }
                      ]
                    }
                  ],
                  "storageKey": "issueLinkConnection(first:1000)"
                }
              ],
              "type": "JiraIssueLinkField"
            }
          ]
        }
      ]
    }
  ]
},
v37 = [
  {
    "concreteType": "JiraIssueFieldSetEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "concreteType": "JiraIssueFieldSet",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v18/*: any*/),
          (v19/*: any*/),
          (v36/*: any*/)
        ]
      }
    ]
  }
],
v38 = [
  {
    "kind": "Variable",
    "name": "filterId",
    "variableName": "filterId"
  },
  (v16/*: any*/),
  {
    "kind": "Variable",
    "name": "namespace",
    "variableName": "namespace"
  },
  {
    "kind": "Variable",
    "name": "viewId",
    "variableName": "viewId"
  }
],
v39 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v40 = [
  {
    "concreteType": "JiraIssueFieldSetEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      (v20/*: any*/),
      (v39/*: any*/),
      {
        "concreteType": "JiraIssueFieldSet",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v20/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v36/*: any*/)
        ]
      }
    ]
  },
  {
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "endCursor"
      },
      (v35/*: any*/)
    ]
  }
],
v41 = [
  (v39/*: any*/),
  {
    "kind": "ScalarField",
    "name": "pageNumber"
  },
  {
    "kind": "ScalarField",
    "name": "isCurrent"
  }
],
v42 = {
  "kind": "ScalarField",
  "name": "displayName"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/)
    ],
    "kind": "Fragment",
    "name": "issueTableViewQuery",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "issueTableView_reactIssueTableGadgets"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v11/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v10/*: any*/),
      (v0/*: any*/),
      (v6/*: any*/),
      (v4/*: any*/),
      (v7/*: any*/),
      (v5/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisdensityenabledrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcischildissuestotalcountenabledrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcstatusinlineeditrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcparentfromissueviewrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "issueTableViewQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "after",
                "variableName": "after"
              },
              (v12/*: any*/),
              {
                "kind": "Variable",
                "name": "first",
                "variableName": "first"
              },
              {
                "kind": "Variable",
                "name": "issueSearchInput",
                "variableName": "issueSearchInput"
              }
            ],
            "concreteType": "JiraIssueConnection",
            "kind": "LinkedField",
            "name": "issueSearchStable",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraIssue",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      (v14/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "issueId"
                      },
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider",
                        "kind": "Condition",
                        "passingValue": false,
                        "selections": [
                          {
                            "condition": "shouldQueryFieldSetsById",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                              {
                                "args": (v17/*: any*/),
                                "concreteType": "JiraIssueFieldSetConnection",
                                "kind": "LinkedField",
                                "name": "fieldSetsById",
                                "plural": false,
                                "selections": (v37/*: any*/)
                              }
                            ]
                          },
                          {
                            "condition": "shouldQueryFieldSetsById",
                            "kind": "Condition",
                            "passingValue": false,
                            "selections": [
                              {
                                "args": (v38/*: any*/),
                                "concreteType": "JiraIssueFieldSetConnection",
                                "kind": "LinkedField",
                                "name": "fieldSetsForIssueSearchView",
                                "plural": false,
                                "selections": (v37/*: any*/)
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "condition": "shouldQueryFieldSetsById",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                              {
                                "args": (v17/*: any*/),
                                "concreteType": "JiraIssueFieldSetConnection",
                                "kind": "LinkedField",
                                "name": "fieldSetsById",
                                "plural": false,
                                "selections": (v40/*: any*/)
                              },
                              {
                                "args": (v17/*: any*/),
                                "filters": [],
                                "handle": "connection",
                                "key": "IssueTable_IssueRow__fieldSetsById",
                                "kind": "LinkedHandle",
                                "name": "fieldSetsById"
                              }
                            ]
                          },
                          {
                            "condition": "shouldQueryFieldSetsById",
                            "kind": "Condition",
                            "passingValue": false,
                            "selections": [
                              {
                                "args": (v38/*: any*/),
                                "concreteType": "JiraIssueFieldSetConnection",
                                "kind": "LinkedField",
                                "name": "fieldSetsForIssueSearchView",
                                "plural": false,
                                "selections": (v40/*: any*/)
                              },
                              {
                                "args": (v38/*: any*/),
                                "filters": [],
                                "handle": "connection",
                                "key": "IssueTable_IssueRow__fieldSetsForIssueSearchView",
                                "kind": "LinkedHandle",
                                "name": "fieldSetsForIssueSearchView"
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcischildissuestotalcountenabledrelayprovider",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "childIssues",
                            "plural": false,
                            "selections": [
                              (v20/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "args": [
                                      {
                                        "kind": "Literal",
                                        "name": "activeProjectsOnly",
                                        "value": true
                                      },
                                      {
                                        "kind": "Literal",
                                        "name": "first",
                                        "value": 10
                                      }
                                    ],
                                    "concreteType": "JiraIssueConnection",
                                    "kind": "LinkedField",
                                    "name": "issues",
                                    "plural": false,
                                    "selections": [
                                      (v30/*: any*/)
                                    ],
                                    "storageKey": "issues(activeProjectsOnly:true,first:10)"
                                  }
                                ],
                                "type": "JiraChildIssuesWithinLimit"
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "ClientExtension",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "isHighlightedIssueRow"
                          }
                        ]
                      }
                    ]
                  },
                  (v20/*: any*/)
                ]
              },
              {
                "kind": "ScalarField",
                "name": "totalIssueSearchResultCount"
              },
              {
                "kind": "ScalarField",
                "name": "isCappingIssueSearchResult"
              },
              {
                "concreteType": "JiraIssueNavigatorPageInfo",
                "kind": "LinkedField",
                "name": "issueNavigatorPageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "firstIssuePosition"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "lastIssuePosition"
                  }
                ]
              },
              {
                "args": [
                  {
                    "kind": "Literal",
                    "name": "maxCursors",
                    "value": 7
                  },
                  {
                    "kind": "Variable",
                    "name": "pageSize",
                    "variableName": "first"
                  }
                ],
                "concreteType": "JiraPageCursors",
                "kind": "LinkedField",
                "name": "pageCursors",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraPageCursor",
                    "kind": "LinkedField",
                    "name": "first",
                    "plural": false,
                    "selections": (v41/*: any*/)
                  },
                  {
                    "concreteType": "JiraPageCursor",
                    "kind": "LinkedField",
                    "name": "around",
                    "plural": true,
                    "selections": (v41/*: any*/)
                  },
                  {
                    "concreteType": "JiraPageCursor",
                    "kind": "LinkedField",
                    "name": "last",
                    "plural": false,
                    "selections": (v41/*: any*/)
                  }
                ]
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "__id"
                  }
                ]
              }
            ]
          },
          {
            "args": [
              (v12/*: any*/),
              (v15/*: any*/)
            ],
            "concreteType": "JiraIssueSearchFieldSetConnection",
            "kind": "LinkedField",
            "name": "fieldSetsById",
            "plural": false,
            "selections": [
              (v30/*: any*/),
              {
                "concreteType": "JiraIssueSearchFieldSetEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraIssueSearchFieldSet",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v19/*: any*/),
                      (v18/*: any*/),
                      (v42/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "jqlTerm"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "isSortable"
                      },
                      {
                        "concreteType": "JiraFieldType",
                        "kind": "LinkedField",
                        "name": "fieldType",
                        "plural": false,
                        "selections": [
                          (v42/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "condition": "isNotFilterFetch",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "args": [
                  {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "filterAri"
                  }
                ],
                "kind": "LinkedField",
                "name": "filter",
                "plural": false,
                "selections": [
                  (v20/*: any*/),
                  (v22/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "jql"
                  },
                  (v14/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "77b90ac2f39da150b63df5a655a306ebcd641d8a8b8bed91593f5ba728f5cf77",
    "metadata": {},
    "name": "issueTableViewQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcisdensityenabledrelayprovider": isDensityFullFFEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcischildissuestotalcountenabledrelayprovider": isChildIssueTotalCountEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider": isJscM1ApiUpdatesEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcstatusinlineeditrelayprovider": isSpreadsheetUIEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcparentfromissueviewrelayprovider": isIssueViewParentComponentEnabled_provider
    }
  }
};
})();

(node as any).hash = "b26a3728781eb5ba9292ce7929ffbbd3";

export default node;
