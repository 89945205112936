import React, { useMemo } from 'react';
import { Section } from '@atlaskit/side-navigation';
import { ROUTE_NAMES } from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';
import { useIntl } from '@atlassian/jira-intl';
import { getSystemNavigationPaths, MenuItem } from '@atlassian/jira-navigation-apps-sidebar-common';
import messages from './messages';

/**
 *  Returns Menu navigation respecting the current user permissions
 *  When updating this file, make sure to also update the following files if necessary:
 * - src/packages/navigation-apps/sidebar/common/src/controllers/use-navigation-path/system
 */
export const AutomationSection = () => {
	const { formatMessage } = useIntl();

	const navigationPath = useMemo(() => getSystemNavigationPaths(), []);

	return (
		<Section title={formatMessage(messages.automationGroup)}>
			<MenuItem
				analytics={{ itemId: 'automation' }}
				href={navigationPath.globalAutomation}
				selectedOn={[ROUTE_NAMES.SETTINGS_AUTOMATION]}
			>
				{formatMessage(messages.globalAutomation)}
			</MenuItem>
		</Section>
	);
};
