import type { ExperienceEvent } from '@atlassian/experience-tracker';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import {
	type Application,
	JIRA_SOFTWARE,
	JIRA_PRODUCT_DISCOVERY,
	JIRA_CORE,
	JIRA_SERVICE_DESK,
	JIRA_PORTFOLIO,
	type ApplicationEdition,
	FREE_EDITION,
	STANDARD_EDITION,
	PREMIUM_EDITION,
	UNLICENSED,
} from '@atlassian/jira-shared-types';
import { TASK_SUCCESS, TASK_FAIL, TASK_ABORT, type Action } from '../../common/constants';

const transformApplication = (application?: Application): string => {
	switch (application) {
		case JIRA_SOFTWARE:
			return 'jira-software';
		case JIRA_PRODUCT_DISCOVERY:
			return 'jira-product-discovery';
		case JIRA_CORE:
			return 'jira-core';
		case JIRA_SERVICE_DESK:
			return 'jira-servicedesk';
		case JIRA_PORTFOLIO:
			return 'jira-portfolio';
		default:
			return 'unknown';
	}
};
const transformEdition = (edition?: ApplicationEdition): string => {
	switch (edition) {
		case FREE_EDITION:
			return 'jedi';
		case STANDARD_EDITION:
			return 'standard';
		case PREMIUM_EDITION:
			return 'premium';
		case UNLICENSED:
			return 'unlicensed';
		default:
			return 'unknown';
	}
};
export type AdditionalEventAttributes = {
	readonly [attributeKey: string]: string | boolean | number;
};
type ExperienceDescription = {
	experience: string;
	action: Action;
	location?: string;
	analyticsSource: string;
	application?: Application;
	edition?: ApplicationEdition;
	additionalAttributes?: AdditionalEventAttributes;
	errorMessage?: string;
};
const createAnalyticsEvent = ({
	experience,
	action,
	location,
	analyticsSource,
	application,
	edition,
	errorMessage,
	additionalAttributes,
}: ExperienceDescription) => ({
	source: 'ui',
	action,
	actionSubject: 'ui',
	attributes: {
		...additionalAttributes,
		// any attribute on top of this is overridable by any tracker
		task: experience,
		reason: location,
		analyticsSource,
		application: transformApplication(application),
		edition: transformEdition(edition),
		...(errorMessage && { errorMessage }),
	},
});
const reportSli = (experienceDescription: ExperienceDescription): void => {
	getAnalyticsWebClientPromise().then((client) => {
		const event = createAnalyticsEvent(experienceDescription);
		const clientInstance = client.getInstance();
		clientInstance.sendOperationalEvent(event);
	});
};
/**
 * Manages the reporting of service level indicators by evaluating incoming events.
 * It determines the necessity of reporting based on the event's attributes and
 * categorizes the event as a success, failure, or abort. This categorization aids
 * in analytics and monitoring of service performance, ensuring relevant data is
 * captured for analysis.
 */
export const sliSubscriber = (event: ExperienceEvent) => {
	const { name, action } = event;
	// @ts-expect-error - TS2339 - Property 'disableSli' does not exist on type '{}'. | TS2339 - Property 'analyticsSource' does not exist on type '{}'. | TS2339 - Property 'application' does not exist on type '{}'. | TS2339 - Property 'edition' does not exist on type '{}'. | TS2339 - Property 'location' does not exist on type '{}'.
	const { disableSli, analyticsSource, application, edition, location, ...additionalAttributes } =
		event.attributes || {};
	if (disableSli) {
		return;
	}
	switch (action) {
		case TASK_SUCCESS:
			reportSli({
				experience: name,
				action: TASK_SUCCESS,
				analyticsSource,
				application,
				edition,
				additionalAttributes,
			});
			break;
		case TASK_FAIL:
			reportSli({
				experience: name,
				action: TASK_FAIL,
				analyticsSource,
				application,
				edition,
				location,
				...(event.error && { errorMessage: String(event.error) }),
				additionalAttributes,
			});
			break;
		case TASK_ABORT:
			reportSli({
				experience: name,
				action: TASK_ABORT,
				analyticsSource,
				application,
				edition,
				location,
				additionalAttributes,
			});
			break;
		default:
	}
};
