import React, { type ComponentType } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LazyServicedeskCmdbLandingPage = lazyForPaint<ComponentType<Record<any, any>>>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-cmdb-landing-page", jiraSpaEntry: "async-servicedesk-cmdb-landing-page" */ '@atlassian/jira-spa-apps-servicedesk-cmdb-landing-page'
		),
);

const LandingPage = () => (
	<LazyPage
		Page={LazyServicedeskCmdbLandingPage}
		pageId="spa-apps/servicedesk/insight/cmdb-landing-page"
		shouldShowSpinner
	/>
);

export default LandingPage;
