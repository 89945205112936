import React, { useState, useEffect, useCallback } from 'react';
import { graphql, usePreloadedQuery, useQueryLoader, type PreloadedQuery } from 'react-relay';
import { SkeletonItem } from '@atlaskit/side-navigation';
import { ff } from '@atlassian/jira-feature-flagging';
import { getIsGlobalScopeEnrolmentEnabledFromLocalStorage } from '@atlassian/jira-issue-navigator-global-scope-enrolment';
import { sidebarProjectIssuesResource } from '@atlassian/jira-navigation-apps-resources';
import Placeholder from '@atlassian/jira-placeholder';
import { useRelayResource } from '@atlassian/jira-relay-utils';
import QUERY, {
	type starredFiltersContainer_starredFilters_mainStarredFilters_NavigationAppsSidebarQuery,
} from '@atlassian/jira-relay/src/__generated__/starredFiltersContainer_starredFilters_mainStarredFilters_NavigationAppsSidebarQuery.graphql';
import { useResource } from '@atlassian/jira-router';
import { isNinGlobalScopeEnrolmentEnabledResource } from '@atlassian/jira-router-resources-is-nin-global-scope-enrolment-enabled';
import { useCloudId } from '@atlassian/jira-tenant-context-controller';
import { getStarredFilterLink } from '../../../utils';
import { StarredFiltersList } from '../starred-filters-list';

type StarredFiltersContainerProps = {
	projectKey: string;
	pinUrl?: string;
};

type StarredFiltersProps = StarredFiltersContainerProps & {
	onFavouritesChanged: () => void;
	queryReference: PreloadedQuery<starredFiltersContainer_starredFilters_mainStarredFilters_NavigationAppsSidebarQuery>;
};

export const StarredFilters = ({
	onFavouritesChanged,
	queryReference,
	projectKey,
	pinUrl,
}: StarredFiltersProps) => {
	// We store localStorageData as state so that it's not recalculated during subsequent renders. We want that as a
	// guarantee, not just as a performance optimisation, so we're using useState rather than useMemo.
	//
	// The reason we want to guarantee it's only fetched during the initial render is that we want
	// isNinGlobalScopeEnrolmentEnabled to be stable if there's localStorageData. This can prevent a bug where fetch
	// true from localStorage, we render NIN, the resource returns false, we update local storage to false, and then
	// GinRedirect is rendered again by a parent component and localStorage now returns false and we redirect later
	// than we should.;
	const [localStorageData] = useState(getIsGlobalScopeEnrolmentEnabledFromLocalStorage());
	const { data: resourceData } = useResource(isNinGlobalScopeEnrolmentEnabledResource);

	// get value from localStorage if available, get from the resource if this code runs in SSR
	let isNinGlobalScopeEnrolmentEnabled: boolean;
	if (localStorageData !== null && localStorageData !== undefined) {
		isNinGlobalScopeEnrolmentEnabled = !!localStorageData;
	} else {
		isNinGlobalScopeEnrolmentEnabled = !!resourceData;
	}

	const baseHref = getStarredFilterLink({
		projectKey,
		pinUrl,
		shouldStayProjectScope: ff('nin.global-scope_aqec8') && isNinGlobalScopeEnrolmentEnabled,
	});

	const data =
		usePreloadedQuery<starredFiltersContainer_starredFilters_mainStarredFilters_NavigationAppsSidebarQuery>(
			graphql`
				query starredFiltersContainer_starredFilters_mainStarredFilters_NavigationAppsSidebarQuery(
					$cloudId: ID!
				) {
					jira @required(action: THROW) {
						...starredFiltersList_navigationAppsSidebarCommon @arguments(cloudId: $cloudId)
					}
				}
			`,
			queryReference,
		);

	return (
		<StarredFiltersList
			baseHref={baseHref}
			jiraQuery={data.jira}
			onFavouritesChanged={onFavouritesChanged}
		/>
	);
};

export const StarredFiltersContainer = ({ projectKey, pinUrl }: StarredFiltersContainerProps) => {
	const { queryReference: resourceQueryRef } = useRelayResource(sidebarProjectIssuesResource);
	const [queryReference, loadQuery] =
		useQueryLoader<starredFiltersContainer_starredFilters_mainStarredFilters_NavigationAppsSidebarQuery>(
			QUERY,
			resourceQueryRef,
		);

	const cloudId = useCloudId();
	const loadSidebarData = useCallback(() => {
		loadQuery({ cloudId }, { fetchPolicy: 'store-and-network' });
	}, [cloudId, loadQuery]);

	useEffect(() => {
		if (!queryReference) {
			loadSidebarData();
		}
	}, [loadSidebarData, queryReference]);

	return (
		<Placeholder name="starred-filters" fallback={<SkeletonItem />}>
			{queryReference ? (
				<StarredFilters
					queryReference={queryReference}
					onFavouritesChanged={loadSidebarData}
					pinUrl={pinUrl}
					projectKey={projectKey}
				/>
			) : (
				<SkeletonItem />
			)}
		</Placeholder>
	);
};
