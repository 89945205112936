import React, { useMemo } from 'react';
import flow from 'lodash/flow';
import { Header } from '@atlaskit/side-navigation';
// eslint-disable-next-line jira/import/no-restricted-paths
import { useSidebarTheme } from '../../../controllers';
import type { TitleHeaderProps } from './types';
import { generateCustomThemeCssFn } from './utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const overrideStyles = (currentStyles: any) => ({
	...currentStyles,

	'[data-item-title]': {
		...currentStyles['[data-item-title]'],
		whiteSpace: 'normal',
		lineHeight: 'initial',
	},
});

export const TitleHeader = ({ children }: TitleHeaderProps) => {
	const theme = useSidebarTheme();
	const cssFn = useMemo(() => flow([generateCustomThemeCssFn(theme), overrideStyles]), [theme]);
	// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
	return <Header cssFn={cssFn}>{children}</Header>;
};
