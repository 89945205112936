import { useCallback, useRef, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import type { GraphQLError } from 'graphql';
import { useNavigationProject } from '@atlassian/jira-business-entity-project';
import { useChangeFavourite } from '@atlassian/jira-favourite-change-provider';
import {
	useNavigationResource,
	NOT_APPLICABLE,
	type NavigationOverview,
	type NavigationProject,
} from '@atlassian/jira-router-resources-business-navigation';
import { prependProject, mapProjectChanges } from './utils';

type DataType = {
	currentProject: NavigationProject | null;
	recents: NavigationProject[];
	favorites: NavigationProject[];
	hasMoreFavorite: boolean;
	overviews: NavigationOverview[];
	overviewsError: GraphQLError | null;
	shouldHideOverviews: boolean;
	loading: boolean;
};

type ReturnType = DataType & {
	onFavoriteToggle: (project: NavigationProject) => void;
};

/**
 * Do not mount this hook multiple times on the page, it is meant to only be mounted once in the sidebar navigation
 * as it refreshes the navigation data as a side effect.
 */
export const useSidebarData = (): ReturnType => {
	const { project: currentProject } = useNavigationProject();
	const { data, update, refresh, loading } = useNavigationResource();

	const {
		changeFavourite,
		items: { projects: changedItems },
	} = useChangeFavourite();

	const isRefreshingFavorites = useRef(false);
	const hasRefreshedOnce = useRef(false);

	const onFavoriteToggle = useCallback(
		(project: NavigationProject) => {
			changeFavourite({
				id: String(project.id),
				type: 'projects',
				value: !project.isFavorite,
			});
		},
		[changeFavourite],
	);

	useEffect(() => {
		if (!isEmpty(changedItems) && !Object.values(changedItems).some(({ pending }) => pending)) {
			isRefreshingFavorites.current = true;
			refresh();
		}
	}, [changedItems, refresh]);

	useEffect(() => {
		isRefreshingFavorites.current = false;
	}, [data]);

	let recents: NavigationProject[];
	let favorites: NavigationProject[];
	let hasMoreFavorite: boolean;
	let overviews: NavigationOverview[];
	let overviewsError: GraphQLError | null;
	let isPremiumUserSeat: boolean;
	let showPlansOnboarding: boolean;
	let shouldHideOverviews: boolean;

	if (data == null || data === NOT_APPLICABLE) {
		// if the resource was initially fetched for a not applicable project (i.e. not a business project)
		// on the initial page load, then we have not yet fetched the list of sidebar business projects
		// we thus refresh the resource in the background now that we are in a business project
		if (data === NOT_APPLICABLE && !hasRefreshedOnce.current) {
			hasRefreshedOnce.current = true;
			refresh();
		}

		recents = [];
		favorites = [];
		hasMoreFavorite = false;
		overviews = [];
		overviewsError = null;
		isPremiumUserSeat = false;
		showPlansOnboarding = false;
		shouldHideOverviews = false;
	} else {
		recents = data.recents;
		favorites = data.favorites;
		hasMoreFavorite = data.hasMoreFavorite;
		overviews = data.overviews;
		overviewsError = data.overviewsError;
		isPremiumUserSeat = data.isPremiumUserSeat;
		showPlansOnboarding = data.showPlansOnboarding;
		shouldHideOverviews = data.shouldHideOverviews;
	}

	// if the current is not in either recents or favorites, push it to the top of recents
	if (
		currentProject != null &&
		!recents.some((project) => project.id === currentProject.id) &&
		!favorites.some((project) => project.id === currentProject.id)
	) {
		recents = prependProject(currentProject, recents);
		// make sure that the reference doesn't get overwritten for the resource update below
		const newRecents = recents;

		update(() => ({
			recents: newRecents,
			favorites,
			hasMoreFavorite,
			overviews,
			overviewsError,
			isPremiumUserSeat,
			showPlansOnboarding,
			shouldHideOverviews,
		}));
	}

	// if there are changes to favorites, update projects
	if (!isEmpty(changedItems)) {
		recents = recents.map((project) => mapProjectChanges(project, changedItems));
		favorites = favorites.map((project) => mapProjectChanges(project, changedItems));
	}

	return {
		currentProject,
		recents,
		favorites,
		hasMoreFavorite,
		onFavoriteToggle,
		overviews,
		overviewsError,
		shouldHideOverviews,
		loading: loading && !isRefreshingFavorites.current,
	};
};
