import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const servicedeskIssueRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_ISSUE,
	path: '/jira/servicedesk/projects/:projectKey/issue/:issueKey',
	exact: true,
};

export const servicedeskQueuesLandingRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_QUEUES_LANDING,

	// Supporting optional /default for legacy backwards compatability
	path: '/jira/servicedesk/projects/:projectKey/queues/(default|custom)?',

	exact: true,
};

export const servicedeskQueuesNewRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_QUEUES_NEW,
	path: '/jira/servicedesk/projects/:projectKey/queues/custom/new',
	query: ['parentId?'],
	exact: true,
};

export const servicedeskQueuesEditRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_QUEUES_EDIT,
	path: '/jira/servicedesk/projects/:projectKey/queues/custom/:queueId/edit',
	exact: true,
};

export const servicedeskQueuesIssueRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_QUEUES_ISSUE,
	path: '/jira/servicedesk/projects/:projectKey/queues/issue/:issueKey',
	query: ['oldIssueView?'],
	exact: true,
};

export const servicedeskQueuesManageViewRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_QUEUES_MANAGE_VIEW,
	path: '/jira/servicedesk/projects/:projectKey/queues/queue-settings',
	exact: true,
};

export const servicedeskQueuesAddonRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_QUEUES_ADDON,
	path: '/jira/servicedesk/projects/:projectKey/queues/addon/:addonKey',
	exact: true,
};

export const servicedeskPracticeLandingRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_PRACTICE_LANDING,
	path: '/jira/servicedesk/projects/:projectKey/section/:practiceType/(custom)?',
	exact: true,
};

export const servicedeskPracticeNewRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_PRACTICE_NEW,
	path: '/jira/servicedesk/projects/:projectKey/section/:practiceType/custom/new',
	query: ['parentId?'],
	exact: true,
};

export const servicedeskPracticeManageViewRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_PRACTICE_MANAGE_VIEW,
	path: '/jira/servicedesk/projects/:projectKey/section/:practiceType/queue-settings',
	exact: true,
};

export const servicedeskPracticeEditRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_PRACTICE_EDIT,
	path: '/jira/servicedesk/projects/:projectKey/section/:practiceType/custom/:queueId/edit',
	exact: true,
};

export const servicedeskPracticeIssueRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_PRACTICE_ISSUE,
	path: '/jira/servicedesk/projects/:projectKey/section/:practiceType/issue/:issueKey',
	exact: true,
};
