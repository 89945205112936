import { resources } from '@atlassian/eoc-api-key-management/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { componentWithFF } from '@atlassian/jira-feature-flagging-utils';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import {
	AsyncHorizontalOperationsConfigurationNav,
	LazyHorizontalOperationsConfigurationNav,
} from '@atlassian/jira-operations-configurations-horizontal-nav';
import { createEntry } from '@atlassian/jira-route-entry';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation';
import { ErrorPagesNotFound } from '@atlassian/jira-router-routes-common';
import { eocApiKeyManagementRoute } from '@atlassian/jira-router-routes-eoc-api-key-management-routers';
import APIKeyManagementPage, { LazyApiKeyManagement } from './ui';

export const eocApiKeyManagementRouteEntry = createEntry(eocApiKeyManagementRoute, {
	component: componentWithFF(
		'eoc-api-key-management-feature-jira_8ox4z',
		APIKeyManagementPage,
		ErrorPagesNotFound,
	),
	layout: globalSettingsLayout,

	navigation: {
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
		horizontal: AsyncHorizontalOperationsConfigurationNav,
		horizontalFeatureFlag: 'platform.50cent.operations-general-configurations-sidebar-item_dqyta',
	},

	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		...resources,
	],

	forPaint: [
		LazyHorizontalOperationsConfigurationNav,
		LazyAtlassianNavigation,
		LazyApiKeyManagement,
	],
});
