/**
 * @generated SignedSource<<e8c5a0e0ea698dc2e1e006ae87d94b11>>
 * @relayHash 1a1fa6193f4c1b504180b39801d236b0
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 46a4ccbc5551eab017f7cc101b63a1401cc0ac15f18b6f069f3a7dfcc6ce9af4

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { formatRulesQuery } from './formatRulesQuery.graphql';

const node: PreloadableConcreteRequest<formatRulesQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "46a4ccbc5551eab017f7cc101b63a1401cc0ac15f18b6f069f3a7dfcc6ce9af4",
    "metadata": {},
    "name": "formatRulesQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
