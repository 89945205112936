import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ContactAdministratorPageType from '@atlassian/jira-admin-pages-contact-administrator';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyContactAdministratorPage = lazyForPaint<typeof ContactAdministratorPageType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-jira-admin-pages-contact-administrator", jiraSpaEntry: "async-jira-admin-pages-contact-administrator" */ '@atlassian/jira-admin-pages-contact-administrator'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyContactAdministratorPage}
		pageId="jira-admin-pages-contact-administrator"
		shouldShowSpinner
	/>
);
