// Package name to be used in famaCatchAndLogFetchError and logError utils
export { toPackageName } from './consts';
export type PackageName = string;

// Unique error identifier to be used in famaCatchAndLogFetchError and logError utils
export type ErrorId = string;
export const toErrorId = (id: string): ErrorId => id;

// Brief description of an error to be used in famaCatchAndLogFetchError and logError utils
export type ErrorMessage = string;
export const toErrorMessage = (message: string): ErrorMessage => message;

export type Avatar = {
	id: number;
	uri: string;
	fileName?: string;
};
