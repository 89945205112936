import { createResource } from 'react-resource-router';

import { getBooleanFF } from '@atlaskit/platform-feature-flags';

import { getGroupFeatureStatus } from '../endpoint';
import { type AlertGroupFeatureStateResponse } from '../types';

const emptyAlertGroupingState = {
	isEnabled: false,
	reason: null,
};

export const alertGroupingStateResource = createResource<AlertGroupFeatureStateResponse>({
	type: 'alertGroupingStateResource',
	getKey: () => 'alertGroupingStateResource',
	getData: async () => {
		try {
			if (getBooleanFF('platform.aiops-smart-view_yf5qp')) {
				const response = await getGroupFeatureStatus();
				return response;
			}
			return emptyAlertGroupingState;
		} catch (e) {
			// Exception handling and to return emptyState
			return emptyAlertGroupingState;
		}
	},
	maxAge: 5 * 60 * 1000, // 5 minutes
	isBrowserOnly: true,
});
