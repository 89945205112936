import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import type { PrefetchDictEntryType } from '../../types';
import { LOADING_PRIORITY } from './constant';
import { getActivityStreamPrefetchLink, getFilterResultsPrefetchLink } from './prefetch-gadgets';

export const getPrefetchDict = (): PrefetchDictEntryType => ({
	'jira-dashboard-items/activitystream': {
		func: getActivityStreamPrefetchLink,
		priority: LOADING_PRIORITY.HIGH,
		enabled: true,
		isSyncLoadingSupported: () => false,
	},

	'jira-dashboard-items/filter-results': {
		func: getFilterResultsPrefetchLink,
		priority: LOADING_PRIORITY.LOW,
		enabled:
			!ff('endeavour.gadget.nin-filter-results-gadget') ||
			!fg('endeavour_nin_filter_results_gadget'),
		isSyncLoadingSupported: () => true,
	},
});
