import { createResource } from '@atlassian/jira-router';
import { RESOURCE_TYPE_SERVICEDESK_ATLASSIAN_ANALYTICS } from './constants';
import type { ResourceData } from './types';

const getDataLoader = () =>
	import(
		/* webpackChunkName: "async-resource-servicedesk-atlassian-analytics", jiraSpaEntry: "async-resource-servicedesk-atlassian-analytics" */ './controllers/get-data'
	);

export const servicedeskAtlassianAnalyticsResource = createResource<ResourceData>({
	type: RESOURCE_TYPE_SERVICEDESK_ATLASSIAN_ANALYTICS,
	getKey: () => 'atlassian analytics',
	getDataLoader,
	isBrowserOnly: true,
});
