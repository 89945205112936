import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ServicedeskKnowledgeArticleEmbedEditorType from '@atlassian/jira-spa-apps-servicedesk-knowledge-article-embed-editor';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyServiceDeskKnowledgeArticleEmbedEditor = lazyForPaint<
	typeof ServicedeskKnowledgeArticleEmbedEditorType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-servicedesk-knowledge-hub-embed-editor", jiraSpaEntry: "async-servicedesk-knowledge-hub-embed-editor" */ '@atlassian/jira-spa-apps-servicedesk-knowledge-article-embed-editor'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyServiceDeskKnowledgeArticleEmbedEditor}
		pageId="knowledge-article-embed-editor"
		shouldShowSpinner
	/>
);
