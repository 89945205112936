import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { performGetRequest } from '@atlassian/jira-fetch';
import { createResource, useResource } from '@atlassian/jira-router';
import { RESOURCE_TYPE, NEW_COMPASS_PROJECTS_ENABLED_KEY } from './constants';
import type { CompassProductSettingsResourceData, ApplicationPropertyResponse } from './types';

export const compassProductSettingsResource = createResource<CompassProductSettingsResourceData>({
	type: RESOURCE_TYPE,
	getKey: () => 'state',
	getData: async (): Promise<CompassProductSettingsResourceData> => {
		try {
			const isCompassEnabledForNewProjects = await performGetRequest<ApplicationPropertyResponse>(
				`/rest/api/3/application-properties?key=${NEW_COMPASS_PROJECTS_ENABLED_KEY}`,
			).then(({ value }) => value.toLowerCase().trim() === 'true');

			return {
				isCompassEnabledForNewProjects,
			};
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			if (error?.statusCode === 404) {
				return {
					isCompassEnabledForNewProjects: true,
				};
			}

			fireErrorAnalytics({
				error,
				meta: {
					id: 'compassProductSettingsResource',
					packageName: 'jiraRouterResourcesCompassProductSettings',
					teamName: 'compass-lodestone',
				},
				sendToPrivacyUnsafeSplunk: true,
			});

			throw error;
		}
	},
});

export const useCompassProductSettingsResource = () =>
	useResource<CompassProductSettingsResourceData | null>(compassProductSettingsResource);
