import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ProductsJsmFeatureUsageType from '@atlassian/jira-spa-apps-products-jsm-feature-usage';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyProductsJsmFeatureUsage = lazyForPaint<typeof ProductsJsmFeatureUsageType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-products-jsm-feature-usage", jiraSpaEntry: "async-products-jsm-feature-usage" */ '@atlassian/jira-spa-apps-products-jsm-feature-usage'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyProductsJsmFeatureUsage}
		pageId="products-jsm-feature-usage"
		shouldShowSpinner={false}
	/>
);
