import { di } from 'react-magnetic-di';
import Deferred from './deferred';

export const createPollingCollection = <T, U>(
	fn: (arg1: T) => U,
	timeout: number,
): ((arg1: T) => Promise<U>) => {
	di(setTimeout);
	let isPolling = false;

	// Deferred instance is retained for polling
	const deferredMap = new Map<T, Deferred<U>>();

	// Promise is derived from Deferred and is retained indefinitely
	const promiseMap = new Map<T, Promise<U>>();

	// Provided fn will throw until ready, once ready we will resolve and delete the Deferred
	const evaluateForKey = (key: T) => {
		let result: U | undefined;
		try {
			result = fn(key);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			return;
		}

		const deferred = deferredMap.get(key);
		if (deferred instanceof Deferred) {
			deferred.resolve(result);
			deferredMap.delete(key);
		}
	};

	const maybePoll = () => {
		if (!isPolling && deferredMap.size > 0) {
			isPolling = true;
			setTimeout(() => {
				isPolling = false;
				[...deferredMap.keys()].forEach(evaluateForKey);
				maybePoll();
			}, timeout);
		}
	};

	const getter = (key: T): Promise<U> => {
		if (!promiseMap.has(key)) {
			const deferred = new Deferred<U>();
			const promise = deferred.then((v) => v);
			deferredMap.set(key, deferred);
			promiseMap.set(key, promise);
			evaluateForKey(key);
			maybePoll();
		}

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return promiseMap.get(key) as Promise<U>;
	};

	return getter;
};
