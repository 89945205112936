import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const softwarePresentationToolkitReportRoute = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_PRESENTATION_TOOLKIT_REPORT,
	path: '/jira/software/presentation-toolkit-report',
	exact: true,
};
