import { useMemo } from 'react';
import { useBannerCookieConsent } from '@atlassian/jira-cookie-consent-banner';
import { ff } from '@atlassian/jira-feature-flagging';
import { useBannerIPBlocklisting } from '@atlassian/jira-ip-block-listing-banner';
import { useIsChromeless } from '@atlassian/jira-layout-controller';
import { useBannerAnnouncement } from '@atlassian/jira-platform-ui-banners-announcement';
import { useBannerEocTransition } from '@atlassian/jira-platform-ui-banners-eoc-transition';
import { useBannerMailExpiry } from '@atlassian/jira-platform-ui-mail-expiry-reminder';
import { useBannerReturnToMobile } from '@atlassian/jira-return-to-mobile-banner';

type ReturnType = {
	name: string | null;
	height: number;
	isEnabled: boolean;
};

const noBanner: ReturnType = {
	name: null,
	height: 0,
	isEnabled: false,
};

export const useBanner = (): ReturnType => {
	const [isChromeless] = useIsChromeless();
	const announcement = useBannerAnnouncement();

	const transition = ff('opsgenie-consolidation-transitioning-state_d7vm9')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useBannerEocTransition()
		: undefined;
	const returnToMobile = useBannerReturnToMobile();
	const IPBlocklisting = useBannerIPBlocklisting();

	const cookieConsent = ff('platform.moonjelly.browser-storage-controls') // eslint-disable-next-line react-hooks/rules-of-hooks
		? useBannerCookieConsent()
		: undefined;
	const mailExpiry = ff('jira-banner-for-incoming-mail-expiry-notification_y2w1w') // eslint-disable-next-line react-hooks/rules-of-hooks
		? useBannerMailExpiry()
		: undefined;

	return useMemo(() => {
		// cookie consent banner has the highest priority
		if (cookieConsent?.isEnabled) return cookieConsent;
		if (isChromeless) return noBanner;
		if (IPBlocklisting.isEnabled) return IPBlocklisting;
		if (returnToMobile.isEnabled) return returnToMobile;
		if (announcement.isEnabled) return announcement;
		if (transition?.isEnabled) return transition;
		if (mailExpiry?.isEnabled) return mailExpiry;

		return noBanner;
	}, [
		cookieConsent,
		isChromeless,
		IPBlocklisting,
		returnToMobile,
		announcement,
		transition,
		mailExpiry,
	]);
};
