import { useMemo } from 'react';
import {
	type ProjectType,
	CORE_PROJECT,
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
	PRODUCT_DISCOVERY_PROJECT,
	CUSTOMER_SERVICE_PROJECT,
} from '@atlassian/jira-common-constants';
import { PROJECT_SETTINGS_PAGE_MODULE } from '@atlassian/jira-forge-ui-constants';
import type {
	ExtensionEnvironment,
	ProjectSettingsPage,
	Section,
} from '@atlassian/jira-forge-ui-types';
import { doesExtensionMeetConditions } from '@atlassian/jira-forge-ui-utils/src/utils/conditions';
import { parseExtensionId } from '@atlassian/jira-forge-ui-utils/src/utils/parse-extension-id';
import { forgeProjectSettingsModuleResource } from '@atlassian/jira-navigation-apps-resources';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import { useResource } from '@atlassian/jira-router';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller';
import { toSections, useForgeItemStack } from '../../common/utils';
import type {
	UseNavigationResourceReturnWithStack,
	UseNavigationResourceReturnWithAN,
} from '../types';

export const useProjectSettingsForgeApps = (): UseNavigationResourceReturnWithAN<
	ProjectSettingsPage[]
> => {
	const { data: projectContext } = useProjectContext();
	const { data, loading, error } = useResource(forgeProjectSettingsModuleResource);
	const projectSettingsData = useMemo(() => data?.extensions || null, [data]);
	const accessNarrowedData = useMemo(() => data?.accessNarrowedExtensions || null, [data]);
	const { isAdmin, isSiteAdmin } = useTenantContext();

	const hasPermissions = useMemo(
		() => (projectContext?.isProjectAdmin || isAdmin || isSiteAdmin) === true,
		[isAdmin, isSiteAdmin, projectContext?.isProjectAdmin],
	);

	// Instead of dumping projectContext as is we'd like to hardcode the context
	// to be sure that correct prop are exposed externally
	const context = useMemo(
		() =>
			projectContext
				? {
						projectKey: projectContext.projectKey,
						projectId: projectContext.projectId,
						projectName: projectContext.projectName,
						projectType: projectContext.projectType,
					}
				: {},
		[projectContext],
	);

	return useMemo<UseNavigationResourceReturnWithAN<ProjectSettingsPage[]>>(
		() => ({
			data: projectSettingsData
				? projectSettingsData.filter(
						(extension) => hasPermissions && doesExtensionMeetConditions(extension, context),
					)
				: null,
			accessNarrowedData,
			loading,
			error,
		}),
		[projectSettingsData, accessNarrowedData, loading, error, hasPermissions, context],
	);
};

const appsProjectTypeRouteMap: Record<'tmp' | 'cmp', Record<ProjectType, string | undefined>> = {
	tmp: {
		[CORE_PROJECT]: 'core',
		[SOFTWARE_PROJECT]: 'software',
		[SERVICE_DESK_PROJECT]: 'servicedesk',
		// it's genuinely missing Jira Product Discovery and CUSTOMER_SERVICE_PROJECT support
		[PRODUCT_DISCOVERY_PROJECT]: undefined,
		[CUSTOMER_SERVICE_PROJECT]: undefined,
	},
	cmp: {
		[CORE_PROJECT]: 'core',
		[SOFTWARE_PROJECT]: 'software/c',
		[SERVICE_DESK_PROJECT]: 'servicedesk',
		[PRODUCT_DISCOVERY_PROJECT]: undefined,
		[CUSTOMER_SERVICE_PROJECT]: undefined,
	},
};

export const getItemHref = (
	extension: ProjectSettingsPage,
	projectType: ProjectType,
	projectKey: string,
	isSimplified: boolean,
) => {
	const { appId, envId } = parseExtensionId(extension.id);
	return `/jira/${
		appsProjectTypeRouteMap[isSimplified ? 'tmp' : 'cmp'][projectType]
	}/projects/${projectKey}/settings/apps/${appId}/${envId}`;
};

export type ForgeProjectSettingsItem = {
	id: string;
	url: string;
	name: string;
	iconUrl?: string;
	sections?: Section[];
	environmentType: ExtensionEnvironment;
	environmentKey: string;
	moduleName: string;
};

export const useProjectSettingsForgeAppsForMenu = (
	routeName?: string,
	parentStack?: string[],
): UseNavigationResourceReturnWithStack<ForgeProjectSettingsItem[]> => {
	const { data: projectContext } = useProjectContext();
	const { data, loading, error } = useProjectSettingsForgeApps();
	const stack = useForgeItemStack(data, routeName, parentStack);
	const { projectType, projectKey, simplified: isSimplified } = projectContext || {};

	return useMemo<UseNavigationResourceReturnWithStack<ForgeProjectSettingsItem[]>>(
		() => ({
			data: data
				? data.map((extension) => ({
						id: extension.id,
						// @ts-expect-error - TS2345 - Argument of type 'ProjectType | undefined' is not assignable to parameter of type 'ProjectType'.
						url: getItemHref(extension, projectType, projectKey, isSimplified),
						name: extension.properties.title,
						iconUrl: extension.properties.icon,
						environmentType: extension.environmentType,
						environmentKey: extension.environmentKey,
						moduleName: PROJECT_SETTINGS_PAGE_MODULE,
						sections: toSections(extension),
					}))
				: null,
			loading,
			error,
			stack,
		}),
		[data, loading, error, stack, projectType, projectKey, isSimplified],
	);
};
