import {
	fetchActivityFeed,
	type PagedActivityFeedType,
} from '@atlassian/jira-business-summary-services';
import { createResource, useResource } from '@atlassian/jira-router';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils';

const RESOURCE_TYPE_KEY = 'BUSINESS_ACTIVITY_FEED';

const EMPTY_DATA: PagedActivityFeedType = {
	data: [],
	pageInfo: {
		hasNextPage: false,
		endCursor: '',
	},
};

export const activityFeedResource = createResource<PagedActivityFeedType>({
	type: RESOURCE_TYPE_KEY,
	getKey: (matchedRoute) => {
		const { projectKey } = getProjectKeyId(matchedRoute.match, matchedRoute.query);
		return `${RESOURCE_TYPE_KEY}_${projectKey}`;
	},
	getData: async ({ match, query }, { tenantContext: { isAnonymous } }) => {
		// do not fetch the resource for anonymous access
		if (isAnonymous) {
			return EMPTY_DATA;
		}

		const { projectKey } = getProjectKeyId(match, query);

		return fetchActivityFeed(null, projectKey);
	},
	maxAge: 5 * 1000,
	isBrowserOnly: true,
});

export const useActivityFeed = () =>
	// forcing the return type due to react-resource-router's weird error types
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	useResource<PagedActivityFeedType>(activityFeedResource) as {
		data: PagedActivityFeedType | null | undefined;
		error: Error | null | undefined;
		loading: boolean;
	};
