import type {
	IssueTypeWorkloadBeaconId,
	IssueTypeWorkloadSettings,
} from '../types/issue-type-workload';

export const ISSUE_TYPE_WORKLOAD_BEACON_ID: IssueTypeWorkloadBeaconId = 'issue-type-workload';

export const ISSUE_TYPE_WORKLOAD_SETTINGS_DEFAULTS: IssueTypeWorkloadSettings = {
	hidden: false,
};
