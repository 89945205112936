import React, { useLayoutEffect, useRef, useMemo } from 'react';
import { ExperienceStart } from '@atlassian/jira-experience-tracker';
import { useRouter } from '@atlassian/jira-router';
import { TOP_NAV, useSpaStateActions, usePageId } from '@atlassian/jira-spa-state-controller';
import { EXPERIENCE_NAVIGATION_TOP_MAIN } from '../../common/constants';

/**
 * The start tracker for atlassian navigation, what it records,
 * spa app TOP_BAR start
 *
 * start condition, new route and the route is not a redirect
 */
export const AtlassianNavigationStart = () => {
	const [{ location }] = useRouter();
	const [, { setAppStartWithAppId }] = useSpaStateActions();
	const pageId = usePageId();

	const startRef = useRef({
		location,
		startTime: Date.now(),
	});

	useMemo(() => {
		if (location !== startRef.current.location) {
			startRef.current = {
				location,
				startTime: Date.now(),
			};
		}
	}, [location]);

	useLayoutEffect(() => {
		setAppStartWithAppId(TOP_NAV, startRef.current.startTime);
	}, [location, setAppStartWithAppId]);

	return (
		<ExperienceStart
			experience={EXPERIENCE_NAVIGATION_TOP_MAIN}
			analyticsSource="atlassian-navigation"
			experienceId={pageId}
		/>
	);
};
