import type {
	AvatarCollections,
	SavedAvatar,
} from '@atlassian/jira-common-rest/src/api/2/universal-avatar/type/issuetype/owner/id/types';
import type { Avatar } from '../types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (rawAvatars: AvatarCollections): Avatar[] =>
	rawAvatars.system.concat(rawAvatars.custom).map((raw) => ({
		id: Number(raw.id),
		uri: raw.urls['48x48'],
		fileName: raw.fileName,
	}));

export const getProjectAvatarUrl = (avatarId: number): string =>
	`/secure/viewavatar?avatarId=${avatarId}&avatarType=issuetype`;

export const normaliseSavedAvatar = (avatar: SavedAvatar): Avatar => ({
	id: parseInt(avatar.id, 10),
	uri: getProjectAvatarUrl(parseInt(avatar.id, 10)),
	fileName: avatar.fileName,
});
