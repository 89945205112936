import type { JiraHomeRouteResourceData } from '@atlassian/jira-home';
import { createResource } from '@atlassian/jira-router';
import { RESOURCE_TYPE_JIRA_HOME } from './constants';

export const jiraHomeResourceWithCache = createResource<JiraHomeRouteResourceData | null>({
	type: RESOURCE_TYPE_JIRA_HOME,
	getKey: () => 'jira-home',
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-jira-home-with-cache", jiraSpaEntry: "async-resource-jira-home" */ './controllers/get-data'
		),
	maxAge: Infinity,
});
