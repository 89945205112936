import { useEffect, useMemo } from 'react';
import { createResource, useResource } from '@atlassian/jira-router';
import type { CHANGES, INCIDENTS, PROBLEMS } from '@atlassian/jira-servicedesk-work-category';
import type { AccountId } from '@atlassian/jira-shared-types';
import type { JsmRepackagingBanner } from './controllers/get-data/types';
import { deleteBannerDismissState } from './services/delete-banner-dismiss-state';
import type { BannerDismissState } from './services/get-banner-dismiss-state/types';
import type { BannerMeta } from './services/get-banner-meta/types';

const getDataLoader = () =>
	import(
		/*
			webpackChunkName: "async-resource-jsm-repackaging-banner",
			jiraSpaEntry: "async-resource-jsm-repackaging-banner"
		*/
		'./controllers/get-data'
	);

export const jsmRepackagingBannerResource = createResource<JsmRepackagingBanner | undefined>({
	type: 'JSM_REPACKAGING_BANNER',
	getKey: () => 'JSM_REPACKAGING_BANNER',
	getDataLoader,
	maxAge: Infinity,
});

export const useJsmRepackagingBannerResource = (
	workCategory: typeof CHANGES | typeof INCIDENTS | typeof PROBLEMS,
	accountId: AccountId | null,
): BannerMeta & BannerDismissState => {
	const { data, loading, error, refresh } = useResource(jsmRepackagingBannerResource);

	useEffect(() => {
		if (!data && !loading && !error) {
			refresh();
		}
	}, [data, loading, refresh, error]);

	return useMemo(() => {
		if (error || loading || !data || !accountId) {
			return {
				show: false,
			};
		}

		const { bannerMeta, bannerDismissState } = data;
		const dismissState = bannerDismissState?.[workCategory];

		if (!bannerMeta.show || !(bannerMeta.type === 'info' || bannerMeta.type === 'warning')) {
			return {
				show: false,
			};
		}

		if (!dismissState) {
			return bannerMeta;
		}

		if (dismissState.packagingChangeDate !== bannerMeta.data.packagingChangeDate) {
			deleteBannerDismissState({ accountId });

			return {
				...bannerMeta,
				...bannerDismissState,
			};
		}

		if (dismissState.bannerHiddenUntil > Date.now()) {
			return {
				show: false,
			};
		}

		return {
			...bannerMeta,
			...bannerDismissState,
		};
	}, [data, loading, error, workCategory, accountId]);
};

export { deleteBannerDismissState };
