import React, { type ComponentType } from 'react';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect';

export const conditionalRedirect =
	(CurrentComponent: ComponentType, redirectUrl: string, redirectCondition: () => boolean) =>
	() => {
		if (redirectCondition()) {
			return <ScreenRedirect to={redirectUrl} />;
		}
		return <CurrentComponent />;
	};

export const redirectTo = (redirectUrl: string) => () => <ScreenRedirect to={redirectUrl} />;
