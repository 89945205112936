import React from 'react';
import Avatar from '@atlaskit/avatar';
import { Section as SectionDI } from '@atlaskit/side-navigation';
import { MenuItem as MenuItemDI } from '../../common/ui/menu-item';
import { matchUrlPathname } from '../../utils/url-matchers';
import type { Props } from './types';

export const IssueTypesGroup = ({
	MenuItem = MenuItemDI,
	Section = SectionDI,
	baseSettingsUrl,
	hasSeparator,
	navigationItems,
}: Props) => {
	if (!navigationItems.length) {
		return null;
	}

	return (
		<Section hasSeparator={hasSeparator}>
			{navigationItems.map((issueType) => (
				<MenuItem
					key={String(issueType.id)}
					analytics={{ itemId: String(issueType.id) }}
					selectedOn={matchUrlPathname()}
					href={`${baseSettingsUrl}/issuetypes/${issueType.id}`}
					iconBefore={
						<Avatar
							appearance="square"
							size="small"
							src={issueType.icon}
							borderColor="transparent"
						/>
					}
				>
					{issueType.name}
				</MenuItem>
			))}
		</Section>
	);
};
