import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const archivedProjectDirectLinkRoute = {
	group: ROUTE_GROUPS.ERRORS,
	name: ROUTE_NAMES.ARCHIVED_PROJECT_DIRECT_LINK,
	path: '/jira/errors/project-archived',
	exact: true,
};
