import type {
	IssuesForAttentionBeaconId,
	IssuesForAttentionSettings,
} from '../types/issues-for-attention';

export const ISSUES_FOR_ATTENTION_BEACON_ID: IssuesForAttentionBeaconId = 'issues-for-attention';

export const ISSUES_FOR_ATTENTION_SETTINGS_DEFAULTS: IssuesForAttentionSettings = {
	hidden: false,
};
