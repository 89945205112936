import { lazyAfterPaint } from 'react-loosely-lazy';
import type BackgroundScripts from './ui';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyAfterPaintBackgroundScripts = lazyAfterPaint<typeof BackgroundScripts>(
	() => import(/* webpackChunkName: "async-jira-dashboard-background-scripts" */ './ui'),
	{
		ssr: false,
	},
);
