import { createRelayResource } from '@atlassian/jira-relay-utils';
import metricsOverviewQuery, {
	type main_adminPagesPerformance_Overview_Query,
} from '@atlassian/jira-relay/src/__generated__/main_adminPagesPerformance_Overview_Query.graphql';

export const metricsOverviewPageResource =
	createRelayResource<main_adminPagesPerformance_Overview_Query>({
		type: 'ADMIN_PAGES_PERFORMANCE_OVERVIEW',
		getQuery: (_, { tenantContext }) => ({
			parameters: metricsOverviewQuery,
			variables: {
				cloudId: tenantContext.cloudId,
			},
			options: {
				fetchPolicy: 'network-only',
			},
		}),
	});
