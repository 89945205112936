import merge from 'lodash/merge';

import { commonUrls, type PathParameters } from '@atlassian/eoc-schedule-common';

const urls = Object.freeze(
	merge(
		{
			schedules: {
				url: () => `/v1/schedules`,
				scheduleId: {
					url: (
						{ scheduleId }: PathParameters<'scheduleId'> = {
							scheduleId: ':scheduleId',
						},
					) => `/v1/schedules/${scheduleId}`,
					clone: {
						url: (
							{ scheduleId }: PathParameters<'scheduleId'> = {
								scheduleId: ':scheduleId',
							},
						) => `/v1/schedules/${scheduleId}/clone`,
					},
					name: {
						url: (
							{ scheduleId }: PathParameters<'scheduleId'> = {
								scheduleId: ':scheduleId',
							},
						) => `/v1/schedules/${scheduleId}/name`,
					},
					timezone: {
						url: (
							{ scheduleId }: PathParameters<'scheduleId'> = {
								scheduleId: ':scheduleId',
							},
						) => `/v1/schedules/${scheduleId}/timezone`,
					},
					enable: {
						url: (
							{ scheduleId }: PathParameters<'scheduleId'> = {
								scheduleId: ':scheduleId',
							},
						) => `/v1/schedules/${scheduleId}/enable`,
					},
					disable: {
						url: (
							{ scheduleId }: PathParameters<'scheduleId'> = {
								scheduleId: ':scheduleId',
							},
						) => `/v1/schedules/${scheduleId}/disable`,
					},
					webCalendarUrl: {
						url: (
							{ scheduleId }: PathParameters<'scheduleId'> = {
								scheduleId: ':scheduleId',
							},
						) => `/v1/schedules/${scheduleId}/web-calendar-url`,
					},
					timeline: {
						url: (
							{ scheduleId }: PathParameters<'scheduleId'> = {
								scheduleId: ':scheduleId',
							},
						) => `/v1/schedules/${scheduleId}/timeline`,
					},
					overrides: {
						url: (
							{ scheduleId }: PathParameters<'scheduleId'> = {
								scheduleId: ':scheduleId',
							},
						) => `/v1/schedules/${scheduleId}/overrides`,
						alias: {
							url: (
								{ scheduleId, alias }: PathParameters<'scheduleId' | 'alias'> = {
									scheduleId: ':scheduleId',
									alias: ':alias',
								},
							) => `/v1/schedules/${scheduleId}/overrides/${alias}`,
						},
						bulk: {
							url: (
								{ scheduleId }: PathParameters<'scheduleId'> = {
									scheduleId: ':scheduleId',
								},
							) => `/v1/schedules/${scheduleId}/overrides/bulk`,
						},
					},
					rotations: {
						url: (
							{ scheduleId }: PathParameters<'scheduleId'> = {
								scheduleId: ':scheduleId',
							},
						) => `/v1/schedules/${scheduleId}/rotations`,
						rotationId: {
							url: (
								{ scheduleId, rotationId }: PathParameters<'scheduleId' | 'rotationId'> = {
									scheduleId: ':scheduleId',
									rotationId: ':rotationId',
								},
							) => `/v1/schedules/${scheduleId}/rotations/${rotationId}`,
							order: {
								url: (
									{ scheduleId, rotationId }: PathParameters<'scheduleId' | 'rotationId'> = {
										scheduleId: ':scheduleId',
										rotationId: ':rotationId',
									},
								) => `/v1/schedules/${scheduleId}/rotations/${rotationId}/order`,
							},
							name: {
								url: (
									{ scheduleId, rotationId }: PathParameters<'scheduleId' | 'rotationId'> = {
										scheduleId: ':scheduleId',
										rotationId: ':rotationId',
									},
								) => `/v1/schedules/${scheduleId}/rotations/${rotationId}/name`,
							},
						},
					},
				},
				teams: {
					teamId: {
						url: ({ teamId }: PathParameters<'teamId'> = { teamId: ':teamId' }) =>
							`/v1/schedules/teams/${teamId}`,
						rights: {
							url: ({ teamId }: PathParameters<'teamId'> = { teamId: ':teamId' }) =>
								`/v1/schedules/teams/${teamId}/rights`,
						},
					},
				},
				timeTemplates: {
					rotation: {
						url: () => '/v1/schedules/time-templates/rotation',
					},
				},
			},
			uiPreference: {
				schedule: {
					team: {
						teamId: {
							url: ({ teamId }: PathParameters<'teamId'> = { teamId: ':teamId' }) =>
								`/v1/users/ui-preference/schedule/team/${teamId}`,
						},
					},
				},
			},
			onboarding: {
				completeTask: {
					url: () => '/v1/onboarding/complete-task',
				},
			},
		} as const,
		commonUrls,
	),
);

export { urls };
