import React, { memo } from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import {
	setPerformanceMarkLoadStart,
	setPerformanceMarkLoadEnd,
} from '@atlassian/jira-navigation-apps-sidebar-common';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-common/async';
import type HelpCenterSettingsSidebarType from './index';

export const LazyHelpCenterSettingsSidebar = lazyAfterPaint<typeof HelpCenterSettingsSidebarType>(
	() => {
		setPerformanceMarkLoadStart();

		return import(/* webpackChunkName: "async-sidebar-help-center-settings" */ './index').then(
			(bundle) => {
				setPerformanceMarkLoadEnd();
				return bundle;
			},
		);
	},
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const HelpCenterSettingsSidebar = memo<Record<any, any>>(() => (
	<SidebarBoundary id="servicedeskProject">
		<LazyHelpCenterSettingsSidebar />
	</SidebarBoundary>
));

HelpCenterSettingsSidebar.displayName = 'HelpCenterSettingsSidebar';

export default HelpCenterSettingsSidebar;
