import { ROUTE_NAMES } from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';
// eslint-disable-next-line jira/import-whitelist
import { SYSTEM_DASHBOARD_ID } from '@atlassian/jira-dashboard-common';
import type { RouterContext, RouterDataContext } from '@atlassian/jira-router';

export const getKey = ({ route, query, match: { params } }: RouterContext | RouterDataContext) => {
	if (route.name === ROUTE_NAMES.SYSTEM_DASHBOARD_EDIT) {
		return SYSTEM_DASHBOARD_ID;
	}

	if (route.name === ROUTE_NAMES.DASHBOARD_WALLBOARD) {
		// NOTE - Currently we will only load the first dashboard in the list
		return query.dashboardId?.split(',')[0];
	}

	return (
		params.dashboardId?.replace('last-visited', 'lastVisited') ??
		query.selectPageId ??
		'lastVisited'
	);
};
