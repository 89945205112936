import {
	type MatcherLocation,
	matchUrlExact,
} from '@atlassian/jira-navigation-apps-sidebar-common';

const VIEW_AUDITING_PATH_REGEXP = /\/auditing\/(\w+)/i;

export const isViewAuditingSelected = (
	currentLocation: MatcherLocation,
	itemLocation: MatcherLocation,
): boolean =>
	matchUrlExact()(currentLocation, itemLocation) ||
	VIEW_AUDITING_PATH_REGEXP.test(currentLocation.pathname);
