import { chromelessLayout } from '@atlassian/jira-chromeless-layout';
import { createEntry } from '@atlassian/jira-route-entry';
import { userSegmentationRoute } from '@atlassian/jira-router-routes-user-segmentation-routes';
import { UserSegmentation, LazyUserSegmentation } from './ui';

export const userSegmentationRouteEntry = createEntry(userSegmentationRoute, {
	ufoName: 'user-segmentation',
	component: UserSegmentation,
	layout: chromelessLayout,
	forPaint: [LazyUserSegmentation],
});
