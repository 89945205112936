import React from 'react';
import { LegacyBreadcrumbAdapter } from '@atlassian/jira-breadcrumbs';

const BreadcrumbAdapter = () => {
	const baseUrl = '';

	// @ts-expect-error - TS2322 - Type '{ baseUrl: string; }' is not assignable to type 'IntrinsicAttributes & Omit<PropsWithDefault, "baseUrl" | keyof InjectedProps>'.
	return <LegacyBreadcrumbAdapter baseUrl={baseUrl} />;
};

export { BreadcrumbAdapter };
