import type { Match, Query } from '@atlassian/jira-router';
/**
 * Determines the key and ID for a project based on URL parameters or query strings.
 * It handles the extraction of project keys and IDs from multiple potential sources
 * in the URL query parameters, providing a unified object containing these details.
 */
export const getProjectKeyId = (match: Match, query: Query) => {
	// handle reports legacy url or project settings details
	const projectId = query.selectedProjectId || query.pid || query.projectId || query['project.id'];
	const projectKey =
		match.params.projectKey ||
		query.projectKey ||
		query['project.key'] ||
		(match.params.issueKey || '').split('-')[0];
	return {
		projectKey,
		projectId,
	};
};
