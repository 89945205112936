import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithFF } from '@atlassian/jira-feature-flagging-utils';
import type GlobalPermissions from '@atlassian/jira-global-permissions';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarGlobalSettingsSystem from '@atlassian/jira-navigation-apps-sidebar-global-settings-system/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { globalPermissionsResource } from '@atlassian/jira-router-resources-admin-pages-permissions';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { globalSettingsGlobalPermissionsRoute } from '@atlassian/jira-router-routes-admin-pages-global-permissions-routes';
import { ErrorPagesNotFound } from '@atlassian/jira-router-routes-common';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyGlobalPermissions = lazyForPaint<typeof GlobalPermissions>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-global-permissions", jiraSpaEntry: "async-global-permissions" */ '@atlassian/jira-global-permissions'
		),
	),
);

export const LazyGlobalPermissionsPage = () => (
	<LazyPage Page={LazyGlobalPermissions} pageId="jira-global-permissions" shouldShowSpinner />
);

export const globalPermissionsRouteEntries: Route[] = [
	createEntry(globalSettingsGlobalPermissionsRoute, {
		component: componentWithFF(
			'everest-global-permissions-fe-modernisation_vvy6j',
			LazyGlobalPermissionsPage,
			ErrorPagesNotFound,
		),

		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsSystem,
		},

		resources: [
			...getNavigationResources(),
			navigationSidebarGlobalResource,
			globalPermissionsResource,
		],

		canTransitionIn: () => ff('everest-global-permissions-fe-modernisation_vvy6j'),
		forPaint: [LazyAtlassianNavigation, LazyGlobalPermissions],

		meta: {
			reporting: {
				id: 'GlobalPermissions',
				packageName: 'jiraAdminPagesGlobalPermissions',
				teamName: 'olympus',
			},
		},
	}),
];
