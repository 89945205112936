import { JiraServicedeskQueueAri } from '@atlassian/ari/jira-servicedesk/queue';
import { JiraSoftwareBoardAri } from '@atlassian/ari/jira-software/board';
import { JiraDashboardAri } from '@atlassian/ari/jira/dashboard';
import { JiraFilterAri } from '@atlassian/ari/jira/filter';
import { JiraPlanAri } from '@atlassian/ari/jira/plan';
import { JiraProjectAri } from '@atlassian/ari/jira/project';
import type {
	ChangeFavouriteMutationPayload,
	ChangeFavouritePayload,
	ItemType,
	ItemTypename,
} from '../../../model/types';

function assertUnreachable(_: never): never {
	throw new Error('Unexpected entity type');
}

const isSystemFilter = (filterId: string) => /^-\d+$/.test(filterId);

export const favouriteTypeToTypename = (
	type: ItemType,
	resourceId: string,
): ItemTypename | undefined => {
	switch (type) {
		case 'projects':
			return 'JiraProject';
		case 'filters':
			return isSystemFilter(resourceId) ? 'JiraSystemFilter' : 'JiraCustomFilter';
		case 'dashboards':
			return 'JiraDashboard';
		case 'plans':
			return 'JiraPlan';
		case 'boards':
			return 'JiraBoard';
		case 'queues':
			return 'JiraServiceManagementQueue';
		default:
			return undefined;
	}
};

const favouriteAriToId = (typename: ItemTypename, ari: string): string => {
	switch (typename) {
		case 'JiraProject':
			return JiraProjectAri.parse(ari).projectId;
		case 'JiraCustomFilter':
		case 'JiraSystemFilter':
			return JiraFilterAri.parse(ari).filterId;
		case 'JiraDashboard':
			return JiraDashboardAri.parse(ari).dashboardId;
		case 'JiraPlan':
			return JiraPlanAri.parse(ari).planId;
		case 'JiraBoard':
			return JiraSoftwareBoardAri.parse(ari).boardId;
		case 'JiraServiceManagementQueue':
			return JiraServicedeskQueueAri.parse(ari).queueId;
		default:
			assertUnreachable(typename);
	}
};

const favouriteTypenameToType = (typename: ItemTypename): ItemType => {
	switch (typename) {
		case 'JiraProject':
			return 'projects';
		case 'JiraCustomFilter':
		case 'JiraSystemFilter':
			return 'filters';
		case 'JiraDashboard':
			return 'dashboards';
		case 'JiraPlan':
			return 'plans';
		case 'JiraBoard':
			return 'boards';
		case 'JiraServiceManagementQueue':
			return 'queues';
		default:
			assertUnreachable(typename);
	}
};

/**
 * Map the provided mutation payload to the expected item shape required by the `FavouriteChangeContext`.
 * This primarily to support existing consumers of `FavouriteChangeContext` who may be dependent on the entity ID and
 * defined enum types. Ideally the context type and underlying consumers would leverage the entity ARI and GraphQL
 * typename so we can avoid this mapping, however that would require all consumers to have adopted Relay in their
 * experience which is an ongoing effort.
 */
export const favouriteMutationToRestPayload = ({
	id,
	typename,
	value,
	beforeEntity,
}: ChangeFavouriteMutationPayload): ChangeFavouritePayload => ({
	id: favouriteAriToId(typename, id),
	type: favouriteTypenameToType(typename),
	value,
	beforeEntity: beforeEntity
		? {
				id: favouriteAriToId(beforeEntity.typename, beforeEntity.id),
				type: favouriteTypenameToType(beforeEntity.typename),
			}
		: undefined,
});
