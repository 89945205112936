import React from 'react';
import { Redirect, type RouteContext } from '@atlassian/jira-router';
import { getBusinessProjectResources } from '@atlassian/jira-router-resources-business-common';
import {
	getNavigationResources,
	getNavigationSidebarProjectSettingsResource,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common';

export const CORE_PRODUCT = 'core';
export const SOFTWARE_PRODUCT = 'software';
export const SERVICE_DESK_PRODUCT = 'servicedesk';

// This URL path is passed to the SPA router with ':projectKey' as a path param.
export const projectSettingsUrl = (product: string) =>
	`/jira/${product}/projects/:projectKey/settings`;

/**
 * Get the appropriate settings resources based on the product.
 */
export const getSettingsNavigationResources = (product: string) => {
	if (product === SERVICE_DESK_PRODUCT) {
		return getServiceDeskSettingsResources();
	}

	if (product === CORE_PRODUCT) {
		return [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getNavigationSidebarProjectSettingsResource(),
			...getBusinessProjectResources(),
		];
	}

	return [
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		...getNavigationSidebarProjectSettingsResource(),
	];
};

export const defaultRedirectForSettings =
	(product: string) =>
	({ match }: RouteContext) => (
		<Redirect
			to={`/jira/${product}/projects/${String(match.params.projectKey)}/settings/details`}
		/>
	);

export const defaultClassicRedirectForSettings =
	(product: string) =>
	({ match }: RouteContext) => (
		<Redirect
			to={`/jira/${product}/c/projects/${String(match.params.projectKey)}/settings/details`}
		/>
	);
