import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type LazyAtlassianAnalyticsAppType from '@atlassian/jira-spa-apps-servicedesk-atlassian-analytics';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyAtlassianAnalytics = lazyForPaint<typeof LazyAtlassianAnalyticsAppType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-servicedesk-atlassian-analytics", jiraSpaEntry: "async-servicedesk-atlassian-analytics" */ '@atlassian/jira-spa-apps-servicedesk-atlassian-analytics'
		),
	),
);

export const AtlassianAnalytics = () => (
	<LazyPage
		Page={LazyAtlassianAnalytics}
		pageId="spa-apps/servicedesk/atlassian-analytics"
		shouldShowSpinner
	/>
);
