import { log } from '@atlassian/jira-common-util-logging';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { performPostRequest } from '@atlassian/jira-fetch';
import type { Field } from '../../types';

const PAGE_SIZE = 100;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Handler = (promise: Promise<any>) => Promise<Field[]>;

export const fetchFields = async (
	query: string,
	handler: Handler,
	params?: { [key: string]: string },
) => {
	try {
		const promise = performPostRequest('/gateway/api/graphql', {
			body: JSON.stringify({
				query,
				variables: {
					first: PAGE_SIZE,
					...params,
				},
			}),
			headers: {
				'content-type': 'application/json',
				'X-ExperimentalApi': 'JiraJqlBuilder',
			},
		});

		return await handler(promise);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		log.safeErrorWithoutCustomerData(
			'jiraRouterResourcesBusinessFields',
			'Something went wrong while fetching fields',
		);

		const isClientError = error?.statusCode >= 400 && error?.statusCode < 500;
		if (!isClientError) {
			fireErrorAnalytics({
				meta: {
					id: 'fieldsResource',
					packageName: 'jiraRouterResourcesBusinessFields',
					teamName: 'wanjel',
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});
		}
	}

	return null;
};
