import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { componentWithFF } from '@atlassian/jira-feature-flagging-utils';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncHelpCenterSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-help-center-settings/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context';
import { getServicedeskPageContextResources } from '@atlassian/jira-router-resources-service-desk-common';
import { servicedeskIssueTypesResource } from '@atlassian/jira-router-resources-service-desk-issue-types';
import { servicedeskPortalGroupsResource } from '@atlassian/jira-router-resources-service-desk-portal-groups';
import { servicedeskPracticesResource } from '@atlassian/jira-router-resources-service-desk-practices';
import { ErrorPagesNotFound } from '@atlassian/jira-router-routes-common';
import {
	helpCenterSettingsRoute,
	helpCenterSettingsKbRoute,
	helpCenterSettingsCustomerNotificationsRoute,
	helpCenterSettingsEmailRoute,
	helpCenterSettingsUnassignedTicketTypeFieldsRoute,
	helpCenterSettingsUnassignedTicketTypesConfigRoute,
} from '@atlassian/jira-router-routes-help-center-settings-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import {
	HelpCenterSettingsCustomerNotifications,
	LazyHelpCenterSettingsCustomerNotifications,
} from './ui/customer-notifications';
import { HelpCenterSettingsEmail, LazyHelpCenterSettingsEmail } from './ui/email';
import {
	HelpCenterSettingsKnowledgeBase,
	LazyHelpCenterSettingsKnowledgeBase,
} from './ui/knowledge-base';
import { getSettingsNavigationResources, SERVICE_DESK_PRODUCT } from './ui/product-routes/common';
import { Redirect } from './ui/redirect';
import {
	HelpCenterSettingsTicketTypeFields,
	LazyHelpCenterSettingsTicketTypeFields,
} from './ui/ticket-type-fields';
import {
	HelpCenterSettingsTicketTypesConfig,
	LazyHelpCenterSettingsTicketTypesConfig,
} from './ui/ticket-types-config';

const navigation = {
	sidebarId: SIDEBAR_ID.HELP_CENTER_SETTINGS,
	menuId: MENU_ID.HELP_CENTER_SETTINGS,
	sidebar: AsyncHelpCenterSettingsSidebar,
};

export const helpCenterSettingsRedirectRouteEntry: Route = createEntry(helpCenterSettingsRoute, {
	layout: serviceProjectLayout,
	navigation,
	component: Redirect,
	forPaint: [LazyAtlassianNavigation],
	resources: [...getSettingsNavigationResources(SERVICE_DESK_PRODUCT)],
});

export const helpCenterSettingsKbRouteEntry: Route = createEntry(helpCenterSettingsKbRoute, {
	perfMetricKey: 'help-center-settings.kb',
	layout: serviceProjectLayout,
	navigation,
	component: componentWithFF(
		'jcs-master-flag',
		HelpCenterSettingsKnowledgeBase,
		ErrorPagesNotFound,
	),
	forPaint: [LazyAtlassianNavigation, LazyHelpCenterSettingsKnowledgeBase],
	resources: [
		...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
		// TODO: https://jplat.jira.atlassian.cloud/browse/BERRY-2884
		...getServicedeskPageContextResources(),
		getConsolidationStateResource(),
	],
});

export const helpCenterSettingsCustomerNotificationsRouteEntry: Route = createEntry(
	helpCenterSettingsCustomerNotificationsRoute,
	{
		perfMetricKey: 'help-center-settings.customer-notifications',
		layout: serviceProjectLayout,
		navigation,
		component: componentWithFF(
			'jcs-master-flag',
			HelpCenterSettingsCustomerNotifications,
			ErrorPagesNotFound,
		),
		forPaint: [LazyAtlassianNavigation, LazyHelpCenterSettingsCustomerNotifications],
		resources: [...getSettingsNavigationResources(SERVICE_DESK_PRODUCT)],
	},
);

export const helpCenterSettingsEmailRouteEntry: Route = createEntry(helpCenterSettingsEmailRoute, {
	perfMetricKey: 'help-center-settings.email',
	layout: serviceProjectLayout,
	navigation,
	component: componentWithFF('jcs-master-flag', HelpCenterSettingsEmail, ErrorPagesNotFound),
	forPaint: [LazyAtlassianNavigation, LazyHelpCenterSettingsEmail],
	resources: [...getSettingsNavigationResources(SERVICE_DESK_PRODUCT)],
});

export const helpCenterSettingsTicketTypesConfigRouteEntry: Route = createEntry(
	helpCenterSettingsUnassignedTicketTypesConfigRoute,
	{
		perfMetricKey: 'help-center-settings-request-types-configuration',
		layout: serviceProjectLayout,
		navigation,
		component: componentWithFF(
			'jcs-master-flag',
			HelpCenterSettingsTicketTypesConfig,
			ErrorPagesNotFound,
		),
		forPaint: [LazyAtlassianNavigation, LazyHelpCenterSettingsTicketTypesConfig],
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			projectContextResource,
			servicedeskPracticesResource,
			servicedeskIssueTypesResource,
			servicedeskPortalGroupsResource,
			getConsolidationStateResource(),
		],
	},
);

export const helpCenterSettingsTicketTypeFieldsRouteEntry: Route = createEntry(
	helpCenterSettingsUnassignedTicketTypeFieldsRoute,
	{
		perfMetricKey: 'help-center-settings-request-types-fields',
		layout: serviceProjectLayout,
		navigation,
		component: componentWithFF(
			'jcs-master-flag',
			HelpCenterSettingsTicketTypeFields,
			ErrorPagesNotFound,
		),
		forPaint: [LazyAtlassianNavigation, LazyHelpCenterSettingsTicketTypeFields],
		resources: [...getSettingsNavigationResources(SERVICE_DESK_PRODUCT)],
	},
);
