import { coreProjectLayout } from '@atlassian/jira-core-project-layout';
import { composeLayouts, type Layout } from '@atlassian/jira-route-layout';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import {
	classicSoftwareProjectLayout,
	softwareProjectLayout,
} from '@atlassian/jira-software-project-layout';
import { userBoardLayout } from '@atlassian/jira-user-board-layout';

// TODO Remove this when all route paths are no longer ambiguous
export const genericProjectLayout: Layout = composeLayouts(
	classicSoftwareProjectLayout,
	coreProjectLayout,
	serviceProjectLayout,
	softwareProjectLayout,
	userBoardLayout,
);
