import React, {
	type ComponentType,
	type Ref,
	forwardRef,
	useRef,
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
} from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { IntlContext, type IntlShape as IntlShapeV5 } from 'react-intl-next';
import { getRelativeTimeAndUnit } from './formatted-relative';
import type { IntlShapeV2 } from './types';

type InjectIntlOptions = {
	intlPropName?: string;
	withRef?: boolean;
};

const IntlConsumer = IntlContext.Consumer;

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
declare type InjectIntlVoidProps = { intl: IntlShapeV2 | void };

const isDisplayableString = (mayBeString?: string | null): boolean =>
	typeof mayBeString === 'string' && mayBeString !== '';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getDisplayName<C extends ComponentType<any>>(Component: C): string {
	if (isDisplayableString(Component.displayName)) {
		// @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'string'.
		return Component.displayName;
	}

	if (isDisplayableString(Component.name)) {
		return Component.name;
	}
	return 'Component';
}

type WithIntlProps<P> = Omit<P, keyof InjectIntlVoidProps> & {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	forwardedRef?: Ref<any>;
};

/**
 * @deprecated use useIntl
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const injectIntlV2 = <C extends ComponentType<any>>(
	WrappedComponent: C,
	options?: InjectIntlOptions,
) => {
	const { withRef = false } = options || {};

	const WithIntl = (props: WithIntlProps<JSX.LibraryManagedAttributes<C, ComponentProps<C>>>) => {
		const intlRef = useRef<IntlShapeV2 | null>(null);

		return (
			<IntlConsumer>
				{(intl: IntlShapeV5 | null) => {
					if (!intl) {
						throw new Error('REACT_INTL_FACADE: React Intl Could not find required `intl` object');
					}

					if (!intlRef.current) {
						intlRef.current = {
							// Now is deprecated since version 2: https://github.com/formatjs/formatjs/issues/1409
							now: () => Date.now(),
							// @ts-expect-error We force v5 messages type on v2 type
							messages: intl.messages,
							locale: intl.locale,
							formats: intl.formats,
							formatDate: intl.formatDate,
							formatMessage: intl.formatMessage,
							formatHTMLMessage: (descriptor, values) =>
								intl.formatMessage(descriptor, values, { ignoreTag: true }),
							formatNumber: intl.formatNumber,
							formatRelative: (date, opts) => {
								const { time, unit } = getRelativeTimeAndUnit(date);
								return intl.formatRelativeTime(time, unit, opts);
							},
							formatPlural: intl.formatPlural,
							formatTime: intl.formatTime,
						};
					}

					return (
						// @ts-expect-error - TS2322 - Type 'Diff<LibraryManagedAttributes<Component, ComponentProps<Component>>, InjectIntlVoidProps> & { ...; } & { ...; }' is not assignable to type 'IntrinsicAttributes & LibraryManagedAttributes<Component, PropsWithChildren<P>>'.
						<WrappedComponent
							{...props}
							intl={intlRef.current}
							ref={withRef ? props.forwardedRef : null}
						/>
					);
				}}
			</IntlConsumer>
		);
	};
	// Changed this display name to be capital letter to prevent snapshots from failing, here is the original: https://github.com/formatjs/formatjs/blob/main/packages/react-intl/src/components/injectIntl.tsx#L98
	WithIntl.displayName = `InjectIntl(${getDisplayName(WrappedComponent)})`;
	WithIntl.WrappedComponent = WrappedComponent;

	if (withRef) {
		// @ts-expect-error - TS2352 - Conversion of type 'ForwardRefExoticComponent<PropsWithoutRef<WithIntlProps<LibraryManagedAttributes<C, ComponentProps<C>>>> & RefAttributes<...>> & NonReactStatics<...>' to type 'ComponentType<WithIntlProps<LibraryManagedAttributes<C, ComponentProps<C>>>>' may be a mistake because neither type sufficiently overlaps with the other. If this was intentional, convert the expression to 'unknown' first.
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return hoistNonReactStatics(
			forwardRef((props, ref) => <WithIntl {...props} forwardedRef={ref} />),
			WrappedComponent,
		) as ComponentType<WithIntlProps<JSX.LibraryManagedAttributes<C, ComponentProps<C>>>>;
	}

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return hoistNonReactStatics(WithIntl, WrappedComponent) as ComponentType<
		WithIntlProps<JSX.LibraryManagedAttributes<C, ComponentProps<C>>>
	>;
};
