/**
 * Why do we need the initialSelectedPath? It all stems from the workaround that Nav4 uses
 * to SSR the initial sidebar semi-correctly, while pretending to lazy-load everything else.
 *
 * Ideally we would be able to pre-load entrypoints that we know we need to load, and lazy-load
 * the rest. This would support SSR and be performant.
 * Unfortunately, unless all of the Jira routes are loaded via entry-points, we cannot use
 * this approach.
 *
 * The workaround is to use Relay variables to tell Relay what data to preload, and then load
 * the corresponding UI component directly (not via an entrypoint) when we have the data for it.
 * When we don't have the preloaded data, we lazy load __the same component via an entrypoint__.
 * In other words, we sacrifice bundle size (by directly including the component for the data-available
 * case), in order to get SSR benefits.
 *
 * A consequence of this approach is we have two versions of the top-level menu item components - the
 * regular version (called *Landing) and the entrypoint version (called *Default). When navigating,
 * the router's state would change, causing the components to switch around. In the UI, this would
 * cause ExpandableMenuItem components to collapse, when they were open before navigating.
 *
 * To workaround THIS problem, we store a computed-version of the initial state of the router, and
 * then query this value when deciding which component to show (the *Landing or the *Default). Because
 * the initial state doesn't change, our components won't switch around after the first render.
 */

import once from 'lodash/once';
import { routeToSelectedPath } from './routeToSelectedPath';

// This function is a workaround for when we want this path in a RRR resource, which doesn't support hooks.
export const getInitialSelectedPath = once(routeToSelectedPath);
