import { useMemo } from 'react';
import type { GlobalPage } from '@atlassian/jira-forge-ui-types';
import { doesExtensionMeetConditions } from '@atlassian/jira-forge-ui-utils/src/utils/conditions';
import { parseExtensionId } from '@atlassian/jira-forge-ui-utils/src/utils/parse-extension-id';
import { forgeGlobalModuleResource } from '@atlassian/jira-navigation-apps-resources';
import type { UseNavigationResourceReturn } from '@atlassian/jira-navigation-apps-sidebar-forge/src/controllers/types';
import { useResource } from '@atlassian/jira-router';

export const getItemHref = (extension: GlobalPage) => {
	const { appId, envId } = parseExtensionId(extension.id);
	return `/jira/apps/${appId}/${envId}`;
};

export const useGlobalForgeApps = (): UseNavigationResourceReturn<GlobalPage[]> => {
	const { data, loading, error } = useResource(forgeGlobalModuleResource);
	const globalPageData = useMemo(() => data?.extensions || null, [data]);

	return useMemo<UseNavigationResourceReturn<GlobalPage[]>>(
		() => ({
			data: globalPageData
				? globalPageData.filter((extension) => doesExtensionMeetConditions(extension, {}))
				: null,
			loading,
			error,
		}),
		[globalPageData, loading, error],
	);
};
