import React from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { BannerAnnouncement as BannerAnnouncementType } from './banner';

const BannerAnnouncement = lazyAfterPaint<typeof BannerAnnouncementType>(
	() =>
		import(/* webpackChunkName: "async-platform-ui-banners-announcement-v2" */ './banner-v2').then(
			(module) => module.BannerAnnouncement,
		),
	{ ssr: true },
);

export const BannerAnnouncementAsync = () => (
	<Placeholder name="async-platform-ui-banners-announcement" fallback={null}>
		<BannerAnnouncement />
	</Placeholder>
);
