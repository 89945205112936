import React, { useCallback, useMemo } from 'react';
import { type JSErrorBoundaryProps, JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import { getUserLocation } from '@atlassian/jira-platform-router-utils';
import { Fallback } from './fallback';

export const SidebarErrorBoundary = (props: JSErrorBoundaryProps) => {
	const userLocation = getUserLocation();

	const extraEventData = useMemo(
		() => ({ ...props.extraEventData, userLocation }),
		[props.extraEventData, userLocation],
	);

	const fallback = useCallback(({ error }: { error: Error }) => <Fallback error={error} />, []);
	return <JSErrorBoundary {...props} extraEventData={extraEventData} fallback={fallback} />;
};
