import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type GlobalMailSettingsType from '@atlassian/jira-spa-apps-global-mail-settings';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyGlobalMailSettings = lazyForPaint<typeof GlobalMailSettingsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-global-mail-settings", jiraSpaEntry: "async-global-mail-settings" */ '@atlassian/jira-spa-apps-global-mail-settings'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazyGlobalMailSettings} pageId="global-mail-settings" shouldShowSpinner={false} />
);
