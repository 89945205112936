import { fetch } from '@atlassian/eoc-fetch';

import { type GetTimezonesResponse } from '../../../common/types';
import { urls } from '../../constants';

const getTimezones = async () => {
	const response = await fetch<GetTimezonesResponse>(urls.customers.timezones.url());

	// NOTE: convert offset from minutes to millis
	const adjusted = response.timezones.map((timezone) => ({
		...timezone,
		offset: timezone.offset * 60000,
		currentOffset: timezone.currentOffset * 60000,
	}));

	return {
		...response,
		timezones: adjusted,
	};
};

export const endpoints = {
	getTimezones,
};
