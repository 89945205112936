import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const softwareDeploymentsRoute = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_DEPLOYMENTS,
	path: '/jira/software/projects/:projectKey/deployments',
};

export const softwareDeploymentsClassicRoute = {
	group: ROUTE_GROUPS.CLASSIC_SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_DEPLOYMENTS_CLASSIC,
	path: '/jira/software/c/projects/:projectKey/deployments',
};
