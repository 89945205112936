import React from 'react';
import { render } from 'react-dom';
import { lazyForPaint, lazyAfterPaint } from 'react-loosely-lazy';
import { log } from '@atlassian/jira-common-util-logging';
import { JiraContextContainer } from '@atlassian/jira-context-container';
import { FlagsBoundary } from '@atlassian/jira-flag-boundary';
import { StrictMode } from '@atlassian/jira-react-strict-mode';

const throwError = (message: string) => {
	const error = new Error(message);
	log.safeErrorWithoutCustomerData('flags.entry', message, error);
	throw error;
};

const getContainer = () => {
	let container = document.getElementById('flags');
	if (container == null) {
		container = document.createElement('div');
		container.id = 'flags';
		if (document.body) {
			document.body.appendChild(container);
		} else {
			throwError('document.body was not found whilst trying to create the flags container');
		}
	}
	return container;
};

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const Flags = lazyForPaint(
	() => import(/* webpackChunkName: "async-flags" */ '@atlassian/jira-flag-app/src/main'),
	{ ssr: false },
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AfterPaintFlags = lazyAfterPaint(
	() =>
		// eslint-disable-next-line jira/import/webpack-magic-comment-entries
		import(/* webpackChunkName: "async-flags" */ '@atlassian/jira-flag-app/src/main'),
	{ ssr: false },
);

export const renderFlags = () =>
	render(
		<StrictMode>
			<JiraContextContainer>
				<FlagsBoundary packageName="flags-entry">
					{__SPA__ ? <AfterPaintFlags /> : <Flags />}
				</FlagsBoundary>
			</JiraContextContainer>
		</StrictMode>,
		getContainer(),
	);
