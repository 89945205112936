import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { createResource } from '@atlassian/jira-router';
import type { OgConsolidationUserState } from './common/types';
import { getOgConsolidationUserState } from './services';

export { OG_CONSOLIDATION_USER_STATE_STORAGE_KEY } from './common/constants';
export type { OgConsolidationUserState } from './common/types';

const RESOURCE_KEY = 'RESOURCE_TYPE_EOC_OG_CONSOLIDATION_USER_STATE';

export const consolidationUserStateResource = createResource<OgConsolidationUserState>({
	type: RESOURCE_KEY,
	getKey: () => RESOURCE_KEY,
	getData: (_, { tenantContext: { atlassianAccountId: accountId } }) =>
		getOgConsolidationUserState(accountId).catch((error) => {
			fireErrorAnalytics({
				meta: {
					id: 'consolidationUserStateResource',
					packageName: 'jiraRouterResourcesEocOgConsolidationUserState',
					teamName: 'alexstrasza',
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});
			throw error;
		}),
	maxAge: Infinity,
});
