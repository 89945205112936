import type { Layout } from '../../types';

export const layoutWithCondition = (
	conditionGetter: () => boolean,
	itemTrue: Layout,
	itemFalse: Layout,
): Layout => ({
	get isChromeless() {
		return conditionGetter() ? itemTrue.isChromeless : itemFalse.isChromeless;
	},
	get globalComponents() {
		return conditionGetter() ? itemTrue.globalComponents : itemFalse.globalComponents;
	},
	get rightSidebars() {
		return conditionGetter() ? itemTrue.rightSidebars : itemFalse.rightSidebars;
	},
});
