import { fetchJson } from '@atlassian/jira-fetch';
import type { ProductFeatures } from '@atlassian/jira-project-settings-apps-issue-type-page-product-features';
import type { Result } from '../../types';
import { getAllPropertiesKeys } from './utils';

const getIssueTypesNavigationItemsQuery = (projectId: number) => `query IssueTypesNavigationItems {
    simplifiedProject(projectId: ${projectId}) {
        issueTypeCollection {
            limitPerProject
            data {
                id
                name
                avatarId
                hierarchyLevel
                operations {
                    deletable
                    editable
                }                                
            }
        }            
    }
  }`;

const getIssueTypesNavigationItemsWithPropertiesQuery = (
	projectId: number,
	keys: string[],
) => `query IssueTypesNavigationItemsWithProperties {
    simplifiedProject(projectId: ${projectId}) {
        issueTypeCollection {
            limitPerProject
            data {
                id
                name
                avatarId
                hierarchyLevel
                operations {
                    deletable
                    editable
                }                
                properties(keys: ${JSON.stringify(keys)})
            }
        }            
    }
  }`;

const queryIssueTypesNavigationItems = (
	projectId: number,
	productFeatures: ProductFeatures,
): Promise<Result> => {
	const propertyKeys = getAllPropertiesKeys(productFeatures);
	const query = propertyKeys.length
		? getIssueTypesNavigationItemsWithPropertiesQuery(projectId, propertyKeys)
		: getIssueTypesNavigationItemsQuery(projectId);

	return fetchJson('/rest/gira/latest/?issue-type-page-navigation', {
		method: 'POST',
		body: JSON.stringify({
			query,
		}),
	});
};

export { queryIssueTypesNavigationItems };
