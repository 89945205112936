import { createStore, createHook } from 'react-sweet-state';
import actions from './actions';
import { COHORT_DETAILS_DEFAULT_STATE } from './constants';

const Store = createStore({
	initialState: COHORT_DETAILS_DEFAULT_STATE,
	actions,
	name: 'Migrations - Cohort details',
});

export const useCohortDetails = createHook(Store);
