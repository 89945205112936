import type { Route } from '@atlassian/jira-router';
import { projectSettingsCoreRouteEntries } from '@atlassian/jira-router-routes-project-settings-core-entries';
import { serviceDeskRouteEntries } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries';
import { softwareRouteEntries } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries';
/*
 *************************************************** ATTENTION ***************************************************
 * Take special care with any redirect components you render. For legacy routes they should redirect to the legacy URL,
 * and for product routes the should redirect to the product URL.
 */
export const projectSettingsRouteEntries: Route[] = [
	...softwareRouteEntries,
	...serviceDeskRouteEntries,
	...projectSettingsCoreRouteEntries,
];
