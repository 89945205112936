/* eslint-disable complexity */
import React from 'react';
import { Section, SkeletonItem } from '@atlaskit/side-navigation';
import { getBaseAutomationUrl } from '@atlassian/jira-automation-menu/src/common/utils';
import { CORE_PROJECT } from '@atlassian/jira-common-constants';
import type { Project } from '@atlassian/jira-common-constants/src/project-types';
import {
	ROUTE_NAMES_PROJECT_SETTINGS_CORE_NOTIFICATIONS,
	ROUTE_NAMES_PROJECT_SETTINGS_CORE_NOTIFICATION_EMAIL,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_NOTIFICATIONS,
	ROUTE_NAMES_PROJECT_SETTINGS_CORE_DETAILS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_EDIT,
	ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_CORE_PROFORMA_FORMS,
	ROUTE_NAMES_PROJECT_SETTINGS_VERSIONS,
	ROUTE_NAMES_PROJECT_SETTINGS_COMPONENTS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_COLLECTORS,
} from '@atlassian/jira-common-constants/src/spa-routes';
import { ff } from '@atlassian/jira-feature-flagging';
import { PROJECT_SETTINGS_PAGE_MODULE } from '@atlassian/jira-forge-ui-constants';
import { useIntl, type IntlShape } from '@atlassian/jira-intl';
import {
	EngagementSpotlight,
	FireUiAnalytics,
	MenuItem,
	SpotlightTarget,
	TitleHeader,
	matchUrlPathname,
	useProject,
	ExpandableSubmenuItem,
	NotificationsSubMenu,
} from '@atlassian/jira-navigation-apps-sidebar-common';
import { withThrowOnError } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/throw-when-no-data-and-error';
import { EcosystemMenu } from '@atlassian/jira-navigation-apps-sidebar-ecosystem';
import {
	useNewIssueViewLockedInStatus,
	DEFAULT_TO_OLD_ISSUE_VIEW,
} from '@atlassian/jira-router-resources-new-issue-view-transition-lock-in-status';
import { toProjectKey } from '@atlassian/jira-shared-types';
import { useDlpEntitlement } from '@atlassian/jira-tenant-context-controller';
import { useSettingFeatures } from '../../../../../../controllers/features';
import messages from './messages';
import type { SettingsProps } from './types';

export const useThrowProject = withThrowOnError(useProject);
export const useThrowSettingFeatures = withThrowOnError(useSettingFeatures);
export const useThrowNewIssueViewLockedInStatus = withThrowOnError(useNewIssueViewLockedInStatus);

const NOTIFICATIONS_ROUTE_NAMES = [
	ROUTE_NAMES_PROJECT_SETTINGS_CORE_NOTIFICATIONS,
	ROUTE_NAMES_PROJECT_SETTINGS_CORE_NOTIFICATION_EMAIL,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_NOTIFICATIONS,
];

// will be cleaned with FF
// - jlovep-1857-enable-project-email-audit-pages-for-business-projects_tz5uu cleanup
interface DisplayNotificationsOptionsProps {
	hasNotifications: boolean;
	formatMessage: IntlShape['formatMessage'];
	project: Project;
}

// will be cleaned with FF cleanup
const DisplayNotificationsOptions = ({
	hasNotifications,
	formatMessage,
	project,
}: DisplayNotificationsOptionsProps) => {
	if (!hasNotifications) {
		return <></>;
	}

	if (ff('jlovep-1857-enable-project-email-audit-pages-for-business-projects_tz5uu')) {
		return (
			<ExpandableSubmenuItem
				analytics={{ itemId: 'notifications' }}
				menuItemLabel={formatMessage(messages.notifications)}
				forceExpand
				submenuId="core-notifications-settings"
			>
				<NotificationsSubMenu
					settingsUrl={`/plugins/servlet/project-config/${project.key}/notifications`}
					issueLogsUrl={`/jira/core/projects/${project.key}/settings/notifications/issue-logs?projectId=${project.id}`}
					displayLozenge={
						!ff('jlovep-1857-remove-lozenge-for-pea-sidebar-for-business-project_z34bw')
					}
				/>
			</ExpandableSubmenuItem>
		);
	}

	return (
		<MenuItem
			analytics={{ itemId: 'notifications' }}
			href={`/plugins/servlet/project-config/${project.key}/notifications`}
			selectedOn={NOTIFICATIONS_ROUTE_NAMES}
		>
			{formatMessage(messages.notifications)}
		</MenuItem>
	);
};

export const Settings = ({ ecosystemItems }: SettingsProps) => {
	const { data: features } = useThrowSettingFeatures();
	const { data: status } = useThrowNewIssueViewLockedInStatus();
	const isNewIssueViewLockedIn =
		status?.newIssueViewTransitionLockingStatus !== DEFAULT_TO_OLD_ISSUE_VIEW;
	const { formatMessage } = useIntl();
	const { data: project } = useThrowProject();
	const hasDlpEnabled = useDlpEntitlement();

	if (features == null || project == null) {
		return <SkeletonItem />;
	}

	return (
		<>
			<TitleHeader>{formatMessage(messages.projectSettings)}</TitleHeader>
			<Section>
				{features.hasDetails && (
					<MenuItem
						analytics={{ itemId: 'details' }}
						selectedOn={[
							ff('hix-4818-adopt-modern-project-details-page-across-all-products_3zvy1')
								? ROUTE_NAMES_PROJECT_SETTINGS_CORE_DETAILS
								: ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_EDIT,
						]}
						href={
							ff('hix-4818-adopt-modern-project-details-page-across-all-products_3zvy1')
								? `/jira/core/projects/${project.key}/settings/details`
								: `/secure/project/EditProject!default.jspa?pid=${project.id}`
						}
					>
						{formatMessage(messages.details)}
					</MenuItem>
				)}
				{features.hasPeople && (
					<MenuItem
						analytics={{ itemId: 'people' }}
						selectedOn={matchUrlPathname()}
						href={`/plugins/servlet/project-config/${project.key}/people`}
					>
						{formatMessage(messages.people)}
					</MenuItem>
				)}
				<>
					<SpotlightTarget name="spotlight-sidebar-project-automation">
						<MenuItem
							analytics={{ itemId: 'automation' }}
							selectedOn={matchUrlPathname()}
							href={getBaseAutomationUrl({
								isSimplified: project.isSimplified,
								projectKey: toProjectKey(project.key),
								projectType: CORE_PROJECT,
							})}
						>
							{formatMessage(messages.automation)}
						</MenuItem>
					</SpotlightTarget>
					<EngagementSpotlight engagementId="spotlight-sidebar-project-automation" />
					<FireUiAnalytics actionSubject="automationNavigationItem" action="viewed" />
				</>
				{features.hasSummary && (
					<MenuItem
						analytics={{ itemId: 'summary' }}
						selectedOn={matchUrlPathname()}
						href={`/plugins/servlet/project-config/${project.key}/summary`}
					>
						{formatMessage(messages.summary)}
					</MenuItem>
				)}
			</Section>
			<Section
				hasSeparator={
					features.hasIssueConfigSection ||
					isNewIssueViewLockedIn ||
					ff('proforma-mvp-jwm-project-settings_5edja')
				}
			>
				{features.hasIssueTypes && (
					<MenuItem
						selectedOn={matchUrlPathname()}
						analytics={{ itemId: 'issue-types' }}
						href={`/plugins/servlet/project-config/${project.key}/issuetypes`}
					>
						{formatMessage(messages.issueTypes)}
					</MenuItem>
				)}
				{isNewIssueViewLockedIn && (
					<MenuItem
						selectedOn={matchUrlPathname()}
						analytics={{ itemId: 'issue-layout' }}
						href={`/plugins/servlet/project-config/${project.key}/issuelayout`}
					>
						{formatMessage(messages.issueLayout)}
					</MenuItem>
				)}
				{ff('proforma-mvp-jwm-project-settings_5edja') && (
					<MenuItem
						analytics={{ itemId: 'proforma-forms' }}
						href={`/jira/core/projects/${project.key}/settings/forms`}
						selectedOn={[ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_CORE_PROFORMA_FORMS]}
					>
						{formatMessage(messages.forms)}
					</MenuItem>
				)}
			</Section>
			<Section hasSeparator={features.hasProjectGroupTwoSection}>
				{features.hasWorkflows && (
					<MenuItem
						selectedOn={matchUrlPathname()}
						analytics={{ itemId: 'workflows' }}
						href={`/plugins/servlet/project-config/${project.key}/workflows`}
					>
						{formatMessage(messages.workflows)}
					</MenuItem>
				)}
				{features.hasScreens && (
					<MenuItem
						selectedOn={matchUrlPathname()}
						analytics={{ itemId: 'screens' }}
						href={`/plugins/servlet/project-config/${project.key}/screens`}
					>
						{formatMessage(messages.screens)}
					</MenuItem>
				)}
				{features.hasFields && (
					<MenuItem
						selectedOn={matchUrlPathname()}
						analytics={{ itemId: 'fields' }}
						href={`/plugins/servlet/project-config/${project.key}/fields`}
					>
						{formatMessage(messages.fields)}
					</MenuItem>
				)}
			</Section>
			<Section hasSeparator={features.hasProjectGroupThreeSection}>
				{features.hasVersions && (
					<MenuItem
						selectedOn={[ROUTE_NAMES_PROJECT_SETTINGS_VERSIONS]}
						analytics={{ itemId: 'versions' }}
						href={`/plugins/servlet/project-config/${project.key}/administer-versions`}
					>
						{formatMessage(messages.versions)}
					</MenuItem>
				)}
				{features.hasComponents && (
					<MenuItem
						selectedOn={[ROUTE_NAMES_PROJECT_SETTINGS_COMPONENTS]}
						analytics={{ itemId: 'components' }}
						href={`/plugins/servlet/project-config/${project.key}/administer-components`}
					>
						{formatMessage(messages.components)}
					</MenuItem>
				)}
			</Section>
			<Section hasSeparator={features.hasProjectGroupFourSection}>
				{features.hasPermissions && (
					<MenuItem
						selectedOn={matchUrlPathname()}
						analytics={{ itemId: 'permissions' }}
						href={`/plugins/servlet/project-config/${project.key}/permissions`}
					>
						{formatMessage(messages.permissions)}
					</MenuItem>
				)}
				{features.hasIssueSecurity && (
					<MenuItem
						selectedOn={matchUrlPathname()}
						analytics={{ itemId: 'issue-security' }}
						href={`/plugins/servlet/project-config/${project.key}/issuesecurity`}
					>
						{formatMessage(messages.issueSecurity)}
					</MenuItem>
				)}
				<DisplayNotificationsOptions
					hasNotifications={features.hasNotifications}
					formatMessage={formatMessage}
					project={project}
				/>
				{features.hasDevtools && (
					<MenuItem
						selectedOn={matchUrlPathname()}
						analytics={{ itemId: 'devtools' }}
						href={`/secure/ConfigureDevStatus.jspa?projectKey=${project.key}`}
					>
						{formatMessage(messages.devtools)}
					</MenuItem>
				)}
			</Section>
			<Section hasSeparator={features.hasgetIssueCollectorSection}>
				{features.hasIssueCollectors && (
					<MenuItem
						selectedOn={[ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_COLLECTORS]}
						analytics={{ itemId: 'issue-collectors' }}
						href={`/secure/ViewCollectors!default.jspa?projectKey=${project.key}`}
					>
						{formatMessage(messages.issueCollectors)}
					</MenuItem>
				)}
			</Section>
			<EcosystemMenu
				hasSeparator
				items={ecosystemItems}
				forgeModule={PROJECT_SETTINGS_PAGE_MODULE}
				overrides={{
					BackMenuItem: {
						href: `/plugins/servlet/project-config/${project.key}`,
						title: formatMessage(messages.itemBack),
					},
				}}
			/>
			{hasDlpEnabled && (
				<Section hasSeparator>
					<MenuItem
						analytics={{ itemId: 'data-classifications' }}
						selectedOn={matchUrlPathname()}
						href={`/plugins/servlet/project-config/${project.key}/data-classifications`}
					>
						{formatMessage(messages.dataClassificationsNonFinal)}
					</MenuItem>
				</Section>
			)}
		</>
	);
};
