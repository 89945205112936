import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const systemConfigurationRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.SYSTEM_CONFIGURATION,
	path: '/jira/settings/system/general-configuration',
	exact: true,
};

export const routeNamesSystemConfigurationRedirectRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: `${ROUTE_NAMES.SYSTEM_CONFIGURATION}-redirect`,
	path: '/secure/admin/ViewApplicationProperties.jspa',
	exact: true,
};
