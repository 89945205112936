import type {
	SprintCommitmentBeaconId,
	SprintCommitmentSettings,
} from '../types/sprint-commitment';

export const SPRINT_COMMITMENT_BEACON_ID: SprintCommitmentBeaconId = 'sprint-commitment';

export const SPRINT_COMMITMENT_SETTINGS_DEFAULTS: SprintCommitmentSettings = {
	hidden: false,
};
