import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { PeriscopePageSkeleton } from '@atlassian/jira-skeletons';
import messages from './messages';

// Extend this for loading screen
export const Skeleton = () => {
	const { formatMessage } = useIntl();
	return (
		<Box
			xcss={SpinnerContainerStyles}
			data-testId="software-periscope-periscope-page.ui.loading"
			aria-label={formatMessage(messages.labelNonFinal)}
			padding="space.500"
		>
			<PeriscopePageSkeleton />
		</Box>
	);
};

const SpinnerContainerStyles = xcss({
	width: '100%',
	height: '100%',
	boxSizing: 'border-box',
});
