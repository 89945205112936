import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type PermissionSchemePageType from '@atlassian/jira-permission-scheme';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyPermissionSchemePage = lazyForPaint<typeof PermissionSchemePageType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-jira-permission-scheme", jiraSpaEntry: "async-jira-permission-scheme" */ '@atlassian/jira-permission-scheme'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazyPermissionSchemePage} pageId="jira-permission-scheme" shouldShowSpinner />
);
