import { ff } from '@atlassian/jira-feature-flagging-using-meta';

export const getEcosystemQuery = () => `
  ecosystem {
    operations {
      name
      url: href
      tooltip
      styleClass
    }
    ${
			ff('issue-view-remove-backgroundscripts-from-critical-fetch_6f6on')
				? `
    backgroundScripts {
      iframe {
        addonKey
        moduleKey
        options
      }
      shouldReloadOnRefresh
    }`
				: ''
		}
  }
`;
