import React from 'react';
import { PAGE_LAYOUT_OFFSET_TOP } from '@atlassian/jira-common-constants/src/page-layout';
/**
 * Currently, we are not able to set the height for ak Main slot.
 * Temperarly work around for this is to adding this style fix for
 * Jira page.
 */
export const MainHeightFixCss = () => (
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
	<style
		// eslint-disable-next-line react/no-danger
		dangerouslySetInnerHTML={{
			__html: `[data-ds--page-layout--slot="main"] { height: calc(100vh - ${PAGE_LAYOUT_OFFSET_TOP}) }`,
		}}
	/>
);
