import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { AsyncKbNav } from '@atlassian/jira-horizontal-nav-jsm-knowledge-base';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { kbConfluenceContextResource } from '@atlassian/jira-router-resources-confluence-context';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common';
import {
	knowledgeHubLandingRoute,
	knowledgeHubBrowseAllArticlesRoute,
	knowledgeHubDraftsRoute,
	knowledgeHubBrowseAllCategoriesRoute,
	knowledgeHubSuggestedArticlesRoute,
	knowledgeHubBrowseViewArticleRoute,
	knowledgeHubArticleEmbedEditorRoute,
	knowledgeHubAllArticlesRoute,
	knowledgeHubArticleInCategoryRoute,
	knowledgeHubCategoryPageRoute,
	knowledgeHubCategoryRoute,
	knowledgeHubSearchRoute,
	knowledgeHubSetupRoute,
} from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import { APP_NAMES, toPackageName } from '@atlassian/jira-servicedesk-common/src/utils/app-names';
import ServiceDeskKnowledgeHubBrowseAll, {
	LazyServicedeskKnowledgeHubBrowseAllArticles,
} from './ui/browse-all';
import ServiceDeskKnowledgeHubBrowseAllCategories, {
	LazyServicedeskKnowledgeHubBrowseAllCategories,
} from './ui/browse-all-categories';
import ServiceDeskKnowledgeHubCategory, {
	LazyServicedeskKnowledgeHubCategory,
} from './ui/category';
import ServiceDeskKnowledgeHubDrafts, { LazyServicedeskKnowledgeHubDrafts } from './ui/drafts';
import ServiceDeskKnowledgeArticleEmbedEditor, {
	LazyServiceDeskKnowledgeArticleEmbedEditor,
} from './ui/kb-article-embed-editor';
import ServiceDeskKnowledgeHubLandingPage, {
	LazyServicedeskKnowledgeHubLanding,
} from './ui/landing-page';
import ServiceDeskKnowledgeHubSetup, { LazyServicedeskKnowledgeHubSetup } from './ui/setup';
import {
	ServiceDeskKnowledgeHubSuggestedArticles,
	LazyServicedeskKnowledgeHubSuggestedArticles,
} from './ui/suggested-articles';
import ServiceDeskKnowledgeHubViewArticle, {
	LazyServicedeskKnowledgeHubViewArticle,
} from './ui/view-article';

const getKnowledgeHubResources = () => [
	...getServicedeskPageContextResources(),
	kbConfluenceContextResource,
];

export const knowledgeHubRouteEntries: Route[] = [
	createEntry(knowledgeHubLandingRoute, {
		component: ServiceDeskKnowledgeHubLandingPage,
		layout: serviceProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			horizontal: AsyncKbNav,
			// TODO: Remove this during jira_nav4 cleanup
			onlyShowHorziontalOnNav4: true,
		},

		resources: [
			...getServiceDeskNavigationResources(),
			...getKnowledgeHubResources(),
			getConsolidationStateResource(),
		],

		forPaint: [LazyAtlassianNavigation, LazyServicedeskKnowledgeHubLanding],

		meta: {
			reporting: {
				id: APP_NAMES.KB_LANDING,
				packageName: toPackageName(APP_NAMES.KB_LANDING),
				teamName: 'jsm-nebula',
			},
		},
	}),
	createEntry(knowledgeHubBrowseAllArticlesRoute, {
		component: ServiceDeskKnowledgeHubBrowseAll,
		layout: serviceProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			horizontal: AsyncKbNav,
			// TODO: Remove this during jira_nav4 cleanup
			onlyShowHorziontalOnNav4: true,
		},

		resources: [
			...getServiceDeskNavigationResources(),
			...getKnowledgeHubResources(),
			getConsolidationStateResource(),
		],

		forPaint: [LazyAtlassianNavigation, LazyServicedeskKnowledgeHubBrowseAllArticles],

		meta: {
			reporting: {
				id: APP_NAMES.KB_BROWSE_ARTICLES,
				packageName: toPackageName(APP_NAMES.KB_BROWSE_ARTICLES),
				teamName: 'jsm-nebula',
			},
		},
	}),
	createEntry(knowledgeHubDraftsRoute, {
		component: ServiceDeskKnowledgeHubDrafts,
		layout: serviceProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			horizontal: AsyncKbNav,
			// TODO: Remove this during jira_nav4 cleanup
			onlyShowHorziontalOnNav4: true,
		},

		resources: [
			...getServiceDeskNavigationResources(),
			...getKnowledgeHubResources(),
			getConsolidationStateResource(),
		],

		forPaint: [LazyAtlassianNavigation, LazyServicedeskKnowledgeHubDrafts],
		perfMetricKey: 'service-management.kb-drafts',

		meta: {
			reporting: {
				id: APP_NAMES.KB_DRAFTS,
				packageName: toPackageName(APP_NAMES.KB_DRAFTS),
				teamName: 'jsm-nebula',
			},
		},
	}),
	createEntry(knowledgeHubBrowseAllCategoriesRoute, {
		component: ServiceDeskKnowledgeHubBrowseAllCategories,
		layout: serviceProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			horizontal: AsyncKbNav,
			// TODO: Remove this during jira_nav4 cleanup
			onlyShowHorziontalOnNav4: true,
		},

		resources: [
			...getServiceDeskNavigationResources(),
			...getKnowledgeHubResources(),
			getConsolidationStateResource(),
		],

		forPaint: [LazyAtlassianNavigation, LazyServicedeskKnowledgeHubBrowseAllCategories],
		perfMetricKey: 'service-management.kb-categories',

		meta: {
			reporting: {
				id: APP_NAMES.KB_BROWSE_CATEGORIES,
				packageName: toPackageName(APP_NAMES.KB_BROWSE_CATEGORIES),
				teamName: 'jsm-nebula',
			},
		},
	}),
	createEntry(knowledgeHubSuggestedArticlesRoute, {
		component: ServiceDeskKnowledgeHubSuggestedArticles,
		layout: serviceProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			horizontal: AsyncKbNav,
			// TODO: Remove this during jira_nav4 cleanup
			onlyShowHorziontalOnNav4: true,
		},

		resources: [
			...getServiceDeskNavigationResources(),
			...getKnowledgeHubResources(),
			getConsolidationStateResource(),
		],

		forPaint: [LazyAtlassianNavigation, LazyServicedeskKnowledgeHubSuggestedArticles],
		perfMetricKey: 'service-management.kb-suggested-articles',

		meta: {
			reporting: {
				id: APP_NAMES.KB_SUGGESTED_ARTICLES,
				packageName: toPackageName(APP_NAMES.KB_SUGGESTED_ARTICLES),
				teamName: 'jsm-nebula',
			},
		},
	}),
	createEntry(knowledgeHubBrowseViewArticleRoute, {
		perfMetricKey: 'service-management.knowledge.article',
		component: ServiceDeskKnowledgeHubViewArticle,
		layout: serviceProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

		resources: [
			...getServiceDeskNavigationResources(),
			...getKnowledgeHubResources(),
			getConsolidationStateResource(),
		],

		forPaint: [LazyAtlassianNavigation, LazyServicedeskKnowledgeHubViewArticle],

		meta: {
			reporting: {
				id: APP_NAMES.KB_VIEW_ARTICLE,
				packageName: toPackageName(APP_NAMES.KB_VIEW_ARTICLE),
				teamName: 'jsm-nebula',
			},
		},
	}),
	createEntry(knowledgeHubArticleEmbedEditorRoute, {
		component: ServiceDeskKnowledgeArticleEmbedEditor,
		layout: serviceProjectLayout,
		resources: [...getServiceDeskNavigationResources(), ...getKnowledgeHubResources()],
		forPaint: [LazyAtlassianNavigation, LazyServiceDeskKnowledgeArticleEmbedEditor],
		perfMetricKey: 'service-management.knowledge.ep-editor',
	}),
	createEntry(knowledgeHubAllArticlesRoute, {
		component: ServiceDeskKnowledgeHubBrowseAll,
		layout: serviceProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			horizontal: AsyncKbNav,
			// TODO: Remove this during jira_nav4 cleanup
			onlyShowHorziontalOnNav4: true,
		},

		resources: [
			...getServiceDeskNavigationResources(),
			...getKnowledgeHubResources(),
			getConsolidationStateResource(),
		],

		forPaint: [LazyAtlassianNavigation, LazyServicedeskKnowledgeHubBrowseAllArticles],

		meta: {
			reporting: {
				id: APP_NAMES.KB_BROWSE_ARTICLES,
				packageName: toPackageName(APP_NAMES.KB_BROWSE_ARTICLES),
				teamName: 'jsm-nebula',
			},
		},
	}),
	createEntry(knowledgeHubArticleInCategoryRoute, {
		perfMetricKey: 'service-management.knowledge.article',
		component: ServiceDeskKnowledgeHubViewArticle,
		layout: serviceProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

		resources: [
			...getServiceDeskNavigationResources(),
			...getKnowledgeHubResources(),
			getConsolidationStateResource(),
		],

		forPaint: [LazyAtlassianNavigation, LazyServicedeskKnowledgeHubViewArticle],

		meta: {
			reporting: {
				id: APP_NAMES.KB_VIEW_ARTICLE,
				packageName: toPackageName(APP_NAMES.KB_VIEW_ARTICLE),
				teamName: 'jsm-nebula',
			},
		},
	}),
	createEntry(knowledgeHubCategoryPageRoute, {
		component: ServiceDeskKnowledgeHubCategory,
		layout: serviceProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

		resources: [
			...getServiceDeskNavigationResources(),
			...getKnowledgeHubResources(),
			getConsolidationStateResource(),
		],

		forPaint: [LazyAtlassianNavigation, LazyServicedeskKnowledgeHubCategory],

		meta: {
			reporting: {
				id: APP_NAMES.KB_CATEGORY,
				packageName: toPackageName(APP_NAMES.KB_CATEGORY),
				teamName: 'jsm-nebula',
			},
		},
	}),
	createEntry(knowledgeHubCategoryRoute, {
		ufoName: 'service-management.knowledge.category',
		component: ServiceDeskKnowledgeHubCategory,
		layout: serviceProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

		resources: [
			...getServiceDeskNavigationResources(),
			...getKnowledgeHubResources(),
			getConsolidationStateResource(),
		],

		forPaint: [LazyAtlassianNavigation, LazyServicedeskKnowledgeHubCategory],

		meta: {
			reporting: {
				id: APP_NAMES.KB_CATEGORY,
				packageName: toPackageName(APP_NAMES.KB_CATEGORY),
				teamName: 'jsm-nebula',
			},
		},
	}),
	createEntry(knowledgeHubSearchRoute, {
		component: ServiceDeskKnowledgeHubViewArticle,
		layout: serviceProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			horizontal: AsyncKbNav,
			onlyShowHorziontalOnNav4: true,
		},

		resources: [
			...getServiceDeskNavigationResources(),
			...getKnowledgeHubResources(),
			getConsolidationStateResource(),
		],

		forPaint: [LazyAtlassianNavigation, LazyServicedeskKnowledgeHubViewArticle],

		meta: {
			reporting: {
				id: APP_NAMES.KB_VIEW_ARTICLE,
				packageName: toPackageName(APP_NAMES.KB_VIEW_ARTICLE),
				teamName: 'jsm-nebula',
			},
		},
	}),
	createEntry(knowledgeHubSetupRoute, {
		component: ServiceDeskKnowledgeHubSetup,
		layout: serviceProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

		resources: [
			...getServiceDeskNavigationResources(),
			...getKnowledgeHubResources(),
			getConsolidationStateResource(),
		],

		forPaint: [LazyAtlassianNavigation, LazyServicedeskKnowledgeHubSetup],

		meta: {
			reporting: {
				id: APP_NAMES.KB_SETUP,
				packageName: toPackageName(APP_NAMES.KB_SETUP),
				teamName: 'jsm-nebula',
			},
		},
	}),
];
