/**
 * @generated SignedSource<<8221a0384be3e0c7d78b1bbaa2f66504>>
 * @relayHash 7d72a8e1ec2336ed99383dd817c25c42
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 4c5640c21c44dc3835a72f29fecfcb703a916b96913572926485bd4e862b315c

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcVirtualAgentBrowseIntentsStatisticsQuery } from './srcVirtualAgentBrowseIntentsStatisticsQuery.graphql';

const node: PreloadableConcreteRequest<srcVirtualAgentBrowseIntentsStatisticsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "4c5640c21c44dc3835a72f29fecfcb703a916b96913572926485bd4e862b315c",
    "metadata": {},
    "name": "srcVirtualAgentBrowseIntentsStatisticsQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
