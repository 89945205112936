import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalLayout } from '@atlassian/jira-global-layout';
import { createEntry } from '@atlassian/jira-route-entry';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { homeOnboardingRedirectRoute } from '@atlassian/jira-router-routes-home-onboarding-redirect-routes';
import JiraHomeOnboardingRedirect from '@atlassian/jira-spa-apps-common/src/redirect/jira-home-onboarding';

export const homeOnboardingRedirectRouteEntry = createEntry(homeOnboardingRedirectRoute, {
	component: JiraHomeOnboardingRedirect,
	layout: globalLayout,
	resources: [...getNavigationResources()],
	isRedirect: true,
	forPaint: [LazyAtlassianNavigation],
});
