import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const eocTeamCustomerLogsRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_TEAM_CUSTOMER_LOGS,
	path: '/jira/settings/products/ops/jira-service-management-audit-logs',
	exact: true,
};

export const eocTeamCustomerLogsDownloadRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_TEAM_CUSTOMER_LOGS_DOWNLOAD,
	path: '/jira/settings/products/ops/jira-service-management-audit-logs/download/:exportUniqueKey',
	exact: true,
};
