import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ProjectSettingsServiceDeskTicketTypesConfig from '@atlassian/jira-spa-apps-project-settings-service-desk-ticket-types-config';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyProjectSettingsServiceDeskTicketTypesConfig = lazyForPaint<
	typeof ProjectSettingsServiceDeskTicketTypesConfig
>(
	() =>
		import(
			/* webpackChunkName: "async-project-settings-service-desk-ticket-types-config", jiraSpaEntry: "async-project-settings-service-desk-ticket-types-config" */ '@atlassian/jira-spa-apps-project-settings-service-desk-ticket-types-config'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyProjectSettingsServiceDeskTicketTypesConfig}
		pageId="project-settings-ticket-types-config"
		shouldShowSpinner
	/>
);
