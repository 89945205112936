import queryString from 'query-string';
import type { GadgetData, PrefetchLinksType } from '../../../types';

const FILTER_RESULTS_REST_URL = 'rest/gadget/1.0/issueTable';

// IMPORTANT - The below code is copied from monolith gadgets plugin and modified to fit linting rules
// so any changes in here have to be synced with the monolith code as well.
// https://stash.atlassian.com/projects/JIRACLOUD/repos/jira/browse/jira-components/jira-plugins/jira-gadgets-plugin/src/main/resources/jira-gadgets-plugin/static/components/searchresults/SearchResults.js?at=6daea248aeceaaf27df9dacc26521c6e4c3b50c5#132
export const getFilterResultsPrefetchLink = (gadgetData: GadgetData): PrefetchLinksType => {
	const { properties } = gadgetData.context.dashboardItem;

	const data = {
		num: properties.num,
		tableContext: 'jira.table.cols.dashboard',
		addDefault: false,
		columnNames:
			typeof properties.columnNames === 'string' ? properties.columnNames.split('|') : undefined,
		enableSorting: true,
		paging: true,
		showActions: true,
		filterId: undefined,
		jql: undefined,
		sortBy: String(properties.sortBy && properties.sortBy !== '' ? properties.sortBy : null),
		startIndex: properties.startIndex ? properties.startIndex : 0,
	};

	let queryType = '';

	if (properties.filterId) {
		queryType = 'filter';
		Object.assign(data, { filterId: properties.filterId });
	} else if (properties.jql) {
		queryType = 'jql';
		Object.assign(data, { jql: properties.jql });
	}

	// IMPORTANT - type definitions are incorrect, sort is definitely an option
	// @see https://www.npmjs.com/package/query-string/v/5.1.0
	// @ts-expect-error - TS2345 - Argument of type '{ sort: boolean; }' is not assignable to parameter of type 'StringifyOptions'.
	const paramStr = queryString.stringify(data, { sort: false }).replace(/%20/g, '+');
	const preloadUrl = `/${FILTER_RESULTS_REST_URL}/${queryType}?${paramStr}`;

	return [preloadUrl];
};
