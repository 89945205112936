import React, { type ComponentType } from 'react';
import type { ProjectStyle, ProjectType } from '@atlassian/jira-common-constants/src/project-types';
import { useProject } from '@atlassian/jira-navigation-apps-sidebar-common';
import Placeholder from '@atlassian/jira-placeholder';
import type {
	Layout,
	Option,
	WithProjectTypeOptions,
	GlobalComponent,
	RightSidebar,
	ProjectContext,
} from './types';

export { layoutWithCondition } from './utils/layout-with-condition';

export type {
	Layout,
	Option,
	WithProjectTypeOptions,
	GlobalComponent,
	RightSidebar,
	ProjectContext,
};

export const coalesce = (maybeString?: string | null): string | undefined =>
	maybeString == null || maybeString === '' ? undefined : maybeString;

export const renderGlobalComponents = (components: ReadonlyArray<ComponentType<{}>>) =>
	components.map((Component, i) => {
		const { displayName, name } = Component;
		const key = coalesce(displayName) ?? coalesce(name) ?? i;

		return (
			// TODO Only add suspense if lazy component, otherwise add suspense within component array
			<Placeholder name={key?.toString()} fallback={<></>} key={key}>
				<Component />
			</Placeholder>
		);
	});

/**
 * This factory creates a Layout and ensures that any of the provided components are unique instances, which is
 * important when using composition by concatenation.
 *
 * In practice, when rendering a component list from the layout, using this factory will:
 *
 * 1. Prevent rendering the same component multiple times
 * 2. Prevent runtime failures when using the component name as a key
 *
 * @param globalComponents The list of global components that will be rendered in the page container
 * @param rightSidebars The list of right sidebar components that will be rendered in the page container
 * @returns {Layout} The layout to be used in a route definition
 *
 * @see https://reactjs.org/docs/lists-and-keys.html
 */
export const createLayout = ({
	globalComponents = [],
	rightSidebars = [],
	isChromeless = false,
}: Partial<Layout> = {}): Layout => ({
	isChromeless,
	globalComponents: Array.from(new Set(globalComponents)),
	rightSidebars: Array.from(new Set(rightSidebars)),
});

/**
 * This function combines all of the slots in each of the layouts via composition.
 *
 * @param layouts The list of layouts to compose with one another
 * @returns {Layout} The layout to be used in a route definition
 */
export const composeLayouts = (...layouts: ReadonlyArray<Partial<Layout>>): Layout => {
	if (layouts.length === 1) {
		return createLayout(layouts[0]);
	}

	const layout = layouts.reduce<Layout>(
		(l, { globalComponents = [], rightSidebars = [], isChromeless = false }) => ({
			isChromeless,
			globalComponents: [...l.globalComponents, ...globalComponents],
			rightSidebars: [...l.rightSidebars, ...rightSidebars],
		}),
		{ globalComponents: [], rightSidebars: [], isChromeless: false },
	);

	return createLayout(layout);
};

const capitalize = (str: string) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

const formatPrefix = (style?: Option<ProjectStyle>, type?: Option<ProjectType>) => {
	if (!style && !type) {
		return 'Empty';
	}

	if (style === 'any' && type === 'any') {
		return 'Any';
	}

	const formattedStyle = capitalize(style === 'next-gen' ? 'nextGen' : style ?? '');
	if (type === 'any') {
		return formattedStyle;
	}

	const formattedType = capitalize(type === 'service_desk' ? 'service' : type ?? '');
	if (style === 'any') {
		return formattedType;
	}

	return `${formattedStyle}${formattedType}`;
};

/**
 * This function creates a higher-order component, that renders the provided component when the useProject hook matches
 * the input options. Unfortunately, this is necessary as long as we have ambiguous routes, and ensures that components
 * do not unmount during transitions.
 *
 * TODO This HOC should be removed when route paths are no longer ambiguous
 *
 * @param Component The component to enhance, and render
 * @param options The conditions that need to be matched in order to render the provided component
 * @returns {function(): (null|*)} A component that will only be rendered when the project context matches the provided
 * options
 */
export const withProjectContext = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Component: ComponentType<Record<any, any>>,
	options: WithProjectTypeOptions, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ComponentType<Record<any, any>> => {
	const { project: expectedProject } = options;

	const ScopedComponent = () => {
		const { data: project, loading } = useProject();

		if (loading) {
			return null;
		}

		if (
			(expectedProject?.type === 'any' ? !project : project?.type !== expectedProject?.type) ||
			(expectedProject?.style === 'any' ? !project : project?.style !== expectedProject?.style)
		) {
			return null;
		}

		return <Component />;
	};

	const name = coalesce(Component.displayName) ?? coalesce(Component.name) ?? 'Component';
	const prefix = formatPrefix(expectedProject?.style, expectedProject?.type);

	ScopedComponent.displayName = `with${prefix}ProjectContext(${name})`;

	return ScopedComponent;
};
