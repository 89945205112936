import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import { ExperienceTrackerProvider } from '@atlassian/jira-experience-tracker';
import { routeAnalyticsAttributes } from '@atlassian/jira-platform-route-analytics';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { SubProductUpdater } from './subproduct-updater';
import type { MetricsContainersProps } from './types';

export const MetricsContainers = ({ children }: MetricsContainersProps) => (
	<ContextualAnalyticsData attributes={routeAnalyticsAttributes}>
		<ExperienceTrackerProvider>
			{!__SERVER__ && (
				<JSErrorBoundary
					fallback="unmount"
					id="metrics-containers-subproduct-updater"
					packageName="page-container"
				>
					<SubProductUpdater />
				</JSErrorBoundary>
			)}
			{children}
		</ExperienceTrackerProvider>
	</ContextualAnalyticsData>
);
