import { useCallback } from 'react';
import { performPutRequest } from '@atlassian/jira-fetch';
import type { PermissionInfo } from '@atlassian/jira-navigation-apps-resources';
import type { BaseUrl, ProjectKey } from '@atlassian/jira-shared-types';
import {
	useJiraSoftwareProjectNavigation,
	useJiraWorkmanagementProjectNavigation,
} from '../common';
import type { UseProjectPagesLinkReturnType } from './types';

const pagesLinkBaseUrl = (baseUrl: BaseUrl, projectKey: ProjectKey): string =>
	`${baseUrl}/rest/projects/1.0/pageslink/${projectKey}`;

const enablePagesLinkUrl = (baseUrl: BaseUrl, projectKey: ProjectKey): string =>
	`${pagesLinkBaseUrl(baseUrl, projectKey)}/enable`;

const disablePagesLinkUrl = (baseUrl: BaseUrl, projectKey: ProjectKey): string =>
	`${pagesLinkBaseUrl(baseUrl, projectKey)}/disable`;

type SetPagesLinkServiceArg = { baseUrl: BaseUrl; projectKey: ProjectKey; enable: boolean };
const setPagesLinkService = ({ baseUrl, projectKey, enable }: SetPagesLinkServiceArg) =>
	enable
		? performPutRequest(enablePagesLinkUrl(baseUrl, projectKey))
		: performPutRequest(disablePagesLinkUrl(baseUrl, projectKey));

const isProjectOrJiraAdmin = (permissionInfo: PermissionInfo): boolean =>
	permissionInfo.isAdmin || permissionInfo.projectPermissionsQueried.ADMINISTER_PROJECTS === true;

export const useJiraSoftwareProjectPagesLink = (): UseProjectPagesLinkReturnType => {
	const { data, loading, refresh, error } = useJiraSoftwareProjectNavigation();

	const setPagesLink = useCallback(
		async (...args: [SetPagesLinkServiceArg]) => {
			await setPagesLinkService(...args);
			await refresh();
		},
		[refresh],
	);
	return {
		data: {
			isEnabled: data?.hasPages === true,
			canEdit: data?.permissionInfo != null && isProjectOrJiraAdmin(data.permissionInfo),
			pending: loading,
			setPagesLink,
		},
		loading,
		error,
	};
};

export const useJiraWorkManagementProjectPagesLink = (): UseProjectPagesLinkReturnType => {
	const { loading, refresh, error } = useJiraWorkmanagementProjectNavigation();

	const setPagesLink = useCallback(
		async (...args: [SetPagesLinkServiceArg]) => {
			await setPagesLinkService(...args);
			await refresh();
		},
		[refresh],
	);
	return {
		data: {
			isEnabled: true,
			canEdit: false,
			pending: loading,
			setPagesLink,
		},
		loading,
		error,
	};
};
