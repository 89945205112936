import { getPermalinkId, WORKLOG } from '../../../../common/utils/permalinks';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({
	startAt,
	orderBy,
	worklogAmount,
}: {
	startAt: number;
	orderBy?: string;
	worklogAmount?: number;
}): string => {
	const orderByParameter =
		orderBy !== null && orderBy !== undefined ? `, orderBy: "${orderBy}"` : '';

	const worklogPermalinkId = getPermalinkId(WORKLOG);

	if (worklogPermalinkId === null || worklogPermalinkId === undefined) {
		return '';
	}

	const worklogParameter = `, worklogId: ${worklogPermalinkId}`;

	const worklogAmountParameter =
		worklogAmount !== null && worklogAmount !== undefined
			? `, worklogAmount: ${worklogAmount}`
			: '';

	return `
        worklogs (startAt: ${startAt}${orderByParameter}${worklogParameter}${worklogAmountParameter}) {
            nodes {
                id
                author {
                    accountId
                    displayName
                    avatarUrl
                }
                updateAuthor {
                    accountId
                    displayName
                    avatarUrl
                }
                created
                started
                updated
                comment
                timeSpent
                timeSpentSeconds
            }
            totalCount
            startIndex
        }
    `;
};
