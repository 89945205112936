import React, { type ReactNode, useContext, useMemo } from 'react';
import { ProfilerContext as RLLProfilerContext } from 'react-loosely-lazy';
import { ProfilerContext as RelayProfilerContext } from 'react-relay';
import {
	type UFOInteractionContextType,
	useInteractionContext,
} from '@atlassian/ufo-interaction-context';
import PlatformUFOLabel from '@atlassian/ufo-label';

type Props = {
	name: string;
	children: ReactNode;
};

const newContextWithUfoContext = <TContext extends {}>(
	context: TContext | null,
	interactionContext: UFOInteractionContextType | null,
) =>
	context &&
	interactionContext && {
		...context,
		getInteractionContext: () => interactionContext,
	};

const flowRight =
	<T,>(functionList: ((arg: T) => T)[]) =>
	(arg: T): T =>
		functionList.reduceRight((acc, fn) => fn(acc), arg);

const renderContextProviderIfNonNull =
	<TContextValue,>(Context: React.Context<TContextValue>, contextValue: TContextValue | null) =>
	(node: JSX.Element): JSX.Element =>
		contextValue ? <Context.Provider value={contextValue}>{node}</Context.Provider> : node;

function JiraUFOLabelWrapper({ children }: { children: ReactNode }) {
	const context = useInteractionContext();
	const rllProfilerContext = useContext(RLLProfilerContext);
	const relayProfilerContext = useContext(RelayProfilerContext);

	const newRllProfilerContext = useMemo(
		() => ({
			current: newContextWithUfoContext(rllProfilerContext?.current, context),
		}),
		[context, rllProfilerContext],
	);
	const newRelayProfilerContext = useMemo(
		() => newContextWithUfoContext(relayProfilerContext, context),
		[context, relayProfilerContext],
	);

	// Produces the following but only renders a provider if its context is not null
	// <RelayProfilerContext.Provider ...>
	//   <RLLProfilerContext.Provider ...>
	//     {children}
	//   </...etc
	return flowRight([
		renderContextProviderIfNonNull(RelayProfilerContext, newRelayProfilerContext),
		renderContextProviderIfNonNull(RLLProfilerContext, newRllProfilerContext),
	])(<>{children}</>);
}

/**
 * Annotate part of the react tree with a product name
 * ```
 * <UFOLabel name="card">
 *   <Card card={data} />
 * </UFOLabel>
 * ```
 */
export default function UFOLabel({ name, children }: Props): JSX.Element {
	return (
		<PlatformUFOLabel name={name}>
			<JiraUFOLabelWrapper>{children}</JiraUFOLabelWrapper>
		</PlatformUFOLabel>
	);
}
