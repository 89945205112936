import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { useSidebarVisibility } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/plan';
import type PlanRoadmapType from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-report';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import { LazyAdvancedRoadmapsPlan } from './plan';

/**
 * Classic Report Page
 */

const ReportPageComponent = () => <LazyAdvancedRoadmapsPlan viewType="report" />;

/**
 * New Report page
 */
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyPlanReport = lazyForPaint<typeof PlanRoadmapType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-plan-report", jiraSpaEntry: "async-plan-report" */ '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-report'
			),
		),
	{ ssr: false },
);

const PlanReport = () => {
	const { isSidebarVisible } = useSidebarVisibility();

	return isSidebarVisible ? (
		<LazyPage Page={LazyPlanReport} pageId="plan-report" shouldShowSpinner />
	) : (
		<LazyPage Page={ReportPageComponent} pageId="advanced-roadmap" shouldShowSpinner />
	);
};

export default PlanReport;
