import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const playbookEditViewEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-spa-apps-playbooks-edit" */ './src'),
	),
	getPreloadProps: ({ context, tenantContext }: EntryPointRouteParams) => {
		const { projectKey } = context.match.params;
		if (!projectKey) {
			throw new Error('projectKey is required');
		}

		return {
			extraProps: {
				accountId: tenantContext.atlassianAccountId,
				cloudId: tenantContext.cloudId,
				projectIds: context?.query?.projectId,
				projectKey: context?.match.params.projectKey,
			},
		};
	},
});

export default playbookEditViewEntryPoint;
