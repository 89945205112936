export const DEFAULT_METRICS_HISTOGRAM = '1000_1500_2000_2500_3000_4000';

export const BOARD_SETTINGS_ARTICLE_ID_CONFIG_DISABLE_SECTION = '2QE2xWf0TpDbYpk4oY9aQr';
export const BOARD_SETTINGS_ARTICLE_ID_CONFIG_DISABLE_SECTION_ANALYTICS_ID = `${BOARD_SETTINGS_ARTICLE_ID_CONFIG_DISABLE_SECTION}.config-disabled-section.article.analytics`;

export const DEFAULT_LEVEL_ONE_NAME = 'Epic';

const INTERNAL_BOARD_SETTINGS_CONFIG_QUERY_PARAM_VALUES = [
	'cardColors',
	'cardLayout',
	'columns',
	'estimation',
	'filter',
	'swimlanes',
	'time',
	'timelineConfig',
	'quickFilters',
	'insightsConfig',
	'dateFields',
] as const;

export const BOARD_SETTINGS_CONFIG_QUERY_PARAM_VALUES = new Set<string>(
	INTERNAL_BOARD_SETTINGS_CONFIG_QUERY_PARAM_VALUES,
);

export type ConfigParamKey = (typeof INTERNAL_BOARD_SETTINGS_CONFIG_QUERY_PARAM_VALUES)[number];

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const boardSettingsQueryConfigParamValues = Object.fromEntries(
	INTERNAL_BOARD_SETTINGS_CONFIG_QUERY_PARAM_VALUES.map((key) => [key, key]),
) as { [K in ConfigParamKey]: K };

type Config = {
	configQuery: ConfigParamKey;
	jsmAllowed: boolean;
	userLocatedBoardAllowed: boolean;
	kanbanAllowed: boolean;
};

export const SETTINGS_CONFIG: Record<ConfigParamKey, Config> = {
	filter: {
		configQuery: boardSettingsQueryConfigParamValues.filter,
		jsmAllowed: true,
		userLocatedBoardAllowed: true,
		kanbanAllowed: true,
	},
	columns: {
		configQuery: boardSettingsQueryConfigParamValues.columns,
		jsmAllowed: true,
		userLocatedBoardAllowed: true,
		kanbanAllowed: true,
	},
	swimlanes: {
		configQuery: boardSettingsQueryConfigParamValues.swimlanes,
		jsmAllowed: true,
		userLocatedBoardAllowed: true,
		kanbanAllowed: true,
	},
	quickFilters: {
		configQuery: boardSettingsQueryConfigParamValues.quickFilters,
		jsmAllowed: true,
		userLocatedBoardAllowed: true,
		kanbanAllowed: true,
	},
	cardColors: {
		configQuery: boardSettingsQueryConfigParamValues.cardColors,
		jsmAllowed: true,
		userLocatedBoardAllowed: true,
		kanbanAllowed: true,
	},
	cardLayout: {
		configQuery: boardSettingsQueryConfigParamValues.cardLayout,
		jsmAllowed: true,
		userLocatedBoardAllowed: true,
		kanbanAllowed: true,
	},
	estimation: {
		configQuery: boardSettingsQueryConfigParamValues.estimation,
		jsmAllowed: false,
		userLocatedBoardAllowed: true,
		kanbanAllowed: false,
	},
	time: {
		configQuery: boardSettingsQueryConfigParamValues.time,
		jsmAllowed: false,
		userLocatedBoardAllowed: true,
		kanbanAllowed: true,
	},
	timelineConfig: {
		configQuery: boardSettingsQueryConfigParamValues.timelineConfig,
		jsmAllowed: false,
		userLocatedBoardAllowed: false,
		kanbanAllowed: true,
	},
	insightsConfig: {
		configQuery: boardSettingsQueryConfigParamValues.insightsConfig,
		jsmAllowed: false,
		userLocatedBoardAllowed: false,
		kanbanAllowed: false,
	},
	dateFields: {
		configQuery: boardSettingsQueryConfigParamValues.dateFields,
		jsmAllowed: true,
		userLocatedBoardAllowed: true,
		kanbanAllowed: true,
	},
} as const;

export const HEAVY_TEST_TIMEOUT = 60000;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const EDIT_BOARD_CONFIG = 'EDIT_BOARD_CONFIG' as unknown as AGG$SoftwareBoardPermission;
export const ADMINISTER_PARENT_PROJECT =
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	'ADMINISTER_PARENT_PROJECT' as unknown as AGG$SoftwareBoardPermission;
export const USER_PICKER_PROJECT_PERMISSION_KEY =
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	'USER_PICKER' as unknown as AGG$SoftwareBoardPermission;
export const ADMINISTER_PROJECT_PERMISSION_KEY = 'ADMINISTER';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const BACKLOG_FEATURE_KEY = 'BACKLOG' as unknown as AGG$SoftwareBoardFeatureKey;
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const SPRINTS_FEATURE_KEY = 'SPRINTS' as unknown as AGG$SoftwareBoardFeatureKey;

export const FEATURE_ENABLED_STATUS = 'ENABLED';
export const FEATURE_DISABLED_STATUS = 'DISABLED';
