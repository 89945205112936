import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ProjectsConnectType from '@atlassian/jira-spa-apps-connect-projects';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyProjectsConnect = lazyForPaint<typeof ProjectsConnectType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-connect-projects", jiraSpaEntry: "async-connect-projects" */ '@atlassian/jira-spa-apps-connect-projects'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazyProjectsConnect} pageId="connect-projects" shouldShowSpinner />
);
