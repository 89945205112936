import React, { createRef } from 'react';
import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import Lozenge from '@atlaskit/lozenge';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { Box, xcss } from '@atlaskit/primitives';
import { SkeletonItem } from '@atlaskit/side-navigation';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl';
import { MenuItem } from '../../common/ui/menu-item';
import { useProject as useProjectDI, type UseProjectReturn } from '../../controllers';
import { matchUrlPathname } from '../../utils';
import { withThrowOnError } from '../../utils/throw-when-no-data-and-error';
import { messages } from './messages';
import type { Props } from './types';

export const useProject: () => UseProjectReturn = withThrowOnError(useProjectDI);

const ISSUE_EMAIL_LOG_SPOTLIGHT_KEY = 'issue-email-log-spotlight';

export const NotificationsSubMenu = ({
	settingsUrl,
	issueLogsUrl,
	displayLozenge = false,
}: Props) => {
	const { formatMessage } = useIntl();
	const { data: project, loading: projectLoading } = useProject();

	if (projectLoading || !project) return <SkeletonItem />;

	const content = (
		<>
			<MenuItem
				analytics={{ itemId: 'notifications-settings' }}
				href={settingsUrl}
				ref={createRef()}
				selectedOn={matchUrlPathname()}
			>
				{formatMessage(messages.itemSettings)}
			</MenuItem>
			<SpotlightTarget name={ISSUE_EMAIL_LOG_SPOTLIGHT_KEY}>
				<MenuItem
					analytics={{ itemId: 'notifications-issue-logs' }}
					href={issueLogsUrl}
					ref={createRef()}
					selectedOn={matchUrlPathname()}
				>
					<Box xcss={issueLogsContainerStyles}>
						{ff('platform.jlove-pulsar.jlovep-1440-issue-email-audit-ui-changes')
							? formatMessage(messages.itemProjectEmailAuditLogs)
							: formatMessage(messages.itemIssueLogs)}
						{displayLozenge && (
							<Lozenge appearance="new">{formatMessage(messages.newLozenge)}</Lozenge>
						)}
					</Box>
				</MenuItem>
				<EngagementSpotlight engagementId={ISSUE_EMAIL_LOG_SPOTLIGHT_KEY} />
			</SpotlightTarget>
		</>
	);

	return <>{content}</>;
};

const issueLogsContainerStyles = xcss({
	display: 'flex',
	justifyContent: 'space-between',
});
