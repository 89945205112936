import React, { memo } from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import {
	setPerformanceMarkLoadStart,
	setPerformanceMarkLoadEnd,
} from '@atlassian/jira-navigation-apps-sidebar-common';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-common/async';
import type SoftwareProjectSidebarType from './index';

const LazyClassicSoftwareProjectSidebar = lazyAfterPaint<typeof SoftwareProjectSidebarType>(() => {
	setPerformanceMarkLoadStart();
	return import(/* webpackChunkName: "async-sidebar-next-gen" */ './index').then((bundle) => {
		setPerformanceMarkLoadEnd();
		return bundle;
	});
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SoftwareProjectSidebar = memo<Record<any, any>>(() => (
	<SidebarBoundary id="softwareProject">
		<LazyClassicSoftwareProjectSidebar />
	</SidebarBoundary>
));

SoftwareProjectSidebar.displayName = 'SoftwareProjectSidebar';

export default SoftwareProjectSidebar;
