import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const archivedProjectsDirectoryRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.ARCHIVED_PROJECTS_DIRECTORY,
	path: '/jira/settings/projects/archive',
	exact: true,
};
