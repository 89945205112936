import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const projectIssueNavigatorRoute = {
	group: ROUTE_GROUPS.ISSUE_NAVIGATOR,
	name: ROUTE_NAMES.PROJECT_ISSUE_NAVIGATOR,
	path: '/projects/:projectKey/issues/:issueKey?',
};

export const softwareIssueNavigatorRoute = {
	group: ROUTE_GROUPS.ISSUE_NAVIGATOR,
	name: ROUTE_NAMES.SOFTWARE_ISSUE_NAVIGATOR,
	path: '/jira/software/projects/:projectKey/issues/:issueKey?',
};

export const projectIssueNavigatorClassicRoute = {
	group: ROUTE_GROUPS.ISSUE_NAVIGATOR,
	name: ROUTE_NAMES.PROJECT_ISSUE_NAVIGATOR_CLASSIC,
	path: '/jira/software/c/projects/:projectKey/issues/:issueKey?',
};

export const servicedeskIssuesRoute = {
	group: ROUTE_GROUPS.ISSUE_NAVIGATOR,
	name: ROUTE_NAMES.SERVICEDESK_ISSUES,
	path: '/jira/servicedesk/projects/:projectKey/issues/:issueKey?',
};

export const projectIssueNavigatorCoreRoute = {
	group: ROUTE_GROUPS.ISSUE_NAVIGATOR,
	name: ROUTE_NAMES.PROJECT_ISSUE_NAVIGATOR_CORE,
	path: '/jira/core/projects/:projectKey/issues/:issueKey?',
};

export const globalIssueNavigatorRoute = {
	group: ROUTE_GROUPS.ISSUE_NAVIGATOR,
	name: ROUTE_NAMES.GLOBAL_ISSUE_NAVIGATOR,
	path: '/issues/:issueKey?',
};

export const globalIssueNavigator1Route = {
	group: ROUTE_GROUPS.ISSUE_NAVIGATOR,
	name: ROUTE_NAMES.GLOBAL_ISSUE_NAVIGATOR,
	path: '/jira/issues/:issueKey?',
};

export const globalIssueNavigatorBrowseIssueRedirectRoute = {
	group: ROUTE_GROUPS.ISSUE_NAVIGATOR,
	name: ROUTE_NAMES.GLOBAL_ISSUE_NAVIGATOR_BROWSE_ISSUE_REDIRECT,
	path: '/browse/:issueKey?',
	query: ['jql|filter'],
};
