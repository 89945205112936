import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import {
	type GraphQLResponse,
	type Variables,
	QueryResponseCache as RelayQueryResponseCache,
	stableCopy,
} from 'relay-runtime';
import { log } from '@atlassian/jira-common-util-logging';
import HttpError from '@atlassian/jira-fetch/src/utils';
import { UnusedRelayResponseError } from './services/unused-relay-response-error';

type RequestMetadata = {
	queryName: string;
	operationKind: string;
};

export function getCacheKey(queryID: string, variables?: Variables): string {
	return JSON.stringify(stableCopy({ queryID, variables }));
}

export function getQueryIDFromCacheKey(cacheKey: string): string {
	return JSON.parse(cacheKey).queryID;
}

/**
 * Query response cache that works across SSR. Extends on Relay's QueryResponse Cache:
 * https://github.com/facebook/relay/blob/main/packages/relay-runtime/network/RelayQueryResponseCache.js
 *
 * Can properly handle multiple requests of the same ID (Relay's Cache ID is based on query, not variables),
 * and includes a TTL and maximum cache size to further prevent stale data.
 */
export class SSRQueryResponseCache extends RelayQueryResponseCache {
	cacheName: string;

	requestMetadata: Map<string, RequestMetadata>;

	constructor({ cacheName, size, ttl }: { cacheName: string; size: number; ttl: number }) {
		super({ size, ttl });
		const cachedData = __SERVER__ ? {} : window?.SPA_STATE?.[cacheName] || {};

		this.cacheName = cacheName;
		this.requestMetadata = new Map(cachedData.requestMetadata);
		// @ts-expect-error - TS2339 - Property '_responses' does not exist on type 'SSRQueryResponseCache'.
		this._responses = new Map(cachedData.responses);

		if (!__SERVER__) {
			setTimeout(() => {
				this.logUnusedPayloadErrors();
				// cleanup leftover data to reduce unnecessary memory usage
				this.requestMetadata.clear();
				// @ts-expect-error - TS2339 - Property '_responses' does not exist on type 'SSRQueryResponseCache'.
				this._responses.clear();
			}, 60 * 1000 /* 1 minute */);
		}
	}

	logUnusedPayloadErrors() {
		const loggedQueries = new Set();
		// @ts-expect-error - TS2339 - Property '_responses' does not exist on type 'SSRQueryResponseCache'. | TS7006 - Parameter 'response' implicitly has an 'any' type. | TS7006 - Parameter 'key' implicitly has an 'any' type.
		this._responses.forEach((response, key) => {
			const queryID = getQueryIDFromCacheKey(key);

			const metadata = this.requestMetadata.get(queryID);
			const { queryName = 'unknown', operationKind = 'unknown' } = metadata ?? {
				queryName: 'unknown',
				operationKind: 'unknown',
			};

			if (!loggedQueries.has(queryName)) {
				loggedQueries.add(queryName);

				const error = new UnusedRelayResponseError(queryID);

				const statusCode = get(error, ['statusCode'], null);
				const errorType = error instanceof HttpError ? 'network' : 'unknown';

				// Log any errors even in SSR
				log.safeErrorWithoutCustomerData('relay.AGG.error.graphql', `[${errorType} error]`, {
					message: `[${capitalize(errorType)} error]: ${error?.message ?? ''}`,
					errorType,
					statusCode,
					queryName,
					operationKind,
					errorName: get(error, ['name'], null),
				});
			}
		});
	}

	setWithMetadata(
		queryID: string,
		variables: Variables,
		payload: GraphQLResponse,
		metadata: RequestMetadata,
	): void {
		this.requestMetadata.set(queryID, metadata);
		this.set(queryID, variables, payload);
	}

	/**
	 * Deletes a request from the store, if found.
	 *
	 * IMPORTANT: Make sure DELETE method always has tests that include get/set
	 * to ensure our `getCacheKey` matches Relay's.
	 *
	 * @returns {boolean} indicating if an entry in the cache was deleted
	 */
	delete(queryID: string, variables: Variables): boolean {
		const cacheKey = getCacheKey(queryID, variables);
		// @ts-expect-error - TS2339 - Property '_responses' does not exist on type 'SSRQueryResponseCache'.
		return this._responses.delete(cacheKey);
	}

	serialize(): Record<string, [string, GraphQLResponse][]> {
		return {
			[this.cacheName]: {
				// @ts-expect-error - TS2322 - Type '{ [x: string]: { requestMetadata: [string, RequestMetadata][]; responses: unknown[]; }; }' is not assignable to type 'Partial<Record<string, [string, GraphQLResponse][]>>'.
				requestMetadata: Array.from(this.requestMetadata.entries()),
				// @ts-expect-error - TS2339 - Property '_responses' does not exist on type 'SSRQueryResponseCache'.
				responses: Array.from(this._responses.entries()),
			},
		};
	}
}
/* eslint-enable no-underscore-dangle */

// Export a singleton, already configured for use with the SSR Cache.
export const QueryResponseCache = new SSRQueryResponseCache({
	cacheName: 'relay-cache/v2/AGG',
	size: Infinity,
	ttl: Infinity,
});

export const serializeRelayCacheForSSR = (): Record<string, [string, GraphQLResponse][]> =>
	QueryResponseCache.serialize();
