import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type IssueTypeScreenSchemesPage from '@atlassian/jira-admin-pages-issue-type-screen-schemes-page';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyIssueTypeScreenSchemesPage = lazyForPaint<typeof IssueTypeScreenSchemesPage>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-jira-admin-pages-issue-type-screen-schemes-page", jiraSpaEntry: "async-jira-admin-pages-issue-type-screen-schemes-page" */ '@atlassian/jira-admin-pages-issue-type-screen-schemes-page'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyIssueTypeScreenSchemesPage}
		pageId="jira-admin-pages-issue-type-screen-schemes-page"
		shouldShowSpinner
	/>
);
