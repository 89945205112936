import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalLayout } from '@atlassian/jira-global-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import { createEntry } from '@atlassian/jira-route-entry';
import { boardsDirectoryResource } from '@atlassian/jira-router-resources-directories-boards';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import {
	boardsDirectoryRoute,
	boardsDirectoryRedirectRoute,
} from '@atlassian/jira-router-routes-directories-routes';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect';
import { BoardsDirectorySkeleton } from '@atlassian/jira-skeletons';
import type BoardsDirectoryType from '@atlassian/jira-spa-apps-directories-boards';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

const BoardsDirectoryRedirect = () => <ScreenRedirect to="/jira/boards" />;

const LazyBoardsDirectory = lazyForPaint<typeof BoardsDirectoryType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-boards-directory", jiraSpaEntry: "async-boards-directory" */ '@atlassian/jira-spa-apps-directories-boards'
		),
	),
);

export const BoardsDirectoryComponent = () => (
	<LazyPage Page={LazyBoardsDirectory} pageId="boards-directory" shouldShowSpinner={false} />
);

export const boardsDirectoryRouteEntry = createEntry(boardsDirectoryRoute, {
	component: BoardsDirectoryComponent,
	skeleton: BoardsDirectorySkeleton,
	layout: globalLayout,
	resources: [...getNavigationResources(), boardsDirectoryResource],

	navigation: {
		menuId: MENU_ID.PROJECTS,
	},

	forPaint: [LazyAtlassianNavigation, LazyBoardsDirectory],
});

export const boardsDirectoryRedirectRouteEntry = createEntry(boardsDirectoryRedirectRoute, {
	component: BoardsDirectoryRedirect,
	skeleton: BoardsDirectorySkeleton,
	layout: globalLayout,
	resources: [...getNavigationResources(), boardsDirectoryResource],

	navigation: {
		menuId: MENU_ID.PROJECTS,
	},

	forPaint: [LazyAtlassianNavigation, LazyBoardsDirectory],
	isRedirect: true,
});
