import {
	type MatcherLocation,
	matchUrlExact,
} from '@atlassian/jira-navigation-apps-sidebar-common';

const INCOMING_MAIL_PATH_REGEXP = /\/admin\/(\w+)Pop(Mail)?Server/i;

export const isIncomingMailSelected = (
	currentLocation: MatcherLocation,
	itemLocation: MatcherLocation,
): boolean =>
	matchUrlExact()(currentLocation, itemLocation) ||
	INCOMING_MAIL_PATH_REGEXP.test(currentLocation.pathname);
