import {
	ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_SUMMARY,
	ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_CALENDAR,
	ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_TIMELINE,
	ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_REDIRECT,
	ROUTE_NAMES_CONNECT_PROJECT,
	ROUTE_NAMES_CONNECT_GENERAL,
	ROUTE_NAMES_CONNECT_GENERAL_SERVICE_DESK_PROJECT,
	ROUTE_NAMES_LEGACY_CONNECT_PROJECT,
	ROUTE_NAMES_LEGACY_CONNECT_GENERAL,
} from './spa-routes';

export const JWM_OVERVIEWS_ROUTE_SET = new Set<string>([
	ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_SUMMARY,
	ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_CALENDAR,
	ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_TIMELINE,
	ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_REDIRECT,
]);
export const CONNECT_ROUTES_SET = new Set<string>([
	ROUTE_NAMES_CONNECT_PROJECT,
	ROUTE_NAMES_CONNECT_GENERAL,
	ROUTE_NAMES_CONNECT_GENERAL_SERVICE_DESK_PROJECT,
	ROUTE_NAMES_LEGACY_CONNECT_PROJECT,
	ROUTE_NAMES_LEGACY_CONNECT_GENERAL,
]);
