import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

const getProjectSettingsUrl = (product: string): string =>
	`/jira/${product}/projects/:projectKey/settings`;

export const SERVICE_DESK_PRODUCT = 'servicedesk';

export const servicedeskPlaybooksListRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK,
	name: ROUTE_NAMES.PLAYBOOKS_LIST,
	path: `${getProjectSettingsUrl(SERVICE_DESK_PRODUCT)}/playbooks`,
	exact: true,
};

export const servicedeskPlaybooksLogsRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK,
	name: ROUTE_NAMES.PLAYBOOKS_LOGS,
	path: `${getProjectSettingsUrl(SERVICE_DESK_PRODUCT)}/playbooks/logs`,
	exact: true,
};

export const servicedeskPlaybooksEditRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK,
	name: ROUTE_NAMES.PLAYBOOKS_EDIT,
	path: `${getProjectSettingsUrl(SERVICE_DESK_PRODUCT)}/playbooks/:id`,
	exact: true,
};
