import { createResource } from '@atlassian/jira-router';
import { RESOURCE_TYPE_JSM_CUSTOMER_ACCESS_AFFECTED_PROJECTS } from '../../constants';
import type { JsmCustomerAccessAffectedProjectsResponse } from './types';

const ONE_DAY_IN_MILLIS = 1000 * 60 * 60 * 24;

export const jsmGlobalCustomerAccessAffectedProjectsResource =
	createResource<JsmCustomerAccessAffectedProjectsResponse>({
		type: RESOURCE_TYPE_JSM_CUSTOMER_ACCESS_AFFECTED_PROJECTS,
		getKey: () => 'affected-projects',
		getDataLoader: () =>
			import(
				/* webpackChunkName: "async-resource-jsm-customer-access-affected-projects", jiraSpaEntry: "async-resource-jsm-customer-access-affected-projects" */ './get-data'
			),
		maxAge: ONE_DAY_IN_MILLIS,
	});
