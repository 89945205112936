/**
 * @generated SignedSource<<2ae0c2a3d75de85d371462f7bda6d3c2>>
 * @relayHash 3b1ad2a90ca74c50737c2302b5bacecd
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 35aa850db46b29486ee495b583fa52299c2053d85d00c14f99c4d51a620b6e67

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcVirtualAgentBrowseConversationsQuery } from './srcVirtualAgentBrowseConversationsQuery.graphql';

const node: PreloadableConcreteRequest<srcVirtualAgentBrowseConversationsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "35aa850db46b29486ee495b583fa52299c2053d85d00c14f99c4d51a620b6e67",
    "metadata": {},
    "name": "srcVirtualAgentBrowseConversationsQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
