// eslint-disable-next-line jira/restricted/@atlassian/jira-router
import { createResource } from '@atlassian/jira-router';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils';
import {
	fetchDefaultPINFiltersNavData,
	type DefaultPinFeatures,
} from '../../../services/sidebar/project-filters';

export const staticSidebarDefaultPINFiltersResource = createResource<DefaultPinFeatures | null>({
	type: 'JIRA_PROJECT_DEFAULT_PIN_FILTERS_NAV_DATA',
	getKey: ({ match, query }) => {
		const { projectKey } = getProjectKeyId(match, query);
		return projectKey || 'none';
	},
	getData: async ({ match, query }) => {
		const { projectKey } = getProjectKeyId(match, query);
		if (projectKey == null) {
			return null;
		}
		return fetchDefaultPINFiltersNavData(projectKey);
	},
	maxAge: 5 * 60 * 1000,
});
