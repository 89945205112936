import chunk from 'lodash/chunk';
import debounce from 'lodash/debounce';

const APP_ID = '7066';
export const BASE_URL = `https://views.unsplash.com/v?app_id=${APP_ID}`;
const MAX_URL_LENGTH = 2083; // the maximum length of a URL in most browsers
const ID_LENGTH = 12; // the length of an unsplash image id
export const MAX_NUM_IDS = Math.floor(MAX_URL_LENGTH / ID_LENGTH);

/**
 * This class is responsible for tracking unsplash images that are displayed in the app.
 * This is needed for legal reasons to comply with the Unsplash API terms of service.
 * It will track the image when it is displayed and then track it again every 10 minutes.
 */
class UnsplashTracker {
	private static instance: UnsplashTracker;

	private trackedImages: Map<string, Date> = new Map();

	private trackingInterval: number = 10 * 60 * 1000; // 10 minutes

	private queue: Set<string> = new Set();

	private sendDebounced = debounce(this.send, 1000);

	/**
	 * Send a request to the Unsplash API to track the image views.
	 * This will send a request for all the images in the queue.
	 * If the request fails, the images will be added back to the queue.
	 */
	private send(): void {
		if (this.queue.size === 0) {
			return;
		}

		const imageIds = Array.from(this.queue);
		this.queue.clear();

		chunk(imageIds, MAX_NUM_IDS).forEach((chunkIds) => {
			const url = `${BASE_URL}&photoId=${chunkIds.join(',')}`;
			const now = new Date();
			imageIds.forEach((imageId) => {
				this.trackedImages.set(imageId, now);
			});

			const handleFailure = () => {
				// if the request fails, add the image ids back to the queue
				imageIds.forEach((imageId) => {
					this.queue.add(imageId);
					this.trackedImages.delete(imageId);
				});
			};

			fetch(url)
				.then((response) => {
					if (!response.ok) {
						handleFailure();
					}
				})
				.catch(() => handleFailure());
		});
	}

	/**
	 * Track an image view. This will add the image to the queue to be tracked.
	 * If the image was tracked less than 10 minutes ago, it will not be added to the queue.
	 * @param sourceIdentifier The Unsplash photo id to track. Usually formatted as `photo-<something>-<id>`
	 * @returns void
	 */
	public trackImage(sourceIdentifier: string): void {
		const imageId = sourceIdentifier.split('-').pop(); // photo-1696144706485-ff7825ec8481 -> ff7825ec8481

		if (!imageId) {
			return;
		}

		const lastTracked = this.trackedImages.get(imageId);
		if (lastTracked && Date.now() - lastTracked.getTime() < this.trackingInterval) {
			return;
		}

		this.queue.add(imageId);
		this.sendDebounced();
	}

	/**
	 * Track an image view while it is in the viewport. This will track the image when it is displayed
	 * and then track it again every 10 minutes.
	 * @param element The element that contains the image
	 * @param sourceIdentifier The Unsplash photo id to track. Usually formatted as `photo-<something>-<id>`
	 * @returns A function to stop tracking the image
	 */
	public trackWhileInViewport(element: Element, sourceIdentifier: string): () => void {
		let trackIntervalId: number | null = null;

		const observer = new IntersectionObserver(
			(entry) => {
				if (entry[0].isIntersecting && !trackIntervalId) {
					unsplashTracker.trackImage(sourceIdentifier);

					trackIntervalId = window.setInterval(() => {
						unsplashTracker.trackImage(sourceIdentifier);
					}, this.trackingInterval + 10); // extra 10ms to ensure the interval is always greater than 10 minutes
				} else if (trackIntervalId) {
					window.clearInterval(trackIntervalId);
				}
			},
			{
				rootMargin: '0px',
				threshold: 1.0,
			},
		);

		observer.observe(element);

		return () => {
			observer.disconnect();
			if (trackIntervalId) {
				window.clearInterval(trackIntervalId);
			}
		};
	}

	public static getInstance(): UnsplashTracker {
		if (!UnsplashTracker.instance) {
			UnsplashTracker.instance = new UnsplashTracker();
		}
		return UnsplashTracker.instance;
	}
}

export const unsplashTracker = UnsplashTracker.getInstance();
