import React from 'react';
import { layers } from '@atlassian/jira-common-styles/src';
import {
	BANNER_NAME as BANNER_NAME_COOKIE_CONSENT,
	CookieConsentBannerAsync as CookieConsentBanner,
} from '@atlassian/jira-cookie-consent-banner';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import {
	BANNER_NAME as BANNER_NAME_IPBLOCKLISTING,
	IPBlocklistingBannerAsync as BannerIPBlocklisting,
} from '@atlassian/jira-ip-block-listing-banner/src/async';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout';
import { Banner as AkBanner } from '@atlassian/jira-navigation-system';
import { useBanner } from '@atlassian/jira-platform-controllers-global-banner';
import {
	NAME as BANNER_NAME_ANNOUNCEMENT,
	BannerAnnouncementAsync as BannerAnnouncement,
} from '@atlassian/jira-platform-ui-banners-announcement';
import {
	BANNER_NAME as BANNER_NAME_TRANSITION,
	BannerTransitionAsync,
} from '@atlassian/jira-platform-ui-banners-eoc-transition';
import {
	BANNER_NAME as BANNER_NAME_MAIL_EXPIRY,
	MailExpiryBannerAsync as MailExpiryBanner,
} from '@atlassian/jira-platform-ui-mail-expiry-reminder';
import {
	BANNER_NAME as BANNER_NAME_RETURN_TO_MOBILE,
	ReturnToMobileBanner as BannerReturnToMobile,
} from '@atlassian/jira-return-to-mobile-banner/src/async';
import UFOSegment from '@atlassian/jira-ufo-segment';
import { ZIndexWrapper } from '../z-index-wrapper';

/**
 * This test ID is used in pollinator tests. Do not change lightly.
 */
const TEST_ID = 'page-layout.banner';

/* IMPORTANT:
 * When making any changes to the OLD component, please ensure that you also make the same changes to the
 * NEW component. The feature flag used is part of the Navigation Refresh work and we need to ensure that
 * we do not introduce tech debt for the roll-out by only updating the current component that is due to be
 * removed.
 *
 * If you have any concerns or questions please reach out to the #jira-navigation channel. Thanks!
 */
export const NewBanner = () => {
	const banner = useBanner();
	return banner.isEnabled ? (
		<UFOSegment name="banner">
			<AkBanner height={banner.height} testId={TEST_ID}>
				<BannerContent bannerName={banner.name} />
			</AkBanner>
		</UFOSegment>
	) : null;
};

/**
 * Dynamically displays a banner based on the current context and feature flags. It supports several types of banners,
 * each serving a different purpose (e.g., announcements, cookie consent). The decision to display a particular banner is
 * made through a combination of fetched banner data and the evaluation of feature flags, ensuring that the presented banner
 * is relevant to the user's current interaction and system state.
 */
export const OldBanner = () => {
	const banner = useBanner();
	return banner.isEnabled ? (
		<UFOSegment name="banner">
			<ZIndexWrapper zIndex={layers.banner}>
				<AkBanner height={banner.height}>
					<BannerContent bannerName={banner.name} />
				</AkBanner>
			</ZIndexWrapper>
		</UFOSegment>
	) : null;
};

const BannerContent = ({ bannerName }: { bannerName: string | null }) => (
	<>
		{bannerName === BANNER_NAME_ANNOUNCEMENT && <BannerAnnouncement />}
		{bannerName === BANNER_NAME_IPBLOCKLISTING && <BannerIPBlocklisting />}
		{bannerName === BANNER_NAME_RETURN_TO_MOBILE && <BannerReturnToMobile />}
		{ff('platform.moonjelly.browser-storage-controls') &&
			bannerName === BANNER_NAME_COOKIE_CONSENT && <CookieConsentBanner />}
		{ff('opsgenie-consolidation-transitioning-state_d7vm9') &&
			bannerName === BANNER_NAME_TRANSITION && <BannerTransitionAsync />}
		{ff('jira-banner-for-incoming-mail-expiry-notification_y2w1w') &&
			bannerName === BANNER_NAME_MAIL_EXPIRY && <MailExpiryBanner />}
	</>
);

export const Banner = componentWithCondition(getWillShowNav4, NewBanner, OldBanner);
