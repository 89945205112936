/**
 * @generated SignedSource<<1fdcb6662e730122aef756bd081f75b3>>
 * @relayHash ce6af27f9d9b03fa77aadf4099d7cb40
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 5b9085520b88cfe6babdffd77a69b0c76692a50a7c4f2b2e9956242b2861bb08

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcVirtualAgentSettingsQuery } from './srcVirtualAgentSettingsQuery.graphql';

const node: PreloadableConcreteRequest<srcVirtualAgentSettingsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "5b9085520b88cfe6babdffd77a69b0c76692a50a7c4f2b2e9956242b2861bb08",
    "metadata": {},
    "name": "srcVirtualAgentSettingsQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
