import { JS_EVAL_START } from '@atlassian/jira-common-constants/src/analytics-marks';
import { stopInitialPageLoadTimingFromPerformanceMarkStart } from '@atlassian/jira-spa-performance-breakdown';

let reportedBentoBundleReady = false;
/**
 * Optimizes load performance by marking an imported bundle as ready once fully loaded. It ensures
 * efficient load time tracking by preventing duplicate readiness reporting and adjusting performance
 * markers accordingly.
 */
export const markAsBentoBundleReady = <T,>(importedBundle: Promise<T>): Promise<T> => {
	if (!__SERVER__) {
		importedBundle.then(() => {
			// Mutex for reporter
			if (reportedBentoBundleReady) return;
			reportedBentoBundleReady = true;
			stopInitialPageLoadTimingFromPerformanceMarkStart(
				'bento.bundle-ready',
				'jira-spa.js:eval-stop',
			);
			const resources = performance.getEntriesByType('mark');
			for (let i = resources.length - 1; i >= 0; i -= 1) {
				if (
					resources[i].name.startsWith('async-issue-app') &&
					resources[i].name.endsWith(JS_EVAL_START)
				) {
					stopInitialPageLoadTimingFromPerformanceMarkStart('bento.eval', resources[i].name);
					break;
				}
			}
		});
	}
	return importedBundle;
};
