import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ServicedeskKnowledgeHubLandingType from '@atlassian/jira-spa-apps-servicedesk-knowledge-hub-landing';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyServicedeskKnowledgeHubLanding = lazyForPaint<
	typeof ServicedeskKnowledgeHubLandingType
>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-knowledge-hub-landing", jiraSpaEntry: "async-servicedesk-knowledge-hub-landing" */ '@atlassian/jira-spa-apps-servicedesk-knowledge-hub-landing'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyServicedeskKnowledgeHubLanding}
		pageId="spa-apps/servicedesk/knowledge-hub/landing-page"
		shouldShowSpinner
	/>
);
