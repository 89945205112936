import { saveStats } from '../stats';
import { loadBridgeRace } from './load-bridge-race';
import { loadWrmResources } from './load-wrm-resources';
import { SHOULD_LOG_ERROR } from './shared';

export type LoadBridgeStrictOptions = {
	/** AMD name of the resource being loaded */
	name: string;
	/** WRM keys of the module that implements the amd resource */
	wrmKeys: string[];
	shouldLogError?: boolean;
};

/**
 * Load an AMD module if the system has already loaded it, or fallback to loading from given WRM
 * keys without retry.
 * This will only load WRM when the named module is not present.
 * @throws Error
 */
export const loadBridgeStrict = async <T,>({
	name,
	wrmKeys,
	shouldLogError = SHOULD_LOG_ERROR,
}: LoadBridgeStrictOptions): Promise<T> => {
	saveStats(name, wrmKeys);
	try {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return window.require(name) as T;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		await loadWrmResources({ wrmKeys, shouldLogError });
		return loadBridgeRace<T>({ name, shouldLogError });
	}
};
