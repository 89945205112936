import React from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { BannerTransition as BannerTransitionType } from './banner';

const BannerTransition = lazyAfterPaint<typeof BannerTransitionType>(() =>
	import(/* webpackChunkName: "async-banner-eoc-transition" */ './banner').then(
		(module) => module.BannerTransition,
	),
);

export const BannerTransitionAsync = () => (
	<Placeholder name="async-banner-eoc-transition" fallback={null}>
		<BannerTransition />
	</Placeholder>
);
