import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type CustomFieldsDirectoryAdminType from '@atlassian/jira-spa-apps-admin-pages-custom-fields-directory';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyCustomFieldsDirectoryAdmin = lazyForPaint<typeof CustomFieldsDirectoryAdminType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-admin-pages-custom-fields-directory", jiraSpaEntry: "async-admin-pages-custom-fields-directory" */ '@atlassian/jira-spa-apps-admin-pages-custom-fields-directory'
			),
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyCustomFieldsDirectoryAdmin}
		pageId="admin-pages-custom-fields-directory"
		shouldShowSpinner
	/>
);
