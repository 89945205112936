import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type HelpCenterSettingsTicketTypesConfigType from '@atlassian/jira-spa-apps-project-settings-service-desk-ticket-types-config';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyHelpCenterSettingsTicketTypesConfig = lazyForPaint<
	typeof HelpCenterSettingsTicketTypesConfigType
>(
	() =>
		import(
			/* webpackChunkName: "async-help-center-settings-ticket-types-config", jiraSpaEntry: "async-help-center-settings-ticket-types-config" */ '@atlassian/jira-spa-apps-project-settings-service-desk-ticket-types-config'
		),
);

export const HelpCenterSettingsTicketTypesConfig = () => (
	<LazyPage
		Page={LazyHelpCenterSettingsTicketTypesConfig}
		pageId="help-center-settings-ticket-types-config"
		shouldShowSpinner
	/>
);
