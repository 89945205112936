import get from 'lodash/get';
import { createResource } from '@atlassian/jira-router';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils';
import { RESOURCE_TYPE_SERVICEDESK_FEATURES } from '../common/constants';
import type { FeaturesData } from '../types';

const getDataLoader = () =>
	import(
		/* webpackChunkName: "async-resource-servicedesk-practice-features", jiraSpaEntry: "async-resource-servicedesk-practice-features" */ '../controllers/get-data'
	);

const ONE_DAY_IN_MILLIS = 60 * 60 * 1000 * 24;

export const featuresResource = createResource<FeaturesData | null>({
	type: RESOURCE_TYPE_SERVICEDESK_FEATURES,
	getKey: ({ match, query }) =>
		// projectKey from url path or browse/{issueKey}
		getProjectKeyId(match, query).projectKey || get(match, 'params.issueKey', '').split('-')[0],
	getDataLoader,
	maxAge: ONE_DAY_IN_MILLIS,
});
