import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LazyAlertList = lazyForPaint<any>(
	() =>
		import(
			/* webpackChunkName: "async-jira-eoc-alert-list", jiraSpaEntry: "async-jira-eoc-alert-list" */ '@atlassian/jira-eoc-alert-list'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazyAlertList} pageId="async-jira-eoc-alert-list" shouldShowSpinner={false} />
);
