import { isFedRamp } from '@atlassian/atl-context';
import { ff } from '@atlassian/jira-feature-flagging';
// eslint-disable-next-line jira/import-whitelist
import {
	forgeServiceDeskQueueModuleResource,
	staticJSMProjectConnectAddonsNavigationResource,
	staticProjectReportsSidebarConnectAddonsNavigationResource,
	staticSidebarJsmReportsResource,
} from '@atlassian/jira-navigation-apps-resources';
import { kbConfluenceContextResource } from '@atlassian/jira-router-resources-confluence-context';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
	getNavigationSidebarProjectSettingsResource,
} from '@atlassian/jira-router-resources-navigation';
import { projectContextResourceWithCache } from '@atlassian/jira-router-resources-project-context';
import { serviceDeskAnalyticsContextResource } from '@atlassian/jira-router-resources-service-desk-analytics-context';
import { serviceDeskContextResource } from '@atlassian/jira-router-resources-service-desk-context';
import { servicedeskPracticesResource } from '@atlassian/jira-router-resources-service-desk-practices';
import {
	priorityGroupResource,
	queuesNavigationItems,
} from '@atlassian/jira-router-resources-service-desk-queues';
import { virtualAgentOnboardingResource } from '@atlassian/jira-router-resources-service-desk-virtual-agent';
import { featuresResource } from '@atlassian/jira-router-resources-service-project-features';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils';
// eslint-disable-next-line jira/import-whitelist
import { workspaceResource } from '@atlassian/jira-servicedesk-insight-common-workspace-context';

export const getServiceDeskNavigationResources = () => [
	...getNavigationResources(),
	...getNavigationSidebarProjectResource(),
	...getServiceDeskNavigationSingleResources(),
];

export const getServiceDeskNavigationSingleResources = () => [
	...getServiceDeskExclusiveNavigationSingleResources(),
	...getServiceDeskSharedNavigationSingleResources(),
];

/**
 * These resources only work correctly for Service Desk projects.
 * They will throw errors if used on non-Service Desk projects.
 */
export const getServiceDeskExclusiveNavigationSingleResources = () => [
	resourceWithCondition2(() => ff('jsm.queues.sectional.healing'), priorityGroupResource),
	featuresResource,
	staticSidebarJsmReportsResource,
];

/**
 * These resources are able to handle both Service Desk and non-Service Desk projects.
 */
export const getServiceDeskSharedNavigationSingleResources = () => [
	queuesNavigationItems,
	forgeServiceDeskQueueModuleResource,
	staticJSMProjectConnectAddonsNavigationResource,
	staticProjectReportsSidebarConnectAddonsNavigationResource,
];

export const getServiceDeskSettingsResources = () => [
	...getNavigationResources(),
	...getNavigationSidebarProjectResource(),
	...getNavigationSidebarProjectSettingsResource(),
	servicedeskPracticesResource,
	serviceDeskContextResource,
	serviceDeskAnalyticsContextResource,
	resourceWithCondition2(() => !isFedRamp(), virtualAgentOnboardingResource),
];

export const getServicedeskPageContextResources = () => [
	projectContextResourceWithCache,
	serviceDeskContextResource,
	serviceDeskAnalyticsContextResource,
];

export const getQueuesResources = () => [
	...getServicedeskPageContextResources(),
	...getServiceDeskNavigationResources(),
];

export const getKnowledgeBaseResources = () => [kbConfluenceContextResource];

export const getInsightResources = () => [workspaceResource];
