export const RESOURCE_TYPE_ISSUE_NAVIGATOR_RELAY = 'ISSUE_NAVIGATOR_RELAY';
export const RESOURCE_TYPE_ISSUE_NAVIGATOR_FILTER_RELAY = 'ISSUE_NAVIGATOR_FILTER_RELAY';

export const defaultOrderFields = {
	created: 'created',
	lastviewed: 'lastviewed',
	updated: 'updated',
} as const;

export const sortDirections = {
	ASC: 'ASC',
	DESC: 'DESC',
	NONE: 'NONE',
} as const;
