import type { Action } from 'react-sweet-state';
import type { State } from '../../utils';

export const removeDocumentTitle =
	(title: symbol): Action<State> =>
	({ setState, getState }) => {
		const { contextArray } = getState();
		setState({
			contextArray: contextArray.filter((symbol) => symbol !== title),
		});
	};
