import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ProductsJsmConfigurationType from '@atlassian/jira-spa-apps-products-jsm-configuration';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyProductsJsmConfiguration = lazyForPaint<typeof ProductsJsmConfigurationType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-products-jsm-configuration", jiraSpaEntry: "async-products-jsm-configuration" */ '@atlassian/jira-spa-apps-products-jsm-configuration'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyProductsJsmConfiguration}
		pageId="products-jsm-configuration"
		shouldShowSpinner={false}
	/>
);
