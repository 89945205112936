import { PRODUCT_DISCOVERY_PROJECT, SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants';

export const PACKAGE_NAME = '@atlassian/jira-end-of-trial-message-shared-global-component';
export const TEAM_NAME = 'transmuters-growth';
export const STARGATE_URL = '/gateway/api';
export const END_OF_TRIAL_MESSAGE_AUDIENCES = {
	[PRODUCT_DISCOVERY_PROJECT]: [
		'jpd_standard_end_of_trial_message_day_14',
		'jpd_standard_end_of_trial_message_day_13',
		'jpd_standard_end_of_trial_message_day_12',
		'jpd_standard_end_of_trial_message_day_11',
		'jpd_standard_end_of_trial_message_day_10',
		'jpd_standard_end_of_trial_message_day_9',
		'jpd_standard_end_of_trial_message_day_8',
		'jpd_standard_end_of_trial_message_day_7',
		'jpd_standard_end_of_trial_message_day_6',
		'jpd_standard_end_of_trial_message_day_5',
		'jpd_standard_end_of_trial_message_day_4',
		'jpd_standard_end_of_trial_message_day_3',
		'jpd_standard_end_of_trial_message_day_2',
		'jpd_standard_end_of_trial_message_day_1',
	],
	[SERVICE_DESK_PROJECT]: [
		'jsm_standard_end_of_trial_message_day_14',
		'jsm_standard_end_of_trial_message_day_13',
		'jsm_standard_end_of_trial_message_day_12',
		'jsm_standard_end_of_trial_message_day_11',
		'jsm_standard_end_of_trial_message_day_10',
		'jsm_standard_end_of_trial_message_day_9',
		'jsm_standard_end_of_trial_message_day_8',
		'jsm_standard_end_of_trial_message_day_7',
		'jsm_standard_end_of_trial_message_day_6',
		'jsm_standard_end_of_trial_message_day_5',
		'jsm_standard_end_of_trial_message_day_4',
		'jsm_standard_end_of_trial_message_day_3',
		'jsm_standard_end_of_trial_message_day_2',
		'jsm_standard_end_of_trial_message_day_1',
	],
};
