import React, { useMemo, useEffect } from 'react';
import { styled } from '@compiled/react';
import { ComponentWithAnalytics } from '@atlassian/jira-analytics-web-react/src';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize } from '@atlassian/jira-common-styles/src';
import BreadcrumbsApp from '@atlassian/jira-navigation-apps-breadcrumbs/src';
import type { TenantContextSubscriber as TenantContextSubscriberType } from '@atlassian/jira-tenant-context-controller';
import { useBreadcrumbs } from '../controller';
import type { BreadcrumbStateData } from '../types';

type Props = {
	TenantContextSubscriber: typeof TenantContextSubscriberType;
	onMount: () => void;
};

// Adapt to prop type from breadcrumbs app
const mapTrailToBreadcrumbItems = (trail: BreadcrumbStateData[]) =>
	trail.map((crumb) => ({
		...crumb,
		target: crumb.target || '_self',
	}));

const BreadcrumbRenderer = ({ onMount }: Props) => {
	const [{ trail }] = useBreadcrumbs();

	const items = useMemo(() => mapTrailToBreadcrumbItems(trail), [trail]);

	// go/jfe-eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(onMount, []);

	return (
		<BreadcrumbsWrapper>
			<BreadcrumbsApp isVisible items={items} />
		</BreadcrumbsWrapper>
	);
};

export default ComponentWithAnalytics('breadcrumbsRenderer', { onMount: 'mounted' })(
	BreadcrumbRenderer,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BreadcrumbsWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minHeight: `${gridSize * 3}px`,
});
