import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type SystemC2cMigrationsFocusPageType from '@atlassian/jira-spa-apps-c2c-migrations-focus-page';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazySystemC2cMigrationsFocusPage = lazyForPaint<
	typeof SystemC2cMigrationsFocusPageType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-system-c2c-migrations-focus-page", jiraSpaEntry: "async-system-c2c-migrations-focus-page" */ '@atlassian/jira-spa-apps-c2c-migrations-focus-page'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazySystemC2cMigrationsFocusPage}
		pageId="system-c2c-migrations-focus-page"
		shouldShowSpinner
	/>
);
