import { performPostRequest, performDeleteRequest } from '@atlassian/jira-fetch';
import type { ChangeFavouritePayload as Request } from '../model/types';

export const updateFavourite = async (request: Request): Promise<void> => {
	if (request.value) {
		return performPostRequest('/rest/internal/2/favourites', {
			body: JSON.stringify({
				entity: {
					id: request.id,
					type: request.type,
				},
				beforeEntityPosition: request.beforeEntity || null,
			}),
		});
	}

	return performDeleteRequest(`/rest/internal/2/favourites/${request.type}/${request.id}`);
};
