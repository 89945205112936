import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import {
	LazyHorizontalOperationsOverviewNav,
	AsyncHorizontalOperationsOverviewNav,
} from '@atlassian/jira-operations-horizontal-nav';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { getTeamsResource } from '@atlassian/jira-router-resources-eoc-overview';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { eocOverviewRoute } from '@atlassian/jira-router-routes-eoc-overview-routes';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LazyOverviewPage = lazyForPaint<any>(() =>
	import(
		/* webpackChunkName: "async-jira-eoc-overview", jiraSpaEntry: "async-jira-eoc-overview" */ '@atlassian/jira-eoc-overview'
	).then((m) => m.OverviewPage),
);

const EocOverviewComponent = () => (
	<LazyPage
		Page={LazyOverviewPage}
		pageId="async-jira-eoc-alert-overview"
		shouldShowSpinner={false}
	/>
);

export const eocOverviewRouteEntry: Route = createEntry(eocOverviewRoute, {
	exact: true,
	component: EocOverviewComponent,
	resources: [...getNavigationResources(), getConsolidationStateResource(), getTeamsResource],
	navigation: {
		menuId: MENU_ID.HOME,
		horizontal: AsyncHorizontalOperationsOverviewNav,
		horizontalFeatureFlag: 'alx-8775-operations-horizontal-nav_fr1tg',
	},
	forPaint: [LazyAtlassianNavigation, LazyHorizontalOperationsOverviewNav, LazyOverviewPage],
});
