import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const eocGlobalAlertDetailRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_GLOBAL_ALERT_DETAIL,
	path: '/jira/ops/alerts/:id',
	exact: true,
};

export const eocGlobalAlertListRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_GLOBAL_ALERTS,
	path: '/jira/ops/alerts',
};
