/* eslint-disable jira/import-whitelist */
import { loadQuery, type PreloadedQuery } from 'react-relay';
import { ROUTE_NAMES } from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import {
	DEFAULT_LIMIT,
	filterInitialState,
	filterAliasMap,
} from '@atlassian/jira-projects-directory-v3-utils/src/constants';
import {
	mapQueryToFilter,
	sanitizeLegacyQuery,
} from '@atlassian/jira-projects-directory-v3-utils/src/utils';
import getRelayEnvironment from '@atlassian/jira-relay-environment';
import { QueryPromisesMap } from '@atlassian/jira-relay-query-promises';
import { RELAY_RESOURCE_TYPE } from '@atlassian/jira-relay-utils';
import ProjectDirectoryPageQuery, {
	type pageLayout_projectsDirectoryLayout_RootQuery,
} from '@atlassian/jira-relay/src/__generated__/pageLayout_projectsDirectoryLayout_RootQuery.graphql';
import { createResource } from '@atlassian/jira-router';
import { RESOURCE_TYPE_PROJECTS_DIRECTORY_RELAY } from '@atlassian/jira-router-resources-project-directories/src/constants';

export const projectsDirectoryPageResource = createResource<
	PreloadedQuery<pageLayout_projectsDirectoryLayout_RootQuery>
>({
	type: `${RELAY_RESOURCE_TYPE}_${RESOURCE_TYPE_PROJECTS_DIRECTORY_RELAY}`,
	getKey: () => 'projects-directory-relay',
	// @ts-expect-error - might return null
	getData: async ({ query, route }, { tenantContext }) => {
		const { cloudId, isAnonymous } = tenantContext;

		// SSR/AGG does not currently work for anonymous users and returns a 403. We should temporally disable anonymous SSR until
		// the underlying auth issues have been fixed. Duplo team will push the real fix later.
		if (__SERVER__ && isAnonymous) {
			return null;
		}

		const queryReference = loadQuery(
			getRelayEnvironment(),
			ProjectDirectoryPageQuery,
			{
				cloudId: tenantContext.cloudId,
				filter: mapQueryToFilter(filterInitialState, {
					cloudId,
					query: sanitizeLegacyQuery(query),
					aliases: filterAliasMap,
				}),
				first: query?.page ? DEFAULT_LIMIT * (parseInt(query.page, 10) || 1) : DEFAULT_LIMIT,
				last: DEFAULT_LIMIT,
				isAdminSettingsContext: route.name === ROUTE_NAMES.PROJECTS_DIRECTORY_MANAGE,
				isAnonymous,
				isProjectListSidebarExperimentEnabled: expValEquals(
					'contextual_bandits_reccs_in_projects_directory',
					'cohort',
					'variation',
				),
				skipShouldShowRightSidebar:
					isAnonymous || ff('server-render-projects-directory-sidebar_rj9ki'),
			},
			{
				fetchPolicy: 'network-only',
			},
		);

		if (__SERVER__ && queryReference.id != null) {
			await QueryPromisesMap.get(queryReference.id);
		}

		return queryReference;
	},
});
