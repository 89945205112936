import React, { useMemo } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { useProject } from '@atlassian/jira-navigation-apps-sidebar-common';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id';
import type { Environment } from '@atlassian/jira-shared-types';
import {
	useActivationId,
	useCloudId,
	useEnvironment,
	useIsSiteAdmin,
	useLocale,
} from '@atlassian/jira-tenant-context-controller';
import {
	type PostOfficeEnvironmentConfig,
	PostOfficeProvider as PostOfficeBaseProvider,
} from '@atlassian/post-office-context';

const JiraPostOfficeProvider = ({ children }: { children: React.ReactNode }) => {
	const { data } = useProject();
	const isSiteAdmin = useIsSiteAdmin();
	const cloudId = useCloudId();
	const activationId = useActivationId();
	const locale = useLocale();
	const environment = useEnvironment();
	const { data: orgId } = useOrgId();

	const context = useMemo(
		() => ({
			product: 'jira',
			tenantId: cloudId,
			workspaceId: activationId,
			orgId,
			subproduct: data?.type,
			isSiteAdmin,
			locale,
		}),
		[cloudId, orgId, activationId, data?.type, isSiteAdmin, locale],
	);
	const env = useMemo(() => transformJiraEnvToPostOfficeEnvironment(environment), [environment]);
	return (
		<PostOfficeBaseProvider context={{ current: context }} environment={{ env }}>
			{children}
		</PostOfficeBaseProvider>
	);
};

export const PostOfficeProvider = ({ children }: { children: React.ReactNode }) => {
	// Using the Feature Gate used for the placement app-switcher-discovery-section because PostOfficeProvider is always needed when the app-switcher-discovery-section is enabled
	const isPostOfficeEnabled = fg('post_office_app_switcher_discovery_jira');

	return isPostOfficeEnabled ? (
		<JiraPostOfficeProvider>{children}</JiraPostOfficeProvider>
	) : (
		<>{children}</>
	);
};

export const transformJiraEnvToPostOfficeEnvironment = (
	environment: Environment | null,
): PostOfficeEnvironmentConfig['env'] => {
	switch (environment) {
		case 'dev':
		case 'staging':
			return 'staging';
		case 'prod':
			return 'production';
		case 'local':
			return 'local';
		default:
			return 'local';
	}
};
