import { type Flag, toFlagId } from '@atlassian/jira-flags/src/services/types';
import messages from './messages';

/**
 * Maps query-string IDs to `Flag`s
 */
type NotificationMessages = {
	[notificationId: string]: Flag;
};

const notificationMessages: NotificationMessages = {
	'jsw.board-not-found': {
		id: toFlagId('jsw.board-not-found'),
		type: 'error',
		title: messages.boardNotFound,
		description: messages.boardNotFoundDescription,
	},
	'jsw.project-not-found': {
		id: toFlagId('jsw.project-not-found'),
		type: 'error',
		title: messages.projectNotFound,
		description: messages.projectNotFoundDescription,
	},
};

export default notificationMessages;
