/**
 * @generated SignedSource<<bffbc550178ca76168f5d796a42493f6>>
 * @relayHash 53d9b2e496bc7ec604afbc7a4cc9ad09
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 6b0fc2d1c43d1a621f52d449d63cb889c572ca4e9d6b48493739ef054eeef0ac

import type { ConcreteRequest, Query } from 'relay-runtime';
export type uiFieldsPageQuery$variables = {
  cloudId: string;
  projectKey: string;
};
export type uiFieldsPageQuery$data = {
  readonly jira: {
    readonly jiraProjectByKey: {
      readonly name: string;
    } | null | undefined;
  } | null | undefined;
};
export type uiFieldsPageQuery = {
  response: uiFieldsPageQuery$data;
  variables: uiFieldsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKey"
},
v2 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "projectKey"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "uiFieldsPageQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "jiraProjectByKey",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "uiFieldsPageQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "jiraProjectByKey",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "name": "id"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "6b0fc2d1c43d1a621f52d449d63cb889c572ca4e9d6b48493739ef054eeef0ac",
    "metadata": {},
    "name": "uiFieldsPageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "50f7e412b39f8025a80a3735596a66ed";

export default node;
