const urls = Object.freeze({
	alerts: {
		responders: {
			names: {
				url: () => '/v1/alerts/responders/names',
			},
			autoComplete: {
				url: () => '/v1/alerts/responders/auto-complete',
			},
		},
	},
	customers: {
		timezones: {
			url: () => '/v1/customers/timezones',
		},
	},
} as const);

export { urls };
