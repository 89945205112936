import type { PreloadFetchPolicy } from 'react-relay';
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin';
import parameters from '@atlassian/jira-relay/src/__generated__/formatRulesQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const FORMAT_RULES_FIRST = 20;

export const listEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() => import(/* webpackChunkName: "async-graph" */ './src')),
	getPreloadProps: ({ context, tenantContext }: EntryPointRouteParams) => {
		const { projectKey } = context.match.params;
		if (!projectKey) {
			throw new Error('projectKey is required');
		}

		return {
			queries: {
				formattingRulesQuery: {
					parameters,
					variables: {
						projectKey,
						cloudId: tenantContext.cloudId,
						first: FORMAT_RULES_FIRST,
					},
					options: {
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						fetchPolicy: 'network-only' as PreloadFetchPolicy,
					},
				},
			},
			entryPoints: {},
			extraProps: {},
		};
	},
});
