/* eslint sort-keys: "error" */

export const ROUTE_GROUPS = {
	ARCHIVE_ISSUES: 'archive-issues',
	ARJ_SETTINGS: 'arj-settings',
	CLASSIC_BUSINESS: 'classic-business',
	CLASSIC_SOFTWARE: 'classic-software',
	COMPONENTS: 'components',
	CONNECT: 'connect',
	DASHBOARD: 'dashboard',
	DASHBOARDS: 'dashboards',
	DEVOPS: 'devops',
	DIRECTORIES: 'directories',
	EOC: 'eoc',
	ERRORS: 'errors',
	GLOBAL_SETTINGS: 'global-settings',
	HELP_CENTER_SETTINGS: 'helpcenter-settings',
	HOME: 'home',
	ISSUE: 'issue',
	ISSUE_NAVIGATOR: 'issue-navigator',
	LEGACY: 'legacy',
	LEGACY_SERVICEDESK_SETTINGS: 'legacy-jsd-settings',
	MARKETPLACE: 'marketplace',
	PEOPLE_PROFILES: 'people-profiles',
	PERSONAL_SETTINGS: 'personal-settings',

	PLAN_SETTINGS: 'plan-settings',

	POLARIS: 'polaris',
	PROFORMA: 'proforma',
	PROJECT_SETTINGS_CLASSIC: 'project-settings-classic',
	PROJECT_SETTINGS_CORE: 'project-settings-core',
	PROJECT_SETTINGS_SERVICEDESK: 'project-settings-servicedesk',
	PROJECT_SETTINGS_SOFTWARE: 'project-settings-software',
	PROJECT_SETTINGS_SOFTWARE_BOARDS: 'project-settings-software-boards',

	REDIRECTS: 'redirects',
	SERVICEDESK: 'servicedesk',
	SERVICES: 'services',
	SOFTWARE: 'software',
	VERSIONS: 'versions',
} as const;

export const ROUTE_NAMES = {
	ARCHIVED_ISSUES_DIRECTORY: 'archived-issues-directory',
	ARCHIVED_PLANS_DIRECTORY: 'archived-plans-directory',

	ARCHIVED_PROJECTS_DIRECTORY: 'archived-projects-directory',
	ARCHIVED_PROJECT_DIRECT_LINK: 'archived-project-direct-link',

	BOARDS_DIRECTORY: 'boards-directory',
	BROWSE_PROJECT_REDIRECT: 'browse-project-redirect',
	BUSINESS_BOARD: 'next-gen-business-board',
	BUSINESS_CLASSIC_REPORTS: 'business-classic-reports',
	BUSINESS_REPORTS: 'next-gen-business-reports',

	// Jira business routes
	CLASSIC_BUSINESS_OVERVIEW_CALENDAR: `${ROUTE_GROUPS.CLASSIC_BUSINESS}-overview-calendar`,
	CLASSIC_BUSINESS_OVERVIEW_REDIRECT: `${ROUTE_GROUPS.CLASSIC_BUSINESS}-overview-redirect`,
	CLASSIC_BUSINESS_OVERVIEW_SUMMARY: `${ROUTE_GROUPS.CLASSIC_BUSINESS}-overview-summary`,
	CLASSIC_BUSINESS_OVERVIEW_TIMELINE: `${ROUTE_GROUPS.CLASSIC_BUSINESS}-overview-timeline`,

	CLASSIC_BUSINESS_PROJECT_APPROVALS: `${ROUTE_GROUPS.CLASSIC_BUSINESS}-approvals`,
	CLASSIC_BUSINESS_PROJECT_ATTACHMENTS: `${ROUTE_GROUPS.CLASSIC_BUSINESS}-attachments`,
	CLASSIC_BUSINESS_PROJECT_BOARD: `${ROUTE_GROUPS.CLASSIC_BUSINESS}-board`,
	CLASSIC_BUSINESS_PROJECT_BOARD_EMBED: `${ROUTE_GROUPS.CLASSIC_BUSINESS}-board-embed`,
	CLASSIC_BUSINESS_PROJECT_CALENDAR: `${ROUTE_GROUPS.CLASSIC_BUSINESS}-calendar`,
	CLASSIC_BUSINESS_PROJECT_CALENDAR_EMBED: `${ROUTE_GROUPS.CLASSIC_BUSINESS}-calendar-embed`,
	CLASSIC_BUSINESS_PROJECT_FILES: `${ROUTE_GROUPS.CLASSIC_BUSINESS}-files`,
	CLASSIC_BUSINESS_PROJECT_FORM: `${ROUTE_GROUPS.CLASSIC_BUSINESS}-form`,
	CLASSIC_BUSINESS_PROJECT_FORM_BUILDER: `${ROUTE_GROUPS.CLASSIC_BUSINESS}-form-builder`,
	CLASSIC_BUSINESS_PROJECT_FORM_SUBMIT: `${ROUTE_GROUPS.CLASSIC_BUSINESS}-form-submit`,
	CLASSIC_BUSINESS_PROJECT_FORM_SUBMIT_EMBED: `${ROUTE_GROUPS.CLASSIC_BUSINESS}-form-submit-embed`,
	CLASSIC_BUSINESS_PROJECT_LIST: `${ROUTE_GROUPS.CLASSIC_BUSINESS}-list`,
	CLASSIC_BUSINESS_PROJECT_LIST_EMBED: `${ROUTE_GROUPS.CLASSIC_BUSINESS}-list-embed`,
	CLASSIC_BUSINESS_PROJECT_SAMPLE_ONBOARDING: `${ROUTE_GROUPS.CLASSIC_BUSINESS}-sample-onboarding`,
	CLASSIC_BUSINESS_PROJECT_SHORTCUT: `${ROUTE_GROUPS.CLASSIC_BUSINESS}-shortcut`,
	CLASSIC_BUSINESS_PROJECT_SUMMARY: `${ROUTE_GROUPS.CLASSIC_BUSINESS}-summary`,
	CLASSIC_BUSINESS_PROJECT_SUMMARY_EMBED: `${ROUTE_GROUPS.CLASSIC_BUSINESS}-summary-embed`,
	CLASSIC_BUSINESS_PROJECT_TIMELINE: `${ROUTE_GROUPS.CLASSIC_BUSINESS}-timeline`,
	CLASSIC_BUSINESS_PROJECT_TIMELINE_EMBED: `${ROUTE_GROUPS.CLASSIC_BUSINESS}-timeline-embed`,
	CLASSIC_BUSINESS_REDIRECT: `${ROUTE_GROUPS.CLASSIC_BUSINESS}-redirect`,

	CLASSIC_PROJECT_SETTINGS_CODE: `${ROUTE_GROUPS.CLASSIC_SOFTWARE}-code`,
	CLASSIC_PROJECT_SETTINGS_CORE_PROFORMA_FORMS: `${ROUTE_GROUPS.PROJECT_SETTINGS_CORE}-proforma-project-forms`,
	CLASSIC_PROJECT_SETTINGS_DATA_CLASSIFICATIONS: `${ROUTE_GROUPS.PROJECT_SETTINGS_CLASSIC}-data-classifications`,
	CLASSIC_PROJECT_SETTINGS_DETAILS: `${ROUTE_GROUPS.CLASSIC_SOFTWARE}-details`,
	CLASSIC_PROJECT_SETTINGS_FEATURES: `${ROUTE_GROUPS.CLASSIC_SOFTWARE}-features`,
	CLASSIC_PROJECT_SETTINGS_ISSUE_LAYOUT: `${ROUTE_GROUPS.PROJECT_SETTINGS_CLASSIC}-issue-layout`,
	CLASSIC_PROJECT_SETTINGS_NOTIFICATION_EMAIL: `${ROUTE_GROUPS.CLASSIC_SOFTWARE}-notifications-notification-email`,
	CLASSIC_PROJECT_SETTINGS_OPSGENIE: `${ROUTE_GROUPS.CLASSIC_SOFTWARE}-link-opsgenie`,
	CLASSIC_PROJECT_SETTINGS_SOFTWARE_DETAILS: `${ROUTE_GROUPS.CLASSIC_SOFTWARE}-details`,
	CLASSIC_PROJECT_SETTINGS_SOFTWARE_NOTIFICATIONS: `${ROUTE_GROUPS.CLASSIC_SOFTWARE}-notifications`,
	CLASSIC_PROJECT_SETTINGS_SOFTWARE_NOTIFICATIONS_ISSUE_LOGS: `${ROUTE_GROUPS.CLASSIC_SOFTWARE}-notifications-issue-logs`,
	CLASSIC_PROJECT_SETTINGS_SOFTWARE_PROFORMA_EDIT_FORMS: `${ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE}-proforma-edit-forms`,
	CLASSIC_PROJECT_SETTINGS_SOFTWARE_PROFORMA_FORMS: `${ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE}-proforma-project-forms`,
	CLASSIC_PROJECT_SETTINGS_TOOLCHAIN: `${ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE}-toolchain-company-managed`,

	COMPASS_PRODUCT_SETTINGS: 'compass-product-settings',
	CONFLUENCE_PRODUCT_SETTINGS: 'confluence-product-settings',

	CONNECT_GENERAL: 'connect-general',
	CONNECT_GENERAL_INVALID: 'connect-general-invalid',
	CONNECT_GENERAL_SERVICE_DESK_PROJECT: 'connect-general-service-desk-project',
	CONNECT_PROJECT: 'connect-project',
	CREATE_PROJECT: 'create-project',

	CUSTOMER_SERVICE_ACCOUNTS: 'customer-service-accounts',

	DASHBOARD: 'dashboard',
	DASHBOARDS_DIRECTORY: 'dashboards-directory',
	DASHBOARDS_DIRECTORY_SECURE: 'dashboards-directory-secure',
	DASHBOARD_EDIT: 'dashboard-edit',
	DASHBOARD_SELECTED: 'dashboard-selected',
	DASHBOARD_VIEW: 'dashboard-view',
	DASHBOARD_VIEW_EMBED: 'dashboard-view-embed',
	DASHBOARD_WALLBOARD: 'dashboard-wallboard',

	DEVOPS_CONNECTED_PROJECT: 'devops-connected-project',

	EOC_ALERTS: 'eoc-alerts',
	EOC_ALERT_DETAIL: 'eoc-alert-detail',
	EOC_ALERT_ISSUE_SYNCING: 'eoc-alert-issue-syncing',
	EOC_ALERT_ISSUE_SYNCING_CREATE: 'eoc-alert-issue-syncing-create',
	EOC_ALERT_ISSUE_SYNCING_DETAIL: 'eoc-alert-issue-syncing-detail',
	EOC_API_KEY_MANAGEMENT: 'eoc-api-key-management',
	EOC_ASSIGN_CUSTOM_ROLES: 'eoc-assign-custom-roles',
	EOC_AUTOMATIONS: 'eoc-automations',
	EOC_CUSTOM_ROLES: 'eoc-custom-roles',
	EOC_GLOBAL_ALERTS: 'eoc-global-alerts',
	EOC_GLOBAL_ALERT_DETAIL: 'eoc-global-alert-detail',
	EOC_GLOBAL_GROUP_DETAIL: 'eoc-global-group-detail',
	EOC_GLOBAL_MAINTENANCE: 'eoc-global-maintenance',
	EOC_GLOBAL_REPORTS: 'eoc-global-reports',
	EOC_HEARTBEATS: 'eoc-heartbeats',
	EOC_INCOMING_CALL: 'eoc-incoming-call',
	EOC_INCOMING_CALL_DETAIL: 'eoc-incoming-call-detail',
	EOC_INTEGRATIONS: 'eoc-integrations',
	EOC_INTEGRATION_CREATE: 'eoc-integration-create',
	EOC_INTEGRATION_DETAIL: 'eoc-integration-detail',
	EOC_INTEGRATION_LIST: 'eoc-integration-list',
	EOC_INTEGRATION_LOG: 'eoc-integration-log',
	EOC_MAINTENANCE: 'eoc-maintenance',
	EOC_ON_CALL: 'eoc-on-call',
	EOC_OVERVIEW: 'eoc-overview',
	EOC_PERMISSIONS: 'eoc-permissions',
	EOC_POLICIES: 'eoc-policies',
	EOC_REPORTS: 'eoc-reports',
	EOC_REPORTS_TEAM: 'eoc-reports-team',
	EOC_REPORTS_UNSUBSCRIBE: 'eoc-reports-unsubscribe',
	EOC_ROLE_BASED_NOTIFICATIONS: 'eoc-role-based-notifications',
	EOC_ROLE_BASED_NOTIFICATIONS_DETAIL: 'eoc-role-based-notifications-detail',
	EOC_SETTINGS: 'eoc-settings',
	EOC_TEAM_ALERTS: 'eoc-team-alerts',
	EOC_TEAM_ALERT_DETAILS: 'eoc-team-alert-detail',
	EOC_TEAM_ALERT_ISSUE_SYNCING: 'eoc-team-alert-issue-syncing',
	EOC_TEAM_ALERT_ISSUE_SYNCING_CREATE: 'eoc-team-alert-issue-syncing-create',
	EOC_TEAM_ALERT_ISSUE_SYNCING_DETAIL: 'eoc-team-alert-issue-syncing-detail',
	EOC_TEAM_CUSTOMER_LOGS: 'eoc-team-customer-logs',
	EOC_TEAM_CUSTOMER_LOGS_DOWNLOAD: 'eoc-team-customer-logs-download',
	EOC_TEAM_GROUP_DETAIL: 'eoc-team-group-detail',
	EOC_TEAM_INTEGRATIONS: 'eoc-team-integrations',
	EOC_TEAM_INTEGRATION_CREATE: 'eoc-team-integration-create',
	EOC_TEAM_INTEGRATION_DETAIL: 'eoc-team-integration-detail',
	EOC_TEAM_INTEGRATION_LIST: 'eoc-team-integration-list',
	EOC_TEAM_INTEGRATION_LOG: 'eoc-team-integration-log',
	EOC_TEAM_POLICIES: 'eoc-team-policies',
	EOC_UNLOCK_OPERATIONS: 'eoc-unlock-operations',
	EOC_WHO_IS_ON_CALL: 'eoc-who-is-on-call',

	ERRORS_NOT_FOUND: 'errors-not-found',
	EXTERNAL_IMPORT: 'external-import',
	FILTERS_DIRECTORY: 'filters-directory',
	FILTERS_DIRECTORY_SECURE: 'filters-directory-secure',

	FORGE_ADMIN: 'forge-spa-admin',
	FORGE_ADMIN_SETTINGS: 'forge-spa-admin-settings',
	FORGE_FIELD_CONTEXTUAL_CONFIGURATION: 'forge-spa-field-context-config',
	FORGE_GLOBAL: 'forge-spa-global',
	FORGE_PROJECT: 'forge-spa-project',
	FORGE_SERVICEDESK_QUEUE: 'forge-spa-servicedesk-queue',

	GLOBAL_ADMIN_HELPER_EMAIL_NOTIFICATIONS_LOG: 'global-admin-helper-email-notifications-log',

	GLOBAL_ISSUE_NAVIGATOR: 'global-issue-navigator',
	GLOBAL_ISSUE_NAVIGATOR_BROWSE_ISSUE_REDIRECT: 'global-issue-navigator-browse-issue-redirect',

	GLOBAL_MAIL_SETTINGS: 'global-mail-settings',

	GLOBAL_SETTINGS_CONTACT_ADMINISTRATOR: 'global-settings-contact-administrator',
	GLOBAL_SETTINGS_CUSTOM_FIELDS: 'global-settings-issues-custom-fields-view',
	GLOBAL_SETTINGS_CUSTOM_FIELDS_ASSOCIATE: 'global-settings-issues-custom-fields-associate',
	GLOBAL_SETTINGS_CUSTOM_FIELDS_CONFIGURE: 'global-settings-issues-custom-fields-config',
	GLOBAL_SETTINGS_CUSTOM_FIELDS_DELETE: 'global-settings-issues-custom-fields-delete',
	GLOBAL_SETTINGS_CUSTOM_FIELDS_EDIT: 'global-settings-issues-custom-fields-edit',
	GLOBAL_SETTINGS_CUSTOM_FIELDS_MANAGE: 'global-settings-issues-custom-fields-manage',
	GLOBAL_SETTINGS_CUSTOM_FIELDS_TRANSLATE: 'global-settings-issues-custom-fields-translate',
	GLOBAL_SETTINGS_EDIT_PERMISSION_SCHEMES: 'global-settings-issues-edit-permissions-schemes',
	GLOBAL_SETTINGS_FIELD_LAYOUTS: 'global-settings-issues-fieldlayouts-view',
	GLOBAL_SETTINGS_FIELD_LAYOUTS_CONFIGURE: 'global-settings-issues-fieldview',
	GLOBAL_SETTINGS_FIELD_LAYOUTS_COPY: 'global-settings-issues-fieldlayouts-copy',
	GLOBAL_SETTINGS_FIELD_LAYOUT_SCHEMES: 'global-settings-issues-fieldlayouts-schemes-view',
	GLOBAL_SETTINGS_FIELD_LAYOUT_SCHEMES_CONFIGURE:
		'global-settings-issues-fieldlayouts-schemes-config',
	GLOBAL_SETTINGS_FIELD_LAYOUT_SCHEMES_COPY: 'global-settings-issues-fieldlayouts-schemes-copy',
	GLOBAL_SETTINGS_FIELD_LAYOUT_SCHEMES_DELETE: 'global-settings-issues-fieldlayouts-schemes-delete',
	GLOBAL_SETTINGS_FIELD_LAYOUT_SCHEMES_EDIT: 'global-settings-issues-fieldlayouts-schemes-edit',
	GLOBAL_SETTINGS_FIELD_LAYOUT_SCHEMES_EDIT_ENTRY:
		'global-settings-issues-fieldlayouts-schemes-entity',
	GLOBAL_SETTINGS_FIELD_LAYOUT_SCHEMES_VIEW: 'global-settings-issues-fieldlayouts-config',
	GLOBAL_SETTINGS_FORM_DATA_CONNECTIONS: 'global-settings-issues-form-data-connections',
	GLOBAL_SETTINGS_GLOBAL_PERMISSIONS: 'global-settings-global-permissions',
	GLOBAL_SETTINGS_ISSUES_LINK_TYPES: 'global-settings-issues-linktypes-view',
	GLOBAL_SETTINGS_ISSUES_LINK_TYPES_ACTIVATE: 'global-settings-issues-linktypes-activate',
	GLOBAL_SETTINGS_ISSUES_LINK_TYPES_DEACTIVATE: 'global-settings-issues-linktypes-deactivate',
	GLOBAL_SETTINGS_ISSUES_LINK_TYPES_DELETE: 'global-settings-issues-linktypes-delete',
	GLOBAL_SETTINGS_ISSUES_LINK_TYPES_EDIT: 'global-settings-issues-linktypes-edit',
	GLOBAL_SETTINGS_ISSUES_NEW_ISSUE_SEARCH: 'global-settings-issues-new-issue-search',
	GLOBAL_SETTINGS_ISSUES_NEW_ISSUE_VIEW: 'global-settings-issues-new-issue-view',
	GLOBAL_SETTINGS_ISSUES_NOTIFICATION_ADD: 'global-settings-issues-notification-add',
	GLOBAL_SETTINGS_ISSUES_NOTIFICATION_DELETE: 'global-settings-issues-notification-delete',
	GLOBAL_SETTINGS_ISSUES_NOTIFICATION_EDIT: 'global-settings-issues-notification-edit',
	GLOBAL_SETTINGS_ISSUES_NOTIFICATION_SCHEME_ADD: 'global-settings-issues-notification-scheme-add',
	GLOBAL_SETTINGS_ISSUES_NOTIFICATION_SCHEME_DELETE:
		'global-settings-issues-notification-scheme-delete',
	GLOBAL_SETTINGS_ISSUES_NOTIFICATION_SCHEME_EDIT:
		'global-settings-issues-notification-scheme-edit',
	GLOBAL_SETTINGS_ISSUES_NOTIFICATION_VIEW: 'global-settings-issues-notification-view',
	GLOBAL_SETTINGS_ISSUES_PERMISSIONS_SCHEMES: 'global-settings-issues-permissions-schemes-view',
	GLOBAL_SETTINGS_ISSUES_PERMISSIONS_SCHEMES_ADD: 'global-settings-issues-permissions-schemes-add',
	GLOBAL_SETTINGS_ISSUES_PERMISSIONS_SCHEMES_DELETE:
		'global-settings-issues-permissions-schemes-delete',
	GLOBAL_SETTINGS_ISSUES_PERMISSIONS_SCHEMES_EDIT:
		'global-settings-issues-permissions-schemes-edit',
	GLOBAL_SETTINGS_ISSUES_PRIORITIES: 'global-settings-issues-priorities',
	GLOBAL_SETTINGS_ISSUES_PRIORITIES_DELETE: 'global-settings-issues-priorities-delete',
	GLOBAL_SETTINGS_ISSUES_PRIORITIES_EDIT: 'global-settings-issues-priorities-edit',
	GLOBAL_SETTINGS_ISSUES_PRIORITIES_TRANSLATE: 'global-settings-issues-priorities-translations',
	GLOBAL_SETTINGS_ISSUES_PRIORITY_SCHEMES: 'global-settings-issues-priority-schemes',
	GLOBAL_SETTINGS_ISSUES_RESOLUTIONS: 'global-settings-issues-resolutions',
	GLOBAL_SETTINGS_ISSUES_RESOLUTIONS_DEFAULT: 'global-settings-issues-resolutions-default',
	GLOBAL_SETTINGS_ISSUES_RESOLUTIONS_DELETE: 'global-settings-issues-resolutions-delete',
	GLOBAL_SETTINGS_ISSUES_RESOLUTIONS_EDIT: 'global-settings-issues-resolutions-edit',
	GLOBAL_SETTINGS_ISSUES_RESOLUTIONS_TRANSLATE: 'global-settings-issues-resolutions-translations',
	GLOBAL_SETTINGS_ISSUES_SECURITY_EDIT: 'global-settings-issues-security-edit',
	GLOBAL_SETTINGS_ISSUES_SECURITY_LEVEL_ADD: 'global-settings-issues-security-level-add',
	GLOBAL_SETTINGS_ISSUES_SECURITY_LEVEL_DELETE: 'global-settings-issues-security-level-delete',
	GLOBAL_SETTINGS_ISSUES_SECURITY_LEVEL_EDIT: 'global-settings-issues-security-level-edit',
	GLOBAL_SETTINGS_ISSUES_SECURITY_SCHEMES: 'global-settings-issues-security-schemes',
	GLOBAL_SETTINGS_ISSUES_SECURITY_SCHEMES_ADD: 'global-settings-issues-security-schemes-add',
	GLOBAL_SETTINGS_ISSUES_SECURITY_SCHEMES_DELETE: 'global-settings-issues-security-schemes-delete',
	GLOBAL_SETTINGS_ISSUES_SECURITY_SCHEMES_EDIT: 'global-settings-issues-security-schemes-edit',
	GLOBAL_SETTINGS_ISSUES_STATUSES: 'global-settings-issues-statuses',
	GLOBAL_SETTINGS_ISSUES_STATUSES_TRANSLATE: 'global-settings-issues-statuses-translations',
	GLOBAL_SETTINGS_ISSUE_FIELD_CONFIGURE: 'global-settings-issues-field-configure',
	GLOBAL_SETTINGS_ISSUE_FIELD_CONFIGURE_EDIT: 'global-settings-issues-field-configure-edit',
	GLOBAL_SETTINGS_ISSUE_FIELD_SCHEMES: 'global-settings-issues-field-schemes-view',
	GLOBAL_SETTINGS_ISSUE_FIELD_SCHEMES_ADD: 'global-settings-issues-field-schemes-add',
	GLOBAL_SETTINGS_ISSUE_FIELD_SCHEMES_CONFIGURE: 'global-settings-issues-field-schemes-configure',
	GLOBAL_SETTINGS_ISSUE_FIELD_SCHEMES_COPY: 'global-settings-issues-field-schemes-copy',
	GLOBAL_SETTINGS_ISSUE_FIELD_SCHEMES_DELETE: 'global-settings-issues-field-schemes-delete',
	GLOBAL_SETTINGS_ISSUE_FIELD_SCHEMES_EDIT: 'global-settings-issues-field-schemes-edit',
	GLOBAL_SETTINGS_ISSUE_FIELD_SCHEMES_EDIT_ITEM: 'global-settings-issues-field-schemes-edit-item',
	GLOBAL_SETTINGS_ISSUE_FIELD_SCREENS: 'global-settings-issues-field-screens',
	GLOBAL_SETTINGS_ISSUE_FIELD_SCREENS_COPY: 'global-settings-issues-field-copy',
	GLOBAL_SETTINGS_ISSUE_FIELD_SCREENS_DELETE: 'global-settings-issues-field-delete',
	GLOBAL_SETTINGS_ISSUE_FIELD_SCREENS_EDIT: 'global-settings-issues-field-edit',
	GLOBAL_SETTINGS_ISSUE_HIERARCHY: 'global-settings-issues-hierarchy-view',
	GLOBAL_SETTINGS_ISSUE_HIERARCHY_REDIRECT: 'global-settings-issues-hierarchy-redirect',
	GLOBAL_SETTINGS_ISSUE_SUBTASKS: 'global-settings-issues-subtasks-manage',
	GLOBAL_SETTINGS_ISSUE_SUBTASKS_DISABLE: 'global-settings-issues-subtasks-disable',
	GLOBAL_SETTINGS_ISSUE_SUBTASKS_EDIT: 'global-settings-issues-subtasks-edit',
	GLOBAL_SETTINGS_ISSUE_SUBTASKS_TRANSLATE: 'global-settings-issues-subtasks-tranlations',
	GLOBAL_SETTINGS_ISSUE_TIME_TRACKING: 'global-settings-issues-time-tracking',
	GLOBAL_SETTINGS_ISSUE_TYPES: 'global-settings-issues-types-view',
	GLOBAL_SETTINGS_ISSUE_TYPES_DELETE: 'global-settings-issues-types-delete',
	GLOBAL_SETTINGS_ISSUE_TYPES_EDIT: 'global-settings-issues-types-edit',
	GLOBAL_SETTINGS_ISSUE_TYPES_SCHEMES: 'global-settings-issues-types-schemes-manage',
	GLOBAL_SETTINGS_ISSUE_TYPES_SCHEMES_ADD_OR_COPY: 'global-settings-issues-types-schemes-option',
	GLOBAL_SETTINGS_ISSUE_TYPES_SCHEMES_ASSOCIATE:
		'global-settings-issues-types-schemes-associate-default',
	GLOBAL_SETTINGS_ISSUE_TYPES_SCHEMES_DELETE: 'global-settings-issues-types-schemes-option-delete',
	GLOBAL_SETTINGS_ISSUE_TYPES_SCREEN_SCHEMES: 'global-settings-issues-types-screen-schemes-view',
	GLOBAL_SETTINGS_ISSUE_TYPES_SCREEN_SCHEMES_CONFIGURE:
		'global-settings-issues-types-screen-schemes-configure',
	GLOBAL_SETTINGS_ISSUE_TYPES_SCREEN_SCHEMES_COPY:
		'global-settings-issues-types-screen-schemes-copy',
	GLOBAL_SETTINGS_ISSUE_TYPES_SCREEN_SCHEMES_DELETE:
		'global-settings-issues-types-screen-schemes-delete',
	GLOBAL_SETTINGS_ISSUE_TYPES_SCREEN_SCHEMES_EDIT:
		'global-settings-issues-types-screen-schemes-edit',
	GLOBAL_SETTINGS_ISSUE_TYPES_SCREEN_SCHEMES_ENTITY:
		'global-settings-issues-types-screen-schemes-entity',
	GLOBAL_SETTINGS_ISSUE_TYPES_TRANSLATE: 'global-settings-issues-types-translations',
	GLOBAL_SETTINGS_ISSUE_WORKFLOW: 'global-settings-issues-workflows-pages',
	GLOBAL_SETTINGS_ISSUE_WORKFLOW_EDIT: 'global-settings-issues-workflow-edit',
	GLOBAL_SETTINGS_ISSUE_WORKFLOW_SCHEMES: 'global-settings-issues-workflows-schemes-view',
	GLOBAL_SETTINGS_ISSUE_WORKFLOW_SCHEMES_EDIT: 'global-settings-issues-workflows-schemes-edit',
	GLOBAL_SETTINGS_ISSUE_WORKFLOW_TRANSITION: 'global-settings-issues-workflow-transition',
	GLOBAL_SETTINGS_ISSUE_WORKFLOW_TRANSITION_META_ATTRIBUTES:
		'global-settings-issues-workflow-transition-meta-attributes',
	GLOBAL_SETTINGS_MIGRATIONS_DASHBOARD: 'global-settings-migrations-dashboard',
	GLOBAL_SETTINGS_MIGRATIONS_FOCUS_PAGE: 'global-settings-migrations-focus-page',
	GLOBAL_SETTINGS_MIGRATIONS_HOME: 'global-settings-migrations-home',
	GLOBAL_SETTINGS_PERFORMANCE_CUSTOM_FIELD: 'global-settings-performance-custom-field',
	GLOBAL_SETTINGS_PERFORMANCE_ISSUE: 'global-settings-performance-issue',
	// This is an old route that is not used anymore. It is kept here for backward compatibility.
	GLOBAL_SETTINGS_PERFORMANCE_METRIC_DETAIL: 'global-settings-performance-metric-detail',
	GLOBAL_SETTINGS_PERFORMANCE_OVERVIEW: 'global-settings-performance-overview',
	GLOBAL_SETTINGS_PERFORMANCE_PROJECT_CLEANUP: 'global-settings-performance-project-cleanup',
	GLOBAL_SETTINGS_PERFORMANCE_PROJECT_ROLE_ACTOR: 'global-settings-performance-project-role-actor',
	GLOBAL_SETTINGS_PERMISSION_SCHEMES: 'global-settings-permissions-schemes',
	GLOBAL_SETTINGS_PRODUCTS_APPLICATION_LINKS: 'global-settings-products-applinks',
	GLOBAL_SETTINGS_PRODUCTS_DVCS: 'global-settings-products-dvcs',
	GLOBAL_SETTINGS_PRODUCTS_SDMAIL: 'global-settings-products-sdmail',
	GLOBAL_SETTINGS_PRODUCTS_STATUS_PAGE: 'global-settings-products-jsd-status',
	GLOBAL_SETTINGS_PROJECTS_CATEGORIES_DELETE: 'global-settings-projects-categories-delete',
	GLOBAL_SETTINGS_PROJECTS_CATEGORIES_EDIT: 'global-settings-projects-categories-edit',
	GLOBAL_SETTINGS_PROJECTS_CATEGORIES_VIEW: 'global-settings-projects-categories-view',
	GLOBAL_SETTINGS_SHARED_DASHBOARDS: 'global-settings-system-shared-dashboards',
	GLOBAL_SETTINGS_SYSTEM_ADMIN_NOTIFICATION_HELPER: 'global-settings-system-helper-notification',
	GLOBAL_SETTINGS_SYSTEM_ADMIN_PERMISSION_HELPER: 'global-settings-system-helper-permission',
	GLOBAL_SETTINGS_SYSTEM_ADVANCED_ATTACHMENTS: 'global-settings-system-attachments',
	GLOBAL_SETTINGS_SYSTEM_ADVANCED_EVENTS: 'global-settings-system-events',
	GLOBAL_SETTINGS_SYSTEM_ADVANCED_EVENTS_ADD: 'global-settings-system-events-add',
	GLOBAL_SETTINGS_SYSTEM_ADVANCED_EVENTS_DELETE: 'global-settings-system-events-delete',
	GLOBAL_SETTINGS_SYSTEM_ADVANCED_EVENTS_EDIT: 'global-settings-system-events-edit',
	GLOBAL_SETTINGS_SYSTEM_ADVANCED_LEXORANK_MANAGEMENT: 'global-settings-system-lexorank',
	GLOBAL_SETTINGS_SYSTEM_ADVANCED_SERVICES: 'global-settings-system-services',
	GLOBAL_SETTINGS_SYSTEM_ADVANCED_WEBHOOKS: 'global-settings-system-webhooks',
	GLOBAL_SETTINGS_SYSTEM_BULK_MAIL: 'global-settings-system-bulk-mail',
	GLOBAL_SETTINGS_SYSTEM_GENERAL_CONFIGURATION_ADVANCED_SETTINGS:
		'global-settings-system-application-advanced',
	GLOBAL_SETTINGS_SYSTEM_GENERAL_CONFIGURATION_EDIT_SETTINGS:
		'global-settings-system-application-edit',
	GLOBAL_SETTINGS_SYSTEM_GLOBAL_PERMISSIONS: 'global-settings-system-global-permissions',
	GLOBAL_SETTINGS_SYSTEM_IMPORT_EXPORT_BACKUP_MANAGER: 'global-settings-system-export',
	GLOBAL_SETTINGS_SYSTEM_IMPORT_EXPORT_CLOUD_OR_SERVER_IMPORT:
		'global-settings-system-import-cloud',
	GLOBAL_SETTINGS_SYSTEM_IMPORT_EXPORT_EXTERNAL_IMPORT: 'global-settings-system-import-external',
	GLOBAL_SETTINGS_SYSTEM_IMPORT_EXPORT_EXTERNAL_IMPORT_CSV: 'global-settings-system-import-csv',
	GLOBAL_SETTINGS_SYSTEM_IMPORT_EXPORT_EXTERNAL_IMPORT_JSON: 'global-settings-system-import-json',
	GLOBAL_SETTINGS_SYSTEM_IMPORT_EXPORT_EXTERNAL_IMPORT_TRELLO:
		'global-settings-system-import-trello',
	GLOBAL_SETTINGS_SYSTEM_ISSUE_COLLECTORS: 'global-settings-system-collectors',
	GLOBAL_SETTINGS_SYSTEM_MAIL_DELETE_MAIL_HANDLER: 'global-settings-system-delete-mail-handler',
	GLOBAL_SETTINGS_SYSTEM_MAIL_INCOMING_MAIL: 'global-settings-system-mail-incoming',
	GLOBAL_SETTINGS_SYSTEM_MAIL_OUTGOING_MAIL: 'global-settings-system-mail-outgoing',
	GLOBAL_SETTINGS_SYSTEM_MAIL_SEND_MAIL: 'global-settings-system-mail-send',
	GLOBAL_SETTINGS_SYSTEM_SECURITY_GLOBAL_PERMISSIONS_OLD: 'global-settings-system-permissions', // remove when cleaning up everest-global-permissions-fe-modernisation_vvy6j ff
	GLOBAL_SETTINGS_SYSTEM_SECURITY_PROJECT_ROLES: 'global-settings-system-roles-view',
	GLOBAL_SETTINGS_SYSTEM_SECURITY_PROJECT_ROLES_ACTORS: 'global-settings-system-roles-actors',
	GLOBAL_SETTINGS_SYSTEM_SECURITY_PROJECT_ROLES_ACTORS_GROUPS:
		'global-settings-system-roles-actors-groups',
	GLOBAL_SETTINGS_SYSTEM_SECURITY_PROJECT_ROLES_ACTORS_USERS:
		'global-settings-system-roles-actors-users',
	GLOBAL_SETTINGS_SYSTEM_SECURITY_PROJECT_ROLES_DELETE: 'global-settings-system-roles-delete',
	GLOBAL_SETTINGS_SYSTEM_SECURITY_PROJECT_ROLES_EDIT: 'global-settings-system-roles-edit',
	GLOBAL_SETTINGS_SYSTEM_SECURITY_PROJECT_ROLES_USAGE: 'global-settings-system-roles-usage',
	GLOBAL_SETTINGS_SYSTEM_SHARED_FILTERS: 'global-settings-system-shared-filters',
	GLOBAL_SETTINGS_SYSTEM_TROUBLESHOOTING_AUDIT_LOG: 'global-settings-system-auditing',
	GLOBAL_SETTINGS_SYSTEM_UI_ANNOUNCEMENT_BANNER: 'global-settings-system-ui-announcement-banner',
	GLOBAL_SETTINGS_SYSTEM_UI_DASHBOARD_EDIT: 'global-settings-system-ui-dashboard-edit',
	GLOBAL_SETTINGS_SYSTEM_UI_LOOK_AND_FEEL: 'global-settings-system-look',
	GLOBAL_SETTINGS_SYSTEM_UI_PREFERENCES_APPLY:
		'global-settings-system-ui-default-user-preferences-apply',
	GLOBAL_SETTINGS_SYSTEM_UI_USER_DEFAULTS: 'global-settings-system-default-settings',

	HELP_CENTER_SETTINGS: ROUTE_GROUPS.HELP_CENTER_SETTINGS,
	HELP_CENTER_SETTINGS_CUSTOMER_NOTIFICATIONS: `${ROUTE_GROUPS.HELP_CENTER_SETTINGS}-customer-notifications`,
	HELP_CENTER_SETTINGS_EMAIL: `${ROUTE_GROUPS.HELP_CENTER_SETTINGS}-email`,
	HELP_CENTER_SETTINGS_KNOWLEDGE_BASE: `${ROUTE_GROUPS.HELP_CENTER_SETTINGS}-kb`,
	HELP_CENTER_SETTINGS_REQUEST_TYPES: `${ROUTE_GROUPS.HELP_CENTER_SETTINGS}-unassigned-ticket-types-config`,
	HELP_CENTER_SETTINGS_REQUEST_TYPE_FIELDS: `${ROUTE_GROUPS.HELP_CENTER_SETTINGS}-unassigned-ticket-type-fields`,
	HELP_CENTER_SETTINGS_WEB_PORTAL: `${ROUTE_GROUPS.HELP_CENTER_SETTINGS}-web-portal`,

	HOME_ONBOARDING_REDIRECT: 'home-onboarding-redirect',
	HOME_REDIRECT: 'home-redirect',
	ISSUE: 'issue',
	ISSUE_CREATE_EMBED: 'issue-create-embed',
	ISSUE_EMBED: 'issue-embed',
	JIRA_SEARCH: 'jira-search',
	KNOWLEDGE_HUB_ALL_ARTICLES: 'knowledge-hub-all-articles',
	KNOWLEDGE_HUB_ARTICLE_EMBED_EDITOR: 'kb-article-embed-editor',
	KNOWLEDGE_HUB_ARTICLE_IN_CATEGORY: 'knowledge-hub-article-in-category',
	KNOWLEDGE_HUB_BROWSE_ALL_ARTICLES: 'knowledge-hub-browse-all-articles',
	KNOWLEDGE_HUB_BROWSE_ALL_CATEGORIES: 'knowledge-hub-browse-all-categories',
	KNOWLEDGE_HUB_BROWSE_VIEW_ARTICLE: 'knowledge-hub-browse-view-article',
	KNOWLEDGE_HUB_CATEGORY: 'knowledge-hub-category',
	KNOWLEDGE_HUB_CATEGORY_PAGE: 'knowledge-hub-category-page',
	KNOWLEDGE_HUB_DRAFTS: 'knowledge-hub-drafts',
	KNOWLEDGE_HUB_KNOWLEDGE_GAPS: 'knowledge-hub-knowledge-gaps',
	KNOWLEDGE_HUB_LANDING: 'knowledge-hub-landing',
	KNOWLEDGE_HUB_SEARCH: 'knowledge-hub-search',
	KNOWLEDGE_HUB_SEARCH_ARTICLES: 'knowledge-hub-search-articles',
	KNOWLEDGE_HUB_SETUP: 'knowledge-hub-setup',
	LEGACY_ALL_REPORTS: 'legacy-all-reports',

	LEGACY_BACKLOG: 'legacy-backlog',
	LEGACY_BOARD: 'legacy-board',
	LEGACY_CONNECT_GENERAL: 'legacy-connect-general',
	LEGACY_CONNECT_PROJECT: 'legacy-connect-project',
	LEGACY_CORE_REPORTS: 'legacy-core-reports',
	LEGACY_CUSTOM_REPORT: 'legacy-custom-report',
	LEGACY_FALLBACK: 'legacy-fallback',
	LEGACY_GIN: 'legacy-gin',
	LEGACY_GIN_ISSUE: 'legacy-gin-issue',
	LEGACY_GIN_QUICKSEARCH: 'legacy-gin-quicksearch',
	LEGACY_GIN_SUBSCRIPTIONS: 'legacy-gin-subscriptions',
	LEGACY_ISSUE: 'legacy-issue',
	LEGACY_JSD_CUSTOMERS: 'legacy-jsd-customers',
	LEGACY_JSD_CUSTOMER_PERMISSIONS: 'legacy-jsd-customer-permissions',
	LEGACY_JSD_GETTING_STARTED: 'legacy-jsd-getting-started',
	LEGACY_JSD_JIRA_AUTOMATION: 'legacy-jsd-jira-automation',
	LEGACY_JSD_KNOWLEDGE: 'legacy-jsd-knowledge',
	LEGACY_JSD_QUEUES: 'legacy-jsd-queues',
	LEGACY_JSD_QUEUES_MANAGE: 'legacy-jsd-queues-manage',
	LEGACY_JSD_REPORTS: 'legacy-jsd-reports',
	LEGACY_JSD_REPORTS_CUSTOM: 'legacy-jsd-reports-custom',
	LEGACY_JSD_SETTINGS_FALLBACK: 'legacy-jsd-settings-fallback',
	LEGACY_JSD_SETTINGS_LANGUAGE: 'legacy-jsd-settings-language',
	LEGACY_JSD_SETTINGS_LANGUAGE_PAGES: 'legacy-jsd-settings-language-pages',
	LEGACY_JSD_SETTINGS_PORTAL: 'legacy-jsd-settings-portal-pages',
	LEGACY_JSD_WIDGETS: 'legacy-jsd-widgets',
	LEGACY_PIN: 'legacy-pin',
	LEGACY_PROJECT_RELEASES: 'legacy-project-releases',
	LEGACY_PROJECT_RELEASES_BAMBOO: 'legacy-project-releases-bamboo',
	LEGACY_PROJECT_RELEASES_NOTE: 'legacy-project-releases-note',
	LEGACY_PROJECT_RELEASES_NOTE_CONFIG: 'legacy-project-releases-note-config',
	LEGACY_PROJECT_SETTINGS_COLLECTORS: 'legacy-project-settings-collectors',
	LEGACY_PROJECT_SETTINGS_COLLECTORS_ADD: 'legacy-project-settings-collectors-add',
	LEGACY_PROJECT_SETTINGS_COLLECTORS_HELP: 'legacy-project-settings-collectors-help',
	LEGACY_PROJECT_SETTINGS_COLLECTORS_VIEW: 'legacy-project-settings-collectors-view',
	LEGACY_PROJECT_SETTINGS_CONFIG: 'legacy-project-settings-config',
	LEGACY_PROJECT_SETTINGS_DATA_CLASSIFICATIONS: 'project-settings-classic-data-classifications',
	LEGACY_PROJECT_SETTINGS_DELETE: 'legacy-project-settings-delete',
	LEGACY_PROJECT_SETTINGS_DEV: 'legacy-project-settings-dev',
	LEGACY_PROJECT_SETTINGS_EDIT: 'legacy-project-settings-edit',
	LEGACY_PROJECT_SETTINGS_FIELDS: 'legacy-project-settings-fields',
	LEGACY_PROJECT_SETTINGS_FIELDS_ASSOCIATE: 'legacy-project-settings-fields-associate',
	LEGACY_PROJECT_SETTINGS_ISSUE_LAYOUT: 'legacy-project-settings-issue-layout',
	LEGACY_PROJECT_SETTINGS_ISSUE_SECURITY: 'legacy-project-settings-issue-security',
	LEGACY_PROJECT_SETTINGS_ISSUE_SECURITY_ASSOCIATE:
		'legacy-project-settings-issue-security-associate',
	LEGACY_PROJECT_SETTINGS_ISSUE_TYPES: 'legacy-project-settings-issue-types',
	LEGACY_PROJECT_SETTINGS_ISSUE_TYPES_ASSOCIATE: 'legacy-project-settings-issue-types-associate',
	LEGACY_PROJECT_SETTINGS_NOTIFICATIONS: 'legacy-project-settings-notifications',
	LEGACY_PROJECT_SETTINGS_NOTIFICATIONS_ASSOCIATE:
		'legacy-project-settings-notifications-associate',
	LEGACY_PROJECT_SETTINGS_OPSGENIE: 'legacy-project-settings-opsgenie',
	LEGACY_PROJECT_SETTINGS_PEOPLE: 'legacy-project-settings-people',
	LEGACY_PROJECT_SETTINGS_PERMISSIONS: 'legacy-project-settings-permissions',
	LEGACY_PROJECT_SETTINGS_PERMISSIONS_ASSOCIATE: 'legacy-project-settings-permissions-associate',
	LEGACY_PROJECT_SETTINGS_SCREENS: 'legacy-project-settings-screens',
	LEGACY_PROJECT_SETTINGS_SCREENS_ASSOCIATE: 'legacy-project-settings-screens-associate',
	LEGACY_PROJECT_SETTINGS_SUMMARY: 'legacy-project-settings-summary',
	LEGACY_PROJECT_SETTINGS_WORKFLOWS: 'legacy-project-settings-workflows',
	LEGACY_PROJECT_SETTINGS_WORKFLOWS_ASSOCIATE: 'legacy-project-settings-workflows-associate',

	LEGACY_REDIRECT: 'legacy-redirect',
	LEGACY_REPORTS_AVERAGE_AGE: 'legacy-reports-average-age-report',
	LEGACY_REPORTS_CONFIG: 'legacy-reports-configure',
	LEGACY_REPORTS_CREATED_VS_RESOLVED: 'legacy-reports-created-vs-resolved-report',
	LEGACY_REPORTS_DEVELOPER_WORKLOAD: 'legacy-reports-developer-workload-report',
	LEGACY_REPORTS_PIE: 'legacy-reports-pie-report',
	LEGACY_REPORTS_RECENTLY_CREATED: 'legacy-reports-recently-created-report',
	LEGACY_REPORTS_RESOLUTION_TIME: 'legacy-reports-resolution-time-report',
	LEGACY_REPORTS_SINGLE_LEVEL_GROUP_BY: 'legacy-reports-single-level-group-by-report',
	LEGACY_REPORTS_TIME_SINCE: 'legacy-reports-time-since-report',
	LEGACY_REPORTS_TIME_TRACKING_REPORT: 'legacy-reports-time-tracking-report',
	LEGACY_REPORTS_VERSION_WORKLOAD: 'legacy-reports-version-workload-report',
	LEGACY_REPORTS_WORKLOAD_PIE: 'legacy-reports-workload-pie-report',

	LOGIN_EMBED: 'login-embed',

	MARKETPLACE_ADDONS_EMCEE: 'global-settings-addons-emcee',
	MARKETPLACE_ADDONS_EMCEE_PUBLIC: 'addons-emcee-public',
	MARKETPLACE_APP_REQUESTS: 'marketplace-app-requests',
	MARKETPLACE_INDEX: 'marketplace-index',
	MARKETPLACE_MANAGE_APPS: 'global-settings-addons-upm',
	MARKETPLACE_PROMOTIONS: 'marketplace-promotions',

	NOTIFICATION_LOGS: 'notification-logs',

	OPSGENIE_SCHEDULE: 'opsgenie-schedule',
	PEOPLE_INDEX: 'people-index',

	// legacy personal settings route
	PERSONAL_SETTINGS: 'personal-settings',
	// new personal settings route
	PERSONAL_SETTINGS_GENERAL: 'personal-settings-general',
	PERSONAL_SETTINGS_NOTIFICATIONS: 'personal-settings-notifications',
	PERSONAL_SETTINGS_OPS_NOTIFICATIONS: 'personal-settings-ops-notifications',

	PLANS_DIRECTORY: 'plans-directory',

	PLAYBOOKS_EDIT: 'project-settings-playbooks-edit',
	PLAYBOOKS_LIST: 'project-settings-playbooks-list',
	PLAYBOOKS_LOGS: 'project-settings-playbooks-logs',

	POLARIS_COLLECTIONS: 'polaris-collections',
	POLARIS_IDEAS: 'polaris-ideas',
	POLARIS_INSIGHTS: 'polaris-insights',
	POLARIS_ONBOARDING: 'polaris-onboarding',
	POLARIS_PLAYS: 'polaris-plays',
	POLARIS_ROOMS: 'polaris-rooms',
	POLARIS_SETTINGS_ACCESS: 'polaris-settings-access',
	POLARIS_SETTINGS_GLOBAL_FIELDS: 'polaris-settings-global-fields',
	POLARIS_UNLICENSED: 'polaris-unlicensed',

	PRODUCTS_JSM_AUTHENTICATION_SETTINGS: 'products-jsm-authentication-settings',
	PRODUCTS_JSM_CHATOPS_AND_VIDEOS_TOOLS: 'products-jsm-chatops-and-videos-tools',
	PRODUCTS_JSM_COMPLIANCE: 'products-jsm-compliance',
	PRODUCTS_JSM_CONFIGURATION: 'products-jsm-configuration',
	PRODUCTS_JSM_CUSTOMER_ACCESS: 'products-jsm-customer-access',
	PRODUCTS_JSM_EMAIL_REQUESTS: 'products-jsm-email-requests',
	PRODUCTS_JSM_FEATURE_USAGE: 'products-jsm-feature-usage',
	PRODUCTS_JSM_GLOBAL_OPS_REPORTS: 'products-jsm-global-ops-reports',
	PRODUCTS_JSM_INCIDENT_MANAGEMENT_TOOLS: 'products-jsm-incident-management-tools',
	PRODUCTS_JSM_KB_PERMISSIONS: 'products-jsm-kb-permissions',
	PRODUCTS_JSM_ORGANIZATIONS: 'products-jsm-organizations',
	PRODUCTS_JSW_CONFIGURATION: 'products-jsw-configuration',
	PRODUCTS_JSW_STORAGE_MANAGEMENT: 'products-jsw-storage-management',

	PROFORMA_EXPORT: 'proforma-export',
	PROFORMA_ISSUE_FORMS_DIRECT: 'proforma-issue-forms-direct',
	PROFORMA_ISSUE_FORMS_DIRECT_EMBED: 'proforma-issue-forms-direct-embed',
	PROFORMA_ISSUE_FORMS_GENERAL: 'proforma-issue-forms-general',

	PROJECTS_DIRECTORY: 'projects-directory',
	PROJECTS_DIRECTORY_LEGACY_PATH_SPA: 'projects-directory-legacy-path-spa',
	PROJECTS_DIRECTORY_MANAGE: 'projects-directory-manage',
	PROJECTS_REDIRECT: 'projects-redirect',
	PROJECT_COMPONENTS: 'project-components',
	PROJECT_DIRECTORY_REDIRECT: 'project-directory-redirect',
	PROJECT_ISSUE_NAVIGATOR: 'issue-navigator',
	PROJECT_ISSUE_NAVIGATOR_CLASSIC: 'issue-navigator-classic',
	PROJECT_ISSUE_NAVIGATOR_CORE: 'issue-navigator-core',
	PROJECT_PAGES: 'project-pages',

	PROJECT_SETTINGS_AUTOMATION: 'project-settings-automation',
	PROJECT_SETTINGS_COMPONENTS: 'project-settings-components',

	// business project settings routes
	PROJECT_SETTINGS_CORE_ACCESS: `${ROUTE_GROUPS.PROJECT_SETTINGS_CORE}-access`,
	PROJECT_SETTINGS_CORE_ACCESS_REDIRECT: `${ROUTE_GROUPS.PROJECT_SETTINGS_CORE}-access-redirect`,
	PROJECT_SETTINGS_CORE_APPROVALS: `${ROUTE_GROUPS.PROJECT_SETTINGS_CORE}-approvals`,
	PROJECT_SETTINGS_CORE_APPS: `${ROUTE_GROUPS.PROJECT_SETTINGS_CORE}-apps`,
	PROJECT_SETTINGS_CORE_APPS_FIELDS: `${ROUTE_GROUPS.PROJECT_SETTINGS_CORE}-apps-fields`,
	PROJECT_SETTINGS_CORE_AUTOMATION: `${ROUTE_GROUPS.PROJECT_SETTINGS_CORE}-automation`,
	PROJECT_SETTINGS_CORE_DATA_CLASSIFICATIONS: `${ROUTE_GROUPS.PROJECT_SETTINGS_CORE}-data-classifications`,
	PROJECT_SETTINGS_CORE_DEFAULT_REDIRECT: `${ROUTE_GROUPS.PROJECT_SETTINGS_CORE}-redirect`,
	PROJECT_SETTINGS_CORE_DETAILS: `${ROUTE_GROUPS.PROJECT_SETTINGS_CORE}-details`,
	PROJECT_SETTINGS_CORE_FIELDS: `${ROUTE_GROUPS.PROJECT_SETTINGS_CORE}-fields`,
	PROJECT_SETTINGS_CORE_FORGE_APPS: `${ROUTE_GROUPS.PROJECT_SETTINGS_CORE}-forge-apps`,
	PROJECT_SETTINGS_CORE_ISSUE_TYPES: `${ROUTE_GROUPS.PROJECT_SETTINGS_CORE}-issuetypes`,
	PROJECT_SETTINGS_CORE_ISSUE_TYPES_WORKFLOW: `${ROUTE_GROUPS.PROJECT_SETTINGS_CORE}-issuetypes-workflow`,
	PROJECT_SETTINGS_CORE_NOTIFICATIONS: `${ROUTE_GROUPS.PROJECT_SETTINGS_CORE}-notifications`,
	PROJECT_SETTINGS_CORE_NOTIFICATIONS_ISSUE_LOGS: `${ROUTE_GROUPS.PROJECT_SETTINGS_CORE}-notifications-issue-logs`,
	PROJECT_SETTINGS_CORE_NOTIFICATION_EMAIL: `${ROUTE_GROUPS.PROJECT_SETTINGS_CORE}-notifications-notification-email`,
	PROJECT_SETTINGS_CORE_PROFORMA_JIRA_FORM_BUILDER: `${ROUTE_GROUPS.PROJECT_SETTINGS_CORE}-proforma-jira-form-builder`,
	PROJECT_SETTINGS_CORE_PROFORMA_PROJECT_FORMS: `${ROUTE_GROUPS.PROJECT_SETTINGS_CORE}-proforma-project-forms`,
	// end of business project settings

	PROJECT_SETTINGS_FORGE_APPS: `${ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE}-forge-apps`,
	// the following name format is required for the correct next-gen App sidebar rendering
	PROJECT_SETTINGS_FORGE_APPS_NEXTGEN: `${ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE}-apps-forge`,

	// JSM project setings routes
	PROJECT_SETTINGS_SERVICEDESK_APPS_FIELDS: `${ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK}-apps-fields`,
	PROJECT_SETTINGS_SERVICEDESK_CHAT: `${ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK}-chat`,
	PROJECT_SETTINGS_SERVICEDESK_CLASSIC_CUSTOMER_NOTIFICATIONS: `${ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK}-customer-notifications-classic`,
	PROJECT_SETTINGS_SERVICEDESK_CLASSIC_EMAIL_SETTINGS: `${ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK}-email-classic`,
	PROJECT_SETTINGS_SERVICEDESK_DETAILS: `${ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK}-details`,
	PROJECT_SETTINGS_SERVICEDESK_FORGE_APPS: `${ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK}-forge-apps`,
	PROJECT_SETTINGS_SERVICEDESK_ITSM_REQUEST_TYPES: `${ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK}-itsm-ticket-types-config`,
	PROJECT_SETTINGS_SERVICEDESK_ITSM_REQUEST_TYPES_FIELDS: `${ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK}-itsm-ticket-type-fields`,
	PROJECT_SETTINGS_SERVICEDESK_NOTIFICATIONS: `${ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK}-notifications`,
	PROJECT_SETTINGS_SERVICEDESK_NOTIFICATIONS_NOTIFICATION_EMAIL: `${ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK}-notifications-notification-email`,
	PROJECT_SETTINGS_SERVICEDESK_PORTAL_SETTINGS: `${ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK}-portal-settings`,
	PROJECT_SETTINGS_SERVICEDESK_REQUEST_TYPES: `${ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK}-ticket-types-config`,
	PROJECT_SETTINGS_SERVICEDESK_REQUEST_TYPES_FIELDS: `${ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK}-ticket-type-fields`,
	PROJECT_SETTINGS_SERVICEDESK_UNASSIGNED_REQUEST_TYPES: `${ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK}-unassigned-ticket-types-config`,
	PROJECT_SETTINGS_SERVICEDESK_UNASSIGNED_REQUEST_TYPES_FIELDS: `${ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK}-unassigned-ticket-type-fields`,
	// end of JSM project settings

	PROJECT_SETTINGS_SOFTWARE_ACCESS: 'project-settings-software-access',
	PROJECT_SETTINGS_SOFTWARE_APPS_FIELDS: `${ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE}-apps-fields`,
	PROJECT_SETTINGS_SOFTWARE_AUTOMATION: 'project-settings-software-automation',
	PROJECT_SETTINGS_SOFTWARE_BOARDS_CARD: 'project-settings-software-boards-card',
	PROJECT_SETTINGS_SOFTWARE_BOARDS_CSM: 'project-settings-software-boards-csm',
	PROJECT_SETTINGS_SOFTWARE_BOARDS_CUSTOM_FILTERS:
		'project-settings-software-boards-custom-filters',
	PROJECT_SETTINGS_SOFTWARE_BOARDS_DETAILS: 'project-settings-software-boards-details',
	PROJECT_SETTINGS_SOFTWARE_BOARDS_FEATURES: 'project-settings-software-boards-features',
	PROJECT_SETTINGS_SOFTWARE_BOARDS_INSIGHTS: 'project-settings-software-boards-insights',
	PROJECT_SETTINGS_SOFTWARE_BOARDS_REDIRECT: 'project-settings-software-boards-redirect',
	PROJECT_SETTINGS_SOFTWARE_BOARDS_ROADMAP: 'project-settings-software-boards-roadmap',
	PROJECT_SETTINGS_SOFTWARE_BOARDS_SWIMLANES: 'project-settings-software-boards-swimlanes',
	PROJECT_SETTINGS_SOFTWARE_BOARDS_TIMELINE: 'project-settings-software-boards-timeline',
	PROJECT_SETTINGS_SOFTWARE_DATA_CLASSIFICATIONS: 'project-settings-software-data-classifications',
	PROJECT_SETTINGS_SOFTWARE_DETAILS: 'project-settings-software-details',
	PROJECT_SETTINGS_SOFTWARE_FEATURES: 'project-settings-software-next-gen-features',
	PROJECT_SETTINGS_SOFTWARE_ISSUE_TYPES: 'project-settings-software-issuetypes',
	PROJECT_SETTINGS_SOFTWARE_NOTIFICATIONS: `${ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE}-notifications`,
	PROJECT_SETTINGS_SOFTWARE_NOTIFICATIONS_ISSUE_LOGS: `${ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE}-notifications-issue-logs`,
	PROJECT_SETTINGS_SOFTWARE_NOTIFICATION_EMAIL:
		'project-settings-software-notifications-notification-email',
	PROJECT_SETTINGS_SOFTWARE_OPSGENIE: `${ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE}-link-opsgenie`,
	PROJECT_SETTINGS_TOOLCHAIN: `${ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE}-toolchain-team-managed`,
	PROJECT_SETTINGS_VERSIONS: 'project-settings-versions',

	PROJECT_VERSIONS: 'project-versions',
	RAPIDBOARD: 'rapidboard',
	RAPIDBOARD_BACKLOG: 'rapidboard-backlog',
	RAPIDBOARD_BOARD: 'rapidboard-board',
	RAPIDBOARD_BOARD_PRETTY_URL_REDIRECT: 'rapidboard-board-pretty-url-redirect',
	RAPIDBOARD_LAST_VISITED_PROJECT_REDIRECT: 'rapidboard-last-visited-project-redirect',
	RAPIDBOARD_REPORT: 'rapidboard-report',
	RAPIDBOARD_UIF_BACKLOG: 'rapidboard-uif-backlog',
	RAPIDBOARD_USER_BACKLOG: 'rapidboard-user-backlog',
	RAPIDBOARD_USER_BOARD: 'rapidboard-user-board',
	RAPIDBOARD_USER_REPORT: 'rapidboard-user-report',
	SERVICEDESK_ATLASSIAN_ANALYTICS: 'servicedesk-atlassian-analytics',
	SERVICEDESK_BOARDS: 'servicedesk-boards',
	SERVICEDESK_CALENDAR: 'servicedesk-calendar',
	SERVICEDESK_CALENDAR_EAP: 'servicedesk-calendar-eap',
	SERVICEDESK_CHANGE_CALENDAR: 'servicedesk-change-calendar',
	SERVICEDESK_CMDB_ASSETS_CONFIGURE: 'servicedesk-cmdb-configure',
	SERVICEDESK_CMDB_ASSETS_OBJECT: 'servicedesk-cmdb-object',
	SERVICEDESK_CMDB_ASSETS_OBJECT_SCHEMA: 'servicedesk-cmdb-object-schema',
	SERVICEDESK_CMDB_ASSETS_OBJECT_SCHEMA_CONFIGURATION:
		'servicedesk-cmdb-object-schema-configuration',
	SERVICEDESK_CMDB_ASSETS_OBJECT_TYPE_CONFIGURATION: 'servicedesk-cmdb-object-type-configuration',
	SERVICEDESK_CMDB_ASSETS_REPORTS: 'servicedesk-cmdb-assets-reports',
	SERVICEDESK_CMDB_ASSETS_REPORTS_DEFAULT_REDIRECT:
		'servicedesk-cmdb-assets-reports-default-redirect',
	SERVICEDESK_CMDB_ASSETS_REPORTS_UNSUBSCRIBE: 'servicedesk-cmdb-assets-reports-unsubscribe',
	SERVICEDESK_CMDB_INSIGHT: 'servicedesk-cmdb-manage-object-schemas',
	SERVICEDESK_CMDB_INSIGHT_CONFIGURE: 'servicedesk-cmdb-configure',
	SERVICEDESK_CMDB_INSIGHT_LANDING_PAGE: 'servicedesk-cmdb-landing-page',
	SERVICEDESK_CMDB_INSIGHT_OBJECT: 'servicedesk-cmdb-object',
	SERVICEDESK_CMDB_INSIGHT_OBJECT_SCHEMA: 'servicedesk-cmdb-object-schema',
	SERVICEDESK_CMDB_INSIGHT_OBJECT_SCHEMA_CONFIGURATION:
		'servicedesk-cmdb-object-schema-configuration',
	SERVICEDESK_CMDB_INSIGHT_OBJECT_TYPE_CONFIGURATION: 'servicedesk-cmdb-object-type-configuration',
	SERVICEDESK_CONNECT_PROJECT: 'servicedesk-connect-project',
	SERVICEDESK_CREATE_PROJECT: 'servicedesk-create-project',
	SERVICEDESK_CUSTOMERS_V2: 'servicedesk-customers-v2',
	SERVICEDESK_CUSTOMER_DETAILS: 'servicedesk-customer-details',
	SERVICEDESK_CUSTOMER_SERVICE_ESCALATIONS: 'servicedesk-customer-service-escalations',
	SERVICEDESK_CUSTOMER_SERVICE_PRODUCTS: 'servicedesk-customer-service-products',
	SERVICEDESK_EARLY_ACCESS_ITSM: 'servicedesk-early-access-itsm',
	SERVICEDESK_GETTING_STARTED_PAGE: 'servicedesk-getting-started-page',
	SERVICEDESK_INCIDENTS: 'servicedesk-incidents',
	SERVICEDESK_INDEX: 'servicedesk-index',
	SERVICEDESK_ISSUE: 'servicedesk-issue',
	SERVICEDESK_ISSUES: 'servicedesk-issues',
	SERVICEDESK_ITSM_SAMPLE_SPACE: 'servicedesk-itsm-sample-space',
	SERVICEDESK_JIRA_AUTOMATION: 'servicedesk-automation',
	SERVICEDESK_KB_REQUESTS_REPORTS: 'servicedesk-kb-requests-reports',
	SERVICEDESK_KB_REQUESTS_REPORTS_ISSUE: 'servicedesk-kb-requests-reports-time-scale-issue',
	SERVICEDESK_KB_REQUESTS_REPORTS_TIME_SCALE: 'servicedesk-kb-requests-reports-time-scale',
	SERVICEDESK_ONCALL_SCHEDULE: 'servicedesk-oncall-schedule',
	SERVICEDESK_ORGANIZATIONS: 'servicedesk-organizations',
	SERVICEDESK_ORGANIZATION_DETAILS: 'servicedesk-organization-details',
	SERVICEDESK_ORGANIZATION_V2: 'servicedesk-organization_v2',
	SERVICEDESK_PRACTICE_CUSTOM_AND_ISSUE: 'servicedesk-practice-custom-and-issue',
	SERVICEDESK_PRACTICE_EDIT: 'servicedesk-practice-edit',
	SERVICEDESK_PRACTICE_ISSUE: 'servicedesk-practice-issue',
	SERVICEDESK_PRACTICE_LANDING: 'servicedesk-practice-landing',
	SERVICEDESK_PRACTICE_MANAGE_VIEW: 'servicedesk-practice-manage-view',
	SERVICEDESK_PRACTICE_NEW: 'servicedesk-practice-new',
	SERVICEDESK_PROJECT_CONVERSION: 'servicedesk-project-conversion',
	SERVICEDESK_QUEUES_ADDON: 'servicedesk-queues-addon',
	SERVICEDESK_QUEUES_CUSTOM_AND_ISSUE: 'servicedesk-queues-custom-and-issue',
	SERVICEDESK_QUEUES_EDIT: 'servicedesk-queues-edit',
	SERVICEDESK_QUEUES_ISSUE: 'servicedesk-queues-issue',
	SERVICEDESK_QUEUES_LANDING: 'servicedesk-queues-landing',
	SERVICEDESK_QUEUES_MANAGE_VIEW: 'servicedesk-queues-manage-view',
	SERVICEDESK_QUEUES_NEW: 'servicedesk-queues-new',
	SERVICEDESK_REDIRECT: 'servicedesk-redirect',
	SERVICEDESK_REPORTS: 'servicedesk-reports',
	SERVICEDESK_REPORTS_CUSTOM: 'servicedesk-reports-custom',
	SERVICEDESK_REPORTS_CUSTOM_CREATE: 'servicedesk-reports-custom-create',
	SERVICEDESK_REPORTS_CUSTOM_EDIT: 'servicedesk-reports-custom-edit',
	SERVICEDESK_REPORTS_CUSTOM_ISSUE: 'servicedesk-reports-custom-time-scale-issue',
	SERVICEDESK_REPORTS_CUSTOM_TIME_SCALE: 'servicedesk-reports-custom-time-scale',
	SERVICEDESK_REPORTS_SATISFACTION: 'servicedesk-reports-satisfaction',
	SERVICEDESK_REPORTS_SATISFACTION_ISSUE: 'servicedesk-reports-satisfaction-time-scale-issue',
	SERVICEDESK_REPORTS_SATISFACTION_TIME_SCALE: 'servicedesk-reports-satisfaction-time-scale',
	SERVICEDESK_REPORTS_SUMMARY: 'servicedesk-reports-summary',
	SERVICEDESK_REPORTS_TYPE: 'servicedesk-reports-type',
	SERVICEDESK_REPORTS_WORKLOAD: 'servicedesk-reports-workload',
	SERVICEDESK_SERVICES: 'servicedesk-services',
	SERVICEDESK_SERVICES_NEW: 'servicedesk-services-new',
	SERVICEDESK_SERVICES_WITHOUT_PROJECT: 'servicedesk-services-without-project',
	SERVICEDESK_SERVICE_DETAILS: 'servicedesk-service-details',
	SERVICEDESK_SERVICE_DETAILS_NEW: 'servicedesk-service-details-new',
	SERVICEDESK_SERVICE_DETAILS_WITHOUT_PROJECT: 'servicedesk-service-details-without-project',
	SERVICEDESK_SERVICE_MODAL_EDIT: 'servicedesk-service-modal-edit',
	SERVICEDESK_SERVICE_MODAL_NEW: 'servicedesk-service-modal-new',
	SERVICEDESK_SLA_SETTINGS: 'servicedesk-sla-settings',
	SERVICE_HUB_SERVICES: 'service-hub-services',
	SERVICE_HUB_SERVICE_DETAILS: 'service-hub-service-details',
	SETTINGS_AUTOMATION: 'global-automation',
	SETTINGS_CMDB_FIELD_CONFIG: 'global-settings-issues-cmdb-field-config',
	SETTINGS_OAUTH_CREDENTIALS: 'admin-oauth-credentials',
	SETTINGS_RECOMMENDATIONS: 'recommendations',
	SETTINGS_VIEW_CUSTOM_FIELDS: 'view-custom-fields',
	SOFTWARE_ADVANCED_ROADMAPS: 'software-advanced-roadmaps',
	SOFTWARE_ADVANCED_ROADMAPS_GETTING_STARTED_REDIRECT:
		'software-advanced-roadmaps-getting-started-redirect',
	SOFTWARE_ADVANCED_ROADMAPS_REDIRECT: 'software-advanced-roadmaps-redirect',
	SOFTWARE_ADVANCED_ROADMAPS_SETUP: 'software-advanced-roadmaps-setup',
	SOFTWARE_ARJ_SETTINGS_DEPENDENCIES: `${ROUTE_GROUPS.ARJ_SETTINGS}-dependencies`,
	SOFTWARE_ARJ_SETTINGS_DEPENDENCIES_REDIRECT: `${ROUTE_GROUPS.ARJ_SETTINGS}-dependencies-redirect`,
	SOFTWARE_ARJ_SETTINGS_FINANCIAL_YEAR: `${ROUTE_GROUPS.ARJ_SETTINGS}-financial-year`,
	SOFTWARE_ARJ_SETTINGS_LOADING_LIMITS: `${ROUTE_GROUPS.ARJ_SETTINGS}-loading-limits`,
	SOFTWARE_ARJ_SETTINGS_LOADING_LIMITS_REDIRECT: `${ROUTE_GROUPS.ARJ_SETTINGS}-loading-limits-redirect`,
	SOFTWARE_ARJ_SETTINGS_PERMISSIONS: `${ROUTE_GROUPS.ARJ_SETTINGS}-permissions`,
	SOFTWARE_ARJ_SETTINGS_PERMISSIONS_REDIRECT: `${ROUTE_GROUPS.ARJ_SETTINGS}-permissions-redirect`,
	SOFTWARE_BACKLOG: 'software-backlog',
	SOFTWARE_BOARDS: 'software-boards',
	SOFTWARE_CALENDAR: 'software-calendar',
	SOFTWARE_CALENDAR_CLASSIC: 'software-calendar-classic',
	SOFTWARE_CALENDAR_CLASSIC_EMBED: 'software-calendar-classic-embed',
	SOFTWARE_CALENDAR_EMBED: 'software-calendar-embed',
	SOFTWARE_CLASSIC_PROFORMA_ISSUE_FORMS_DIRECT: `${ROUTE_GROUPS.CLASSIC_SOFTWARE}-proforma-issue-forms-direct`,
	SOFTWARE_CLASSIC_PROFORMA_ISSUE_FORMS_DIRECT_EMBED: `${ROUTE_GROUPS.CLASSIC_SOFTWARE}-proforma-issue-forms-direct-embed`,
	SOFTWARE_CODE: 'software-code',
	SOFTWARE_CODE_CLASSIC: 'software-code-classic',
	SOFTWARE_DEPLOYMENTS: 'software-deployments',
	SOFTWARE_DEPLOYMENTS_CLASSIC: 'software-deployments-classic',
	SOFTWARE_GOALS: 'software-goals',
	SOFTWARE_GOALS_CLASSIC: 'software-goals-classic',
	SOFTWARE_INCIDENTS: 'software-incidents',
	SOFTWARE_INCIDENTS_CLASSIC: 'software-incidents-classic',
	SOFTWARE_ISSUE_NAVIGATOR: 'software-issue-navigator',
	SOFTWARE_LIST: 'software-list',
	SOFTWARE_LIST_CLASSIC: 'software-list-classic',
	SOFTWARE_LIST_CLASSIC_EMBED: 'software-list-classic-embed',
	SOFTWARE_LIST_EMBED: 'software-list-embed',
	SOFTWARE_NEXTGEN_REDIRECT: 'software-nextgen-redirect',
	SOFTWARE_ONBOARDING: 'software-onboarding',
	SOFTWARE_PERISCOPE: 'software-periscope',
	SOFTWARE_PERISCOPE_CLASSIC: 'software-periscope-classic',
	SOFTWARE_PLAN_CALENDAR: 'software-plan-calendar',
	SOFTWARE_PLAN_DEPENDENCIES: 'software-plan-dependencies',
	SOFTWARE_PLAN_INCREMENT: 'software-plan-increment',
	SOFTWARE_PLAN_INCREMENTS: 'software-plan-increments',
	SOFTWARE_PLAN_RELEASES: 'software-plan-releases',
	SOFTWARE_PLAN_SETTINGS_AUTO_SCHEDULER: `${ROUTE_GROUPS.PLAN_SETTINGS}-auto-scheduler`,
	SOFTWARE_PLAN_SETTINGS_CUSTOM_FIELDS: `${ROUTE_GROUPS.PLAN_SETTINGS}-custom-fields`,
	SOFTWARE_PLAN_SETTINGS_EXCLUSION_RULES: `${ROUTE_GROUPS.PLAN_SETTINGS}-exclusion-rules`,
	SOFTWARE_PLAN_SETTINGS_FIND_YOUR_ISSUE: `${ROUTE_GROUPS.PLAN_SETTINGS}-find-your-issue`,
	SOFTWARE_PLAN_SETTINGS_ISSUE_SOURCES: `${ROUTE_GROUPS.PLAN_SETTINGS}-issue-sources`,
	SOFTWARE_PLAN_SETTINGS_PERMISSIONS: `${ROUTE_GROUPS.PLAN_SETTINGS}-permissions`,
	SOFTWARE_PLAN_SETTINGS_PLAN_INFO: `${ROUTE_GROUPS.PLAN_SETTINGS}-plan-info`,
	SOFTWARE_PLAN_SETTINGS_PROGRAM_BOARDS: `${ROUTE_GROUPS.PLAN_SETTINGS}-program-boards`,
	SOFTWARE_PLAN_SETTINGS_REMOVED_ISSUES: `${ROUTE_GROUPS.PLAN_SETTINGS}-removed-issues`,
	SOFTWARE_PLAN_SETTINGS_SAVED_VIEWS: `${ROUTE_GROUPS.PLAN_SETTINGS}-saved-views`,
	SOFTWARE_PLAN_SETTINGS_SCENARIOS: `${ROUTE_GROUPS.PLAN_SETTINGS}-scenarios`,
	SOFTWARE_PLAN_SETTINGS_SCHEDULING: `${ROUTE_GROUPS.PLAN_SETTINGS}-scheduling`,
	SOFTWARE_PLAN_SUMMARY: 'software-plan-summary',
	SOFTWARE_PLAN_TEAMS: 'software-plan-teams',
	SOFTWARE_PLAN_TIMELINE: 'software-plan-timeline',
	SOFTWARE_PRESENTATION_TOOLKIT_REPORT: 'software-presentation-toolkit-report',
	SOFTWARE_RELEASE_NOTES: 'software-release-notes',
	SOFTWARE_REPORTS: 'software-reports',
	SOFTWARE_REPORTS_BURNDOWN: 'software-reports-burndown',
	SOFTWARE_REPORTS_BURNUP: 'software-reports-burnup',
	SOFTWARE_REPORTS_CUMULATIVE: 'software-reports-cumulative',
	SOFTWARE_REPORTS_CYCLE_TIME: 'software-reports-cycle-time',
	SOFTWARE_REPORTS_CYCLE_TIME_CLASSIC: 'software-reports-cycle-time-classic',
	SOFTWARE_REPORTS_DEPLOYMENT_FREQUENCY: 'software-reports-deployment-frequency',
	SOFTWARE_REPORTS_DEPLOYMENT_FREQUENCY_CLASSIC: 'software-reports-deployment-frequency-classic',
	SOFTWARE_REPORTS_VELOCITY: 'software-reports-velocity',
	SOFTWARE_REQUESTS: 'software-requests',
	SOFTWARE_REQUESTS_CLASSIC: 'software-requests-classic',
	SOFTWARE_ROADMAP: 'software-roadmap',
	SOFTWARE_ROADMAP_CLASSIC: 'software-roadmap-classic',
	SOFTWARE_ROADMAP_REDIRECT: 'software-roadmap-redirect',
	SOFTWARE_SECURITY: 'software-security',
	SOFTWARE_SECURITY_CLASSIC: 'software-security-classic',
	SOFTWARE_TIMELINE: 'software-timeline',
	SOFTWARE_TIMELINE_CLASSIC: 'software-timeline-classic',
	SOFTWARE_VERSION_DETAIL: 'software-version-detail',
	SOFTWARE_VERSION_DETAIL_LEGACY_REDIRECT: 'software-version-detail-legacy-redirect',
	SOFTWARE_VERSION_DETAIL_NO_TAB_PATH: 'software-version-detail-no-tab-path',
	SYSTEM_CONFIGURATION: 'system-configuration-general',
	SYSTEM_DASHBOARD_EDIT: 'system-dashboard-edit',
	TRASHED_PLAN_DIRECT_LINK: 'trashed-plan-direct-link',
	TRASH_PLANS_DIRECTORY: 'trash-plans-directory',
	TRASH_PROJECTS_DIRECTORY: 'trash-projects-directory',
	USER_SEGMENTATION: 'user-segmentation',
	YOUR_WORK: 'your-work',
} as const;

export type RouteNames = (typeof ROUTE_NAMES)[keyof typeof ROUTE_NAMES];

export const JWM_OVERVIEWS_ROUTE_SET = new Set<string>([
	ROUTE_NAMES.CLASSIC_BUSINESS_OVERVIEW_SUMMARY,
	ROUTE_NAMES.CLASSIC_BUSINESS_OVERVIEW_CALENDAR,
	ROUTE_NAMES.CLASSIC_BUSINESS_OVERVIEW_TIMELINE,
	ROUTE_NAMES.CLASSIC_BUSINESS_OVERVIEW_REDIRECT,
]);
export const CONNECT_ROUTES_SET = new Set<string>([
	ROUTE_NAMES.CONNECT_PROJECT,
	ROUTE_NAMES.CONNECT_GENERAL,
	ROUTE_NAMES.CONNECT_GENERAL_SERVICE_DESK_PROJECT,
	ROUTE_NAMES.LEGACY_CONNECT_PROJECT,
	ROUTE_NAMES.LEGACY_CONNECT_GENERAL,
]);
