import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalLayout } from '@atlassian/jira-global-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import { createEntry } from '@atlassian/jira-route-entry';
import { filtersDirectoryResource } from '@atlassian/jira-router-resources-directories-filters';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import {
	filtersDirectoryRoute,
	filtersDirectoryRedirectRoute,
	secureFiltersDirectoryRedirectRoute,
} from '@atlassian/jira-router-routes-directories-routes';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect';
import { FiltersDirectorySkeleton } from '@atlassian/jira-skeletons';
import type FiltersDirectoryType from '@atlassian/jira-spa-apps-directories-filters';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const FiltersDirectoryRedirect = () => <ScreenRedirect to="/jira/filters" />;

const LazyFiltersDirectory = lazyForPaint<typeof FiltersDirectoryType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-filters-directory", jiraSpaEntry: "async-filters-directory" */ '@atlassian/jira-spa-apps-directories-filters'
		),
	),
);

export const FiltersDirectoryComponent = () => (
	<LazyPage Page={LazyFiltersDirectory} pageId="filters-directory" shouldShowSpinner={false} />
);

export const filtersDirectoryRouteEntry = createEntry(filtersDirectoryRoute, {
	resources: [...getNavigationResources(), filtersDirectoryResource],
	component: FiltersDirectoryComponent,
	skeleton: FiltersDirectorySkeleton,
	layout: globalLayout,

	navigation: {
		menuId: MENU_ID.FILTERS,
	},

	forPaint: [LazyAtlassianNavigation, LazyFiltersDirectory],
});

export const filtersDirectoryRedirectRouteEntry = createEntry(filtersDirectoryRedirectRoute, {
	resources: [...getNavigationResources(), filtersDirectoryResource],
	component: !__SERVER__ ? FiltersDirectoryRedirect : FiltersDirectoryComponent,
	skeleton: FiltersDirectorySkeleton,
	layout: globalLayout,

	navigation: {
		menuId: MENU_ID.FILTERS,
	},

	forPaint: [LazyAtlassianNavigation, LazyFiltersDirectory],
	isRedirect: true,
});

export const secureFiltersDirectoryRedirectRouteEntry = createEntry(
	secureFiltersDirectoryRedirectRoute,
	{
		resources: [...getNavigationResources(), filtersDirectoryResource],
		component: !__SERVER__ ? FiltersDirectoryRedirect : FiltersDirectoryComponent,
		skeleton: FiltersDirectorySkeleton,
		layout: globalLayout,

		navigation: {
			menuId: MENU_ID.FILTERS,
		},

		forPaint: [LazyAtlassianNavigation, LazyFiltersDirectory],
		isRedirect: true,
	},
);
