import { toEventId } from '@atlassian/jira-forge-ui-types';
import type { Source } from '@atlassian/jira-forge-ui-types/src/common/types';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import { fireInitializationEvent } from '../../common/utils/consumers';

const INITIALIZATION_ACTIONS = {
	STARTED: 'started',
	FINISHED: 'finished',
	FAILED: 'failed',
} as const;

export const issueViewInitializationId = toEventId('issueViewInitialization');
export const fetchModulesInitializationId = toEventId('fetchModulesInitialization');

export const fireInitializationFetchFinishedEvent = (source?: Source, attributes?: Attributes) =>
	fireInitializationEvent(INITIALIZATION_ACTIONS.FINISHED, {
		id: fetchModulesInitializationId,
		source,
		attributes,
	});

export const fireInitializationFetchFailedEvent = (source?: Source, attributes?: Attributes) =>
	fireInitializationEvent(INITIALIZATION_ACTIONS.FAILED, {
		id: fetchModulesInitializationId,
		source,
		attributes,
	});

export const fireInitializationIssueViewFinishedEvent = (
	source?: Source,
	attributes?: Attributes,
) =>
	fireInitializationEvent(INITIALIZATION_ACTIONS.FINISHED, {
		id: issueViewInitializationId,
		source,
		attributes,
	});

export const fireInitializationIssueViewFailedEvent = (source?: Source, attributes?: Attributes) =>
	fireInitializationEvent(INITIALIZATION_ACTIONS.FAILED, {
		id: issueViewInitializationId,
		source,
		attributes,
	});
