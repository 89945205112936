/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, {
	createContext,
	type Dispatch,
	type SetStateAction,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';

import { jsx } from '@compiled/react';
import { bind } from 'bind-event-listener';

import { IconButton } from '@atlaskit/button/new';
import { cssMap } from '@atlaskit/css';
import __noop from '@atlaskit/ds-lib/noop';
import SidebarCollapseIcon from '@atlaskit/icon/core/sidebar-collapse';
import SidebarExpandIcon from '@atlaskit/icon/core/sidebar-expand';
import { token } from '@atlaskit/tokens';

import { InteractionConsumer } from '../../components/interaction-surface';

const SideNavDesktopVisibility = createContext<boolean | null>(null);
const SideNavMobileVisibility = createContext<boolean>(false);
/**
 * Sets the visibility of the side nav on desktop screens.
 * Internally exporting for use in tests.
 */
export const SetSideNavDesktopVisibility =
	createContext<Dispatch<SetStateAction<boolean | null>>>(__noop);
/**
 * Sets the visibility of the side nav for mobile screens
 * Internally exporting for use in tests.
 */
export const SetSideNavMobileVisibility = createContext<Dispatch<SetStateAction<boolean>>>(__noop);

/**
 * Manages the side nav visibility state and provides the context.
 */
export const SideNavVisibilityProvider = ({ children }: { children: React.ReactNode }) => {
	const [visibleOnDesktop, setVisibleOnDesktop] = useState<boolean | null>(null);
	const [visibleOnMobile, setVisibleOnMobile] = useState(false);

	return (
		<SideNavDesktopVisibility.Provider value={visibleOnDesktop}>
			<SetSideNavDesktopVisibility.Provider value={setVisibleOnDesktop}>
				<SideNavMobileVisibility.Provider value={visibleOnMobile}>
					<SetSideNavMobileVisibility.Provider value={setVisibleOnMobile}>
						{children}
					</SetSideNavMobileVisibility.Provider>
				</SideNavMobileVisibility.Provider>
			</SetSideNavDesktopVisibility.Provider>
		</SideNavDesktopVisibility.Provider>
	);
};

/**
 * __useToggleSideNav__
 *
 * Returns a function to toggle the side nav visibility.
 *
 * It will toggle the appropriate internal desktop or mobile side nav visibility state based on the current screen size.
 */
export const useToggleSideNav = () => {
	const setVisibleOnDesktop = useContext(SetSideNavDesktopVisibility);
	const setVisibleOnMobile = useContext(SetSideNavMobileVisibility);

	const toggleSideNav = useCallback(() => {
		const { matches } = window.matchMedia('(min-width: 64rem)');
		if (matches) {
			setVisibleOnDesktop((prev) => !prev);
		} else {
			setVisibleOnMobile((prev) => !prev);
		}
	}, [setVisibleOnDesktop, setVisibleOnMobile]);

	return toggleSideNav;
};

export const useSideNavVisibility = ({
	defaultCollapsed = false,
}: {
	/**
	 * The default visibility state of the side nav on desktop screens.
	 * This value will be used if the visibility state is not set in context yet, e.g. during SSR.
	 */
	defaultCollapsed?: boolean;
} = {}): {
	visibleOnDesktop: boolean;
	setVisibleOnDesktop: Dispatch<SetStateAction<boolean | null>>;
	visibleOnMobile: boolean;
	setVisibleOnMobile: Dispatch<SetStateAction<boolean>>;
} => {
	const visibleOnDesktop = useContext(SideNavDesktopVisibility);
	const setVisibleOnDesktop = useContext(SetSideNavDesktopVisibility);
	const visibleOnMobile = useContext(SideNavMobileVisibility);
	const setVisibleOnMobile = useContext(SetSideNavMobileVisibility);

	return {
		visibleOnDesktop: visibleOnDesktop === null ? !defaultCollapsed : visibleOnDesktop,
		visibleOnMobile,
		setVisibleOnDesktop,
		setVisibleOnMobile,
	};
};

const styles = cssMap({
	hiddenMobileAndDesktop: {
		display: 'none',
	},
	hiddenMobileOnly: {
		display: 'none',
		'@media (min-width: 64rem)': {
			display: 'initial',
		},
	},
	hiddenDesktopOnly: {
		'@media (min-width: 64rem)': {
			display: 'none',
		},
	},
	absolutelyPositionWithinSideNav: {
		position: 'absolute',
		// Positioning at the top-right corner of the side nav
		insetInlineEnd: token('space.150'),
		insetBlockStart: token('space.150'),
		// Ensure it is above side nav content
		zIndex: 100,
	},
	buttonBackgroundColor: {
		backgroundColor: token('elevation.surface'),
	},
});

/**
 * __SideNavToggleButton__
 *
 * Button for toggling the side nav. Should be used in the top bar.
 *
 * The alternative is `SideNavCollapseButton`, which is for use within the side nav.
 *
 * _It contains built-in responsive visibility behaviour:_
 * - For mobile screens, the button is always shown.
 * - For desktop screens, the button is only shown when the side nav is hidden.
 */
export const SideNavToggleButton = ({
	defaultCollapsed = false,
	testId,
	interactionName,
}: {
	/**
	 * Whether the side nav should be collapsed by default __on desktop screens__.
	 *
	 * It is always collapsed by default for mobile screens.
	 *
	 * __Note:__ If using this prop, ensure that it is also provided to the `SideNav` slot.
	 * This is to ensure the state is in sync before post-SSR hydration.
	 */
	defaultCollapsed?: boolean;
	testId?: string;
	/**
	 * An optional name used to identify events for [React UFO (Unified Frontend Observability) press interactions](https://developer.atlassian.com/platform/ufo/react-ufo/react-ufo/getting-started/#quick-start--press-interactions). For more information, see [React UFO integration into Design System components](https://go.atlassian.com/react-ufo-dst-integration).
	 */
	interactionName?: string;
}) => {
	const { visibleOnDesktop: isSideNavVisibleOnDesktop, visibleOnMobile: isSideNavVisibleOnMobile } =
		useSideNavVisibility({ defaultCollapsed });

	const [isSideNavVisible, setIsSideNavVisible] = useState<boolean>(!defaultCollapsed);

	useEffect(() => {
		const { matches } = window.matchMedia('(min-width: 64rem)');
		setIsSideNavVisible(matches ? isSideNavVisibleOnDesktop : isSideNavVisibleOnMobile);
	}, [isSideNavVisibleOnDesktop, isSideNavVisibleOnMobile]);

	// When screen size changes, ensure we use the correct visibility state
	useEffect(() => {
		const mediaQueryList = window.matchMedia('(min-width: 64rem)');
		return bind(mediaQueryList, {
			type: 'change',
			listener() {
				setIsSideNavVisible(
					mediaQueryList.matches ? isSideNavVisibleOnDesktop : isSideNavVisibleOnMobile,
				);
			},
		});
	}, [isSideNavVisibleOnDesktop, isSideNavVisibleOnMobile]);

	const toggleVisibility = useToggleSideNav();

	return (
		<div
			css={isSideNavVisibleOnDesktop && styles.hiddenDesktopOnly}
			data-testid={testId ? `${testId}-container` : undefined}
		>
			<IconButton
				appearance="subtle"
				label={isSideNavVisible ? 'Collapse sidebar' : 'Expand sidebar'}
				icon={isSideNavVisible ? SidebarCollapseIcon : SidebarExpandIcon}
				onClick={toggleVisibility}
				testId={testId}
				isTooltipDisabled={false}
				interactionName={interactionName}
			/>
		</div>
	);
};

/**
 * __SideNavCollapseButton__
 *
 * The button for collapsing the side nav, for use within the `<SideNav>`.
 * Should be used within the `collapseButton` slot prop of the `SideNav` component, e.g.
 * ```tsx
 * <SideNav collapseButton={<SideNavCollapseButton label="Collapse sidebar" />} />
 * ```
 *
 * The alternative is `SideNavToggleButton`, which is for use within the top bar.
 *
 * It is only shown on desktop screens, when the side nav is visible.
 */
export const SideNavCollapseButton = ({
	testId,
	interactionName,
}: {
	testId?: string /**
	 * An optional name used to identify events for [React UFO (Unified Frontend Observability) press interactions](https://developer.atlassian.com/platform/ufo/react-ufo/react-ufo/getting-started/#quick-start--press-interactions). For more information, see [React UFO integration into Design System components](https://go.atlassian.com/react-ufo-dst-integration).
	 */;
	interactionName?: string;
}) => {
	const isSideNavVisibleOnDesktop = useContext(SideNavDesktopVisibility);
	const setSideNavVisibleOnDesktop = useContext(SetSideNavDesktopVisibility);

	const collapseSideNav = useCallback(() => {
		setSideNavVisibleOnDesktop(false);
	}, [setSideNavVisibleOnDesktop]);

	return (
		<div
			css={[
				styles.absolutelyPositionWithinSideNav,
				isSideNavVisibleOnDesktop && styles.hiddenMobileOnly,
				!isSideNavVisibleOnDesktop && styles.hiddenMobileAndDesktop,
			]}
			data-testid={testId ? `${testId}-container` : undefined}
		>
			{/**
			 * Nesting in `InteractionConsumer` separately to the above `div` to prevent Compiled styling conflicts with
			 * `display` property.
			 */}
			<InteractionConsumer
				// Setting background color to the `InteractionConsumer` element so it's not visible when the button is hidden
				xcss={styles.buttonBackgroundColor}
				behavior="hideOnLeave"
			>
				<IconButton
					appearance="subtle"
					label="Collapse sidebar"
					icon={SidebarCollapseIcon}
					onClick={collapseSideNav}
					testId={testId}
					isTooltipDisabled={false}
					interactionName={interactionName}
				/>
			</InteractionConsumer>
		</div>
	);
};
