import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

const SERVICE_DESK_ROUTE_PREFIX = '/jira/servicedesk/projects/:projectKey';
const WORKLOAD_ROUTE_SUFFIX = '/reports/workload';
const ATLASSIAN_ANALYTICS_ROUTE_SUFFIX = '/reports/atlassian-analytics';
const CUSTOM_ROUTE_SUFFIX = '/reports/custom/:reportId';
const REQUESTS_ROUTE_SUFFIX = '/reports/:reportId(kb-requests-resolved|kb-requests-deflected)';
const TIME_SCALE = '/timescale/:timescaleId';
const SATISFACTION_ROUTE_SUFFIX = '/reports/all-rating';
const ISSUE_KEY = '/:issueKey';
export const servicedeskReportsSummaryRoute = {
	// HACK - the reports backbone code tries to refresh the page
	// in order to re-render the view. This route allows us to replicate that
	// behaviour in React.
	group: ROUTE_GROUPS.SERVICEDESK,

	name: ROUTE_NAMES.SERVICEDESK_REPORTS_SUMMARY,
	path: '(/jira/servicedesk/projects/[^/]+/reports/.+)/summary',
	exact: true,
};

export const servicedeskReports1Route = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_REPORTS,
	path: `${SERVICE_DESK_ROUTE_PREFIX}/reports`,
	exact: true,
};

export const servicedeskAtlassianAnalyticsRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_ATLASSIAN_ANALYTICS,
	path: `${SERVICE_DESK_ROUTE_PREFIX}${ATLASSIAN_ANALYTICS_ROUTE_SUFFIX}`,
	exact: true,
};

export const servicedeskReportsWorkloadRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_REPORTS_WORKLOAD,
	path: `${SERVICE_DESK_ROUTE_PREFIX}${WORKLOAD_ROUTE_SUFFIX}`,
	exact: true,
};

export const servicedeskReportsSatisfactionIssueRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_REPORTS_SATISFACTION_ISSUE,
	path: `${SERVICE_DESK_ROUTE_PREFIX}${SATISFACTION_ROUTE_SUFFIX}${TIME_SCALE}${ISSUE_KEY}`,
	query: ['oldIssueView?'],
	exact: true,
};

export const servicedeskReportsSatisfactionTimeScaleRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_REPORTS_SATISFACTION_TIME_SCALE,
	path: `${SERVICE_DESK_ROUTE_PREFIX}${SATISFACTION_ROUTE_SUFFIX}${TIME_SCALE}`,
};

export const servicedeskReportsSatisfaction2Route = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_REPORTS_SATISFACTION,
	path: `${SERVICE_DESK_ROUTE_PREFIX}${SATISFACTION_ROUTE_SUFFIX}`,
};

export const servicedeskKbRequestsReportsIssueRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_KB_REQUESTS_REPORTS_ISSUE,
	path: `${SERVICE_DESK_ROUTE_PREFIX}${REQUESTS_ROUTE_SUFFIX}${TIME_SCALE}${ISSUE_KEY}`,
	query: ['oldIssueView?'],
	exact: true,
};

export const servicedeskKbRequestsReportsTimeScaleRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_KB_REQUESTS_REPORTS_TIME_SCALE,
	path: `${SERVICE_DESK_ROUTE_PREFIX}${REQUESTS_ROUTE_SUFFIX}${TIME_SCALE}`,
};

export const servicedeskKbRequestsReports2Route = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_KB_REQUESTS_REPORTS,
	path: `${SERVICE_DESK_ROUTE_PREFIX}${REQUESTS_ROUTE_SUFFIX}`,
};

export const servicedeskReportsCustomEditRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_REPORTS_CUSTOM_EDIT,
	path: `${SERVICE_DESK_ROUTE_PREFIX}${CUSTOM_ROUTE_SUFFIX}/edit`,
};

export const servicedeskReportsCustomCreateRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_REPORTS_CUSTOM_CREATE,
	path: `${SERVICE_DESK_ROUTE_PREFIX}/reports/custom/new`,
};

export const servicedeskReportsCustomIssueRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_REPORTS_CUSTOM_ISSUE,
	path: `${SERVICE_DESK_ROUTE_PREFIX}${CUSTOM_ROUTE_SUFFIX}${TIME_SCALE}${ISSUE_KEY}`,
	query: ['oldIssueView?'],
	exact: true,
};

export const servicedeskReportsCustomTimeScaleRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_REPORTS_CUSTOM_TIME_SCALE,
	path: `${SERVICE_DESK_ROUTE_PREFIX}${CUSTOM_ROUTE_SUFFIX}${TIME_SCALE}`,
};

export const servicedeskReportsCustom4Route = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_REPORTS_CUSTOM,
	path: `${SERVICE_DESK_ROUTE_PREFIX}${CUSTOM_ROUTE_SUFFIX}`,
};

export const servicedeskReportsTypeRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_REPORTS_TYPE,
	path: `${SERVICE_DESK_ROUTE_PREFIX}/reports/:reportType/:reportId?/:reportsAction?/:timescaleId?/:issueKey?`,
	query: ['oldIssueView?'],
};
