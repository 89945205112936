import { fg } from '@atlassian/jira-feature-gating';
import {
	CUSTOM_FIELDS_KEY,
	ISSUE_KEY,
	PROJECT_ROLE_ACTOR_KEY,
	PROJECT_CLEANUP_KEY,
} from './assets';
import type { Metric } from './assets/types';
import { METRICS } from './constants';

/**
 * A registry of all the metrics. It provides the methods for looking up the metrics that are registered.
 * *Exported for testing purposes only. DO NOT used this class directly.*
 */
export class MetricRegistry {
	private metrics: ReadonlyArray<Metric>;

	public constructor(metrics: ReadonlyArray<Metric>) {
		this.metrics = metrics;
	}

	public getMetric(key: string): Metric | undefined {
		return this.metrics.find((m) => m.key === key);
	}

	public getAllVisibleMetrics(): Metric[] {
		return this.metrics.filter((metric) => metric.visible && mitigationEnabled(metric.key));
	}

	public getAllVisibleMetricsKeys(): string[] {
		return getAllVisibleMetrics().map((m) => m.key);
	}
}

/**
 * Metric registry singleton. We should not be creating another registry.
 */
const REGISTRY = new MetricRegistry(METRICS);

/**
 * Get metric using the metric key
 * @param key
 */
export const getMetric = REGISTRY.getMetric.bind(REGISTRY);

/**
 * Get all metrics that are visible
 */
export const getAllVisibleMetrics = REGISTRY.getAllVisibleMetrics.bind(REGISTRY);

/**
 * Get all metric keys for visible metrics
 */
export const getAllVisibleMetricKeys = REGISTRY.getAllVisibleMetricsKeys.bind(REGISTRY);

export const mitigationEnabled = (metricKey: string) => {
	switch (metricKey) {
		case PROJECT_CLEANUP_KEY:
			return fg('site-optimizer-project-cleanup');
		case PROJECT_ROLE_ACTOR_KEY:
		case CUSTOM_FIELDS_KEY:
		case ISSUE_KEY:
			return true;
		default:
			return false;
	}
};

export const assertNever = (_: never, message: string) => {
	throw new Error(message);
};
