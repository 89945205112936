import { ROUTE_NAMES } from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const servicedeskCmdbAssetsRoute = {
	name: 'servicedesk-cmdb-assets',
	path: '/jira/servicedesk/assets',
	exact: true,
};

export const servicedeskCmdbInsightRoute = {
	name: 'servicedesk-cmdb-insight',
	path: '/jira/servicedesk/insight',
	exact: true,
};

export const servicedeskCmdbAssetsDefaultRedirect = {
	name: ROUTE_NAMES.SERVICEDESK_CMDB_ASSETS_REPORTS_DEFAULT_REDIRECT,
	path: '/jira/servicedesk/assets/reports',
	exact: true,
};

export const servicedeskCmdbAssetsReportsRoute = {
	name: ROUTE_NAMES.SERVICEDESK_CMDB_ASSETS_REPORTS,
	path: '/jira/servicedesk/assets/reports/:templateId',
	exact: true,
};

export const servicedeskCmdbAssetsReportsUnsubscribeRoute = {
	name: ROUTE_NAMES.SERVICEDESK_CMDB_ASSETS_REPORTS_UNSUBSCRIBE,
	path: '/jira/servicedesk/assets/reports/unsubscribe/:templateId/:subscriptionId',
	exact: true,
};

export const servicedeskCmdbAssetsObjectSchema1Route = {
	name: ROUTE_NAMES.SERVICEDESK_CMDB_ASSETS_OBJECT_SCHEMA,
	path: '/jira/servicedesk/assets/object-schema/:objectSchemaId',
};

export const servicedeskCmdbInsightObjectSchemaRoute = {
	name: ROUTE_NAMES.SERVICEDESK_CMDB_INSIGHT_OBJECT_SCHEMA,
	path: '/jira/servicedesk/insight/object-schemas/:objectSchemaId',
};

export const servicedeskCmdbAssetsObjectTypeConfigurationRoute = {
	name: ROUTE_NAMES.SERVICEDESK_CMDB_ASSETS_OBJECT_TYPE_CONFIGURATION,
	path: '/jira/servicedesk/assets/configure/object-type/:objectTypeId',
};

export const servicedeskCmdbInsightObjectTypeConfigurationRoute = {
	name: ROUTE_NAMES.SERVICEDESK_CMDB_INSIGHT_OBJECT_TYPE_CONFIGURATION,
	path: '/jira/servicedesk/insight/configure/object-type/:objectTypeId',
};

export const servicedeskCmdbAssetsConfigureRoute = {
	name: ROUTE_NAMES.SERVICEDESK_CMDB_ASSETS_CONFIGURE,
	path: '/jira/servicedesk/assets/configure/global',
};

export const servicedeskCmdbInsightConfigureGlobalRoute = {
	name: ROUTE_NAMES.SERVICEDESK_CMDB_INSIGHT_CONFIGURE,
	path: '/jira/servicedesk/insight/configure/global',
};

export const servicedeskCmdbAssetsObjectSchemaConfigurationRoute = {
	name: ROUTE_NAMES.SERVICEDESK_CMDB_ASSETS_OBJECT_SCHEMA_CONFIGURATION,
	path: '/jira/servicedesk/assets/configure/object-schema/:objectSchemaId',
};

export const servicedeskCmdbInsightObjectSchemaConfigurationRoute = {
	name: ROUTE_NAMES.SERVICEDESK_CMDB_INSIGHT_OBJECT_SCHEMA_CONFIGURATION,
	path: '/jira/servicedesk/insight/configure/object-schema/:objectSchemaId',
};

export const servicedeskCmdbInsightConfigureRoute = {
	name: ROUTE_NAMES.SERVICEDESK_CMDB_INSIGHT_CONFIGURE,
	path: '/jira/servicedesk/insight/configure',
};

export const servicedeskCmdbAssetsObject4Route = {
	name: ROUTE_NAMES.SERVICEDESK_CMDB_ASSETS_OBJECT,
	path: '/jira/servicedesk/assets/object/:objectId',
};

export const servicedeskCmdbInsightObject4Route = {
	name: ROUTE_NAMES.SERVICEDESK_CMDB_INSIGHT_OBJECT,
	path: '/jira/servicedesk/insight/assets/:objectId',
};
