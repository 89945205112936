// eslint-disable-next-line jira/restricted/@atlassian/jira-router
import { createResource, type Match, type Query } from '@atlassian/jira-router';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils';
import {
	type JiraServiceManagementNavReportsData,
	fetchJsmReportsSidebarNavInfo,
} from '../../../services/sidebar/jsm-reports-sidebar-navigation';

const getIdentifier = (match: Match, query: Query): string => {
	const { projectKey, projectId } = getProjectKeyId(match, query);
	return projectKey || projectId;
};

export const staticSidebarJsmReportsResource =
	createResource<JiraServiceManagementNavReportsData | null>({
		type: 'NAVIGATION_SIDEBAR_JIRA_SERVICE_MANAGEMENT_REPORTS',
		getKey: ({ match, query }) => getIdentifier(match, query) ?? 'none',
		getData: async ({ match, query }) => {
			const projectKeyOrId = getIdentifier(match, query);
			if (projectKeyOrId == null) {
				return null;
			}
			return fetchJsmReportsSidebarNavInfo(projectKeyOrId);
		},
		maxAge: 5 * 60 * 1000,
	});
