import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ServicedeskCustomerDetailsType from '@atlassian/jira-spa-apps-servicedesk-customer-details';
import type ServicedeskCustomersV2Type from '@atlassian/jira-spa-apps-servicedesk-customers-v2';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyServicedeskCustomersV2 = lazyForPaint<typeof ServicedeskCustomersV2Type>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-servicedesk-customers-v2", jiraSpaEntry: "async-servicedesk-customers-v2" */ '@atlassian/jira-spa-apps-servicedesk-customers-v2'
		),
	),
);

export const ServiceDeskCustomersV2 = () => (
	<LazyPage
		Page={LazyServicedeskCustomersV2}
		pageId="spa-apps/servicedesk/customers-v2"
		shouldShowSpinner
	/>
);

export const LazyServicedeskCustomerDetails = lazyForPaint<typeof ServicedeskCustomerDetailsType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-servicedesk-customer-details", jiraSpaEntry: "async-servicedesk-customer-details" */ '@atlassian/jira-spa-apps-servicedesk-customer-details'
			),
		),
);

export const ServicedeskCustomerDetails = () => (
	<LazyPage
		Page={LazyServicedeskCustomerDetails}
		pageId="spa-apps/servicedesk/customer-details"
		shouldShowSpinner
	/>
);
