import React, { type FC, useEffect, useMemo } from 'react';
import { useExperienceStart, useExperienceSuccess } from '@atlassian/jira-experience-tracker';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { usePageId } from '@atlassian/jira-spa-state-controller';
import UFOCustomData from '@atlassian/ufo-custom-data';

export const useSidebarExperience = () => {
	const pageId = usePageId();

	return useMemo(
		() => ({
			experience: 'viewSidebarNavigation',
			experienceId: pageId,
			analyticsSource: 'Sidebar',
			attributes: {
				sidebar: 'v4',
			},
		}),
		[pageId],
	);
};

export const SidebarStart: FC = () => {
	const route = useCurrentRoute();
	const { experience, experienceId, analyticsSource } = useSidebarExperience();
	const onStartExperience = useExperienceStart({ experience, experienceId, analyticsSource });

	useEffect(() => {
		if (route.isRedirect) {
			return;
		}

		onStartExperience();
	}, [onStartExperience, route]);

	return null;
};

export const SidebarReady: FC = () => {
	const route = useCurrentRoute();
	const { experience, attributes } = useSidebarExperience();
	const onSuccessfulExperience = useExperienceSuccess({ experience, attributes });

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const routeName = route.name ?? route.group ?? 'UNKNOWN';

	const doNotRunEffects = route.isRedirect || __SERVER__;

	const sidebarId = route.navigation?.sidebarId ?? 'default';

	useEffect(() => {
		if (doNotRunEffects) {
			return;
		}

		const event = createAnalyticsEvent({
			action: 'ready',
			actionSubject: 'sidebar',
			meta: { teamName: 'endeavour' },
		});

		fireUIAnalytics(event, 'Sidebar Ready', {
			route: routeName,
		});
	}, [createAnalyticsEvent, doNotRunEffects, routeName]);

	useEffect(() => {
		if (doNotRunEffects) {
			return;
		}

		onSuccessfulExperience();
	}, [doNotRunEffects, onSuccessfulExperience, route]);

	const customData = useMemo(
		() => ({
			sidebarType: sidebarId,
		}),
		[sidebarId],
	);

	return <UFOCustomData data={customData} />;
};
