import isEmpty from 'lodash/isEmpty';
import { createAri } from '@atlassian/jira-platform-ari';
import { createResource, useResource } from '@atlassian/jira-router';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils';
import { fetchNavigation } from './services/navigation';
import { PROJECT_KEY_QUERY, PROJECT_ID_QUERY, OVERVIEW_QUERY } from './services/navigation/gql';
import type { NavigationResourceType } from './types';

export { PROJECT_PAGE_SIZE, NOT_APPLICABLE } from './constants';
export type { NavigationProject, NavigationOverview, BaseProject } from './types';

export const navigationResource = createResource<NavigationResourceType>({
	type: 'BUSINESS_NAVIGATION',
	getKey: () => 'BUSINESS_NAVIGATION',
	getData: async ({ match, query }, { tenantContext: { cloudId, isAnonymous } }) => {
		// do not fetch the resource for anonymous access
		if (isAnonymous) {
			return null;
		}

		const { projectKey, projectId } = getProjectKeyId(match, query);

		if (!isEmpty(projectKey)) {
			return fetchNavigation(PROJECT_KEY_QUERY, {
				cloudId,
				projectKey,
			});
		}

		if (!isEmpty(projectId)) {
			return fetchNavigation(PROJECT_ID_QUERY, {
				cloudId,
				projectId: createAri({
					resourceOwner: 'jira',
					cloudId,
					resourceType: 'project',
					resourceId: projectId,
				}),
			});
		}

		return fetchNavigation(OVERVIEW_QUERY, {
			cloudId,
		});
	},
	maxAge: Infinity,
});

export const useNavigationResource = () => useResource(navigationResource);

export const useIsPremiumUserSeat = (): boolean => {
	const { data } = useNavigationResource();

	if (data === 'NOT_APPLICABLE') {
		return false;
	}

	return Boolean(data?.isPremiumUserSeat);
};

export const useTriggerOverviewChangeboarding = (): boolean => {
	const { data } = useNavigationResource();

	if (data === 'NOT_APPLICABLE') {
		return false;
	}

	return Boolean(data?.showPlansOnboarding);
};
