import {
	PROJECT_ISSUE_NAVIGATOR_MY_OPEN_ISSUES,
	PROJECT_ISSUE_NAVIGATOR_REPORTED_BY_ME_ISSUES,
	PROJECT_ISSUE_NAVIGATOR_ALL_ISSUES,
	PROJECT_ISSUE_NAVIGATOR_ALL_OPEN_ISSUES,
	PROJECT_ISSUE_NAVIGATOR_DONE_ISSUES,
	PROJECT_ISSUE_NAVIGATOR_RECENTLY_VIEWED_ISSUES,
	PROJECT_ISSUE_NAVIGATOR_ADDED_RECENTLY_ISSUES,
	PROJECT_ISSUE_NAVIGATOR_RESOLVED_RECENTLY_ISSUES,
	PROJECT_ISSUE_NAVIGATOR_UPDATED_RECENTLY_ISSUES,
} from '../../../common/constants';
import { matchUrlParams } from '../../../utils';

interface ItemMapEntry {
	analytics: {
		itemId: string;
	};
	selectedOn: (
		jql: string,
		// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
		allOpenIssuesSelector: ReturnType<typeof matchUrlParams> | void,
	) => ReturnType<typeof matchUrlParams>;
}

export const isInAllFiltersState = (filter?: string, jql?: string) => {
	// Initial load
	if ((typeof filter === 'undefined' || !filter.length) && typeof jql === 'undefined') {
		return true;
	}

	if (filter != null && filter !== 'allissues') {
		return false;
	}

	if (typeof jql === 'string' && jql.match(/^project = ".*" ORDER BY created (?:DESC|ASC)$/)) {
		return true;
	}

	return false;
};

export const itemMap: { [key: string]: ItemMapEntry } = {
	ninLayoutAllIssues: {
		analytics: {
			itemId: `pin-${PROJECT_ISSUE_NAVIGATOR_ALL_ISSUES}`,
		},
		selectedOn:
			(jql) =>
			(...args) =>
				isInAllFiltersState(undefined, '') ||
				(!jql ? matchUrlParams(['filter'], false)(...args) : false),
	},
	myOpenIssues: {
		analytics: {
			itemId: `pin-${PROJECT_ISSUE_NAVIGATOR_MY_OPEN_ISSUES}`,
		},
		selectedOn: (jql) => (!jql ? matchUrlParams(['filter'], false) : () => false),
	},
	reportedByMe: {
		analytics: {
			itemId: `pin-${PROJECT_ISSUE_NAVIGATOR_REPORTED_BY_ME_ISSUES}`,
		},
		selectedOn: (jql) => (!jql ? matchUrlParams(['filter'], false) : () => false),
	},
	notNinLayoutAllIssues: {
		analytics: { itemId: `pin-${PROJECT_ISSUE_NAVIGATOR_ALL_ISSUES}` },
		selectedOn: (jql) => (!jql ? matchUrlParams(['filter'], false) : () => false),
	},
	allOpenIssues: {
		analytics: {
			itemId: `pin-${PROJECT_ISSUE_NAVIGATOR_ALL_OPEN_ISSUES}`,
		},
		selectedOn: (jql, allOpenIssuesSelector) =>
			!jql && allOpenIssuesSelector ? allOpenIssuesSelector : () => false,
	},
	doneIssues: {
		analytics: {
			itemId: `pin-${PROJECT_ISSUE_NAVIGATOR_DONE_ISSUES}`,
		},
		selectedOn: (jql) => (!jql ? matchUrlParams(['filter'], false) : () => false),
	},
	viewedRecently: {
		analytics: {
			itemId: `pin-${PROJECT_ISSUE_NAVIGATOR_RECENTLY_VIEWED_ISSUES}`,
		},
		selectedOn: (jql) => (!jql ? matchUrlParams(['filter'], false) : () => false),
	},
	createdRecently: {
		analytics: {
			itemId: `pin-${PROJECT_ISSUE_NAVIGATOR_ADDED_RECENTLY_ISSUES}`,
		},
		selectedOn: (jql) => (!jql ? matchUrlParams(['filter'], false) : () => false),
	},
	resolvedRecently: {
		analytics: {
			itemId: `pin-${PROJECT_ISSUE_NAVIGATOR_RESOLVED_RECENTLY_ISSUES}`,
		},
		selectedOn: (jql) => (!jql ? matchUrlParams(['filter'], false) : () => false),
	},
	updatedRecently: {
		analytics: {
			itemId: `pin-${PROJECT_ISSUE_NAVIGATOR_UPDATED_RECENTLY_ISSUES}`,
		},
		selectedOn: (jql) => (!jql ? matchUrlParams(['filter'], false) : () => false),
	},
};
