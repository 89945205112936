import React from 'react';
import { NavigationSkeleton as TopNavigationSkeleton } from '@atlaskit/atlassian-navigation/skeleton';
import UFOLoadHold from '@atlassian/ufo-load-hold';
import { useTheme } from '../../controllers/theme';

export const JiraAtlassianNavigationSkeleton = () => {
	const theme = useTheme();
	return (
		<UFOLoadHold name="top-navigation">
			<TopNavigationSkeleton theme={theme} />
		</UFOLoadHold>
	);
};
