/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import {
	createContext,
	Fragment,
	type MutableRefObject,
	type ReactNode,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';

import { cssMap, jsx } from '@compiled/react';
import { createPortal } from 'react-dom';
import invariant from 'tiny-invariant';

import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { disableNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/disable-native-drag-preview';
import { preventUnhandled } from '@atlaskit/pragmatic-drag-and-drop/prevent-unhandled';
import { Pressable } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import VisuallyHidden from '@atlaskit/visually-hidden';

import type { ResizeBounds } from './types';
import { useTextDirection } from './use-text-direction';
import { getComputedWidth, getWidthFromDragLocation } from './utils';

const styles = cssMap({
	root: {
		display: 'none',
		position: 'absolute',
		insetBlockEnd: 0,
		insetBlockStart: 0,
		// Offset to overlap the panel border, so that the hover state line remains visually centered
		insetInlineEnd: '1px',
		outline: 'none',
		'@media (min-width: 48rem)': {
			display: 'block',
		},
	},
	grabArea: {
		width: '16px',
		height: '100%',
		padding: token('space.0'),
		color: 'transparent',
		background: 'transparent',
		transition: 'color 100ms',
		transitionDelay: '0ms',
		'&:hover': {
			cursor: 'ew-resize',
			transitionDelay: '200ms',
		},
		'&:hover, &:focus': {
			color: token('color.text.brand'),
			transition: 'color 200ms',
		},
		position: 'absolute',
	},
	line: {
		display: 'block',
		width: token('border.width.indicator'),
		height: '100%',
		color: 'inherit',
		backgroundColor: 'currentcolor',
	},
	panelChildrenWrapper: {
		overflow: 'auto',
		height: '100%',
	},
	buttonContent: {
		width: '100%',
		height: '100%',
	},
});

export type PanelSplitterContext = {
	/**
	 * A ref to the page layout element that will be resized by the splitter.
	 */
	panelRef: MutableRefObject<HTMLDivElement | null>;
	/**
	 * The "saved" width of the panel element. Used to calculate the new width of the panel when dragging.
	 */
	panelWidth: number;
	/**
	 * Called when the user finishes resizing the panel. It should update the width of the panel element.
	 */
	onCompleteResize: (newWidth: number) => void;
	/**
	 * The minimum and maximum bounds for resizing the panel.
	 * The bounds can be provided as `px` or `vw` values.
	 */
	resizeBounds: ResizeBounds;
	/**
	 * A ref to the portal element where the panel splitter will be rendered.
	 * Internally set by the PanelSplitterProvider.
	 * Used to render the panel splitter outside of an overflow container.
	 */
	portalRef: MutableRefObject<HTMLDivElement | null>;
	/**
	 * The CSS variable that will be set on the panel element to temporarily resize it while dragging the splitter
	 */
	resizingCssVar: string;
	/**
	 * An optional click handler for the splitter. It is not called when the splitter is _clicked and dragged_, but only when _clicked without dragging_.
	 */
	onClick?: (event: React.MouseEvent<HTMLButtonElement>, analyticsEvent: UIAnalyticsEvent) => void;
};

const PanelSplitterContext = createContext<PanelSplitterContext | null>(null);

export type PanelSplitterProviderProps = Omit<PanelSplitterContext, 'portalRef'> & {
	children: React.ReactNode;
};

/**
 * Provides the context required for the panel splitter to work within a page layout slot. Should be used in the page layout slot components, e.g. SideNav, Aside etc, as opposed to products.
 */
export const PanelSplitterProvider = ({
	panelWidth,
	onCompleteResize,
	resizeBounds,
	resizingCssVar,
	onClick,
	panelRef,
	children,
}: PanelSplitterProviderProps) => {
	const portalRef = useRef<HTMLDivElement | null>(null);

	return (
		<Fragment>
			<PanelSplitterContext.Provider
				value={{
					panelWidth,
					onCompleteResize,
					resizeBounds,
					portalRef,
					resizingCssVar,
					onClick,
					panelRef,
				}}
			>
				<div css={styles.panelChildrenWrapper}>{children}</div>
			</PanelSplitterContext.Provider>
			{/**
			 * Portal target for rendering the PanelSplitter.
			 * Used to ensure the PanelSplitter can overflow the side nav container, without causing it to stretch or scroll.
			 */}
			<div ref={portalRef} />
		</Fragment>
	);
};

type PanelSplitterProps = {
	/**
	 * The accessible label for the panel splitter. It is visually hidden, but is required for accessibility.
	 */
	label: React.ReactNode;

	/**
	 * Called when the user begins resizing the panel.
	 * Intended for analytics.
	 */
	onResizeStart?: ({ initialWidth }: { initialWidth: number }) => void;

	/**
	 * Called when the user finishes resizing the panel.
	 */
	onResizeEnd?: ({
		initialWidth,
		finalWidth,
	}: {
		initialWidth: number;
		finalWidth: number;
	}) => void;

	/**
	 * An optional name used to identify events for [React UFO (Unified Frontend Observability) press interactions](https://developer.atlassian.com/platform/ufo/react-ufo/react-ufo/getting-started/#quick-start--press-interactions). For more information, see [React UFO integration into Design System components](https://go.atlassian.com/react-ufo-dst-integration).
	 */
	interactionName?: string;
};

const PortaledPanelSplitter = ({
	label,
	onResizeStart,
	onResizeEnd,
	interactionName,
}: PanelSplitterProps): JSX.Element => {
	const splitterRef = useRef<HTMLButtonElement | null>(null);
	const panelSplitterContext = useContext(PanelSplitterContext);
	invariant(panelSplitterContext, 'Panel splitter context must be set');
	const { panelWidth, onCompleteResize, resizeBounds, panelRef, resizingCssVar, portalRef } =
		panelSplitterContext;
	invariant(portalRef.current, 'Portal ref must be set');

	const direction = useTextDirection(portalRef.current);

	useEffect(() => {
		const splitter = splitterRef.current;
		invariant(splitter, 'Splitter ref must be set');

		return draggable({
			element: splitter,
			onGenerateDragPreview: ({ nativeSetDragImage }) => {
				// We will be moving the line to indicate a drag. We can disable the native drag preview
				disableNativeDragPreview({ nativeSetDragImage });
				// We don't want any native drop animation for when the user does not drop on a drop target. We want the drag to finish immediately
				preventUnhandled.start();
			},
			getInitialData() {
				invariant(panelRef.current, 'Panel ref must be set');

				/**
				 * The drag calculations require the actual computed width of the element
				 * For example, if the panel has loaded with a width of 2000px, but the max bound is 1000px and is visually 1000px (due to the CSS `clamp()`),
				 * the drag calculations should use the actual width of 1000px, not the width in state of 2000px.
				 */
				return {
					initialWidth: getComputedWidth(panelRef.current),
				};
			},
			onDragStart({ source }) {
				invariant(
					typeof source.data.initialWidth === 'number',
					'expected initialWidth to be a number',
				);

				onResizeStart?.({ initialWidth: source.data.initialWidth });
			},
			onDrag({ location, source }) {
				if (typeof source.data.initialWidth !== 'number') {
					return;
				}

				panelRef.current?.style.setProperty(
					resizingCssVar,
					`clamp(${resizeBounds.min}, ${getWidthFromDragLocation({ initialWidth: source.data.initialWidth, location, direction })}px, ${resizeBounds.max})`,
				);
			},
			onDrop({ source }) {
				preventUnhandled.stop();
				invariant(panelRef.current, 'Panel ref must be set');
				invariant(
					typeof source.data.initialWidth === 'number',
					'expected initialWidth to be a number',
				);

				const finalWidth = getComputedWidth(panelRef.current);
				onCompleteResize(finalWidth);
				onResizeEnd?.({
					initialWidth: source.data.initialWidth,
					finalWidth,
				});
			},
		});
	}, [
		onCompleteResize,
		onResizeStart,
		onResizeEnd,
		panelRef,
		resizingCssVar,
		panelWidth,
		resizeBounds,
		direction,
	]);

	return createPortal(
		<div css={styles.root}>
			<Pressable
				// To win the specificity war we need to use inline styles so Compiled wins over Emotion.
				// Sorry Kylor!
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
				style={{ outline: 'none' }}
				ref={splitterRef}
				xcss={styles.grabArea}
				onClick={panelSplitterContext.onClick}
				interactionName={interactionName}
			>
				<div
					// Due to a Firefox bug with button element drag events we need to make the button
					// content (children) fill up the entire space of the button.
					// See: https://bugzilla.mozilla.org/show_bug.cgi?id=568313#c16
					css={styles.buttonContent}
				>
					<VisuallyHidden>{label}</VisuallyHidden>
					<span css={styles.line} />
				</div>
			</Pressable>
		</div>,
		portalRef.current,
	);
};

// Ensures that the component is only rendered on a client. Uses a `useEffect`, which is not run on servers.
const ClientOnly = ({ children }: { children: ReactNode }): JSX.Element => {
	const [hasMounted, setHasMounted] = useState(false);

	useEffect(() => {
		setHasMounted(true);
	}, []);

	return <Fragment>{hasMounted ? children : null}</Fragment>;
};

/**
 * _PanelSplitter_
 *
 * A component that allows the user to resize a panel.
 * It can be used within page layout slots like SideNav. The page layout component should provide the context for it,
 * using `<PanelSplitterProvider>`.
 *
 * Example usage in products:
 * ```tsx
 * <SideNav>
 *   {/* other side nav content *}
 *   <PanelSplitter label="Resize Side Nav" />
 * </SideNav>
 * ```
 */
export const PanelSplitter = ({
	label,
	onResizeStart,
	onResizeEnd,
	interactionName,
}: PanelSplitterProps): JSX.Element => (
	<ClientOnly>
		<PortaledPanelSplitter
			label={label}
			onResizeStart={onResizeStart}
			onResizeEnd={onResizeEnd}
			interactionName={interactionName}
		/>
	</ClientOnly>
);
