import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import type { AnalyticsDataPayload, AnalyticsContextPayload } from './types';

// AnalyticsDataPayload is cast to inexact type so that it can also accept subtypes
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (payload: Readonly<AnalyticsDataPayload>): AnalyticsContextPayload => {
	const { tags, containerId, containers, containerType, objectId, objectType, attributes } =
		payload;
	return omitBy(
		{
			tags,
			containerId,
			containers,
			containerType,
			objectId,
			objectType,
			attributes,
		},
		isUndefined,
	);
};
