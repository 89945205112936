import { createStore, createContainer, createHook } from 'react-sweet-state';
import * as actions from './actions';
import type { State, ContainerProps } from './types';

const initialState: State = {
	data: null,
	loading: false,
	error: null,
};

type Actions = typeof actions;

const Store = createStore<State, Actions>({
	name: 'user',
	initialState,
	actions,
});

export const UserContainer = createContainer<State, Actions, ContainerProps>(Store);

export const useUserSubscriber = createHook(Store);
