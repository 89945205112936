import { createResource } from '@atlassian/jira-router';
import { getIssueKey } from '@atlassian/jira-router-resources-utils';
import { withResourceEarlyData } from '../../utils';
import getData from './get-data';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const issueGiraNonCriticalDataResource = createResource<any>(
	withResourceEarlyData({
		type: 'ISSUE_GIRA_NON_CRITICAL_DATA',
		getKey: ({ match }) => {
			const issueKey = getIssueKey(match);
			return issueKey || '';
		},
		getData: (...args) => getData(...args),
		isBrowserOnly: true,
	}),
);
