import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';
import type { Route } from '@atlassian/jira-router';

export const planIncrementsRoute: Route = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_PLAN_INCREMENTS,
	path: '/jira/plans/:planId/scenarios/:scenarioId/program',
};

export const planIncrementRoute: Route = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_PLAN_INCREMENT,
	path: '/jira/plans/:planId/scenarios/:scenarioId/program/:incrementId',
};

export const archivedPlansRoute: Route = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.ARCHIVED_PLANS_DIRECTORY,
	path: '/jira/plans/settings/archive',
};

export const trashedPlansRoute: Route = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.TRASH_PLANS_DIRECTORY,
	path: '/jira/plans/settings/trash',
};

export const trashedPlanDirectLinkRoute: Route = {
	group: ROUTE_GROUPS.ERRORS,
	name: ROUTE_NAMES.TRASHED_PLAN_DIRECT_LINK,
	path: '/jira/plans/plan-trashed',
	exact: true,
};

export const arjSettingsFinancialYearPageRoute: Route = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_ARJ_SETTINGS_FINANCIAL_YEAR,
	path: '/jira/plans/settings/financial-year',
};
