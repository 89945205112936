import { createResource } from '@atlassian/jira-router';
import { RESOURCE_TYPE_CUSTOMER_PERMISSIONS } from '../../constants';
import type { RequestSecuritySettingsResponse } from './get-data';

export const customerPermissionsPageDataResource = createResource<RequestSecuritySettingsResponse>({
	type: RESOURCE_TYPE_CUSTOMER_PERMISSIONS,
	getKey: ({ match }) => match.params.projectKey || '',
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-customer-permissions", jiraSpaEntry: "async-resource-customer-permissions" */ './get-data'
		),
	maxAge: Infinity,
});

export type { RequestSecuritySettingsResponse } from './get-data';
