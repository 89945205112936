import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { ROUTE_GROUPS } from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarServiceDesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info';
import {
	routeGroupsProjectSettingsServicedeskAutomationEditRuleRoute,
	routeGroupsProjectSettingsServicedeskAutomationCopyRuleRoute,
	routeGroupsProjectSettingsServicedeskAutomationCreateRoute,
	routeGroupsProjectSettingsServicedeskAutomationViewLogRoute,
	routeGroupsProjectSettingsServicedeskAutomationSummaryRoute,
} from '@atlassian/jira-router-routes-project-settings-service-desk-automation-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import { getSettingsNavigationResources, SERVICE_DESK_PRODUCT } from './ui/product-routes/common';
import ServiceDeskAutomationCopyRule, {
	LazyProjectSettingsServiceDeskAutomationCopyRule,
} from './ui/service-desk-automation/copy-rule';
import ServiceDeskAutomationCreateRule, {
	LazyProjectSettingsServiceDeskAutomationCreateRule,
} from './ui/service-desk-automation/create-rule';
import ServiceDeskAutomationEditRule, {
	LazyProjectSettingsServiceDeskAutomationEditRule,
} from './ui/service-desk-automation/edit-rule';
import {
	oldAutomationPathFragment,
	automationRedirect,
	type AutomationPageType,
} from './ui/service-desk-automation/redirect';
import ServiceDeskAutomationSummary, {
	LazyProjectSettingsServiceDeskAutomationSummary,
} from './ui/service-desk-automation/summary';
import ServiceDeskAutomationViewLog, {
	LazyProjectSettingsServiceDeskAutomationViewLog,
} from './ui/service-desk-automation/view-log';

const getLegacyAutomationRoutesCommon = () => ({
	layout: serviceProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServiceDesk,
	},
	resources: [
		...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
		getConsolidationStateResource(),
	],
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK,
});

const getOldAutomationRouteRedirectRoute = (pageType: AutomationPageType): Route => ({
	...getLegacyAutomationRoutesCommon(),
	name: `${ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK}-automation-redirect-${pageType}`,
	path: `/projects/:projectKey/settings/automation/${oldAutomationPathFragment(pageType)}`,
	forPaint: [],
	component: automationRedirect(pageType),
});

export const legacyAutomationRouteEntries: Route[] = [
	createEntry(routeGroupsProjectSettingsServicedeskAutomationEditRuleRoute, {
		...getLegacyAutomationRoutesCommon(),
		ufoName: 'service-management.automation.edit-rule',
		component: ServiceDeskAutomationEditRule,
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskAutomationEditRule],
	}),
	createEntry(routeGroupsProjectSettingsServicedeskAutomationCopyRuleRoute, {
		...getLegacyAutomationRoutesCommon(),
		ufoName: 'service-management.automation.copy-rule',
		component: ServiceDeskAutomationCopyRule,
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskAutomationCopyRule],
	}),
	createEntry(routeGroupsProjectSettingsServicedeskAutomationCreateRoute, {
		...getLegacyAutomationRoutesCommon(),
		ufoName: 'service-management.automation.create-rule',
		component: ServiceDeskAutomationCreateRule,
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskAutomationCreateRule],
	}),
	createEntry(routeGroupsProjectSettingsServicedeskAutomationViewLogRoute, {
		...getLegacyAutomationRoutesCommon(),
		ufoName: 'service-management.automation.log',
		component: ServiceDeskAutomationViewLog,
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskAutomationViewLog],
	}),
	createEntry(routeGroupsProjectSettingsServicedeskAutomationSummaryRoute, {
		...getLegacyAutomationRoutesCommon(),
		ufoName: 'service-management.automation.summary',
		component: ServiceDeskAutomationSummary,
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskAutomationSummary],
	}),

	/* Old urls.
	 * Do not remove these redirects, ever.
	 * Because customers will have bookmarks and saved links and we need them to keep working forever.
	 */
	getOldAutomationRouteRedirectRoute('summary'),
	getOldAutomationRouteRedirectRoute('create'),
	getOldAutomationRouteRedirectRoute('edit'),
	getOldAutomationRouteRedirectRoute('copy'),
	getOldAutomationRouteRedirectRoute('viewlog'),
];
