import type { ProductFeatures } from '@atlassian/jira-project-settings-apps-issue-type-page-product-features';
import {
	JSD_IS_EMAIL_REQUEST_TYPE,
	JSD_ISSUE_TYPE_HELPTEXT,
} from '@atlassian/jira-project-settings-apps-issue-type-page/src/model/properties';

const getAllPropertiesKeys = (features: ProductFeatures) => {
	const { issueTypeHelpText } = features;

	const allPropertiesKeys: string[] = [JSD_IS_EMAIL_REQUEST_TYPE, JSD_ISSUE_TYPE_HELPTEXT];

	return issueTypeHelpText ? allPropertiesKeys : [];
};

export { getAllPropertiesKeys };
