import type {
	ComponentType,
	ForwardRefExoticComponent,
	PropsWithoutRef,
	RefAttributes,
} from 'react';
import { withAnalyticsContext, type WithContextProps } from '@atlaskit/analytics-next';
import type { ScreenType } from '../utils/types';

type ContextResult<C, P> = ForwardRefExoticComponent<
	PropsWithoutRef<JSX.LibraryManagedAttributes<C, P & WithContextProps>> & RefAttributes<C>
>;

export const AnalyticsSource =
	(name: string, type: ScreenType) =>
	<C extends ComponentType<P>, P = React.ComponentProps<C>>(
		WrappedComponent: C,
	): ContextResult<C, P> =>
		withAnalyticsContext({ source: `${name}${type}` })<P, C>(WrappedComponent);

export const AnalyticsSubject =
	(
		name: string,
		attributes?: Record<any, any>, // eslint-disable-line @typescript-eslint/no-explicit-any
	) =>
	<C extends ComponentType<P>, P = React.ComponentProps<C>>(
		WrappedComponent: C,
	): ContextResult<C, P> =>
		withAnalyticsContext({
			actionSubject: name,
			attributes,
		})<P, C>(WrappedComponent);
