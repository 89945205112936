import queryString from 'query-string';
import type { Location } from '@atlassian/jira-router';

const PLAN = 'planning';
const REPORTING = 'reporting';
const REPORTS = 'reports';

type NextGenQuery = {
	rapidView: string;
	projectKey: string;
	view?: string;
	report?: string;
};

const constructUrlForView = (url: string, view: string, searchResult: NextGenQuery) => {
	if (view.startsWith(PLAN)) {
		return `${url}/backlog`;
	}
	if (view.startsWith(REPORTING) || view.startsWith(REPORTS)) {
		if (searchResult.report) {
			return `${url}/reports/${searchResult.report}`;
		}
		return `${url}/reports`;
	}

	return url;
};

const KNOWN_KEYS = ['rapidView', 'projectKey', 'view', 'report'];

const forwardUnknownQueryParams = (url: string, queryParams: NextGenQuery) => {
	const query = queryString.stringify(
		Object.entries(queryParams)
			.filter(([k]) => !KNOWN_KEYS.includes(k)) // eslint-disable-next-line @typescript-eslint/no-explicit-any
			.reduce<Record<string, any>>(
				(result, [k, v]) => ({
					[k]: v,
					// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
					...result,
				}),
				{},
			),
	);
	if (query) {
		return `${url}?${query}`;
	}

	return url;
};

export const pickNextGenView = (location: Location) => {
	const searchResult: NextGenQuery = queryString.parse(location.search);

	const url = `/jira/software/projects/${searchResult.projectKey}/boards/${searchResult.rapidView}`;

	if (searchResult.view) {
		return forwardUnknownQueryParams(
			constructUrlForView(url, searchResult.view, searchResult),
			searchResult,
		);
	}

	return forwardUnknownQueryParams(url, searchResult);
};
