import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type JiraBusinessOverviewSummaryAppType from '@atlassian/jira-spa-apps-business-overview-summary';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyOverviewSummaryView = lazyForPaint<typeof JiraBusinessOverviewSummaryAppType>(
	() =>
		import(
			/* webpackChunkName: "async-business-overview-summary", jiraSpaEntry: "async-business-overview-summary" */ '@atlassian/jira-spa-apps-business-overview-summary'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyOverviewSummaryView}
		pageId="async-business-overview-summary"
		shouldShowSpinner
	/>
);
