import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin';
import { ff } from '@atlassian/jira-feature-flagging';
import srcVirtualAgentBrowseStandardFlowsQuery from '@atlassian/jira-relay/src/__generated__/srcVirtualAgentBrowseStandardFlowsQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const browseStandardFlowsEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-virtual-agent-browse-standard-flows" */ './src'),
	),
	getPreloadProps: ({ context, tenantContext }: EntryPointRouteParams) => ({
		queries: {
			page: {
				parameters: srcVirtualAgentBrowseStandardFlowsQuery,
				variables: {
					cloudId: tenantContext.cloudId,
					projectKey: context.match.params.projectKey ?? '',
					checkForAiEnabledFF: ff('check-for-ai-enabling-in-get-premium-banner_oxh6r'),
				},
			},
		},
	}),
});
