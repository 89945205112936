import { setProductBundleReadyForInitialRender } from '@atlassian/jira-spa-performance-breakdown';

export const markAsProductBundleReady = <T,>(importedBundle: Promise<T>): Promise<T> => {
	if (!__SERVER__) {
		importedBundle.then(() => {
			setProductBundleReadyForInitialRender();
		});
	}
	return importedBundle;
};
