import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { ViewsBaseNav } from './ui';

const LazyViewsNav = lazyForPaint<typeof ViewsBaseNav>(() =>
	import(/* webpackChunkName: "async-jsm-views-nav" */ './ui').then(
		({ ViewsBaseNav }) => ViewsBaseNav,
	),
);

export const AsyncViewsNav = () => (
	<Placeholder name="horizontal-jsm-views-nav" fallback={null}>
		<LazyViewsNav />
	</Placeholder>
);
