import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';
import type { Route } from '@atlassian/jira-router';

export const softwareIncidentsNextGenRoute: Route = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_INCIDENTS,
	path: '/jira/software/projects/:projectKey/incidents',
};

export const softwareIncidentsClassicRoute: Route = {
	group: ROUTE_GROUPS.CLASSIC_SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_INCIDENTS_CLASSIC,
	path: '/jira/software/c/projects/:projectKey/incidents',
};
