/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
// eslint-disable-next-line jira/import-whitelist
import { ff } from '@atlassian/jira-feature-flagging';
import { AppSkeletonImage } from '../../common/ui';
import imgSVGNavRefresh from './assets/roadmap-app-skeleton-nav-refresh.tpl.svg';
import imgSVG from './assets/roadmap-app-skeleton.tpl.svg';

const customCssStyles = css({
	// increase self specificity to ensure deterministic override
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		borderRightWidth: 0,
	},
});

export const RoadmapSkeleton = () =>
	ff('sea-2744-jsw-tabs-nav') ? (
		<AppSkeletonImage src={imgSVGNavRefresh} css={customCssStyles} />
	) : (
		<AppSkeletonImage src={imgSVG} css={customCssStyles} />
	);
