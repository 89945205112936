import React from 'react';
import AppBase from '@atlassian/jira-app-base';
import { PerformanceMark } from '@atlassian/jira-common-performance';
import type { MatchedRoute, Route } from '@atlassian/jira-router';
import { setInitialPageLoadTimingFromPerformanceMarks } from '@atlassian/jira-spa-performance-breakdown';
import View from './view';

export type Props = {
	initRoute: MatchedRoute | null;
	routes: Route[];
};

const onSpaShellReady = () => {
	setInitialPageLoadTimingFromPerformanceMarks(
		'jira-spa/spa-shell/core',
		'_jira-spa/spa-shell.start',
		'JIRA_SPA_SHELL_READY',
	);
};

const Spa = ({ initRoute = null, routes }: Props) => (
	<AppBase hasErrorBoundary={false}>
		<PerformanceMark metricKey="JIRA_SPA_SHELL_READY" onSet={onSpaShellReady} />
		<View initRoute={initRoute} routes={routes} />
	</AppBase>
);

export default Spa;
