import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type { InsightsSettingsCMPPageLoader } from '@atlassian/jira-spa-apps-classic-board-settings-insights';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyInsights = lazyForPaint<typeof InsightsSettingsCMPPageLoader>(() =>
	import(
		/* webpackChunkName: "async-board-settings-insights", jiraSpaEntry:"async-board-settings-insights" */
		'@atlassian/jira-spa-apps-classic-board-settings-insights'
	).then((module) => module.InsightsSettingsCMPPageLoader),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyInsights}
		pageId="classic-board-settings-insights"
		shouldShowSpinner={false}
	/>
);
