import React, { memo } from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import {
	setPerformanceMarkLoadStart,
	setPerformanceMarkLoadEnd,
} from '@atlassian/jira-navigation-apps-sidebar-common';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-common/async';
import type GlobalProjectsSettingsSidebarType from './index';

const LazyGlobalProjectsSettingsSidebar = lazyAfterPaint<typeof GlobalProjectsSettingsSidebarType>(
	() => {
		setPerformanceMarkLoadStart();
		return import(/* webpackChunkName: "async-sidebar-global-settings-projects" */ './index').then(
			(bundle) => {
				setPerformanceMarkLoadEnd();
				return bundle;
			},
		);
	},
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const GlobalProjectsSettingsSidebar = memo<Record<any, any>>(() => (
	<SidebarBoundary id="globalProjectsSettings">
		<LazyGlobalProjectsSettingsSidebar />
	</SidebarBoundary>
));

GlobalProjectsSettingsSidebar.displayName = 'GlobalProjectsSettingsSidebar';

export default GlobalProjectsSettingsSidebar;
