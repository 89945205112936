import queryString from 'query-string';
import type { IssueTypeResourceData } from '@atlassian/jira-admin-pages-issue-types-common';
// eslint-disable-next-line jira/restricted/@atlassian/jira-router
import { createResource } from '@atlassian/jira-router';
import { getIssueTypes } from '../../services/issue-types';

// Used for fetching during SSR
export const issueTypesResource = createResource<IssueTypeResourceData>({
	type: 'GLOBAL_ADMIN_ISSUE_TYPES_DIRECTORY',
	getKey: () => 'issue types',
	getData: async ({ query }) => getIssueTypes(queryString.stringify(query)),
});
