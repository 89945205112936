import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ArchivedProjectsAdminType from '@atlassian/jira-spa-apps-archived-projects';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyArchivedProjectsAdmin = lazyForPaint<typeof ArchivedProjectsAdminType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-archived-projects", jiraSpaEntry: "async-archived-projects" */ '@atlassian/jira-spa-apps-archived-projects'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazyArchivedProjectsAdmin} pageId="archived-projects" shouldShowSpinner />
);
