import React from 'react';
import { Redirect, useRouter, useQueryParam } from '@atlassian/jira-router';

export const InlineRedirect = ({ to }: { to: string }) => (
	<script
		// eslint-disable-next-line react/no-danger
		dangerouslySetInnerHTML={{
			__html: `window.location.assign("${to}")`,
		}}
	/>
);
InlineRedirect.displayName = 'InlineRedirect';
/**
 * Facilitates user redirection to the login page, with special handling for embedded pages and specific scenarios to ensure a smooth post-login experience. It constructs the appropriate redirect URL based on the current and target pages, taking into account special cases such as embedded issue creation.
 */
export const RedirectToLoginPage = () => {
	const [
		{
			location: { pathname, search },
		},
	] = useRouter();
	const redirectUrl = pathname + search;
	const encodedRedirectUrl = encodeURIComponent(redirectUrl);
	let path = `/login.jsp?os_destination=${encodedRedirectUrl}`;
	const parentProductQueryName = 'parentProduct';
	const [parentProduct] = useQueryParam(parentProductQueryName);
	if (pathname.endsWith('/embed')) {
		const searchParams = new URLSearchParams(search);
		// We need to remove parentProduct param as it is only used in embed urls
		searchParams.delete(parentProductQueryName);
		/**
		 * encodedLink is the link to the non embedded version of the embed url.
		 * So, we strip out the /embed suffix from path
		 */
		let encodedLink = encodeURIComponent(`${pathname.slice(0, -6)}?${searchParams}`);
		/**
		 * this is the specific case for create issue embed, where we need to redirect
		 * user to your-work page with create issue as true to open GIC by default.
		 * We will remove this when we FPIC exp implemented for GIC
		 */
		if (pathname.includes('/issues/create')) {
			encodedLink = encodeURIComponent('/jira/your-work?createissue=true');
		}
		const encodedParentProduct = encodeURIComponent(parentProduct || '');
		path = `/jira-login/embed?destination=${encodedRedirectUrl}&link=${encodedLink}&parentProduct=${encodedParentProduct}`;
	}
	return __SERVER__ ? <InlineRedirect to={path} /> : <Redirect to={path} />;
};
