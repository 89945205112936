/**
 * @generated SignedSource<<b8551376641e6166be1e2d86bd00b040>>
 * @relayHash 5db959bf0a939ed80fba352ae388f0e3
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 36bc2fc319728c22ee4d8b6c09fd795713f59dc760252cfcd48aa96dbc645a88

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
export type JiraVersionSortField = "DESCRIPTION" | "NAME" | "RELEASE_DATE" | "SEQUENCE" | "START_DATE" | "%future added value";
export type JiraVersionStatus = "ARCHIVED" | "RELEASED" | "UNRELEASED" | "%future added value";
export type SortDirection = "ASC" | "DESC" | "%future added value";
export type JiraVersionSortInput = {
  order: SortDirection;
  sortByField: JiraVersionSortField;
};
export type uiProjectVersionsQuery$variables = {
  cloudId: string;
  filter: ReadonlyArray<JiraVersionStatus | null | undefined>;
  initialLoadCount: number;
  projectKey: string;
  searchString: string;
  sortBy: JiraVersionSortInput;
};
export type uiProjectVersionsQuery$data = {
  readonly jira: {
    readonly jiraProjectByKey: {
      readonly jiraProjectType: JiraProjectType | null | undefined;
      readonly key: string;
      readonly name: string;
      readonly projectId: string;
      readonly versions: {
        readonly __id: string;
        readonly edges: ReadonlyArray<{
          readonly __typename: "JiraVersionEdge";
        } | null | undefined> | null | undefined;
        readonly errors: ReadonlyArray<{
          readonly extensions: ReadonlyArray<{
            readonly statusCode: number | null | undefined;
          }> | null | undefined;
        }> | null | undefined;
      };
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"table_projectVersions">;
};
export type uiProjectVersionsQuery = {
  response: uiProjectVersionsQuery$data;
  variables: uiProjectVersionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "initialLoadCount"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKey"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchString"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortBy"
},
v6 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "projectKey"
  }
],
v7 = {
  "kind": "ScalarField",
  "name": "projectId"
},
v8 = {
  "kind": "ScalarField",
  "name": "name"
},
v9 = {
  "kind": "ScalarField",
  "name": "key"
},
v10 = {
  "alias": "jiraProjectType",
  "kind": "ScalarField",
  "name": "projectType"
},
v11 = {
  "kind": "Variable",
  "name": "filter",
  "variableName": "filter"
},
v12 = {
  "kind": "Variable",
  "name": "searchString",
  "variableName": "searchString"
},
v13 = {
  "kind": "Variable",
  "name": "sortBy",
  "variableName": "sortBy"
},
v14 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v15 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v16 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v17 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "endCursor"
    },
    {
      "kind": "ScalarField",
      "name": "hasNextPage"
    }
  ]
},
v18 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v19 = [
  (v11/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "initialLoadCount"
  },
  (v12/*: any*/),
  (v13/*: any*/)
],
v20 = {
  "kind": "ScalarField",
  "name": "id"
},
v21 = {
  "kind": "ScalarField",
  "name": "totalCount"
},
v22 = [
  (v21/*: any*/)
],
v23 = [
  {
    "kind": "Literal",
    "name": "filter",
    "value": "DONE"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "name": "uiProjectVersionsQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v6/*: any*/),
                "concreteType": "JiraProject",
                "kind": "LinkedField",
                "name": "jiraProjectByKey",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v7/*: any*/),
                    "action": "THROW",
                    "path": "jira.jiraProjectByKey.projectId"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v8/*: any*/),
                    "action": "THROW",
                    "path": "jira.jiraProjectByKey.name"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v9/*: any*/),
                    "action": "THROW",
                    "path": "jira.jiraProjectByKey.key"
                  },
                  (v10/*: any*/),
                  {
                    "kind": "RequiredField",
                    "field": {
                      "alias": "versions",
                      "args": [
                        (v11/*: any*/),
                        (v12/*: any*/),
                        (v13/*: any*/)
                      ],
                      "concreteType": "JiraVersionConnection",
                      "kind": "LinkedField",
                      "name": "__uiProject__versions_connection",
                      "plural": false,
                      "selections": [
                        {
                          "concreteType": "JiraVersionEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            (v14/*: any*/),
                            (v15/*: any*/),
                            {
                              "concreteType": "JiraVersion",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v14/*: any*/)
                              ]
                            }
                          ]
                        },
                        {
                          "concreteType": "QueryError",
                          "kind": "LinkedField",
                          "name": "errors",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "name": "extensions",
                              "plural": true,
                              "selections": [
                                (v16/*: any*/)
                              ]
                            }
                          ]
                        },
                        (v17/*: any*/),
                        (v18/*: any*/)
                      ]
                    },
                    "action": "THROW",
                    "path": "jira.jiraProjectByKey.versions"
                  }
                ]
              },
              "action": "THROW",
              "path": "jira.jiraProjectByKey"
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      },
      {
        "args": (v19/*: any*/),
        "kind": "FragmentSpread",
        "name": "table_projectVersions"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "uiProjectVersionsQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v6/*: any*/),
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "jiraProjectByKey",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "args": (v19/*: any*/),
                "concreteType": "JiraVersionConnection",
                "kind": "LinkedField",
                "name": "versions",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraVersionEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v14/*: any*/),
                      (v15/*: any*/),
                      {
                        "concreteType": "JiraVersion",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v14/*: any*/),
                          (v20/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "status"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "description"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "startDate"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "releaseDate"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "overdue"
                          },
                          {
                            "alias": "allIssues",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "filter",
                                "value": "ALL"
                              }
                            ],
                            "concreteType": "JiraIssueConnection",
                            "kind": "LinkedField",
                            "name": "issues",
                            "plural": false,
                            "selections": (v22/*: any*/),
                            "storageKey": "issues(filter:\"ALL\")"
                          },
                          {
                            "alias": "doneIssues",
                            "args": (v23/*: any*/),
                            "concreteType": "JiraIssueConnection",
                            "kind": "LinkedField",
                            "name": "issues",
                            "plural": false,
                            "selections": (v22/*: any*/),
                            "storageKey": "issues(filter:\"DONE\")"
                          },
                          {
                            "alias": "done",
                            "args": (v23/*: any*/),
                            "concreteType": "JiraIssueConnection",
                            "kind": "LinkedField",
                            "name": "issues",
                            "plural": false,
                            "selections": (v22/*: any*/),
                            "storageKey": "issues(filter:\"DONE\")"
                          },
                          {
                            "alias": "inProgress",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "filter",
                                "value": "IN_PROGRESS"
                              }
                            ],
                            "concreteType": "JiraIssueConnection",
                            "kind": "LinkedField",
                            "name": "issues",
                            "plural": false,
                            "selections": (v22/*: any*/),
                            "storageKey": "issues(filter:\"IN_PROGRESS\")"
                          },
                          {
                            "alias": "toDo",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "filter",
                                "value": "TODO"
                              }
                            ],
                            "concreteType": "JiraIssueConnection",
                            "kind": "LinkedField",
                            "name": "issues",
                            "plural": false,
                            "selections": (v22/*: any*/),
                            "storageKey": "issues(filter:\"TODO\")"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "versionId"
                          },
                          (v8/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "hasReleaseNote"
                          },
                          (v18/*: any*/)
                        ]
                      }
                    ]
                  },
                  {
                    "concreteType": "QueryError",
                    "kind": "LinkedField",
                    "name": "errors",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "extensions",
                        "plural": true,
                        "selections": [
                          (v14/*: any*/),
                          (v16/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v21/*: any*/)
                ]
              },
              {
                "args": (v19/*: any*/),
                "filters": [
                  "filter",
                  "searchString",
                  "sortBy"
                ],
                "handle": "connection",
                "key": "uiProject__versions",
                "kind": "LinkedHandle",
                "name": "versions"
              },
              (v20/*: any*/),
              {
                "kind": "ScalarField",
                "name": "projectType"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "36bc2fc319728c22ee4d8b6c09fd795713f59dc760252cfcd48aa96dbc645a88",
    "metadata": {
      "connection": [
        {
          "count": "initialLoadCount",
          "cursor": null,
          "direction": "forward",
          "path": [
            "jira",
            "jiraProjectByKey",
            "versions"
          ]
        }
      ]
    },
    "name": "uiProjectVersionsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "3bed4070fd244faac23cfc6a62e64f6c";

export default node;
