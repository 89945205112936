/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { AppSkeletonImage } from '../../common/ui';
import imgSVG from './assets/next-gen-board-app-skeleton.tpl.svg';

const customCssStyles = css({
	// increase self specificity to ensure deterministic override
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		borderRightWidth: 0,
	},
});

export const NextGenBoardSkeleton = () => <AppSkeletonImage src={imgSVG} css={customCssStyles} />;
