import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';
import { NORMAL_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets';

export const browseIssueRoute = {
	group: ROUTE_GROUPS.ISSUE,
	name: ROUTE_NAMES.ISSUE,
	path: '/browse/:issueKey(\\w+-\\d+)(.*)?',
	query: ['filter?!=(.*)', 'jql?!=(.*)'],
	preloadOptions: {
		earlyChunks: ['issue-app-early-entry'],
	},
	earlyAssets: NORMAL_EARLY_ASSET_STRATEGY,
};

export const browseIssueEmbedRoute = {
	group: ROUTE_GROUPS.ISSUE,
	name: ROUTE_NAMES.ISSUE_EMBED,
	path: '/browse/:issueKey(\\w+-\\d+)/embed',
	preloadOptions: {
		earlyChunks: ['issue-app-early-entry'],
	},
	earlyAssets: NORMAL_EARLY_ASSET_STRATEGY,
};
