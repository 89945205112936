import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common';
import {
	servicedeskServicesRoute,
	servicedeskServiceDetailsRoute,
	servicedeskServiceModalNewRoute,
	servicedeskServiceModalEditRoute,
	serviceDeskServicesWithoutProjectRoute,
	serviceDeskServiceDetailsWithoutProjectRoute,
} from '@atlassian/jira-router-routes-servicedesk-services-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import ServiceCreateModalWithoutProjectContext, {
	LazyServiceCreateModalWithProjectContext,
} from './ui/service-create-modal-without-project-context';
import ServiceDetailsWithProjectContext, {
	LazyServicedeskServiceDetailsWithProjectContext,
} from './ui/service-details-with-project-context';
import ServicedeskServiceDetailsWithoutProjectContext, {
	LazyServicedeskServiceDetailsWithoutProjectContext,
} from './ui/service-details-without-project-context';
import ServiceUpdateModalWithoutProjectContext, {
	LazyServiceUpdateModalWithProjectContext,
} from './ui/service-update-modal-without-project-context';
import ServiceDeskServicesWithProjectContext, {
	LazyServicedeskServicesWithProjectContext,
} from './ui/services-with-project-context';
import ServiceDeskServicesWithoutProjectContext, {
	LazyServicedeskServicesWithoutProjectContext,
} from './ui/services-without-project-context';

export const servicesWithProjectContextRouteEntries: Route[] = [
	createEntry(servicedeskServicesRoute, {
		ufoName: 'service-management.services',
		component: ServiceDeskServicesWithProjectContext,
		layout: serviceProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

		resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			getConsolidationStateResource(),
		],

		forPaint: [LazyAtlassianNavigation, LazyServicedeskServicesWithProjectContext],
	}),
	createEntry(servicedeskServiceDetailsRoute, {
		ufoName: 'service-management.service-details',
		component: ServiceDetailsWithProjectContext,
		layout: serviceProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

		resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			getConsolidationStateResource(),
		],

		forPaint: [LazyAtlassianNavigation, LazyServicedeskServiceDetailsWithProjectContext],
	}),
	createEntry(serviceDeskServicesWithoutProjectRoute, {
		ufoName: 'service-management.services-without-project',
		component: ServiceDeskServicesWithoutProjectContext,

		resources: [...getNavigationResources(), getConsolidationStateResource()],

		forPaint: [LazyAtlassianNavigation, LazyServicedeskServicesWithoutProjectContext],
	}),
	createEntry(serviceDeskServiceDetailsWithoutProjectRoute, {
		ufoName: 'service-management.service-details-without-project',
		component: ServicedeskServiceDetailsWithoutProjectContext,

		resources: [...getNavigationResources(), getConsolidationStateResource()],

		forPaint: [LazyAtlassianNavigation, LazyServicedeskServiceDetailsWithoutProjectContext],
	}),
];

export const serviceModalWithoutProjectContextRouteEntries: Route[] = [
	createEntry(servicedeskServiceModalNewRoute, {
		component: ServiceCreateModalWithoutProjectContext,
		layout: serviceProjectLayout,

		resources: [...getServiceDeskNavigationResources(), ...getServicedeskPageContextResources()],

		forPaint: [LazyAtlassianNavigation, LazyServiceCreateModalWithProjectContext],
	}),
	createEntry(servicedeskServiceModalEditRoute, {
		component: ServiceUpdateModalWithoutProjectContext,
		layout: serviceProjectLayout,

		resources: [...getServiceDeskNavigationResources(), ...getServicedeskPageContextResources()],

		forPaint: [LazyAtlassianNavigation, LazyServiceUpdateModalWithProjectContext],
	}),
];

export const servicesRouteEntries: Route[] = [
	...servicesWithProjectContextRouteEntries,
	...serviceModalWithoutProjectContextRouteEntries,
];
