import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const jiraBusinessBoardRoute = {
	group: ROUTE_GROUPS.CLASSIC_BUSINESS,
	name: ROUTE_NAMES.CLASSIC_BUSINESS_PROJECT_BOARD,
	ufoName: 'jwm.jwm-board',
	exact: true,
	path: '/jira/core/projects/:projectKey/board',
	query: ['issue-key?'],
};
