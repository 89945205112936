import { Network } from 'relay-runtime';
import { fetch } from '@atlassian/jira-relay-fetch';
import { subscribe } from '@atlassian/jira-relay-subscription';
import { isSSR } from './constants';

export const getNetwork = () => {
	if (isSSR()) {
		Network.create(fetch);
	}
	return Network.create(fetch, subscribe);
};
