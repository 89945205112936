import { isValidElement } from 'react';
import {
	type Action as ReduxAction,
	type Store as StoreRedux,
	type StoreEnhancer,
	type Reducer,
	type Middleware,
	type PreloadedState,
	applyMiddleware,
	createStore,
	compose,
} from 'redux';
import { enableBatching, type BatchAction as BatchActionImported } from 'redux-batched-actions';
import { type Epic, createEpicMiddleware } from 'redux-observable';
import { debugEnabled } from '@atlassian/jira-common-debug';
import composeWithDevtools from '@atlassian/jira-common-util-compose-with-devtools';
import ActionListenerMiddleware from './action-listener-middleware';

type Params<State, Action, Dependencies> = {
	appName: string;
	rootReducer: Reducer<State>;
	initialState?: PreloadedState<State>;
	rootEpic?: Epic<Action, State>;
	rootEpicDependencies?: Dependencies;
	middlewares?: Middleware[];
	enhancers?: StoreEnhancer[];
};
export type BatchAction = BatchActionImported;

type ActionListeners = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	removeActionListener: (...args: any[]) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	addActionListener: (...args: any[]) => void;
};

export type Store<TState> = StoreRedux<TState> & ActionListeners;

/**
 * Initializes a Redux store with configurations for state management and middleware integration.
 * It addresses serialization challenges with non-serializable entities and enhances debugging capabilities.
 * The custom action listener middleware offers additional control over action dispatches.
 */
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default <State, Action extends ReduxAction, Dependencies>({
	appName,
	rootReducer,
	initialState,
	rootEpic,
	rootEpicDependencies,
	middlewares = [],
	enhancers = [],
}: Params<State, Action, Dependencies>): Store<State> => {
	const actionListenerMiddleware = new ActionListenerMiddleware<Action, State>();
	middlewares.push(actionListenerMiddleware.perform);
	if (debugEnabled('redux-logger')) {
		// include logger only for development environment

		// eslint-disable-next-line @typescript-eslint/no-require-imports, @typescript-eslint/no-var-requires
		const { logger } = require('redux-logger');
		middlewares.push(logger);
	}
	if (rootEpic) {
		middlewares.push(
			createEpicMiddleware<Action, State>(
				rootEpic,
				rootEpicDependencies
					? {
							dependencies: rootEpicDependencies,
						}
					: undefined,
			),
		);
	}
	const store: Store<State> = createStore(
		enableBatching(rootReducer),
		initialState,
		composeWithDevtools({
			name: appName,
			// @ts-expect-error - TS2345 - Argument of type '{ name: string; serialize: { replacer: (key: any, value: any) => any; }; }' is not assignable to parameter of type 'DevtoolsConfig'.
			serialize: {
				// Replacer function is necessary to fix redux-devtools because some actions and state are React Elements
				// and the default serialization gets into a loop in a circular dependency somewhere in internal React properties.
				// https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md#serialize
				// @ts-expect-error - TS7006 - Parameter 'key' implicitly has an 'any' type. | TS7006 - Parameter 'value' implicitly has an 'any' type.
				replacer: (key, value) => {
					if (isValidElement(value)) {
						// @ts-expect-error - TS2339 - Property 'displayName' does not exist on type 'string | JSXElementConstructor<any>'.
						if (value.type && value.type.displayName) {
							// @ts-expect-error - TS2339 - Property 'displayName' does not exist on type 'string | JSXElementConstructor<any>'.
							return `React Element <${value.type.displayName}>`;
						}
						return 'React Element';
					}
					// The benefit of using React + Redux + declarative API is that there is no more need to save DOM elements in store!
					// ... yet we still do. So when that happens, Redux devtools sometimes crashes because it tries to serialize circular DOM structure.
					if (typeof HTMLElement !== 'undefined' && value instanceof HTMLElement) {
						return `HTMLElement<${value.tagName}>`;
					}
					return value;
				},
			},
		})(compose(...enhancers, applyMiddleware(...middlewares))),
	);

	store.addActionListener = actionListenerMiddleware.add;
	store.removeActionListener = actionListenerMiddleware.remove;
	return store;
};
