import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const globalSettingsIssueHierarchyRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.GLOBAL_SETTINGS_ISSUE_HIERARCHY,
	path: '/jira/settings/issues/issue-hierarchy',
};

export const globalSettingsIssueHierarchyRedirectRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.GLOBAL_SETTINGS_ISSUE_HIERARCHY_REDIRECT,
	path: '/secure/PortfolioHierarchy.jspa',
};

export const globalSettingsIssueTypesRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.GLOBAL_SETTINGS_ISSUE_TYPES,
	path: '/jira/settings/issues/issue-types',
};

export const globalSettingsIssueTypesSchemesRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.GLOBAL_SETTINGS_ISSUE_TYPES_SCHEMES,
	path: '/jira/settings/issues/issue-type-schemes',
};
