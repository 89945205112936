import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ProjectSettingsServiceDeskAutomationCreateRuleType from '@atlassian/jira-spa-apps-project-settings-service-desk-automation-create-rule';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyProjectSettingsServiceDeskAutomationCreateRule = lazyForPaint<
	typeof ProjectSettingsServiceDeskAutomationCreateRuleType
>(
	() =>
		import(
			/* webpackChunkName: "async-project-settings-service-desk-automation-create-rule", jiraSpaEntry: "async-project-settings-service-desk-automation-create-rule" */ '@atlassian/jira-spa-apps-project-settings-service-desk-automation-create-rule'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyProjectSettingsServiceDeskAutomationCreateRule}
		pageId="project-settings-automation-create-rule"
		shouldShowSpinner
	/>
);
