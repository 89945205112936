import { useEffect, useMemo } from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import {
	useIncomingMailExpiryStatus,
	DEFAULT_RESPONSE,
} from '@atlassian/jira-router-resources-incoming-mail-expiry-status';
import { BANNER_NAME, HEIGHT } from '../../constants';
import { useBannerActions, useBannerState } from '../use-banner-store';

export const useBannerMailExpiry = () => {
	const { responseStatus, state } = useIncomingMailExpiryStatus();
	const { onStateFetch } = useBannerActions();
	const { isBannerShown } = useBannerState();

	useEffect(() => {
		if (!ff('jira-banner-for-incoming-mail-expiry-notification_y2w1w')) {
			onStateFetch({ isAdmin: false, showBanner: false, OauthTypes: ['GMAIL_OAUTH'] });
			return;
		}

		if (responseStatus === 'error') {
			onStateFetch(DEFAULT_RESPONSE);
			return;
		}

		if (responseStatus === 'success') {
			onStateFetch(state);
		}
	}, [onStateFetch, responseStatus, state]);

	return useMemo(
		() => ({
			isEnabled: isBannerShown,
			name: BANNER_NAME,
			height: HEIGHT,
		}),
		[isBannerShown],
	);
};
