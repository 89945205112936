/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { AppSkeletonImage } from '../../../common/ui';
import browseConversationsSkeletonSVG from './assets/virtual-agent-browse-conversations-app-skeleton.tpl.svg';

const customStyles = css({
	margin: '0 auto',
	maxWidth: '960px',
});

export const VirtualAgentBrowseConversationsSkeleton = () => (
	<AppSkeletonImage src={browseConversationsSkeletonSVG} css={customStyles} />
);
