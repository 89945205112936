import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type PlanSettingsInfoType from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-settings/auto-scheduler';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

/**
 * Plan Settings - auto-scheduler page
 */
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyPlanSettingsAutoScheduler = lazyForPaint<typeof PlanSettingsInfoType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-plan-settings-auto-scheduler", jiraSpaEntry: "async-plan-settings-auto-scheduler" */ '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-settings/auto-scheduler'
			),
		),
	{ ssr: false },
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyPlanSettingsAutoScheduler}
		pageId="plan-settings-auto-scheduler"
		shouldShowSpinner
	/>
);
