import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';
import type { Route } from '@atlassian/jira-router';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const alertPoliciesRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_TEAM_POLICIES,
	path: '/jira/ops/teams/:teamId/policies',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const globalPoliciesRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_POLICIES,
	path: '/jira/settings/products/ops/policies',
} as const as Route;
