import isMatchWith from 'lodash/isMatchWith';
import { useCallback, useEffect, useState } from 'react';

import type {
	ActiveScopeEntry,
	ContextMatch,
	MatchOptions,
	PostOfficeActiveScopes,
	PostOfficeScopeConfig,
	RouteMatch,
	ScopeEntry,
	UsePostOfficeContextScope,
} from './types';
import { usePostOfficeContext } from '../post-office-context';
import { type PostOfficeContextValue } from '../post-office-context/types';
import { usePostOfficeRoute } from '../post-office-route/store';
import { type RouteState } from '../post-office-route/store/types';
import { createSimplePubSub } from '../util/simple-pub-sub';
import type { Publisher, Subscriber } from '../util/simple-pub-sub/types';

// Hook

export const usePostOfficeContextScope: UsePostOfficeContextScope = (config) => {
	const matchScopes = useCallback(createMatchScopes(config), [config.scopes]);

	const [state, setState] = useState(initialState);

	const route = usePostOfficeRoute();

	const context = usePostOfficeContext();

	const next = matchScopes({ route, context });

	usePostOfficeScopeSpy(state, config);

	useEffect(() => {
		if (!nextIsDifferent({ next, state })) return;

		return setState({
			current: next,
			previous: state.current,
		});
	}, [stringifyActiveScopes(next)]);

	return state;
};

const initialState: PostOfficeActiveScopes = {
	current: [],
	previous: [],
};

// Match Scope

type CreateMatchScope = (context: {
	route: RouteState;
	context: PostOfficeContextValue;
}) => (input: ScopeEntry) => boolean;
const createMatchScope: CreateMatchScope =
	({ route, context }) =>
	(input: ScopeEntry): boolean => {
		const contextMatch: ContextMatch = route?.current
			? { route: extractRelevantUrlFields(route.current), context }
			: { route: undefined, context };

		const inputMatch = input.when;

		return matchScope({ contextMatch, inputMatch });
	};

const extractRelevantUrlFields = (route: string): RouteMatch => {
	const { hostname, pathname } = new URL(route);

	return { hostname, pathname };
};

// Match Context

type ValueMatchParams = {
	contextValue?: string | Record<string, unknown>;
	inputValue?: MatchOptions | Record<string, MatchOptions>;
};

const matchScope = ({
	contextMatch,
	inputMatch,
}: {
	contextMatch: Record<string, unknown>;
	inputMatch: Record<string, unknown>;
}): boolean =>
	isMatchWith(
		contextMatch,
		inputMatch,
		(contextValue: ValueMatchParams['contextValue'], inputValue: ValueMatchParams['inputValue']) =>
			isValueMatchDeep({ contextValue, inputValue }),
	);

const isValueMatch = ({ contextValue, inputValue }: ValueMatchParams): boolean => {
	if (!contextValue || !inputValue) return false;

	if (typeof inputValue === 'string') return contextValue === inputValue;

	if (inputValue?.equals) return contextValue === inputValue.equals;

	if (!inputValue?.matches) return false;

	if (typeof inputValue.matches !== 'string' || typeof contextValue !== 'string') return false;

	return new RegExp(inputValue.matches).test(contextValue);
};

const isValueMatchDeep = ({ contextValue, inputValue }: ValueMatchParams): boolean =>
	typeof inputValue === 'object' && typeof contextValue === 'object'
		? matchScope({ contextMatch: contextValue, inputMatch: inputValue })
		: isValueMatch({ contextValue, inputValue });

type CreateMatchScopes = (
	config: PostOfficeScopeConfig,
) => (input: { route: RouteState; context: PostOfficeContextValue }) => Array<ActiveScopeEntry>;
export const createMatchScopes: CreateMatchScopes =
	({ scopes }) =>
	(input) =>
		scopes.filter(createMatchScope(input)).map((scope) => ({ name: scope.name }));

type NextIsDifferent = (params: {
	next: Array<ActiveScopeEntry>;
	state: PostOfficeActiveScopes;
}) => boolean;
const nextIsDifferent: NextIsDifferent = ({ next, state }) => {
	const currentSet = Array.from(new Set(state.current.map((e) => e.name)));
	const nextSet = Array.from(new Set(next.map((e) => e.name)));

	if (nextSet.length !== currentSet.length) return true;

	return !nextSet.every((e) => currentSet.includes(e));
};

const stringifyActiveScopes = (scopes: ActiveScopeEntry[]) =>
	JSON.stringify(scopes.map((e) => e?.name));

// Scope Spy

const usePostOfficeScopeSpy = (state: PostOfficeActiveScopes, config: PostOfficeScopeConfig) => {
	if (config?.consoleSpy && typeof window !== 'undefined' && !window?.postOffice?.scopes) {
		const [listen, update] = createSimplePubSub<PostOfficeActiveScopes>();

		window.postOffice = {
			...window.postOffice,
			scopes: {
				[config?.consoleSpy.name]: {
					listen,
					update,
					list: config.scopes,
				},
			},
		};
	}

	useEffect(() => {
		if (!config?.consoleSpy) return;

		if (!window.postOffice?.scopes) return;

		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		window.postOffice?.scopes?.[config?.consoleSpy.name]?.update?.(state);
	}, [stringifyActiveScopes(state.current)]);
};

declare global {
	interface Window {
		postOffice?: {
			scopes?: {
				[K: string]: {
					update: Publisher<PostOfficeActiveScopes>;
					listen: Subscriber<PostOfficeActiveScopes>;
					list: PostOfficeScopeConfig['scopes'];
				};
			};
		};
	}
}
