import { isFedRamp } from '@atlassian/atl-context';
import { ff } from '@atlassian/jira-feature-flagging';
import { hasItemInSidebar } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/utils';
import type { SidebarConfigData } from '@atlassian/jira-navigation-apps-sidebar-common/src/types';
import type { Features } from '../../../common/types';

export const extractData = (
	data: SidebarConfigData,
	isDevMode: boolean,
	isAdmin: boolean,
	isSiteAdmin: boolean,
): Features => ({
	hasGeneralConfiguration: isAdmin,
	hasDarkFeatures: hasItemInSidebar(data, 'dark_features'),
	hasTroubleshootingAndSupportSection: isAdmin,
	hasViewAuditing: isAdmin,
	hasPluginViewer: isDevMode && isAdmin,
	hasModulesViewer: isDevMode && isAdmin,
	hasSystemInfo: hasItemInSidebar(data, 'system_info'),
	hasIntegrityChecker: hasItemInSidebar(data, 'integrity_checker'),
	hasLoggingProfiling: hasItemInSidebar(data, 'logging_profiling'),
	hasSchedulerDetails: hasItemInSidebar(data, 'scheduler_details'),
	hasSecuritySection: isAdmin,
	hasProjectRoles: isAdmin,
	hasGlobalPermissions: isAdmin,
	hasCollectors: isAdmin,
	hasUserInterfaceSection: isAdmin,
	hasUserDefaults: isAdmin,
	hasEditDefaultDashboard: isAdmin,
	hasLookAndFeel: isAdmin,
	hasPasAdmin: hasItemInSidebar(data, 'com.atlassian.pas:pas-admin-link-jira'),
	hasImportExportSection: isAdmin,
	hasBackupManager: isAdmin,
	hasExternalImport: isAdmin,
	hasRestoreSystem: hasItemInSidebar(data, 'vertigo_import'),
	hasCloudImport: hasItemInSidebar(data, 'vertigo_import_jira_cloud'),
	hasServerImport: hasItemInSidebar(data, 'vertigo_import_jira_server'),
	hasC2CMigration:
		isSiteAdmin && ff('jira-system-settings-cloud-to-cloud-migration') && !isFedRamp(),
	hasMailSection: isAdmin,
	hasGlobalMailSettings: isAdmin,
	hasOutgoingMail: isAdmin,
	hasIncomingMail: isAdmin,
	hasSendMail: isAdmin,
	hasAdminHelperSection: isAdmin,
	hasPermissionHelper: isAdmin,
	hasNotificationHelper: isAdmin && !ff('sea-1973-notifications-hub'),
	hasSharedSection: isAdmin,
	hasManageDashboards: isAdmin,
	hasManageFilters: isAdmin,
	hasEmailNotificationsLogHelper: ff('platform.jlove-pulsar.jlovep-423-jsw-notification-logs-ui'),

	// advanced
	hasAdvancedSection: isAdmin,
	hasAttachments: isAdmin,
	hasEvents: isAdmin,
	hasWebHooks: isAdmin,
	hasServices: isAdmin,
	hasLexoRankManagement: hasItemInSidebar(data, 'greenhopper-lexorank-manage'),
});
