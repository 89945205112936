import { ROUTE_NAMES } from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';
import { useRouter, type RouterContext, type RouterDataContext } from '@atlassian/jira-router';

const GENERAL_CONNECT_ROUTES = new Set<string>([
	ROUTE_NAMES.CONNECT_GENERAL,
	ROUTE_NAMES.CONNECT_GENERAL_INVALID,
	ROUTE_NAMES.LEGACY_CONNECT_GENERAL,
	/**
	 * This one tricky, and I wish it was documented somewhere, so here you go:
	 * Among different Connect app types, there are global Connect apps and JSM Connect apps (project apps).
	 * The URL of the global app looks like this:
	 *   `/plugins/servlet/ac/catlassian/catlassian-page?project.key=IP&project.id=10154`
	 * The URL of the JSM project app looks like this (there is also another URL pattern, but it is not relevant to this case):
	 *   `/plugins/servlet/ac/catlassian/catlassian-page-A?project.key=IP&project.id=10154&servicedesk.serviceDeskId=31`
	 * They have different route names and differ only by the `servicedesk.serviceDeskId` query parameter.
	 * But here's the catch: the global app URL can also contain a `servicedesk.serviceDeskId` query parameter,
	 * it is enough for the user to navigate to a specific JSM route in order for that JSM project to be saved as the most recent project,
	 * and it looks like Connect adds that most recent project context to the URL.
	 * Once that happens, the global app route is no longer matched, the JSM one is, which brings a lot of confusion since at this point
	 * we have no idea whether the "Apps" section should be expanded or the "Projects" one, since it is possible the app lives in either of those.
	 * You can navigate to the JSW project in order to override the JSM one and that query param will disappear from the global app URL.
	 * So what we are doing here? We are adding the route to the list of global Connect apps (just like its route name suggests) and potentially return a false positive value.
	 * Later on in the code we are going to check whether the app is global or not based on the data we have (the list of apps) and fix that.
	 */
	ROUTE_NAMES.CONNECT_GENERAL_SERVICE_DESK_PROJECT,
]);

const UNCERTAIN_APPS_SECTION_ROUTES = GENERAL_CONNECT_ROUTES;
const CERTAIN_APPS_SECTION_ROUTES = new Set<string>([
	ROUTE_NAMES.FORGE_GLOBAL,
	ROUTE_NAMES.MARKETPLACE_INDEX,
]);

export const APPS_SECTION_ROUTES = new Set<string>([
	...UNCERTAIN_APPS_SECTION_ROUTES,
	...CERTAIN_APPS_SECTION_ROUTES,
]);

/**
 * @returns true when the route is one of many that maybe be rendered under the "Apps" section.
 * May return false positive results in case the current route is a general Connect route.
 */
export function isAppsRoute(routerContext: RouterContext | RouterDataContext) {
	const { route } = routerContext;
	return APPS_SECTION_ROUTES.has(route.name);
}

/**
 * @returns true when the route is one of many that maybe be rendered under the "Apps" section.
 * May return false positive results in case the current route is a general Connect route.
 */
export function useIsAppsRoute() {
	const [routerContext] = useRouter();
	return isAppsRoute(routerContext);
}

/**
 * @returns true when the current route is a general Connect route.
 */
export function useIsGeneralConnectRoute() {
	const [routerContext] = useRouter();
	return GENERAL_CONNECT_ROUTES.has(routerContext.route.name);
}
