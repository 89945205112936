import React, { useMemo } from 'react';
import Lozenge from '@atlaskit/lozenge';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import {
	getSystemNavigationPaths,
	NestingMenuItem,
} from '@atlassian/jira-navigation-apps-sidebar-common';
import { SECTION_ID } from '../../../../common/constants';
import { useReleaseVersion } from '../../../../controllers/migrate-cloud-site';
import { messages } from './messages';
import { MigrationsMenu } from './migrate-to-cloud-site-menu';

export const MigrateCloudSiteMenuItem = () => {
	const { formatMessage } = useIntl();
	const { release } = useReleaseVersion();

	const BackMenuItem = {
		href: '/jira/settings/system/general-configuration',
		title: formatMessage(messages.itemBack),
	};

	const isBeta = ff('jira-frontend-c2c-migrations-ga-release') ? release === 'BETA' : true;
	const navigationPath = useMemo(() => getSystemNavigationPaths(), []);

	return (
		<NestingMenuItem
			analytics={{ itemId: 'migrate-to-cloud-site' }}
			href={navigationPath.importSectionMigrateCloud}
			title={
				<>
					{formatMessage(messages.migration)}{' '}
					{isBeta && (
						<Lozenge appearance="new">{formatMessage(messages.migrationBetaLabel)}</Lozenge>
					)}
				</>
			}
			overrides={{ BackMenuItem }}
			id={SECTION_ID.MIGRATE_TO_CLOUD_SITE}
		>
			<MigrationsMenu />
		</NestingMenuItem>
	);
};
