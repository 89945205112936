import queryString from 'query-string';
import type { UserPreferenceType } from '@atlassian/jira-dashboard-user-preference';
import type { PrefetchLinksType, GadgetData } from '../../../types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const replacer = (match: string, part1: any) => String.fromCharCode(part1);

// IMPORTANT - The below code is copied from monolith streams plugin and modified to fit linting rules
// so any changes in here have to be synced with the monolith code as well.
// https://stash.atlassian.com/projects/jiracloud/repos/jira/browse/jira-components/jira-plugins/streams/streams-jira-plugin/src/main/resources/streams-jira-plugin/dashboarditem/activity-stream/js/activity-stream.js?at=64cdcf2e1660c3afeaf26d8795e8cb737286efe9#63-81
// @ts-expect-error - TS7006 - Parameter 'operatorLower' implicitly has an 'any' type.
const getDateValue = (dateValue: string, operatorLower) => {
	// make sure we're dealing with a string so we can use indexOf and split without errors
	const dateStr = `${dateValue}`;
	const operator = operatorLower.toUpperCase();

	let dateArr;
	if (dateStr.indexOf(' ') !== -1) {
		dateArr = dateStr.split(' ');
	} else if (operator === 'BETWEEN') {
		dateArr = [dateValue, dateValue];
	} else {
		dateArr = [dateValue];
	}

	return dateArr
		.map((date, index) => {
			// make sure we're adding numbers and not concatenating strings
			const dateInt = parseInt(date, 10);
			if (operator === 'AFTER' || (operator === 'BETWEEN' && index === 1)) {
				return dateInt + 1000 * 60 * 60 * 24 - 1; // Add 23 hours, 59 minutes, 59 seconds to get to end of day
			}
			return dateInt;
		})
		.join(' ');
};

// IMPORTANT - The below code is copied from monolith streams plugin and modified to fit linting rules
// so any changes in here have to be synced with the monolith code as well.
// https://stash.atlassian.com/projects/jiracloud/repos/jira/browse/jira-components/jira-plugins/streams/streams-jira-plugin/src/main/resources/streams-jira-plugin/dashboarditem/activity-stream/js/activity-stream.js?at=64cdcf2e1660c3afeaf26d8795e8cb737286efe9#20
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleSpecialCases = (rule: any) => {
	let { value, operator } = rule;
	const { type } = rule;
	if (Array.isArray(value)) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const encodedValues: Array<any> = [];
		value.forEach((val) => {
			// additional level of encoding for spaces because otherwise the spaces
			// will be mistaken for value delimiters (STRM-1857); escape space as "_"
			// and escape any existing "_" as "\_"
			encodedValues.push(val.replace(/_/g, '\\_').replace(/ /g, '_'));
		});
		value = encodedValues.join(' ');
	}
	if (type === 'date') {
		if (operator === 'is') {
			// back end doesn't support "IS", we have to turn it into a between
			operator = 'between';
		}
		value = getDateValue(value, operator);
		// We had some problems getting the date values
		// Return empty rule so it won't be included
		if (value.indexOf('NaN') !== -1) {
			return {};
		}
	} else if (type === 'list' || type === 'user') {
		// allow comma-separated values (in addition to space separated) for issue keys and usernames
		value = value.replace(/,/g, ' ');
		// escape underscores as "\_" (because "_" is used to escape spaces in lists)
		value = value.replace(/_/g, '\\_');
	}
	return {
		value,
		operator,
	};
};

// IMPORTANT - The below code is copied from monolith streams plugin and modified to fit linting rules
// so any changes in here have to be synced with the monolith code as well.
// https://stash.atlassian.com/projects/jiracloud/repos/jira/browse/jira-components/jira-plugins/streams/streams-jira-plugin/src/main/resources/streams-jira-plugin/dashboarditem/activity-stream/js/activity-stream.js?at=64cdcf2e1660c3afeaf26d8795e8cb737286efe9#651-683
const getParamsFromRules = (rules: string) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const params: Record<string, any> = {};
	const providers =
		JSON.parse(decodeURIComponent(rules.replace(/&#([0-9]+);/g, replacer)))?.providers || [];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const enabledProviders: Array<any> = [];

	// @ts-expect-error - TS7006 - Parameter 'provider' implicitly has an 'any' type.
	providers.forEach((provider) => {
		const providerKey = provider.provider;
		const { rules: providerRules } = provider;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const stringifiedRules: Array<any | string> = [];
		if (!provider.disabled) {
			if (providerKey !== 'streams') {
				enabledProviders.push(providerKey);
			}
			// @ts-expect-error - TS7006 - Parameter 'rule' implicitly has an 'any' type.
			providerRules.forEach((rule) => {
				const modifiedRuleObj = handleSpecialCases(rule);
				const { operator, value } = modifiedRuleObj;
				if (operator && value) {
					stringifiedRules.push([rule.rule, operator.toUpperCase(), value].join(' '));
				}
			});
			params[providerKey] = stringifiedRules;
		}
	});

	if (enabledProviders.length < providers.length - 1) {
		params.providers = enabledProviders.join(' ');
	}
	return params;
};

export const getRestQueryParams = (gadget: GadgetData) => {
	const prefs = Object.fromEntries(
		gadget.userPrefs?.fields.map((field: UserPreferenceType) => [field.name, field.value]) ?? [],
	);

	const params = {
		maxResults: prefs.numofentries ?? 25,
		relativeLinks: true,
		...(prefs.rules ? getParamsFromRules(prefs.rules) : {}),
	};

	// Gadget encodes spaces as '+', rather than '%20'.  Need to replace here to match URL exactly.
	return queryString.stringify(params).replace(/%20/g, '+');
};

const ACTIVITY_STREAM_REST_URL = 'plugins/servlet/streams';

export const getActivityStreamPrefetchLink = (gadgetData: GadgetData): PrefetchLinksType => {
	const paramStr = getRestQueryParams(gadgetData);

	return [`/${ACTIVITY_STREAM_REST_URL}?${paramStr}`];
};
