import React from 'react';
import { createHook } from 'react-sweet-state';
import OpTrigger from '@atlassian/jira-common-util-op-triggers/src/on-mount-and-update';
import { SpaStateSubscriber } from '../../common';
import Store from '../../store';
import type { State } from '../../types';

export type SpaStatePageReadyProps = {
	comparer: unknown[];
};

type IsReadySelectorType = {
	isReady: boolean;
};

export const isReadySelector = ({ currentPage }: State): IsReadySelectorType => {
	const { apps } = currentPage;
	const appIds = Object.keys(apps);
	return {
		isReady: appIds.length ? appIds.every((id) => apps[id].endTime || apps[id].timeoutTime) : false,
	};
};

export const useSpaStatePageReady = createHook(Store, {
	selector: isReadySelector,
});

export const SpaStatePageReady = () => (
	/* TODO - this should be using only the actions subscriber not state+actions subscriber */
	<SpaStateSubscriber>{(_, { setAppReady }) => <OpTrigger op={setAppReady} />}</SpaStateSubscriber>
);

export const SpaStatePageReadyOnUpdate = ({ comparer }: SpaStatePageReadyProps) => (
	/* TODO - this should be using only the actions subscriber not state+actions subscriber */
	<SpaStateSubscriber>
		{/* @ts-expect-error - TS2769 - No overload matches this call. */}
		{(_, { setAppReady }) => <OpTrigger op={setAppReady} args={comparer} />}
	</SpaStateSubscriber>
);
