import type { KeyboardEvent, MouseEvent } from 'react';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { UseOnClickType } from './types';

export const useOnClick: UseOnClickType = ({ analytics, onClick } = {}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (event: KeyboardEvent | MouseEvent) => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'navigationItem',
		});
		if (analytics) {
			fireUIAnalytics(analyticsEvent, 'sidebar', analytics);
		} else {
			fireUIAnalytics(analyticsEvent, 'sidebar');
		}
		onClick && onClick(event);
	};
};
