import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';
import type { Route } from '@atlassian/jira-router';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const heartbeatsRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_HEARTBEATS,
	path: '/jira/ops/teams/:teamId/heartbeats',
} as const as Route;
