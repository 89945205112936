// eslint-disable-next-line jira/restricted/@atlassian/jira-router
import { createResource, type RouterContext } from '@atlassian/jira-router';
import type { AssetConfiguration } from '../../../../common/types';

export const CHANGE_MANAGEMENT_ASSET_CONFIGURATION_STATUS =
	'CHANGE_MANAGEMENT_ASSET_CONFIGURATION_STATUS';

const getDataLoader = () =>
	import(
		/* webpackChunkName: "async-resource-asset-config", jiraSpaEntry: "async-resource-asset-config" */ './get-data'
	);

const getKey = ({ match }: RouterContext): string =>
	typeof match.params.projectKey === 'string' ? match.params.projectKey : '';

export const changeManagementAssetConfigurationStatusResource = createResource<AssetConfiguration>({
	type: CHANGE_MANAGEMENT_ASSET_CONFIGURATION_STATUS,
	getKey,
	getDataLoader,
	maxAge: Infinity,
});
