import React, { memo } from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import {
	setPerformanceMarkLoadStart,
	setPerformanceMarkLoadEnd,
} from '@atlassian/jira-navigation-apps-sidebar-common';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-common/async';
import type PersonalSettingsSidebarType from './index';

const LazyPersonalSettingsSidebar = lazyAfterPaint<typeof PersonalSettingsSidebarType>(() => {
	setPerformanceMarkLoadStart();
	return import(/* webpackChunkName: "async-sidebar-personal-settings" */ './index').then(
		(bundle) => {
			setPerformanceMarkLoadEnd();
			return bundle;
		},
	);
});

const PersonalSettingsSidebar = () => (
	<SidebarBoundary id="personalSettings">
		<LazyPersonalSettingsSidebar />
	</SidebarBoundary>
);

export default memo(PersonalSettingsSidebar);
