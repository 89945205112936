import type { EntryPoint, EntryPointComponent } from 'react-relay';
import { topNavigationEntryPoint } from '@atlassian/jira-atlassian-navigation/entrypoint';
import type { MenuId } from '@atlassian/jira-atlassian-navigation/src/common/types';
import type { AnyEntryPoint } from '@atlassian/jira-entry-point';
import {
	HORIZONTAL_NAVIGATION,
	MAIN_CONTENT,
	TOP_NAVIGATION,
	RIGHT_SIDEBAR,
} from '@atlassian/jira-entry-point-page-layout-fields';
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin';

type ChromelessPage = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	main: EntryPoint<EntryPointComponent<any, any, any, any>, EntryPointRouteParams>;
};

type Page = ChromelessPage & {
	horizontalNavigation?: AnyEntryPoint;
	topNavigationMenuId: MenuId;
	leftSidebar?: AnyEntryPoint;
	rightSidebar?: AnyEntryPoint;
};

export const createChromelessEntryPoint = (page: ChromelessPage) => ({
	root: page.main.root,
	getPreloadProps: (entryPointParams: EntryPointRouteParams) => ({
		entryPoints: {
			[MAIN_CONTENT]: {
				entryPoint: page.main,
				entryPointParams,
			},
		},
	}),
	toString: () => 'ChromelessEntryPoint',
});

export const createPageEntryPoint = (page: Page) => ({
	root: page.main.root,
	getPreloadProps: (entryPointParams: EntryPointRouteParams) => ({
		entryPoints: {
			[MAIN_CONTENT]: {
				entryPoint: page.main,
				entryPointParams,
			},
			[TOP_NAVIGATION]: {
				entryPoint: topNavigationEntryPoint,
				entryPointParams: { menuId: page.topNavigationMenuId },
			},
			...(page.horizontalNavigation
				? {
						[HORIZONTAL_NAVIGATION]: {
							entryPoint: page.horizontalNavigation,
							entryPointParams: {},
						},
					}
				: undefined),
			...(page.rightSidebar
				? {
						[RIGHT_SIDEBAR]: {
							entryPoint: page.rightSidebar,
							entryPointParams,
						},
					}
				: undefined),
		},
	}),
	toString: () => 'PageEntryPoint',
});
