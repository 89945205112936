import { lazyAfterPaint } from 'react-loosely-lazy';
import type IssueAppType from '@atlassian/jira-issue-view/src/views/issue-details/issue-app';

/**
 * This is specifically for prefetching, and should be handled by automation down the track
 */
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyIssueApp = lazyAfterPaint<typeof IssueAppType>(
	() =>
		import(
			/* webpackChunkName: "async-issue-app" */ '@atlassian/jira-issue-view/src/views/issue-details/issue-app'
		),
	{ ssr: false },
);
