import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const settingsOauthCredentialsRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.SETTINGS_OAUTH_CREDENTIALS,
	path: '/secure/admin/oauth-credentials',
};
