import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarGlobalSystemSettings from '@atlassian/jira-navigation-apps-sidebar-global-settings-system';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { getOfferingCatalogueFeaturesResource } from '@atlassian/jira-router-resources-offering-catalogue-features';
import { globalSettingsSystemBulkMailRoute } from '@atlassian/jira-router-routes-admin-pages-outgoing-mail-routes';
import BulkEmail, { LazyBulkEmail } from './ui/spa/admin-pages/outgoing-mail/bulk-mail';

export const outgoingMailRouteEntries: Route[] = [
	createEntry(globalSettingsSystemBulkMailRoute, {
		component: BulkEmail,

		navigation: {
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSystemSettings,
		},

		layout: globalSettingsLayout,

		resources: [
			...getNavigationResources(),
			...getOfferingCatalogueFeaturesResource(),
			navigationSidebarGlobalResource,
		],

		forPaint: [LazyAtlassianNavigation, LazyBulkEmail],
	}),
];
