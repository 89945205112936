import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const classicProjectSettingsIssueLayoutRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_CLASSIC,
	name: ROUTE_NAMES.CLASSIC_PROJECT_SETTINGS_ISSUE_LAYOUT,
	path: '/plugins/servlet/project-config/:projectKey/issuelayout',
	query: ['screenId'],
	exact: true,
};
