import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { chromelessLayout } from '@atlassian/jira-chromeless-layout';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { composeLayouts } from '@atlassian/jira-route-layout';
import type { Route } from '@atlassian/jira-router';
import { configurationResource } from '@atlassian/jira-router-resources-business-configuration';
import { fieldsResource } from '@atlassian/jira-router-resources-business-fields';
import { navigationResource } from '@atlassian/jira-router-resources-business-navigation';
import { projectDetailsResource } from '@atlassian/jira-router-resources-business-project-details';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { projectCreatePermissionsResource } from '@atlassian/jira-router-resources-project-permissions';
import { ErrorPagesNotFound, LazyIssueApp } from '@atlassian/jira-router-routes-common';
import {
	softwareListRoute,
	softwareListEmbedRoute,
} from '@atlassian/jira-router-routes-software-list-routes';
import { ListSkeleton, ListEmbedSkeleton } from '@atlassian/jira-skeletons';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav';
import { softwareProjectLayout } from '@atlassian/jira-software-project-layout';
import { listEntryPoint } from '@atlassian/jira-spa-apps-jsw-list/entrypoint';
import {
	SoftwareListPage,
	LazyNextGenList,
	SoftwareListEmbedPage,
	LazyNextGenListEmbed,
} from './ui/list';

const getListProjectResources = () => [
	projectCreatePermissionsResource,
	navigationResource,
	projectDetailsResource,
	configurationResource,
	fieldsResource,
];
export const listRouteEntry = createPageEntryPoint({
	main: listEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

export const softwareListRouteEntry: Route = createEntry(softwareListRoute, {
	component: componentWithCondition(
		() => expValEquals('jsw_list_view', 'cohort', 'variation'),
		SoftwareListPage,
		ErrorPagesNotFound,
	),
	skeleton: ListSkeleton,

	entryPoint() {
		return expValEquals('list_view_conditional_formatting', 'cohort', 'variation')
			? listRouteEntry
			: undefined;
	},

	layout: composeLayouts(
		softwareProjectLayout,
		embeddedIssuePreloadLayoutBuilder([{ query: ['selectedIssue'] }]),
	),

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarNextGen,
		horizontal: AsyncHorizontalSoftwareProjectNav,
		horizontalFeatureFlag: 'sea-2744-jsw-tabs-nav',
	},

	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		...getListProjectResources(),
	],

	afterPaint: [LazyIssueApp],
	forPaint: [LazyAtlassianNavigation, LazyNextGenList],
	ufoName: 'next-gen-list',
});

export const softwareListEmbedRouteEntry: Route = createEntry(softwareListEmbedRoute, {
	component: componentWithCondition(
		() => expValEquals('jsw_list_view', 'cohort', 'variation'),
		SoftwareListEmbedPage,
		ErrorPagesNotFound,
	),
	skeleton: ListEmbedSkeleton,
	entryPoint() {
		return expValEquals('list_view_conditional_formatting', 'cohort', 'variation')
			? listRouteEntry
			: undefined;
	},
	layout: chromelessLayout,
	resources: [...getNavigationResources(), ...getListProjectResources()],
	forPaint: [LazyNextGenListEmbed],
	ufoName: 'next-gen-list-embed',
});
