import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { Box, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Placeholder from '@atlassian/jira-placeholder';
import type { Props } from './types';

export const Skeleton = () => (
	<Stack space="space.100" grow="hug">
		<Box xcss={headingSkeleton} />
		<Box xcss={dropdownSkeleton} />
	</Stack>
);

const LazyPageSwitcher = lazyForPaint(
	() =>
		import(
			/* webpackChunkName: "async-jira-navigation-apps-sidebar-nav4-sidebar-global-settings-page-switcher" */ './main'
		),
);

export const AsyncPageSwitcher = (props: Props) => (
	<Placeholder name="nav4.sidebar.global-settings.page-switcher" fallback={<Skeleton />}>
		<LazyPageSwitcher {...props} />
	</Placeholder>
);

const headingSkeleton = xcss({
	backgroundColor: 'color.skeleton',
	width: '50%',
	height: token('space.200'),
	borderRadius: 'border.radius.100',
});

const dropdownSkeleton = xcss({
	backgroundColor: 'color.skeleton',
	height: token('space.400'),
	borderRadius: 'border.radius.100',
});
