/**
 * @generated SignedSource<<642f4ac62154bddf4b2f4e9d7776bddd>>
 * @relayHash 31ce6078aa04dd287acc44b9c9417f47
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID c3b1c8d98edcfbf446d19e7604e225820f9c27c834e844fde6d552c5ef3cbd77

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcIncidentsPageInternalQuery } from './srcIncidentsPageInternalQuery.graphql';

const node: PreloadableConcreteRequest<srcIncidentsPageInternalQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "c3b1c8d98edcfbf446d19e7604e225820f9c27c834e844fde6d552c5ef3cbd77",
    "metadata": {
      "connection": [
        {
          "count": "servicePageSize",
          "cursor": "after",
          "direction": "forward",
          "path": [
            "jira",
            "jiraProjectByKey",
            "linkedOperationsComponentsByProject"
          ]
        }
      ]
    },
    "name": "srcIncidentsPageInternalQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
