import React, { memo } from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import {
	setPerformanceMarkLoadStart,
	setPerformanceMarkLoadEnd,
} from '@atlassian/jira-navigation-apps-sidebar-common';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-common/async';
import type { SidebarFiltersGlobal as SidebarFiltersGlobalType } from './ui/global-scope';
import type SidebarFiltersLegacyType from './index';

// Legacy GIN Sidebar
const LazySidebarFiltersLegacy = lazyAfterPaint<typeof SidebarFiltersLegacyType>(() => {
	setPerformanceMarkLoadStart();
	return import(/* webpackChunkName: "async-sidebar-filters" */ './index').then((bundle) => {
		setPerformanceMarkLoadEnd();
		return bundle;
	});
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AsyncSidebarFiltersLegacy = memo<Record<any, any>>(() => (
	<SidebarBoundary id="filters">
		<LazySidebarFiltersLegacy />
	</SidebarBoundary>
));

export default AsyncSidebarFiltersLegacy;

// Global-scope NIN sidebar
const LazySidebarFiltersGlobal = lazyAfterPaint<typeof SidebarFiltersGlobalType>(() => {
	setPerformanceMarkLoadStart();
	// using direct path instead of `index` because webpack will combine multiple chunks from same file
	return import(/* webpackChunkName: "async-sidebar-filters-global" */ './ui/global-scope').then(
		(bundle) => {
			setPerformanceMarkLoadEnd();
			return bundle.SidebarFiltersGlobal;
		},
	);
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AsyncSidebarFiltersGlobal = memo<Record<any, any>>(() => (
	<SidebarBoundary id="filters-global">
		<LazySidebarFiltersGlobal />
	</SidebarBoundary>
));
