import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyUserSegmentation = lazyForPaint(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-user-segmentation", jiraSpaEntry: "async-user-segmentation" */ '@atlassian/jira-user-segmentation'
		),
	),
);

export const UserSegmentation = () => (
	<LazyPage Page={LazyUserSegmentation} pageId="user-segmentation" shouldShowSpinner />
);
