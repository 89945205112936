/** @jsx jsx */
import type { ReactNode } from 'react';
import { cssMap, jsx } from '@compiled/react';
import { Tokens } from '@atlassian/jira-custom-theme-constants';
import {
	InnerWrapper,
	InnerWrapperWithBackground,
	OuterWrapper,
	OuterWrapperWithBackground,
} from './styled';

const styles = cssMap({
	root: {
		position: 'relative',
	},
	fullHeight: {
		minHeight: 0,
		height: '100%',
	},
	insetRoot: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		padding: Tokens.WRAPPER_PADDING,
	},
	insetContainer: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderRadius: Tokens.WRAPPER_BORDER_RADIUS,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		backgroundColor: Tokens.WRAPPER_BACKGROUND,
	},
});

/**
 * This component sets a fluid layout that works with the new page
 * layout created in @atlassian/navigation-system. It does not use
 * explicit sizes set from CSS variables but grows to the space given.
 */
export const ThemedContentLayout = ({
	children,
	appearance,
}: {
	appearance: 'default' | 'inset';
	children: ReactNode;
}) => (
	<div css={[styles.root, styles.fullHeight, appearance === 'inset' && styles.insetRoot]}>
		<div css={[styles.fullHeight, appearance === 'inset' && styles.insetContainer]}>{children}</div>
	</div>
);

export const BusinessWrapper = ({
	withWhiteBackground,
	children,
}: {
	withWhiteBackground: boolean;
	children: ReactNode;
}) => {
	if (withWhiteBackground) {
		return (
			<OuterWrapperWithBackground>
				<InnerWrapperWithBackground>{children}</InnerWrapperWithBackground>
			</OuterWrapperWithBackground>
		);
	}

	return (
		<OuterWrapper>
			<InnerWrapper>{children}</InnerWrapper>
		</OuterWrapper>
	);
};
