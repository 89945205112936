import {
	AsyncTransitionHomeRenderer,
	AsyncWelcomeModalRenderer,
} from '@atlassian/jira-eoc-changeboarding-layout';
import { globalLayout } from '@atlassian/jira-global-layout';
import { composeLayouts, type Layout } from '@atlassian/jira-route-layout';

export const homepageLayout: Layout = composeLayouts(globalLayout, {
	// both of the components are wrapped with componentWithCondition
	globalComponents: [AsyncTransitionHomeRenderer, AsyncWelcomeModalRenderer],
});
