import merge from 'lodash/merge';
import sendExperienceAnalyticsLegacy, {
	type AdditionalEventAttributes,
	type ExperienceDescription,
} from '@atlassian/jira-common-experience-tracking-analytics';
import { ff } from '@atlassian/jira-feature-flagging-using-meta';
import getMeta from '@atlassian/jira-get-meta';
import { allFeatureFlagsAccessed } from '@atlassian/ufo-feature-flags-accessed';

// eslint-disable-next-line jira/export/no-external-exports
export type { AdditionalEventAttributes, ExperienceDescription };

const isSynthetic = () => getMeta('ajs-is-synthetic') === 'true';

/**
 * sendExperienceAnalytics helper that is sampling events per user.
 *
 * @param {ExperienceDescription} experienceDescription - Description of the experience
 */
export function sendExperienceAnalytics(experienceDescription: ExperienceDescription): void {
	const additionalContext = {};

	const shard = getMeta('ajs-shard');
	const region = getMeta('ajs-region');
	Object.assign(additionalContext, { shard, region });

	if (ff('issue.details.feature-flags-to-slo-analytics')) {
		const enabledFeatureFlags = Object.fromEntries(
			[...allFeatureFlagsAccessed].filter(([, value]) => value),
		);
		Object.assign(additionalContext, { enabledFeatureFlags });
	}

	const synthetics = {
		additionalAttributes: {
			synthetic: isSynthetic(),
			...additionalContext,
		},
	};

	merge(experienceDescription, synthetics);
	sendExperienceAnalyticsLegacy(experienceDescription);
}
