import { createRelayResource } from '@atlassian/jira-relay-utils';
import QUERY, {
	type mainJiraPermissionSchemeAndGetPermissionSchemeByIdQuery,
} from '@atlassian/jira-relay/src/__generated__/mainJiraPermissionSchemeAndGetPermissionSchemeByIdQuery.graphql';

const RESOURCE_TYPE_PERMISSION_SCHEME_PAGE = 'PERMISSION_SCHEME_PAGE';

export const permissionSchemePageResource =
	createRelayResource<mainJiraPermissionSchemeAndGetPermissionSchemeByIdQuery>({
		type: RESOURCE_TYPE_PERMISSION_SCHEME_PAGE,
		getQuery: ({ match }, { tenantContext }) => ({
			parameters: QUERY,
			variables: {
				// To Do: use ari-typescript library once it is approved
				// https://hello.atlassian.net/wiki/spaces/TGR/pages/1374189554/Dependency+review+atlassian+ari
				schemeId: `ari:cloud:jira:${tenantContext.cloudId}:permission-scheme/activation/${
					tenantContext.activationId
				}/${String(match.params.schemeId)}`,
			},
			options: { fetchPolicy: 'network-only' },
		}),
	});
