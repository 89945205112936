import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { performDeleteRequest } from '@atlassian/jira-fetch';
import type { AccountId } from '@atlassian/jira-shared-types';

type Parameters = {
	accountId: AccountId;
};

export async function deleteBannerDismissState({ accountId }: Parameters) {
	try {
		await performDeleteRequest(
			`/rest/api/3/user/properties/jsm-repackaging-banners-dismiss-state?accountId=${accountId}`,
		);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			meta: {
				id: 'deleteBannerDismissState',
				packageName: 'jiraRouterResourcesJsmRepackagingBanner',
				teamName: 'jenga',
			},
			error,
		});
	}
}
