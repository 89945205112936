import React from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import { ErrorBoundary } from '@atlassian/jira-error-boundary';
import { ReportErrors } from '@atlassian/jira-errors-handling';

const AsyncPendoAnalytics = lazyAfterPaint(() =>
	import(/* webpackChunkName: "async-pendo-analytics" */ './index').then(
		({ PendoAnalytics }) => PendoAnalytics,
	),
);

// This is a wrapper component that will catch any errors thrown by the lazy loaded component
// and report them to Sentry and Splunk
// We don't use JSErrorBoundary here because we don't want user to see a UI flag/page if
// there are any pendo issues
export const AsyncPendoAnalyticsWrapper = () => (
	<ErrorBoundary id="jiraCommonPendoAnalyticsReportErrors" packageName="jiraCommonPendoAnalytics">
		<ReportErrors
			teamName="martech"
			id="jiraCommonPendoAnalyticsReportErrors"
			packageName="jiraCommonPendoAnalytics"
		>
			<AsyncPendoAnalytics />
		</ReportErrors>
	</ErrorBoundary>
);
