/**
 * @generated SignedSource<<1bf695f627b91a25ce87882865dc0922>>
 * @relayHash 15d7acad258757e4608b0a07a6bde204
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 8789ab3f986d4f369f91bdc4d71ace921807eaec7638f0e5dde21bdca1c34e17

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { uiVirtualAgentIntentTemplatesQuery } from './uiVirtualAgentIntentTemplatesQuery.graphql';

const node: PreloadableConcreteRequest<uiVirtualAgentIntentTemplatesQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "8789ab3f986d4f369f91bdc4d71ace921807eaec7638f0e5dde21bdca1c34e17",
    "metadata": {},
    "name": "uiVirtualAgentIntentTemplatesQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
