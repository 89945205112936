import type { ProfilerContextType } from 'react-relay';
import type {
	LogEvent,
	GraphQLSingularResponse,
	GraphQLResponseWithData,
	GraphQLResponseWithoutData,
	RequiredFieldLogger,
} from 'relay-runtime';
import { log } from '@atlassian/jira-common-util-logging';
import killswitch from '@atlassian/jira-killswitch';
import { type RequestInfo, addSpanToAll } from '@atlassian/ufo-interaction-metrics';

const RELAY_CLIENT_ID = 'AGG';
const LOG_NAMESPACE = `relay.${RELAY_CLIENT_ID}.error.graphql`;

type ExecuteInfo = {
	name: string;
	kind: 'mutation' | 'query' | 'subscription';
	start: number;
	end?: number;
	duration: number;
};

interface RelayMetricsRecorder {
	retainQuery: (info: RequestInfo) => void;
}

export type ExtendedProfilerContext = ProfilerContextType & Partial<RelayMetricsRecorder>;

const requests: {
	[key: number]: RequestInfo;
} = {};

const queries: {
	[queryName: string]: RequestInfo;
} = {};

const resources: {
	[resourceID: number]: RequestInfo;
} = {};

const executes: {
	[executeId: number]: ExecuteInfo;
} = {};

const CLEANUP_TIMEOUT = 5 * 60 * 1000; // 5 minutes

export const requiredFieldLogger: RequiredFieldLogger = (event) => {
	log.safeErrorWithoutCustomerData(LOG_NAMESPACE, '[relay required field error]', {
		kind: event.kind,
		owner: event.owner,
		fieldPath: event.fieldPath,
		message: event.kind === 'relay_resolver.error' ? event.error?.message : null,
	});
};

const getResponseSize = (resp: GraphQLSingularResponse) => {
	if (resp.data === undefined) {
		return 0;
	}

	try {
		return JSON.stringify(resp.data).length;
	} catch (e) {
		return 0;
	}
};

export default function relayLogHandler(event: LogEvent): void {
	if (killswitch('relay_log')) {
		return;
	}

	switch (event.name) {
		// NETWORK
		case 'network.start': {
			const networkStart = performance.now();
			const { networkRequestId } = event;
			const { name } = event.params;

			const requestInfo = requests[networkRequestId];

			if (requestInfo == null) {
				const newRequestInfo: RequestInfo = {
					name,
					flushes: [],
					start: networkStart,
					networkStart,
				};
				queries[name] = newRequestInfo;
				requests[networkRequestId] = newRequestInfo;

				setTimeout(() => {
					delete requests[networkRequestId];
					delete queries[name];
				}, CLEANUP_TIMEOUT);
			} else {
				requestInfo.networkStart = networkStart;
			}

			break;
		}
		case 'network.next': {
			const requestInfo = requests[event.networkRequestId];
			if (requestInfo != null) {
				const { response } = event;

				const addFlush = (resp: GraphQLSingularResponse) => {
					if (resp.data !== null) {
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						const res = resp as GraphQLResponseWithData | GraphQLResponseWithoutData;

						const flush = {
							label: res.label != null ? res.label : 'root',
							time: performance.now(),
							size: getResponseSize(res),
						};

						requestInfo.flushes?.push(flush);
					}
				};

				Array.isArray(response)
					? response.forEach(addFlush)
					: // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						addFlush(response as GraphQLSingularResponse);
			}
			break;
		}
		case 'network.error': {
			const info = requests[event.networkRequestId];
			if (info != null) {
				info.error = event.error;
			}
			break;
		}
		case 'network.info': {
			break;
		}
		case 'network.complete': {
			const requestInfo = requests[event.networkRequestId];
			const networkComplete = performance.now();

			if (requestInfo != null) {
				const size = requestInfo.flushes.map((f) => f.size).reduce((p, c) => p + c, 0);

				requestInfo.size = size;
				requestInfo.networkComplete = networkComplete;
				requestInfo.flushes = [];

				addSpanToAll(
					'relay',
					requestInfo.name,
					[{ name: 'network' }],
					requestInfo.start,
					requestInfo.networkComplete,
					size,
				);
			}

			break;
		}
		case 'network.unsubscribe': {
			break;
		}
		// SUSPENSE
		case 'suspense.fragment': {
			break;
		}
		case 'suspense.query': {
			break;
		}
		// QUERY
		case 'queryresource.fetch': {
			const { node } = event.operation.root;
			const fetchStart = performance.now();

			// @ts-expect-error - TS2339 - Property 'name' does not exist on type 'NormalizationSelectableNode'.
			if (node.name != null) {
				// @ts-expect-error - TS2339 - Property 'name' does not exist on type 'NormalizationSelectableNode'.
				const { name } = node;
				const requestInfo = queries[name];

				if (requestInfo != null) {
					requestInfo.fetchStart = fetchStart;
					resources[event.resourceID] = requestInfo;
				} else {
					const newRequestInfo: RequestInfo = {
						name,
						flushes: [],
						start: fetchStart,
						fetchStart,
					};
					queries[name] = newRequestInfo;
					resources[event.resourceID] = newRequestInfo;

					setTimeout(() => {
						// @ts-expect-error - TS2339 - Property 'name' does not exist on type 'NormalizationSelectableNode'.
						delete queries[node.name];
					}, CLEANUP_TIMEOUT);
				}

				setTimeout(() => {
					delete resources[event.resourceID];
				}, CLEANUP_TIMEOUT);
			}

			break;
		}
		case 'queryresource.retain': {
			const requestInfo = resources[event.resourceID];

			if (requestInfo != null && event.profilerContext != null) {
				requestInfo.end = performance.now();
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const context = event.profilerContext as ExtendedProfilerContext;
				context?.retainQuery?.(requestInfo);
			}
			break;
		}

		// EXECUTE
		case 'execute.start': {
			executes[event.executeId] = {
				name: event.params.name,
				// @ts-expect-error - TS2322 - Type 'string' is not assignable to type '"query" | "mutation" | "subscription"'.
				kind: event.params.operationKind,
				start: performance.now(),
				duration: 0,
			};
			break;
		}
		case 'execute.next': {
			executes[event.executeId].duration += event.duration;
			break;
		}
		case 'execute.async.module': {
			break;
		}
		case 'execute.flight.payload_deserialize': {
			break;
		}
		case 'execute.error': {
			delete executes[event.executeId];
			break;
		}
		case 'execute.complete': {
			const op = executes[event.executeId];
			if (op != null) {
				op.end = performance.now();
				delete executes[event.executeId];
			}
			break;
		}
		// STORE
		case 'store.publish': {
			break;
		}
		case 'store.snapshot': {
			break;
		}
		case 'store.restore': {
			break;
		}
		case 'store.gc': {
			break;
		}
		// FLUSH
		case 'store.notify.start': {
			break;
		}
		case 'store.notify.complete': {
			break;
		}
		case 'store.notify.subscription': {
			break;
		}
		// ENTRYPOINT
		case 'entrypoint.root.consume': {
			break;
		}
		default: {
			break;
		}
	}
}
