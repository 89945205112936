import { ROUTE_NAMES } from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';
import type { RouterContext } from '@atlassian/jira-router';
import { ENTITY_ID, MENU_ID } from '../../../constants';
import type { SelectedPath } from '../../../types';

const routes = new Set<string>([
	// ROUTE_NAMES.DASHBOARD, // --> This redirects to DASHBOARD_VIEW
	// ROUTE_NAMES.DASHBOARD_SELECTED, // --> This redirects to DASHBOARD_VIEW
	ROUTE_NAMES.DASHBOARDS_DIRECTORY,
	// ROUTE_NAMES.DASHBOARDS_DIRECTORY_SECURE, // ---> redirects to ROUTE_NAMES.DASHBOARDS_DIRECTORY
	ROUTE_NAMES.DASHBOARD_EDIT,
	ROUTE_NAMES.DASHBOARD_VIEW,
	ROUTE_NAMES.DASHBOARD_VIEW_EMBED,
	ROUTE_NAMES.DASHBOARD_WALLBOARD,
]);

function convert(routeContext: RouterContext): SelectedPath {
	const routeName = routeContext.route.name;
	const { match, query } = routeContext;
	const path: SelectedPath = [MENU_ID.DASHBOARDS];

	switch (routeName) {
		case ROUTE_NAMES.DASHBOARDS_DIRECTORY:
			path.push(MENU_ID.DASHBOARDS_VIEW_ALL);
			break;

		case ROUTE_NAMES.DASHBOARD_WALLBOARD:
			query.dashboardId && path.push(ENTITY_ID.DASHBOARD(query.dashboardId));
			break;

		default: {
			const dashboardId = match.params.dashboardId;

			if (dashboardId === 'last-visited') {
				// This is not really correct. The problem is we cannot tell what the true dashboardId is in this case.
				path.push(MENU_ID.DASHBOARDS_VIEW_ALL);
			} else {
				dashboardId && path.push(ENTITY_ID.DASHBOARD(dashboardId));
			}

			break;
		}
	}

	return path;
}

export const dashboardConverter = { routes, convert };
