import React, { type ReactNode, type ComponentType, createContext, useContext } from 'react';
import type { IconProps } from '@atlaskit/icon';
import type { JiraNavigationItemTypeKey } from '@atlassian/jira-relay/src/__generated__/ui_navigationKitTabList_TabList_tabsFragment.graphql';

type BaseTabGetConfigurationReturn = {
	icon: ComponentType<IconProps>;
	isSelectedOnRoutes: Set<string>;
};
type BoardTabGetConfigurationReturn = BaseTabGetConfigurationReturn & {
	path: string;
};
type ProjectPathTabGetConfigurationReturn = BaseTabGetConfigurationReturn & {
	path: string;
};
type ProjectPluginTabGetConfigurationReturn = BaseTabGetConfigurationReturn & {
	// used for old "jira-projects-plugin" style routes
	// that are in the format /projects/SEA?selectedItem=com.atlassian.jira.jira-projects-plugin:release-page
	projectPluginPage: string;
};
type CustomTabGetConfiguration = {
	icon?: ComponentType<IconProps>;
	isSelectedOnRoutes?: Set<string>;
};

type ProjectTabGetConfigurationReturn =
	| ProjectPathTabGetConfigurationReturn
	| ProjectPluginTabGetConfigurationReturn;

export type TabDetailsProps = {
	onConfirm: (path?: string) => void;
	label: string;
	preview: ReactNode;
};

type BoardScopedTabConfiguration = {
	type: 'board';
	team: string;
	id: string;
	getConfiguration: () => BoardTabGetConfigurationReturn;
	tabDetails?: {
		illustration: ReactNode;
		component: ComponentType<TabDetailsProps>;
	};
};
type ProjectTabConfiguration = {
	type: 'project';
	team: string;
	id: string;
	getConfiguration: () => ProjectTabGetConfigurationReturn;
	tabDetails?: {
		illustration: ReactNode;
		component: ComponentType<TabDetailsProps>;
	};
};

type CustomTabConfiguration = {
	// entirely custom tab, add the component to the switch below and add another
	// type to the union
	type: 'reports' | 'shortcuts' | 'app';
	id: string;
	team: string;
	getConfiguration: () => CustomTabGetConfiguration;
	tabDetails?: {
		illustration: ReactNode;
		component: ComponentType<TabDetailsProps>;
	};
};

export type TabConfiguration =
	| BoardScopedTabConfiguration
	| ProjectTabConfiguration
	| CustomTabConfiguration;

export type TabsConfiguration = Partial<Record<JiraNavigationItemTypeKey, TabConfiguration>>;

type TabsConfigurationProviderProps = {
	children: ReactNode;
	tabsConfiguration: TabsConfiguration;
};

const TabsConfigurationContext = createContext<TabsConfiguration | undefined>(undefined);

export const TabsConfigurationProvider = ({
	tabsConfiguration,
	children,
}: TabsConfigurationProviderProps) => (
	<TabsConfigurationContext.Provider value={tabsConfiguration}>
		{children}
	</TabsConfigurationContext.Provider>
);

export const useTabConfiguration = (
	typeKey: JiraNavigationItemTypeKey | undefined | null,
): TabConfiguration | undefined | null => {
	const tabsConfiguration = useContext(TabsConfigurationContext);

	if (!tabsConfiguration) {
		throw new Error('useTabsConfiguration must be used within a TabConfigurationProvider');
	}

	if (!typeKey || !(typeKey in tabsConfiguration)) {
		return null;
	}

	return tabsConfiguration[typeKey];
};

export const useTabsConfiguration = (): TabsConfiguration => {
	const tabsConfiguration = useContext(TabsConfigurationContext);

	if (!tabsConfiguration) {
		throw new Error('useTabsConfiguration must be used within a TabConfigurationProvider');
	}

	return tabsConfiguration;
};
