import { fetchJson } from '@atlassian/jira-fetch';
import { toErrorId, toErrorMessage } from '@atlassian/jira-project-settings-apps-common/src/types';
import { famaCatchAndLogFetchError } from '@atlassian/jira-project-settings-apps-common/src/util/catch-and-log-fetch-error';
import type { App } from '../../model/connect-apps/types';
import { PACKAGE_NAME } from '../../model/constants';
import type { AppsResponse } from './types';

export const fetchAppUrl = (projectKey: string) =>
	`/rest/internal/2/connectprojectconfig/${projectKey}`;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (projectKey: string): Promise<App[]> => {
	const fetchPromise = famaCatchAndLogFetchError(
		fetchJson(fetchAppUrl(projectKey)),
		PACKAGE_NAME,
		toErrorId('getConnectApps'),
		toErrorMessage('Failed to fetch connect apps for a project'),
	);

	return fetchPromise.then((response: AppsResponse) =>
		response.map((app) => {
			// Server returns JSON as a string inside JSON as a string inside JSON as a string because reasons.
			// Let's parse it here
			const options = JSON.parse(app.iframe.options);
			const myApp: App = {
				title: app.title,
				id: options.uniqueKey,
				connectIframeProps: {
					addon_key: app.iframe.addonKey,
					key: app.iframe.moduleKey,
					url: options.url,
					options: {
						...options,
						productContext: JSON.parse(options.productCtx),
						structuredContext: JSON.parse(options.structuredContext),
						user: {
							uid: options.uid,
							ukey: options.ukey,
							timeZone: options.timeZone,
						},
					},
				},
			};
			return myApp;
		}),
	);
};
