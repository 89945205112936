import { createResource } from '@atlassian/jira-router';
import type { JiraPlanDataRestResponse } from '@atlassian/jira-software-backlog-uif-types';
import type { UIFBacklogCachedDataResult } from '@atlassian/jira-software-uif-early-script';
import {
	RESOURCE_TYPE_NEXTGEN_BACKLOG_REST_RESOURCE,
	RESOURCE_TYPE_NEXTGEN_BACKLOG_REST_RESOURCE_OLD,
} from './constants';

export const uifBacklogResourceOld = createResource<JiraPlanDataRestResponse | null>({
	type: RESOURCE_TYPE_NEXTGEN_BACKLOG_REST_RESOURCE_OLD,
	getKey: ({
		match: {
			params: { boardId },
		},
		query: { config },
	}) =>
		/*
            When navigating to and from the board settings page we need
            the data to get reloaded. So we are updating the key to force a refresh
        */
		['uif-backlog-data']
			.concat(boardId ?? '')
			.concat(config ? `config-${config}` : '')
			.filter(Boolean)
			.join('-'),
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-nextgen-plandata-rest-old", jiraSpaEntry: "async-nextgen-plandata-rest-old" */ './get-data-old'
		),
	// Setting mexAge to 0 so it will get the data again after coming back from a config page
	maxAge: 0,
});

export const uifBacklogResource = createResource<UIFBacklogCachedDataResult | null>({
	type: RESOURCE_TYPE_NEXTGEN_BACKLOG_REST_RESOURCE,
	getKey: ({
		match: {
			params: { boardId },
		},
		query: { config },
	}) =>
		/*
            When navigating to and from the board settings page we need
            the data to get reloaded. So we are updating the key to force a refresh
        */
		['uif-backlog-data']
			.concat(boardId ?? '')
			.concat(config ? `config-${config}` : '')
			.filter(Boolean)
			.join('-'),
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-nextgen-plandata-rest", jiraSpaEntry: "async-nextgen-plandata-rest" */ './get-data'
		),
	// Setting mexAge to 0 so it will get the data again after coming back from a config page
	maxAge: 0,
});
