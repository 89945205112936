import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ProductsJswStorageManagementType from '@atlassian/jira-spa-apps-products-jsw-storage-management';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyProductsJswStorageManagement = lazyForPaint<
	typeof ProductsJswStorageManagementType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-products-jsw-storage-management", jiraSpaEntry: "async-products-jsw-storage-management" */ '@atlassian/jira-spa-apps-products-jsw-storage-management'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyProductsJswStorageManagement}
		pageId="products-jsw-storage-management"
		shouldShowSpinner
	/>
);
