import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type PlanSummaryType from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-summary';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyPlanSummary = lazyForPaint<typeof PlanSummaryType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-plan-summary", jiraSpaEntry: "async-plan-summary" */ '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-summary'
			),
		),
	{ ssr: false },
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => <LazyPage Page={LazyPlanSummary} pageId="plan-summary" shouldShowSpinner />;
