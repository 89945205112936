import type { Action } from 'react-sweet-state';
import type { LayoutState, BottomRightPortalState } from '../../../../common/types';

export const registerBottomRightCornerContainer =
	(ref: HTMLElement | null | undefined): Action<LayoutState> =>
	({ setState, getState }) => {
		const { bottomRightCorner } = getState();

		setState({
			bottomRightCorner: {
				...bottomRightCorner,
				container: ref ?? undefined,
			},
		});
	};

export const insertBottomRightCornerPortal =
	(item: BottomRightPortalState): Action<LayoutState> =>
	// @ts-expect-error - TS2322 - Type '({ setState, getState }: StoreActionApi<LayoutState>) => number' is not assignable to type 'Action<LayoutState, void, void | Promise<void>>'.
	({ setState, getState }) => {
		const { bottomRightCorner } = getState();
		const { portals } = bottomRightCorner;
		const sorted = [item, ...portals].sort((a, b) => b.orderFromRight - a.orderFromRight);
		const index = sorted.findIndex((portal) => portal.id === item.id);

		setState({
			bottomRightCorner: {
				...bottomRightCorner,
				portals: sorted,
			},
		});

		return index;
	};

export const removeBottomRightCornerPortal =
	(id: string): Action<LayoutState> =>
	({ setState, getState }) => {
		const { bottomRightCorner } = getState();
		const { portals } = bottomRightCorner;

		setState({
			bottomRightCorner: {
				...bottomRightCorner,
				portals: portals.filter((portal) => portal.id !== id),
			},
		});
	};
