import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type DetailsSettingsType from '@atlassian/jira-spa-apps-project-settings-details';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyDetailsSettings = lazyForPaint<typeof DetailsSettingsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-project-settings-details", jiraSpaEntry: "async-project-settings-details" */ '@atlassian/jira-spa-apps-project-settings-details'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyDetailsSettings}
		pageId="project-settings-details"
		shouldShowSpinner={false}
	/>
);
