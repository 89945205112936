import { createStore } from 'react-sweet-state';
import * as actions from './actions';
import type { State } from './types';

export type Actions = typeof actions;

const initialState: State = {
	'issue-view-experiment': {
		data: { value: false },
		loading: false,
		error: null,
	},
};

// @ts-expect-error - TS2344 - Type 'typeof import("/buildeng/bamboo-agent-home/xml-data/build-dir/JF-TSMIG123-APPLY/src/packages/platform/services/user-preferences/src/actions/index")' does not satisfy the constraint 'Record<string, ActionThunk<State, typeof import("/buildeng/bamboo-agent-home/xml-data/build-dir/JF-TSMIG123-APPLY/src/packages/platform/services/user-preferences/src/actions/index")>>'.
export default createStore<State, Actions>({
	name: 'user-preference',
	initialState,
	actions,
});
