import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const eocOverviewRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_OVERVIEW,
	path: '/jira/ops/overview',
};
