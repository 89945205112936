import React from 'react';
import noop from 'lodash/noop';
import {
	Banner as LegacyBanner,
	BANNER_HEIGHT,
	Content as LegacyContent,
	LEFT_PANEL_WIDTH,
	LEFT_SIDEBAR_WIDTH,
	LeftPanel as LegacyLeftPanel,
	LeftSidebar as LegacyLeftSidebar,
	LeftSidebarWithoutResize as LegacyLeftSidebarWithoutResize,
	Main as LegacyMain,
	PageLayout as LegacyPageLayout,
	RIGHT_PANEL_WIDTH,
	RIGHT_SIDEBAR_WIDTH,
	RightPanel as LegacyRightPanel,
	RightSidebar as LegacyRightSidebar,
	TOP_NAVIGATION_HEIGHT,
	TopNavigation as LegacyTopNavigation,
	useCustomSkipLink as useLegacyCustomSkipLink,
	useLeftSidebarFlyoutLock as useLegacyLeftSidebarFlyoutLock,
	usePageLayoutResize as useLegacyPageLayoutResize,
} from '@atlaskit/page-layout';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout';
import {
	Aside,
	Banner as NewBanner,
	Main as NewMain,
	Panel,
	Root,
	SideNav,
	TopBar,
} from '@atlassian/navigation-system';
import { SideNavCollapseButton } from '@atlassian/navigation-system/layout/side-nav';

export function PageLayout(props: React.ComponentProps<typeof LegacyPageLayout>) {
	return getWillShowNav4() ? (
		<Root UNSAFE_dangerouslyHoistSlotSizes testId={props.testId}>
			{props.children}
		</Root>
	) : (
		<LegacyPageLayout {...props} />
	);
}

export function Banner(props: React.ComponentProps<typeof LegacyBanner>) {
	return getWillShowNav4() ? (
		<NewBanner UNSAFE_height={props.height} testId={props.testId}>
			{props.children}
		</NewBanner>
	) : (
		<LegacyBanner {...props} />
	);
}

export function TopNavigation(props: React.ComponentProps<typeof LegacyTopNavigation>) {
	return getWillShowNav4() ? (
		<TopBar UNSAFE_height={props.height} testId={props.testId}>
			{props.children}
		</TopBar>
	) : (
		<LegacyTopNavigation {...props} />
	);
}

export function LeftPanel(props: React.ComponentProps<typeof LegacyLeftPanel>) {
	// LeftPanel is not supported in new page layout
	return getWillShowNav4() ? null : <LegacyLeftPanel {...props} />;
}

export function LeftSidebarWithoutResize(
	props: React.ComponentProps<typeof LegacyLeftSidebarWithoutResize>,
) {
	return getWillShowNav4() ? (
		<SideNav
			defaultWidth={props.width}
			testId={props.testId}
			collapseButton={<SideNavCollapseButton />}
		>
			{props.children}
		</SideNav>
	) : (
		<LegacyLeftSidebarWithoutResize {...props} />
	);
}

export function LeftSidebar(props: React.ComponentProps<typeof LegacyLeftSidebar>) {
	return getWillShowNav4() ? (
		<SideNav
			defaultCollapsed={props.collapsedState === 'collapsed'}
			defaultWidth={props.width}
			testId={props.testId}
			collapseButton={<SideNavCollapseButton />}
		>
			{props.children}
		</SideNav>
	) : (
		<LegacyLeftSidebar {...props} />
	);
}

export function Main(props: React.ComponentProps<typeof LegacyMain>) {
	return getWillShowNav4() ? (
		<NewMain isFixed={props.isFixed} testId={props.testId}>
			{props.children}
		</NewMain>
	) : (
		<LegacyMain {...props} />
	);
}

export function Content(props: React.ComponentProps<typeof LegacyContent>) {
	return getWillShowNav4() ? (
		// There isn't a concept of a `Content` wrapper component in the new page layout
		props.children
	) : (
		<LegacyContent {...props} />
	);
}

export function RightSidebar(props: React.ComponentProps<typeof LegacyRightSidebar>) {
	return getWillShowNav4() ? (
		<Aside isFixed={props.isFixed} defaultWidth={props.width} testId={props.testId}>
			{props.children}
		</Aside>
	) : (
		<LegacyRightSidebar {...props} />
	);
}

export function RightPanel(props: React.ComponentProps<typeof LegacyRightPanel>) {
	return getWillShowNav4() ? (
		<Panel defaultWidth={props.width} testId={props.testId}>
			{props.children}
		</Panel>
	) : (
		<LegacyRightPanel {...props} />
	);
}

/**
 * Hooks do not exist in the new page layout, and are no-op'd
 */

export const useCustomSkipLink: typeof useLegacyCustomSkipLink = (id, title, index) =>
	getWillShowNav4()
		? {}
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useLegacyCustomSkipLink(id, title, index);

export const useLeftSidebarFlyoutLock: typeof useLegacyLeftSidebarFlyoutLock = () =>
	getWillShowNav4()
		? {}
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useLegacyLeftSidebarFlyoutLock();

// A mock value is needed to match the signature of the hook
const mockPageLayoutResize = {
	isLeftSidebarCollapsed: false,
	expandLeftSidebar: noop,
	collapseLeftSidebar: noop,
	leftSidebarState: {
		isFlyoutOpen: false,
		isResizing: false,
		isLeftSidebarCollapsed: false,
		leftSidebarWidth: 0,
		lastLeftSidebarWidth: 0,
		flyoutLockCount: 0,
		isFixed: true,
	},
	setLeftSidebarState: noop,
	toggleLeftSidebar: noop,
};

export const usePageLayoutResize: typeof useLegacyPageLayoutResize = () =>
	getWillShowNav4()
		? mockPageLayoutResize
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useLegacyPageLayoutResize();

export const UNSAFE_DO_NOT_USE_BANNER_HEIGHT = BANNER_HEIGHT;
export const UNSAFE_DO_NOT_USE_LEFT_PANEL_WIDTH = LEFT_PANEL_WIDTH;
export const UNSAFE_DO_NOT_USE_LEFT_SIDEBAR_WIDTH = LEFT_SIDEBAR_WIDTH;
export const UNSAFE_DO_NOT_USE_RIGHT_PANEL_WIDTH = RIGHT_PANEL_WIDTH;
export const UNSAFE_DO_NOT_USE_RIGHT_SIDEBAR_WIDTH = RIGHT_SIDEBAR_WIDTH;
export const UNSAFE_DO_NOT_USE_TOP_NAVIGATION_HEIGHT = TOP_NAVIGATION_HEIGHT;

// eslint-disable-next-line jira/export/no-external-exports
export { CreateButton, Help, Notifications } from '@atlassian/navigation-system';
