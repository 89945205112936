export const RESOURCE_TYPE_ISSUE_TRANSITION_CHANGEBOARDING_PREFERENCES =
	'issue_transition_changeboarding_preferences';

export const ISSUE_TRANSITION_CHANGEBOARDING_PREFERENCES_STATUS_RESOURCE_KEY =
	'issue_transition_changeboarding_preferences_status';

export const ISSUE_TRANSITION_CHANGEBOARDING_PREFERENCES_VISIBILITY_API =
	'/rest/api/3/mypreferences?key=jira.its.changeboarding.preferences';

export const USER_SEEN_CARDINALITY_UNKNOWN = -1;
