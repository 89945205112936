/**
 * @generated SignedSource<<1c85df49bf87849dab4d439bc70a8c3b>>
 * @relayHash a9b5647d8459f16784cadf784c08ee01
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f31dd28adcaf6c18a0dc9713c957a224b1432a8978a87fc1069abdd23c838a83

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { ssrExperiment_ui_growthRecommendationsInProjectList_RecommendationsInProjectListQuery } from './ssrExperiment_ui_growthRecommendationsInProjectList_RecommendationsInProjectListQuery.graphql';

const node: PreloadableConcreteRequest<ssrExperiment_ui_growthRecommendationsInProjectList_RecommendationsInProjectListQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "f31dd28adcaf6c18a0dc9713c957a224b1432a8978a87fc1069abdd23c838a83",
    "metadata": {},
    "name": "ssrExperiment_ui_growthRecommendationsInProjectList_RecommendationsInProjectListQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
