import { setStrictlyNecessaryCookie as setControlledStrictlyNecessaryCookie } from '@atlassian/browser-storage-controls';
import type { CookieAttributes } from '../../types';

export const setStrictlyNecessaryCookie = (
	key: string,
	value: string,
	attributes?: CookieAttributes,
) => {
	setControlledStrictlyNecessaryCookie(key, value, attributes);
};
