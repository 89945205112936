import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ProductsJsmChatopsAndVideoToolsType from '@atlassian/jira-spa-apps-products-jsm-chatops-and-video-tools';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyProductsJsmChatopsAndVideoTools = lazyForPaint<
	typeof ProductsJsmChatopsAndVideoToolsType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-products-jsm-chatops-and-video-tools", jiraSpaEntry: "async-products-jsm-chatops-and-video-tools" */ '@atlassian/jira-spa-apps-products-jsm-chatops-and-video-tools'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyProductsJsmChatopsAndVideoTools}
		pageId="products-jsm-chatops-and-video-tools"
		shouldShowSpinner={false}
	/>
);
