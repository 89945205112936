import type { SidebarConfigData, NavLink } from '@atlassian/jira-navigation-types';
import { connectPrefix, jsdConnectPrefix, connectSections } from '../../constants';

type Options = {
	skipFilter?: boolean;
	omitSections?: boolean;
};

const findChildLinks = (arrayOfLinks: NavLink[], legacyId: string): NavLink[] | undefined =>
	arrayOfLinks &&
	arrayOfLinks
		.map((item) => {
			if (item.id === legacyId) {
				return item.links;
			}
			return item.links && findChildLinks(item.links, legacyId);
		})
		.find(Boolean);

const getLinksFromSidebar = (sidebarConfig: SidebarConfigData, legacyId?: string): NavLink[] => {
	const links =
		legacyId != null
			? findChildLinks(sidebarConfig.product && sidebarConfig.product.links, legacyId)
			: sidebarConfig.product && sidebarConfig.product.links;
	return links || [];
};

export const getLinks = (
	sidebarConfig: SidebarConfigData,
	locations: string[],
	{ skipFilter, omitSections }: Options = {},
): NavLink[] => {
	const acc: Array<NavLink> = [];

	locations.forEach((location) => {
		const links = getLinksFromSidebar(sidebarConfig, location);

		if (omitSections === true) {
			// merge 2 arrays in good performance way
			Array.prototype.push.apply(
				acc,
				links.flatMap((item) => (item && item.section === true ? item.links || [] : item)),
			);
			return;
		}

		// merge 2 arrays in good performance way
		Array.prototype.push.apply(acc, links);
	});

	return acc.filter((item) => {
		if (!item) return false;

		if (skipFilter === true) {
			return true;
		}

		const hasConnectPrefix = item.id && item.id.includes(connectPrefix);
		if (hasConnectPrefix) {
			return true;
		}

		// @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type '"sd-reports-addons" | "sd-queues-addons" | "com.atlassian.jira.jira-projects-plugin:tab-panel-link-parent"'.
		const isConnectSection = connectSections.includes(item.id);
		if (isConnectSection) {
			return true;
		}

		const isJsdConnectSection = item.id && item.id.includes(jsdConnectPrefix);
		if (isJsdConnectSection) {
			return true;
		}

		return false;
	});
};
