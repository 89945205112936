import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { createChromelessEntryPoint } from '@atlassian/jira-create-route-entry-point';
import type JiraBusinessCalendarEmbedAppType from '@atlassian/jira-spa-apps-business-calendar-embed';
import { calendarEntryPoint } from '@atlassian/jira-spa-apps-business-calendar-new/entrypoint';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const businessCalendarEmbedRouteEntry = createChromelessEntryPoint({
	main: calendarEntryPoint,
});

export const LazyCalendarEmbedView = lazyForPaint<typeof JiraBusinessCalendarEmbedAppType>(
	() =>
		import(
			/* webpackChunkName: "async-business-calendar-embed", jiraSpaEntry: "async-business-calendar-embed" */ '@atlassian/jira-spa-apps-business-calendar-embed'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazyCalendarEmbedView} pageId="async-business-calendar-embed" shouldShowSpinner />
);
