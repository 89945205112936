import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type SoftwareNotificationsSettingsType from '@atlassian/jira-spa-apps-project-settings-software-notifications';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazySoftwareNotificationsSettings = lazyForPaint<
	typeof SoftwareNotificationsSettingsType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-project-settings-software-notifications", jiraSpaEntry: "async-project-settings-software-notifications" */ '@atlassian/jira-spa-apps-project-settings-software-notifications'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazySoftwareNotificationsSettings}
		pageId="project-settings-software-notifications"
		shouldShowSpinner={false}
	/>
);
