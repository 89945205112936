import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type TrashedProjectsAdminType from '@atlassian/jira-spa-apps-trashed-projects';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyTrashedProjectsAdmin = lazyForPaint<typeof TrashedProjectsAdminType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-trashed-projects", jiraSpaEntry: "async-trashed-projects" */ '@atlassian/jira-spa-apps-trashed-projects'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazyTrashedProjectsAdmin} pageId="trashed-projects" shouldShowSpinner />
);
