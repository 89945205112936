import React from 'react';
import { ThemeContainer } from '@atlassian/jira-controllers-global-theme';
import getMeta from '@atlassian/jira-get-meta';
import {
	DocumentTitleContainer,
	ripTitleTemplateFromMeta,
} from '@atlassian/jira-global-document-title';
import type { PageConfigContainersProps } from './types';

export const PageConfigContainers = ({ children }: PageConfigContainersProps) => (
	<ThemeContainer isGlobal getMeta={getMeta}>
		<DocumentTitleContainer titleSuffix={ripTitleTemplateFromMeta(getMeta)}>
			{children}
		</DocumentTitleContainer>
	</ThemeContainer>
);
