import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

const SOFTWARE_PRODUCT = 'software';
const projectSettingsUrl = (product: string) => `/jira/${product}/projects/:projectKey/settings`;
const boardSettingsUrl = (product: string) => `${projectSettingsUrl(product)}/boards/:boardId`;

export const routeGroupsProjectSettingsSoftwareIssuetypesRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: `${ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE}-issuetypes`,
	path: `${projectSettingsUrl(SOFTWARE_PRODUCT)}/issuetypes/:issueTypeId?`,
	exact: true,
};

export const routeGroupsProjectSettingsSoftwareIssuetypesWorkflowRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: `${ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE}-issuetypes-workflow`,
	path: `${projectSettingsUrl(SOFTWARE_PRODUCT)}/issuetypes/:issueTypeId/:initialSection(workflow)`,
	exact: true,
};

export const routeGroupsProjectSettingsSoftwareAppsFieldsRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: ROUTE_NAMES.PROJECT_SETTINGS_SOFTWARE_APPS_FIELDS,
	path: `${projectSettingsUrl(SOFTWARE_PRODUCT)}/apps/app-fields`,
	exact: true,
};

export const routeGroupsProjectSettingsSoftwareApps1Route = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: `${ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE}-apps`,
	path: `${projectSettingsUrl(SOFTWARE_PRODUCT)}/apps/:appId?`,
	exact: true,
};

export const routeGroupsProjectSettingsSoftwareDetailsRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: `${ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE}-details`,
	path: `${projectSettingsUrl(SOFTWARE_PRODUCT)}/details`,
	exact: true,
};

export const routeGroupsProjectSettingsSoftwareAccessRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: `${ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE}-access`,
	path: `${projectSettingsUrl(SOFTWARE_PRODUCT)}/access`,
	exact: true,
};

export const routeGroupsProjectSettingsSoftwareAccessRedirectRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: `${ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE}-access-redirect`,
	path: `${projectSettingsUrl(SOFTWARE_PRODUCT)}/people`,
	exact: true,
};

export const routeGroupsProjectSettingsSoftwareProformaProjectFormsRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: `${ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE}-proforma-project-forms`,
	path: `${projectSettingsUrl(SOFTWARE_PRODUCT)}/forms`,
	exact: true,
};

export const routeGroupsProjectSettingsSoftwareProformaJiraFormBuilderRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: `${ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE}-proforma-jira-form-builder`,
	path: `${projectSettingsUrl(SOFTWARE_PRODUCT)}/forms/form/:templateFormId/edit`,
	exact: true,
};

export const projectSettingsSoftwareAutomationRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: ROUTE_NAMES.PROJECT_SETTINGS_AUTOMATION,
	path: `${projectSettingsUrl(SOFTWARE_PRODUCT)}/automate`,
};

export const routeGroupsProjectSettingsSoftwareDataClassificationsRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: `${ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE}-data-classifications`,
	path: `${projectSettingsUrl(SOFTWARE_PRODUCT)}/data-classifications`,
	exact: true,
};

export const routeGroupsProjectSettingsSoftwareNextGenFeaturesRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: `${ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE}-next-gen-features`,
	path: `${projectSettingsUrl(SOFTWARE_PRODUCT)}/features`,
	exact: true,
};

export const projectSettingsSoftwareNotificationsRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: ROUTE_NAMES.PROJECT_SETTINGS_SOFTWARE_NOTIFICATIONS,
	path: `${projectSettingsUrl(SOFTWARE_PRODUCT)}/notifications`,
	exact: true,
};

export const projectSettingsOpsgenieRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: ROUTE_NAMES.PROJECT_SETTINGS_SOFTWARE_OPSGENIE,
	path: `${projectSettingsUrl(SOFTWARE_PRODUCT)}/opsgenie`,
	exact: true,
};

export const classicProjectSettingsSoftwareRedirectRoute = {
	// the following route should be non-exact, it will handle "index" and "404" cases
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: `${ROUTE_GROUPS.CLASSIC_SOFTWARE}-redirect`,
	path: `/jira/${SOFTWARE_PRODUCT}/c/projects/:projectKey/settings`,
};

export const classicProjectSettingsDetailsRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: ROUTE_NAMES.CLASSIC_PROJECT_SETTINGS_SOFTWARE_DETAILS,
	path: '/jira/software/c/projects/:projectKey/settings/details',
	exact: true,
};

export const classicProjectSettingsOpsgenieRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: ROUTE_NAMES.CLASSIC_PROJECT_SETTINGS_OPSGENIE,
	path: '/jira/software/c/projects/:projectKey/settings/opsgenie',
	exact: true,
};

export const projectSettingsToolchainRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: ROUTE_NAMES.PROJECT_SETTINGS_TOOLCHAIN,
	path: `${projectSettingsUrl(SOFTWARE_PRODUCT)}/toolchain`,
	exact: true,
};

export const classicProjectSettingsToolchainRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: ROUTE_NAMES.CLASSIC_PROJECT_SETTINGS_TOOLCHAIN,
	path: '/jira/software/c/projects/:projectKey/settings/toolchain',
	exact: true,
};

export const classicProjectSettingsFeaturesRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: ROUTE_NAMES.CLASSIC_PROJECT_SETTINGS_FEATURES,
	path: '/jira/software/c/projects/:projectKey/settings/features',
	exact: true,
};

export const projectSettingsSoftwareNotificationEmailRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: ROUTE_NAMES.PROJECT_SETTINGS_SOFTWARE_NOTIFICATION_EMAIL,
	path: '/jira/software/c/projects/:projectKey/settings/notifications/notification-email',
	exact: true,
};

export const projectSettingsSoftwareNotificationEmail1Route = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: ROUTE_NAMES.PROJECT_SETTINGS_SOFTWARE_NOTIFICATION_EMAIL,
	path: '/jira/software/projects/:projectKey/settings/notifications/notification-email',
	exact: true,
};

export const legacyProjectReleasesNoteRoute = {
	group: ROUTE_GROUPS.CLASSIC_SOFTWARE,
	name: ROUTE_NAMES.LEGACY_PROJECT_RELEASES_NOTE,
	path: '/jira/secure/ReleaseNote.jspa',
	query: ['projectId', 'version'],
};

export const legacyProjectReleasesNoteConfigRoute = {
	group: ROUTE_GROUPS.CLASSIC_SOFTWARE,
	name: ROUTE_NAMES.LEGACY_PROJECT_RELEASES_NOTE_CONFIG,
	path: '/secure/ConfigureReleaseNote.jspa',
	query: ['projectId', 'version'],
};

export const softwareReleaseNotesRoute = {
	group: ROUTE_GROUPS.CLASSIC_SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_RELEASE_NOTES,
	path: '/secure/ReleaseNote.jspa',
	query: ['projectId'],
};

export const routeGroupsProjectSettingsSoftwareAutomationRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: `${ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE}-automation`,
	path: `${projectSettingsUrl(SOFTWARE_PRODUCT)}/automation`,
	exact: true,
};

export const projectSettingsSoftwareNotificationsIssueLogsRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: ROUTE_NAMES.PROJECT_SETTINGS_SOFTWARE_NOTIFICATIONS_ISSUE_LOGS,
	path: `${projectSettingsUrl(SOFTWARE_PRODUCT)}/notifications/issue-logs`,
	exact: true,
};

export const classicProjectSettingsSoftwareNotificationsIssueLogsRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: ROUTE_NAMES.CLASSIC_PROJECT_SETTINGS_SOFTWARE_NOTIFICATIONS_ISSUE_LOGS,
	path: '/jira/software/c/projects/:projectKey/settings/notifications/issue-logs',
	query: ['projectId'],
};

export const classicProjectSettingsSoftwareProformaFormsRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: ROUTE_NAMES.CLASSIC_PROJECT_SETTINGS_SOFTWARE_PROFORMA_FORMS,
	path: '/jira/software/c/projects/:projectKey/settings/forms',
	exact: true,
};

export const classicProjectSettingsSoftwareProformaEditFormsRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: ROUTE_NAMES.CLASSIC_PROJECT_SETTINGS_SOFTWARE_PROFORMA_EDIT_FORMS,
	path: '/jira/software/c/projects/:projectKey/settings/forms/form/:templateFormId/edit',
	exact: true,
};

export const projectSettingsSoftwareBoardsRedirectRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: ROUTE_NAMES.PROJECT_SETTINGS_SOFTWARE_BOARDS_REDIRECT,
	path: `${boardSettingsUrl(SOFTWARE_PRODUCT)}`,
	exact: true,
};

export const projectSettingsSoftwareBoardsCsmRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: ROUTE_NAMES.PROJECT_SETTINGS_SOFTWARE_BOARDS_CSM,
	path: `${boardSettingsUrl(SOFTWARE_PRODUCT)}/columns`,
	exact: true,
};

export const projectSettingsSoftwareBoardsCustomFiltersRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: ROUTE_NAMES.PROJECT_SETTINGS_SOFTWARE_BOARDS_CUSTOM_FILTERS,
	path: `${boardSettingsUrl(SOFTWARE_PRODUCT)}/custom-filters`,
	exact: true,
};

export const projectSettingsSoftwareBoardsCardRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: ROUTE_NAMES.PROJECT_SETTINGS_SOFTWARE_BOARDS_CARD,
	path: `${boardSettingsUrl(SOFTWARE_PRODUCT)}/card`,
	exact: true,
};

export const projectSettingsSoftwareBoardsRoadmapRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: ROUTE_NAMES.PROJECT_SETTINGS_SOFTWARE_BOARDS_ROADMAP,
	path: `${boardSettingsUrl(SOFTWARE_PRODUCT)}/roadmap`,
	exact: true,
};

export const projectSettingsSoftwareBoardsTimelineRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: ROUTE_NAMES.PROJECT_SETTINGS_SOFTWARE_BOARDS_TIMELINE,
	path: `${boardSettingsUrl(SOFTWARE_PRODUCT)}/timeline`,
	exact: true,
};

export const projectSettingsAutomation1Route = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: ROUTE_NAMES.PROJECT_SETTINGS_AUTOMATION,
	path: '/jira/software/c/projects/:projectKey/settings/automate',
};

export const projectSettingsForgeAppsRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: ROUTE_NAMES.PROJECT_SETTINGS_FORGE_APPS,
	path: '/jira/software/c/projects/:projectKey/settings/apps/:appId/:envId/:route?',
};

export const projectSettingsSoftwareBoardsInsightsRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: ROUTE_NAMES.PROJECT_SETTINGS_SOFTWARE_BOARDS_INSIGHTS,
	path: `${boardSettingsUrl(SOFTWARE_PRODUCT)}/insights/:location`,
	exact: true,
};

export const projectSettingsForgeAppsNextgenRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: ROUTE_NAMES.PROJECT_SETTINGS_FORGE_APPS_NEXTGEN,
	path: '/jira/software/projects/:projectKey/settings/apps/:appId/:envId/:route?',
};

export const routeGroupsProjectSettingsSoftwareRedirectRoute = {
	// the following route should be non-exact, it will handle "index" and "404" cases
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: `${ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE}-redirect`,
	path: `/jira/${SOFTWARE_PRODUCT}/projects/:projectKey/settings`,
};

export const routeGroupsProjectSettingsSoftwareFieldsRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE,
	name: `${ROUTE_GROUPS.PROJECT_SETTINGS_SOFTWARE}-fields`,
	path: `${projectSettingsUrl(SOFTWARE_PRODUCT)}/fields`,
	exact: true,
};
