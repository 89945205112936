import { createResource } from '@atlassian/jira-router';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils';
import { RESOURCE_TYPE_PROJECT_CONTEXT } from '../../constants';
import type { ProjectContext } from './types';

export type { ProjectContext, ProjectContextData } from './types';

const getDataLoader = () =>
	import(
		/* webpackChunkName: "async-resource-project-context", jiraSpaEntry: "async-resource-project-context" */ './get-data'
	);

export const projectContextResource = createResource<ProjectContext | null>({
	type: RESOURCE_TYPE_PROJECT_CONTEXT,
	getKey: (matchedRoute) => {
		const { projectKey, projectId } = getProjectKeyId(matchedRoute.match, matchedRoute.query);
		return projectKey || projectId;
	},
	getDataLoader,
	maxAge: 30 * 60 * 1000,
});

export const projectContextResourceWithCache = projectContextResource;
