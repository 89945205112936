import memoizeOne from 'memoize-one';
import {
	ROUTE_NAMES_ISSUE,
	ROUTE_NAMES_ISSUE_EMBED,
	ROUTE_NAMES_RAPIDBOARD_BACKLOG,
	ROUTE_NAMES_RAPIDBOARD_BOARD,
	ROUTE_NAMES_RAPIDBOARD_REPORT,
	ROUTE_NAMES_YOUR_WORK,
	ROUTE_NAMES_PROJECTS_DIRECTORY,
	ROUTE_NAMES_PROJECTS_DIRECTORY_LEGACY_PATH_SPA,
	ROUTE_NAMES_BOARDS_DIRECTORY,
	ROUTE_NAMES_FILTERS_DIRECTORY,
	ROUTE_NAMES_FILTERS_DIRECTORY_SECURE,
	ROUTE_NAMES_DASHBOARDS_DIRECTORY,
	ROUTE_NAMES_DASHBOARDS_DIRECTORY_SECURE,
	ROUTE_NAMES_PROJECT_COMPONENTS,
	ROUTE_NAMES_PROJECT_PAGES,
	ROUTE_NAMES_PROJECT_VERSIONS,
	ROUTE_NAMES_SOFTWARE_BOARDS,
	ROUTE_NAMES_SOFTWARE_BACKLOG,
	ROUTE_NAMES_SOFTWARE_TIMELINE,
	ROUTE_NAMES_SOFTWARE_ROADMAP,
	ROUTE_NAMES_SOFTWARE_ROADMAP_CLASSIC,
	ROUTE_NAMES_SOFTWARE_TIMELINE_CLASSIC,
	ROUTE_NAMES_SOFTWARE_REPORTS,
	ROUTE_NAMES_SOFTWARE_REPORTS_BURNUP,
	ROUTE_NAMES_SOFTWARE_REPORTS_BURNDOWN,
	ROUTE_NAMES_SOFTWARE_REPORTS_CUMULATIVE,
	ROUTE_NAMES_SOFTWARE_REPORTS_VELOCITY,
	ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR,
	ROUTE_NAMES_GLOBAL_ISSUE_NAVIGATOR,
	ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR_CLASSIC,
	ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR_CORE,
	ROUTE_NAMES_SOFTWARE_ISSUE_NAVIGATOR,
	ROUTE_NAMES_SYSTEM_DASHBOARD_EDIT,
} from '@atlassian/jira-common-constants/src/spa-routes';
import getMeta from '@atlassian/jira-get-meta';

const FIRST_RELEASE_ROUTES = [
	ROUTE_NAMES_ISSUE,
	ROUTE_NAMES_ISSUE_EMBED,
	ROUTE_NAMES_RAPIDBOARD_BACKLOG,
	ROUTE_NAMES_RAPIDBOARD_BOARD,
	ROUTE_NAMES_RAPIDBOARD_REPORT,
	ROUTE_NAMES_YOUR_WORK,
	ROUTE_NAMES_PROJECTS_DIRECTORY,
	ROUTE_NAMES_PROJECTS_DIRECTORY_LEGACY_PATH_SPA,
	ROUTE_NAMES_BOARDS_DIRECTORY,
	ROUTE_NAMES_FILTERS_DIRECTORY,
	ROUTE_NAMES_FILTERS_DIRECTORY_SECURE,
	ROUTE_NAMES_DASHBOARDS_DIRECTORY,
	ROUTE_NAMES_DASHBOARDS_DIRECTORY_SECURE,
	ROUTE_NAMES_PROJECT_COMPONENTS,
	ROUTE_NAMES_PROJECT_PAGES,
	ROUTE_NAMES_PROJECT_VERSIONS,
	ROUTE_NAMES_SOFTWARE_BOARDS,
	ROUTE_NAMES_SOFTWARE_BACKLOG,
	ROUTE_NAMES_SOFTWARE_TIMELINE,
	ROUTE_NAMES_SOFTWARE_ROADMAP,
	ROUTE_NAMES_SOFTWARE_ROADMAP_CLASSIC,
	ROUTE_NAMES_SOFTWARE_TIMELINE_CLASSIC,
	ROUTE_NAMES_SOFTWARE_REPORTS,
	ROUTE_NAMES_SOFTWARE_REPORTS_BURNUP,
	ROUTE_NAMES_SOFTWARE_REPORTS_BURNDOWN,
	ROUTE_NAMES_SOFTWARE_REPORTS_CUMULATIVE,
	ROUTE_NAMES_SOFTWARE_REPORTS_VELOCITY,
	ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR,
	ROUTE_NAMES_GLOBAL_ISSUE_NAVIGATOR,
	ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR_CLASSIC,
	ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR_CORE,
	ROUTE_NAMES_SOFTWARE_ISSUE_NAVIGATOR,
	ROUTE_NAMES_SYSTEM_DASHBOARD_EDIT,
];

export const LOADER_STYLES_CSS_SELECTOR = 'body::before';
export const LOADER_STYLESHEET_ID = 'loaderStylesheet';
export const DEFAULT_LOADING_BAR_COLOR = 'rgb(76, 154, 255)';

export const getAllowedRoutes = memoizeOne(() => {
	const ALLOWED_ROUTES: string[] = FIRST_RELEASE_ROUTES;
	return ALLOWED_ROUTES;
});

export const getLoadingBarColor = memoizeOne(
	() => getMeta('ajs-lf-navigation-highlightcolour') || DEFAULT_LOADING_BAR_COLOR,
);
