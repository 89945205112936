import {
	useRouter,
	type RouterContext,
	type RouterDataContext,
	type Match,
} from '@atlassian/jira-router';
import { getProjectKeyOrId } from '@atlassian/jira-router-resources-utils';

export function useIsProjectUrl() {
	const [{ match }] = useRouter();

	return isProjectURL(match);
}

export function isProjectRoute({ match }: RouterContext | RouterDataContext) {
	return isProjectURL(match);
}

function isProjectURL(match: Match) {
	const projectKeyOrId = getProjectKeyOrId(match);
	if (projectKeyOrId.length > 0) {
		return true;
	}
	return match.path === '/jira/projects';
}
