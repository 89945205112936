import { fetch } from '@atlassian/eoc-fetch';

import { type TeamsResponse } from '../../common/types';

interface GetTeamsParams {
	accountId?: string;
	keyword?: string;
	size?: number;
	offset?: number;
	showAvatar?: boolean;
}

export const getTeams = ({ accountId, keyword, size = 10, offset, showAvatar }: GetTeamsParams) => {
	const params = new URLSearchParams();

	if (keyword) {
		params.append('keyword', keyword);
	}

	if (size) {
		params.append('size', size.toString());
	}

	if (offset) {
		params.append('offset', offset.toString());
	}

	if (accountId) {
		params.append('memberId', accountId);
	}

	if (showAvatar) {
		params.append('showAvatar', String(showAvatar));
	}

	const url = `/v1/teams${params.toString() ? `?${params.toString()}` : ''}`;

	return fetch<TeamsResponse>(url);
};
