import type { SidebarConfigData } from '@atlassian/jira-navigation-types';
import { useResource, type RouteResourceError } from '@atlassian/jira-router';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src';

type UseNavigationGlobalResourceReturn = {
	data: SidebarConfigData | null;
	loading: boolean;
	error: RouteResourceError | null;
};

export const useNavigationGlobalResource = (): UseNavigationGlobalResourceReturn => {
	const { data, loading, error } = useResource(navigationSidebarGlobalResource);

	return { data: data && data.response, loading, error };
};
