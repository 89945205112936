import type { Route } from 'react-resource-router';
import { useLastLoadedRoute } from '../store';

export const useRouteEntryPoint = ({ route }: { route: Route }) => {
	const [lastLoadedRoute] = useLastLoadedRoute();

	// route.entryPoint is already loaded
	if (lastLoadedRoute && lastLoadedRoute.entryPointReference && lastLoadedRoute.route === route) {
		return lastLoadedRoute.entryPointReference;
	}

	return null;
};
