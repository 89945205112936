import React, { useEffect, type ReactNode } from 'react';
import { render } from 'react-dom';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
// eslint-disable-next-line jira/restricted/react-18
import { createRoot } from 'react-dom/client';
import LooselyLazy, { MODE, type LazyComponent } from 'react-loosely-lazy';
import { browserMetrics } from '@atlassian/browser-metrics';
import { reportBootstrapError } from '@atlassian/jira-bootstrap-sla-reporter';
import { initBrowserMetrics3 } from '@atlassian/jira-browser-metrics';
import { CAPABILITY_HEADER_NAME, setCapability } from '@atlassian/jira-capabilities';
import '@atlassian/jira-global-side-effects';
import { setMark } from '@atlassian/jira-common-performance';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-legacy
import { getTenantContext_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-common-util-get-tenant-context';
import {
	EntryPointPlaceholderContext,
	getPlaceholdersFromHTML,
} from '@atlassian/jira-entry-point-placeholder';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import {
	ff,
	getFeatureFlagValue,
	initPlatformFeatureFlags,
} from '@atlassian/jira-feature-flagging';
import { NonCritical } from '@atlassian/jira-non-critical';
import { Spa } from '@atlassian/jira-page-container-v2/src/ui/spa';
import { setMatchedRoute, useCurrentRoute } from '@atlassian/jira-platform-router-utils';
import { StrictMode } from '@atlassian/jira-react-strict-mode';
import { matchRoute, useRouterActions, type RouterContext } from '@atlassian/jira-router';
import { Router } from '@atlassian/jira-router-components';
import { JiraRouterLinkConfiguration } from '@atlassian/jira-router-components/src/ui/router-link-config';
import { getRoutes } from '@atlassian/jira-routes';
import SessionTracker from '@atlassian/jira-session-tracker';
import {
	addInitialPageLoadTimings,
	measureInitialPageLoadTiming,
	setInitialPageLoadTimingFromPerformanceMarks,
	stopInitialPageLoadTimingFromPerformanceMarkStart,
} from '@atlassian/jira-spa-performance-breakdown';
import { setPrefetchStartMark } from '@atlassian/jira-spa-transition-resources-tracker';
import createSpaHistory from '@atlassian/jira-spa/src/services/create-spa-history';
import { getSpaRouterContext } from '@atlassian/jira-spa/src/services/spa-router-context';
import initReactUFO from '@atlassian/jira-ufo-interaction-metrics-init';
import UFOSegment from '@atlassian/jira-ufo-segment';
import { GlobalPageLoadExperience } from '@atlassian/ufo';
import getUFORouteName from '@atlassian/ufo-route-name';
import traceUFOPageLoad from '@atlassian/ufo-trace-pageload';
import { useUFOTransitionCompleter } from '@atlassian/ufo-trace-transition';
import { getVCObserver } from '@atlassian/ufo-vc';
import global from './global-core';

const isPreprodSlow = (): number => getFeatureFlagValue<number>('uip.preprod.slow', 0);

setMark('JIRA_SPA_ENTRY_LOADED');

// Set SPA global
window.__SPA__ = true;

LooselyLazy.init({
	mode: MODE.RENDER,
	crossOrigin: 'anonymous',
});

const tenantContext = getTenantContext_DEPRECATED_DO_NOT_USE();

setMark('jira-spa/language-pack.start');
const messages = require('language-pack');

setMark('jira-spa/language-pack.end');

const { locale } = tenantContext;

const superbatchInit = () => {
	window.DeferScripts = { deferState: 'done' };
	document.body && document.body.classList.remove('deferred');
};

const globalSettingsArgs = { navigation: false, upflow: false } as const;

const InstallGlobalComponentsOnce = () => {
	useEffect(() => {
		global.installLowPriorityApps(
			// @ts-expect-error - TS2345 - Argument of type 'Partial<{ readonly navigation: false; readonly upflow: false; }>' is not assignable to parameter of type 'LowPriorityAppsArgs'.
			omit(globalSettingsArgs, ['flags', 'navigation', 'renderNavigation']),
		);
		setMark('JIRA_SPA_ENTRY_GLOBAL_INSTALL');
	}, []);
	return null;
};

const updateCapability = (capability: string | null) => {
	setCapability(capability);
	if (capability === null && window.AJS?.$?.ajaxSettings?.headers?.[CAPABILITY_HEADER_NAME]) {
		delete window.AJS.$.ajaxSettings.headers[CAPABILITY_HEADER_NAME];
	} else {
		window.AJS?.$?.ajaxSetup({ headers: { [CAPABILITY_HEADER_NAME]: capability } });
	}
};

// @ts-expect-error - TS7031 - Binding element 'routes' implicitly has an 'any' type.
const CapabilityTrackerConnector = ({ routes }) => {
	const { registerBlock } = useRouterActions();
	registerBlock((nextLocation: Location) => {
		const nextMatchObject = matchRoute(
			routes,
			nextLocation.pathname,
			// @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'Query | undefined'.
			nextLocation.search,
			'',
		);
		updateCapability(nextMatchObject?.route.meta?.capability || null);
		return true;
	});
	const route = useCurrentRoute();
	updateCapability(route.meta?.capability || null);
	return null;
};

const main = async () => {
	initReactUFO();
	initBrowserMetrics3();
	initPlatformFeatureFlags();
	addInitialPageLoadTimings();

	stopInitialPageLoadTimingFromPerformanceMarkStart('jira-spa/init', 'jira-spa.js:eval-start');
	setMark('jira-spa/setup.start');

	const routes = measureInitialPageLoadTiming('jira-spa/setup/routes', () => getRoutes());
	const history = measureInitialPageLoadTiming('jira-spa/setup/history', () =>
		createSpaHistory(routes),
	);

	const initRoute = measureInitialPageLoadTiming('jira-spa/setup/match-route', () => {
		// @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'Query | undefined'.
		const matchedRoute = matchRoute(routes, window.location.pathname, window.location.search);
		setMatchedRoute(matchedRoute?.route, matchedRoute?.match);
		return matchedRoute;
	});

	if (initRoute != null) {
		const ufoName = getUFORouteName(initRoute.route);
		traceUFOPageLoad(ufoName, initRoute.route.name);
	}

	const BrowserMetricsConnector = () => {
		const route = useCurrentRoute();
		browserMetrics.setRoute(route.name);

		useUFOTransitionCompleter();

		if (ff('uip.ufo')) {
			GlobalPageLoadExperience.setPageLoadId(route.perfMetricKey || 'UNKNOWN');
		}
		return null;
	};

	if (ff('uip.ufo')) {
		GlobalPageLoadExperience.startPageLoad('UNKNOWN', true);
	}

	const routerProps = {
		history,
		routes,
		basePath: '',
		initialRoute: initRoute?.route,
		resourceData: window.SPA_STATE,
		resourceContext: getSpaRouterContext({
			tenantContext,
		}),
		onPrefetch: ({ route, match }: RouterContext) => {
			setPrefetchStartMark(match);

			if (!route.forPaint) {
				return;
			}

			route.forPaint.map(
				(component: LazyComponent<any>) => component.preload && component.preload(),
			);
		},
	};

	const SpaClientRouter = ({ children }: { children: ReactNode }) => (
		<Router {...routerProps}>
			<JSErrorBoundary
				id="spa-main.client-router"
				packageName="jiraEntry"
				fallback="unmount"
				teamName="delorean-spa"
			>
				<BrowserMetricsConnector />
				<CapabilityTrackerConnector routes={routes} />
				{/* @ts-expect-error - TS2739 - Type '{}' is missing the following properties from type 'RouteContext': location, query, route, match, action */}
				<SessionTracker />
			</JSErrorBoundary>
			{children}
			<NonCritical id="spa-main.client-router.non-critical" teamName="delorean-spa">
				<InstallGlobalComponentsOnce />
			</NonCritical>
		</Router>
	);

	const App = ({ placeholders }: { placeholders: Map<string, string> }) => (
		<StrictMode>
			<UFOSegment name="jira-spa">
				<EntryPointPlaceholderContext.Provider value={placeholders}>
					<Spa
						baseUrl=""
						locale={locale}
						messages={messages}
						Router={SpaClientRouter}
						initRoute={initRoute}
						routes={routes}
						routerLinkComponent={JiraRouterLinkConfiguration}
					/>
				</EntryPointPlaceholderContext.Provider>
			</UFOSegment>
		</StrictMode>
	);

	window.__NAV_VERSION__ = '3.0.0';

	stopInitialPageLoadTimingFromPerformanceMarkStart('jira-spa/setup', 'jira-spa/setup.start', true);

	setMark('_jira-spa/spa-shell.start');

	const container = document.getElementById('jira-frontend');

	if (container) {
		if (ff('uip.vc.partial', false)) {
			getVCObserver().setSSRElement(container);
			getVCObserver().setReactRootRenderStart();
			requestAnimationFrame(() => {
				requestAnimationFrame(() => {
					getVCObserver().setReactRootRenderStop();
				});
			});
		}

		const placeholders = getPlaceholdersFromHTML();

		// if the feature flag is false, do not opt into concurrent features
		// if the local env var is not set, or set to false, do not opt into concurrent features
		if (
			!ff('jfp.magma.concurrent-root.jira-spa') &&
			(process.env.JIRA_SPA_CONCURRENT_FEATURES === undefined ||
				process.env.JIRA_SPA_CONCURRENT_FEATURES === 'false')
		) {
			render(<App placeholders={placeholders} />, container);
		}

		if (
			process.env.JIRA_SPA_CONCURRENT_FEATURES === 'true' ||
			ff('jfp.magma.concurrent-root.jira-spa')
		) {
			const root = createRoot(container);
			root.render(<App placeholders={placeholders} />);
		}
	}

	stopInitialPageLoadTimingFromPerformanceMarkStart(
		'jira-spa/spa-shell',
		'_jira-spa/spa-shell.start',
	);
	setMark('JIRA_SPA_ENTRY_REACT_DOM_RENDER');
	setTimeout(() => {
		setInitialPageLoadTimingFromPerformanceMarks(
			'jira-spa',
			'commons-entry.js:eval-stop',
			'jira-spa.js:eval-stop',
		);
	});

	if (isPreprodSlow() > 0) {
		setMark('slow-start');
		const end = performance.now() + isPreprodSlow();
		while (performance.now() < end) {
			// do nothing
		}
		setMark('slow-stop');
	}

	global.installHighPriorityApps(
		// @ts-expect-error - TS2345 - Argument of type 'Partial<{ readonly navigation: false; readonly upflow: false; }>' is not assignable to parameter of type 'HighPriorityAppsArgs'.
		pick(globalSettingsArgs, ['flags', 'navigation', 'renderNavigation']),
	);
	superbatchInit();

	return null;
};

main().catch((reason) => reportBootstrapError(reason));
