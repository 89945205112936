import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type PlanSettingsScheduleType from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-settings/scheduling';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

/**
 * Plan Settings - scheduling page
 */
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyPlanSettingsScheduling = lazyForPaint<typeof PlanSettingsScheduleType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-plan-settings-scheduling", jiraSpaEntry: "async-plan-settings-scheduling" */ '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-settings/scheduling'
			),
		),
	{ ssr: false },
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazyPlanSettingsScheduling} pageId="plan-settings-scheduling" shouldShowSpinner />
);
