import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import { GENERIC_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector';
import { genericProjectSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars';
import { createEntry } from '@atlassian/jira-route-entry';
import { getBusinessProjectResources } from '@atlassian/jira-router-resources-business-common';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context';
import { ErrorPagesNotFound } from '@atlassian/jira-router-routes-common';
import {
	routeNamesProjectPagesLegacyRoute,
	projectPagesRoute,
} from '@atlassian/jira-router-routes-project-pages-routes';
import { isDevopsFeatureDisabledInFedRamp } from '@atlassian/jira-software-devops-fedramp-utils';
import Pages, { LazyProjectPages, PagesRedirect } from './ui';

export const projectPagesRouteEntry = createEntry(routeNamesProjectPagesLegacyRoute, {
	resources: [
		...getBusinessProjectResources(),
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		projectContextResource,
		getConsolidationStateResource(),
	],

	component: !__SERVER__ ? PagesRedirect : Pages,
	layout: genericProjectLayout,

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: genericProjectSidebars,
		horizontal: GENERIC_PROJECT_HORIZONTAL_NAV,
	},

	forPaint: [LazyAtlassianNavigation, LazyProjectPages],
	isRedirect: true,
});

export const projectPages1RouteEntry = createEntry(projectPagesRoute, {
	resources: [
		...getBusinessProjectResources(),
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		projectContextResource,
		getConsolidationStateResource(),
	],

	component: componentWithCondition(isDevopsFeatureDisabledInFedRamp, ErrorPagesNotFound, Pages),
	layout: genericProjectLayout,

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: genericProjectSidebars,
		horizontal: GENERIC_PROJECT_HORIZONTAL_NAV,
	},

	forPaint: [LazyAtlassianNavigation, LazyProjectPages],
});
