import queryString from 'query-string';
// eslint-disable-next-line jira/restricted/@atlassian/jira-router
import { createResource, type RouterContext, type RouterDataContext } from '@atlassian/jira-router';
import type { Page, Workflow } from '../../../types';
import { getListWorkFlows } from '../../api/get-list-workflows';
import { WORKFLOWS_TABLE_TYPE, WORKFLOWS_TABLE_KEY } from './constants';

const workFlowsResource = createResource<Page<Workflow>>({
	type: WORKFLOWS_TABLE_TYPE,
	getKey: ({ query }: RouterContext) => `${WORKFLOWS_TABLE_KEY}-${queryString.stringify(query)}`,
	maxAge: 0,
	getData: async ({ query }: RouterDataContext) => getListWorkFlows(queryString.stringify(query)),
});

export default workFlowsResource;
