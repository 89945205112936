import React, { useEffect } from 'react';
import { useEntryPoint } from '@atlassian/jira-entry-point';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container';
import { PACKAGE_NAME } from './common/constants';
import { downloadMobilePromptEntryPoint } from './entrypoint';

const runtimeProps = {};
const entryPointParams = {};
export const AsyncMobileDownloadPrompt = () => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		downloadMobilePromptEntryPoint,
		entryPointParams,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	return (
		<JiraEntryPointContainer
			id="jiraBusinessDownloadMobilePrompt"
			packageName={PACKAGE_NAME}
			teamName="frontier"
			entryPointReferenceSubject={entryPointReferenceSubject}
			runtimeProps={runtimeProps}
			errorFallback="unmount"
		/>
	);
};
