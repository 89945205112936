import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const jiraHomeRoute = {
	group: ROUTE_GROUPS.HOME,
	name: ROUTE_NAMES.YOUR_WORK,
	path: '/jira/your-work',
};
