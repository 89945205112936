import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

const HELP_CENTER_SETTINGS_URL = '/jira/helpcenter/projects/:projectKey/settings';

export const helpCenterSettingsRoute = {
	group: ROUTE_GROUPS.HELP_CENTER_SETTINGS,
	name: ROUTE_NAMES.HELP_CENTER_SETTINGS,
	path: HELP_CENTER_SETTINGS_URL,
	exact: true,
};

export const helpCenterSettingsKbRoute = {
	group: ROUTE_GROUPS.HELP_CENTER_SETTINGS,
	name: ROUTE_NAMES.HELP_CENTER_SETTINGS_KNOWLEDGE_BASE,
	path: `${HELP_CENTER_SETTINGS_URL}/kb`,
	exact: true,
};

export const helpCenterSettingsCustomerNotificationsRoute = {
	group: ROUTE_GROUPS.HELP_CENTER_SETTINGS,
	name: ROUTE_NAMES.HELP_CENTER_SETTINGS_CUSTOMER_NOTIFICATIONS,
	path: `${HELP_CENTER_SETTINGS_URL}/customer-notifications`,
};

export const helpCenterSettingsEmailRoute = {
	group: ROUTE_GROUPS.HELP_CENTER_SETTINGS,
	name: ROUTE_NAMES.HELP_CENTER_SETTINGS_EMAIL,
	path: `${HELP_CENTER_SETTINGS_URL}/email`,
	exact: true,
};

export const helpCenterSettingsUnassignedTicketTypesConfigRoute = {
	exact: true,
	group: ROUTE_GROUPS.HELP_CENTER_SETTINGS,
	name: ROUTE_NAMES.HELP_CENTER_SETTINGS_REQUEST_TYPES,
	path: `${HELP_CENTER_SETTINGS_URL}/request-types/unassigned`,
};

export const helpCenterSettingsUnassignedTicketTypeFieldsRoute = {
	exact: true,
	group: ROUTE_GROUPS.HELP_CENTER_SETTINGS,
	name: ROUTE_NAMES.HELP_CENTER_SETTINGS_REQUEST_TYPE_FIELDS,
	path: `${HELP_CENTER_SETTINGS_URL}/request-types/unassigned/request-type/:requestTypeId/:tabKey?`,
};
