import {
	type MatcherLocation,
	matchUrlExact,
} from '@atlassian/jira-navigation-apps-sidebar-common';

const PROJECT_ROLES_PATH_REGEXP = /\/project\/(\w+)ProjectRole/i;

export const isProjectRolesSelected = (
	currentLocation: MatcherLocation,
	itemLocation: MatcherLocation,
): boolean =>
	matchUrlExact()(currentLocation, itemLocation) ||
	PROJECT_ROLES_PATH_REGEXP.test(currentLocation.pathname);
