import { createResource } from '@atlassian/jira-router';
import { RESOURCE_TYPE_PRODUCT_JSM_ORGANIZATION_DETAILS } from './constants';
import type { OrganizationFetchResponse } from './services/types';

export type { OrganizationFetchResponse } from './services/types';

export const productsJsmOrganizationDetailsResource = createResource<OrganizationFetchResponse>({
	type: RESOURCE_TYPE_PRODUCT_JSM_ORGANIZATION_DETAILS,
	getKey: () => 'state',
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-products-jsm-organization-details", jiraSpaEntry: "async-resource-products-jsm-organization-details" */ './services/get-data'
		),
});
