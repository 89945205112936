import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	viewAllFilters: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX - ENABLING FLAT LINT CONFIG
		id: 'spa-apps-sidebar-common.issues.filters-footer.view-all-filters',
		defaultMessage: 'View all filters',
		description: 'Filter link on the issues view in software navigation',
	},
});
