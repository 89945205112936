import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';
import type { Route } from '@atlassian/jira-router';

export const confluenceProductSettingsRoute: Route = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.CONFLUENCE_PRODUCT_SETTINGS,
	path: '/jira/settings/products/confluence',
	exact: true,
};
