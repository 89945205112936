import React, { memo } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import {
	setPerformanceMarkLoadStart,
	setPerformanceMarkLoadEnd,
} from '@atlassian/jira-navigation-apps-sidebar-common';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-common/async';
import type CoreProjectSidebarType from './index';

const LazyCoreProjectSidebar = lazyForPaint<typeof CoreProjectSidebarType>(() => {
	setPerformanceMarkLoadStart();
	return import(/* webpackChunkName: "async-sidebar-core" */ './index').then((bundle) => {
		setPerformanceMarkLoadEnd();
		return bundle;
	});
});

const CoreProjectSidebar = () => (
	<SidebarBoundary id="coreProject">
		<LazyCoreProjectSidebar />
	</SidebarBoundary>
);

export default memo(CoreProjectSidebar);
