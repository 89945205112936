import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { HorizontalOperationsConfiguration } from './ui';

export const LazyHorizontalOperationsConfigurationNav = lazyForPaint<
	typeof HorizontalOperationsConfiguration
>(() =>
	import(/* webpackChunkName: "jira-operations-configurations-horizontal-nav" */ './ui').then(
		({ HorizontalOperationsConfiguration }) => HorizontalOperationsConfiguration,
	),
);

export const AsyncHorizontalOperationsConfigurationNav = () => (
	<Placeholder name="jira-operations-configurations-horizontal-nav" fallback={null}>
		<LazyHorizontalOperationsConfigurationNav />
	</Placeholder>
);
