import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalLayout } from '@atlassian/jira-global-layout';
import type ArchivedDirectLinkType from '@atlassian/jira-project-archived-direct-link';
import { createEntry } from '@atlassian/jira-route-entry';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { archivedProjectDirectLinkRoute } from '@atlassian/jira-router-routes-archived-project-direct-link-routes';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyArchivedDirectLink = lazyForPaint<typeof ArchivedDirectLinkType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-project-archived-direct-link", jiraSpaEntry: "async-project-archived-direct-link" */ '@atlassian/jira-project-archived-direct-link'
		),
	),
);

export const ArchivedProjectDirectLink = () => (
	<LazyPage
		Page={LazyArchivedDirectLink}
		pageId="archived-project-direct-link"
		shouldShowSpinner={false}
	/>
);

export const archivedProjectDirectLinkRouteEntry = createEntry(archivedProjectDirectLinkRoute, {
	component: ArchivedProjectDirectLink,
	layout: globalLayout,
	resources: [...getNavigationResources()],
	forPaint: [LazyAtlassianNavigation, LazyArchivedDirectLink],
	ufoName: 'project-archived',
});
