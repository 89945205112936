import React from 'react';
import { styled } from '@compiled/react';
import AkSpinner from '@atlaskit/spinner';
import UFOLoadHold from '@atlassian/ufo-load-hold';

export default function Spinner({ testId }: { testId?: string }) {
	return (
		<UFOLoadHold name="legacy-spinner">
			<Container>
				<Wrapper>
					<AkSpinner size="large" testId={testId} />
				</Wrapper>
			</Container>
		</UFOLoadHold>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignContent: 'center',
	height: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	alignSelf: 'center',
});
