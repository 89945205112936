import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const homeRedirectRoute = {
	group: ROUTE_GROUPS.HOME,
	name: ROUTE_NAMES.HOME_REDIRECT,
	path: '/jira',
	exact: true,
};
