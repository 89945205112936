import React, { Component } from 'react';
import flow from 'lodash/flow';
import { AnalyticsSubject, AnalyticsSource } from '@atlassian/jira-analytics-web-react/src';
import AppBase from '@atlassian/jira-app-base';
import { SCREEN } from '@atlassian/jira-product-analytics-bridge';
import DwellTime from './view';

export type Props = {
	sessionId: string;
	data: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		attributes?: any;
		containerId?: string | null;
		objectId?: string | null;
		objectType?: string | null;
		actionSubject?: string | null;
		source?: string | null;
	};
};

type State = {
	DwellTimeComponent: ReturnType<typeof getDwellComponent>;
};

const defaultSource = 'dwellComponent';
const defaultActionSubject = 'dwellComponent';

const getDwellComponent = (source?: string | null, actionSubject?: string | null) =>
	flow(
		AnalyticsSubject(actionSubject || defaultActionSubject),
		AnalyticsSource(source || defaultSource, SCREEN),
	)(DwellTime);

// eslint-disable-next-line jira/react/no-class-components
export default class DwellWrapper extends Component<Props, State> {
	static getDerivedStateFromProps(props: Props) {
		const { data } = props;
		return {
			DwellTimeComponent: getDwellComponent(data.source, data.actionSubject),
		};
	}

	constructor(props: Props) {
		super(props);
		this.state = {
			DwellTimeComponent: null,
		};
	}

	render() {
		const { sessionId, data } = this.props;
		const { DwellTimeComponent } = this.state;

		const enrichedData = {
			...data,
			source: (data && data.source) || defaultSource,
			actionSubject: (data && data.actionSubject) || defaultActionSubject,
		};

		if (sessionId && DwellTimeComponent) {
			return (
				<AppBase>
					<DwellTimeComponent sessionId={sessionId} data={enrichedData} />
				</AppBase>
			);
		}
		return null;
	}
}
