import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const softwareArjSettingsDependenciesRoute = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_ARJ_SETTINGS_DEPENDENCIES,
	path: '/jira/plans/settings/dependencies',
};

export const softwareArjSettingsDependenciesRedirectRoute = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_ARJ_SETTINGS_DEPENDENCIES_REDIRECT,
	path: '/secure/PortfolioDependencySettings.jspa',
};

export const softwareArjSettingsLoadingLimitsRoute = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_ARJ_SETTINGS_LOADING_LIMITS,
	path: '/jira/plans/settings/loading-limits',
};

export const softwareArjSettingsLoadingLimitsRedirectRoute = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_ARJ_SETTINGS_LOADING_LIMITS_REDIRECT,
	path: '/secure/PortfolioLoadingLimits.jspa',
};

export const softwareArjSettingsPermissionsRoute = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_ARJ_SETTINGS_PERMISSIONS,
	path: '/jira/plans/settings/permissions',
};

export const softwareArjSettingsPermissionsRedirectRoute = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_ARJ_SETTINGS_PERMISSIONS_REDIRECT,
	path: '/secure/PortfolioPermissions.jspa',
};
