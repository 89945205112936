import React, { useMemo, type ReactNode } from 'react';
import {
	RelayEnvironmentProvider,
	useRelayEnvironment,
	type IEnvironmentProvider,
} from 'react-relay';
import getRelayEnvironment from '@atlassian/jira-relay-environment';

export default function JiraRelayEnvironmentProvider({ children }: { children: ReactNode }) {
	return (
		<RelayEnvironmentProvider environment={getRelayEnvironment()}>
			{children}
		</RelayEnvironmentProvider>
	);
}

export function useJiraRelayEnvironmentProvider(): IEnvironmentProvider<{}> {
	const environment = useRelayEnvironment();

	return useMemo(
		() => ({
			getEnvironment() {
				return environment;
			},
		}),
		[environment],
	);
}
