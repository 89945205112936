import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { softwareGoalsRoute } from '@atlassian/jira-router-routes-software-goals-routes';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav';
import AtlasGoalsPage from './ui';

export const softwareGoalsRouteEntry = createEntry(softwareGoalsRoute, {
	component: AtlasGoalsPage,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarNextGen,
		horizontal: AsyncHorizontalSoftwareProjectNav,
		horizontalFeatureFlag: 'sea-2744-jsw-tabs-nav',
	},
	resources: [...getNavigationResources(), ...getNavigationSidebarProjectResource()],
});
