import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type HelpCenterSettingsTicketTypesFieldType from '@atlassian/jira-spa-apps-project-settings-service-desk-ticket-type-fields';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyHelpCenterSettingsTicketTypeFields = lazyForPaint<
	typeof HelpCenterSettingsTicketTypesFieldType
>(
	() =>
		import(
			/* webpackChunkName: "async-help-center-settings-ticket-type-fields", jiraSpaEntry: "async-help-center-settings-ticket-type-fields" */ '@atlassian/jira-spa-apps-project-settings-service-desk-ticket-type-fields'
		),
);

export const HelpCenterSettingsTicketTypeFields = () => (
	<LazyPage
		Page={LazyHelpCenterSettingsTicketTypeFields}
		pageId="help-center-settings-ticket-type-fields"
		shouldShowSpinner
	/>
);
