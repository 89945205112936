import React, { type ComponentType } from 'react';
import { flagService } from '../../services';
import type { FlagService } from '../../services/types';

export type FlagServiceProps = {
	flagService: FlagService;
};

const withFlagService = <TProps extends FlagServiceProps, TDefault>(
	WrappedComponent: ComponentType<TProps> & { defaultProps?: TDefault },
) => {
	type PropsWithDefault = Omit<TProps, keyof TDefault> & Partial<TDefault>;
	type PropsWithoutInjected = Omit<PropsWithDefault, 'flagService'>;

	return (props: PropsWithoutInjected) => (
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		<WrappedComponent {...(props as TProps & TDefault)} flagService={flagService} />
	);
};

export default withFlagService;
