import { useMemo } from 'react';
import { setMatchedRoute } from '@atlassian/jira-platform-router-utils';
import { useRouter } from '@atlassian/jira-router';

const MatchedRouteListener = () => {
	const [{ route, match }] = useRouter();
	useMemo(() => setMatchedRoute(route, match), [route, match]);
	return null;
};

export { MatchedRouteListener };
