import { loadQuery } from 'react-relay';
import type { ConcreteRequest, OperationType } from 'relay-runtime';
import getRelayEnvironment from '@atlassian/jira-relay-environment';
import { QueryPromisesMap } from '@atlassian/jira-relay-query-promises';
import type { UserPrefsForIssueTableQuery } from '../../types';
import { transformToVariables } from './transform-to-variables';

export async function loadQueryWithUserPrefs<T extends OperationType>(
	{ filterId, columnNames, num, sortField, sortDirection }: UserPrefsForIssueTableQuery,
	query: ConcreteRequest,
	cloudId: string,
	activationId: string,
	fetchPolicy: 'store-only' | 'store-and-network' | 'store-or-network' = 'store-or-network',
	jql?: string,
) {
	const { variables } = await transformToVariables({
		userPrefs: { filterId, columnNames, num, sortField, sortDirection },
		cloudId,
		activationId,
		jql,
	});

	const queryReference = loadQuery<T>(getRelayEnvironment(), query, variables, {
		fetchPolicy,
	});

	if (__SERVER__ && queryReference.id != null) {
		await QueryPromisesMap.get(queryReference.id);
	}

	return queryReference;
}
