import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { arjSettingsLoadingLimitsResource } from '@atlassian/jira-router-resources-advanced-roadmaps-settings';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation';
import {
	softwareArjSettingsLoadingLimitsRoute,
	softwareArjSettingsLoadingLimitsRedirectRoute,
} from '@atlassian/jira-router-routes-advanced-roadmaps-routes';
import type ArjLoadingLimitsSettingsType from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/settings/loading-limits';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import { redirectTo } from '../../../common/ui/redirect/settings';

/**
 * Plans Settings - Loading Limits
 */
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyArjSettingsLoadingLimits = lazyForPaint<typeof ArjLoadingLimitsSettingsType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-arj-settings-loading-limits", jiraSpaEntry: "async-arj-settings-loading-limits" */ '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/settings/loading-limits'
			),
		),
	{ ssr: false },
);

export const ArjSettingsLoadingLimits = () => (
	<LazyPage
		Page={LazyArjSettingsLoadingLimits}
		pageId="arj-settings-loading-limits"
		shouldShowSpinner
	/>
);

export const arjSettingsLoadingLimitsRouteEntry: Route = createEntry(
	softwareArjSettingsLoadingLimitsRoute,
	{
		component: ArjSettingsLoadingLimits,

		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProductsSettingResource(),
			arjSettingsLoadingLimitsResource,
		],

		forPaint: [LazyAtlassianNavigation, LazyArjSettingsLoadingLimits],
	},
);

export const arjSettingsLoadingLimitsLegacyRedirectRouteEntry: Route = createEntry(
	softwareArjSettingsLoadingLimitsRedirectRoute,
	{
		component: redirectTo('/jira/plans/settings/loading-limits'),
		isRedirect: true,
		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},

		resources: [...getNavigationResources(), ...getNavigationSidebarProductsSettingResource()],
		forPaint: [LazyAtlassianNavigation, LazyArjSettingsLoadingLimits],
	},
);
