import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const servicedeskOrganizationV2Route = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_ORGANIZATION_V2,
	path: '/jira/servicedesk/projects/:projectKey/organization/:organizationId',
	exact: true,
};
