import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const softwareCalendarRoute = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_CALENDAR,
	path: '/jira/software/projects/:projectKey/boards/:boardId/calendar',
	queries: ['date?'],
};

export const softwareCalendarEmbedRoute = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_CALENDAR_EMBED,
	path: '/jira/software/projects/:projectKey/boards/:boardId/calendar/embed',
	queries: ['date?'],
};
