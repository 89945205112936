import queryString from 'query-string';
import type { IssueTypeResourceData } from '@atlassian/jira-admin-pages-issue-types-common';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { fetchJson } from '@atlassian/jira-fetch';
import { ERROR_REPORTING_PACKAGE_NAME } from '../../common/constants';
import { toApiUrlTransformer, fromUrlTransformer } from './utils/transformers';

export const getIssueTypes = async (qs: string): Promise<IssueTypeResourceData> => {
	const filter = fromUrlTransformer(queryString.parse(qs));
	const query = queryString.stringify(toApiUrlTransformer(filter));

	try {
		return await fetchJson(`/rest/internal/3/issuetype/search?${query}`);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			error,
			meta: {
				id: 'getIssueTypesRequest',
				packageName: ERROR_REPORTING_PACKAGE_NAME,
				teamName: 'morphinecake',
			},
			sendToPrivacyUnsafeSplunk: true,
		});
		throw error;
	}
};
