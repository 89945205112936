import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type SystemC2cMigrationsDashboardType from '@atlassian/jira-spa-apps-c2c-migrations-dashboard';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazySystemC2cMigrationsDashboard = lazyForPaint<
	typeof SystemC2cMigrationsDashboardType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-system-c2c-migrations-dashboard", jiraSpaEntry: "async-system-c2c-migrations-dashboard" */ '@atlassian/jira-spa-apps-c2c-migrations-dashboard'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazySystemC2cMigrationsDashboard}
		pageId="system-c2c-migrations-dashboard"
		shouldShowSpinner
	/>
);
