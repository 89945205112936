import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type JiraBusinessFormSubmitAppType from '@atlassian/jira-spa-apps-business-form-submit';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyFormSubmitView = lazyForPaint<typeof JiraBusinessFormSubmitAppType>(
	() =>
		import(
			/* webpackChunkName: "async-business-form-submit", jiraSpaEntry: "async-business-form-submit" */ '@atlassian/jira-spa-apps-business-form-submit'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazyFormSubmitView} pageId="async-business-form-submit" shouldShowSpinner />
);
