import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const loginEmbedRoute = {
	group: ROUTE_GROUPS.ERRORS,
	name: ROUTE_NAMES.LOGIN_EMBED,
	path: '/jira-login/embed',
};
