import React, { memo } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-nav4-common/src/ui/sidebar-boundary';
import Placeholder from '@atlassian/jira-placeholder';

const LazySidebarContent = lazyForPaint(() =>
	import(
		/* webpackChunkName: "async-jira-navigation-apps-sidebar-nav4-sidebar-content" */ './index'
	).then(({ SidebarContent }) => SidebarContent),
);

const AsyncSidebarContent = memo(() => (
	<SidebarBoundary
		id="asyncSidebarContent"
		packageName="jiraNavigationAppsSidebarNav4SidebarContent"
		teamName="jira-cosmos"
	>
		<Placeholder name="lazySidebarContent">
			<LazySidebarContent />
		</Placeholder>
	</SidebarBoundary>
));

AsyncSidebarContent.displayName = 'AsyncSidebarContent';

// Used in jira/src/packages/page-container-v2/src/common/ui/sidebar
export default AsyncSidebarContent;
