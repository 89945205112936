import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const globalSettingsIssuesNewIssueSearchRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.GLOBAL_SETTINGS_ISSUES_NEW_ISSUE_SEARCH,
	path: '/jira/settings/issues/new-issue-search-transition',
};
