import { createResource, type RouteResourceError, useResource } from '@atlassian/jira-router';
import { RESOURCE_TYPE_NEW_ISSUE_VIEW_LOCK_IN_STATUS } from '../common/constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const newIssueViewTransitionLockInStatusResource = createResource<any>({
	type: RESOURCE_TYPE_NEW_ISSUE_VIEW_LOCK_IN_STATUS,
	getKey: () => 'newIssueViewTransitionLockingStatus',
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-new-issue-view-lock-in-status-data", jiraSpaEntry: "async-resource-new-issue-view-lock-in-status-data" */ './get-data'
		),
	maxAge: Number.MAX_VALUE,
});

export const LOCK_IN_NEW_ISSUE_VIEW = 'LOCK_IN_NEW_ISSUE_VIEW' as const;
export const DEFAULT_TO_NEW_ISSUE_VIEW = 'DEFAULT_TO_NEW_ISSUE_VIEW' as const;
export const DEFAULT_TO_OLD_ISSUE_VIEW = 'DEFAULT_TO_OLD_ISSUE_VIEW' as const;

export type NewIssueViewTransitionType =
	| typeof LOCK_IN_NEW_ISSUE_VIEW
	| typeof DEFAULT_TO_NEW_ISSUE_VIEW
	| typeof DEFAULT_TO_OLD_ISSUE_VIEW;

export type NewIssueViewLockInStatus = {
	loading: boolean;
	error: RouteResourceError;
	newIssueViewLockedIn: NewIssueViewTransitionType;
};

export const useNewIssueViewLockedInStatus = () =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	useResource<any>(newIssueViewTransitionLockInStatusResource);
