import React, { useState, type ReactNode, useEffect, useCallback, useRef } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { getCookie, setStrictlyNecessaryCookie } from '@atlassian/jira-browser-storage-controls';
import { fg } from '@atlassian/jira-feature-gating';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { ExpandButton } from '../expand-button';
import { MenuItem } from '../menu-item';

const defaultExpandMenuState = {
	submenuActive: false,
	expanded: false,
};

type ExpandMenuState = {
	submenuActive: boolean;
	expanded: boolean;
};

export type ExpandableSubmenuItemType = {
	children: ReactNode;
	iconBefore?: ReactNode;
	expandButtonLabel?: string;
	analytics?: {
		itemId: string;
	};
	menuItemLabel: string;
	href?: string;
	useButtonLink?: boolean;
	forceExpand?: boolean;
	noListStyle?: boolean;
	submenuId?: string;
	menuCollapseState?: ExpandMenuState;
	testId?: string;
};

export const useExpandableMenuStates = () => {
	const getExpandableState = (submenuId: string) => {
		const cookieData = getCookie(`jira.navigation.expandableMenuStates.${submenuId}`);
		let parsedCookieData = null;
		try {
			// @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
			parsedCookieData = JSON.parse(cookieData);
		} catch {
			// Rather than throw an error - just set the default state
			parsedCookieData = defaultExpandMenuState;
		}
		return parsedCookieData;
	};

	const setExpandedState = (
		submenuId: string,
		newExpandedState: {
			expanded: boolean;
			submenuActive: boolean;
		},
	) => {
		if (submenuId != null) {
			setStrictlyNecessaryCookie(
				`jira.navigation.expandableMenuStates.${submenuId}`,
				JSON.stringify(newExpandedState),
			);
		}
	};

	return { getExpandableState, setExpandedState };
};

export const ExpandableSubmenuItem = ({
	children,
	analytics,
	iconBefore = <></>,
	menuItemLabel,
	expandButtonLabel = 'expand',
	href,
	useButtonLink = false,
	forceExpand = false,
	noListStyle = false,
	submenuId = '',
	testId = '',
}: ExpandableSubmenuItemType) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { getExpandableState, setExpandedState } = useExpandableMenuStates();
	const [subMenuActive, setSubMenuActive] = useState<boolean>(false);
	const [currentCookieData] = useState<ExpandMenuState>(getExpandableState(submenuId));
	const expandButtonId = menuItemLabel.replace(/ /g, '-').toLowerCase();
	const [expanded, setExpanded] = useState<boolean>(
		currentCookieData?.expanded || fg('jsm_simplified_project_settings_navigation')
			? forceExpand
			: false,
	);
	const expandableMenuItemRef = useRef<HTMLElement>(null);

	useEffect(() => {
		if (!fg('jsm_simplified_project_settings_navigation') && forceExpand) {
			setExpanded(true);
		}
	}, [forceExpand]);

	useEffect(() => {
		if (currentCookieData?.expanded) {
			setExpanded(currentCookieData.expanded);
			setSubMenuActive(currentCookieData.submenuActive);
		}
	}, [currentCookieData]);

	const onClick = useCallback(() => {
		if (submenuId != null) {
			setExpandedState(submenuId, { submenuActive: false, expanded: !expanded });
		}

		fireUIAnalytics(createAnalyticsEvent({}), 'expandButton changed', 'projectSettings', {
			expandMenuId: analytics?.itemId,
			expanded: !expanded,
		});
		setExpanded(!expanded);

		requestAnimationFrame(() => {
			expandableMenuItemRef?.current?.focus();
		});
	}, [analytics, createAnalyticsEvent, expanded, setExpandedState, submenuId]);

	return (
		<ExpandableSubmenuWrapper isExpanded={subMenuActive}>
			<MenuItem
				analytics={analytics}
				href={href}
				useButtonLink={useButtonLink}
				iconBefore={
					<>
						<ExpandButton
							id={expandButtonId}
							label={expandButtonLabel}
							isActive
							isExpanded={expanded}
							size="medium"
						/>
						{iconBefore}
					</>
				}
				onClick={onClick}
				data-test-id={testId}
				ref={expandableMenuItemRef}
				tabIndex={-1}
			>
				{menuItemLabel}
			</MenuItem>
			{expanded && (
				<ExpandableSubmenuItemWrapper noListStyle={noListStyle}>
					{children}
				</ExpandableSubmenuItemWrapper>
			)}
		</ExpandableSubmenuWrapper>
	);
};

// Buttons inside the Expandable Menu indicate sub-expandable menus,
// these are styled differently that top level expandable menu items so the aligment look ok
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ExpandableSubmenuWrapper = styled.div<{ isExpanded: boolean }>(
	{
		/*  the below selectors target all variations of menu items <MenuItem /> which can be
            a, button or either nested inside a span -> if the nested menu is not open, the left negative margin
            (which accounts for the expand button) needs to be removed so menu items appear correctly in nested
            menus.
        */
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'a, span > a, button, span > button': {
			paddingLeft: 0,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'& ~ div > a, & ~ div > span > a, & ~ div > button, & ~ div > span > button': {
				width: 'calc(100% - 16px)',
				marginLeft: token('space.200', '16px'),
				// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
				paddingLeft: '22px',
			},
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		button: {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			span: {
				gap: '0px',
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			"span[data-item-elem-before='true']": {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
				"& > div[role='button']": {
					top: '1px',
				},
			},
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) => props.isExpanded && 'height: 100%',
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ExpandableSubmenuItemWrapper = styled.div<{ noListStyle?: boolean }>({
	height: '100%',
	marginLeft: token('space.negative.050', '-4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	a: {
		width: '100%',
		marginLeft: token('space.100', '8px'),
		paddingLeft: 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& > div': {
			width: 'calc(100% - 20px)',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		marginLeft: token('space.075', '6px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	"& > div:not([role='group'])": {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'> a': {
			width: 'calc(100% + 15px)',
			// TODO: This is not an integer. Possibly unintentional
			paddingLeft: `${8 * 2.325}px`,
			marginLeft: 0,
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="NavigationContentWrapperContainer"]': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		"& [role='group']": {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'& [data-item-title]': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				paddingLeft: '15px !important',
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'[data-ds--menu--heading-item]': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				paddingLeft: '10px !important',
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			a: {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				paddingLeft: `${token('space.050', '4px')} !important`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				width: 'calc(100% - 8px) !important',
			},
		},
	},
});
