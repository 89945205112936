import { useEffect, useMemo, useState, useRef } from 'react';
import { di } from 'react-magnetic-di';
import { unsplashTracker } from '@atlassian/jira-business-theme-unsplash';
import { type ThemeSetting, generateMediaImageUrl } from '@atlassian/jira-custom-theme-constants';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { isClientFetchError } from '@atlassian/jira-fetch';
import { useMediaConfiguration } from '@atlassian/jira-router-resources-business-project-details';

export const useBackgroundImageUrl = (themeSetting: ThemeSetting | null): string | null => {
	di(URL, fetch);

	const media = useMediaConfiguration();

	const lowResUrl = useMemo(
		() =>
			media && themeSetting?.type === 'image'
				? generateMediaImageUrl({
						mediaClientId: media.clientId,
						mediaExternalEndpointUrl: media.externalEndpointUrl,
						mediaApiFileId: themeSetting.mediaApiFileId,
						token: themeSetting.mediaReadToken,
						mediaEndpoint: 'image',
						width: 1000,
						'max-age': Number.MAX_SAFE_INTEGER,
					})
				: null,
		[media, themeSetting],
	);

	const [imageUrl, setImageUrl] = useState<string | null>(lowResUrl);

	const currentThemeId = useRef<string | null>(null);
	currentThemeId.current = themeSetting?.type === 'image' ? themeSetting.id : null;

	useEffect(() => {
		setImageUrl(lowResUrl);

		// if we don't have an image theme, do nothing
		if (!media || themeSetting?.type !== 'image') {
			return;
		}

		// build a new URL to the /binary path
		const binaryUrl = generateMediaImageUrl({
			mediaClientId: media.clientId,
			mediaExternalEndpointUrl: media.externalEndpointUrl,
			mediaApiFileId: themeSetting.mediaApiFileId,
			mediaEndpoint: 'binary',
			'max-age': Number.MAX_SAFE_INTEGER,
		});

		// send a request to fetch the image with the binary URL with bearer token authentication
		fetch(binaryUrl.toString(), {
			headers: {
				Authorization: `Bearer ${themeSetting.mediaReadToken}`,
			},
		})
			.then((response) => response.blob())
			.then((blob) => {
				const objectUrl = URL.createObjectURL(blob);

				if (currentThemeId.current === themeSetting.id) {
					setImageUrl(objectUrl);

					// send viewing analytics to Unsplash for Unsplash images
					if (themeSetting.sourceType === 'UNSPLASH' && themeSetting.sourceIdentifier) {
						unsplashTracker.trackImage(themeSetting.sourceIdentifier);
					}
				}
			})
			.catch((error) => {
				// only fire error analytics if the error is not a client fetch error (e.g. network error, aborted)
				if (!isClientFetchError(error)) {
					fireErrorAnalytics({
						meta: {
							id: 'fetchBinaryBackgroundImage',
							packageName: 'jiraBusinessThemeComponents',
							teamName: 'work-management-frontier',
						},
						error,
						sendToPrivacyUnsafeSplunk: true,
					});
				}
			});
	}, [themeSetting, media, lowResUrl]);

	return imageUrl;
};
