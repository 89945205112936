// eslint-disable-next-line jira/import-whitelist
import { fetchIssueGiraData } from '@atlassian/jira-issue-fetch-services';
import type { RouterDataContext, ResourceStoreContext } from '@atlassian/jira-router';
import { createResourceError, getIssueKey } from '@atlassian/jira-router-resources-utils';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default async ({ match }: RouterDataContext, { tenantContext }: ResourceStoreContext) => {
	const issueKey = getIssueKey(match);

	if (!issueKey) {
		return null;
	}

	try {
		const { baseUrl } = tenantContext;
		const data = await fetchIssueGiraData(baseUrl, issueKey);
		return data;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		throw createResourceError(
			{
				component: 'spa.view.routes.issue-view.gira.getData',
				message: 'An error was thrown while prefetching issue gira data',
				name: 'ResourceError',
			},
			error,
		);
	}
};
