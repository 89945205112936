import React from 'react';
import camelCase from 'lodash/camelCase';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { ROUTE_NAMES } from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';
import componentWithCondition from '@atlassian/jira-common-util-component-with-condition';
import { ff } from '@atlassian/jira-feature-flagging';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async';
import AsyncSidebarSoftwareClassic from '@atlassian/jira-navigation-apps-sidebar-software-classic/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context';
import { securityPageResource } from '@atlassian/jira-router-resources-software-security';
import { ErrorPagesNotFound } from '@atlassian/jira-router-routes-common';
import {
	softwareSecurityRoute,
	softwareSecurityClassicRoute,
} from '@atlassian/jira-router-routes-software-security-routes';
import { isDevopsFeatureDisabledInFedRamp } from '@atlassian/jira-software-devops-fedramp-utils';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav';
import {
	classicSoftwareProjectLayout,
	softwareProjectLayoutNoOnboarding,
} from '@atlassian/jira-software-project-layout';
import type SecurityPageSpa from '@atlassian/jira-spa-apps-software-security';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

const isSecurityPageEnabled = () =>
	ff('corex-isotopes-security-page-in-jira_h0ex9') && !isDevopsFeatureDisabledInFedRamp();

export const LazySecurity = lazyForPaint<typeof SecurityPageSpa>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-security", jiraSpaEntry: "async-security" */ '@atlassian/jira-spa-apps-software-security'
		),
	),
);

export const SecurityNextGen = () => (
	<LazyPage Page={LazySecurity} pageId="securityNextGen" shouldShowSpinner />
);

export const SecurityClassic = () => (
	<LazyPage Page={LazySecurity} pageId="securityClassic" shouldShowSpinner />
);

export const softwareSecurityNextGenRouteEntry: Route = createEntry(softwareSecurityRoute, {
	component: componentWithCondition(isSecurityPageEnabled, SecurityNextGen, ErrorPagesNotFound),
	layout: softwareProjectLayoutNoOnboarding,
	ufoName: 'software-security-combined',

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarNextGen,
		horizontal: AsyncHorizontalSoftwareProjectNav,
		horizontalFeatureFlag: 'sea-2744-jsw-tabs-nav',
	},

	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		projectContextResource,
		securityPageResource,
	],

	forPaint: [LazyAtlassianNavigation, LazySecurity],

	meta: {
		reporting: {
			id: ROUTE_NAMES.SOFTWARE_SECURITY,
			packageName: camelCase(ROUTE_NAMES.SOFTWARE_SECURITY),
			teamName: 'fusion-isotopes',
		},
	},
});

export const softwareSecurityClassicRouteEntry: Route = createEntry(softwareSecurityClassicRoute, {
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		projectContextResource,
		securityPageResource,
	],
	ufoName: 'software-security-combined',
	component: componentWithCondition(isSecurityPageEnabled, SecurityClassic, ErrorPagesNotFound),
	layout: classicSoftwareProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarSoftwareClassic,
		horizontal: AsyncHorizontalSoftwareProjectNav,
		horizontalFeatureFlag: 'sea-2744-jsw-tabs-nav',
	},
	forPaint: [LazyAtlassianNavigation, LazySecurity],
	meta: {
		reporting: {
			id: ROUTE_NAMES.SOFTWARE_SECURITY_CLASSIC,
			packageName: camelCase(ROUTE_NAMES.SOFTWARE_SECURITY_CLASSIC),
			teamName: 'fusion-isotopes',
		},
	},
});
