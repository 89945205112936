import React, { type ComponentType, type ReactNode } from 'react';
import type { BreadcrumbProps } from '../../breadcrumb/types';
import type { BoardBreadcrumbProps } from '../../common-breadcrumbs/board/types';
import type { ProjectBreadcrumbProps } from '../../common-breadcrumbs/project/types';
import type { UserBreadcrumbProps } from '../../common-breadcrumbs/user/types';
import type { BreadcrumbData } from '../../types';
import type { LegacyBreadcrumbItem } from '../types';
import type { ContextProviderProps } from './context-provider/types';
import type { LegacyBreadcrumbConnectorProps } from './types';

export type LegacyBreadcrumbInjectedProps = {
	Breadcrumb: ComponentType<BreadcrumbProps>;
	ProjectBreadcrumb: ComponentType<ProjectBreadcrumbProps>;
	// Using shape here due to Flow not being able to work out that the projectId and isSimplifiedProject
	// props are optional due to the default prop in the component
	BoardBreadcrumb: ComponentType<Partial<BoardBreadcrumbProps>>;
	UserBreadcrumb: ComponentType<UserBreadcrumbProps>;
	ContextProvider: ComponentType<ContextProviderProps>;
};

type Props = LegacyBreadcrumbConnectorProps & LegacyBreadcrumbInjectedProps;

const toBreadcrumbData = ({ id, index, ...legacyCrumb }: LegacyBreadcrumbItem): BreadcrumbData => ({
	...legacyCrumb,
	legacyId: id,
	type: 'legacy',
});

const ContextBreadcrumbs = ({
	ContextProvider,
	ProjectBreadcrumb,
	BoardBreadcrumb,
	UserBreadcrumb,
	children,
}: {
	ContextProvider: Props['ContextProvider'];
	ProjectBreadcrumb: Props['ProjectBreadcrumb'];
	BoardBreadcrumb: Props['BoardBreadcrumb'];
	UserBreadcrumb: Props['UserBreadcrumb'];
	children: ReactNode;
	baseUrl?: string;
}) => (
	<ContextProvider>
		{({ data }) => {
			if (!data) {
				return children;
			}
			const boardBreadcrumb = data.scope ? (
				<BoardBreadcrumb boardId={data.scope.id} boardName={data.scope.name || 'Board'}>
					{children}
				</BoardBreadcrumb>
			) : (
				children
			);
			return data.contextType === 'user' ? (
				<UserBreadcrumb profileUrl={data.user.profileUrl} name={data.user.name}>
					{boardBreadcrumb}
				</UserBreadcrumb>
			) : (
				<ProjectBreadcrumb
					projectKey={data.project.key}
					projectName={data.project.name}
					projectId={data.project.id}
					isSimplifiedProject={data.project.isSimplified}
				>
					{boardBreadcrumb}
				</ProjectBreadcrumb>
			);
		}}
	</ContextProvider>
);

const LegacyBreadcrumbConnector = ({
	legacyTrail,
	Breadcrumb,
	BoardBreadcrumb,
	UserBreadcrumb,
	ProjectBreadcrumb,
	ContextProvider,
}: Props) => {
	const accumulateBreadcrumb = (accumulatedCrumbs: ReactNode, currentCrumb: BreadcrumbData) => (
		<Breadcrumb breadcrumb={currentCrumb}>{accumulatedCrumbs}</Breadcrumb>
	);

	return (
		<ContextBreadcrumbs
			BoardBreadcrumb={BoardBreadcrumb}
			ProjectBreadcrumb={ProjectBreadcrumb}
			UserBreadcrumb={UserBreadcrumb}
			ContextProvider={ContextProvider}
		>
			{legacyTrail.map(toBreadcrumbData).reverse().reduce(accumulateBreadcrumb, null)}
		</ContextBreadcrumbs>
	);
};

export default LegacyBreadcrumbConnector;
