import { useMemo } from 'react';
import type { FullPage } from '@atlassian/jira-forge-ui-types';
import { useRouter } from '@atlassian/jira-router';
import { useMatchedExtension } from '@atlassian/jira-spa-apps-forge-common';
import { toSections } from '../subpages';

type Props<T> = T[] | null;

export const useForgeItemStack = <T extends FullPage>(
	modules: Props<T>,
	routeName?: string,
	parentStack?: string[],
) => {
	const [{ match, route }] = useRouter();
	const matchedExtension = useMatchedExtension<T>({ modules, match });

	return useMemo(() => {
		if (route.name !== routeName) {
			return null;
		}
		// match nested navigation view
		if (matchedExtension && toSections(matchedExtension).length) {
			const stackId = `${matchedExtension.id}-subpages`;
			return parentStack ? [...parentStack, stackId] : [stackId];
		}
		// match a simple navigation item (a single top-level page is defined in the manifest)
		if (matchedExtension && parentStack) {
			return parentStack;
		}
		// skip
		return null;
	}, [matchedExtension, parentStack, route.name, routeName]);
};
