// The entity types are used to uniquely identify an entity instance.

// The entity's id should be in the URL
export const ENTITY_ID = {
	BOARD: (id: string) => `boar_${id}`,
	DASHBOARD: (id: string) => `dash_${id}`,
	FILTER: (id: string) => `filt_${id}`,
	PROJECT: (id: string) => `proj_${id}`,
} as const; // We MUST use the same IDs for the same components, otherwise we run into trouble (especially with peeking / more-menu)

export const CONFIGURABLE_MENU_ID = {
	PROJECTS: 'jira.sidebar.projects',
	FILTERS: 'jira.sidebar.filters',
	DASHBOARDS: 'jira.sidebar.dashboards',
	TEAMS: 'jira.sidebar.teams',
	ASSETS: 'jira.sidebar.assets',
	PLANS: 'jira.sidebar.plans',
	APPS: 'jira.sidebar.apps',
	OPERATIONS: 'jira.sidebar.operations',
	GOALS: 'jira.sidebar.goals',
	RECENT: 'jira.sidebar.recent',
	STARRED: 'jira.sidebar.starred',
} as const;

export const MENU_ID = {
	DASHBOARDS: CONFIGURABLE_MENU_ID.DASHBOARDS,
	DASHBOARDS_VIEW_ALL: 'dash-view',
	DASHBOARDS_VIEW_ALL_STARRED: 'dash-view-starred',
	FILTERS: CONFIGURABLE_MENU_ID.FILTERS,
	FILTERS_SEARCH: 'filt-search',
	FILTERS_VIEW_ALL: 'filt-view-all',
	FILTERS_VIEW_ALL_STARRED: 'filt-view-starred',
	PLANS: CONFIGURABLE_MENU_ID.PLANS,
	PLANS_VIEW_ALL: 'plan-view-all',
	PROJECTS: CONFIGURABLE_MENU_ID.PROJECTS,
	PROJECTS_VIEW_ALL: 'proj-view-all',
	TEAMS: CONFIGURABLE_MENU_ID.TEAMS,
	YOUR_WORK: 'yrwk',
} as const;
