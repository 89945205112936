import {
	staticProjectReportsSidebarConnectAddonsNavigationResource,
	staticSidebarJSWReportsResource,
} from '@atlassian/jira-navigation-apps-resources';
import { devOpsReportPreconditionResource } from '@atlassian/jira-router-resources-devops-report-group';

export const getReportsCommonResource = () => [
	devOpsReportPreconditionResource,
	staticProjectReportsSidebarConnectAddonsNavigationResource,
];

export const getTMPReportsCommonResource = () => [
	devOpsReportPreconditionResource,
	staticProjectReportsSidebarConnectAddonsNavigationResource,
	staticSidebarJSWReportsResource,
];
