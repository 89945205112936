import React, { useCallback, useMemo } from 'react';
import { getBooleanFF } from '@atlaskit/platform-feature-flags';
import { Box, xcss } from '@atlaskit/primitives';
import ConversationAssistant, {
	type ConversationAssistantConfig,
	ResizeWrapper,
} from '@atlassian/conversation-assistant';
import { CONVERSATION_ASSISTANT_CONTAINER_ID } from '@atlassian/conversation-assistant/utils';
import { ff } from '@atlassian/jira-feature-flagging';
import getMeta from '@atlassian/jira-get-meta';
import { useRightSidebarController } from '@atlassian/jira-layout-controller';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useActivationId, useCloudId } from '@atlassian/jira-tenant-context-controller';

type JiraConversationAssistantProps = {
	onClosePanel: () => void;
};

const SYNTHETIC_HEADER = 'x-issynthetic-traffic';

// TODO: Revisit the X-Product values once we understand AI-access patterns. Using the technically-correct values here results in some subproducts failing to load AI Mate because they can't be enabled with AI, so we're treating everything as "jira" for now
// const getJiraSubProduct = (): ConversationAssistantConfig['product'] => {
// return 'jira';
// const url = window.location.href;
// if (url.includes('/jira/core/')) {
//     return 'jira-core';
// }
// if (url.includes('/jira/servicedesk/')) {
//     return 'jsm';
// }
// if (url.includes('/jira/polaris/')) {
//     return 'jpd';
// }
// if (url.includes('/jira/software/')) {
//     return 'jira-software';
// }
// return 'unknown';
// };
const getJiraSubProduct = (): ConversationAssistantConfig['product'] => 'jira';

export const JiraConversationAssistant = ({ onClosePanel }: JiraConversationAssistantProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const cloudId = useCloudId();
	const activationId = useActivationId();
	const { openRightSidebar } = useRightSidebarController();

	const isSyntheticTenant = getMeta('ajs-is-synthetic') === 'true' || ff('jsw.pollinator.tenants');

	let baseUrl = '/gateway/api/assist';
	if (ff('jira.frontend.ai.mate.route.stargate.to.convo.ai')) {
		baseUrl = '/gateway/api/assist-kotlin';
	} else if (getBooleanFF('platform.jira.frontend.ai.mate_route_stargate_to_stg_west')) {
		baseUrl = '/gateway/api/assist-tester';
	}

	const config: ConversationAssistantConfig = useMemo(
		() => ({
			displaySubmitFlag: true,
			siteId: cloudId,
			activationId,
			product: getJiraSubProduct(),
			experienceId: 'ai-mate',
			assistanceServiceConfig: {
				// TODO: type `AssistanceServiceConfig` should be decoupled from headers to prevent the need for other required keys which shouldn't need to be defined here (`product` and `experienceId`)
				headers: isSyntheticTenant
					? { [SYNTHETIC_HEADER]: String(isSyntheticTenant) }
					: // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						({} as Record<string, string>),
				baseUrl,
			},
		}),
		[isSyntheticTenant, activationId, baseUrl, cloudId],
	);

	const handleResizeComplete = useCallback(
		(width: number) => {
			openRightSidebar(CONVERSATION_ASSISTANT_CONTAINER_ID, width);

			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'resized',
					actionSubject: 'aiMatePanel',
				}),
			);
		},
		[openRightSidebar, createAnalyticsEvent],
	);

	return (
		<ResizeWrapper onResizeComplete={handleResizeComplete}>
			<Box xcss={conversationAssistantWrapperStyles}>
				<ConversationAssistant
					testId="conversation-assistant.ui.conversation-assistant"
					onCloseChat={onClosePanel}
					config={config}
				/>
			</Box>
		</ResizeWrapper>
	);
};

const conversationAssistantWrapperStyles = xcss({
	width: '100%',
});
