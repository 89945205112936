// This is a list of known legacy connect item IDs that is used to filter out unecessary connect items
// from being rendered in the connect sections
/**
 * @deprecated Delete when cleaning up feature gate blu-3702_-_legacy_connect_nav_item_filtering
 */
export const LEGACY_CONNECT_ITEM_IDS = new Set<string>([
	// Global Settings Static Legacy IDs
	'general_configuration',
	'dark_features',
	'troubleshooting_and_support',
	'view_auditing',
	'pluginViewer',
	'pluginViewer',
	'system_info',
	'integrity_checker',
	'logging_profiling',
	'scheduler_details',
	'security_section',
	'project_role_browser',
	'global_permissions',
	'collectors',
	'user_interface',
	'user_defaults',
	'edit_default_dashboard',
	'lookandfeel',
	'com.atlassian.pas:pas-admin-link-jira',
	'import_export_section',
	'vertigo_export',
	'external_import',
	'vertigo_import',
	'vertigo_import_jira_cloud',
	'vertigo_import_jira_server',
	'mail_section',
	'global_mail_settings',
	'outgoing_mail',
	'incoming_mail',
	'send_email',
	'admin-helper-admin-section',
	'permissionhelper-admin-page',
	'notificationhelper-admin-page',
	'shared_section',
	'shared_filters',
	'shared_dashboards',
	'advanced_section',
	'attachments',
	'eventtypes',
	'webhooks-admin',
	'services',
	'manage_dashboards',
	'manage_filters',
	'greenhopper-lexorank-manage',

	// Project Settings Static Legacy IDs
	'jira.webfragments.view.project.operations:edit_project',
	'com.atlassian.jira.jira-project-config-plugin:users-and-roles',
	'com.atlassian.jira.jira-project-config-plugin:view_project_summary',
	'com.atlassian.jira.jira-project-config-plugin:view_delete_project',
	'com.atlassian.jira.jira-project-config-plugin:view_project_issuetypes',
	'com.atlassian.jira.jira-project-config-plugin:view_project_workflows',
	'com.atlassian.jira.jira-project-config-plugin:view_project_screens',
	'com.atlassian.jira.jira-project-config-plugin:view_project_fields',
	'com.atlassian.jira.jira-projects-plugin:administer_project_versions',
	'com.atlassian.jira.jira-projects-plugin:administer_project_components',
	'com.atlassian.jira.jira-project-config-plugin:view_project_permissions',
	'com.atlassian.jira.jira-project-config-plugin:view_project_issuesecurity',
	'com.atlassian.jira.jira-project-config-plugin:view_project_notifications',
	'com.atlassian.jira.jira-project-config-plugin:project-opsgenie-association',
	'com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:view_issue_collectors',
	'com.atlassian.jira.plugins.jira-development-integration-plugin:devstatus-admin-page-link',
	'com.atlassian.plugins.atlassian-connect-plugin:com.codebarrel.addons.automation__cb-automation-project-config',
	'com.atlassian.jira.jira-project-config-plugin:view_project_issuelayout',
	'com.atlassian.jira.jira-projects-plugin:administer_project_code',
	'com.atlassian.jira.jira-projects-plugin:administer_project_deployments',
	'com.atlassian.servicedesk.project-ui:sd-project-change-management-item',
	'com.atlassian.servicedesk.project-ui:sd-project-features-item',
	'com.atlassian.servicedesk.project-ui:sd-project-request-types-item',
	'com.atlassian.servicedesk.project-ui:sd-project-customer-permissions-item',
	'com.atlassian.servicedesk.servicedesk-lingo-integration-plugin:sd-project-language-support-settings-item-adg3',
	'com.atlassian.servicedesk.project-ui:sd-project-portal-settings-item',
	'com.atlassian.servicedesk.project-ui:sd-project-email-settings-item',
	'com.atlassian.servicedesk.servicedesk-notifications-plugin:sd-project-outgoing-email-settings-item',
	'com.atlassian.servicedesk.project-ui:sd-project-widget-addon-installer-item',
	'com.atlassian.plugins.atlassian-connect-plugin:com.atlassian.servicedesk.embedded__settings',
	'com.atlassian.plugins.atlassian-connect-plugin:com.atlassian.servicedesk.embedded-dev__settings',
	'com.atlassian.servicedesk.project-ui:sd-project-assets-ad-item',
	'com.atlassian.servicedesk.project-ui:sd-project-feedback-settings-item',
	'com.atlassian.servicedesk.project-ui:sd-project-confluence-kb-item',
	'com.atlassian.servicedesk.project-ui:sd-project-sla-item',
	'com.atlassian.servicedesk.project-ui:sd-project-automation-item',
	'com.atlassian.servicedesk.project-ui:sd-project-app-discovery-item',
	'com.atlassian.servicedesk.project-ui:sd-project-incident-management-item',
	'com.atlassian.servicedesk.project-ui:sd-project-itsm-features-item',
	'com.atlassian.servicedesk.project-ui:sd-project-external-links-item',
	'com.atlassian.plugins.atlassian-connect-plugin:com.atlassian.atlas.jira__goals-project-page',

	// Automation connect item
	'com.codebarrel.addons.automation__cb-automation',
]);
