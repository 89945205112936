import React from 'react';
import { type AsImgProps, AsImg } from './implementation';
import type { IllustrationProps, IllustrationCoreProps } from './types';

// this level of indirection is a provision to future-proof this component
// we may want to add extra "render as" in the future
// (note: as of 2023/11 unsure of the exact API, the ENGHEALTH campaign will help clarify the needs, tracked in ERAI-1592)
export function Illustration<Props extends IllustrationProps = AsImgProps>({
	embedAs,
	...props
}: Props) {
	return <AsImg {...props} />;
}

export function createIllustration<Props extends IllustrationProps = AsImgProps>({
	srcLight,
	srcDark,
	autoDark,
}: IllustrationCoreProps & Pick<IllustrationProps, 'autoDark'>) {
	return (props: Omit<Props, 'srcLight' | 'srcDark'>) => (
		<Illustration srcLight={srcLight} srcDark={srcDark} autoDark={autoDark} {...props} />
	);
}
