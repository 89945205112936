import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type NotFoundErrorPageType from '@atlassian/jira-spa-apps-error-pages-not-found';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyNotFoundErrorPage = lazyForPaint<typeof NotFoundErrorPageType>(
	() =>
		import(
			/* webpackChunkName: "async-error-pages-not-found", jiraSpaEntry: "async-error-pages-not-found" */ '@atlassian/jira-spa-apps-error-pages-not-found'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazyNotFoundErrorPage} pageId="errors-404" shouldShowSpinner={false} />
);
