import React, { type ReactNode } from 'react';
import AnalyticsProviders from '@atlassian/jira-analytics-providers';
import { standardizeLocale } from '@atlassian/jira-common-constants/src/supported-locales';
import EngagementProvider from '@atlassian/jira-engagement-provider';
import { ExperienceTrackerProvider } from '@atlassian/jira-experience-tracker';
import getMetaUtil from '@atlassian/jira-get-meta';
import { IntlProvider } from '@atlassian/jira-intl';
import { ModalContextProvider } from '@atlassian/jira-modal-context-provider';
import { routeAnalyticsAttributes } from '@atlassian/jira-platform-route-analytics';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import JiraRelayEnvironmentProvider from '@atlassian/jira-relay-environment-provider';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id';
import {
	TenantContextContainer,
	TenantContextSubscriber,
} from '@atlassian/jira-tenant-context-controller';
import { LoadingPhaseManager } from './ui';

export type JiraContextContainerProps = {
	children: ReactNode;
};

type State = {
	messages?: {
		[key: string]: string;
	};
};

const ContextualAnalyticsDataFeatureFlagged = ({ children }: { children: ReactNode }) => (
	<ContextualAnalyticsData attributes={routeAnalyticsAttributes}>
		{children}
	</ContextualAnalyticsData>
);

/**
 * Provides all containers and providers needed for apps that are rendered outside of the SPA context (e.g. Bento in iframe or mobile).
 */

export const JiraContextContainer = (props: JiraContextContainerProps) => {
	const state: State = {
		// eslint-disable-next-line @typescript-eslint/no-require-imports
		messages: require('language-pack'),
	};
	const { data: orgId } = useOrgId();

	return (
		<ContextualAnalyticsDataFeatureFlagged>
			<TenantContextContainer isGlobal getMeta={getMetaUtil}>
				<TenantContextSubscriber>
					{({ tenantContext }) => (
						<IntlProvider
							messages={state.messages}
							locale={standardizeLocale(tenantContext.locale)}
						>
							<AnalyticsProviders>
								<EngagementProvider orgId={orgId}>
									<ExperienceTrackerProvider>
										<JiraRelayEnvironmentProvider>
											<ModalContextProvider>{props.children}</ModalContextProvider>
										</JiraRelayEnvironmentProvider>
									</ExperienceTrackerProvider>
								</EngagementProvider>
							</AnalyticsProviders>
						</IntlProvider>
					)}
				</TenantContextSubscriber>
			</TenantContextContainer>
			<LoadingPhaseManager />
		</ContextualAnalyticsDataFeatureFlagged>
	);
};
