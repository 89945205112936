import { useCallback, useRef } from 'react';
import type { ExperienceAttributes } from '../../common/types';
import { useExperienceContext } from '../../common/ui/use-experience-context';

export type Props = {
	experience: string;
	attributes?: ExperienceAttributes;
};

export const useExperienceAbort = ({ experience, attributes }: Props) => {
	const experienceTracker = useExperienceContext();

	const self = useRef({
		name: experience,
		attributes,
		experienceTracker,
	});

	self.current.name = experience;
	self.current.attributes = attributes;
	self.current.experienceTracker = experienceTracker;

	return useCallback(
		(reason: string, extraAttributes: ExperienceAttributes = {}) => {
			const { current } = self;
			current.experienceTracker.abort({
				name: current.name,
				reason,
				attributes: {
					...current.attributes,
					...extraAttributes,
				},
			});
		},
		[self],
	);
};
