import {
	DEFAULT_BACKGROUND_CONFIG,
	type OverviewTheme,
} from '@atlassian/jira-custom-theme-constants';
import { useOverview } from '@atlassian/jira-router-resources-business-overview-details';

export const useOverviewTheme = (): OverviewTheme | null => {
	const { data } = useOverview();

	if (data != null) {
		return {
			themeSetting: { type: 'color', value: data.theme ?? DEFAULT_BACKGROUND_CONFIG.name },
			entityId: `overview/${data.id}`,
		};
	}

	return null;
};
