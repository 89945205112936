import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const boardsDirectoryRoute = {
	group: ROUTE_GROUPS.DIRECTORIES,
	name: ROUTE_NAMES.BOARDS_DIRECTORY,
	path: '/jira/boards',
};

export const boardsDirectoryRedirectRoute = {
	group: ROUTE_GROUPS.DIRECTORIES,
	name: `${ROUTE_NAMES.BOARDS_DIRECTORY}-redirect`,
	path: '/secure/ManageRapidViews.jspa',
};
