import queryString from 'query-string';
// eslint-disable-next-line jira/restricted/@atlassian/jira-router
import { createResource, type RouterContext, type RouterDataContext } from '@atlassian/jira-router';
import type { Page, IssueTypeScheme } from '../../../types';
import { getIssueTypeSchemes } from '../../api/get-issue-types-schemes';
import { ISSUE_TYPE_SCHEME_TABLE_TYPE, ISSUE_TYPE_SCHEME_TABLE_KEY } from './constants';

const issueTypeSchemeResource = createResource<Page<IssueTypeScheme>>({
	type: ISSUE_TYPE_SCHEME_TABLE_TYPE,
	getKey: ({ query }: RouterContext) =>
		`${ISSUE_TYPE_SCHEME_TABLE_KEY}-${queryString.stringify(query)}`,
	maxAge: 0,
	getData: async ({ query }: RouterDataContext) =>
		getIssueTypeSchemes(queryString.stringify(query)),
});

export default issueTypeSchemeResource;
