import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const productsJsmOrganizationsRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.PRODUCTS_JSM_ORGANIZATIONS,
	path: '/jira/settings/products/servicedesk/organizations',
	exact: true,
};

export const productsJsmOrganizations1Route = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.PRODUCTS_JSM_ORGANIZATIONS,
	path: '/jira/settings/products/servicedesk/organizations/:organizationId',
	exact: true,
};
