import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type PolarisOnboardingPageType from '@atlassian/jira-spa-apps-polaris-onboarding';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyOnboardingPage = lazyForPaint<typeof PolarisOnboardingPageType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-polaris-onboarding", jiraSpaEntry: "async-polaris-ideas" */ '@atlassian/jira-spa-apps-polaris-onboarding'
			),
		),
	{ ssr: false },
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazyOnboardingPage} pageId="async-polaris-onboarding" shouldShowSpinner={false} />
);
