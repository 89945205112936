import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import type { SelectedPath, SidebarNav4ControllerType } from '../../common/types';
import { isSelected } from '../../common/utils/isSelected';

/**
 * This context is designed to allow the sidebar to be controlled.
 * Therefore, it must live very high in the component tree.
 */
const SidebarNav4ControllerContext = createContext<SidebarNav4ControllerType | null>(null);

type Props = {
	children: React.ReactNode;
	controlledPath?: SelectedPath | null;
};

export function SidebarNav4ControllerProvider(props: Props) {
	const { children, controlledPath: initialControlledPath = null } = props;
	const [controlledPath, setControlledPath] = useState<SelectedPath | null>(initialControlledPath); // Null means "not being controlled"

	const isControlled = useCallback(() => controlledPath !== null, [controlledPath]);

	const isControlledPath = useCallback(
		(menuId: string) => (controlledPath === null ? false : isSelected(controlledPath, menuId)),
		[controlledPath],
	);

	const sidebarNav4Controller = useMemo(
		() => ({ isControlled, isControlledPath, setControlledPath }),
		[isControlled, isControlledPath, setControlledPath],
	);

	return (
		<SidebarNav4ControllerContext.Provider value={sidebarNav4Controller}>
			{children}
		</SidebarNav4ControllerContext.Provider>
	);
}

export function useSidebarNav4Controller() {
	const context = useContext(SidebarNav4ControllerContext);

	if (context == null) {
		throw new Error('useSidebarNav4Controller must be used inside a SidebarNav4ControllerContext');
	}

	return context;
}
