import React from 'react';
import { AppSkeletonImage } from '../../common/ui';
import imgSVG from './assets/your-work-app-skeleton.tpl.svg';
import imgItemListSVG from './assets/your-work-itemlist-skeleton.tpl.svg';

export const YourWorkSkeleton = () => <AppSkeletonImage src={imgSVG} />;

export const YourWorkItemListSkeleton = () => (
	<AppSkeletonImage src={imgItemListSVG} containerStyles={{ maxHeight: '30em' }} />
);
