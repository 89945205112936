import React from 'react';
import { Section as SectionDI, SkeletonItem as SkeletonItemDI } from '@atlaskit/side-navigation';
import { useIntl as useIntlDI } from '@atlassian/jira-intl';
import {
	matchUrlParams as matchUrlParamsDI,
	MenuItem as MenuItemDI,
	useProject as useProjectDI,
	SectionHeader as SectionHeaderDI,
} from '@atlassian/jira-navigation-apps-sidebar-common';
import { withThrowOnError } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/throw-when-no-data-and-error';
import { ConnectMenu as ConnectMenuDI } from '@atlassian/jira-navigation-apps-sidebar-connect';
import { createReportUrlFunc } from '../../../../../../common/utils';
import { useDefaultReportItems as useDefaultReportItemsDI } from '../../../../../../controllers/default-report-items';
import { useReportFeatures as useReportFeaturesDI } from '../../../../../../controllers/features';
import messages from './messages';
import type { ReportsProps } from './types';

const useThrowDefaultReportItemsDI = withThrowOnError(useDefaultReportItemsDI);
const useThrowProjectDI = withThrowOnError(useProjectDI);
const useThrowReportFeaturesDI = withThrowOnError(useReportFeaturesDI);

export const Reports = ({
	ConnectMenu = ConnectMenuDI,
	MenuItem = MenuItemDI,
	Section = SectionDI,
	SkeletonItem = SkeletonItemDI,
	connectItems,
	SectionHeader = SectionHeaderDI,
	matchUrlParams = matchUrlParamsDI,
	useDefaultReportItems = useThrowDefaultReportItemsDI,
	useIntl = useIntlDI,
	useProject = useThrowProjectDI,
	useReportFeatures = useThrowReportFeaturesDI,
}: ReportsProps) => {
	const baseUrl = '';
	const { data: defaultItems } = useDefaultReportItems();
	const { data: features } = useReportFeatures();
	const { formatMessage } = useIntl();
	const { data: project } = useProject();

	if (project == null || features == null) return <SkeletonItem />;

	const createReportUrl = createReportUrlFunc({ baseUrl, project });

	return (
		<>
			{defaultItems && defaultItems.items.length > 0 && (
				// @ts-expect-error - TS2769 - No overload matches this call.
				<Section id={defaultItems.id} key={defaultItems.id}>
					<SectionHeader>{defaultItems.name}</SectionHeader>
					{defaultItems.items &&
						defaultItems.items.map((item) => (
							<MenuItem
								key={item.id}
								analytics={{ itemId: item.id }}
								selectedOn={matchUrlParams(['reportKey'])}
								href={item.url}
							>
								{item.name != null ? item.name : null}
							</MenuItem>
						))}
				</Section>
			)}
			{features && features.hasForcastSection && (
				<Section>
					<SectionHeader>{formatMessage(messages.forecastAndManagement)}</SectionHeader>
					{features.hasTimeTracking && (
						<MenuItem
							analytics={{ itemId: 'time-tracking' }}
							href={createReportUrl('com.atlassian.jira.plugin.system.reports:time-tracking')}
							selectedOn={matchUrlParams(['reportKey'])}
						>
							{formatMessage(messages.timeTracking)}
						</MenuItem>
					)}
					{features.hasDeveloperWorkload && (
						<MenuItem
							analytics={{ itemId: 'developer-workload' }}
							href={createReportUrl('com.atlassian.jira.plugin.system.reports:developer-workload')}
							selectedOn={matchUrlParams(['reportKey'])}
						>
							{formatMessage(messages.userWorkload)}
						</MenuItem>
					)}
					{features.hasVersionWorkloadReport && (
						<MenuItem
							analytics={{ itemId: 'version-workload' }}
							href={createReportUrl(
								'com.atlassian.jira.plugins.jira-software-plugin:version-workload-report',
							)}
							selectedOn={matchUrlParams(['reportKey'])}
						>
							{formatMessage(messages.versionWorkload)}
						</MenuItem>
					)}
				</Section>
			)}
			<Section>
				<SectionHeader>{formatMessage(messages.other)}</SectionHeader>
				<MenuItem
					analytics={{ itemId: 'workloadpie' }}
					href={createReportUrl('com.atlassian.jira.ext.charting:workloadpie-report')}
					selectedOn={matchUrlParams(['reportKey'])}
				>
					{formatMessage(messages.workloadPieChart)}
				</MenuItem>

				<ConnectMenu
					items={connectItems}
					overrides={{
						BackMenuItem: {
							href: `/projects/${project.key}?selectedItem=com.atlassian.jira.jira-projects-plugin:report-page`,
							title: formatMessage(messages.itemBack),
						},
					}}
				/>
			</Section>
		</>
	);
};
