import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ServicedeskCmdbObjectSchemaType from '@atlassian/jira-spa-apps-servicedesk-cmdb-object-schema-v2';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyServicedeskCmdbObjectSchemaV2 = lazyForPaint<
	typeof ServicedeskCmdbObjectSchemaType
>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-cmdb-object-schema-v2", jiraSpaEntry: "async-servicedesk-object-schema-v2" */ '@atlassian/jira-spa-apps-servicedesk-cmdb-object-schema-v2'
		),
	{ ssr: false },
);

export const ObjectSchemaV2 = () => (
	<LazyPage
		Page={LazyServicedeskCmdbObjectSchemaV2}
		pageId="spa-apps/servicedesk/insight/cmdb-object-schema"
		shouldShowSpinner
	/>
);

export default ObjectSchemaV2;
