import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const emailNotificationsLogAccessRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.GLOBAL_ADMIN_HELPER_EMAIL_NOTIFICATIONS_LOG,
	path: '/jira/settings/system/notification-logs',
	exact: true,
};
