import React, { memo } from 'react';
import WorldIcon from '@atlaskit/icon/glyph/world';
import {
	DEFAULT_BACKGROUND_CONFIG,
	type OverviewTheme,
} from '@atlassian/jira-custom-theme-constants';
import { WRAPPER } from './styled';
import type { Size } from './types';

type Props = {
	size?: Size;
	className?: string;
	themeName?: NonNullable<OverviewTheme['themeSetting']>['value'] | null;
};

const OverviewIcon = ({ size = 'medium', className, themeName }: Props) => {
	const theme = themeName ?? DEFAULT_BACKGROUND_CONFIG.name;

	const Wrapper = WRAPPER[size][theme];

	return (
		<Wrapper
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={className}
			data-testid="business-entity-overview.common.ui.overview-icon"
		>
			{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */}
			<WorldIcon label="" size={size} />
		</Wrapper>
	);
};

export default memo(OverviewIcon);
