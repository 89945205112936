import React, { forwardRef, type Ref, memo } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { ButtonProps } from '@atlaskit/button';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { StyledButton } from './styled';

type Props = {
	label: string;
	testId?: string;
	onClick: (e: React.MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => void;
} & Omit<ButtonProps, 'as'>;

const RoundCreateButton = forwardRef(
	({ label, onClick, testId, ...props }: Props, ref: Ref<HTMLElement>) => (
		<Tooltip content={label} hideTooltipOnClick>
			<StyledButton
				{...props}
				ref={ref}
				testId={testId}
				spacing="compact"
				iconBefore={
					<EditorAddIcon
						label={label}
						primaryColor={token('color.text', colors.N800)}
						size="small"
					/>
				}
				onClick={onClick}
			/>
		</Tooltip>
	),
);

export default memo(RoundCreateButton);
