import React from 'react';
import { styled } from '@compiled/react';
import { Section, SkeletonItem } from '@atlaskit/side-navigation';
import { token } from '@atlaskit/tokens';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize } from '@atlassian/jira-common-styles/src';
import UFOLoadHold from '@atlassian/ufo-load-hold';
import type { SidebarSkeletonItemProps } from './types';

export const Skeleton = ({ isFull = false }: SidebarSkeletonItemProps) => (
	<UFOLoadHold name="sidebar-skeleton">
		<BackgroundedWrapper>
			{isFull && (
				<HeadingWrapper>
					<SkeletonItem hasIcon />
				</HeadingWrapper>
			)}
			<Section>
				<SkeletonItem hasIcon={isFull} />
				<SkeletonItem hasIcon={isFull} />
				<SkeletonItem hasIcon={isFull} />
				<SkeletonItem hasIcon={isFull} />
				<SkeletonItem hasIcon={isFull} />
			</Section>
		</BackgroundedWrapper>
	</UFOLoadHold>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BackgroundedWrapper = styled.div({
	height: 'calc(100% - 15vh)',
	padding: `0 ${token('space.300', '24px')} 0 ${token('space.100', '8px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeadingWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	padding: `${gridSize * 3 + gridSize / 2}px 0 ${token('space.200', '16px')}`,
});
