import type { Route } from '@atlassian/jira-router';
import {
	helpCenterSettingsRedirectRouteEntry,
	helpCenterSettingsKbRouteEntry,
	helpCenterSettingsCustomerNotificationsRouteEntry,
	helpCenterSettingsEmailRouteEntry,
	helpCenterSettingsTicketTypesConfigRouteEntry,
	helpCenterSettingsTicketTypeFieldsRouteEntry,
} from '@atlassian/jira-router-routes-help-center-settings-entries';

/*
 *************************************************** ATTENTION ***************************************************
 * Take special care with any redirect components you render. For legacy routes they should redirect to the legacy URL,
 * and for product routes the should redirect to the product URL.
 */
export const helpCenterSettingsRouteEntries: Route[] = [
	helpCenterSettingsRedirectRouteEntry,
	helpCenterSettingsKbRouteEntry,
	helpCenterSettingsCustomerNotificationsRouteEntry,
	helpCenterSettingsEmailRouteEntry,
	helpCenterSettingsTicketTypesConfigRouteEntry,
	helpCenterSettingsTicketTypeFieldsRouteEntry,
];
