import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { HorizontalSoftwareProjectNav } from './ui';

const LazyHorizontalSoftwareProjectNav = lazyForPaint<typeof HorizontalSoftwareProjectNav>(() =>
	import(/* webpackChunkName: "async-software-horizontal-nav" */ './ui').then(
		({ HorizontalSoftwareProjectNav }) => HorizontalSoftwareProjectNav,
	),
);

export const AsyncHorizontalSoftwareProjectNav = () => (
	<Placeholder name="horizontal-software-project-nav" fallback={null}>
		<LazyHorizontalSoftwareProjectNav />
	</Placeholder>
);
