import { INSIGHTS_SETTINGS_ANALYTICS } from '@atlassian/jira-insights-settings/src/common/constants';

export const RESOURCE_TYPE = 'INSIGHT_SETTINGS_RESOURCE_TYPE';
export const KEY_PREFIX = 'INSIGHT_SETTINGS_BOARD';
export const insightsSettingsLoaderAnalytics = {
	ACTION_SUBJECT: INSIGHTS_SETTINGS_ANALYTICS,
	ACTION_SUBJECT_ID: 'get-settings',
} as const;
export const ANALYTICS_SOURCE = 'insightsBoardSettingsScreen';
export const FIVE_MINS_IN_MILLS = 5 * 60 * 1000;
