import { useState, useLayoutEffect } from 'react';
import { useLeftSidebarState } from '@atlassian/jira-layout-controller';

const TIMEOUT = 500;

export const useIsFullyExpanded = () => {
	const [ready, setReady] = useState(false);
	const { isCollapsed } = useLeftSidebarState();

	useLayoutEffect(() => {
		const timeoutId = setTimeout(() => {
			setReady(!isCollapsed);
		}, TIMEOUT);
		return () => clearTimeout(timeoutId);
	}, [isCollapsed]);

	return ready && !isCollapsed;
};
