import React, { type ComponentPropsWithoutRef } from 'react';
import { Router as ResourceRouter } from 'react-resource-router';
import type { ResourceStoreContext, ResourceStoreData } from 'react-resource-router/resources';
import { usePlugins } from '../../controller';

type Props = Omit<ComponentPropsWithoutRef<typeof ResourceRouter>, 'plugins'> & {
	resourceContext?: ResourceStoreContext | undefined;
	resourceData?: ResourceStoreData | undefined;
};

export const Router = ({ children, ...props }: Props) => {
	const pluginsWithOverride = usePlugins({
		resourceContext: props.resourceContext,
		resourceData: props.resourceData,
	});

	return (
		<ResourceRouter plugins={pluginsWithOverride} {...props}>
			{children}
		</ResourceRouter>
	);
};
