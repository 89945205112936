import {
	type TenantInfo,
	ConsolidationState,
} from '@atlassian/jira-router-resources-eoc-tenant-info';
import { EMPTY_STATE_TRANSITIONING_COHORT, TRANSITIONING_COHORT } from './constants';

export const checkShouldUseJSM = (tenantInfo?: TenantInfo | ConsolidationState | null) => {
	if (!tenantInfo) {
		return false;
	}

	// remove string check after ff is cleanuped 'eoc-tenant-info-hook_dq6ke'
	return typeof tenantInfo === 'string'
		? tenantInfo !== ConsolidationState.NOT_CONSOLIDATED
		: tenantInfo.consolidationState !== ConsolidationState.NOT_CONSOLIDATED;
};

export const checkIsTransitioning = (tenantInfo?: TenantInfo | ConsolidationState | null) => {
	if (!tenantInfo) {
		return false;
	}

	// remove string check after ff is cleanuped 'eoc-tenant-info-hook_dq6ke'
	return typeof tenantInfo === 'string'
		? tenantInfo === ConsolidationState.TRANSITIONING
		: tenantInfo.consolidationState === ConsolidationState.TRANSITIONING;
};

export const checkIsConsolidated = (tenantInfo?: TenantInfo | ConsolidationState | null) => {
	if (!tenantInfo) {
		return false;
	}

	return typeof tenantInfo === 'string'
		? tenantInfo === ConsolidationState.CONSOLIDATED
		: tenantInfo.consolidationState === ConsolidationState.CONSOLIDATED;
};

export const checkIsTransitioned = (tenantInfo?: TenantInfo | ConsolidationState | null) => {
	if (!tenantInfo) {
		return false;
	}

	// remove string check after ff is cleanuped 'eoc-tenant-info-hook_dq6ke'
	return typeof tenantInfo === 'string'
		? tenantInfo === ConsolidationState.TRANSITIONED
		: tenantInfo.consolidationState === ConsolidationState.TRANSITIONED;
};

export const checkIsTransitionEligible = (tenantInfo?: TenantInfo | ConsolidationState | null) => {
	if (!tenantInfo) {
		return false;
	}

	// remove string check after ff is cleanuped 'eoc-tenant-info-hook_dq6ke'
	// even though FF is rolled out 100% if the tenantInfo is string it means we don't know the cohort info
	return typeof tenantInfo === 'string'
		? false
		: tenantInfo.consolidationState === ConsolidationState.NOT_CONSOLIDATED &&
				tenantInfo.cohortInfo === TRANSITIONING_COHORT;
};

export const checkShouldAccessUnlockOperations = (
	tenantInfo?: TenantInfo | ConsolidationState | null,
) => {
	if (!tenantInfo) {
		return false;
	}

	// remove string check after ff is cleanuped 'eoc-tenant-info-hook_dq6ke'
	// even though FF is rolled out 100% if the tenantInfo is string it means we don't know the cohort info
	return typeof tenantInfo === 'string'
		? false
		: tenantInfo.consolidationState !== ConsolidationState.CONSOLIDATED &&
				(tenantInfo.cohortInfo === TRANSITIONING_COHORT ||
					tenantInfo.cohortInfo === EMPTY_STATE_TRANSITIONING_COHORT);
};
