import { DefaultInteractionID } from '@atlassian/ufo-interaction-id-context';
import { addRedirect } from '@atlassian/ufo-interaction-metrics';
import UFORouteName from '@atlassian/ufo-route-name-context';

export default function traceUFORedirect(
	fromUfoName: string,
	nextUfoName: string,
	nextRouteName: string,
	time: number,
) {
	UFORouteName.current = nextUfoName;
	const interactionId = DefaultInteractionID.current;
	if (interactionId) {
		addRedirect(interactionId, fromUfoName, nextUfoName, nextRouteName, time);
	}
}
