import { createSelector } from 'reselect';
import { createSubscriber } from 'react-sweet-state';
import type { ApplicationPermissions } from '@atlassian/jira-shared-types';
import Store from '../../store';
import type { Actions, State } from '../../types';
import { useTenantContext } from '../tenant-context';

type SelectorOutput = {
	data: {
		applicationPermissions: ApplicationPermissions;
	};
};

const selector = createSelector(
	(state: State) => state.tenantContext.appPermissions,
	(applicationPermissions) => ({
		data: { applicationPermissions },
	}),
);

export const ApplicationPermissionsSubscriber = createSubscriber<
	State,
	Actions,
	SelectorOutput,
	undefined
>(Store, {
	displayName: 'ApplicationPermissionsSubscriber',
	selector,
});

export const useApplicationPermissions = (): ApplicationPermissions => {
	const tenantContext = useTenantContext();
	return tenantContext && tenantContext.appPermissions;
};
