import React, {
	type ReactNode,
	createContext,
	useContext,
	useLayoutEffect,
	useRef,
	useState,
	useMemo,
	useCallback,
} from 'react';
import { styled } from '@compiled/react';
import { DEFAULT_CONTAINER_QUERY_OBSERVER_STATE } from '../common/constants';

interface ContainerQueryObserverProps {
	children?: ReactNode;
	query: string;
	onChange?: (state: boolean) => void;
	defaultState?: boolean;
}

type QueryState = boolean | undefined;

const QueryContext = createContext<QueryState>(undefined);

let currentIdSeed = 0;

function generateAttributeValue(): string {
	return `indicator-span-${currentIdSeed++}`;
}

/**
 * @param query *required* the container query to be observed
 * @param onChange *optional* void callback which takes in the state that was changed to
 * @param defaultState *optional* provides the initial state which the ContainerQueryObserver should detect changes from
 * @example
 * `<ContainerQueryObserver query='(max-width:1020px)'
 *   onChange={(state:boolean) => console.log(state)}/>`
 */
export function ContainerQueryObserver({
	children,
	query,
	onChange,
	defaultState = DEFAULT_CONTAINER_QUERY_OBSERVER_STATE,
}: ContainerQueryObserverProps) {
	const [active, setActive] = useState<QueryState>(defaultState);
	const handleQueryStatusChange = useCallback(
		(isContainerQueryActive: boolean) => {
			onChange?.(isContainerQueryActive);
			setActive(isContainerQueryActive);
		},
		[onChange],
	);
	return (
		<QueryContext.Provider value={active}>
			{!__SERVER__ && (
				<IntersectionIndicator
					query={query}
					notifyIntersectionStatusChanged={handleQueryStatusChange}
				/>
			)}
			{children}
		</QueryContext.Provider>
	);
}

/**
 * Hook to get the current query state of the closest parent `ContainerQueryObserver`
 *
 * @throws error if it is called without having a parent `ContainerQueryObserver`
 * @returns currentState `true` or `false` depending on the query state
 */
export function useContainerQueryState() {
	const currentState = useContext(QueryContext);
	if (currentState === undefined) {
		throw new Error(
			'The component must be wrapped in a ContainerQueryObserver to use useContainerQueryState',
		);
	}
	return currentState;
}

function IntersectionIndicator({
	notifyIntersectionStatusChanged: handleChange,
	query,
}: {
	notifyIntersectionStatusChanged: (state: boolean) => void;
	query: string;
}) {
	const attributeValue = useMemo(() => generateAttributeValue(), []);
	const spanRef = useRef<HTMLSpanElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);
	const currentState = useContainerQueryState();
	useLayoutEffect(() => {
		const queryStatusChangedCallback = (entries: IntersectionObserverEntry[]) => {
			// This means that the parent container is either empty or loading, we should not notify of any change in this case.
			if (!containerRef.current || containerRef.current.clientWidth === 0) {
				return;
			}

			if (entries.length > 0) {
				// only ever going to be one entry max
				const isContainerQueryActive = entries[0].isIntersecting;
				if (isContainerQueryActive !== currentState) {
					handleChange(isContainerQueryActive);
				}
			}
		};
		const intersectionObserver = new IntersectionObserver(queryStatusChangedCallback);
		const spanElement = spanRef.current;
		spanElement && intersectionObserver.observe(spanElement);
		return () => {
			spanElement && intersectionObserver.unobserve(spanElement);
			intersectionObserver.disconnect();
		};
	}, [currentState, handleChange]);
	return (
		<ContainerToBeQueried ref={containerRef}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766 */}
			<style type="text/css">
				{`
                    [data-container-query-indicator-id="${attributeValue}"] {
                        display: none;
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100vh; 
                        pointer-events: none;
                    }
                    @container ${query} {
                        [data-container-query-indicator-id="${attributeValue}"] {
                            display: inherit;
                        }
                    }`}
			</style>
			<span ref={spanRef} data-container-query-indicator-id={attributeValue} />
		</ContainerToBeQueried>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContainerToBeQueried = styled.div({
	position: 'sticky',
	pointerEvents: 'none',
	top: '0',
	containerType: 'size',
});
