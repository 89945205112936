import React, { type ComponentType } from 'react';
import { useSidebarVisibility } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/plan';
import { useRouter } from '@atlassian/jira-router';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect';
import type { Configuration } from './types';

export function withRedirection(
	Component: ComponentType,
	{ to, redirectCondition }: Configuration,
) {
	return () => {
		const { isSidebarVisible } = useSidebarVisibility();
		const [{ match }] = useRouter();
		if (
			match == null ||
			match.params == null ||
			match.params.planId == null ||
			match.params.scenarioId == null
		) {
			return <ScreenRedirect to="/jira/plans" />;
		}

		if (!redirectCondition(isSidebarVisible)) {
			return <Component />;
		}
		return (
			<ScreenRedirect
				to={`/jira/plans/${match.params.planId}/scenarios/${match.params.scenarioId}${to}`}
			/>
		);
	};
}
