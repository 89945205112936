import { ff } from '@atlassian/jira-feature-flagging';
import { getPermalinkId, COMMENTS } from '../../../../common/utils/permalinks';
import { isJsmTimelineEnabled } from '../../../../feature-flags';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({
	maxResults,
	startAt,
	orderBy,
	commentAmount,
	hydrateData = true,
}: {
	startAt: number;
	maxResults?: number;
	orderBy?: string;
	commentAmount?: number;
	hydrateData?: boolean;
}) => {
	const commentPermalinkId = getPermalinkId(COMMENTS);

	const orderByParameter =
		orderBy !== null && orderBy !== undefined ? `, orderBy: "${orderBy}"` : '';

	const commentParameter =
		commentPermalinkId !== null && commentPermalinkId !== undefined
			? `, commentId: ${commentPermalinkId}`
			: '';

	const commentAmountParameter =
		commentParameter !== '' && commentAmount !== null && commentAmount !== undefined
			? `, commentAmount: ${commentAmount}`
			: '';

	const maxResultsParameter =
		commentParameter === '' && maxResults !== null && maxResults !== undefined
			? `, maxResults: ${maxResults}`
			: '';

	const jiraCommentVisibility = ` jiraCommentVisibility {
    __typename
    ...on JiraRoleVisibility {
      name
    }
    ...on JiraGroupVisibility {
      group {
        groupId
        name
      }
    }
  }`;
	if (
		ff('jiv-14067-issue.details.gira-agg-migration-comments_pzao9') &&
		!ff('issue-jiv-14530-comments-consistency-check_x716d') &&
		!hydrateData
	) {
		return `
        comments (first: ${startAt}${maxResultsParameter}${orderByParameter}${commentParameter}${commentAmountParameter}) {
            totalCount
            startIndex
        }
    `;
	}
	return `
        comments (first: ${startAt}${maxResultsParameter}${orderByParameter}${commentParameter}${commentAmountParameter}) {
            nodes {
                id
                author {
                    accountId
                    displayName
                    avatarUrl
                }
                updateAuthor {
                    accountId
                    displayName
                    avatarUrl
                }
                ${jiraCommentVisibility}
                created
                updated
                jsdPublic
                bodyAdf
                jsdAuthorCanSeeRequest
                ${isJsmTimelineEnabled() ? 'eventOccurredAt' : ''}
                ${isJsmTimelineEnabled() ? 'jsdIncidentActivityViewHidden' : ''}
            }
            totalCount
            startIndex
        }
    `;
};
