import React, { memo } from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import {
	setPerformanceMarkLoadStart,
	setPerformanceMarkLoadEnd,
} from '@atlassian/jira-navigation-apps-sidebar-common';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-common/async';
import type PolarisProjectSidebarType from './index';

const LazyPolarisProjectSidebar = lazyAfterPaint<typeof PolarisProjectSidebarType>(() => {
	setPerformanceMarkLoadStart();
	return import(/* webpackChunkName: "async-sidebar-polaris" */ './index').then((bundle) => {
		setPerformanceMarkLoadEnd();
		return bundle;
	});
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PolarisProjectSidebar = memo<Record<any, any>>(() => (
	<SidebarBoundary id="polarisProject">
		<LazyPolarisProjectSidebar />
	</SidebarBoundary>
));

PolarisProjectSidebar.displayName = 'PolarisProjectSidebar';

export default PolarisProjectSidebar;
