import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const settingsCmdbFieldConfigRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.SETTINGS_CMDB_FIELD_CONFIG,
	path: '/secure/admin/CmdbObjectFieldConfiguration',
};
