import { createResource, useResource } from '@atlassian/jira-router';
import { fetchOverviewDetails } from './services/overview-details';
import type { OverviewDetailsResource } from './types';

export type { OverviewDetailsResource } from './types';

const RESOURCE_TYPE_KEY = 'BUSINESS_OVERVIEW_DETAILS';

export const overviewDetailsResource = createResource<OverviewDetailsResource>({
	type: RESOURCE_TYPE_KEY,
	getKey: ({ match }) => `${RESOURCE_TYPE_KEY}_${match.params.overviewId}`,
	getData: async ({ match }, { tenantContext: { cloudId, activationId } }) =>
		fetchOverviewDetails(cloudId, activationId, match.params.overviewId),
	maxAge: 60 * 60 * 1000, // 1 hour
});

export const useOverview = () => useResource<OverviewDetailsResource>(overviewDetailsResource);
