import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import type { PayloadError } from 'relay-runtime';
import { log } from '@atlassian/jira-common-util-logging';
import HttpError from '@atlassian/jira-fetch/src/utils';

const LOG_NAMESPACE = 'relay.AGG.error.graphql';

type GraphqlQueryDetails = {
	queryName: string;
	operationKind: string;
};

export const getLoggingAndMetricsHelper = (graphqlDetails: GraphqlQueryDetails) => {
	const { queryName, operationKind } = graphqlDetails;

	return {
		logNonBreakingErrors: (errors?: PayloadError[] | null, requestId?: string) => {
			if (__SERVER__ || isNil(errors) || !Array.isArray(errors)) {
				return;
			}
			errors.forEach((error) => {
				error &&
					log.safeErrorWithoutCustomerData(LOG_NAMESPACE, '[graphql error]', {
						queryName,
						operationKind,
						errorType: 'graphql',
						message: error.message,
						requestId,
					});
			});
		},
		logError: (error?: Error) => {
			const statusCode = get(error, ['statusCode'], null);
			const errorType = error instanceof HttpError ? 'network' : 'unknown';

			// Log any errors even in SSR
			log.safeErrorWithoutCustomerData(LOG_NAMESPACE, `[${errorType} error]`, {
				message: `[${capitalize(errorType)} error]: ${error?.message ?? ''}`,
				errorType,
				statusCode,
				queryName,
				operationKind,
				errorName: get(error, ['name'], null),
			});
		},
		logErrorRetry: (error?: Error, retryAttempt?: number) => {
			const statusCode = get(error, ['statusCode'], null);
			const errorType = error instanceof HttpError ? 'network' : 'unknown';

			// Log any errors even in SSR
			log.safeErrorWithoutCustomerData(LOG_NAMESPACE, `[${errorType} error]`, {
				message: `[${capitalize(errorType)} error]: ${error?.message ?? ''}`,
				errorType,
				statusCode,
				queryName,
				operationKind,
				errorName: get(error, ['name'], null),
				retryAttempt,
			});
		},
	};
};
