import {
	usePathParam,
	useRouter,
	type RouterContext,
	type RouterDataContext,
} from '@atlassian/jira-router';

export function useIsPlanUrl() {
	const [{ location }] = useRouter();
	const [planId] = usePathParam('planId');

	return isPlanUrl(location.pathname, planId);
}

export function isPlanRoute(routerContext: RouterContext | RouterDataContext) {
	const { match } = routerContext;
	const planId = match.params.planId;

	return isPlanUrl(match.path, planId);
}

function isPlanUrl(path: string, planId?: string | null) {
	if (planId) {
		return true;
	}

	// TODO: Support other miscellaneous plan urls, such as reports

	return Boolean(path === '/jira/plans');
}
