import type {
	AdminPageModule,
	ProjectPageModule,
	ProjectSettingsPageModule,
	GlobalPageModule,
	CustomFieldModule,
	IssueActionModule,
	IssueActivityModule,
	IssueGlanceModule,
	IssueContextModule,
	IssuePanelModule,
	CustomFieldTypeModule,
	DashboardBackgroundScriptModule,
	DashboardGadgetModule,
	WorkflowValidatorModule,
	WorkflowConditionModule,
	WorkflowPostFunctionModule,
	ServiceDeskQueuePageModule,
	UiModificationsModule,
	ServiceDeskOrganizationPanelModule,
	ServiceDeskAssetsImportTypeModule,
	IssueViewBackgroundScriptModule,
	Source,
	FullPageLayout,
	ContextTokenType,
	ExtensionContextsArrayMap,
	ExtensionPointModule,
} from '@atlassian/jira-forge-ui-types';

export const PRODUCTION_ENVIRONMENT = 'PRODUCTION';
export const STAGING_ENVIRONMENT = 'STAGING';
export const DEVELOPMENT_ENVIRONMENT = 'DEVELOPMENT';
export const DEFAULT_ENVIRONMENT_KEY = 'default';

export const ISSUE_ACTION_MODULE: IssueActionModule = 'jira:issueAction';
export const ISSUE_GLANCE_MODULE: IssueGlanceModule = 'jira:issueGlance';
export const ISSUE_CONTEXT_MODULE: IssueContextModule = 'jira:issueContext';
export const ISSUE_PANEL_MODULE: IssuePanelModule = 'jira:issuePanel';
export const ISSUE_ACTIVITY_MODULE: IssueActivityModule = 'jira:issueActivity';
export const ISSUE_VIEW_BACKGROUND_SCRIPT_MODULE: IssueViewBackgroundScriptModule =
	'jira:issueViewBackgroundScript';
export const UI_MODIFICATIONS_MODULE: UiModificationsModule = 'jira:uiModifications';
export const CUSTOM_FIELD_MODULE: CustomFieldModule = 'jira:customField';
export const CUSTOM_FIELD_TYPE_MODULE: CustomFieldTypeModule = 'jira:customFieldType';
export const ADMIN_PAGE_MODULE: AdminPageModule = 'jira:adminPage';
export const PROJECT_PAGE_MODULE: ProjectPageModule = 'jira:projectPage';
export const PROJECT_SETTINGS_PAGE_MODULE: ProjectSettingsPageModule = 'jira:projectSettingsPage';
export const GLOBAL_PAGE_MODULE: GlobalPageModule = 'jira:globalPage';
export const DASHBOARD_BACKGROUND_SCRIPT_MODULE: DashboardBackgroundScriptModule =
	'jira:dashboardBackgroundScript';
export const DASHBOARD_GADGET_MODULE: DashboardGadgetModule = 'jira:dashboardGadget';
export const WORKFLOW_VALIDATOR_MODULE: WorkflowValidatorModule = 'jira:workflowValidator';
export const WORKFLOW_CONDITION_MODULE: WorkflowConditionModule = 'jira:workflowCondition';
export const WORKFLOW_POST_FUNCTION_MODULE: WorkflowPostFunctionModule =
	'jira:workflowPostFunction';
export const SERVICEDESK_QUEUE_PAGE_MODULE: ServiceDeskQueuePageModule =
	'jiraServiceManagement:queuePage';
export const SERVICEDESK_ORGANIZATION_PANEL_MODULE: ServiceDeskOrganizationPanelModule =
	'jiraServiceManagement:organizationPanel';
export const SERVICEDESK_ASSETS_IMPORT_TYPE_MODULE: ServiceDeskAssetsImportTypeModule =
	'jiraServiceManagement:assetsImportType';

export const SUPPORTED_MODULES: ExtensionPointModule[] = [
	ISSUE_ACTION_MODULE,
	ISSUE_GLANCE_MODULE,
	ISSUE_CONTEXT_MODULE,
	ISSUE_PANEL_MODULE,
	ISSUE_ACTIVITY_MODULE,
	ISSUE_VIEW_BACKGROUND_SCRIPT_MODULE,
	UI_MODIFICATIONS_MODULE,
	CUSTOM_FIELD_MODULE,
	CUSTOM_FIELD_TYPE_MODULE,
	ADMIN_PAGE_MODULE,
	PROJECT_PAGE_MODULE,
	PROJECT_SETTINGS_PAGE_MODULE,
	GLOBAL_PAGE_MODULE,
	DASHBOARD_BACKGROUND_SCRIPT_MODULE,
	DASHBOARD_GADGET_MODULE,
	SERVICEDESK_QUEUE_PAGE_MODULE,
	SERVICEDESK_ORGANIZATION_PANEL_MODULE,
	SERVICEDESK_ASSETS_IMPORT_TYPE_MODULE,
	WORKFLOW_VALIDATOR_MODULE,
	WORKFLOW_CONDITION_MODULE,
	WORKFLOW_POST_FUNCTION_MODULE,
];

export const ENTRY_POINT_EDIT = 'edit' as const;
export const ENTRY_POINT_VIEW = 'view' as const;
export const ENTRY_POINT_CREATE = 'create' as const;
export const ENTRY_POINT_CONTEXT_CONFIG = 'contextConfig' as const;

export const NONE_PANEL_INSTANCE_ID = 'none' as const;

export const ISSUE_PANEL_MULTIPLE_LIMIT = 5;

export const FULL_PAGE_LAYOUT_NATIVE: FullPageLayout = 'native';
export const FULL_PAGE_LAYOUT_BASIC: FullPageLayout = 'basic';
export const FULL_PAGE_LAYOUT_BLANK: FullPageLayout = 'blank';

export const SOURCE_UNKNOWN: Source = 'unknownSource';
export const SOURCE_GLOBAL_ISSUE_CREATE: Source = 'global-issue-create';
export const SOURCE_ISSUE_VIEW: Source = 'issue-view';
export const SOURCE_NAVIGATION: Source = 'navigation';
export const SOURCE_FULL_PAGE_APP: Source = 'full-page-app';
export const SOURCE_ROUTER: Source = 'router';
export const SOURCE_LEGACY: Source = 'legacy';
export const SOURCE_WORKFLOW_LIST: Source = 'workflow-list';
export const SOURCE_ORGANIZATION_PANEL: Source = 'organization-panel';
export const SOURCE_TRANSITION_VIEW: Source = 'transition-view';

export const CONTEXT_TOKEN_TYPE_JIRA: ContextTokenType = 'jira';
export const CONTEXT_TOKEN_TYPE_JSM: ContextTokenType = 'jsm';
export const CONTEXT_TOKEN_TYPE_CMDB: ContextTokenType = 'cmdb';

export const EMPTY_EXTENSIONS_OBJECT: ExtensionContextsArrayMap = {
	jiraIssueAction: [],
	jiraIssueContext: [],
	jiraIssueGlance: [],
	jiraIssuePanel: [],
	jiraIssueActivity: [],
	jiraIssueViewBackgroundScript: [],
	jiraCustomField: [],
	jiraCustomFieldType: [],
	jiraUiModifications: [],
	jiraAdminPage: [],
	jiraGlobalPage: [],
	jiraProjectPage: [],
	jiraProjectSettingsPage: [],
	jiraDashboardBackgroundScript: [],
	jiraDashboardGadget: [],
	jiraServiceManagementQueuePage: [],
	jiraServiceManagementOrganizationPanel: [],
	jiraServiceManagementAssetsImportType: [],
	jiraWorkflowValidator: [],
	jiraWorkflowCondition: [],
	jiraWorkflowPostFunction: [],
};

/**
 * The above EMPTY_EXTENSIONS_OBJECT sometimes is mutated.
 * @see getEmptyExtensionsObject
 */
const STABLE_EMPTY_EXTENSIONS_OBJECT: ExtensionContextsArrayMap = {
	...EMPTY_EXTENSIONS_OBJECT,
};

/**
 * Provides an empty extensions object that will not be reused over multiple calls and
 * is guaranteed to be empty.
 *
 * It happens that in some places we were mutating the EMPTY_EXTENSIONS_OBJECT object above,
 * so it was not "empty" anymore on further usages.
 *
 * This could probably be enforced by some recursive Object.freeze, but then I'd need to go and
 * verify all the fetch modules places using the v2 version to make sure they don't accidentally
 * depend on this object being mutable. The alternative is using this method to create a true copy
 * and make sure fetch modules v3 works correctly with it.
 */
export const getEmptyExtensionsObject = (): ExtensionContextsArrayMap => ({
	...STABLE_EMPTY_EXTENSIONS_OBJECT,
});

export const FORGE_FIELD_TYPE_PREFIX = 'ari:cloud:ecosystem::extension/';
export const PARTIAL_LEGACY_FORGE_FIELD_TYPE_PREFIX = 'com.atlassian.forge:';
export const LEGACY_FORGE_FIELD_TYPE_PREFIX = `${PARTIAL_LEGACY_FORGE_FIELD_TYPE_PREFIX}${FORGE_FIELD_TYPE_PREFIX}`;
