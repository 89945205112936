import { useMemo } from 'react';
import { useIntl } from 'react-intl-next';
import { getRelativeTimeAndUnit } from './formatted-relative';
import type { IntlShapeV2 } from './types';

/**
 * @deprecated use useIntl
 */
export const useIntlV2 = () => {
	const {
		messages,
		locale,
		formats,
		formatDate,
		formatMessage,
		formatRelativeTime,
		formatNumber,
		formatPlural,
		formatTime,
	} = useIntl();

	// Only recreate intl object if reference to originral intl changes.
	const memoisedIntl = useMemo(() => {
		const modifiedIntl: IntlShapeV2 = {
			// Now is deprecated since version 2: https://github.com/formatjs/formatjs/issues/1409
			now: () => Date.now(),
			// @ts-expect-error - TS2322 - Type 'Record<string, string> | Record<string, MessageFormatElement[]>' is not assignable to type '{ [id: string]: string; }'.
			messages,
			locale,
			formats,
			formatDate,
			formatMessage,
			formatHTMLMessage: (descriptor, values) =>
				formatMessage(descriptor, values, { ignoreTag: true }),
			formatNumber,
			formatRelative: (date, opts) => {
				const { time, unit } = getRelativeTimeAndUnit(date);
				return formatRelativeTime(time, unit, opts);
			},
			formatPlural,
			formatTime,
		};

		return modifiedIntl;
	}, [
		formatDate,
		formatMessage,
		formatPlural,
		formatRelativeTime,
		formatTime,
		formatNumber,
		formats,
		locale,
		messages,
	]);

	return memoisedIntl;
};
