import type { NavigationTheme, LegacyEmotionCssObject } from '../../../../../types';

export const getCustomThemeCss = (theme: NavigationTheme | null): LegacyEmotionCssObject => {
	if (theme?.navigation) {
		const {
			navigation: { item, icon },
		} = theme;
		return {
			backgroundColor: item?.background?.default ?? undefined,
			color: item?.color?.default ?? undefined,

			'& [data-item-title]': {
				color: item?.color?.default ?? undefined,
			},

			'&:hover': {
				color: item?.color?.hover ?? undefined,
				backgroundColor: item?.background?.hover ?? undefined,
			},

			/* fixes CSS leaking from the monolith */
			'&:focus, &:visited': {
				color: item?.color?.default,
			},

			'&:active': {
				color: item?.color?.active ?? undefined,
				backgroundColor: item?.background?.active ?? undefined,
			},

			'&:active [data-item-title]': {
				color: item?.color?.active ?? undefined,
			},

			'&:active [data-item-elem-before]': {
				color: item?.color?.active ?? undefined,
			},

			'&:focus': {
				boxShadow:
					item?.color?.active != null ? `${item?.color?.active} 0 0 0 2px inset` : undefined,
			},

			'&[aria-current="page"]': {
				position: 'relative',
				backgroundColor: item?.background?.active ?? undefined,
				color: item?.color?.active ?? undefined,
			},

			'&[aria-current="page"]:before': {
				background: item?.color?.active ?? undefined,
			},

			'& [data-item-elem-after]': {
				color: icon?.secondary?.color?.hover ?? undefined,
			},
		};
	}

	return {};
};
