import type { ItemMap } from '@atlassian/jira-favourite-change-provider';
import {
	PROJECT_PAGE_SIZE,
	type NavigationProject,
} from '@atlassian/jira-router-resources-business-navigation';

export const prependProject = (project: NavigationProject, projects: NavigationProject[]) =>
	[project, ...projects].slice(0, PROJECT_PAGE_SIZE);

export const mapProjectChanges = (
	project: NavigationProject,
	changedItems: ItemMap,
): NavigationProject =>
	project.id in changedItems ? { ...project, isFavorite: changedItems[project.id].value } : project;
