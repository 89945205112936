import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ForgeAdminPageType from '@atlassian/jira-spa-apps-forge-admin';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyForgeAdminPage = lazyForPaint<typeof ForgeAdminPageType>(
	() =>
		import(
			/* webpackChunkName: "async-forge-spa-admin", jiraSpaEntry: "async-forge-spa-admin" */ '@atlassian/jira-spa-apps-forge-admin'
		),
);

export const ForgeAdminPage = () => (
	<LazyPage Page={LazyForgeAdminPage} pageId="forge-spa-admin" shouldShowSpinner />
);

export default ForgeAdminPage;
