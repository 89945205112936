import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type JiraBusinessApprovalsAppType from '@atlassian/jira-spa-apps-business-approvals';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyApprovalsView = lazyForPaint<typeof JiraBusinessApprovalsAppType>(
	() =>
		import(
			/* webpackChunkName: "async-business-approvals", jiraSpaEntry: "async-business-approvals" */ '@atlassian/jira-spa-apps-business-approvals'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazyApprovalsView} pageId="async-business-approvals" shouldShowSpinner />
);
