import { useEffect, useState } from 'react';
// eslint-disable-next-line jira/restricted/use-debounce
import { useDebouncedCallback } from 'use-debounce';
import { setUserProperties } from '@atlassian/jira-common-rest/src/api/latest/user-properties';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { useAccountId } from '@atlassian/jira-tenant-context-controller';
import { useHelpPanel } from '../components';
import { HELP_PANEL_UI_STORAGE_KEY } from '../constants';

const DEBOUNCE_TIME = 1000;

export const RemoteStateUpdater = () => {
	// right-sidebar
	const [{ releaseNotesNotifications }] = useHelpPanel();
	const [releaseNotesNotificationsTemp, setReleaseNotesNotificationsTemp] =
		useState(releaseNotesNotifications);

	const accountId = useAccountId();

	const [debouncedUpdateData] = useDebouncedCallback(async (newReleaseNotesNotifications) => {
		const remoteData = {
			helpPanel: { releaseNotesNotifications: newReleaseNotesNotifications },
		};

		if (accountId) {
			try {
				await setUserProperties(accountId, HELP_PANEL_UI_STORAGE_KEY, JSON.stringify(remoteData));
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (e: any) {
				fireErrorAnalytics({
					error: e,
					meta: {
						id: 'updateServerHelpPanelState',
						packageName: 'jiraHelpPanelServices',
						teamName: 'self-help-experiences',
					},
				});
			}
		}
	}, DEBOUNCE_TIME);

	useEffect(() => {
		if (releaseNotesNotificationsTemp !== releaseNotesNotifications) {
			setReleaseNotesNotificationsTemp(releaseNotesNotifications);
			debouncedUpdateData(releaseNotesNotifications);
		}
	}, [debouncedUpdateData, releaseNotesNotifications, releaseNotesNotificationsTemp]);

	return null;
};
