import { createHook, createStore } from 'react-sweet-state';
import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types';
import * as actions from './actions';
import type { State, ProjectContext, SelectorProps, IssueType } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialState: Record<string, any> = {};
type Actions = typeof actions;

export const store = createStore<State, Actions>({
	name: 'project-context',
	initialState,
	actions,
});

export const useProjectContextActionStore = createHook(store, {
	selector: null,
});

const getProjectContext = (
	state: State,
	{ projectKey }: SelectorProps,
): ProjectContext | undefined => state[projectKey];
export const useProjectContextStore = createHook(store, {
	selector: getProjectContext,
});

const getProjectType = (state: State, { projectKey }: SelectorProps): ProjectType | undefined => {
	const projectContext = getProjectContext(state, { projectKey });
	return projectContext?.projectType;
};

export const useProjectContextProjectTypeStore = createHook(store, {
	selector: getProjectType,
});

const getProjectName = (state: State, { projectKey }: SelectorProps): string | undefined => {
	const projectContext = getProjectContext(state, { projectKey });
	return projectContext?.projectName;
};

export const useProjectContextProjectNameStore = createHook(store, {
	selector: getProjectName,
});

const getIssueTypes = (state: State, { projectKey }: SelectorProps): IssueType[] | undefined => {
	const projectContext = getProjectContext(state, { projectKey });
	return projectContext?.issueTypes;
};

export const useProjectContextIssueTypesStore = createHook(store, {
	selector: getIssueTypes,
});
