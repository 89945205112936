import React, { memo } from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import {
	setPerformanceMarkLoadStart,
	setPerformanceMarkLoadEnd,
} from '@atlassian/jira-navigation-apps-sidebar-common';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-common/async';
import type GlobalAppsSettingsSidebarType from './index';

const LazyGlobalAppsSettingsSidebar = lazyAfterPaint<typeof GlobalAppsSettingsSidebarType>(() => {
	setPerformanceMarkLoadStart();
	return import(/* webpackChunkName: "async-sidebar-global-settings-apps" */ './index').then(
		(bundle) => {
			setPerformanceMarkLoadEnd();
			return bundle;
		},
	);
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const GlobalAppsSettingsSidebar = memo<Record<any, any>>(() => (
	<SidebarBoundary id="globalAppsSettings">
		<LazyGlobalAppsSettingsSidebar />
	</SidebarBoundary>
));

GlobalAppsSettingsSidebar.displayName = 'GlobalAppsSettingsSidebar';

export default GlobalAppsSettingsSidebar;
