import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ForgeGlobalPageType from '@atlassian/jira-spa-apps-forge-global';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyForgeGlobalPage = lazyForPaint<typeof ForgeGlobalPageType>(
	() =>
		import(
			/* webpackChunkName: "async-forge-spa-global", jiraSpaEntry: "async-forge-spa-global" */ '@atlassian/jira-spa-apps-forge-global'
		),
);

export const ForgeGlobalPage = () => (
	<LazyPage Page={LazyForgeGlobalPage} pageId="forge-spa-global" shouldShowSpinner />
);

export default ForgeGlobalPage;
