/**
 * @generated SignedSource<<2354623489ecf5edb83f026dcc06a3e6>>
 * @relayHash 31edef4405dd4bf678c0444d22bb9d4e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 04f9cab1c61bb4f68fefeb9818881d5547507041accc16b4bc1343e7d1ae5fc3

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraContainerNavigationQueryInput = {
  projectKeyQuery?: JiraContainerNavigationByProjectKeyQueryInput | null | undefined;
  scopeId?: string | null | undefined;
};
export type JiraContainerNavigationByProjectKeyQueryInput = {
  cloudId: string;
  projectKey: string;
};
export type uiHorizontalSoftwareProjectNavQuery$variables = {
  boardId?: string | null | undefined;
  cloudId: string;
  containerNavigationInput: JiraContainerNavigationQueryInput;
  first: number;
  projectKey: string;
  useContainerNavigation: boolean;
};
export type uiHorizontalSoftwareProjectNavQuery$data = {
  readonly jira: {
    readonly containerNavigation?: {
      readonly canEdit?: boolean | null | undefined;
      readonly navigationItems?: {
        readonly " $fragmentSpreads": FragmentRefs<"ui_navigationKitTabList_TabList_tabsFragment">;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"ui_navigationKitAddTab_AddTab" | "ui_navigationKitTabList_TabList_containerFragment">;
    } | null | undefined;
    readonly jiraProjectByKey: {
      readonly " $fragmentSpreads": FragmentRefs<"projectBoardHeader_horizontalNavHeader" | "ui_navigationKitTabList_TabList_projectFragment">;
    } | null | undefined;
    readonly jiraSoftwareNavigationItems?: {
      readonly " $fragmentSpreads": FragmentRefs<"ui_navigationKitTabList_TabList_tabsFragment">;
    };
  };
};
export type uiHorizontalSoftwareProjectNavQuery = {
  response: uiHorizontalSoftwareProjectNavQuery$data;
  variables: uiHorizontalSoftwareProjectNavQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "boardId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "containerNavigationInput"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKey"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "useContainerNavigation"
},
v6 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v7 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v8 = [
  {
    "kind": "Variable",
    "name": "boardId",
    "variableName": "boardId"
  },
  (v6/*: any*/),
  (v7/*: any*/),
  {
    "kind": "Variable",
    "name": "projectIdOrKey",
    "variableName": "projectKey"
  }
],
v9 = [
  {
    "kind": "FragmentSpread",
    "name": "ui_navigationKitTabList_TabList_tabsFragment"
  }
],
v10 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "containerNavigationInput"
  }
],
v11 = {
  "kind": "ScalarField",
  "name": "canEdit"
},
v12 = [
  (v7/*: any*/)
],
v13 = [
  (v6/*: any*/),
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "projectKey"
  }
],
v14 = [
  {
    "kind": "ScalarField",
    "name": "canPerform"
  }
],
v15 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v16 = {
  "kind": "ScalarField",
  "name": "id"
},
v17 = {
  "kind": "ScalarField",
  "name": "label"
},
v18 = {
  "kind": "ScalarField",
  "name": "iconUrl"
},
v19 = {
  "kind": "ScalarField",
  "name": "url"
},
v20 = {
  "kind": "ScalarField",
  "name": "styleClass"
},
v21 = [
  {
    "concreteType": "JiraNavigationItemEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v15/*: any*/),
          (v16/*: any*/),
          {
            "kind": "ScalarField",
            "name": "isDefault"
          },
          {
            "kind": "ScalarField",
            "name": "typeKey"
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isJiraNavigationItem"
          },
          (v17/*: any*/),
          {
            "kind": "ScalarField",
            "name": "canRemove"
          },
          {
            "kind": "ScalarField",
            "name": "canSetAsDefault"
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "kind": "ScalarField",
                "name": "__id"
              }
            ]
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "concreteType": "JiraAppSection",
                "kind": "LinkedField",
                "name": "sections",
                "plural": true,
                "selections": [
                  (v17/*: any*/),
                  {
                    "concreteType": "JiraAppNavigationItemNestedLink",
                    "kind": "LinkedField",
                    "name": "links",
                    "plural": true,
                    "selections": [
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/)
                    ]
                  }
                ]
              },
              (v19/*: any*/),
              (v18/*: any*/),
              (v20/*: any*/),
              {
                "kind": "ScalarField",
                "name": "appType"
              },
              {
                "kind": "ScalarField",
                "name": "envLabel"
              }
            ],
            "type": "JiraAppNavigationItem"
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "name": "uiHorizontalSoftwareProjectNavQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "condition": "useContainerNavigation",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "args": (v8/*: any*/),
                    "concreteType": "JiraNavigationItemConnection",
                    "kind": "LinkedField",
                    "name": "jiraSoftwareNavigationItems",
                    "plural": false,
                    "selections": (v9/*: any*/)
                  },
                  "action": "THROW",
                  "path": "jira.jiraSoftwareNavigationItems"
                }
              ]
            },
            {
              "condition": "useContainerNavigation",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "args": (v10/*: any*/),
                  "kind": "LinkedField",
                  "name": "containerNavigation",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ui_navigationKitTabList_TabList_containerFragment"
                    },
                    {
                      "kind": "FragmentSpread",
                      "name": "ui_navigationKitAddTab_AddTab"
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v11/*: any*/),
                        {
                          "args": (v12/*: any*/),
                          "concreteType": "JiraNavigationItemConnection",
                          "kind": "LinkedField",
                          "name": "navigationItems",
                          "plural": false,
                          "selections": (v9/*: any*/)
                        }
                      ],
                      "type": "JiraContainerNavigation"
                    }
                  ]
                }
              ]
            },
            {
              "args": (v13/*: any*/),
              "concreteType": "JiraProject",
              "kind": "LinkedField",
              "name": "jiraProjectByKey",
              "plural": false,
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "projectBoardHeader_horizontalNavHeader"
                },
                {
                  "kind": "FragmentSpread",
                  "name": "ui_navigationKitTabList_TabList_projectFragment"
                }
              ]
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "uiHorizontalSoftwareProjectNavQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v13/*: any*/),
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "jiraProjectByKey",
            "plural": false,
            "selections": [
              {
                "alias": "canEditProjectConfig",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "EDIT_PROJECT_CONFIG"
                  }
                ],
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": (v14/*: any*/),
                "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
              },
              {
                "alias": "canViewProjectConfig",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "VIEW_PROJECT_CONFIG"
                  }
                ],
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": (v14/*: any*/),
                "storageKey": "action(type:\"VIEW_PROJECT_CONFIG\")"
              },
              {
                "kind": "ScalarField",
                "name": "softwareBoardCount"
              },
              {
                "alias": "projectKey",
                "kind": "ScalarField",
                "name": "key"
              },
              {
                "kind": "LinkedField",
                "name": "navigationMetadata",
                "plural": false,
                "selections": [
                  (v15/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "boardId"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "isSimpleBoard"
                      },
                      (v16/*: any*/)
                    ],
                    "type": "JiraSoftwareProjectNavigationMetadata"
                  }
                ]
              },
              (v16/*: any*/)
            ]
          },
          {
            "condition": "useContainerNavigation",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "args": (v8/*: any*/),
                "concreteType": "JiraNavigationItemConnection",
                "kind": "LinkedField",
                "name": "jiraSoftwareNavigationItems",
                "plural": false,
                "selections": (v21/*: any*/)
              }
            ]
          },
          {
            "condition": "useContainerNavigation",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "args": (v10/*: any*/),
                "kind": "LinkedField",
                "name": "containerNavigation",
                "plural": false,
                "selections": [
                  (v15/*: any*/),
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isJiraContainerNavigationResult"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v16/*: any*/),
                      (v11/*: any*/),
                      {
                        "args": (v12/*: any*/),
                        "concreteType": "JiraNavigationItemConnection",
                        "kind": "LinkedField",
                        "name": "navigationItems",
                        "plural": false,
                        "selections": (v21/*: any*/)
                      }
                    ],
                    "type": "JiraContainerNavigation"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v16/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "04f9cab1c61bb4f68fefeb9818881d5547507041accc16b4bc1343e7d1ae5fc3",
    "metadata": {},
    "name": "uiHorizontalSoftwareProjectNavQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "4597f89921f22540a2c0ea9d680db5fc";

export default node;
