import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ProjectSettingsServiceDeskFeedbackSettingsType from '@atlassian/jira-spa-apps-project-settings-service-desk-feedback-settings';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const NEXTGEN_SETTINGS = 'project/service-desk/settings/index';
export const CLASSIC_SETTINGS = 'project/service-desk/settings';

export const LazyProjectSettingsServiceDeskFeedbackSettings = lazyForPaint<
	typeof ProjectSettingsServiceDeskFeedbackSettingsType
>(
	() =>
		import(
			/* webpackChunkName: "async-project-settings-service-desk-feedback-settings", jiraSpaEntry: "async-project-settings-service-desk-feedback-settings" */ '@atlassian/jira-spa-apps-project-settings-service-desk-feedback-settings'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyProjectSettingsServiceDeskFeedbackSettings}
		pageId="project-settings-feedback-settings"
		shouldShowSpinner
	/>
);
