import { ff } from '@atlassian/jira-feature-flagging-using-meta';
import { appendOperationQuery } from '../../common/utils/append-operation-query';
import { getIsOldValueRequested } from '../../common/utils/get-feature-flag-value';
import {
	GIRA_AGG_MIGRATION_ISARCHIVED_FLAG,
	isIssueExportControlEnabled,
} from '../../feature-flags';
import type { IssueViewFetchOperations } from '../../types';
import {
	getContentPanelsQuery,
	getEcosystemQuery,
	getLegacyRightWebPanelsQuery,
	getAttachmentsQuery,
	getCommentsQuery,
	getJiraSettingsQuery,
	getMediaContextQuery,
	getPermissionsQuery,
	getIsArchivedQuery,
	getRemoteLinksQuery,
	getProject,
	getWorklogsQuery,
	NUM_INITIAL_COMMENTS_TO_LOAD,
	NUM_INITIAL_WORKLOGS_TO_LOAD,
	getContainersByTypeQuery,
} from './gira-fragments';

const CREATED_ORDER_DESCENDING = '-created';

export const ISSUE_VIEW_INTERACTIVE_QUERY = 'issueViewInteractiveQuery';

export const getGiraUrl = (
	_baseUrl: string,
	operation?: IssueViewFetchOperations | string,
): string => `/rest/gira/1/${appendOperationQuery(operation)}`;

export { getContentPanelsQuery, getEcosystemQuery } from './gira-fragments';

const viewIssueQuery = () => `
    viewIssue(issueKey: $issueKey) {
        ${getIsOldValueRequested(GIRA_AGG_MIGRATION_ISARCHIVED_FLAG) ? getIsArchivedQuery() : ''}
        canCreateSubTask
        ${isIssueExportControlEnabled() ? 'canExportIssue' : ''}
        ${
					/**
					 * Note: When activity sorting feature is enabled, we do not
					 * want to fetch with sort order passed through; we should defer
					 * to the saved sort order in the backend to return the correct
					 * sort order
					 */
					getCommentsQuery({
						maxResults: NUM_INITIAL_COMMENTS_TO_LOAD,
						startAt: 0,
						orderBy: CREATED_ORDER_DESCENDING,
						commentAmount: Math.floor(NUM_INITIAL_COMMENTS_TO_LOAD),
						hydrateData: false,
					})
				}
        ${
					/**
					 * Note: When activity sorting feature is enabled, we do not
					 * want to fetch with sort order passed through; we should defer
					 * to the saved sort order in the backend to return the correct
					 * sort order
					 */
					getWorklogsQuery({
						startAt: 0,
						worklogAmount: Math.floor(NUM_INITIAL_WORKLOGS_TO_LOAD),
					})
				}
        ${getEcosystemQuery()}
        ${getContentPanelsQuery()}
        ${getLegacyRightWebPanelsQuery()}
        ${ff('issue.details.gira-agg-migration-attachments_ecebe') ? 'totalAttachmentsCount' : getAttachmentsQuery()}
        ${getContainersByTypeQuery()}
        ${getRemoteLinksQuery()}
    }
`;

export const getJiraQueryEcosystemFirstSeenOnIssueView = () => `
    jira {
        ecosystemFirstSeenOnIssueView
    }
`;

export const getJiraQuery = () => `
    jira {
        ecosystemFirstSeenOnIssueView
        hasAdministerPermission: hasGlobalPermission(key: ADMINISTER)
        hasUserPickerPermission: hasGlobalPermission(key: USER_PICKER)
    }
`;

export const buildIssueQuery = () => `
query ${ISSUE_VIEW_INTERACTIVE_QUERY}($issueKey: String!, $projectKey: String)  {
        ${getJiraQuery()}
        ${getMediaContextQuery()}
        ${viewIssueQuery()}
        ${getJiraSettingsQuery()}
        ${getPermissionsQuery()}
        ${getProject()}
    }

`;

export const getGiraRequestOptions = (issueKey: string, projectKey: string) => {
	const payload = {
		operationName: ISSUE_VIEW_INTERACTIVE_QUERY,
		query: buildIssueQuery(),
		variables: {
			issueKey,
			projectKey,
		},
	};

	return {
		method: 'POST',
		body: JSON.stringify(payload),
	};
};
