import type { UserPreferenceType } from '@atlassian/jira-dashboard-user-preference';
import type { GadgetDefinition } from '@atlassian/jira-react-gadgets-common';
import type { CustomReportGadgetData } from './common/types';
import { LazyCustomReportGadgetEdit, LazyCustomReportGadgetView, preload } from './ui';
import getInitialState from './utils/get-initial-state';

const CUSTOM_REPORT_DEFINITION: GadgetDefinition<
	CustomReportGadgetData,
	CustomReportGadgetData,
	UserPreferenceType[]
> = {
	componentView: LazyCustomReportGadgetView,
	componentEdit: LazyCustomReportGadgetEdit,
	getInitialState,
	preload,
};

export default CUSTOM_REPORT_DEFINITION;
