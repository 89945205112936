import { createResource } from '@atlassian/jira-router';
import { RESOURCE_TYPE_RAPIDBOARDS_RESOURCE } from '../../constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const rapidboardResource = createResource<any>({
	type: RESOURCE_TYPE_RAPIDBOARDS_RESOURCE,
	getKey: ({ match }) => match.query.rapidView || '',
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-rapidboards-rapidboard-resource-data", jiraSpaEntry: "async-resource-rapidboards-rapidboard-resource-data" */ './get-data'
		),
	maxAge: Number.MAX_VALUE,
	isBrowserOnly: true,
});
