import React, { memo } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-nav4-common/src/ui/sidebar-boundary';
import { SidebarContainer } from '@atlassian/jira-navigation-apps-sidebar-nav4-common/src/ui/sidebar-container';
import Placeholder from '@atlassian/jira-placeholder';
import { Skeleton } from './skeleton';

const LazySidebarContent = lazyForPaint(
	() =>
		import(
			/* webpackChunkName: "async-jira-navigation-apps-sidebar-nav4-sidebar-help-center-settings" */ './main'
		),
);

const AsyncSidebar = () => (
	<SidebarBoundary
		id="nav4.sidebar.help-center-settings"
		packageName="jiraNavigationAppsSidebarNav4HelpCenterSettings"
	>
		<SidebarContainer>
			<Placeholder name="nav4.sidebar.help-center-settings" fallback={<Skeleton />}>
				<LazySidebarContent />
			</Placeholder>
		</SidebarContainer>
	</SidebarBoundary>
);

export default memo(AsyncSidebar);
