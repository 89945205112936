import { createRelayResource } from '@atlassian/jira-relay-utils';
import GlobalPermissionsAndGrantsRequest, {
	type main_globalPermissionsAndGrantsQuery,
} from '@atlassian/jira-relay/src/__generated__/main_globalPermissionsAndGrantsQuery.graphql';
import type { RouterContext, RouterDataContext } from '@atlassian/jira-router';
import type { TenantContext } from '@atlassian/jira-shared-types';

const RESOURCE_TYPE_GLOBAL_PERMISSIONS = 'GLOBAL_PERMISSIONS';

export const globalPermissionsResource = createRelayResource<main_globalPermissionsAndGrantsQuery>({
	type: RESOURCE_TYPE_GLOBAL_PERMISSIONS,
	getQuery: (
		_: RouterContext | RouterDataContext,
		{ tenantContext }: { tenantContext: TenantContext },
	) => ({
		parameters: GlobalPermissionsAndGrantsRequest,
		variables: {
			cloudId: tenantContext.cloudId,
		},
		options: { fetchPolicy: 'network-only' },
	}),
});
