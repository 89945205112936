import forEach from 'lodash/forEach';
import { getEmptyExtensionsObject } from '@atlassian/jira-forge-ui-constants';
import type {
	Extension,
	ExtensionContextsArrayMap,
	ExtensionContextsMap,
} from '@atlassian/jira-forge-ui-types';

export const filterOutDuplicatedExtensions = (
	modules: ExtensionContextsArrayMap,
): ExtensionContextsArrayMap => {
	const filteredModules = getEmptyExtensionsObject();
	forEach(modules, (module: Array<Extension>, propName: string) => {
		const moduleExtensions = new Map();
		forEach(module, (moduleItem) => {
			const moduleExtension = moduleExtensions.get(moduleItem.id);

			const appVersion = moduleItem?.appVersion || '0.0.0';
			const moduleAppVersion = moduleExtension?.appVersion || '0.0.0';

			if (!moduleExtension || (moduleExtension && appVersion >= moduleAppVersion)) {
				moduleExtensions.set(moduleItem.id, moduleItem);
			}
		});

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		filteredModules[propName as keyof ExtensionContextsMap] = [...moduleExtensions.values()];
	});
	return filteredModules;
};
