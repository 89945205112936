/**
 * @generated SignedSource<<9503b5648f7c4dcff10d23a414866ae7>>
 * @relayHash 22346a72666d0b2cf3aa9045576e503d
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 800b7c81d8cdd57eeb185896a2dcadecd7f4647988840bdd0ddfbabcae088ce8

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { uiVersionDetailsQuery } from './uiVersionDetailsQuery.graphql';

const node: PreloadableConcreteRequest<uiVersionDetailsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "800b7c81d8cdd57eeb185896a2dcadecd7f4647988840bdd0ddfbabcae088ce8",
    "metadata": {},
    "name": "uiVersionDetailsQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
