import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const softwareRequestsRoute = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_REQUESTS,
	path: '/jira/software/projects/:projectKey/requests',
};

export const softwareRequestsClassicRoute = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_REQUESTS_CLASSIC,
	path: '/jira/software/c/projects/:projectKey/requests',
};
