import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const createProjectRoute = {
	group: ROUTE_GROUPS.COMPONENTS,
	name: ROUTE_NAMES.CREATE_PROJECT,
	path: '/jira/create-project',
	exact: true,
};
