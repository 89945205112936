import { fetch } from '@atlassian/eoc-fetch';

import type { GetTeamResponse } from './types';

export const getTeam = (teamId: string) =>
	fetch<GetTeamResponse>(`/v1/alerts/responders/names`, {
		method: 'POST',
		body: {
			responders: [{ id: teamId, type: 'team' }],
		},
	}).then((response) => response.values[0]);
