import React, { useCallback, useState, useEffect, type ReactNode } from 'react';
import { ExperienceTrackerContext, ExperienceTracker } from '@atlassian/experience-tracker';
import { sliSubscriber } from '../../services/sli-subscriber';

type Props = {
	children: ReactNode;
	orgId?: string;
	workspaceId?: string;
};

const useUnmount = (callback: () => void) => useEffect(() => callback, [callback]);

export const ExperienceTrackerProvider = ({ children }: Props) => {
	const [experienceTracker] = useState(new ExperienceTracker());
	const [cleanupSliSub] = useState(() => experienceTracker.subscribe(sliSubscriber));

	const cleanup = useCallback(() => {
		cleanupSliSub();
	}, [cleanupSliSub]);

	useUnmount(cleanup);

	return (
		<ExperienceTrackerContext.Provider value={experienceTracker}>
			{children}
		</ExperienceTrackerContext.Provider>
	);
};
