import { type Theme, ThemeOptions } from '@atlassian/jira-common-constants/src/theme-preference';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { userPreferenceAPI } from '@atlassian/jira-user-preferences-services';
import { THEME_PREFERENCE_KEY } from '../common/constants';

export const setThemePreference = (theme: Theme): Promise<void> => {
	const promise = userPreferenceAPI.set(THEME_PREFERENCE_KEY, theme);
	promise.catch((error) => {
		fireErrorAnalytics({
			meta: {
				id: 'ThemePreferenceCRUD',
				packageName: 'jiraRouterResourcesThemePreference',
			},
			error,
			attributes: {
				op: 'put',
				errName: String(error.name),
				statusCode: error.statusCode,
				param: `to:${theme}`,
			},
		});
	});
	return promise;
};
/**
 * Asynchronously retrieves the user's preferred theme from a preference API and ensures its validity
 * against a set of known themes. Returns the valid theme or undefined for unrecognized themes.
 */
export const getThemePreference = async (): Promise<Theme | undefined> => {
	const promise = userPreferenceAPI.get(THEME_PREFERENCE_KEY);
	promise.catch((error) => {
		fireErrorAnalytics({
			meta: {
				id: 'ThemePreferenceCRUD',
				packageName: 'jiraRouterResourcesThemePreference',
			},
			error,
			attributes: {
				op: 'get',
				errName: String(error.name),
				statusCode: error.statusCode,
			},
		});
	});
	promise.then((theme) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		if (theme && !(ThemeOptions as ReadonlyArray<string>).includes(theme)) {
			// very unlikely but…
			fireErrorAnalytics({
				meta: {
					id: 'ThemePreferenceCRUD',
					packageName: 'jiraRouterResourcesThemePreference',
				},
				error: new Error('Unrecognized theme!'),
				attributes: {
					op: 'get',
					theme,
					errName: 'unrecognized value!',
				},
			});
		}
	});

	const theme = await promise;
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return theme && (ThemeOptions as ReadonlyArray<string>).includes(theme)
		? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(theme as Theme)
		: undefined;
};
