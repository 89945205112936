import { setUserProperties } from '@atlassian/jira-common-rest/src/api/latest/user-properties';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { NAVIGATION_UI_STORAGE_KEY } from '@atlassian/jira-router-resources-navigation-ui-state';
import type { LeftSidebarState, RightSidebarState } from '../../../../../common/types';

export type RemoteLayoutState = {
	leftSidebar: Pick<LeftSidebarState, 'isCollapsed' | 'width'>;
	rightSidebar: RightSidebarState;
};

export async function setRemoteLayoutState({
	accountId,
	state,
}: {
	accountId: string;
	state: RemoteLayoutState;
}) {
	try {
		await setUserProperties(accountId, NAVIGATION_UI_STORAGE_KEY, JSON.stringify(state));
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		fireErrorAnalytics({
			error: e,
			meta: {
				id: 'updateServerLayoutState',
				packageName: 'jiraLayoutController',
				teamName: 'jfp-team',
			},
		});
	}
}
