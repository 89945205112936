import { ROUTE_GROUPS } from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const SERVICE_DESK_PRODUCT = 'servicedesk';
export const routeGroupsProjectSettingsServicedeskUnassignedTicketTypesConfigRoute = {
	exact: true,
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK}-unassigned-ticket-types-config`,
	path: `/jira/${SERVICE_DESK_PRODUCT}/projects/:projectKey/settings/request-types/unassigned`,
};

export const routeGroupsProjectSettingsServicedeskItsmTicketTypesConfigRoute = {
	exact: true,
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK}-itsm-ticket-types-config`,
	path: `/jira/${SERVICE_DESK_PRODUCT}/projects/:projectKey/settings/request-types/category/:practice`,
};

export const routeGroupsProjectSettingsServicedeskTicketTypesConfigRoute = {
	exact: true,
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK}-ticket-types-config`,
	path: `/jira/${SERVICE_DESK_PRODUCT}/projects/:projectKey/settings/request-types`,
};
