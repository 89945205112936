// THIS FILE WAS AUTO-GENERATED. MODIFY WITH CAUTION. See README.md
// brandfolder asset id = tgxxcm73mk6phg9ttfj3rvpf
// brandfolder url = https://brandfolder.com/atlassian-brand-creative-library/atlassian-public-asset-library/#!asset/tgxxcm73mk6phg9ttfj3rvpf
import { createIllustration, type SVGViewBox } from '@atlassian/jira-illustration';
import srcDark from '../../assets/generic-empty-state-darkmode.svg'; // eslint-disable-line jira/import/no-parent-imports
import srcLight from '../../assets/generic-empty-state.svg'; // eslint-disable-line jira/import/no-parent-imports

const Illustration = createIllustration({
	srcLight,
	srcDark,
});

export default Illustration;

// for advanced use only
const viewBox: SVGViewBox = [0, 0, 235, 210];
export { srcLight, srcDark, viewBox };
