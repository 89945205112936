import { ff } from '@atlassian/jira-feature-flagging-using-meta';

const getForgeQuery = () =>
	`forge {
        panels {
          id
          instances {
            id
            collapsed
            added
          }
        }
      }`;

export const getEcosystemQuery = () =>
	`
    ecosystem {
      ${
				!ff('issue-view-remove-connect-operations-from-critical-fetch_vtk4w')
					? `
      operations {
        name
        url: href
        icon {
          url
        }
        tooltip
        styleClass
      }`
					: ''
			}
      activityPanels {
        name
        iframe {
          addonKey
          moduleKey
          options
        }
      }    
      ${
				!ff('issue-view-remove-backgroundscripts-from-critical-fetch_6f6on')
					? `
      backgroundScripts {
        iframe {
          addonKey
          moduleKey
          options
        }
        shouldReloadOnRefresh
      }`
					: ''
			}
      contextPanels {
        name
        iframe {
          addonKey
          moduleKey
          options
        }
        icon {
          url
        }
        content
        status
        type
      }
      ${getForgeQuery()}
    }
`;

export const getLegacyRightWebPanelsQuery = () => `
        legacyRightWebPanels {
          edges {
            node {
              name
              status
              addonKey
              moduleKey
              options
              content
            }
          }
        }
      `;
