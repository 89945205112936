import { di } from 'react-magnetic-di';
import { FetchError } from '@atlassian/jira-fetch';
import type { PagedActivityFeedType } from './types';
import { transformData } from './utils';

const PAGE_SIZE = 10;
const CONTENT_TYPE = 'application/atom+xml; charset=UTF-8';
const STREAM_PROVIDERS = 'thirdparty issues';

export const fetchActivityFeed = (
	after: string | null,
	projectKey: string,
): Promise<PagedActivityFeedType> => {
	di(fetch);

	const firstIndex = after != null ? Number(after) : 0;
	const maxResults = firstIndex + PAGE_SIZE;

	return fetch(
		// @ts-expect-error - TS2345: Types of property 'maxResults' are incompatible. Type 'number' is not assignable to type 'string'.
		`/plugins/servlet/streams?${new URLSearchParams({
			maxResults,
			relativeLinks: true,
			streams: `key IS ${projectKey}`,
			providers: STREAM_PROVIDERS,
		}).toString()}`,
		{
			headers: { 'content-type': CONTENT_TYPE },
		},
	)
		.then((response) => {
			if (!response.ok) {
				throw new FetchError(response.status, `Error server response: ${response.status}`);
			}

			return response.text();
		})
		.then((rawResponse) => {
			const parser = new DOMParser();
			const xmlDoc = parser.parseFromString(rawResponse, 'application/xml');
			const entries = transformData(xmlDoc);

			return {
				data: entries,
				pageInfo: {
					hasNextPage: entries.length >= maxResults,
					endCursor: String(entries.length),
				},
			};
		});
};
