import type { CustomTeamRolesResponse } from '@atlassian/eoc-custom-team-roles';
import { fetch, getMeta } from '@atlassian/eoc-fetch';

import {
	type GetTeamResponse,
	type InvitationStatusInfoResponse,
	type InvitationStatusRequest,
	type MemberRoleRequest,
	type MemberRoleResponse,
	type MemberRolesPageAccessResponse,
	type MembersQueryResponseV2,
} from '../../common/types';

const membersQueryV2 = `query memberRolesTeamMembersQuery($teamId: ID!, $siteId: String!) {
  team {
    teamV2 (id: $teamId, siteId: $siteId) @optIn(to: "Team-v2") {
      id
      members {
        edges {
          cursor
          node {
            member {
              ... on AtlassianAccountUser {
                email
              }
              name
              picture
              accountId
              accountStatus
            }
            state
            role
          }
        }
      }
    }
  }
}
`;

const operationName = 'memberRolesTeamMembersQuery';

export const getMembers = (teamId: string) => {
	const queryUrl = '/gateway/api/graphql';
	const teamAri = `ari:cloud:identity::team/${teamId}`;
	const siteId = getMeta('ajs-cloud-id');

	return fetch<MembersQueryResponseV2>(queryUrl, {
		method: 'POST',
		body: JSON.stringify({
			operationName,
			query: membersQueryV2,
			variables: {
				teamId: teamAri,
				siteId,
			},
		}),
		headers: {
			'Content-Type': 'application/json',
			'X-ExperimentalApi': 'teams-beta, team-members-beta',
		},
		exactUrl: true,
	});
};

export const getMemberRolesPageAccess = (teamId: string) =>
	fetch<MemberRolesPageAccessResponse>(
		`/v1/teams/members/member-roles-page-access?platformTeamId=${teamId}`,
	);

export const getRoleAndJSMLicenseInfoEndpoint = ({
	platformTeamId,
	accountIds,
}: MemberRoleRequest) =>
	fetch<MemberRoleResponse>(`/v1/teams/members`, {
		method: 'POST',
		body: {
			platformTeamId,
			accountIds,
		},
	});

export const getJSMInvitationStatusInfoEndpoint = ({ accountIds }: InvitationStatusRequest) =>
	fetch<InvitationStatusInfoResponse>(
		`/v1/users/access-request-capabilities?accountIds=${accountIds}`,
		{
			method: 'GET',
		},
	);

export const getTeam = (teamId: string) =>
	fetch<GetTeamResponse>(`/v1/alerts/responders/names`, {
		method: 'POST',
		body: {
			responders: [{ id: teamId, type: 'team' }],
		},
	});

export const getCustomTeamRolesEndpoint = (platformTeamId: string) =>
	fetch<CustomTeamRolesResponse>(`/v1/teams/${platformTeamId}/roles`);
