import type { ComponentType } from 'react';
import { lazyAfterPaint, type LazyComponent } from 'react-loosely-lazy';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AsyncUpflowIframeComponent: LazyComponent<ComponentType<Record<any, any>>> =
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazyAfterPaint(
		() =>
			import(/* webpackChunkName: "async-upflow-iframe-plugin" */ './main').then(
				(exportedModule) => exportedModule.UpflowIframeComponent,
			),
		{
			ssr: false,
		},
	);
