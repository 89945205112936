/**
 * @generated SignedSource<<e82aba78c6929e25b389993a4c941f3e>>
 * @relayHash e5a98d7c9e670766e2c1dfb83c7dfe7f
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 49eb32427a4d64a840135e3c409572c381b59a23a9e577ffe3f217a88a68a648

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { uiCustomerDetailsPageQuery } from './uiCustomerDetailsPageQuery.graphql';

const node: PreloadableConcreteRequest<uiCustomerDetailsPageQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "49eb32427a4d64a840135e3c409572c381b59a23a9e577ffe3f217a88a68a648",
    "metadata": {},
    "name": "uiCustomerDetailsPageQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
