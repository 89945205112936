import noop from 'lodash/noop';
import log from '@atlassian/jira-common-util-logging/src/log';
import { NAVIGATION_UI_STORAGE_KEY, LOGGER_LOCATION } from './constants';
import type { UIControllerShape } from './types';

export const safeGetLocalStorage = () => {
	if (typeof window !== 'undefined' && typeof window.localStorage !== 'undefined') {
		return window.localStorage;
	}

	return {
		setItem: noop,
		getItem: noop,
	};
};

const queryLocalStorageSync = (): UIControllerShape | undefined => {
	const localStorage = safeGetLocalStorage();
	const item = localStorage.getItem(NAVIGATION_UI_STORAGE_KEY);

	if (!__SERVER__ && item) {
		try {
			return JSON.parse(item);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			log.safeErrorWithoutCustomerData(LOGGER_LOCATION, 'Fail to parse sync local storage data', e);
		}
	}
};

export const queryLocalStorage = (): Promise<UIControllerShape | undefined> =>
	new Promise((resolve) => {
		const item = queryLocalStorageSync();
		resolve(item);
	});

export const updateLocalStorageData = (data: UIControllerShape) => {
	const localStorage = safeGetLocalStorage();
	localStorage.setItem(NAVIGATION_UI_STORAGE_KEY, JSON.stringify(data));
};
