import {
	getRouteName,
	getUserLocation,
	getPreviousSubProduct,
} from '@atlassian/jira-platform-router-utils';
import { getIsProjectAdmin } from './common/utils';

// this special object passed to analytics allows us to get fresh route
// without requiring a full re-render on route change
export const routeAnalyticsAttributes = Object.defineProperties(
	{},
	{
		routeName: { enumerable: true, get: getRouteName },
		userLocation: { enumerable: true, get: getUserLocation },
		previousSubProduct: { enumerable: true, get: getPreviousSubProduct },
		isProjectAdmin: { enumerable: true, get: getIsProjectAdmin },
	},
);
