import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';
import type { Route } from '@atlassian/jira-router';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const eocAlertIssueSyncingRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_ALERT_ISSUE_SYNCING,
	path: '/jira/settings/products/ops/syncs',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const eocAlertIssueSyncingCreateRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_ALERT_ISSUE_SYNCING_CREATE,
	path: '/jira/settings/products/ops/syncs/create',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const eocAlertIssueSyncingDetailRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_ALERT_ISSUE_SYNCING_DETAIL,
	path: '/jira/settings/products/ops/syncs/:syncId',
} as const as Route;

export const eocTeamAlertIssueSyncingRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_TEAM_ALERT_ISSUE_SYNCING,
	path: '/jira/ops/teams/:teamId/syncs',
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const eocTeamAlertIssueSyncingCreateRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_TEAM_ALERT_ISSUE_SYNCING_CREATE,
	path: '/jira/ops/teams/:teamId/syncs/create',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const eocTeamAlertIssueSyncingDetailRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_TEAM_ALERT_ISSUE_SYNCING_DETAIL,
	path: '/jira/ops/teams/:teamId/syncs/:syncId',
} as const as Route;
