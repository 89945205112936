import React, { useEffect } from 'react';
import { createProjectCtaEntryPoint } from '@atlassian/jira-business-create-project-cta/src/entrypoint';
import { useEntryPoint } from '@atlassian/jira-entry-point';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container';

const runtimeProps = {};
const entryPointParams = {};
export const CreateProjectCta = () => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		createProjectCtaEntryPoint,
		entryPointParams,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	return (
		<JiraEntryPointContainer
			id="jira-business-create-project-cta-entrypoint"
			packageName="@atlassian/jira-business-create-project-cta"
			teamName="jira-werewolves"
			entryPointReferenceSubject={entryPointReferenceSubject}
			runtimeProps={runtimeProps}
			errorFallback="unmount"
		/>
	);
};
