/**
 * @generated SignedSource<<ff95926e7022113cd49dc2068bf42b5b>>
 * @relayHash 1ca562bb5d7e883eaaf1264d05b37876
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 563fe9cb1073575e9dc8babe0ef447ecbabd04641e6a9f889a26808046a1496d

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcVirtualAgentLandingQuery } from './srcVirtualAgentLandingQuery.graphql';

const node: PreloadableConcreteRequest<srcVirtualAgentLandingQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "563fe9cb1073575e9dc8babe0ef447ecbabd04641e6a9f889a26808046a1496d",
    "metadata": {},
    "name": "srcVirtualAgentLandingQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
