import { componentWithFF } from '@atlassian/jira-feature-flagging-utils';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarGlobalSettingsIssues from '@atlassian/jira-navigation-apps-sidebar-global-settings-issues/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import {
	globalSettingsIssuesPrioritiesRoute,
	globalSettingsIssuesPrioritySchemesRoute,
} from '@atlassian/jira-router-routes-admin-pages-priorities-routes';
import { ErrorPagesNotFound } from '@atlassian/jira-router-routes-common';
import PrioritiesPage, {
	LazyPrioritiesPage,
} from './ui/spa/admin-pages/priorities/issue-priorities';
import PrioritySchemes, {
	LazyPrioritySchemes,
} from './ui/spa/admin-pages/priorities/priority-schemes';

export const prioritySchemesRouteEntries: Route[] = [
	createEntry(globalSettingsIssuesPrioritiesRoute, {
		component: componentWithFG(
			'priorities_page_frontend_statsig',
			PrioritiesPage,
			ErrorPagesNotFound,
		),

		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsIssues,
		},

		resources: [...getNavigationResources(), navigationSidebarGlobalResource],
		forPaint: [LazyPrioritiesPage],
	}),
	createEntry(globalSettingsIssuesPrioritySchemesRoute, {
		component: componentWithFF('priorities.per.project', PrioritySchemes, ErrorPagesNotFound),

		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsIssues,
		},

		resources: [...getNavigationResources(), navigationSidebarGlobalResource],
		forPaint: [LazyPrioritySchemes],
	}),
];
