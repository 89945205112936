import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-common/async';
import type GlobalAlertsSidebarType from './index';

export const LazyGlobalAlertsSidebar = lazyForPaint<typeof GlobalAlertsSidebarType>(() =>
	import(/* webpackChunkName: "async-sidebar-global-alerts" */ './index').then((bundle) => bundle),
);

export const AsyncSidebarEocGlobalAlerts = () => (
	<SidebarBoundary id="eocGlobalAlerts">
		<LazyGlobalAlertsSidebar />
	</SidebarBoundary>
);
