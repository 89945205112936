import { GraphQLErrors } from '@atlassian/jira-business-graphql-errors';
import { log } from '@atlassian/jira-common-util-logging';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { performPostRequest } from '@atlassian/jira-fetch';
import { QUERY } from './gql';
import type { Response } from './types';
import { transformData } from './utils';

export const fetchConfiguration = async (cloudId: string) => {
	try {
		const response = await performPostRequest<Response>(
			'/rest/gira/1/?operation=JiraBusinessConfiguration',
			{
				body: JSON.stringify({
					query: QUERY,
					variables: {
						cloudId,
					},
				}),
			},
		);

		if (response.data == null) {
			throw new GraphQLErrors(response.errors);
		}

		return transformData(response);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		log.safeErrorWithoutCustomerData(
			'jiraRouterResourcesBusinessConfiguration',
			'Something went wrong while fetching business configuration',
		);

		const isClientError = error?.statusCode >= 400 && error?.statusCode < 500;
		if (!isClientError) {
			fireErrorAnalytics({
				meta: {
					id: 'configurationResource',
					packageName: 'jiraRouterResourcesBusinessConfiguration',
					teamName: 'wanjel',
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});
		}
	}

	return null;
};
