import { getMultivariateFeatureFlag } from '@atlassian/jira-feature-flagging';
import {
	type PlanSidebarNavInfo,
	staticPlanSidebarNavigationResource,
} from '@atlassian/jira-navigation-apps-resources';
import { useResource } from '@atlassian/jira-router';
import {
	planSidebarNavigationOptInStatusResources,
	type PlanSidebarNavigationOptInStatusResourceData,
} from '@atlassian/jira-router-resources-advanced-roadmaps-nav';

const OFF = 'Off';
const EAP = 'EAP';
const GA = 'GA';

const getPlanSidebarNavigationJiraLabsVariation = () =>
	getMultivariateFeatureFlag('com.atlassian.rm.jpo.sidebar-navigation-jira-labs-variations', OFF, [
		OFF,
		EAP,
		GA,
	]);

const isPlanSidebarNavigationEAPEnabled = () => {
	const variation = getPlanSidebarNavigationJiraLabsVariation();
	return variation === EAP;
};

const isPlanSidebarNavigationGAEnabled = () => {
	const variation = getPlanSidebarNavigationJiraLabsVariation();
	return variation === GA;
};

/** Returns the data needed for the plan sidebar navigation. */
export const usePlan = () =>
	useResource<PlanSidebarNavInfo | null>(staticPlanSidebarNavigationResource);

export const useSidebarVisibility = () => {
	const { data } = useResource<PlanSidebarNavigationOptInStatusResourceData | null>(
		planSidebarNavigationOptInStatusResources,
	);

	return {
		isSidebarVisible:
			((isPlanSidebarNavigationEAPEnabled() && data?.planSidebarNavigationOptInStatus?.isEnabled) ||
				isPlanSidebarNavigationGAEnabled()) ??
			false,
	};
};
