import { lazyForPaint } from 'react-loosely-lazy';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LazyIntegrationAutomation = lazyForPaint<any>(() =>
	import(
		/* webpackChunkName: "async-jira-eoc-integration-automation", jiraSpaEntry: "async-jira-eoc-integration-automation" */ '@atlassian/jira-eoc-integration-automation'
	).then((module) => module.IntegrationAutomation),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LazyIntegrationDetail = lazyForPaint<any>(() =>
	import(
		/* webpackChunkName: "async-jira-eoc-integration-automation-integration-detail", jiraSpaEntry: "async-jira-eoc-integration-automation-integration-detail" */ '@atlassian/jira-eoc-integration-automation'
	).then((module) => module.IntegrationDetail),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LazyIntegrationCreate = lazyForPaint<any>(() =>
	import(
		/* webpackChunkName: "async-jira-eoc-integration-automation-integration-create", jiraSpaEntry: "async-jira-eoc-integration-automation-integration-create" */ '@atlassian/jira-eoc-integration-automation'
	).then((module) => module.IntegrationCreate),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LazyIntegrationList = lazyForPaint<any>(() =>
	import(
		/* webpackChunkName: "async-jira-eoc-integration-automation-integration-list", jiraSpaEntry: "async-jira-eoc-integration-automation-integration-list" */ '@atlassian/jira-eoc-integration-automation'
	).then((module) => module.IntegrationList),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LazyIntegrationLog = lazyForPaint<any>(() =>
	import(
		/* webpackChunkName: "async-jira-eoc-integration-automation-integration-log", jiraSpaEntry: "async-jira-eoc-integration-automation-integration-log" */ '@atlassian/jira-eoc-integration-automation'
	).then((module) => module.IntegrationLog),
);
