import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const servicedeskCalendarEapRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_CALENDAR_EAP,
	path: '/jira/servicedesk/projects/:projectKey/calendar',
	exact: true,
};

export const servicedeskCalendarRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_CALENDAR,
	path: '/jira/servicedesk/projects/:projectKey/boards/:boardId/calendar',
	exact: true,
};
