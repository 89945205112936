import { useJiraWorkManagementProjectPagesLink as useJiraWorkManagementProjectPagesLinkDI } from '@atlassian/jira-navigation-apps-sidebar-common';
import { withThrowOnError } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/throw-when-no-data-and-error';
import {
	useProjectConnectItems as useProjectConnectItemsDI,
	useProjectReportsConnectItems as useProjectReportsConnectItemsDI,
	useProjectSettingsConnectItems as useProjectSettingsConnectItemsDI,
} from '@atlassian/jira-navigation-apps-sidebar-connect';
import { useProjectSettingsForgeAppsForMenu as useProjectSettingsForgeAppsForMenuDI } from '@atlassian/jira-navigation-apps-sidebar-forge/src/controllers/project-settings';
import { useFeatures as useFeaturesDI } from './features';

export { default as useProject } from './project';
export { default as useAutoExpandSidebar } from './auto-expand-sidebar';

export const useFeatures = withThrowOnError(useFeaturesDI);
export const useProjectConnectItems = withThrowOnError(useProjectConnectItemsDI);
export const useProjectReportsConnectItems = withThrowOnError(useProjectReportsConnectItemsDI);
export const useProjectSettingsConnectItems = withThrowOnError(useProjectSettingsConnectItemsDI);
export const useProjectSettingsForgeItems = useProjectSettingsForgeAppsForMenuDI;
export const useJiraWorkManagementProjectPagesLink = withThrowOnError(
	useJiraWorkManagementProjectPagesLinkDI,
);
