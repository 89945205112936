import { useResource } from '@atlassian/jira-router';
import { advancedRoadmapsSummaryDataResource } from './services/summary-data';

export {
	advancedRoadmapsSummaryDataResource,
	fetchAdvancedRoadmapsSummaryData,
} from './services/summary-data';

export {
	AVAILABLE_CHARTS,
	CAPACITY_UNIT,
	SCHEDULING_MODES,
	SPRINT_STATE,
} from './common/constants';

export {
	IssueHierarchy,
	IssueStatus,
	BlockType,
	type CapacityUnit,
	type IssueProgressData,
	type Issues,
	type Progress,
	type ProgressByStatus,
	type SchedulingMode,
	type SprintState,
	type SummaryPageDataResponse,
	type Team,
	type TeamAllocation,
	type TeamCapacityAllocation,
	type TeamCapacityData,
	type StatusCategoryData,
	type TeamHierarchyLevel,
	type TeamMember,
	type TeamsProgressData,
	type BlockersData,
	type IssueWithProgress,
} from './services/summary-data/types';

export const useAdvancedRoadmapsSummaryDataResource = () =>
	useResource(advancedRoadmapsSummaryDataResource);
