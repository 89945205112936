import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const productsJsmKbPermissionsRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.PRODUCTS_JSM_KB_PERMISSIONS,
	path: '/jira/settings/products/jira-service-management-knowledge-base-permissions',
	exact: true,
};
