import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const projectsRedirectRoute = {
	group: ROUTE_GROUPS.REDIRECTS,
	name: ROUTE_NAMES.PROJECTS_REDIRECT,
	path: '/projects/:projectKey',
	query: ['selectedItem!=(.+)'],
	exact: true,
};
