import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type { InsightsSettingsPageLoader } from '@atlassian/jira-spa-apps-board-settings-insights';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyInsights = lazyForPaint<typeof InsightsSettingsPageLoader>(() =>
	import(
		/* webpackChunkName: "async-board-settings-insights", jiraSpaEntry:"async-board-settings-insights" */
		'@atlassian/jira-spa-apps-board-settings-insights'
	).then((module) => module.InsightsSettingsPageLoader),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazyInsights} pageId="board-settings-insights" shouldShowSpinner={false} />
);
