import { type RouterContext, createResource } from '@atlassian/jira-router';
import type { TranslationResponse } from './services/get-data/types';
import type { OutGoingEmailStateResponse } from './services/notification-editor-actions/get-data/types';

export const languageTranslationsResourceKey = 'JSM Language Translations';
export const outgoingEmailStateResourceKey =
	"Language Support Customer Notification's Outgoing Email State";

export type {
	TranslationPanel,
	LanguageKey,
	TranslationLogicalId,
	ProviderKey,
	DisplayLabel,
	Language,
	TranslationResponse,
	TranslationField,
	ProviderUrlKey,
	TranslationGroup,
	TranslationState,
} from './services/get-data/types';

export const languageTranslationsResource = createResource<TranslationResponse>({
	type: 'Language Translations',
	getKey: ({
		match: {
			params: { projectKey, languageKey },
		},
	}: RouterContext) => `${languageTranslationsResourceKey}-${projectKey}-${languageKey}`,
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-language-translations" */
			'./services/get-data'
		),
	maxAge: Infinity,
});

export const outgoingEmailStateModelResource = createResource<OutGoingEmailStateResponse>({
	type: 'Outgoing Email State Model',
	getKey: () => `${outgoingEmailStateResourceKey}`,
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-Outgoing-Email-State" */
			'./services/notification-editor-actions/get-data'
		),
	maxAge: Infinity,
});
