import camelCase from 'lodash/camelCase';
import { alphabeticalSort } from '@atlassian/jira-common-util-software-filters-sort';
import { ADMIN_PAGE_MODULE, SOURCE_FULL_PAGE_APP } from '@atlassian/jira-forge-ui-constants';
import type {
	FullPageModule,
	FullPage,
	ExtensionFromPointModule,
	RequestPromiseData,
	ExtensionFromPointModuleWithDataClassification,
	GenericFullPageWithAccessNarrowingData,
} from '@atlassian/jira-forge-ui-types';
import type { DataClassificationProps } from '@atlassian/jira-forge-ui-utils/src/types';
import fetchForgeModules from '@atlassian/jira-forge-ui-utils/src/utils/fetch-modules';
import { toCloudId } from '@atlassian/jira-shared-types';
import { uniqueExtensionsFilter, adminPageFilter } from './filters';

const dataClassificatedModulesConfig: {
	names: Set<FullPageModule>;
	request: Promise<
		| ExtensionFromPointModuleWithDataClassification<RequestPromiseData>
		| ExtensionFromPointModule<RequestPromiseData>
	>;
} = {
	names: new Set<FullPageModule>(),
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	request: Promise.resolve({}) as Promise<
		| ExtensionFromPointModuleWithDataClassification<RequestPromiseData>
		| ExtensionFromPointModule<RequestPromiseData>
	>,
};

export const fetchForgeModule = async <T extends FullPage>({
	cloudId,
	moduleName,
	dataClassification,
	shouldReturnANExtensions = false,
	contextIds,
}: {
	cloudId: string;
	moduleName: FullPageModule;
	dataClassification: DataClassificationProps;
	shouldReturnANExtensions?: boolean;
	contextIds?: string[];
}): Promise<GenericFullPageWithAccessNarrowingData<T>> => {
	dataClassificatedModulesConfig.names.add(moduleName);
	// simulate next tick so other resources could register their modules to fetch
	await new Promise((resolve: (result: Promise<undefined> | undefined) => void) =>
		setTimeout(resolve, 0),
	);

	const moduleNamesToFetch = Array.from(dataClassificatedModulesConfig.names);
	let extensions: T[] = [];
	let accessNarrowedExtensions: T[] = [];

	// If the fetch is not already handled
	if (moduleNamesToFetch.length > 0) {
		dataClassificatedModulesConfig.names = new Set();
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		dataClassificatedModulesConfig.request = fetchForgeModules(
			toCloudId(cloudId),
			moduleNamesToFetch,
			dataClassification,
			undefined,
			SOURCE_FULL_PAGE_APP,
			contextIds,
		).then((extensionsMap) => extensionsMap) as Promise<
			ExtensionFromPointModule<RequestPromiseData>
		>;
	}

	const result = await dataClassificatedModulesConfig.request;
	extensions = result.extensions[camelCase(moduleName)];

	if (shouldReturnANExtensions)
		accessNarrowedExtensions = result.accessNarrowedExtensions[camelCase(moduleName)];

	const getExtensionsFilter = (fullPageModuleName: FullPageModule) => {
		switch (fullPageModuleName) {
			case ADMIN_PAGE_MODULE:
				return adminPageFilter;
			default:
				// filtered, as we allow only one extension per module
				return uniqueExtensionsFilter;
		}
	};

	return {
		extensions: getExtensionsFilter(moduleName)(extensions).sort((a, b) =>
			alphabeticalSort(a.properties.title, b.properties.title),
		),
		accessNarrowedExtensions: getExtensionsFilter(moduleName)(accessNarrowedExtensions).sort(
			(a, b) => alphabeticalSort(a.properties.title, b.properties.title),
		),
	};
};
