import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalLayout } from '@atlassian/jira-global-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import { createEntry } from '@atlassian/jira-route-entry';
import { dashboardsDirectoryResource } from '@atlassian/jira-router-resources-directories-dashboards';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import {
	dashboardsDirectoryRedirectRoute,
	dashboardsDirectoryRoute,
} from '@atlassian/jira-router-routes-directories-routes';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect';
import { DashboardsDirectorySkeleton } from '@atlassian/jira-skeletons';
import type DashboardsDirectoryType from '@atlassian/jira-spa-apps-directories-dashboards';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

const DashboardsDirectoryRedirect = () => <ScreenRedirect to="/jira/dashboards" />;

const LazyDashboardsDirectory = lazyForPaint<typeof DashboardsDirectoryType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-dashboards-directory", jiraSpaEntry: "async-dashboards-directory" */ '@atlassian/jira-spa-apps-directories-dashboards'
		),
	),
);

export const DashboardsDirectoryComponent = () => (
	<LazyPage
		Page={LazyDashboardsDirectory}
		pageId="dashboards-directory"
		shouldShowSpinner={false}
	/>
);

export const dashboardsDirectoryRouteEntry = createEntry(dashboardsDirectoryRoute, {
	resources: [...getNavigationResources(), dashboardsDirectoryResource],
	component: DashboardsDirectoryComponent,
	skeleton: DashboardsDirectorySkeleton,
	layout: globalLayout,

	navigation: {
		menuId: MENU_ID.DASHBOARDS,
	},

	forPaint: [LazyAtlassianNavigation, LazyDashboardsDirectory],
});

export const dashboardsDirectoryRedirectRouteEntry = createEntry(dashboardsDirectoryRedirectRoute, {
	resources: [...getNavigationResources(), dashboardsDirectoryResource],
	component: !__SERVER__ ? DashboardsDirectoryRedirect : DashboardsDirectoryComponent,
	skeleton: DashboardsDirectorySkeleton,
	layout: globalLayout,

	navigation: {
		menuId: MENU_ID.DASHBOARDS,
	},

	forPaint: [LazyAtlassianNavigation, LazyDashboardsDirectory],
	isRedirect: true,
});
