import type { FullPage } from '@atlassian/jira-forge-ui-types';
import { useRouter } from '@atlassian/jira-router';
import { useMatchedExtension } from '@atlassian/jira-spa-apps-forge-common';

export const useForgeSelectedApp = (modules: FullPage[] | null, routeName?: string) => {
	const [{ match, route }] = useRouter();
	const matchedExtension = useMatchedExtension({ modules, match });

	if (route.name !== routeName || matchedExtension == null) {
		return null;
	}

	return matchedExtension;
};
