import { useMemo } from 'react';
import { withThrowOnError } from '@atlassian/jira-navigation-apps-sidebar-nav4-common/src/utils/throw-when-no-data-and-error';
import type { ConnectDataItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-connect/src/common/types';
import { findItemAndStack } from '@atlassian/jira-navigation-apps-sidebar-nav4-connect/src/common/utils/find-item-stack';
import { getLinks } from '@atlassian/jira-navigation-apps-sidebar-nav4-connect/src/common/utils/get-links';
import { transformLink } from '@atlassian/jira-navigation-apps-sidebar-nav4-connect/src/common/utils/transform-link';
import { useNavigationGlobalResource } from '@atlassian/jira-navigation-apps-sidebar-nav4-global-settings-common/src/controllers/navigation-global-resource';
import type { SidebarConfigData } from '@atlassian/jira-navigation-types';
import { useRouter } from '@atlassian/jira-router';
import { useConnectGeneralResource } from '@atlassian/jira-router-resources-connect-general';

const getItems = (d: SidebarConfigData) =>
	getLinks(d, ['admin_system_menu'], { skipFilter: true })
		.map(transformLink) // Apply transformation to each link
		.filter(Boolean); // Remove falsy values

export const useGlobalSystemSettingsConnectItems = withThrowOnError(() => {
	const { data, loading, error } = useNavigationGlobalResource();

	const items: ConnectDataItem[] | null = useMemo(() => data && getItems(data), [data]);

	return {
		data: items,
		loading,
		error,
	};
});

export const useGlobalSystemSettingsConnectItemActive = () => {
	const [{ route, location }] = useRouter();
	const { data, loading, error } = useGlobalSystemSettingsConnectItems();
	const {
		data: connectGeneralData,
		loading: connectGeneralResourceLoading,
		error: connectGeneralResourceError,
	} = useConnectGeneralResource();

	const isActive = useMemo(
		() => Boolean(route && data && findItemAndStack(data, route.name, location)?.selected),
		[data, route, location],
	);

	return {
		isActive: isActive || connectGeneralData?.moduleType === 'adminPages',
		loading: loading || connectGeneralResourceLoading,
		error: error || connectGeneralResourceError,
	};
};
