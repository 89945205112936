import type {
	VulnerabilityFilters,
	VulnerabilityTableOptions,
} from './vulnerability-filters-types';

export const QUERY_PARAM_SEARCH = 'search' as const;
export const QUERY_PARAM_CONTAINER = 'container' as const;
export const QUERY_PARAM_SEVERITY = 'severity' as const;
export const QUERY_PARAM_VULN_STATUS = 'vulnerabilityStatus' as const;
export const QUERY_PARAM_ISSUE_STATUS = 'issueStatus' as const;
export const QUERY_PARAM_SORT_KEY = 'sortKey' as const;
export const QUERY_PARAM_SORT_ORDER = 'sortOrder' as const;
export const NO_FILTER_VULN_STATUS = 'NO-FILTERS';

export const initialVulnerabilityTableFilters: VulnerabilityFilters = {
	searchFilter: '',
	connectionFilter: [],
	severityFilter: [],
	statusFilter: ['OPEN'],
	jiraIssueStatusFilter: [],
};

export const initialVulnerabilityTableOptions: VulnerabilityTableOptions = {
	sortKey: 'severity',
	sortOrder: 'DESC',
	...initialVulnerabilityTableFilters,
};
