import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import { type RouteContext, type Match, Redirect } from '@atlassian/jira-router';
import type ConnectAppControlsSettingsType from '@atlassian/jira-spa-apps-project-settings-connect-app-controls';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import AppDiscoverySettingsPage from './app-discovery-settings';

export const LazyConnectAppControlsSettings = lazyForPaint<typeof ConnectAppControlsSettingsType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-project-settings-connect-app-controls", jiraSpaEntry: "async-project-settings-connect-app-controls" */ '@atlassian/jira-spa-apps-project-settings-connect-app-controls'
			),
		),
);

const ConnectAppsRedirectInner = ({ match, basePath }: { basePath: string; match: Match }) => {
	const { data: projectContext } = useProjectContext();
	const shouldRedirect =
		projectContext != null &&
		projectContext.simplified &&
		projectContext.projectType === SERVICE_DESK_PROJECT;

	return shouldRedirect ? (
		<Redirect
			to={`${basePath}/projects/${String(match.params.projectKey)}/settings/apps/app-fields`}
			push={false}
		/>
	) : (
		<AppDiscoverySettingsPage />
	);
};

export const ConnectAppsRedirect =
	(basePath: string) =>
	({ match }: RouteContext) => <ConnectAppsRedirectInner match={match} basePath={basePath} />;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyConnectAppControlsSettings}
		pageId="project-settings-connect-app-controls"
		shouldShowSpinner={false}
	/>
);
