import { useMemo } from 'react';
import { useNavigationGlobalResource } from '@atlassian/jira-navigation-apps-sidebar-common';
import { withThrowOnError } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/throw-when-no-data-and-error';
import { useGlobalSettingsSystemConnectItems as useGlobalSettingsSystemConnectItemsWithoutThrowOnError } from '@atlassian/jira-navigation-apps-sidebar-connect';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller';
import type { UseFeaturesReturn } from './types';
import { extractData } from './utils';

export const useFeatures = withThrowOnError((): UseFeaturesReturn => {
	const { data, loading, error } = useNavigationGlobalResource();
	const { isDevMode, isAdmin, isSiteAdmin } = useTenantContext();
	const extractedData = useMemo(
		() => data && extractData(data, isDevMode, isAdmin, isSiteAdmin),
		[data, isDevMode, isAdmin, isSiteAdmin],
	);
	return { data: extractedData, loading, error };
});

export const useGlobalSettingsSystemConnectItems = withThrowOnError(
	useGlobalSettingsSystemConnectItemsWithoutThrowOnError,
);
