import { useMemo } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars -- PLEASE FIX - ENABLING FLAT LINT CONFIG
import { fg } from '@atlassian/jira-feature-gating';
import type { ProjectPage } from '@atlassian/jira-forge-ui-types';
import { doesExtensionMeetConditions } from '@atlassian/jira-forge-ui-utils/src/utils/conditions';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import { useResourceWithCustomRouterContext } from '@atlassian/jira-resource-with-custom-router-context';
import { useForgeItemStack } from '../../common/utils';
import type { UseNavigationResourceReturnWithStackAndAN } from '../types';

export const useProjectForgeApps = (
	routeName?: string,
	projectKey?: string,
): UseNavigationResourceReturnWithStackAndAN<ProjectPage[]> => {
	const { data: projectContext } = useProjectContext(
		projectKey
			? {
					matchParams: { projectKey },
				}
			: undefined,
	);
	const { data, loading, error } = useResourceWithCustomRouterContext(
		forgeProjectModuleResource,
		projectKey
			? {
					matchParams: { projectKey },
				}
			: undefined,
	);
	const projectData = useMemo(() => data?.extensions || null, [data]);
	const accessNarrowedData = useMemo(() => data?.accessNarrowedExtensions || null, [data]);
	const stack = useForgeItemStack(projectData, routeName);

	// Instead of dumping projectContext as is we'd like to hardcode the context
	// to be sure that correct prop are exposed externally
	const context = useMemo(
		() =>
			projectContext
				? {
						projectKey: projectContext.projectKey,
						projectId: projectContext.projectId,
						projectName: projectContext.projectName,
						projectType: projectContext.projectType,
						isProjectAdmin: projectContext.isProjectAdmin,
						isProjectArchived: projectContext.isProjectArchived,
					}
				: {},
		[projectContext],
	);

	return useMemo<UseNavigationResourceReturnWithStackAndAN<ProjectPage[]>>(
		() => ({
			data: projectData
				? projectData.filter((extension) => doesExtensionMeetConditions(extension, context))
				: null,
			accessNarrowedData,
			loading,
			error,
			stack,
		}),
		[projectData, accessNarrowedData, loading, error, stack, context],
	);
};

export const useProjectForgeAppsNav4 = (
	projectKey: string,
): UseNavigationResourceReturnWithStackAndAN<ProjectPage[]> =>
	useProjectForgeApps(undefined, projectKey);
