import React, { type ComponentPropsWithoutRef } from 'react';
import type { AnyEntryPoint, EntryPointReferenceSubject } from '@atlassian/jira-entry-point';
import { useEntryPointSubscription } from '@atlassian/jira-entry-point-subscription';
import { InternalEntryPointContainer } from '@atlassian/jira-internal-entry-point-container';

interface Props<TEntryPoint extends AnyEntryPoint>
	extends Omit<
		ComponentPropsWithoutRef<typeof InternalEntryPointContainer<TEntryPoint>>,
		'entryPointReference'
	> {
	entryPointReferenceSubject: EntryPointReferenceSubject<TEntryPoint>;
}

export const JiraEntryPointContainer = <TEntryPoint extends AnyEntryPoint>({
	entryPointReferenceSubject,
	...containerProps
}: Props<TEntryPoint>) => {
	const entryPointReference = useEntryPointSubscription<TEntryPoint>(entryPointReferenceSubject);

	return entryPointReference ? (
		<InternalEntryPointContainer
			{...containerProps}
			entryPointReference={entryPointReference}
			placeholderName="jira-entry-point-container"
		/>
	) : null;
};

export const NestedEntryPointContainer = InternalEntryPointContainer;
