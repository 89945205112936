import {
	type UserPreferenceType,
	decodeUserPreferences,
} from '@atlassian/jira-dashboard-user-preference';
import type { ReactGadgetGetInitialState } from '@atlassian/jira-react-gadgets-common';
import issueTableViewQuery, {
	type issueTableViewQuery as IssueTableViewQueryType,
} from '@atlassian/jira-relay/src/__generated__/issueTableViewQuery.graphql';
import { loadQueryWithUserPrefs } from '../common/utils';
import { transformToValidUserPrefs } from '../common/utils/transform-to-valid-user-prefs';
import type { IssueTableGadgetData, SortPrefs } from '../types';

const createGetInitialState: ({
	predeterminedJql,
	predeterminedJqlIncludeResolved,
	defaultSortPrefs,
	testId,
}: {
	predeterminedJql?: string;
	predeterminedJqlIncludeResolved?: string;
	defaultSortPrefs?: SortPrefs;
	testId: string;
}) => ReactGadgetGetInitialState<IssueTableGadgetData, UserPreferenceType[]> =
	({ predeterminedJql, predeterminedJqlIncludeResolved, defaultSortPrefs, testId }) =>
	async (resourceContext, userPrefs) => {
		if (resourceContext?.tenantContext == null) {
			throw new Error('Tenant context is not available');
		}
		const { cloudId, activationId } = resourceContext.tenantContext;

		const validUserPrefs = transformToValidUserPrefs(decodeUserPreferences(userPrefs));
		if (
			validUserPrefs.filterId === undefined &&
			predeterminedJql === undefined &&
			predeterminedJqlIncludeResolved === undefined
		) {
			throw new Error(
				'FilterId or JQL has to be available to be able to load the gadget initial state',
			);
		}

		const sortPrefs = (() => {
			if (validUserPrefs.sortField) {
				return {
					sortField: validUserPrefs.sortField,
					sortDirection: validUserPrefs.sortDirection,
				};
			}
			if (defaultSortPrefs) {
				return {
					sortField: defaultSortPrefs.field,
					sortDirection: defaultSortPrefs.direction,
				};
			}
		})();

		const includeResolved = validUserPrefs.showResolved;
		const jql = includeResolved ? predeterminedJqlIncludeResolved : predeterminedJql;
		const queryReference = await loadQueryWithUserPrefs<IssueTableViewQueryType>(
			{
				...validUserPrefs,
				...sortPrefs,
			},
			issueTableViewQuery,
			cloudId,
			activationId,
			'store-and-network',
			jql,
		);

		return { queryReference, predeterminedJql, defaultSortPrefs, testId };
	};

export default createGetInitialState;
