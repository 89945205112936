import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type PlanSettingsCustomFieldsType from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-settings/custom-fields';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

/**
 * Plan Settings - custom fields page
 */
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyPlanSettingsCustomFields = lazyForPaint<typeof PlanSettingsCustomFieldsType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-plan-settings-custom-fields", jiraSpaEntry: "async-plan-settings-custom-fields" */ '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-settings/custom-fields'
			),
		),
	{ ssr: false },
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyPlanSettingsCustomFields}
		pageId="plan-settings-custom-fields"
		shouldShowSpinner
	/>
);
