import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const productsJswConfigurationRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.PRODUCTS_JSW_CONFIGURATION,
	path: '/jira/settings/products/jira-software-configuration',
	exact: true,
};

export const productsJswConfigurationStorageManagementRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.PRODUCTS_JSW_STORAGE_MANAGEMENT,
	path: '/jira/settings/products/jira-software-configuration/storage-management',
	exact: true,
};
