import { createStore, createHook, createActionsHook } from 'react-sweet-state';
import { actions } from '../actions';
import type { LayoutState, LayoutActions } from './types';

export const DEFAULT_LEFT_SIDEBAR_WIDTH = 240;
export const DEFAULT_RIGHT_SIDEBAR_WIDTH = 0;

const initialState: LayoutState = {
	isChromeless: false,
	banner: { height: 0, activeBanner: null },
	topNavigation: { isVisible: true },
	leftPanel: { isVisible: true, width: 0 },
	rightPanel: { isVisible: true, width: 0 },
	bottomRightCorner: { container: undefined, portals: [] },
	content: {
		leftSidebar: {
			width: DEFAULT_LEFT_SIDEBAR_WIDTH,
			isCollapsed: true,
			isVisible: true,
			isFlyoutOpen: false,
		},
		rightSidebar: {
			stackState: [],
		},
	},
};

export const layoutStore = createStore<LayoutState, LayoutActions>({
	initialState,
	actions,
	name: 'JiraLayoutStore',
});

export const useLayoutStoreActions = createHook(layoutStore, {
	selector: null,
});

const detailPanelWidthStore = createStore({
	name: 'detailPanelWidth',
	initialState: {
		width: 0,
	},
	actions: {
		setWidth:
			(value: number) =>
			({ setState }) => {
				setState({ width: value });
			},
	},
});

export const useDetailPanelWidth = createHook(detailPanelWidthStore);
export const useDetailPanelActions = createActionsHook(detailPanelWidthStore);
