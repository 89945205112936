import React, { memo, useMemo } from 'react';
import { ROUTE_NAMES_FORGE_PROJECT } from '@atlassian/jira-common-constants/src/spa-routes';
import type { ProjectPage } from '@atlassian/jira-forge-ui-types';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources';
import {
	ForgeProjectSection,
	useProjectForgeApps,
} from '@atlassian/jira-navigation-apps-sidebar-forge';
import { useForgeSelectedApp } from '@atlassian/jira-navigation-apps-sidebar-forge/src/common/utils';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils';
import { useResource } from '@atlassian/jira-router';
import { useAutoExpandSidebar } from '../../../../../controllers';

const ForgeMenu = () => {
	useAutoExpandSidebar();
	const route = useCurrentRoute();
	const { data: forgeResourceData } = useResource(forgeProjectModuleResource);
	const projectData = useMemo(() => forgeResourceData?.extensions || null, [forgeResourceData]);
	const selectedForgeApp = useForgeSelectedApp(projectData, route.name);
	const { stack: forgeStack } = useProjectForgeApps(ROUTE_NAMES_FORGE_PROJECT);

	if (selectedForgeApp != null && forgeStack != null && forgeStack.length > 0) {
		return (
			<ForgeProjectSection
				overrides={{
					showSelectedAppMenuOnly: true,
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					selectedApp: selectedForgeApp as ProjectPage,
				}}
			/>
		);
	}

	return null;
};

export default memo(ForgeMenu);
