import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ExternalImportType from '@atlassian/jira-spa-apps-external-import';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyExternalImport = lazyForPaint<typeof ExternalImportType>(
	() =>
		import(
			/* webpackChunkName: "async-system-external-import", jiraSpaEntry: "async-system-external-import" */ '@atlassian/jira-spa-apps-external-import'
		),
	{ ssr: false },
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazyExternalImport} pageId="external-import-general" shouldShowSpinner={false} />
);
