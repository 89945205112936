import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type OpsGenieScheduleType from '@atlassian/jira-spa-apps-opsgenie-schedule';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import { RedirectToProjectType } from './project-type';

export const LazyOpsGenieSchedule = lazyForPaint<typeof OpsGenieScheduleType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-opsgenie-schedule", jiraSpaEntry: "async-opsgenie-schedule" */ '@atlassian/jira-spa-apps-opsgenie-schedule'
		),
	),
);

export const OpsgenieScheduleRedirect = () => <RedirectToProjectType page="/on-call-schedule" />;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazyOpsGenieSchedule} pageId="opsgenie-schedule" shouldShowSpinner />
);
