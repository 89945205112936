import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const homeOnboardingRedirectRoute = {
	group: ROUTE_GROUPS.HOME,
	name: ROUTE_NAMES.HOME_ONBOARDING_REDIRECT,
	path: '/jira/your-work-onboarding',
	exact: true,
};
