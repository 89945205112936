import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

const jiraIssueFormCreateEndpoint =
	'projects/:projectKey/forms/form/direct/:templateFormId/:issueTypeId?'; // the 1 query param is "requestTypeId"
export const softwareRoadmapClassicRoute = {
	group: ROUTE_GROUPS.CLASSIC_SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_ROADMAP_CLASSIC,
	path: '/jira/software/c/projects/:projectKey/boards/:boardId/roadmap',
};

export const softwareTimelineClassicRoute = {
	group: ROUTE_GROUPS.CLASSIC_SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_TIMELINE_CLASSIC,
	path: '/jira/software/c/projects/:projectKey/boards/:boardId/timeline',
};

export const softwareCalendarClassicRoute = {
	group: ROUTE_GROUPS.CLASSIC_SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_CALENDAR_CLASSIC,
	path: '/jira/software/c/projects/:projectKey/boards/:boardId/calendar',
	queries: ['date?'],
};

export const softwareCalendarClassicEmbedRoute = {
	group: ROUTE_GROUPS.CLASSIC_SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_CALENDAR_CLASSIC_EMBED,
	path: '/jira/software/c/projects/:projectKey/boards/:boardId/calendar/embed',
	queries: ['date?'],
};

export const softwareListClassicRoute = {
	group: ROUTE_GROUPS.CLASSIC_SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_LIST_CLASSIC,
	path: '/jira/software/c/projects/:projectKey/list',
};

export const softwareListEmbedClassicRoute = {
	group: ROUTE_GROUPS.CLASSIC_SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_LIST_CLASSIC_EMBED,
	path: '/jira/software/c/projects/:projectKey/list/embed',
};

export const softwareGoalsClassicRoute = {
	group: ROUTE_GROUPS.CLASSIC_SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_GOALS_CLASSIC,
	path: '/jira/software/c/projects/:projectKey/goals',
};

export const rapidboardBacklogRoute = {
	group: ROUTE_GROUPS.CLASSIC_SOFTWARE,
	name: ROUTE_NAMES.RAPIDBOARD_BACKLOG,
	path: '/jira/software/c/projects/:projectKey/boards/:boardId/backlog',
};

export const rapidboardReportRoute = {
	group: ROUTE_GROUPS.CLASSIC_SOFTWARE,
	name: ROUTE_NAMES.RAPIDBOARD_REPORT,
	path: '/jira/software/c/projects/:projectKey/boards/:boardId/reports/:chartView',
};

export const softwareReportsCycleTimeClassicRoute = {
	group: ROUTE_GROUPS.CLASSIC_SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_REPORTS_CYCLE_TIME_CLASSIC,
	path: '/jira/software/c/projects/:projectKey/reports/cycle-time',
};

export const softwareClassicProformaIssueFormsDirectRoute = {
	group: ROUTE_GROUPS.CLASSIC_SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_CLASSIC_PROFORMA_ISSUE_FORMS_DIRECT,
	path: `/jira/software/c/${jiraIssueFormCreateEndpoint}`,
	exact: true,
};

export const softwareClassicProformaIssueFormsDirectEmbedRoute = {
	group: ROUTE_GROUPS.CLASSIC_SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_CLASSIC_PROFORMA_ISSUE_FORMS_DIRECT_EMBED,
	path: `/jira/software/c/${jiraIssueFormCreateEndpoint}/embed`,
	exact: true,
};

export const softwareReportsDeploymentFrequencyClassicRoute = {
	group: ROUTE_GROUPS.CLASSIC_SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_REPORTS_DEPLOYMENT_FREQUENCY_CLASSIC,
	path: '/jira/software/c/projects/:projectKey/reports/deployment-frequency',
};

export const rapidboardUserBacklogRoute = {
	group: ROUTE_GROUPS.CLASSIC_SOFTWARE,
	name: ROUTE_NAMES.RAPIDBOARD_USER_BACKLOG,
	path: '/jira/people/:accountId/boards/:boardId/backlog',
};

export const rapidboardUserReportRoute = {
	group: ROUTE_GROUPS.CLASSIC_SOFTWARE,
	name: ROUTE_NAMES.RAPIDBOARD_USER_REPORT,
	path: '/jira/people/:accountId/boards/:boardId/reports/:chartView',
};

export const rapidboardBacklog1Route = {
	group: ROUTE_GROUPS.CLASSIC_SOFTWARE,
	name: ROUTE_NAMES.RAPIDBOARD_BACKLOG,
	path: '/secure/RapidBoard.jspa',
	query: ['rapidView', 'view=(planning|planning.nodetail)'],
};

export const rapidboardReport1Route = {
	group: ROUTE_GROUPS.CLASSIC_SOFTWARE,
	name: ROUTE_NAMES.RAPIDBOARD_REPORT,
	path: '/secure/RapidBoard.jspa',
	query: ['rapidView', 'view=reporting', 'chart'],
};

export const softwareCodeClassicRoute = {
	group: ROUTE_GROUPS.CLASSIC_SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_CODE_CLASSIC,
	path: '/jira/software/c/projects/:projectKey/code',
};

export const rapidboardLastVisitedProjectRedirectRoute = {
	group: ROUTE_GROUPS.CLASSIC_SOFTWARE,
	name: ROUTE_NAMES.RAPIDBOARD_LAST_VISITED_PROJECT_REDIRECT,
	path: '/secure/RapidBoard.jspa',
	query: ['projectKey'],
};

export const projectDirectoryRedirectRoute = {
	group: ROUTE_GROUPS.CLASSIC_SOFTWARE,
	name: ROUTE_NAMES.PROJECT_DIRECTORY_REDIRECT,
	path: '/secure/RapidBoard.jspa',
};
