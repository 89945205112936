import type { UserPreferenceType } from '@atlassian/jira-dashboard-user-preference';
import type { ReactGadgetGetInitialState } from '@atlassian/jira-react-gadgets-common';
import type { CustomReportGadgetData } from '../common/types';

const getInitialState: ReactGadgetGetInitialState<
	CustomReportGadgetData,
	UserPreferenceType[]
> = () =>
	Promise.resolve({
		intervalValue: '1',
		isCustom: 'false',
		projectValue: '0',
		refresh: 'false',
		reportValue: '0',
		gadgetContentTitle: '',
	});

export default getInitialState;
