import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type { RouteContext } from '@atlassian/jira-router';
import type NextGenReportsVelocityType from '@atlassian/jira-spa-apps-next-gen-reports-velocity';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import { getBoardId } from '../../../../common';

export const LazyNextGenVelocityReport = lazyForPaint<typeof NextGenReportsVelocityType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-next-gen-reports-velocity", jiraSpaEntry: "next-gen-reports-velocity" */ '@atlassian/jira-spa-apps-next-gen-reports-velocity'
		),
	),
);

type Props = RouteContext;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ location, match }: Props) => (
	<LazyPage
		Page={LazyNextGenVelocityReport}
		pageId={`next-gen-reports-velocity-${getBoardId({ location, match })}`}
		shouldShowSpinner={false}
	/>
);
