/**
 * @generated SignedSource<<dcf50e775b221024fd1c30e27bbb62f2>>
 * @relayHash 9459c0218f4aabda777b263e43d6af57
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a0cbbb33f77ce77f01e2fbb6dc96c89baf132394dc74242534a2543aea933dda

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { mainVirtualAgentSidebarQuery } from './mainVirtualAgentSidebarQuery.graphql';

const node: PreloadableConcreteRequest<mainVirtualAgentSidebarQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "a0cbbb33f77ce77f01e2fbb6dc96c89baf132394dc74242534a2543aea933dda",
    "metadata": {},
    "name": "mainVirtualAgentSidebarQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
