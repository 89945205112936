import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type JiraBusinessSampleOnboardingAppType from '@atlassian/jira-spa-apps-business-sample-onboarding';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazySampleOnboardingView = lazyForPaint<typeof JiraBusinessSampleOnboardingAppType>(
	() =>
		import(
			/* webpackChunkName: "async-business-sample-onboarding", jiraSpaEntry: "async-business-sample-onboarding" */ '@atlassian/jira-spa-apps-business-sample-onboarding'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazySampleOnboardingView}
		pageId="async-business-sample-onboarding"
		shouldShowSpinner
	/>
);
