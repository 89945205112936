import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import {
	DashboardBackgroundScripts,
	dashboardBackgroundScriptsLayout,
} from '@atlassian/jira-background-scripts-layout';
import { DASHBOARD } from '@atlassian/jira-capabilities';
import { NORMAL_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets';
import type DashboardEditType from '@atlassian/jira-dashboard-edit';
import type DashboardViewType from '@atlassian/jira-dashboard-view';
import type DashboardViewEmbedType from '@atlassian/jira-dashboard-view-embed';
import type DashboardWallboardType from '@atlassian/jira-dashboard-wallboard';
// eslint-disable-next-line @typescript-eslint/no-unused-vars -- PLEASE FIX - ENABLING FLAT LINT CONFIG
import { ff } from '@atlassian/jira-feature-flagging';
import { globalLayout } from '@atlassian/jira-global-layout';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { homepageLayout } from '@atlassian/jira-homepage-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarGlobalSettingsSystem from '@atlassian/jira-navigation-apps-sidebar-global-settings-system/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { composeLayouts, createLayout } from '@atlassian/jira-route-layout';
import {
	dashboardConfigAndWRMResource,
	dashboardAMDModulesResource,
	dashboardReactGadgetsResource,
	dashboardForgeResource,
	gadgetDirectoryResource,
} from '@atlassian/jira-router-resources-dashboard';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference';
import {
	dashboardRoute,
	selectedDashboardRoute,
	dashboardEditRoute,
	dashboardWallboardRoute,
	dashboardViewRoute,
	dashboardViewEmbedRoute,
	systemDashboardEditRoute,
} from '@atlassian/jira-router-routes-dashboard-routes';
import type SystemDashboardEditType from '@atlassian/jira-spa-apps-dashboard-system-dashboard-edit';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import { DashboardRedirect } from './ui/dashboard-redirect';

const dashboardResources = [
	dashboardConfigAndWRMResource,
	dashboardAMDModulesResource,
	dashboardReactGadgetsResource,
	dashboardForgeResource,
];

const LazyDashboardView = lazyForPaint<typeof DashboardViewType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-dashboard-view", jiraSpaEntry: "async-dashboard-view" */ '@atlassian/jira-dashboard-view'
		),
	),
);

const LazyDashboardViewEmbed = lazyForPaint<typeof DashboardViewEmbedType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-dashboard-view-embed", jiraSpaEntry: "async-dashboard-view-embed" */ '@atlassian/jira-dashboard-view-embed'
		),
	),
);

const LazyDashboardWallboard = lazyForPaint<typeof DashboardWallboardType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-dashboard-wallboard", jiraSpaEntry: "async-dashboard-wallboard" */ '@atlassian/jira-dashboard-wallboard'
		),
	),
);

const LazyDashboardEdit = lazyForPaint<typeof DashboardEditType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-dashboard-edit", jiraSpaEntry: "async-dashboard-edit" */ '@atlassian/jira-dashboard-edit'
		),
	),
);

export const LazySystemDashboardEdit = lazyForPaint<typeof SystemDashboardEditType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-system-dashboard-edit", jiraSpaEntry: "async-system-dashboard-edit" */ '@atlassian/jira-spa-apps-dashboard-system-dashboard-edit'
		),
	),
);

export const DashboardViewPage = () => (
	<LazyPage Page={LazyDashboardView} pageId="dashboard" shouldShowSpinner={false} />
);

export const DashboardViewEmbedPage = () => (
	<LazyPage Page={LazyDashboardViewEmbed} pageId="dashboard" shouldShowSpinner />
);

export const DashboardWallboardPage = () => (
	<LazyPage Page={LazyDashboardWallboard} pageId="dashboard" shouldShowSpinner />
);

export const DashboardEditPage = () => (
	<LazyPage Page={LazyDashboardEdit} pageId="dashboard" shouldShowSpinner={false} />
);

export const SystemDashboardEditPage = () => (
	<LazyPage
		Page={LazySystemDashboardEdit}
		pageId="system-dashboard-edit"
		shouldShowSpinner={false}
	/>
);

export const dashboardRouteEntry = createEntry(dashboardRoute, {
	component: DashboardRedirect,
	layout: homepageLayout,
	resources: [...getNavigationResources(), ...dashboardResources],
	forPaint: [LazyAtlassianNavigation, LazyDashboardView],
	meta: { capability: DASHBOARD },
	isRedirect: true,
	earlyAssets: NORMAL_EARLY_ASSET_STRATEGY,
});

export const selectedDashboardRouteEntry = createEntry(selectedDashboardRoute, {
	component: DashboardRedirect,
	layout: globalLayout,
	navigation: {
		menuId: MENU_ID.DASHBOARDS,
	},
	resources: [...getNavigationResources(), ...dashboardResources],
	forPaint: [LazyAtlassianNavigation, LazyDashboardView],
	meta: { capability: DASHBOARD },
	isRedirect: true,
	earlyAssets: NORMAL_EARLY_ASSET_STRATEGY,
});

// IMPORTANT - must precede the view route due to substring path match
export const dashboardEditRouteEntry = createEntry(dashboardEditRoute, {
	component: DashboardEditPage,
	layout: composeLayouts(globalLayout, dashboardBackgroundScriptsLayout),
	navigation: {
		menuId: MENU_ID.DASHBOARDS,
	},
	resources: [...getNavigationResources(), ...dashboardResources, gadgetDirectoryResource],
	forPaint: [LazyAtlassianNavigation, LazyDashboardEdit],
	meta: { capability: DASHBOARD },
	earlyAssets: NORMAL_EARLY_ASSET_STRATEGY,
});

export const dashboardWallboardRouteEntry = createEntry(dashboardWallboardRoute, {
	component: DashboardWallboardPage,
	layout: createLayout({
		globalComponents: [DashboardBackgroundScripts],
		// we don't want nav bar and right panel etc to appear in wallboard, so we use chromeless mode
		isChromeless: true,
	}),
	resources: [themePreferenceResource, ...dashboardResources],
	forPaint: [LazyDashboardWallboard],
	meta: { capability: DASHBOARD },
	earlyAssets: NORMAL_EARLY_ASSET_STRATEGY,
});

export const dashboardViewRouteEntry = createEntry(dashboardViewRoute, {
	component: DashboardViewPage,
	layout: composeLayouts(globalLayout, dashboardBackgroundScriptsLayout),
	navigation: {
		menuId: MENU_ID.DASHBOARDS,
	},
	resources: [...getNavigationResources(), ...dashboardResources],
	forPaint: [LazyAtlassianNavigation, LazyDashboardView],
	meta: { capability: DASHBOARD },
	earlyAssets: NORMAL_EARLY_ASSET_STRATEGY,
	ufoName: 'dashboard',
});

export const dashboardViewEmbedRouteEntry = createEntry(dashboardViewEmbedRoute, {
	component: DashboardViewEmbedPage,
	layout: createLayout({
		globalComponents: [DashboardBackgroundScripts],
		isChromeless: true,
	}),
	resources: [themePreferenceResource, ...dashboardResources],
	forPaint: [LazyDashboardViewEmbed],
	meta: { capability: DASHBOARD },
	earlyAssets: NORMAL_EARLY_ASSET_STRATEGY,
});

export const systemDashboardEditRouteEntry = createEntry(systemDashboardEditRoute, {
	component: SystemDashboardEditPage,
	layout: composeLayouts(globalSettingsLayout, dashboardBackgroundScriptsLayout),
	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsSystem,
	},
	resources: [
		...getNavigationResources(),
		navigationSidebarGlobalResource,
		// IMPORTANT - SSR requires wrm resource must come first
		...dashboardResources,
		gadgetDirectoryResource,
	],
	forPaint: [LazyAtlassianNavigation, LazySystemDashboardEdit],
	earlyAssets: NORMAL_EARLY_ASSET_STRATEGY,
});
