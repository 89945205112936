import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarGlobalSettingsProjects from '@atlassian/jira-navigation-apps-sidebar-global-settings-projects/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import {
	trashProjectsDirectoryRoute,
	trashProjectsDirectory1Route,
} from '@atlassian/jira-router-routes-trash-projects-directory-routes';
import RedirectToPrettyUrlTrashDirectory from './common/ui/redirect/jira-projects-trash-directory-redirect';
import TrashProjectsDirectory, {
	LazyTrashedProjectsAdmin,
} from './ui/admin-pages/trashed-projects';

export const trashProjectsDirectoryRouteEntry = createEntry(trashProjectsDirectoryRoute, {
	ufoName: 'admin.trashed-projects',
	component: RedirectToPrettyUrlTrashDirectory,
	layout: globalSettingsLayout,

	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_PROJECTS_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProjects,
	},

	resources: [...getNavigationResources(), navigationSidebarGlobalResource],
	forPaint: [LazyAtlassianNavigation, LazyTrashedProjectsAdmin],
	isRedirect: true,
});

export const trashProjectsDirectory1RouteEntry = createEntry(trashProjectsDirectory1Route, {
	ufoName: 'admin.trashed-projects',
	component: TrashProjectsDirectory,
	layout: globalSettingsLayout,

	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_PROJECTS_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProjects,
	},

	resources: [...getNavigationResources(), navigationSidebarGlobalResource],
	forPaint: [LazyAtlassianNavigation, LazyTrashedProjectsAdmin],
});
