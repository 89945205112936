import React, { useEffect, useMemo, useState } from 'react';
import {
	NavigationContent,
	NestableNavigationContent,
	NavigationHeader,
	SideNavigation,
	SkeletonItem,
} from '@atlaskit/side-navigation';
import { ROUTE_NAMES } from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import {
	ContextualAnalyticsData,
	matchUrlPathname,
	MenuItem,
	TitleHeader,
	getSystemNavigationPaths,
} from '@atlassian/jira-navigation-apps-sidebar-common';
import { ConnectMenu } from '@atlassian/jira-navigation-apps-sidebar-connect';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils';
import { SECTION_ID } from '../../common/constants';
import { useFeatures, useGlobalSettingsSystemConnectItems } from '../../controllers/features';
import { AdminHelperSection } from './admin-helper';
import { AdvancedSection } from './advanced';
import { AutomationSection } from './automation';
import { ImportExportSection } from './import-export';
import { MailSection } from './mail';
import messages from './messages';
import { SecuritySection } from './security';
import { SharedSection } from './shared';
import { TroubleshootingAndSupportSection } from './troubleshooting-and-support';
import { UserInterfaceSection } from './user-interface';
import { isGeneralConfigurationSelected } from './utils';

// @ts-expect-error - TS7006 - Parameter 'route' implicitly has an 'any' type.
const calculateStack = (route) => {
	const routeName = (route && route.name) || '';
	if (
		[
			ROUTE_NAMES.GLOBAL_SETTINGS_MIGRATIONS_DASHBOARD,
			ROUTE_NAMES.GLOBAL_SETTINGS_MIGRATIONS_HOME,
			ROUTE_NAMES.GLOBAL_SETTINGS_MIGRATIONS_FOCUS_PAGE,
		].includes(routeName)
	) {
		return [SECTION_ID.MIGRATE_TO_CLOUD_SITE];
	}

	if (
		[
			ROUTE_NAMES.GLOBAL_SETTINGS_PERFORMANCE_METRIC_DETAIL,
			ROUTE_NAMES.GLOBAL_SETTINGS_PERFORMANCE_OVERVIEW,
			ROUTE_NAMES.GLOBAL_SETTINGS_PERFORMANCE_PROJECT_CLEANUP,
			ROUTE_NAMES.GLOBAL_SETTINGS_PERFORMANCE_PROJECT_ROLE_ACTOR,
			ROUTE_NAMES.GLOBAL_SETTINGS_PERFORMANCE_CUSTOM_FIELD,
			ROUTE_NAMES.GLOBAL_SETTINGS_PERFORMANCE_ISSUE,
		].includes(routeName)
	) {
		return [SECTION_ID.PERFORMANCE_AND_SCALE];
	}

	return [];
};

/**
 *  Returns Menu navigation respecting the current user permissions
 *  When updating this file, make sure to also update the following files if necessary:
 * - src/packages/navigation-apps/sidebar/common/src/controllers/use-navigation-path/system
 */
export const Menu = () => {
	const { data: features } = useFeatures();
	const { formatMessage } = useIntl();
	const route = useCurrentRoute();

	const { data: connectItems } = useGlobalSettingsSystemConnectItems();
	const [stack, setStack] = useState<string[]>(calculateStack(route));

	useEffect(() => {
		setStack(calculateStack(route));
	}, [route]);

	const navigationPath = useMemo(() => getSystemNavigationPaths(), []);

	const generalConfigurationPath = navigationPath.generalConfiguration;

	if (features == null || connectItems == null) return <SkeletonItem />;

	const navigationContent = (
		<>
			{features.hasGeneralConfiguration && (
				<MenuItem
					analytics={{ itemId: 'general-configuration' }}
					href={generalConfigurationPath}
					selectedOn={isGeneralConfigurationSelected}
				>
					{formatMessage(messages.generalConfiguration)}
				</MenuItem>
			)}
			{features.hasDarkFeatures && (
				<MenuItem
					analytics={{ itemId: 'dark-features' }}
					href={navigationPath.darkFeatures}
					selectedOn={matchUrlPathname()}
				>
					{formatMessage(messages.darkFeatures)}
				</MenuItem>
			)}
			{features.hasTroubleshootingAndSupportSection && <TroubleshootingAndSupportSection />}
			{features.hasSecuritySection && <SecuritySection />}
			{features.hasCollectors && (
				<MenuItem
					analytics={{ itemId: 'collectors' }}
					href={navigationPath.issueCollectors}
					selectedOn={matchUrlPathname()}
				>
					{formatMessage(messages.issueCollectors)}
				</MenuItem>
			)}
			<AutomationSection />
			{features.hasUserInterfaceSection && <UserInterfaceSection />}
			{features.hasImportExportSection && <ImportExportSection stack={stack} />}
			{features.hasMailSection && <MailSection />}
			{features.hasAdminHelperSection && <AdminHelperSection />}
			{features.hasSharedSection && <SharedSection />}
			{features.hasAdvancedSection && <AdvancedSection />}
			<ConnectMenu
				items={connectItems}
				overrides={{
					BackMenuItem: {
						href: '/jira/settings/system/general-configuration',
						title: formatMessage(messages.systemGroup),
					},
				}}
			/>
		</>
	);

	return (
		<ContextualAnalyticsData menuId="global-settings/system" stack={stack}>
			<SideNavigation label="">
				<NavigationHeader>
					<TitleHeader>{formatMessage(messages.systemGroup)}</TitleHeader>
				</NavigationHeader>
				{ff('jira-system-settings-cloud-to-cloud-migration') ? (
					<NestableNavigationContent stack={stack} onChange={setStack}>
						{navigationContent}
					</NestableNavigationContent>
				) : (
					<NavigationContent>{navigationContent}</NavigationContent>
				)}
			</SideNavigation>
		</ContextualAnalyticsData>
	);
};
