import React, { createContext, type ReactNode, useEffect } from 'react';
import { token } from '@atlaskit/tokens';

export const EmbedModeContext = createContext<boolean>(false);
const { Provider } = EmbedModeContext;

type Props = {
	children: ReactNode;
};

const handleEmbedLinkClick = (e: Event) => {
	const eventTarget: EventTarget | null = e.target;

	if (eventTarget && eventTarget instanceof HTMLAnchorElement && eventTarget.target !== '_blank') {
		// link
		eventTarget.target = '_blank';
	}
};

// When issue view is loaded within an iframe, it needs to follow the proper
// element elevation principles, found here: https://hello.atlassian.net/wiki/spaces/~460573267/pages/2355983526/Jira+Dark+Mode+-+UI+Guidelines#Element-elevation-basics
// Unfortunately the current elevation is set in the global-container, which is loaded before this Provider so
// we have to override the overrider to ensure that the proper elevation is set
const DarkModeMonolithStyleOverride = () => (
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
	<style
		// eslint-disable-next-line react/no-danger
		dangerouslySetInnerHTML={{
			__html: `
                    #jira-frontend {
                        background: ${token('elevation.surface.overlay', 'white')};
                    }
                `,
		}}
	/>
);

export const EmbedModeProvider = ({ children }: Props) => {
	useEffect(() => {
		window.addEventListener('click', handleEmbedLinkClick, true);
		return () => {
			window.removeEventListener('click', handleEmbedLinkClick);
		};
	}, []);

	return (
		<>
			<DarkModeMonolithStyleOverride />
			<Provider value>{children}</Provider>
		</>
	);
};
