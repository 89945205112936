import type { Dispatch } from 'redux';
import type { BatchAction } from 'redux-batched-actions';
import { dismissFlagAction } from '../store/constants';
import type { Action } from '../store/types';
import { type DismissFlag, type FlagId, DISMISS_COMMAND } from '../types';

export const dismissFlag = (flagId: FlagId): DismissFlag => ({
	command: DISMISS_COMMAND,
	id: flagId,
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (dispatch: Dispatch<Action | BatchAction>) => (action: DismissFlag) => {
	dispatch(dismissFlagAction(action.id));
};
