import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const globalSettingsMigrationsHomeRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.GLOBAL_SETTINGS_MIGRATIONS_HOME,
	path: '/jira/settings/system/migration/home',
};

export const globalSettingsMigrationsDashboardRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.GLOBAL_SETTINGS_MIGRATIONS_DASHBOARD,
	path: '/jira/settings/system/migration/dashboard',
};

export const globalSettingsMigrationsFocusPageRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.GLOBAL_SETTINGS_MIGRATIONS_FOCUS_PAGE,
	path: '/jira/settings/system/migration/:step(how-it-works|plan-configuration|choose-projects|choose-group-membership|select-customer|check-for-errors|review|view-details)/:migrationId?',
};
