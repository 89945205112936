import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type NotificationLogsType from '@atlassian/jira-spa-apps-servicedesk-notification-logs';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyNotificationLogs = lazyForPaint<typeof NotificationLogsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-servicedesk-notification-logs", jiraSpaEntry: "async-servicedesk-notification-logs" */ '@atlassian/jira-spa-apps-servicedesk-notification-logs'
		),
	),
);

export const NotificationLogs = () => (
	<LazyPage
		Page={LazyNotificationLogs}
		pageId="spa-apps/servicedesk/notification-logs"
		shouldShowSpinner
	/>
);
