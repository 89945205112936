import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ServicedeskReportsSatisfactionType from '@atlassian/jira-spa-apps-servicedesk-reports-satisfaction';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import { SATISFACTION_ROUTE_SUFFIX } from '../../constants';

export const LazyServicedeskReportsSatisfaction = lazyForPaint<
	typeof ServicedeskReportsSatisfactionType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-servicedesk-reports-satisfaction", jiraSpaEntry: "async-servicedesk-reports-satisfaction" */ '@atlassian/jira-spa-apps-servicedesk-reports-satisfaction'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyServicedeskReportsSatisfaction}
		pageId={`spa-apps/servicedesk${SATISFACTION_ROUTE_SUFFIX}}`}
		shouldShowSpinner
	/>
);
