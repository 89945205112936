import { lazyForPaint } from 'react-loosely-lazy';
import { createLayout, type Layout } from '@atlassian/jira-route-layout';
import type ResourcesCleanup from './ui/resources-invalidate';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyPlanResourcesInvalidate = lazyForPaint<typeof ResourcesCleanup>(
	() =>
		import(/* webpackChunkName: "async-plan-resources-invalidate" */ './ui/resources-invalidate'),
	{ ssr: false },
);

export const planLayout: Layout = createLayout({
	globalComponents: [LazyPlanResourcesInvalidate],
});
