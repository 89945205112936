import type { Environment } from '@atlassian/jira-shared-types';
import { useTenantContext } from '../tenant-context';

const ENVIRONMENT = {
	STAGING: 'staging',
	DEV: 'dev',
	LOCAL: 'local',
	PROD: 'prod',
} as const;

type ENVIRONMENT = (typeof ENVIRONMENT)[keyof typeof ENVIRONMENT];

export const useEnvironment = (): Environment => {
	const tenantContext = useTenantContext();
	return tenantContext?.environment || ENVIRONMENT.PROD;
};

export const useIsStagingOrDevOrLocal = (): boolean => {
	const env = useEnvironment();
	return env === ENVIRONMENT.STAGING || env === ENVIRONMENT.DEV || env === ENVIRONMENT.LOCAL;
};
