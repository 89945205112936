import { ff } from '@atlassian/jira-feature-flagging';
import { createAri } from '@atlassian/jira-platform-ari';
import { createRelayResource } from '@atlassian/jira-relay-utils';
import backlogDataQuery, {
	type aggBacklogDataQuery,
} from '@atlassian/jira-relay/src/__generated__/aggBacklogDataQuery.graphql';
import {
	browserOnlyResourceWithCondition,
	resourceWithCondition2,
} from '@atlassian/jira-router-resources-utils';
import { RESOURCE_TYPE_NEXTGEN_BACKLOG_RELAY } from '../../constants';

export const backlogRelayResource = browserOnlyResourceWithCondition(
	() => __SERVER__,
	createRelayResource<aggBacklogDataQuery>({
		type: RESOURCE_TYPE_NEXTGEN_BACKLOG_RELAY,
		getQuery: ({ match }, { tenantContext }) => ({
			parameters: backlogDataQuery,
			variables: {
				boardId: createAri({
					resourceOwner: 'jira-software',
					cloudId: tenantContext.cloudId,
					resourceType: 'board',
					resourceId: String(match.params.boardId),
				}),
				cloudId: tenantContext.cloudId,
			},
			options: { fetchPolicy: 'store-or-network' },
		}),
		captureErrors: true,
	}),
);

export const conditionalBacklogRelayResource = resourceWithCondition2(
	() => ff('jsw.perf.critical-backlog-with-relay'),
	backlogRelayResource,
);
