import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ServicedeskOrganizationV2Type from '@atlassian/jira-spa-apps-servicedesk-organization-v2';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyServicedeskOrganizationV2 = lazyForPaint<typeof ServicedeskOrganizationV2Type>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-servicedesk-organization-v2", jiraSpaEntry: "async-servicedesk-organization-v2" */ '@atlassian/jira-spa-apps-servicedesk-organization-v2'
			),
		),
);

export const ServiceDeskOrganizationV2 = () => (
	<LazyPage
		Page={LazyServicedeskOrganizationV2}
		pageId="spa-apps/servicedesk/organization-v2"
		shouldShowSpinner
	/>
);
