import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type Page from '@atlassian/jira-spa-apps-board-settings-roadmap';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyRoadmap = lazyForPaint<typeof Page>(
	() =>
		import(
			/* webpackChunkName: "async-board-settings-roadmap", jiraSpaEntry: "async-board-settings-roadmap" */ '@atlassian/jira-spa-apps-board-settings-roadmap'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazyRoadmap} pageId="board-settings-roadmap" shouldShowSpinner={false} />
);
