import {
	arjSettingsDependenciesRouteEntry,
	arjSettingsDependenciesLegacyRedirectRouteEntry,
} from './dependencies';
import {
	arjSettingsLoadingLimitsRouteEntry,
	arjSettingsLoadingLimitsLegacyRedirectRouteEntry,
} from './loading-limits';
import {
	arjSettingsPermissionsRouteEntry,
	arjSettingsPermissionsRouteLegacyRedirectRouteEntry,
} from './permissions';

export const arjSettingsRouteEntries = [
	arjSettingsDependenciesRouteEntry,
	arjSettingsDependenciesLegacyRedirectRouteEntry,
	arjSettingsLoadingLimitsRouteEntry,
	arjSettingsLoadingLimitsLegacyRedirectRouteEntry,
	arjSettingsPermissionsRouteEntry,
	arjSettingsPermissionsRouteLegacyRedirectRouteEntry,
];
