/**
 * @generated SignedSource<<2d435d64896d20bada0ea56f9c38679e>>
 * @relayHash 43bf8189b0a7cf4402292630132741e4
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 87629b64aa7ac7d7d4d664921553275ac07a44c6e87c79caf9659ff168ccd950

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { ui_growthRecommendationsInProjectList_RecommendationsInProjectListQuery } from './ui_growthRecommendationsInProjectList_RecommendationsInProjectListQuery.graphql';

const node: PreloadableConcreteRequest<ui_growthRecommendationsInProjectList_RecommendationsInProjectListQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "87629b64aa7ac7d7d4d664921553275ac07a44c6e87c79caf9659ff168ccd950",
    "metadata": {},
    "name": "ui_growthRecommendationsInProjectList_RecommendationsInProjectListQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
