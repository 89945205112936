import { createRelayResource } from '@atlassian/jira-relay-utils';
import CUSTOMER_SERVICE_PRODUCTS_QUERY, {
	type srcCustomerServiceProductsQuery,
} from '@atlassian/jira-relay/src/__generated__/srcCustomerServiceProductsQuery.graphql';

export const PRODUCTS_PAGE_SIZE = 50;

export const customerServiceProductsQueryResource =
	createRelayResource<srcCustomerServiceProductsQuery>({
		type: 'CUSTOMER_SERVICE_PRODUCTS_QUERY',
		getQuery: (_, { tenantContext }) => ({
			parameters: CUSTOMER_SERVICE_PRODUCTS_QUERY,
			variables: {
				cloudId: tenantContext.cloudId,
				first: PRODUCTS_PAGE_SIZE,
			},
			options: { fetchPolicy: 'network-only' },
		}),
	});
