import { ROUTE_NAMES } from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const PROJECT_SETTINGS_ROUTE_NAMES = new Set<string>([
	ROUTE_NAMES.CLASSIC_PROJECT_SETTINGS_DATA_CLASSIFICATIONS,
	ROUTE_NAMES.CLASSIC_PROJECT_SETTINGS_ISSUE_LAYOUT,
	ROUTE_NAMES.LEGACY_PROJECT_SETTINGS_COLLECTORS,
	ROUTE_NAMES.LEGACY_PROJECT_SETTINGS_COLLECTORS_ADD,
	ROUTE_NAMES.LEGACY_PROJECT_SETTINGS_COLLECTORS_HELP,
	ROUTE_NAMES.LEGACY_PROJECT_SETTINGS_COLLECTORS_VIEW,
	ROUTE_NAMES.LEGACY_PROJECT_SETTINGS_CONFIG,
	ROUTE_NAMES.LEGACY_PROJECT_SETTINGS_DEV,
	ROUTE_NAMES.LEGACY_PROJECT_SETTINGS_EDIT,
	ROUTE_NAMES.LEGACY_PROJECT_SETTINGS_FIELDS,
	ROUTE_NAMES.LEGACY_PROJECT_SETTINGS_FIELDS_ASSOCIATE,
	ROUTE_NAMES.LEGACY_PROJECT_SETTINGS_ISSUE_LAYOUT,
	ROUTE_NAMES.LEGACY_PROJECT_SETTINGS_ISSUE_SECURITY,
	ROUTE_NAMES.LEGACY_PROJECT_SETTINGS_ISSUE_SECURITY_ASSOCIATE,
	ROUTE_NAMES.LEGACY_PROJECT_SETTINGS_ISSUE_TYPES,
	ROUTE_NAMES.LEGACY_PROJECT_SETTINGS_ISSUE_TYPES_ASSOCIATE,
	ROUTE_NAMES.LEGACY_PROJECT_SETTINGS_NOTIFICATIONS,
	ROUTE_NAMES.LEGACY_PROJECT_SETTINGS_NOTIFICATIONS_ASSOCIATE,
	ROUTE_NAMES.LEGACY_PROJECT_SETTINGS_OPSGENIE,
	ROUTE_NAMES.LEGACY_PROJECT_SETTINGS_PEOPLE,
	ROUTE_NAMES.LEGACY_PROJECT_SETTINGS_PERMISSIONS,
	ROUTE_NAMES.LEGACY_PROJECT_SETTINGS_PERMISSIONS_ASSOCIATE,
	ROUTE_NAMES.LEGACY_PROJECT_SETTINGS_SCREENS,
	ROUTE_NAMES.LEGACY_PROJECT_SETTINGS_SCREENS_ASSOCIATE,
	ROUTE_NAMES.LEGACY_PROJECT_SETTINGS_SUMMARY,
	ROUTE_NAMES.LEGACY_PROJECT_SETTINGS_WORKFLOWS,
	ROUTE_NAMES.LEGACY_PROJECT_SETTINGS_WORKFLOWS_ASSOCIATE,
	ROUTE_NAMES.PROJECT_SETTINGS_AUTOMATION,
	ROUTE_NAMES.PROJECT_SETTINGS_COMPONENTS,
	ROUTE_NAMES.PROJECT_SETTINGS_CORE_PROFORMA_PROJECT_FORMS,
	ROUTE_NAMES.PROJECT_SETTINGS_VERSIONS,
]);

export const MENU_ID_JSM_SETTINGS = 'project/service-desk/settings';
