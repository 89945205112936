import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalLayout } from '@atlassian/jira-global-layout';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout';
import { polarisProjectLayout } from '@atlassian/jira-polaris-project-layout';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { projectCreatePermissionsResource } from '@atlassian/jira-router-resources-project-permissions';
import {
	polarisCollectionsRoute,
	polarisIdeasRoute,
	polarisOnboardingRoute,
	polarisSettingsAccessRoute,
	polarisSettingsGlobalFieldsRoute,
	polarisUnlicensedRoute,
} from '@atlassian/jira-router-routes-polaris-routes';
import CollectionsPage, { LazyCollectionsPage } from './ui/collections';
import IdeasPage, { LazyIdeasPage } from './ui/ideas';
import OnboardingPage, { LazyOnboardingPage } from './ui/onboarding';
import {
	AccessSettingsPage,
	LazyAccessSettingsPage,
	polarisProductGlobalFieldsSettingsPageEntryPoint,
} from './ui/settings';
import UnlicensedPage, { LazyUnlicensedPage } from './ui/unlicensed';

const commonResources = [projectCreatePermissionsResource];

const getPolarisProjectPermissionsResource = () => [...commonResources];

export const polarisRouteEntries: Route[] = [
	createEntry(polarisIdeasRoute, {
		ufoName: 'jpd.project',
		component: IdeasPage,
		layout: polarisProjectLayout,
		canTransitionIn: (current, next) => {
			if (
				getWillShowNav4() &&
				next?.match?.params?.projectKey &&
				current.match.params.projectKey !== next.match.params.projectKey
			) {
				return false;
			}

			return true;
		},

		navigation: {
			menuId: MENU_ID.PROJECTS,
		},

		resources: [...getNavigationResources(), ...getNavigationSidebarProjectResource()],
		forPaint: [LazyAtlassianNavigation, LazyIdeasPage],
	}),
	createEntry(polarisCollectionsRoute, {
		ufoName: 'jpd.collections',
		component: CollectionsPage,
		layout: globalLayout,
		canTransitionIn: () => true,
		resources: [...getNavigationResources(), ...getNavigationSidebarProjectResource()],
		forPaint: [LazyAtlassianNavigation, LazyCollectionsPage],
	}),
	createEntry(polarisOnboardingRoute, {
		component: OnboardingPage,
		layout: globalLayout,
		canTransitionIn: () => true,
		resources: [...getNavigationResources(), ...getPolarisProjectPermissionsResource()],
		forPaint: [LazyAtlassianNavigation, LazyOnboardingPage],
	}),
	createEntry(polarisSettingsAccessRoute, {
		component: AccessSettingsPage,
		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},

		resources: [...getNavigationResources(), ...getNavigationSidebarProductsSettingResource()],
		forPaint: [LazyAtlassianNavigation, LazyAccessSettingsPage],
	}),
	createEntry(polarisSettingsGlobalFieldsRoute, {
		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},

		resources: [...getNavigationResources(), ...getNavigationSidebarProductsSettingResource()],
		entryPoint() {
			return polarisProductGlobalFieldsSettingsPageEntryPoint;
		},
	}),
	createEntry(polarisUnlicensedRoute, {
		component: UnlicensedPage,
		layout: globalLayout,
		canTransitionIn: () => true,
		resources: [...getNavigationResources(), ...getPolarisProjectPermissionsResource()],
		forPaint: [LazyAtlassianNavigation, LazyUnlicensedPage],
	}),
];
