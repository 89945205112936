/**
 * @generated SignedSource<<47ff364127220f80ee2cb0aac27a87b0>>
 * @relayHash eceb41d09cf82770c471e96ab3b8f7a2
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 17ab1096931781dc2a30062626ff15c982338e53404e995a6edd700a8113d14b

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraContainerNavigationQueryInput = {
  projectKeyQuery?: JiraContainerNavigationByProjectKeyQueryInput | null | undefined;
  scopeId?: string | null | undefined;
};
export type JiraContainerNavigationByProjectKeyQueryInput = {
  cloudId: string;
  projectKey: string;
};
export type projectNavigation_horizontalNavigationQuery$variables = {
  cloudId: string;
  containerNavigationInput: JiraContainerNavigationQueryInput;
  first: number;
  projectIdOrKey: string;
  useContainerNavigation: boolean;
};
export type projectNavigation_horizontalNavigationQuery$data = {
  readonly jira: {
    readonly containerNavigation?: {
      readonly navigationItems?: {
        readonly " $fragmentSpreads": FragmentRefs<"tabs_businessHorizontalNav_TabsContent_navigationItemsFragment">;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"tabs_businessHorizontalNav_TabsContent_containerNavigationFragment">;
    } | null | undefined;
    readonly navigationItems?: {
      readonly edges: ReadonlyArray<{
        readonly __typename: "JiraNavigationItemEdge";
      } | null | undefined> | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"tabs_businessHorizontalNav_TabsContent_navigationItemsFragment">;
    };
  };
};
export type projectNavigation_horizontalNavigationQuery = {
  response: projectNavigation_horizontalNavigationQuery$data;
  variables: projectNavigation_horizontalNavigationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "containerNavigationInput"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectIdOrKey"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "useContainerNavigation"
},
v5 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v6 = {
  "kind": "Variable",
  "name": "projectIdOrKey",
  "variableName": "projectIdOrKey"
},
v7 = {
  "kind": "FragmentSpread",
  "name": "tabs_businessHorizontalNav_TabsContent_navigationItemsFragment"
},
v8 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v9 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v10 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "endCursor"
    },
    {
      "kind": "ScalarField",
      "name": "hasNextPage"
    }
  ]
},
v11 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "containerNavigationInput"
  }
],
v12 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v13 = [
  (v12/*: any*/)
],
v14 = [
  (v5/*: any*/),
  (v12/*: any*/),
  (v6/*: any*/)
],
v15 = {
  "kind": "ScalarField",
  "name": "id"
},
v16 = {
  "kind": "ScalarField",
  "name": "label"
},
v17 = {
  "kind": "ScalarField",
  "name": "iconUrl"
},
v18 = {
  "kind": "ScalarField",
  "name": "url"
},
v19 = {
  "kind": "ScalarField",
  "name": "styleClass"
},
v20 = {
  "kind": "LinkedField",
  "name": "node",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    (v15/*: any*/),
    {
      "kind": "ScalarField",
      "name": "isDefault"
    },
    {
      "kind": "ScalarField",
      "name": "typeKey"
    },
    {
      "kind": "TypeDiscriminator",
      "abstractKey": "__isJiraNavigationItem"
    },
    (v16/*: any*/),
    {
      "kind": "ScalarField",
      "name": "canRemove"
    },
    {
      "kind": "ScalarField",
      "name": "canSetAsDefault"
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__id"
        }
      ]
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "JiraAppSection",
          "kind": "LinkedField",
          "name": "sections",
          "plural": true,
          "selections": [
            (v16/*: any*/),
            {
              "concreteType": "JiraAppNavigationItemNestedLink",
              "kind": "LinkedField",
              "name": "links",
              "plural": true,
              "selections": [
                (v16/*: any*/),
                (v17/*: any*/),
                (v18/*: any*/),
                (v19/*: any*/)
              ]
            }
          ]
        },
        (v18/*: any*/),
        (v17/*: any*/),
        (v19/*: any*/),
        {
          "kind": "ScalarField",
          "name": "appType"
        },
        {
          "kind": "ScalarField",
          "name": "envLabel"
        }
      ],
      "type": "JiraAppNavigationItem"
    }
  ]
},
v21 = {
  "concreteType": "QueryError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    },
    {
      "kind": "LinkedField",
      "name": "extensions",
      "plural": true,
      "selections": [
        (v8/*: any*/),
        {
          "kind": "ScalarField",
          "name": "statusCode"
        }
      ]
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "name": "projectNavigation_horizontalNavigationQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "condition": "useContainerNavigation",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "alias": "navigationItems",
                    "args": [
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "concreteType": "JiraNavigationItemConnection",
                    "kind": "LinkedField",
                    "name": "__tabs_jiraNavigation__navigationItems_connection",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "concreteType": "JiraNavigationItemEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/)
                            ]
                          }
                        ]
                      },
                      (v10/*: any*/)
                    ]
                  },
                  "action": "THROW",
                  "path": "jira.navigationItems"
                }
              ]
            },
            {
              "condition": "useContainerNavigation",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "args": (v11/*: any*/),
                  "kind": "LinkedField",
                  "name": "containerNavigation",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "args": (v13/*: any*/),
                          "kind": "FragmentSpread",
                          "name": "tabs_businessHorizontalNav_TabsContent_containerNavigationFragment"
                        },
                        {
                          "args": (v13/*: any*/),
                          "concreteType": "JiraNavigationItemConnection",
                          "kind": "LinkedField",
                          "name": "navigationItems",
                          "plural": false,
                          "selections": [
                            (v7/*: any*/)
                          ]
                        }
                      ],
                      "type": "JiraContainerNavigation"
                    }
                  ]
                }
              ]
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "projectNavigation_horizontalNavigationQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "condition": "useContainerNavigation",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "alias": "navigationItems",
                "args": (v14/*: any*/),
                "concreteType": "JiraNavigationItemConnection",
                "kind": "LinkedField",
                "name": "jwmSavedViewsByProject",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraNavigationItemEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v20/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ]
                  },
                  (v21/*: any*/),
                  (v10/*: any*/)
                ]
              },
              {
                "alias": "navigationItems",
                "args": (v14/*: any*/),
                "filters": [
                  "cloudId",
                  "projectIdOrKey"
                ],
                "handle": "connection",
                "key": "tabs_jiraNavigation__navigationItems",
                "kind": "LinkedHandle",
                "name": "jwmSavedViewsByProject"
              }
            ]
          },
          {
            "condition": "useContainerNavigation",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "args": (v11/*: any*/),
                "kind": "LinkedField",
                "name": "containerNavigation",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v15/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "canEdit"
                      },
                      {
                        "args": (v13/*: any*/),
                        "concreteType": "JiraNavigationItemConnection",
                        "kind": "LinkedField",
                        "name": "navigationItems",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraNavigationItemEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              (v20/*: any*/),
                              (v8/*: any*/)
                            ]
                          },
                          (v21/*: any*/)
                        ]
                      },
                      {
                        "kind": "TypeDiscriminator",
                        "abstractKey": "__isJiraContainerNavigationResult"
                      }
                    ],
                    "type": "JiraContainerNavigation"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v15/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "17ab1096931781dc2a30062626ff15c982338e53404e995a6edd700a8113d14b",
    "metadata": {
      "connection": [
        {
          "count": "first",
          "cursor": null,
          "direction": "forward",
          "path": [
            "jira",
            "navigationItems"
          ]
        }
      ]
    },
    "name": "projectNavigation_horizontalNavigationQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "82dc77799c6173f933e98a95dd9ac624";

export default node;
