import { useEffect, useRef } from 'react';
import { useLeftSidebarController, useLeftSidebarState } from '@atlassian/jira-layout-controller';

const useAutoExpandSidebar = () => {
	const { expandLeftSidebar } = useLeftSidebarController();
	const { isCollapsed } = useLeftSidebarState();
	const sidebarExpandedRef = useRef(!isCollapsed);

	useEffect(() => {
		if (!sidebarExpandedRef.current && isCollapsed) {
			expandLeftSidebar();
			sidebarExpandedRef.current = true;
		}
	}, [expandLeftSidebar, isCollapsed]);
};

export default useAutoExpandSidebar;
