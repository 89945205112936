import {
	pageSegmentStore,
	NAVIGATION_SIDEBAR_SEGMENT_NAME,
	NAVIGATION_SIDEBAR_MARKS,
} from '@atlassian/jira-atlassian-navigation-performance-metrics';

export const setPerformanceMarkLoadStart = () => {
	const sideBarSegment = pageSegmentStore.getSegment(NAVIGATION_SIDEBAR_SEGMENT_NAME);
	sideBarSegment.mark(NAVIGATION_SIDEBAR_MARKS.loadStart);
};

export const setPerformanceMarkLoadEnd = () => {
	const sideBarSegment = pageSegmentStore.getSegment(NAVIGATION_SIDEBAR_SEGMENT_NAME);
	sideBarSegment.mark(NAVIGATION_SIDEBAR_MARKS.loadEnd);
};
