import { useMemo } from 'react';
import { createHook } from 'react-sweet-state';
import { usePageLayoutResize } from '@atlassian/jira-navigation-system';
import type { LeftSidebarState } from '../../../../common/types';
import { layoutStore } from '../../store';

const useSweetStateLeftSidebarState = createHook(layoutStore, {
	selector: (state) => ({
		...state.content.leftSidebar,
		shouldRender: !state.isChromeless,
	}),
});

type ExtenedLeftSidebarState = LeftSidebarState & {
	shouldRender: boolean;
};

export const useLeftSidebarState = (): ExtenedLeftSidebarState => {
	const [leftSidebar] = useSweetStateLeftSidebarState();
	return leftSidebar;
};

export const useLeftSidebarController = () => {
	const { expandLeftSidebar, collapseLeftSidebar } = usePageLayoutResize();
	return useMemo(
		() => ({
			collapseLeftSidebar,
			expandLeftSidebar,
		}),
		[collapseLeftSidebar, expandLeftSidebar],
	);
};
