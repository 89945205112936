import performance, { getMark } from '@atlassian/jira-common-performance';
import type { CHRReporterSummary } from './types';
import { CHRReporter } from './utils';

const SPA_SSR_RENDERED = 'ssr.spa.rendered';

export const getSSRMark = () => getMark(SPA_SSR_RENDERED)?.startTime || null;

export const getCHRInfo = ({ start }: { start: number }): CHRReporterSummary | {} => {
	// initial load only
	if (start !== 0) {
		return {};
	}

	const ssrMark = getSSRMark();
	const reporter = new CHRReporter(ssrMark);

	try {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		for (const entry of performance.getEntriesByType('resource') as PerformanceResourceTiming[]) {
			reporter.add(entry);
		}

		const chr = reporter.get();
		return chr === null ? {} : { chr };
	} catch (e: unknown) {
		return {};
	}
};
