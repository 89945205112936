import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const productsJsmConfigurationRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.PRODUCTS_JSM_CONFIGURATION,
	path: '/jira/settings/products/jira-service-management-configuration',
	exact: true,
};
