import { defineMessages } from '@atlassian/jira-intl';

// eslint-disable-next-line formatjs/no-multiple-plurals
export default defineMessages({
	breadcrumbsLabel: {
		id: 'navigation-apps.breadcrumbs.aria-label',
		defaultMessage: 'Empty Breadcrumbs item',
		description: 'An empty breadcrumb, to avoid visual jumping',
	},
});
