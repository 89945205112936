import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const rapidboardBoardRoute = {
	group: ROUTE_GROUPS.CLASSIC_SOFTWARE,
	name: ROUTE_NAMES.RAPIDBOARD_BOARD,
	path: '/secure/RapidBoard.jspa',
	query: ['rapidView'],
};
