import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const errorsNotFoundRoute = {
	group: ROUTE_GROUPS.ERRORS,
	name: ROUTE_NAMES.ERRORS_NOT_FOUND,
	path: '/jira/:projectType(software|servicedesk|core)',
};
