import { createContext, useContext } from 'react';

import type { PostOfficeEnvironmentValue } from './types';

export const defaultEnvironmentValue: PostOfficeEnvironmentValue = {
	env: 'local',
	api: {
		origin: undefined,
		pathname: undefined,
		searchParams: undefined,
	},
	remoteModule: {
		origin: undefined,
		pathname: undefined,
		searchParams: undefined,
	},
};

export const PostOfficeEnvironment =
	createContext<PostOfficeEnvironmentValue>(defaultEnvironmentValue);

export const PostOfficeEnvironmentProvider = PostOfficeEnvironment.Provider;

export const usePostOfficeEnvironment = () => useContext(PostOfficeEnvironment);
