import React from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import { OnboardingNudgeErrorBoundary } from '@atlassian/jira-onboarding-quickstart-core/src/common/ui/onboarding-nudge-error-boundary';
import Placeholder from '@atlassian/jira-placeholder';
import { FEEDBACK_NUDGE_ID } from '../../common/constants';
import type { WrapperProps } from '../../common/types';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const FeedbackNudgeLazy = lazyAfterPaint(
	() =>
		import(/* webpackChunkName: "async-feedback-onboarding-nudge" */ './index').then(
			(exportedModule) => exportedModule.FeedbackNudge,
		),
	{
		ssr: false,
	},
);

export const FeedbackNudgeAsync = ({ children }: WrapperProps) => (
	<OnboardingNudgeErrorBoundary
		id={FEEDBACK_NUDGE_ID}
		fallback={children}
		attributes={{
			nudgeId: FEEDBACK_NUDGE_ID,
		}}
	>
		<Placeholder name="feedback-onboarding-nudge" fallback={children}>
			<FeedbackNudgeLazy>{children}</FeedbackNudgeLazy>
		</Placeholder>
	</OnboardingNudgeErrorBoundary>
);

FeedbackNudgeAsync.displayName = 'FeedbackNudgeAsync';
