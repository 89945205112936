import { createStore, createContainer, createSubscriber, createHook } from 'react-sweet-state';
import type { MetaGetter } from '@atlassian/jira-get-meta';
import * as actions from '../actions';
import type { State } from '../actions/types';
import type { Actions } from '../types';

type PropType = {
	getMeta: MetaGetter;
};

const initialState: State = {
	navigationTheme: {
		shouldShowTitle: false,
		logoUrl: '',
		usesCustomColours: false,
		backgroundColor: '',
		highlightColor: '',
	},
};

const Store = createStore<State, Actions>({
	name: 'theme',
	initialState,
	actions,
});

export const ThemeSubscriber = createSubscriber<State, Actions>(Store);

export const useTheme = createHook(Store);

export const ThemeContainer = createContainer<State, Actions, PropType>(Store, {
	onInit: actions.bootstrap,
});
