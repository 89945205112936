import React, { useRef } from 'react';
import {
	NavTopPerformanceMark,
	NavTopSSRPerformanceMark,
	NAVIGATION_TOP_MARKS,
} from '@atlassian/jira-atlassian-navigation-performance-metrics';
import { EXPERIENCE_NAVIGATION_TOP_MAIN } from '@atlassian/jira-atlassian-navigation/src/common/constants';
import { AtlassianNavigationStart } from '@atlassian/jira-atlassian-navigation/src/controllers/atlassian-navigation-start';
import { JiraAtlassianNavigationSkeleton as TopNavigationSkeleton } from '@atlassian/jira-atlassian-navigation/src/ui/skeleton';
import {
	PAGE_LAYOUT_OFFSET_TOP,
	HORIZONTAL_GLOBAL_NAV_HEIGHT,
} from '@atlassian/jira-common-constants/src/page-layout';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize, layers } from '@atlassian/jira-common-styles/src';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import { useExperienceFail } from '@atlassian/jira-experience-tracker';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { useIsFullscreen } from '@atlassian/jira-fullscreen-button';
import { useTopNavState } from '@atlassian/jira-layout-controller';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout';
import { TopNavigation, UNSAFE_DO_NOT_USE_BANNER_HEIGHT } from '@atlassian/jira-navigation-system';
import Placeholder from '@atlassian/jira-placeholder';
import { RenderTracerMark } from '@atlassian/jira-render-tracer-analytics';
import UFOSegment from '@atlassian/jira-ufo-segment';
import { TopNavigationSkipLink } from '../page-container/page-container-skip-links';
import { ZIndexWrapper } from '../z-index-wrapper';
import { AsyncAtlassianNavigation } from './async-atlassian-navigation';

/* IMPORTANT:
 * When making any changes to the OLD component, please ensure that you also make the same changes to the
 * NEW component. The feature flag used is part of the Navigation Refresh work and we need to ensure that
 * we do not introduce tech debt for the roll-out by only updating the current component that is due to be
 * removed.
 *
 * If you have any concerns or questions please reach out to the #jira-navigation channel. Thanks!
 */

const APP_NAVIGATION_TRACE_KEY = 'app_navigation';
const LegacyPageNavigationStyles = () => (
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
	<style
		// eslint-disable-next-line react/no-danger
		dangerouslySetInnerHTML={{
			__html: `
                    #page-body { width: 100vw; max-width: 100%; }
                    #cloneissueprogressform { margin-top: ${gridSize * 3}px; }
                    [data-testid="Content"] {margin-top: calc(${PAGE_LAYOUT_OFFSET_TOP})!important;}
                    #view-projects-content { margin-top: calc(-1 * ${PAGE_LAYOUT_OFFSET_TOP}); } /* admin project dir hack */
                `,
		}}
	/>
);

/**
 * This test ID is used in pollinator tests. Do not change lightly.
 * It's only added once the component is rendered client-side.
 */
const TEST_ID = 'page-layout.top-nav';

const NewTopNav = () => {
	const fullscreenRef = useRef<HTMLDivElement>(null);
	const { isFullscreen } = useIsFullscreen(fullscreenRef.current);
	const { shouldRender } = useTopNavState();
	const onFail = useExperienceFail({
		experience: EXPERIENCE_NAVIGATION_TOP_MAIN,
	});
	const height = isFullscreen ? 0 : HORIZONTAL_GLOBAL_NAV_HEIGHT;

	if (!shouldRender) {
		return null;
	}

	return (
		<UFOSegment name="top-navigation">
			<JSErrorBoundary
				id="topNavigationSkipLink"
				packageName="jiraPageContainerV2"
				fallback="unmount"
			>
				<TopNavigationSkipLink />
			</JSErrorBoundary>
			<LegacyPageNavigationStyles />

			<TopNavigation isFixed height={height} testId={TEST_ID}>
				{!__SERVER__ && <AtlassianNavigationStart />}
				<JSErrorBoundary
					id="navigation.atlassian-navigation"
					packageName="jiraPageContainerV2"
					fallback="flag"
					onError={onFail}
				>
					<Placeholder name="top_navigation_skeleton" fallback={<TopNavigationSkeleton />}>
						<NavTopPerformanceMark metricKey={NAVIGATION_TOP_MARKS.bundleLoadStart} />
						<NavTopSSRPerformanceMark metricKey={NAVIGATION_TOP_MARKS.renderSsrStart} />
						<RenderTracerMark type="start" traceKey={APP_NAVIGATION_TRACE_KEY} />
						<AsyncAtlassianNavigation />
						<RenderTracerMark type="end" traceKey={APP_NAVIGATION_TRACE_KEY} />
						<NavTopSSRPerformanceMark metricKey={NAVIGATION_TOP_MARKS.renderSsrEnd} />
					</Placeholder>
				</JSErrorBoundary>
			</TopNavigation>
		</UFOSegment>
	);
};
/**
 * Implements a responsive top navigation bar that adjusts for fullscreen view and renders
 * based on application state. It ensures seamless navigation experience across different
 * sections of the application while handling errors gracefully and tracking performance.
 */
const OldTopNav = () => {
	const fullscreenRef = useRef<HTMLDivElement>(null);
	const { isFullscreen } = useIsFullscreen(fullscreenRef.current);
	const { shouldRender } = useTopNavState();
	const onFail = useExperienceFail({
		experience: EXPERIENCE_NAVIGATION_TOP_MAIN,
	});
	const height = isFullscreen ? 0 : HORIZONTAL_GLOBAL_NAV_HEIGHT;
	if (!shouldRender) {
		return null;
	}
	return (
		<UFOSegment name="top-navigation">
			<JSErrorBoundary
				id="topNavigationSkipLink"
				packageName="jiraPageContainer"
				fallback="unmount"
			>
				<TopNavigationSkipLink />
			</JSErrorBoundary>
			<LegacyPageNavigationStyles />
			<ZIndexWrapper
				zIndex={layers.navigation}
				// @ts-expect-error - TS2322 - Type '{ children: Element; zIndex: 200; top: string; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<Component<ThemedOuterStyledProps<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, any>, any, any>> & Readonly<...> & Readonly<...>'.
				top={UNSAFE_DO_NOT_USE_BANNER_HEIGHT}
				data-fullscreen-id="fullscreen.page-container-v2.topnav"
				ref={fullscreenRef}
			>
				<TopNavigation isFixed height={height} id="ak-jira-navigation">
					{!__SERVER__ && <AtlassianNavigationStart />}
					<JSErrorBoundary
						id="navigation.atlassian-navigation"
						packageName="jiraPageContainer"
						fallback="flag"
						onError={onFail}
					>
						<Placeholder name="top_navigation_skeleton" fallback={<TopNavigationSkeleton />}>
							<NavTopPerformanceMark metricKey={NAVIGATION_TOP_MARKS.bundleLoadStart} />
							<NavTopSSRPerformanceMark metricKey={NAVIGATION_TOP_MARKS.renderSsrStart} />
							<RenderTracerMark type="start" traceKey={APP_NAVIGATION_TRACE_KEY} />
							<AsyncAtlassianNavigation />
							<RenderTracerMark type="end" traceKey={APP_NAVIGATION_TRACE_KEY} />
							<NavTopSSRPerformanceMark metricKey={NAVIGATION_TOP_MARKS.renderSsrEnd} />
						</Placeholder>
					</JSErrorBoundary>
				</TopNavigation>
			</ZIndexWrapper>
		</UFOSegment>
	);
};

export const TopNav = componentWithCondition(getWillShowNav4, NewTopNav, OldTopNav);
