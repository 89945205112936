import defaults from 'lodash/defaults';
import queryString from 'query-string';
import { NAME, HIERARCHY_LEVEL } from '@atlassian/jira-admin-pages-issue-types-common';
import {
	ASC,
	DESC,
	type SortDirection,
} from '@atlassian/jira-common-constants/src/sort-directions';
import { number } from '@atlassian/jira-common-url-transform-presets';
import { fieldToParam, fieldFromParam, trimSearchFilter } from '../../../common/utils';
import { MAX_RESULTS, DEFAULT_FILTER } from '../constants';
import type { Filter, SortField, ApiQueryParams } from '../types';

const sortDirectionMap: {
	[key: string]: SortDirection;
} = {
	[ASC.toLowerCase()]: ASC,
	[DESC.toLowerCase()]: DESC,
};

const sortFieldMap: {
	[key: string]: SortField;
} = {
	[NAME.toLowerCase()]: NAME,
	[HIERARCHY_LEVEL.toLowerCase()]: HIERARCHY_LEVEL,
};

export const toUrlTransformer = (
	filter?: Filter,
): {
	[key: string]: string | undefined;
} =>
	filter
		? {
				page: filter.page !== undefined && filter.page >= 1 ? String(filter.page) : undefined,
				sortDirection: fieldToParam(filter.sortDirection),
				sortField: fieldToParam(filter.sortField),
				searchFilter: trimSearchFilter(filter.searchFilter),
			}
		: {};

export const fromUrlTransformer = (params: { [key: string]: string }): Filter => ({
	page: Math.floor(Number(number.parse(params.page))) || undefined, // pages are 1-indexed, so 0 is ok to be undefined
	searchFilter: trimSearchFilter(params.searchFilter),
	sortDirection: fieldFromParam(sortDirectionMap, params.sortDirection),
	sortField: fieldFromParam(sortFieldMap, params.sortField),
});

// Return searchFilter from qs. Return '' as default, or if the qs is malformed.
export const toSearchFilterQsTransformer = (qs: string): string => {
	const parsed = queryString.parse(qs);
	return parsed.searchFilter ? parsed.searchFilter : '';
};

// Return sortFilter from qs. Return TYPE / HIERARCHY_LEVEL as default, or if the qs is malformed.
export const toSortFieldFilterQsTransformer = (qs: string): SortField => {
	const parsed = queryString.parse(qs);
	return parsed.sortField && parsed.sortField === 'name' ? NAME : HIERARCHY_LEVEL;
};

// Return sortDirection from qs. Return DESC as default, or if the qs is malformed.
export const toSortDirectionFilterQsTransformer = (qs: string): SortDirection => {
	const parsed = queryString.parse(qs);
	return parsed.sortDirection && parsed.sortDirection === 'asc' ? ASC : DESC;
};

const apiSortByMap = {
	[ASC]: '',
	[DESC]: '-',
} as const;

export const toApiUrlTransformer = (filter: Filter): ApiQueryParams => {
	const fullFilter = defaults({}, filter, DEFAULT_FILTER);

	// this additional mapping here is needed because the BE issue type search API already supports sorting by hierarchy level using the "type" parameter. It was decided to add mapping on frontend. More details here https://atlassian.slack.com/archives/C02HN05MC8K/p1680683395059279
	const sortFieldMapping =
		fullFilter.sortField === 'hierarchy-level' ? 'type' : fullFilter.sortField;

	return {
		maxResults: MAX_RESULTS,
		// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
		startAt: (fullFilter.page - 1) * MAX_RESULTS,
		query: trimSearchFilter(fullFilter.searchFilter),
		// @ts-expect-error - TS2538 - Type 'undefined' cannot be used as an index type.
		orderBy: `${apiSortByMap[fullFilter.sortDirection]}${sortFieldMapping}`,
	};
};
