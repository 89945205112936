import { defaults as stateDefaults } from 'react-sweet-state';

if (!global._IS_JIRA_GLOBAL_SIDE_EFFECT_INIT_) {
	// eslint-disable-next-line @typescript-eslint/no-require-imports
	require('@atlaskit/css-reset');

	const isDevMode = process.env.NODE_ENV !== 'production';

	stateDefaults.devtools =
		isDevMode &&
		(() => ({
			serialize: {
				// @ts-expect-error - TS7006 - Parameter 'key' implicitly has an 'any' type. | TS7006 - Parameter 'value' implicitly has an 'any' type.
				replacer: (key, value) =>
					value instanceof HTMLElement
						? {
								data: {
									tagName: value.tagName,
									className: value.className,
									id: value.id,
									__SANITIZED__: true,
								},
								__serializedType__: 'HTMLElement',
							}
						: value,
			},
		}));
	stateDefaults.batchUpdates = !__SERVER__;
	Object.freeze(stateDefaults);

	global._IS_JIRA_GLOBAL_SIDE_EFFECT_INIT_ = true;
}
