import type { Match } from '@atlassian/jira-router';
/**
 * Safely extracts the issue key from a routing match object. It ensures graceful handling when the issue key is missing or invalid, returning null to prevent runtime errors.
 */
export const getIssueKey = (match: Match) => {
	const fromMatch =
		match && match.params && match.params.issueKey !== undefined && match.params.issueKey !== null
			? match.params.issueKey
			: null;
	return fromMatch;
};
