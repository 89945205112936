import { createGetUsersPlugin, UserIdTypes, type UserData } from '@atlassiansox/cross-flow-plugins';
import type { FetchInstanceUsersResponse } from './types';

const getUsers = async (containerId: string): Promise<UserData[]> => {
	const getUsersUrl = `/gateway/api/xflow/${containerId}/fetch-instance-users`;

	try {
		const response = await fetch(getUsersUrl, {
			credentials: 'same-origin',
		});

		if (response.ok) {
			const data: FetchInstanceUsersResponse = await response.json();

			return data.map<UserData>((value) => ({
				id: value.id,
				idType: UserIdTypes.ATLASSIAN_ACCOUNT_ID,
				displayName: value.displayName,
			}));
		}

		throw new Error(
			`Unexpected response from ${getUsersUrl} ${response.status} ${response.statusText}`,
		);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		throw new Error(`Unable to fetch ${getUsersUrl} ${error.message}`);
	}
};

export const getUsersPlugin = createGetUsersPlugin(getUsers);
