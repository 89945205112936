import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const softwareTimelineRoute = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_TIMELINE,
	path: '/jira/software/projects/:projectKey/boards/:boardId/timeline',
};
