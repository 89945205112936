import React from 'react';
import { IconButton } from '@atlaskit/button/new';
import ArrowLeftIcon from '@atlaskit/icon/core/arrow-left';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useNavigateBack } from '../../controllers/navigate-back-context';
import messages from './messages';

export const BackButton = () => {
	const { formatMessage } = useIntl();
	const { goBack } = useNavigateBack();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<Tooltip content={formatMessage(messages.tooltip)} hideTooltipOnMouseDown>
			<IconButton
				appearance="subtle"
				spacing="compact"
				icon={ArrowLeftIcon}
				label={formatMessage(messages.label)}
				onClick={() => {
					fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'sidebarBackButton');
					goBack();
				}}
			/>
		</Tooltip>
	);
};
