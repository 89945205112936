import type { Action } from 'react-sweet-state';
import type { State } from '../../utils';

export const addDocumentTitle =
	(title: symbol): Action<State> =>
	({ setState, getState }) => {
		const { contextArray } = getState();
		contextArray.push(title);
		setState({
			contextArray,
		});
	};
