import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ServicedeskQueuesIssueViewRedirectType from '@atlassian/jira-spa-apps-servicedesk-queues-issue-view-redirect';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyServicedeskQueuesIssueViewRedirect = lazyForPaint<
	typeof ServicedeskQueuesIssueViewRedirectType
>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-queues-issue-view-redirect", jiraSpaEntry: "async-servicedesk-queues-issue-view-redirect" */ '@atlassian/jira-spa-apps-servicedesk-queues-issue-view-redirect'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyServicedeskQueuesIssueViewRedirect}
		pageId="spa-apps/servicedesk/queues/issue-view-redirect"
		shouldShowSpinner
	/>
);
