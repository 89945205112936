import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireOperationalAnalytics } from '@atlassian/jira-analytics-web-react/src';
import withInjectedProps from '@atlassian/jira-common-util-with-injected-props';
import { TenantContextSubscriber } from '@atlassian/jira-tenant-context-controller';
import BreadcrumbRenderer from './view';

const fireAnalytics = (analyticsEvent: UIAnalyticsEvent) =>
	fireOperationalAnalytics(analyticsEvent, { name: 'breadcrumbRendererMounted' });

export default withInjectedProps(BreadcrumbRenderer, {
	onMount: fireAnalytics,
	TenantContextSubscriber,
});
