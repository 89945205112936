import { useCallback } from 'react';
import {
	useResourceWithCustomRouterContext,
	type UseResourceCustomContext,
} from '@atlassian/jira-resource-with-custom-router-context';
import { projectContextResourceWithCache } from '@atlassian/jira-router-resources-project-context';
import type { ProjectContext, ProjectContextData, ProjectContextProviderProps } from './types';

export type { ProjectContextData, ProjectContext } from './types';
export type { ProjectContextProviderProps };

export const useProjectContext = (customContext?: UseResourceCustomContext): ProjectContextData => {
	const { data, error, loading, update, key } = useResourceWithCustomRouterContext(
		projectContextResourceWithCache,
		customContext,
	);
	const updateCache = useCallback((d: ProjectContext) => update(() => d), [update]);

	return { data, error, loading, updateCache, key };
};

/**
 * @returns If true - project is TMP, if false - project is CMP
 */
export const useIsSimplifiedProject = (): boolean => {
	const { data } = useProjectContext();
	return data?.simplified || false;
};

export const ProjectContextProvider = ({ children }: ProjectContextProviderProps) => {
	const projectContext = useProjectContext();

	return children(projectContext);
};
