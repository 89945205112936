import { useContainerContext } from '@atlassian/jira-providers-container-context';
import { subProductFromProjectType } from '@atlassian/jira-spa-apps-common/src/analytics-sub-product';

export const useSubProduct = () => {
	const [{ data }] = useContainerContext();

	// @ts-expect-error - TS2339 - Property 'project' does not exist on type 'ContainerContext'.
	if (!data?.project) return null;

	// @ts-expect-error - TS2339 - Property 'project' does not exist on type 'ContainerContext'.
	return subProductFromProjectType(data.project.type);
};
