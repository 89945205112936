import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { chromelessLayout } from '@atlassian/jira-chromeless-layout';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { jswOnboardingResource } from '@atlassian/jira-router-resources-jsw-onboarding';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference';
import { softwareOnboardingRoute } from '@atlassian/jira-router-routes-software-onboarding-routes';
import type SoftwareOnboardingPageType from '@atlassian/jira-spa-apps-software-onboarding';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazySoftwareOnboardingPage = lazyForPaint<typeof SoftwareOnboardingPageType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-software-onboarding", jiraSpaEntry: "async-software-onboarding" */ '@atlassian/jira-spa-apps-software-onboarding'
		),
	),
);

export const SoftwareOnboardingPage = () => (
	<LazyPage
		Page={LazySoftwareOnboardingPage}
		pageId="software-onboarding"
		shouldShowSpinner={false}
	/>
);

export const softwareOnboardingRouteEntries: Route[] = [
	createEntry(softwareOnboardingRoute, {
		component: SoftwareOnboardingPage,
		layout: chromelessLayout,
		resources: [jswOnboardingResource, themePreferenceResource],
		forPaint: [LazySoftwareOnboardingPage],
		canTransitionIn: () => false,
	}),
];
