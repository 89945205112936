import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const routeNamesOpsgenieScheduleRedirectRoute = {
	group: ROUTE_GROUPS.COMPONENTS,
	name: `${ROUTE_NAMES.OPSGENIE_SCHEDULE}-redirect`,
	path: '/projects/:projectKey',
	query: ['selectedItem=com.atlassian.jira.jira-projects-plugin:opsgenie-schedule-page'],
};

export const opsgenieScheduleRoute = {
	group: ROUTE_GROUPS.COMPONENTS,
	name: ROUTE_NAMES.OPSGENIE_SCHEDULE,
	path: '/jira/:projectType(software|software/c)/projects/:projectKey/on-call-schedule',
};
