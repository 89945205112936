import { log } from '@atlassian/jira-common-util-logging';
import type { ProjectSidebarNavInfo } from '@atlassian/jira-navigation-apps-resources';
import { extractData as extractProjectBoardData } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/board/utils/extract-data';
import { extractData as extractProjectData } from '@atlassian/jira-navigation-apps-sidebar-utils';
import type { ContainerContext } from '@atlassian/jira-providers-container-context';
import type { RouterContext } from '@atlassian/jira-router';
import type { AppDescriptor } from '../types';
import type { TransformedProductContext } from './types';

// App descriptor shape cames from atlassian-connect/jsapi-v5/src/main/resources/v5/js/iframe/combined/iframe-insertion.js
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformResponse = (response: any): AppDescriptor => {
	try {
		const productContext = response.productCtx ? JSON.parse(response.productCtx) : {};
		const structuredContext = response.structuredContext
			? JSON.parse(response.structuredContext)
			: {};

		const context = {
			productContext,
			structuredContext,
			productCtx: productContext,
		};

		return {
			...response,
			...context,
			isFullPage: response.general === '1',
			user: {
				timeZone: response.timeZone,
				uid: response.uid || undefined,
				ukey: response.ukey || undefined,
				fullName: response.fullName || undefined,
			},
			targets: {
				env: {
					resize: 'both',
				},
			},
			history: {
				state: '',
			},
			autoresize: true,
			ns: response.uniqueKey,
			containerId: `embedded-${response.uniqueKey}`,
			contextPath: response.cp,
			width: response.w || response.width,
			height: response.h || response.height,
		};
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		const location = 'connect-iframe.transformer';
		log.safeErrorWithoutCustomerData(location, 'Failed to transform response', error);

		return {
			autoresize: true,
			productCtx: undefined,
			structuredContext: undefined,
			isFullPage: true,
			history: {
				state: '',
			},
			targets: {
				env: {
					resize: 'both',
				},
			},
		};
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const checkValueAndMapToNewName = (object: any, oldName: string, newName: string): any | null => {
	const newValue = object !== null && object[oldName] ? String(object[oldName]) : null;
	return newValue !== null ? { [newName]: newValue } : null;
};

export const transformContext = (
	contextData: ContainerContext | null,
): TransformedProductContext => {
	const scope = (contextData && contextData.scope) || null;
	// @ts-expect-error - TS2339 - Property 'project' does not exist on type 'ContainerContext'.
	const project = (contextData && contextData.project) || null;

	const projectId = checkValueAndMapToNewName(project, 'id', 'project.id');
	const projectKey = checkValueAndMapToNewName(project, 'key', 'project.key');
	const boardType = checkValueAndMapToNewName(scope, 'type', 'board.type');
	const boardId = checkValueAndMapToNewName(scope, 'id', 'board.id');

	return {
		...projectId,
		...projectKey,
		...boardType,
		...boardId,
	};
};
export const extractContext = (
	projectSidebarNavInfo: ProjectSidebarNavInfo | null,
): TransformedProductContext => {
	const project = (projectSidebarNavInfo && extractProjectData(projectSidebarNavInfo)) || null;
	const board =
		(projectSidebarNavInfo?.jiraSoftwareNavData &&
			extractProjectBoardData(projectSidebarNavInfo.jiraSoftwareNavData)) ||
		null;

	const projectId = checkValueAndMapToNewName(project, 'id', 'project.id');
	const projectKey = checkValueAndMapToNewName(project, 'key', 'project.key');
	const boardType = checkValueAndMapToNewName(board, 'type', 'board.type');
	const boardId = checkValueAndMapToNewName(board, 'id', 'board.id');

	return {
		...projectId,
		...projectKey,
		...boardType,
		...boardId,
	};
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformQueryParams = (contextData: ContainerContext | null, query: any) => {
	const context = transformContext(contextData);
	return {
		...query,
		...context,
	};
};

export const transformUrlToAddonAndModuleKeys = (query: RouterContext['query']) => {
	const { selectedItem } = query;
	let addonKey = '';
	let moduleKey = '';

	if (selectedItem) {
		const urlPattern = selectedItem.includes('%3A')
			? 'com.atlassian.plugins.atlassian-connect-plugin%3A'
			: 'com.atlassian.plugins.atlassian-connect-plugin:';

		[addonKey, moduleKey] = selectedItem.replace(urlPattern, '').split('__');
	}
	return { addonKey, moduleKey };
};
