// eslint-disable-next-line jira/restricted/@atlassian+jira-relay-utils
import { createRelayResource } from '@atlassian/jira-relay-utils';
import QUERY, {
	type main_rightSidePanel_RootQuery,
} from '@atlassian/jira-relay/src/__generated__/main_rightSidePanel_RootQuery.graphql';
import { JIRA_HOME_PAGE_RIGHT_SIDE_PANEL } from '../constants';

const isBroadcastWidgetEnabled = () => false;

export const jiraHomePageRightSidePanelResource =
	createRelayResource<main_rightSidePanel_RootQuery>({
		type: JIRA_HOME_PAGE_RIGHT_SIDE_PANEL,
		getQuery: (_, { tenantContext }) => ({
			parameters: QUERY,
			variables: {
				cloudId: tenantContext.cloudId,
				broadcastWidgetEnabled: isBroadcastWidgetEnabled(),
			},
			options: { fetchPolicy: 'network-only' },
		}),
	});
