import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const globalSettingsPerformanceOverviewRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.GLOBAL_SETTINGS_PERFORMANCE_OVERVIEW,
	path: '/jira/settings/system/performance/overview',
};

// This is an old route that is not used anymore. It is kept here for backward compatibility.
export const globalSettingsPerformanceMetricDetailRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.GLOBAL_SETTINGS_PERFORMANCE_METRIC_DETAIL,
	path: '/jira/settings/system/performance/metric/:metricKey',
};

export const globalSettingsPerformanceCustomFieldRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.GLOBAL_SETTINGS_PERFORMANCE_CUSTOM_FIELD,
	path: '/jira/settings/system/performance/metrics/customfield',
};

export const globalSettingsPerformanceIssueArchivalRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.GLOBAL_SETTINGS_PERFORMANCE_ISSUE,
	path: '/jira/settings/system/performance/metrics/issue',
};

export const globalSettingsPerformanceProjectRoleActorRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.GLOBAL_SETTINGS_PERFORMANCE_PROJECT_ROLE_ACTOR,
	path: '/jira/settings/system/performance/metrics/projectroleactor',
};

export const globalSettingsPerformanceProjectCleanupRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.GLOBAL_SETTINGS_PERFORMANCE_PROJECT_CLEANUP,
	path: '/jira/settings/system/performance/metrics/projectCleanup',
};
