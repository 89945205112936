import React from 'react';
import { EngagementSpotlight as SpotlightEnabler } from '@atlassiansox/engagekit-ts';
// eslint-disable-next-line jira/import/no-restricted-paths
import { useIsFullyExpanded } from '../../../controllers/is-fully-expanded';
import { withNestingOptimisation } from '../with-nesting-optimisation';
import type { EngagementSpotlightProps } from './types';

export const EngagementSpotlight = withNestingOptimisation<EngagementSpotlightProps>(
	({ engagementId }: EngagementSpotlightProps) => {
		const isFullyExpanded = useIsFullyExpanded();

		return isFullyExpanded && <SpotlightEnabler engagementId={engagementId} />;
	},
);
