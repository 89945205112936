import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	forecastAndManagement: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.reports-menu.reports.forecast-and-management',
		defaultMessage: 'Forecast & management',
		description: 'Title for forecast and management reports section',
	},
	timeTracking: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.reports-menu.reports.time-tracking',
		defaultMessage: 'Time Tracking Report',
		description: 'Time tracking link item',
	},
	userWorkload: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.reports-menu.reports.user-workload',
		defaultMessage: 'User Workload Report',
		description: 'User workload report link item',
	},
	versionWorkload: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.reports-menu.reports.version-workload',
		defaultMessage: 'Version Workload Report',
		description: 'Version workload report link item',
	},
	workloadPieChart: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.reports-menu.reports.workload-pie-chart',
		defaultMessage: 'Workload Pie Chart Report',
		description: 'Workload pie chart report link item',
	},
	other: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.reports-menu.reports.other',
		defaultMessage: 'Other',
		description: 'Title for other reports section',
	},
	itemBack: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.reports-menu.reports.item-back',
		defaultMessage: 'Back to reports',
		description: '"Back" menu item.',
	},
});
