import camelCase from 'lodash/camelCase';
import { ROUTE_NAMES } from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point';
import { fg } from '@atlassian/jira-feature-gating';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common';
import { ErrorPagesNotFound } from '@atlassian/jira-router-routes-common';
import {
	servicedeskPlaybooksListRoute,
	servicedeskPlaybooksEditRoute,
	servicedeskPlaybooksLogsRoute,
} from '@atlassian/jira-router-routes-project-settings-playbooks-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import playbookEditEntryPoint from '@atlassian/jira-spa-apps-project-settings-playbooks-edit/entrypoint';
import playbookListEntryPoint from '@atlassian/jira-spa-apps-project-settings-playbooks-list/entrypoint';
import playbookLogsEntryPoint from '@atlassian/jira-spa-apps-project-settings-playbooks-logs/entrypoint';

const listEntryPoint = createPageEntryPoint({
	main: playbookListEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

const logsEntryPoint = createPageEntryPoint({
	main: playbookLogsEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

const editEntryPoint = createPageEntryPoint({
	main: playbookEditEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

const getServiceDeskRoutesCommon = (): Pick<Route, 'layout' | 'navigation'> => ({
	layout: serviceProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
	},
});

export const playbooksRouteEntries = [
	createEntry(servicedeskPlaybooksListRoute, {
		...getServiceDeskRoutesCommon(),
		entryPoint() {
			return fg('playbooks-in-jsm') ? listEntryPoint : undefined;
		},
		resources: [...getServiceDeskSettingsResources(), getConsolidationStateResource()],
		meta: {
			reporting: {
				id: 'project-settings.playbooks.admin-list-view',
				packageName: camelCase(ROUTE_NAMES.PLAYBOOKS_LIST),
				teamName: 'ITOps Phantom',
			},
		},
		component: ErrorPagesNotFound,
		ufoName: 'playbook-list',
	}),
	createEntry(servicedeskPlaybooksLogsRoute, {
		...getServiceDeskRoutesCommon(),
		entryPoint() {
			return fg('playbooks-in-jsm') ? logsEntryPoint : undefined;
		},
		resources: [...getServiceDeskSettingsResources(), getConsolidationStateResource()],
		meta: {
			reporting: {
				id: 'project-settings.playbooks.admin-logs-view',
				packageName: camelCase(ROUTE_NAMES.PLAYBOOKS_LOGS),
				teamName: 'ITOps Phantom',
			},
		},
		component: ErrorPagesNotFound,
		ufoName: 'playbook-logs',
	}),
	createEntry(servicedeskPlaybooksEditRoute, {
		...getServiceDeskRoutesCommon(),
		entryPoint() {
			return fg('playbooks-in-jsm') ? editEntryPoint : undefined;
		},
		resources: [...getServiceDeskSettingsResources(), getConsolidationStateResource()],
		meta: {
			reporting: {
				id: 'project-settings.playbooks.admin-edit-view',
				packageName: camelCase(ROUTE_NAMES.PLAYBOOKS_EDIT),
				teamName: 'ITOps Phantom',
			},
		},
		component: ErrorPagesNotFound,
		ufoName: 'playbook-edit',
	}),
];
