export const ROUTE_GROUPS_ARCHIVE_ISSUES = 'archive-issues';
export const ROUTE_GROUPS_ARJ_SETTINGS = 'arj-settings';
export const ROUTE_GROUPS_CLASSIC_BUSINESS = 'classic-business';
export const ROUTE_GROUPS_CLASSIC_SOFTWARE = 'classic-software';
export const ROUTE_GROUPS_COMPONENTS = 'components';
export const ROUTE_GROUPS_CONNECT = 'connect';
export const ROUTE_GROUPS_DASHBOARD = 'dashboard';
export const ROUTE_GROUPS_DASHBOARDS = 'dashboards';
export const ROUTE_GROUPS_DEVOPS = 'devops';
export const ROUTE_GROUPS_DIRECTORIES = 'directories';
export const ROUTE_GROUPS_EOC = 'eoc';
export const ROUTE_GROUPS_ERRORS = 'errors';
export const ROUTE_GROUPS_GLOBAL_SETTINGS = 'global-settings';
export const ROUTE_GROUPS_HELP_CENTER_SETTINGS = 'helpcenter-settings';
export const ROUTE_GROUPS_HOME = 'home';
export const ROUTE_GROUPS_ISSUE = 'issue';
export const ROUTE_GROUPS_ISSUE_NAVIGATOR = 'issue-navigator';
export const ROUTE_GROUPS_LEGACY = 'legacy';
export const ROUTE_GROUPS_LEGACY_SERVICEDESK_SETTINGS = 'legacy-jsd-settings';
export const ROUTE_GROUPS_MARKETPLACE = 'marketplace';
export const ROUTE_GROUPS_PEOPLE_PROFILES = 'people-profiles';
export const ROUTE_GROUPS_PERSONAL_SETTINGS = 'personal-settings';
export const ROUTE_GROUPS_PLAN_SETTINGS = 'plan-settings';
export const ROUTE_GROUPS_POLARIS = 'polaris';
export const ROUTE_GROUPS_PROFORMA = 'proforma';
export const ROUTE_GROUPS_PROJECT_SETTINGS_CLASSIC = 'project-settings-classic';
export const ROUTE_GROUPS_PROJECT_SETTINGS_CORE = 'project-settings-core';
export const ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK = 'project-settings-servicedesk';
export const ROUTE_GROUPS_PROJECT_SETTINGS_SOFTWARE = 'project-settings-software';
export const ROUTE_GROUPS_PROJECT_SETTINGS_SOFTWARE_BOARDS = 'project-settings-software-boards';
export const ROUTE_GROUPS_REDIRECTS = 'redirects';
export const ROUTE_GROUPS_SERVICEDESK = 'servicedesk';
export const ROUTE_GROUPS_SERVICES = 'services';
export const ROUTE_GROUPS_SOFTWARE = 'software';
export const ROUTE_GROUPS_VERSIONS = 'versions';
export const ROUTE_NAMES_ARCHIVED_ISSUES_DIRECTORY = 'archived-issues-directory';
export const ROUTE_NAMES_ARCHIVED_PLANS_DIRECTORY = 'archived-plans-directory';
export const ROUTE_NAMES_ARCHIVED_PROJECTS_DIRECTORY = 'archived-projects-directory';
export const ROUTE_NAMES_ARCHIVED_PROJECT_DIRECT_LINK = 'archived-project-direct-link';
export const ROUTE_NAMES_BOARDS_DIRECTORY = 'boards-directory';
export const ROUTE_NAMES_BROWSE_PROJECT_REDIRECT = 'browse-project-redirect';
export const ROUTE_NAMES_BUSINESS_BOARD = 'next-gen-business-board';
export const ROUTE_NAMES_BUSINESS_CLASSIC_REPORTS = 'business-classic-reports';
export const ROUTE_NAMES_BUSINESS_REPORTS = 'next-gen-business-reports';
export const ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_CALENDAR = `${ROUTE_GROUPS_CLASSIC_BUSINESS}-overview-calendar`;
export const ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_REDIRECT = `${ROUTE_GROUPS_CLASSIC_BUSINESS}-overview-redirect`;
export const ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_SUMMARY = `${ROUTE_GROUPS_CLASSIC_BUSINESS}-overview-summary`;
export const ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_TIMELINE = `${ROUTE_GROUPS_CLASSIC_BUSINESS}-overview-timeline`;
export const ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_APPROVALS = `${ROUTE_GROUPS_CLASSIC_BUSINESS}-approvals`;
export const ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_ATTACHMENTS = `${ROUTE_GROUPS_CLASSIC_BUSINESS}-attachments`;
export const ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_BOARD = `${ROUTE_GROUPS_CLASSIC_BUSINESS}-board`;
export const ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_BOARD_EMBED = `${ROUTE_GROUPS_CLASSIC_BUSINESS}-board-embed`;
export const ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_CALENDAR = `${ROUTE_GROUPS_CLASSIC_BUSINESS}-calendar`;
export const ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_CALENDAR_EMBED = `${ROUTE_GROUPS_CLASSIC_BUSINESS}-calendar-embed`;
export const ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FILES = `${ROUTE_GROUPS_CLASSIC_BUSINESS}-files`;
export const ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM = `${ROUTE_GROUPS_CLASSIC_BUSINESS}-form`;
export const ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM_BUILDER = `${ROUTE_GROUPS_CLASSIC_BUSINESS}-form-builder`;
export const ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM_SUBMIT = `${ROUTE_GROUPS_CLASSIC_BUSINESS}-form-submit`;
export const ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM_SUBMIT_EMBED = `${ROUTE_GROUPS_CLASSIC_BUSINESS}-form-submit-embed`;
export const ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_LIST = `${ROUTE_GROUPS_CLASSIC_BUSINESS}-list`;
export const ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_LIST_EMBED = `${ROUTE_GROUPS_CLASSIC_BUSINESS}-list-embed`;
export const ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_SAMPLE_ONBOARDING = `${ROUTE_GROUPS_CLASSIC_BUSINESS}-sample-onboarding`;
export const ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_SHORTCUT = `${ROUTE_GROUPS_CLASSIC_BUSINESS}-shortcut`;
export const ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_SUMMARY = `${ROUTE_GROUPS_CLASSIC_BUSINESS}-summary`;
export const ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_SUMMARY_EMBED = `${ROUTE_GROUPS_CLASSIC_BUSINESS}-summary-embed`;
export const ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_TIMELINE = `${ROUTE_GROUPS_CLASSIC_BUSINESS}-timeline`;
export const ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_TIMELINE_EMBED = `${ROUTE_GROUPS_CLASSIC_BUSINESS}-timeline-embed`;
export const ROUTE_NAMES_CLASSIC_BUSINESS_REDIRECT = `${ROUTE_GROUPS_CLASSIC_BUSINESS}-redirect`;
export const ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_CODE = `${ROUTE_GROUPS_CLASSIC_SOFTWARE}-code`;
export const ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_CORE_PROFORMA_FORMS = `${ROUTE_GROUPS_PROJECT_SETTINGS_CORE}-proforma-project-forms`;
export const ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_DATA_CLASSIFICATIONS = `${ROUTE_GROUPS_PROJECT_SETTINGS_CLASSIC}-data-classifications`;
export const ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_DETAILS = `${ROUTE_GROUPS_CLASSIC_SOFTWARE}-details`;
export const ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_FEATURES = `${ROUTE_GROUPS_CLASSIC_SOFTWARE}-features`;
export const ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_ISSUE_LAYOUT = `${ROUTE_GROUPS_PROJECT_SETTINGS_CLASSIC}-issue-layout`;
export const ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_NOTIFICATION_EMAIL = `${ROUTE_GROUPS_CLASSIC_SOFTWARE}-notifications-notification-email`;
export const ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_OPSGENIE = `${ROUTE_GROUPS_CLASSIC_SOFTWARE}-link-opsgenie`;
export const ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_SOFTWARE_DETAILS = `${ROUTE_GROUPS_CLASSIC_SOFTWARE}-details`;
export const ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_SOFTWARE_NOTIFICATIONS = `${ROUTE_GROUPS_CLASSIC_SOFTWARE}-notifications`;
export const ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_SOFTWARE_NOTIFICATIONS_ISSUE_LOGS = `${ROUTE_GROUPS_CLASSIC_SOFTWARE}-notifications-issue-logs`;
export const ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_SOFTWARE_PROFORMA_EDIT_FORMS = `${ROUTE_GROUPS_PROJECT_SETTINGS_SOFTWARE}-proforma-edit-forms`;
export const ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_SOFTWARE_PROFORMA_FORMS = `${ROUTE_GROUPS_PROJECT_SETTINGS_SOFTWARE}-proforma-project-forms`;
export const ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_TOOLCHAIN = `${ROUTE_GROUPS_PROJECT_SETTINGS_SOFTWARE}-toolchain-company-managed`;
export const ROUTE_NAMES_COMPASS_PRODUCT_SETTINGS = 'compass-product-settings';
export const ROUTE_NAMES_CONFLUENCE_PRODUCT_SETTINGS = 'confluence-product-settings';
export const ROUTE_NAMES_CONNECT_GENERAL = 'connect-general';
export const ROUTE_NAMES_CONNECT_GENERAL_INVALID = 'connect-general-invalid';
export const ROUTE_NAMES_CONNECT_GENERAL_SERVICE_DESK_PROJECT =
	'connect-general-service-desk-project';
export const ROUTE_NAMES_CONNECT_PROJECT = 'connect-project';
export const ROUTE_NAMES_CREATE_PROJECT = 'create-project';
export const ROUTE_NAMES_CUSTOMER_SERVICE_ACCOUNTS = 'customer-service-accounts';
export const ROUTE_NAMES_DASHBOARD = 'dashboard';
export const ROUTE_NAMES_DASHBOARDS_DIRECTORY = 'dashboards-directory';
export const ROUTE_NAMES_DASHBOARDS_DIRECTORY_SECURE = 'dashboards-directory-secure';
export const ROUTE_NAMES_DASHBOARD_EDIT = 'dashboard-edit';
export const ROUTE_NAMES_DASHBOARD_SELECTED = 'dashboard-selected';
export const ROUTE_NAMES_DASHBOARD_VIEW = 'dashboard-view';
export const ROUTE_NAMES_DASHBOARD_VIEW_EMBED = 'dashboard-view-embed';
export const ROUTE_NAMES_DASHBOARD_WALLBOARD = 'dashboard-wallboard';
export const ROUTE_NAMES_DEVOPS_CONNECTED_PROJECT = 'devops-connected-project';
export const ROUTE_NAMES_EOC_ALERTS = 'eoc-alerts';
export const ROUTE_NAMES_EOC_ALERT_DETAIL = 'eoc-alert-detail';
export const ROUTE_NAMES_EOC_ALERT_ISSUE_SYNCING = 'eoc-alert-issue-syncing';
export const ROUTE_NAMES_EOC_ALERT_ISSUE_SYNCING_CREATE = 'eoc-alert-issue-syncing-create';
export const ROUTE_NAMES_EOC_ALERT_ISSUE_SYNCING_DETAIL = 'eoc-alert-issue-syncing-detail';
export const ROUTE_NAMES_EOC_API_KEY_MANAGEMENT = 'eoc-api-key-management';
export const ROUTE_NAMES_EOC_ASSIGN_CUSTOM_ROLES = 'eoc-assign-custom-roles';
export const ROUTE_NAMES_EOC_AUTOMATIONS = 'eoc-automations';
export const ROUTE_NAMES_EOC_CUSTOM_ROLES = 'eoc-custom-roles';
export const ROUTE_NAMES_EOC_GLOBAL_ALERTS = 'eoc-global-alerts';
export const ROUTE_NAMES_EOC_GLOBAL_ALERT_DETAIL = 'eoc-global-alert-detail';
export const ROUTE_NAMES_EOC_GLOBAL_GROUP_DETAIL = 'eoc-global-group-detail';
export const ROUTE_NAMES_EOC_GLOBAL_MAINTENANCE = 'eoc-global-maintenance';
export const ROUTE_NAMES_EOC_GLOBAL_REPORTS = 'eoc-global-reports';
export const ROUTE_NAMES_EOC_HEARTBEATS = 'eoc-heartbeats';
export const ROUTE_NAMES_EOC_INCOMING_CALL = 'eoc-incoming-call';
export const ROUTE_NAMES_EOC_INCOMING_CALL_DETAIL = 'eoc-incoming-call-detail';
export const ROUTE_NAMES_EOC_INTEGRATIONS = 'eoc-integrations';
export const ROUTE_NAMES_EOC_INTEGRATION_CREATE = 'eoc-integration-create';
export const ROUTE_NAMES_EOC_INTEGRATION_DETAIL = 'eoc-integration-detail';
export const ROUTE_NAMES_EOC_INTEGRATION_LIST = 'eoc-integration-list';
export const ROUTE_NAMES_EOC_INTEGRATION_LOG = 'eoc-integration-log';
export const ROUTE_NAMES_EOC_MAINTENANCE = 'eoc-maintenance';
export const ROUTE_NAMES_EOC_ON_CALL = 'eoc-on-call';
export const ROUTE_NAMES_EOC_OVERVIEW = 'eoc-overview';
export const ROUTE_NAMES_EOC_PERMISSIONS = 'eoc-permissions';
export const ROUTE_NAMES_EOC_POLICIES = 'eoc-policies';
export const ROUTE_NAMES_EOC_REPORTS = 'eoc-reports';
export const ROUTE_NAMES_EOC_REPORTS_TEAM = 'eoc-reports-team';
export const ROUTE_NAMES_EOC_REPORTS_UNSUBSCRIBE = 'eoc-reports-unsubscribe';
export const ROUTE_NAMES_EOC_ROLE_BASED_NOTIFICATIONS = 'eoc-role-based-notifications';
export const ROUTE_NAMES_EOC_ROLE_BASED_NOTIFICATIONS_DETAIL =
	'eoc-role-based-notifications-detail';
export const ROUTE_NAMES_EOC_SETTINGS = 'eoc-settings';
export const ROUTE_NAMES_EOC_TEAM_ALERTS = 'eoc-team-alerts';
export const ROUTE_NAMES_EOC_TEAM_ALERT_DETAILS = 'eoc-team-alert-detail';
export const ROUTE_NAMES_EOC_TEAM_ALERT_ISSUE_SYNCING = 'eoc-team-alert-issue-syncing';
export const ROUTE_NAMES_EOC_TEAM_ALERT_ISSUE_SYNCING_CREATE =
	'eoc-team-alert-issue-syncing-create';
export const ROUTE_NAMES_EOC_TEAM_ALERT_ISSUE_SYNCING_DETAIL =
	'eoc-team-alert-issue-syncing-detail';
export const ROUTE_NAMES_EOC_TEAM_CUSTOMER_LOGS = 'eoc-team-customer-logs';
export const ROUTE_NAMES_EOC_TEAM_CUSTOMER_LOGS_DOWNLOAD = 'eoc-team-customer-logs-download';
export const ROUTE_NAMES_EOC_TEAM_GROUP_DETAIL = 'eoc-team-group-detail';
export const ROUTE_NAMES_EOC_TEAM_INTEGRATIONS = 'eoc-team-integrations';
export const ROUTE_NAMES_EOC_TEAM_INTEGRATION_CREATE = 'eoc-team-integration-create';
export const ROUTE_NAMES_EOC_TEAM_INTEGRATION_DETAIL = 'eoc-team-integration-detail';
export const ROUTE_NAMES_EOC_TEAM_INTEGRATION_LIST = 'eoc-team-integration-list';
export const ROUTE_NAMES_EOC_TEAM_INTEGRATION_LOG = 'eoc-team-integration-log';
export const ROUTE_NAMES_EOC_TEAM_POLICIES = 'eoc-team-policies';
export const ROUTE_NAMES_EOC_UNLOCK_OPERATIONS = 'eoc-unlock-operations';
export const ROUTE_NAMES_EOC_WHO_IS_ON_CALL = 'eoc-who-is-on-call';
export const ROUTE_NAMES_ERRORS_NOT_FOUND = 'errors-not-found';
export const ROUTE_NAMES_EXTERNAL_IMPORT = 'external-import';
export const ROUTE_NAMES_FILTERS_DIRECTORY = 'filters-directory';
export const ROUTE_NAMES_FILTERS_DIRECTORY_SECURE = 'filters-directory-secure';
export const ROUTE_NAMES_FORGE_ADMIN = 'forge-spa-admin';
export const ROUTE_NAMES_FORGE_ADMIN_SETTINGS = 'forge-spa-admin-settings';
export const ROUTE_NAMES_FORGE_FIELD_CONTEXTUAL_CONFIGURATION = 'forge-spa-field-context-config';
export const ROUTE_NAMES_FORGE_GLOBAL = 'forge-spa-global';
export const ROUTE_NAMES_FORGE_PROJECT = 'forge-spa-project';
export const ROUTE_NAMES_FORGE_SERVICEDESK_QUEUE = 'forge-spa-servicedesk-queue';
export const ROUTE_NAMES_GLOBAL_ADMIN_HELPER_EMAIL_NOTIFICATIONS_LOG =
	'global-admin-helper-email-notifications-log';
export const ROUTE_NAMES_GLOBAL_ISSUE_NAVIGATOR = 'global-issue-navigator';
export const ROUTE_NAMES_GLOBAL_ISSUE_NAVIGATOR_BROWSE_ISSUE_REDIRECT =
	'global-issue-navigator-browse-issue-redirect';
export const ROUTE_NAMES_GLOBAL_MAIL_SETTINGS = 'global-mail-settings';
export const ROUTE_NAMES_GLOBAL_SETTINGS_CONTACT_ADMINISTRATOR =
	'global-settings-contact-administrator';
export const ROUTE_NAMES_GLOBAL_SETTINGS_CUSTOM_FIELDS =
	'global-settings-issues-custom-fields-view';
export const ROUTE_NAMES_GLOBAL_SETTINGS_CUSTOM_FIELDS_ASSOCIATE =
	'global-settings-issues-custom-fields-associate';
export const ROUTE_NAMES_GLOBAL_SETTINGS_CUSTOM_FIELDS_CONFIGURE =
	'global-settings-issues-custom-fields-config';
export const ROUTE_NAMES_GLOBAL_SETTINGS_CUSTOM_FIELDS_DELETE =
	'global-settings-issues-custom-fields-delete';
export const ROUTE_NAMES_GLOBAL_SETTINGS_CUSTOM_FIELDS_EDIT =
	'global-settings-issues-custom-fields-edit';
export const ROUTE_NAMES_GLOBAL_SETTINGS_CUSTOM_FIELDS_MANAGE =
	'global-settings-issues-custom-fields-manage';
export const ROUTE_NAMES_GLOBAL_SETTINGS_CUSTOM_FIELDS_TRANSLATE =
	'global-settings-issues-custom-fields-translate';
export const ROUTE_NAMES_GLOBAL_SETTINGS_EDIT_PERMISSION_SCHEMES =
	'global-settings-issues-edit-permissions-schemes';
export const ROUTE_NAMES_GLOBAL_SETTINGS_FIELD_LAYOUTS = 'global-settings-issues-fieldlayouts-view';
export const ROUTE_NAMES_GLOBAL_SETTINGS_FIELD_LAYOUTS_CONFIGURE =
	'global-settings-issues-fieldview';
export const ROUTE_NAMES_GLOBAL_SETTINGS_FIELD_LAYOUTS_COPY =
	'global-settings-issues-fieldlayouts-copy';
export const ROUTE_NAMES_GLOBAL_SETTINGS_FIELD_LAYOUT_SCHEMES =
	'global-settings-issues-fieldlayouts-schemes-view';
export const ROUTE_NAMES_GLOBAL_SETTINGS_FIELD_LAYOUT_SCHEMES_CONFIGURE =
	'global-settings-issues-fieldlayouts-schemes-config';
export const ROUTE_NAMES_GLOBAL_SETTINGS_FIELD_LAYOUT_SCHEMES_COPY =
	'global-settings-issues-fieldlayouts-schemes-copy';
export const ROUTE_NAMES_GLOBAL_SETTINGS_FIELD_LAYOUT_SCHEMES_DELETE =
	'global-settings-issues-fieldlayouts-schemes-delete';
export const ROUTE_NAMES_GLOBAL_SETTINGS_FIELD_LAYOUT_SCHEMES_EDIT =
	'global-settings-issues-fieldlayouts-schemes-edit';
export const ROUTE_NAMES_GLOBAL_SETTINGS_FIELD_LAYOUT_SCHEMES_EDIT_ENTRY =
	'global-settings-issues-fieldlayouts-schemes-entity';
export const ROUTE_NAMES_GLOBAL_SETTINGS_FIELD_LAYOUT_SCHEMES_VIEW =
	'global-settings-issues-fieldlayouts-config';
export const ROUTE_NAMES_GLOBAL_SETTINGS_FORM_DATA_CONNECTIONS =
	'global-settings-issues-form-data-connections';
export const ROUTE_NAMES_GLOBAL_SETTINGS_GLOBAL_PERMISSIONS = 'global-settings-global-permissions';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_LINK_TYPES =
	'global-settings-issues-linktypes-view';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_LINK_TYPES_ACTIVATE =
	'global-settings-issues-linktypes-activate';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_LINK_TYPES_DEACTIVATE =
	'global-settings-issues-linktypes-deactivate';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_LINK_TYPES_DELETE =
	'global-settings-issues-linktypes-delete';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_LINK_TYPES_EDIT =
	'global-settings-issues-linktypes-edit';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_NEW_ISSUE_SEARCH =
	'global-settings-issues-new-issue-search';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_NEW_ISSUE_VIEW =
	'global-settings-issues-new-issue-view';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_NOTIFICATION_ADD =
	'global-settings-issues-notification-add';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_NOTIFICATION_DELETE =
	'global-settings-issues-notification-delete';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_NOTIFICATION_EDIT =
	'global-settings-issues-notification-edit';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_NOTIFICATION_SCHEME_ADD =
	'global-settings-issues-notification-scheme-add';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_NOTIFICATION_SCHEME_DELETE =
	'global-settings-issues-notification-scheme-delete';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_NOTIFICATION_SCHEME_EDIT =
	'global-settings-issues-notification-scheme-edit';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_NOTIFICATION_VIEW =
	'global-settings-issues-notification-view';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_PERMISSIONS_SCHEMES =
	'global-settings-issues-permissions-schemes-view';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_PERMISSIONS_SCHEMES_ADD =
	'global-settings-issues-permissions-schemes-add';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_PERMISSIONS_SCHEMES_DELETE =
	'global-settings-issues-permissions-schemes-delete';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_PERMISSIONS_SCHEMES_EDIT =
	'global-settings-issues-permissions-schemes-edit';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_PRIORITIES = 'global-settings-issues-priorities';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_PRIORITIES_DELETE =
	'global-settings-issues-priorities-delete';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_PRIORITIES_EDIT =
	'global-settings-issues-priorities-edit';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_PRIORITIES_TRANSLATE =
	'global-settings-issues-priorities-translations';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_PRIORITY_SCHEMES =
	'global-settings-issues-priority-schemes';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_RESOLUTIONS = 'global-settings-issues-resolutions';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_RESOLUTIONS_DEFAULT =
	'global-settings-issues-resolutions-default';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_RESOLUTIONS_DELETE =
	'global-settings-issues-resolutions-delete';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_RESOLUTIONS_EDIT =
	'global-settings-issues-resolutions-edit';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_RESOLUTIONS_TRANSLATE =
	'global-settings-issues-resolutions-translations';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_SECURITY_EDIT =
	'global-settings-issues-security-edit';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_SECURITY_LEVEL_ADD =
	'global-settings-issues-security-level-add';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_SECURITY_LEVEL_DELETE =
	'global-settings-issues-security-level-delete';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_SECURITY_LEVEL_EDIT =
	'global-settings-issues-security-level-edit';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_SECURITY_SCHEMES =
	'global-settings-issues-security-schemes';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_SECURITY_SCHEMES_ADD =
	'global-settings-issues-security-schemes-add';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_SECURITY_SCHEMES_DELETE =
	'global-settings-issues-security-schemes-delete';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_SECURITY_SCHEMES_EDIT =
	'global-settings-issues-security-schemes-edit';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_STATUSES = 'global-settings-issues-statuses';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_STATUSES_TRANSLATE =
	'global-settings-issues-statuses-translations';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_FIELD_CONFIGURE =
	'global-settings-issues-field-configure';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_FIELD_CONFIGURE_EDIT =
	'global-settings-issues-field-configure-edit';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_FIELD_SCHEMES =
	'global-settings-issues-field-schemes-view';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_FIELD_SCHEMES_ADD =
	'global-settings-issues-field-schemes-add';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_FIELD_SCHEMES_CONFIGURE =
	'global-settings-issues-field-schemes-configure';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_FIELD_SCHEMES_COPY =
	'global-settings-issues-field-schemes-copy';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_FIELD_SCHEMES_DELETE =
	'global-settings-issues-field-schemes-delete';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_FIELD_SCHEMES_EDIT =
	'global-settings-issues-field-schemes-edit';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_FIELD_SCHEMES_EDIT_ITEM =
	'global-settings-issues-field-schemes-edit-item';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_FIELD_SCREENS =
	'global-settings-issues-field-screens';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_FIELD_SCREENS_COPY =
	'global-settings-issues-field-copy';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_FIELD_SCREENS_DELETE =
	'global-settings-issues-field-delete';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_FIELD_SCREENS_EDIT =
	'global-settings-issues-field-edit';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_HIERARCHY = 'global-settings-issues-hierarchy-view';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_HIERARCHY_REDIRECT =
	'global-settings-issues-hierarchy-redirect';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_SUBTASKS = 'global-settings-issues-subtasks-manage';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_SUBTASKS_DISABLE =
	'global-settings-issues-subtasks-disable';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_SUBTASKS_EDIT =
	'global-settings-issues-subtasks-edit';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_SUBTASKS_TRANSLATE =
	'global-settings-issues-subtasks-tranlations';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TIME_TRACKING =
	'global-settings-issues-time-tracking';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES = 'global-settings-issues-types-view';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES_DELETE = 'global-settings-issues-types-delete';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES_EDIT = 'global-settings-issues-types-edit';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES_SCHEMES =
	'global-settings-issues-types-schemes-manage';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES_SCHEMES_ADD_OR_COPY =
	'global-settings-issues-types-schemes-option';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES_SCHEMES_ASSOCIATE =
	'global-settings-issues-types-schemes-associate-default';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES_SCHEMES_DELETE =
	'global-settings-issues-types-schemes-option-delete';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES_SCREEN_SCHEMES =
	'global-settings-issues-types-screen-schemes-view';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES_SCREEN_SCHEMES_CONFIGURE =
	'global-settings-issues-types-screen-schemes-configure';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES_SCREEN_SCHEMES_COPY =
	'global-settings-issues-types-screen-schemes-copy';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES_SCREEN_SCHEMES_DELETE =
	'global-settings-issues-types-screen-schemes-delete';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES_SCREEN_SCHEMES_EDIT =
	'global-settings-issues-types-screen-schemes-edit';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES_SCREEN_SCHEMES_ENTITY =
	'global-settings-issues-types-screen-schemes-entity';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES_TRANSLATE =
	'global-settings-issues-types-translations';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_WORKFLOW = 'global-settings-issues-workflows-pages';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_WORKFLOW_EDIT =
	'global-settings-issues-workflow-edit';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_WORKFLOW_SCHEMES =
	'global-settings-issues-workflows-schemes-view';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_WORKFLOW_SCHEMES_EDIT =
	'global-settings-issues-workflows-schemes-edit';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_WORKFLOW_TRANSITION =
	'global-settings-issues-workflow-transition';
export const ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_WORKFLOW_TRANSITION_META_ATTRIBUTES =
	'global-settings-issues-workflow-transition-meta-attributes';
export const ROUTE_NAMES_GLOBAL_SETTINGS_MIGRATIONS_DASHBOARD =
	'global-settings-migrations-dashboard';
export const ROUTE_NAMES_GLOBAL_SETTINGS_MIGRATIONS_FOCUS_PAGE =
	'global-settings-migrations-focus-page';
export const ROUTE_NAMES_GLOBAL_SETTINGS_MIGRATIONS_HOME = 'global-settings-migrations-home';
export const ROUTE_NAMES_GLOBAL_SETTINGS_PERFORMANCE_CUSTOM_FIELD =
	'global-settings-performance-custom-field';
export const ROUTE_NAMES_GLOBAL_SETTINGS_PERFORMANCE_ISSUE = 'global-settings-performance-issue';
export const ROUTE_NAMES_GLOBAL_SETTINGS_PERFORMANCE_METRIC_DETAIL =
	'global-settings-performance-metric-detail';
export const ROUTE_NAMES_GLOBAL_SETTINGS_PERFORMANCE_OVERVIEW =
	'global-settings-performance-overview';
export const ROUTE_NAMES_GLOBAL_SETTINGS_PERFORMANCE_PROJECT_CLEANUP =
	'global-settings-performance-project-cleanup';
export const ROUTE_NAMES_GLOBAL_SETTINGS_PERFORMANCE_PROJECT_ROLE_ACTOR =
	'global-settings-performance-project-role-actor';
export const ROUTE_NAMES_GLOBAL_SETTINGS_PERMISSION_SCHEMES = 'global-settings-permissions-schemes';
export const ROUTE_NAMES_GLOBAL_SETTINGS_PRODUCTS_APPLICATION_LINKS =
	'global-settings-products-applinks';
export const ROUTE_NAMES_GLOBAL_SETTINGS_PRODUCTS_DVCS = 'global-settings-products-dvcs';
export const ROUTE_NAMES_GLOBAL_SETTINGS_PRODUCTS_SDMAIL = 'global-settings-products-sdmail';
export const ROUTE_NAMES_GLOBAL_SETTINGS_PRODUCTS_STATUS_PAGE =
	'global-settings-products-jsd-status';
export const ROUTE_NAMES_GLOBAL_SETTINGS_PROJECTS_CATEGORIES_DELETE =
	'global-settings-projects-categories-delete';
export const ROUTE_NAMES_GLOBAL_SETTINGS_PROJECTS_CATEGORIES_EDIT =
	'global-settings-projects-categories-edit';
export const ROUTE_NAMES_GLOBAL_SETTINGS_PROJECTS_CATEGORIES_VIEW =
	'global-settings-projects-categories-view';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SHARED_DASHBOARDS =
	'global-settings-system-shared-dashboards';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_ADMIN_NOTIFICATION_HELPER =
	'global-settings-system-helper-notification';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_ADMIN_PERMISSION_HELPER =
	'global-settings-system-helper-permission';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_ADVANCED_ATTACHMENTS =
	'global-settings-system-attachments';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_ADVANCED_EVENTS = 'global-settings-system-events';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_ADVANCED_EVENTS_ADD =
	'global-settings-system-events-add';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_ADVANCED_EVENTS_DELETE =
	'global-settings-system-events-delete';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_ADVANCED_EVENTS_EDIT =
	'global-settings-system-events-edit';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_ADVANCED_LEXORANK_MANAGEMENT =
	'global-settings-system-lexorank';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_ADVANCED_SERVICES =
	'global-settings-system-services';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_ADVANCED_WEBHOOKS =
	'global-settings-system-webhooks';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_BULK_MAIL = 'global-settings-system-bulk-mail';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_GENERAL_CONFIGURATION_ADVANCED_SETTINGS =
	'global-settings-system-application-advanced';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_GENERAL_CONFIGURATION_EDIT_SETTINGS =
	'global-settings-system-application-edit';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_GLOBAL_PERMISSIONS =
	'global-settings-system-global-permissions';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_IMPORT_EXPORT_BACKUP_MANAGER =
	'global-settings-system-export';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_IMPORT_EXPORT_CLOUD_OR_SERVER_IMPORT =
	'global-settings-system-import-cloud';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_IMPORT_EXPORT_EXTERNAL_IMPORT =
	'global-settings-system-import-external';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_IMPORT_EXPORT_EXTERNAL_IMPORT_CSV =
	'global-settings-system-import-csv';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_IMPORT_EXPORT_EXTERNAL_IMPORT_JSON =
	'global-settings-system-import-json';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_IMPORT_EXPORT_EXTERNAL_IMPORT_TRELLO =
	'global-settings-system-import-trello';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_ISSUE_COLLECTORS =
	'global-settings-system-collectors';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_MAIL_DELETE_MAIL_HANDLER =
	'global-settings-system-delete-mail-handler';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_MAIL_INCOMING_MAIL =
	'global-settings-system-mail-incoming';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_MAIL_OUTGOING_MAIL =
	'global-settings-system-mail-outgoing';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_MAIL_SEND_MAIL = 'global-settings-system-mail-send';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_SECURITY_GLOBAL_PERMISSIONS_OLD =
	'global-settings-system-permissions';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_SECURITY_PROJECT_ROLES =
	'global-settings-system-roles-view';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_SECURITY_PROJECT_ROLES_ACTORS =
	'global-settings-system-roles-actors';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_SECURITY_PROJECT_ROLES_ACTORS_GROUPS =
	'global-settings-system-roles-actors-groups';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_SECURITY_PROJECT_ROLES_ACTORS_USERS =
	'global-settings-system-roles-actors-users';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_SECURITY_PROJECT_ROLES_DELETE =
	'global-settings-system-roles-delete';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_SECURITY_PROJECT_ROLES_EDIT =
	'global-settings-system-roles-edit';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_SECURITY_PROJECT_ROLES_USAGE =
	'global-settings-system-roles-usage';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_SHARED_FILTERS =
	'global-settings-system-shared-filters';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_TROUBLESHOOTING_AUDIT_LOG =
	'global-settings-system-auditing';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_UI_ANNOUNCEMENT_BANNER =
	'global-settings-system-ui-announcement-banner';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_UI_DASHBOARD_EDIT =
	'global-settings-system-ui-dashboard-edit';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_UI_LOOK_AND_FEEL = 'global-settings-system-look';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_UI_PREFERENCES_APPLY =
	'global-settings-system-ui-default-user-preferences-apply';
export const ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_UI_USER_DEFAULTS =
	'global-settings-system-default-settings';
export const ROUTE_NAMES_HELP_CENTER_SETTINGS = ROUTE_GROUPS_HELP_CENTER_SETTINGS;
export const ROUTE_NAMES_HELP_CENTER_SETTINGS_CUSTOMER_NOTIFICATIONS = `${ROUTE_GROUPS_HELP_CENTER_SETTINGS}-customer-notifications`;
export const ROUTE_NAMES_HELP_CENTER_SETTINGS_EMAIL = `${ROUTE_GROUPS_HELP_CENTER_SETTINGS}-email`;
export const ROUTE_NAMES_HELP_CENTER_SETTINGS_KNOWLEDGE_BASE = `${ROUTE_GROUPS_HELP_CENTER_SETTINGS}-kb`;
export const ROUTE_NAMES_HELP_CENTER_SETTINGS_REQUEST_TYPES = `${ROUTE_GROUPS_HELP_CENTER_SETTINGS}-unassigned-ticket-types-config`;
export const ROUTE_NAMES_HELP_CENTER_SETTINGS_REQUEST_TYPE_FIELDS = `${ROUTE_GROUPS_HELP_CENTER_SETTINGS}-unassigned-ticket-type-fields`;
export const ROUTE_NAMES_HOME_ONBOARDING_REDIRECT = 'home-onboarding-redirect';
export const ROUTE_NAMES_HOME_REDIRECT = 'home-redirect';
export const ROUTE_NAMES_ISSUE = 'issue';
export const ROUTE_NAMES_ISSUE_CREATE_EMBED = 'issue-create-embed';
export const ROUTE_NAMES_ISSUE_EMBED = 'issue-embed';
export const ROUTE_NAMES_JIRA_SEARCH = 'jira-search';
export const ROUTE_NAMES_KNOWLEDGE_HUB_ALL_ARTICLES = 'knowledge-hub-all-articles';
export const ROUTE_NAMES_KNOWLEDGE_HUB_ARTICLE_EMBED_EDITOR = 'kb-article-embed-editor';
export const ROUTE_NAMES_KNOWLEDGE_HUB_ARTICLE_IN_CATEGORY = 'knowledge-hub-article-in-category';
export const ROUTE_NAMES_KNOWLEDGE_HUB_BROWSE_ALL_ARTICLES = 'knowledge-hub-browse-all-articles';
export const ROUTE_NAMES_KNOWLEDGE_HUB_BROWSE_ALL_CATEGORIES =
	'knowledge-hub-browse-all-categories';
export const ROUTE_NAMES_KNOWLEDGE_HUB_BROWSE_VIEW_ARTICLE = 'knowledge-hub-browse-view-article';
export const ROUTE_NAMES_KNOWLEDGE_HUB_CATEGORY = 'knowledge-hub-category';
export const ROUTE_NAMES_KNOWLEDGE_HUB_CATEGORY_PAGE = 'knowledge-hub-category-page';
export const ROUTE_NAMES_KNOWLEDGE_HUB_DRAFTS = 'knowledge-hub-drafts';
export const ROUTE_NAMES_KNOWLEDGE_HUB_KNOWLEDGE_GAPS = 'knowledge-hub-knowledge-gaps';
export const ROUTE_NAMES_KNOWLEDGE_HUB_LANDING = 'knowledge-hub-landing';
export const ROUTE_NAMES_KNOWLEDGE_HUB_SEARCH = 'knowledge-hub-search';
export const ROUTE_NAMES_KNOWLEDGE_HUB_SEARCH_ARTICLES = 'knowledge-hub-search-articles';
export const ROUTE_NAMES_KNOWLEDGE_HUB_SETUP = 'knowledge-hub-setup';
export const ROUTE_NAMES_LEGACY_ALL_REPORTS = 'legacy-all-reports';
export const ROUTE_NAMES_LEGACY_BACKLOG = 'legacy-backlog';
export const ROUTE_NAMES_LEGACY_BOARD = 'legacy-board';
export const ROUTE_NAMES_LEGACY_CONNECT_GENERAL = 'legacy-connect-general';
export const ROUTE_NAMES_LEGACY_CONNECT_PROJECT = 'legacy-connect-project';
export const ROUTE_NAMES_LEGACY_CORE_REPORTS = 'legacy-core-reports';
export const ROUTE_NAMES_LEGACY_CUSTOM_REPORT = 'legacy-custom-report';
export const ROUTE_NAMES_LEGACY_FALLBACK = 'legacy-fallback';
export const ROUTE_NAMES_LEGACY_GIN = 'legacy-gin';
export const ROUTE_NAMES_LEGACY_GIN_ISSUE = 'legacy-gin-issue';
export const ROUTE_NAMES_LEGACY_GIN_QUICKSEARCH = 'legacy-gin-quicksearch';
export const ROUTE_NAMES_LEGACY_GIN_SUBSCRIPTIONS = 'legacy-gin-subscriptions';
export const ROUTE_NAMES_LEGACY_ISSUE = 'legacy-issue';
export const ROUTE_NAMES_LEGACY_JSD_CUSTOMERS = 'legacy-jsd-customers';
export const ROUTE_NAMES_LEGACY_JSD_CUSTOMER_PERMISSIONS = 'legacy-jsd-customer-permissions';
export const ROUTE_NAMES_LEGACY_JSD_GETTING_STARTED = 'legacy-jsd-getting-started';
export const ROUTE_NAMES_LEGACY_JSD_JIRA_AUTOMATION = 'legacy-jsd-jira-automation';
export const ROUTE_NAMES_LEGACY_JSD_KNOWLEDGE = 'legacy-jsd-knowledge';
export const ROUTE_NAMES_LEGACY_JSD_QUEUES = 'legacy-jsd-queues';
export const ROUTE_NAMES_LEGACY_JSD_QUEUES_MANAGE = 'legacy-jsd-queues-manage';
export const ROUTE_NAMES_LEGACY_JSD_REPORTS = 'legacy-jsd-reports';
export const ROUTE_NAMES_LEGACY_JSD_REPORTS_CUSTOM = 'legacy-jsd-reports-custom';
export const ROUTE_NAMES_LEGACY_JSD_SETTINGS_FALLBACK = 'legacy-jsd-settings-fallback';
export const ROUTE_NAMES_LEGACY_JSD_SETTINGS_LANGUAGE = 'legacy-jsd-settings-language';
export const ROUTE_NAMES_LEGACY_JSD_SETTINGS_LANGUAGE_PAGES = 'legacy-jsd-settings-language-pages';
export const ROUTE_NAMES_LEGACY_JSD_SETTINGS_PORTAL = 'legacy-jsd-settings-portal-pages';
export const ROUTE_NAMES_LEGACY_JSD_WIDGETS = 'legacy-jsd-widgets';
export const ROUTE_NAMES_LEGACY_PIN = 'legacy-pin';
export const ROUTE_NAMES_LEGACY_PROJECT_RELEASES = 'legacy-project-releases';
export const ROUTE_NAMES_LEGACY_PROJECT_RELEASES_BAMBOO = 'legacy-project-releases-bamboo';
export const ROUTE_NAMES_LEGACY_PROJECT_RELEASES_NOTE = 'legacy-project-releases-note';
export const ROUTE_NAMES_LEGACY_PROJECT_RELEASES_NOTE_CONFIG =
	'legacy-project-releases-note-config';
export const ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_COLLECTORS = 'legacy-project-settings-collectors';
export const ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_COLLECTORS_ADD =
	'legacy-project-settings-collectors-add';
export const ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_COLLECTORS_HELP =
	'legacy-project-settings-collectors-help';
export const ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_COLLECTORS_VIEW =
	'legacy-project-settings-collectors-view';
export const ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_CONFIG = 'legacy-project-settings-config';
export const ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_DATA_CLASSIFICATIONS =
	'project-settings-classic-data-classifications';
export const ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_DELETE = 'legacy-project-settings-delete';
export const ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_DEV = 'legacy-project-settings-dev';
export const ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_EDIT = 'legacy-project-settings-edit';
export const ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_FIELDS = 'legacy-project-settings-fields';
export const ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_FIELDS_ASSOCIATE =
	'legacy-project-settings-fields-associate';
export const ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_LAYOUT =
	'legacy-project-settings-issue-layout';
export const ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_SECURITY =
	'legacy-project-settings-issue-security';
export const ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_SECURITY_ASSOCIATE =
	'legacy-project-settings-issue-security-associate';
export const ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_TYPES =
	'legacy-project-settings-issue-types';
export const ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_TYPES_ASSOCIATE =
	'legacy-project-settings-issue-types-associate';
export const ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_NOTIFICATIONS =
	'legacy-project-settings-notifications';
export const ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_NOTIFICATIONS_ASSOCIATE =
	'legacy-project-settings-notifications-associate';
export const ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_OPSGENIE = 'legacy-project-settings-opsgenie';
export const ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_PEOPLE = 'legacy-project-settings-people';
export const ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_PERMISSIONS =
	'legacy-project-settings-permissions';
export const ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_PERMISSIONS_ASSOCIATE =
	'legacy-project-settings-permissions-associate';
export const ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_SCREENS = 'legacy-project-settings-screens';
export const ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_SCREENS_ASSOCIATE =
	'legacy-project-settings-screens-associate';
export const ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_SUMMARY = 'legacy-project-settings-summary';
export const ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_WORKFLOWS = 'legacy-project-settings-workflows';
export const ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_WORKFLOWS_ASSOCIATE =
	'legacy-project-settings-workflows-associate';
export const ROUTE_NAMES_LEGACY_REDIRECT = 'legacy-redirect';
export const ROUTE_NAMES_LEGACY_REPORTS_AVERAGE_AGE = 'legacy-reports-average-age-report';
export const ROUTE_NAMES_LEGACY_REPORTS_CONFIG = 'legacy-reports-configure';
export const ROUTE_NAMES_LEGACY_REPORTS_CREATED_VS_RESOLVED =
	'legacy-reports-created-vs-resolved-report';
export const ROUTE_NAMES_LEGACY_REPORTS_DEVELOPER_WORKLOAD =
	'legacy-reports-developer-workload-report';
export const ROUTE_NAMES_LEGACY_REPORTS_PIE = 'legacy-reports-pie-report';
export const ROUTE_NAMES_LEGACY_REPORTS_RECENTLY_CREATED = 'legacy-reports-recently-created-report';
export const ROUTE_NAMES_LEGACY_REPORTS_RESOLUTION_TIME = 'legacy-reports-resolution-time-report';
export const ROUTE_NAMES_LEGACY_REPORTS_SINGLE_LEVEL_GROUP_BY =
	'legacy-reports-single-level-group-by-report';
export const ROUTE_NAMES_LEGACY_REPORTS_TIME_SINCE = 'legacy-reports-time-since-report';
export const ROUTE_NAMES_LEGACY_REPORTS_TIME_TRACKING_REPORT =
	'legacy-reports-time-tracking-report';
export const ROUTE_NAMES_LEGACY_REPORTS_VERSION_WORKLOAD = 'legacy-reports-version-workload-report';
export const ROUTE_NAMES_LEGACY_REPORTS_WORKLOAD_PIE = 'legacy-reports-workload-pie-report';
export const ROUTE_NAMES_LOGIN_EMBED = 'login-embed';
export const ROUTE_NAMES_MARKETPLACE_ADDONS_EMCEE = 'global-settings-addons-emcee';
export const ROUTE_NAMES_MARKETPLACE_ADDONS_EMCEE_PUBLIC = 'addons-emcee-public';
export const ROUTE_NAMES_MARKETPLACE_APP_REQUESTS = 'marketplace-app-requests';
export const ROUTE_NAMES_MARKETPLACE_INDEX = 'marketplace-index';
export const ROUTE_NAMES_MARKETPLACE_MANAGE_APPS = 'global-settings-addons-upm';
export const ROUTE_NAMES_MARKETPLACE_PROMOTIONS = 'marketplace-promotions';
export const ROUTE_NAMES_NOTIFICATION_LOGS = 'notification-logs';
export const ROUTE_NAMES_OPSGENIE_SCHEDULE = 'opsgenie-schedule';
export const ROUTE_NAMES_PEOPLE_INDEX = 'people-index';
export const ROUTE_NAMES_PERSONAL_SETTINGS = 'personal-settings';
export const ROUTE_NAMES_PERSONAL_SETTINGS_GENERAL = 'personal-settings-general';
export const ROUTE_NAMES_PERSONAL_SETTINGS_NOTIFICATIONS = 'personal-settings-notifications';
export const ROUTE_NAMES_PERSONAL_SETTINGS_OPS_NOTIFICATIONS =
	'personal-settings-ops-notifications';
export const ROUTE_NAMES_PLANS_DIRECTORY = 'plans-directory';
export const ROUTE_NAMES_PLAYBOOKS_EDIT = 'project-settings-playbooks-edit';
export const ROUTE_NAMES_PLAYBOOKS_LIST = 'project-settings-playbooks-list';
export const ROUTE_NAMES_PLAYBOOKS_LOGS = 'project-settings-playbooks-logs';
export const ROUTE_NAMES_POLARIS_COLLECTIONS = 'polaris-collections';
export const ROUTE_NAMES_POLARIS_IDEAS = 'polaris-ideas';
export const ROUTE_NAMES_POLARIS_INSIGHTS = 'polaris-insights';
export const ROUTE_NAMES_POLARIS_ONBOARDING = 'polaris-onboarding';
export const ROUTE_NAMES_POLARIS_PLAYS = 'polaris-plays';
export const ROUTE_NAMES_POLARIS_ROOMS = 'polaris-rooms';
export const ROUTE_NAMES_POLARIS_SETTINGS_ACCESS = 'polaris-settings-access';
export const ROUTE_NAMES_POLARIS_SETTINGS_GLOBAL_FIELDS = 'polaris-settings-global-fields';
export const ROUTE_NAMES_POLARIS_UNLICENSED = 'polaris-unlicensed';
export const ROUTE_NAMES_PRODUCTS_JSM_AUTHENTICATION_SETTINGS =
	'products-jsm-authentication-settings';
export const ROUTE_NAMES_PRODUCTS_JSM_CHATOPS_AND_VIDEOS_TOOLS =
	'products-jsm-chatops-and-videos-tools';
export const ROUTE_NAMES_PRODUCTS_JSM_COMPLIANCE = 'products-jsm-compliance';
export const ROUTE_NAMES_PRODUCTS_JSM_CONFIGURATION = 'products-jsm-configuration';
export const ROUTE_NAMES_PRODUCTS_JSM_CUSTOMER_ACCESS = 'products-jsm-customer-access';
export const ROUTE_NAMES_PRODUCTS_JSM_EMAIL_REQUESTS = 'products-jsm-email-requests';
export const ROUTE_NAMES_PRODUCTS_JSM_FEATURE_USAGE = 'products-jsm-feature-usage';
export const ROUTE_NAMES_PRODUCTS_JSM_GLOBAL_OPS_REPORTS = 'products-jsm-global-ops-reports';
export const ROUTE_NAMES_PRODUCTS_JSM_INCIDENT_MANAGEMENT_TOOLS =
	'products-jsm-incident-management-tools';
export const ROUTE_NAMES_PRODUCTS_JSM_KB_PERMISSIONS = 'products-jsm-kb-permissions';
export const ROUTE_NAMES_PRODUCTS_JSM_ORGANIZATIONS = 'products-jsm-organizations';
export const ROUTE_NAMES_PRODUCTS_JSW_CONFIGURATION = 'products-jsw-configuration';
export const ROUTE_NAMES_PRODUCTS_JSW_STORAGE_MANAGEMENT = 'products-jsw-storage-management';
export const ROUTE_NAMES_PROFORMA_EXPORT = 'proforma-export';
export const ROUTE_NAMES_PROFORMA_ISSUE_FORMS_DIRECT = 'proforma-issue-forms-direct';
export const ROUTE_NAMES_PROFORMA_ISSUE_FORMS_DIRECT_EMBED = 'proforma-issue-forms-direct-embed';
export const ROUTE_NAMES_PROFORMA_ISSUE_FORMS_GENERAL = 'proforma-issue-forms-general';
export const ROUTE_NAMES_PROJECTS_DIRECTORY = 'projects-directory';
export const ROUTE_NAMES_PROJECTS_DIRECTORY_LEGACY_PATH_SPA = 'projects-directory-legacy-path-spa';
export const ROUTE_NAMES_PROJECTS_DIRECTORY_MANAGE = 'projects-directory-manage';
export const ROUTE_NAMES_PROJECTS_REDIRECT = 'projects-redirect';
export const ROUTE_NAMES_PROJECT_COMPONENTS = 'project-components';
export const ROUTE_NAMES_PROJECT_DIRECTORY_REDIRECT = 'project-directory-redirect';
export const ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR = 'issue-navigator';
export const ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR_CLASSIC = 'issue-navigator-classic';
export const ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR_CORE = 'issue-navigator-core';
export const ROUTE_NAMES_PROJECT_PAGES = 'project-pages';
export const ROUTE_NAMES_PROJECT_SETTINGS_AUTOMATION = 'project-settings-automation';
export const ROUTE_NAMES_PROJECT_SETTINGS_COMPONENTS = 'project-settings-components';
export const ROUTE_NAMES_PROJECT_SETTINGS_CORE_ACCESS = `${ROUTE_GROUPS_PROJECT_SETTINGS_CORE}-access`;
export const ROUTE_NAMES_PROJECT_SETTINGS_CORE_ACCESS_REDIRECT = `${ROUTE_GROUPS_PROJECT_SETTINGS_CORE}-access-redirect`;
export const ROUTE_NAMES_PROJECT_SETTINGS_CORE_APPROVALS = `${ROUTE_GROUPS_PROJECT_SETTINGS_CORE}-approvals`;
export const ROUTE_NAMES_PROJECT_SETTINGS_CORE_APPS = `${ROUTE_GROUPS_PROJECT_SETTINGS_CORE}-apps`;
export const ROUTE_NAMES_PROJECT_SETTINGS_CORE_APPS_FIELDS = `${ROUTE_GROUPS_PROJECT_SETTINGS_CORE}-apps-fields`;
export const ROUTE_NAMES_PROJECT_SETTINGS_CORE_AUTOMATION = `${ROUTE_GROUPS_PROJECT_SETTINGS_CORE}-automation`;
export const ROUTE_NAMES_PROJECT_SETTINGS_CORE_DATA_CLASSIFICATIONS = `${ROUTE_GROUPS_PROJECT_SETTINGS_CORE}-data-classifications`;
export const ROUTE_NAMES_PROJECT_SETTINGS_CORE_DEFAULT_REDIRECT = `${ROUTE_GROUPS_PROJECT_SETTINGS_CORE}-redirect`;
export const ROUTE_NAMES_PROJECT_SETTINGS_CORE_DETAILS = `${ROUTE_GROUPS_PROJECT_SETTINGS_CORE}-details`;
export const ROUTE_NAMES_PROJECT_SETTINGS_CORE_FIELDS = `${ROUTE_GROUPS_PROJECT_SETTINGS_CORE}-fields`;
export const ROUTE_NAMES_PROJECT_SETTINGS_CORE_FORGE_APPS = `${ROUTE_GROUPS_PROJECT_SETTINGS_CORE}-forge-apps`;
export const ROUTE_NAMES_PROJECT_SETTINGS_CORE_ISSUE_TYPES = `${ROUTE_GROUPS_PROJECT_SETTINGS_CORE}-issuetypes`;
export const ROUTE_NAMES_PROJECT_SETTINGS_CORE_ISSUE_TYPES_WORKFLOW = `${ROUTE_GROUPS_PROJECT_SETTINGS_CORE}-issuetypes-workflow`;
export const ROUTE_NAMES_PROJECT_SETTINGS_CORE_NOTIFICATIONS = `${ROUTE_GROUPS_PROJECT_SETTINGS_CORE}-notifications`;
export const ROUTE_NAMES_PROJECT_SETTINGS_CORE_NOTIFICATIONS_ISSUE_LOGS = `${ROUTE_GROUPS_PROJECT_SETTINGS_CORE}-notifications-issue-logs`;
export const ROUTE_NAMES_PROJECT_SETTINGS_CORE_NOTIFICATION_EMAIL = `${ROUTE_GROUPS_PROJECT_SETTINGS_CORE}-notifications-notification-email`;
export const ROUTE_NAMES_PROJECT_SETTINGS_CORE_PROFORMA_JIRA_FORM_BUILDER = `${ROUTE_GROUPS_PROJECT_SETTINGS_CORE}-proforma-jira-form-builder`;
export const ROUTE_NAMES_PROJECT_SETTINGS_CORE_PROFORMA_PROJECT_FORMS = `${ROUTE_GROUPS_PROJECT_SETTINGS_CORE}-proforma-project-forms`;
export const ROUTE_NAMES_PROJECT_SETTINGS_FORGE_APPS = `${ROUTE_GROUPS_PROJECT_SETTINGS_SOFTWARE}-forge-apps`;
export const ROUTE_NAMES_PROJECT_SETTINGS_FORGE_APPS_NEXTGEN = `${ROUTE_GROUPS_PROJECT_SETTINGS_SOFTWARE}-apps-forge`;
export const ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_APPS_FIELDS = `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-apps-fields`;
export const ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_CHAT = `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-chat`;
export const ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_CLASSIC_CUSTOMER_NOTIFICATIONS = `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-customer-notifications-classic`;
export const ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_CLASSIC_EMAIL_SETTINGS = `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-email-classic`;
export const ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_DETAILS = `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-details`;
export const ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_FORGE_APPS = `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-forge-apps`;
export const ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_ITSM_REQUEST_TYPES = `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-itsm-ticket-types-config`;
export const ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_ITSM_REQUEST_TYPES_FIELDS = `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-itsm-ticket-type-fields`;
export const ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_NOTIFICATIONS = `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-notifications`;
export const ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_NOTIFICATIONS_NOTIFICATION_EMAIL = `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-notifications-notification-email`;
export const ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_PORTAL_SETTINGS = `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-portal-settings`;
export const ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_REQUEST_TYPES = `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-ticket-types-config`;
export const ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_REQUEST_TYPES_FIELDS = `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-ticket-type-fields`;
export const ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_UNASSIGNED_REQUEST_TYPES = `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-unassigned-ticket-types-config`;
export const ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_UNASSIGNED_REQUEST_TYPES_FIELDS = `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-unassigned-ticket-type-fields`;
export const ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_ACCESS = 'project-settings-software-access';
export const ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_APPS_FIELDS = `${ROUTE_GROUPS_PROJECT_SETTINGS_SOFTWARE}-apps-fields`;
export const ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_AUTOMATION =
	'project-settings-software-automation';
export const ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_BOARDS_CARD =
	'project-settings-software-boards-card';
export const ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_BOARDS_CSM =
	'project-settings-software-boards-csm';
export const ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_BOARDS_CUSTOM_FILTERS =
	'project-settings-software-boards-custom-filters';
export const ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_BOARDS_DETAILS =
	'project-settings-software-boards-details';
export const ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_BOARDS_FEATURES =
	'project-settings-software-boards-features';
export const ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_BOARDS_INSIGHTS =
	'project-settings-software-boards-insights';
export const ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_BOARDS_REDIRECT =
	'project-settings-software-boards-redirect';
export const ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_BOARDS_ROADMAP =
	'project-settings-software-boards-roadmap';
export const ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_BOARDS_SWIMLANES =
	'project-settings-software-boards-swimlanes';
export const ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_BOARDS_TIMELINE =
	'project-settings-software-boards-timeline';
export const ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_DATA_CLASSIFICATIONS =
	'project-settings-software-data-classifications';
export const ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_DETAILS = 'project-settings-software-details';
export const ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_FEATURES =
	'project-settings-software-next-gen-features';
export const ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_ISSUE_TYPES =
	'project-settings-software-issuetypes';
export const ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_NOTIFICATIONS = `${ROUTE_GROUPS_PROJECT_SETTINGS_SOFTWARE}-notifications`;
export const ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_NOTIFICATIONS_ISSUE_LOGS = `${ROUTE_GROUPS_PROJECT_SETTINGS_SOFTWARE}-notifications-issue-logs`;
export const ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_NOTIFICATION_EMAIL =
	'project-settings-software-notifications-notification-email';
export const ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_OPSGENIE = `${ROUTE_GROUPS_PROJECT_SETTINGS_SOFTWARE}-link-opsgenie`;
export const ROUTE_NAMES_PROJECT_SETTINGS_TOOLCHAIN = `${ROUTE_GROUPS_PROJECT_SETTINGS_SOFTWARE}-toolchain-team-managed`;
export const ROUTE_NAMES_PROJECT_SETTINGS_VERSIONS = 'project-settings-versions';
export const ROUTE_NAMES_PROJECT_VERSIONS = 'project-versions';
export const ROUTE_NAMES_RAPIDBOARD = 'rapidboard';
export const ROUTE_NAMES_RAPIDBOARD_BACKLOG = 'rapidboard-backlog';
export const ROUTE_NAMES_RAPIDBOARD_BOARD = 'rapidboard-board';
export const ROUTE_NAMES_RAPIDBOARD_BOARD_PRETTY_URL_REDIRECT =
	'rapidboard-board-pretty-url-redirect';
export const ROUTE_NAMES_RAPIDBOARD_LAST_VISITED_PROJECT_REDIRECT =
	'rapidboard-last-visited-project-redirect';
export const ROUTE_NAMES_RAPIDBOARD_REPORT = 'rapidboard-report';
export const ROUTE_NAMES_RAPIDBOARD_UIF_BACKLOG = 'rapidboard-uif-backlog';
export const ROUTE_NAMES_RAPIDBOARD_USER_BACKLOG = 'rapidboard-user-backlog';
export const ROUTE_NAMES_RAPIDBOARD_USER_BOARD = 'rapidboard-user-board';
export const ROUTE_NAMES_RAPIDBOARD_USER_REPORT = 'rapidboard-user-report';
export const ROUTE_NAMES_SERVICEDESK_ATLASSIAN_ANALYTICS = 'servicedesk-atlassian-analytics';
export const ROUTE_NAMES_SERVICEDESK_BOARDS = 'servicedesk-boards';
export const ROUTE_NAMES_SERVICEDESK_CALENDAR = 'servicedesk-calendar';
export const ROUTE_NAMES_SERVICEDESK_CALENDAR_EAP = 'servicedesk-calendar-eap';
export const ROUTE_NAMES_SERVICEDESK_CHANGE_CALENDAR = 'servicedesk-change-calendar';
export const ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_CONFIGURE = 'servicedesk-cmdb-configure';
export const ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT = 'servicedesk-cmdb-object';
export const ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT_SCHEMA = 'servicedesk-cmdb-object-schema';
export const ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT_SCHEMA_CONFIGURATION =
	'servicedesk-cmdb-object-schema-configuration';
export const ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT_TYPE_CONFIGURATION =
	'servicedesk-cmdb-object-type-configuration';
export const ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_REPORTS = 'servicedesk-cmdb-assets-reports';
export const ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_REPORTS_DEFAULT_REDIRECT =
	'servicedesk-cmdb-assets-reports-default-redirect';
export const ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_REPORTS_UNSUBSCRIBE =
	'servicedesk-cmdb-assets-reports-unsubscribe';
export const ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT = 'servicedesk-cmdb-manage-object-schemas';
export const ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_CONFIGURE = 'servicedesk-cmdb-configure';
export const ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_LANDING_PAGE = 'servicedesk-cmdb-landing-page';
export const ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT = 'servicedesk-cmdb-object';
export const ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT_SCHEMA = 'servicedesk-cmdb-object-schema';
export const ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT_SCHEMA_CONFIGURATION =
	'servicedesk-cmdb-object-schema-configuration';
export const ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT_TYPE_CONFIGURATION =
	'servicedesk-cmdb-object-type-configuration';
export const ROUTE_NAMES_SERVICEDESK_CONNECT_PROJECT = 'servicedesk-connect-project';
export const ROUTE_NAMES_SERVICEDESK_CREATE_PROJECT = 'servicedesk-create-project';
export const ROUTE_NAMES_SERVICEDESK_CUSTOMERS_V2 = 'servicedesk-customers-v2';
export const ROUTE_NAMES_SERVICEDESK_CUSTOMER_DETAILS = 'servicedesk-customer-details';
export const ROUTE_NAMES_SERVICEDESK_CUSTOMER_SERVICE_ESCALATIONS =
	'servicedesk-customer-service-escalations';
export const ROUTE_NAMES_SERVICEDESK_CUSTOMER_SERVICE_PRODUCTS =
	'servicedesk-customer-service-products';
export const ROUTE_NAMES_SERVICEDESK_EARLY_ACCESS_ITSM = 'servicedesk-early-access-itsm';
export const ROUTE_NAMES_SERVICEDESK_GETTING_STARTED_PAGE = 'servicedesk-getting-started-page';
export const ROUTE_NAMES_SERVICEDESK_INCIDENTS = 'servicedesk-incidents';
export const ROUTE_NAMES_SERVICEDESK_INDEX = 'servicedesk-index';
export const ROUTE_NAMES_SERVICEDESK_ISSUE = 'servicedesk-issue';
export const ROUTE_NAMES_SERVICEDESK_ISSUES = 'servicedesk-issues';
export const ROUTE_NAMES_SERVICEDESK_ITSM_SAMPLE_SPACE = 'servicedesk-itsm-sample-space';
export const ROUTE_NAMES_SERVICEDESK_JIRA_AUTOMATION = 'servicedesk-automation';
export const ROUTE_NAMES_SERVICEDESK_KB_REQUESTS_REPORTS = 'servicedesk-kb-requests-reports';
export const ROUTE_NAMES_SERVICEDESK_KB_REQUESTS_REPORTS_ISSUE =
	'servicedesk-kb-requests-reports-time-scale-issue';
export const ROUTE_NAMES_SERVICEDESK_KB_REQUESTS_REPORTS_TIME_SCALE =
	'servicedesk-kb-requests-reports-time-scale';
export const ROUTE_NAMES_SERVICEDESK_ONCALL_SCHEDULE = 'servicedesk-oncall-schedule';
export const ROUTE_NAMES_SERVICEDESK_ORGANIZATIONS = 'servicedesk-organizations';
export const ROUTE_NAMES_SERVICEDESK_ORGANIZATION_DETAILS = 'servicedesk-organization-details';
export const ROUTE_NAMES_SERVICEDESK_ORGANIZATION_V2 = 'servicedesk-organization_v2';
export const ROUTE_NAMES_SERVICEDESK_PRACTICE_CUSTOM_AND_ISSUE =
	'servicedesk-practice-custom-and-issue';
export const ROUTE_NAMES_SERVICEDESK_PRACTICE_EDIT = 'servicedesk-practice-edit';
export const ROUTE_NAMES_SERVICEDESK_PRACTICE_ISSUE = 'servicedesk-practice-issue';
export const ROUTE_NAMES_SERVICEDESK_PRACTICE_LANDING = 'servicedesk-practice-landing';
export const ROUTE_NAMES_SERVICEDESK_PRACTICE_MANAGE_VIEW = 'servicedesk-practice-manage-view';
export const ROUTE_NAMES_SERVICEDESK_PRACTICE_NEW = 'servicedesk-practice-new';
export const ROUTE_NAMES_SERVICEDESK_PROJECT_CONVERSION = 'servicedesk-project-conversion';
export const ROUTE_NAMES_SERVICEDESK_QUEUES_ADDON = 'servicedesk-queues-addon';
export const ROUTE_NAMES_SERVICEDESK_QUEUES_CUSTOM_AND_ISSUE =
	'servicedesk-queues-custom-and-issue';
export const ROUTE_NAMES_SERVICEDESK_QUEUES_EDIT = 'servicedesk-queues-edit';
export const ROUTE_NAMES_SERVICEDESK_QUEUES_ISSUE = 'servicedesk-queues-issue';
export const ROUTE_NAMES_SERVICEDESK_QUEUES_LANDING = 'servicedesk-queues-landing';
export const ROUTE_NAMES_SERVICEDESK_QUEUES_MANAGE_VIEW = 'servicedesk-queues-manage-view';
export const ROUTE_NAMES_SERVICEDESK_QUEUES_NEW = 'servicedesk-queues-new';
export const ROUTE_NAMES_SERVICEDESK_REDIRECT = 'servicedesk-redirect';
export const ROUTE_NAMES_SERVICEDESK_REPORTS = 'servicedesk-reports';
export const ROUTE_NAMES_SERVICEDESK_REPORTS_CUSTOM = 'servicedesk-reports-custom';
export const ROUTE_NAMES_SERVICEDESK_REPORTS_CUSTOM_CREATE = 'servicedesk-reports-custom-create';
export const ROUTE_NAMES_SERVICEDESK_REPORTS_CUSTOM_EDIT = 'servicedesk-reports-custom-edit';
export const ROUTE_NAMES_SERVICEDESK_REPORTS_CUSTOM_ISSUE =
	'servicedesk-reports-custom-time-scale-issue';
export const ROUTE_NAMES_SERVICEDESK_REPORTS_CUSTOM_TIME_SCALE =
	'servicedesk-reports-custom-time-scale';
export const ROUTE_NAMES_SERVICEDESK_REPORTS_SATISFACTION = 'servicedesk-reports-satisfaction';
export const ROUTE_NAMES_SERVICEDESK_REPORTS_SATISFACTION_ISSUE =
	'servicedesk-reports-satisfaction-time-scale-issue';
export const ROUTE_NAMES_SERVICEDESK_REPORTS_SATISFACTION_TIME_SCALE =
	'servicedesk-reports-satisfaction-time-scale';
export const ROUTE_NAMES_SERVICEDESK_REPORTS_SUMMARY = 'servicedesk-reports-summary';
export const ROUTE_NAMES_SERVICEDESK_REPORTS_TYPE = 'servicedesk-reports-type';
export const ROUTE_NAMES_SERVICEDESK_REPORTS_WORKLOAD = 'servicedesk-reports-workload';
export const ROUTE_NAMES_SERVICEDESK_SERVICES = 'servicedesk-services';
export const ROUTE_NAMES_SERVICEDESK_SERVICES_NEW = 'servicedesk-services-new';
export const ROUTE_NAMES_SERVICEDESK_SERVICES_WITHOUT_PROJECT =
	'servicedesk-services-without-project';
export const ROUTE_NAMES_SERVICEDESK_SERVICE_DETAILS = 'servicedesk-service-details';
export const ROUTE_NAMES_SERVICEDESK_SERVICE_DETAILS_NEW = 'servicedesk-service-details-new';
export const ROUTE_NAMES_SERVICEDESK_SERVICE_DETAILS_WITHOUT_PROJECT =
	'servicedesk-service-details-without-project';
export const ROUTE_NAMES_SERVICEDESK_SERVICE_MODAL_EDIT = 'servicedesk-service-modal-edit';
export const ROUTE_NAMES_SERVICEDESK_SERVICE_MODAL_NEW = 'servicedesk-service-modal-new';
export const ROUTE_NAMES_SERVICEDESK_SLA_SETTINGS = 'servicedesk-sla-settings';
export const ROUTE_NAMES_SERVICE_HUB_SERVICES = 'service-hub-services';
export const ROUTE_NAMES_SERVICE_HUB_SERVICE_DETAILS = 'service-hub-service-details';
export const ROUTE_NAMES_SETTINGS_AUTOMATION = 'global-automation';
export const ROUTE_NAMES_SETTINGS_CMDB_FIELD_CONFIG = 'global-settings-issues-cmdb-field-config';
export const ROUTE_NAMES_SETTINGS_OAUTH_CREDENTIALS = 'admin-oauth-credentials';
export const ROUTE_NAMES_SETTINGS_RECOMMENDATIONS = 'recommendations';
export const ROUTE_NAMES_SETTINGS_VIEW_CUSTOM_FIELDS = 'view-custom-fields';
export const ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS = 'software-advanced-roadmaps';
export const ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS_GETTING_STARTED_REDIRECT =
	'software-advanced-roadmaps-getting-started-redirect';
export const ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS_REDIRECT =
	'software-advanced-roadmaps-redirect';
export const ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS_SETUP = 'software-advanced-roadmaps-setup';
export const ROUTE_NAMES_SOFTWARE_ARJ_SETTINGS_DEPENDENCIES = `${ROUTE_GROUPS_ARJ_SETTINGS}-dependencies`;
export const ROUTE_NAMES_SOFTWARE_ARJ_SETTINGS_DEPENDENCIES_REDIRECT = `${ROUTE_GROUPS_ARJ_SETTINGS}-dependencies-redirect`;
export const ROUTE_NAMES_SOFTWARE_ARJ_SETTINGS_FINANCIAL_YEAR = `${ROUTE_GROUPS_ARJ_SETTINGS}-financial-year`;
export const ROUTE_NAMES_SOFTWARE_ARJ_SETTINGS_LOADING_LIMITS = `${ROUTE_GROUPS_ARJ_SETTINGS}-loading-limits`;
export const ROUTE_NAMES_SOFTWARE_ARJ_SETTINGS_LOADING_LIMITS_REDIRECT = `${ROUTE_GROUPS_ARJ_SETTINGS}-loading-limits-redirect`;
export const ROUTE_NAMES_SOFTWARE_ARJ_SETTINGS_PERMISSIONS = `${ROUTE_GROUPS_ARJ_SETTINGS}-permissions`;
export const ROUTE_NAMES_SOFTWARE_ARJ_SETTINGS_PERMISSIONS_REDIRECT = `${ROUTE_GROUPS_ARJ_SETTINGS}-permissions-redirect`;
export const ROUTE_NAMES_SOFTWARE_BACKLOG = 'software-backlog';
export const ROUTE_NAMES_SOFTWARE_BOARDS = 'software-boards';
export const ROUTE_NAMES_SOFTWARE_CALENDAR = 'software-calendar';
export const ROUTE_NAMES_SOFTWARE_CALENDAR_CLASSIC = 'software-calendar-classic';
export const ROUTE_NAMES_SOFTWARE_CALENDAR_CLASSIC_EMBED = 'software-calendar-classic-embed';
export const ROUTE_NAMES_SOFTWARE_CALENDAR_EMBED = 'software-calendar-embed';
export const ROUTE_NAMES_SOFTWARE_CLASSIC_PROFORMA_ISSUE_FORMS_DIRECT = `${ROUTE_GROUPS_CLASSIC_SOFTWARE}-proforma-issue-forms-direct`;
export const ROUTE_NAMES_SOFTWARE_CLASSIC_PROFORMA_ISSUE_FORMS_DIRECT_EMBED = `${ROUTE_GROUPS_CLASSIC_SOFTWARE}-proforma-issue-forms-direct-embed`;
export const ROUTE_NAMES_SOFTWARE_CODE = 'software-code';
export const ROUTE_NAMES_SOFTWARE_CODE_CLASSIC = 'software-code-classic';
export const ROUTE_NAMES_SOFTWARE_DEPLOYMENTS = 'software-deployments';
export const ROUTE_NAMES_SOFTWARE_DEPLOYMENTS_CLASSIC = 'software-deployments-classic';
export const ROUTE_NAMES_SOFTWARE_GOALS = 'software-goals';
export const ROUTE_NAMES_SOFTWARE_GOALS_CLASSIC = 'software-goals-classic';
export const ROUTE_NAMES_SOFTWARE_INCIDENTS = 'software-incidents';
export const ROUTE_NAMES_SOFTWARE_INCIDENTS_CLASSIC = 'software-incidents-classic';
export const ROUTE_NAMES_SOFTWARE_ISSUE_NAVIGATOR = 'software-issue-navigator';
export const ROUTE_NAMES_SOFTWARE_LIST = 'software-list';
export const ROUTE_NAMES_SOFTWARE_LIST_CLASSIC = 'software-list-classic';
export const ROUTE_NAMES_SOFTWARE_LIST_CLASSIC_EMBED = 'software-list-classic-embed';
export const ROUTE_NAMES_SOFTWARE_LIST_EMBED = 'software-list-embed';
export const ROUTE_NAMES_SOFTWARE_NEXTGEN_REDIRECT = 'software-nextgen-redirect';
export const ROUTE_NAMES_SOFTWARE_ONBOARDING = 'software-onboarding';
export const ROUTE_NAMES_SOFTWARE_PERISCOPE = 'software-periscope';
export const ROUTE_NAMES_SOFTWARE_PERISCOPE_CLASSIC = 'software-periscope-classic';
export const ROUTE_NAMES_SOFTWARE_PLAN_CALENDAR = 'software-plan-calendar';
export const ROUTE_NAMES_SOFTWARE_PLAN_DEPENDENCIES = 'software-plan-dependencies';
export const ROUTE_NAMES_SOFTWARE_PLAN_INCREMENT = 'software-plan-increment';
export const ROUTE_NAMES_SOFTWARE_PLAN_INCREMENTS = 'software-plan-increments';
export const ROUTE_NAMES_SOFTWARE_PLAN_RELEASES = 'software-plan-releases';
export const ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_AUTO_SCHEDULER = `${ROUTE_GROUPS_PLAN_SETTINGS}-auto-scheduler`;
export const ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_CUSTOM_FIELDS = `${ROUTE_GROUPS_PLAN_SETTINGS}-custom-fields`;
export const ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_EXCLUSION_RULES = `${ROUTE_GROUPS_PLAN_SETTINGS}-exclusion-rules`;
export const ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_FIND_YOUR_ISSUE = `${ROUTE_GROUPS_PLAN_SETTINGS}-find-your-issue`;
export const ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_ISSUE_SOURCES = `${ROUTE_GROUPS_PLAN_SETTINGS}-issue-sources`;
export const ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_PERMISSIONS = `${ROUTE_GROUPS_PLAN_SETTINGS}-permissions`;
export const ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_PLAN_INFO = `${ROUTE_GROUPS_PLAN_SETTINGS}-plan-info`;
export const ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_PROGRAM_BOARDS = `${ROUTE_GROUPS_PLAN_SETTINGS}-program-boards`;
export const ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_REMOVED_ISSUES = `${ROUTE_GROUPS_PLAN_SETTINGS}-removed-issues`;
export const ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_SAVED_VIEWS = `${ROUTE_GROUPS_PLAN_SETTINGS}-saved-views`;
export const ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_SCENARIOS = `${ROUTE_GROUPS_PLAN_SETTINGS}-scenarios`;
export const ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_SCHEDULING = `${ROUTE_GROUPS_PLAN_SETTINGS}-scheduling`;
export const ROUTE_NAMES_SOFTWARE_PLAN_SUMMARY = 'software-plan-summary';
export const ROUTE_NAMES_SOFTWARE_PLAN_TEAMS = 'software-plan-teams';
export const ROUTE_NAMES_SOFTWARE_PLAN_TIMELINE = 'software-plan-timeline';
export const ROUTE_NAMES_SOFTWARE_PRESENTATION_TOOLKIT_REPORT =
	'software-presentation-toolkit-report';
export const ROUTE_NAMES_SOFTWARE_RELEASE_NOTES = 'software-release-notes';
export const ROUTE_NAMES_SOFTWARE_REPORTS = 'software-reports';
export const ROUTE_NAMES_SOFTWARE_REPORTS_BURNDOWN = 'software-reports-burndown';
export const ROUTE_NAMES_SOFTWARE_REPORTS_BURNUP = 'software-reports-burnup';
export const ROUTE_NAMES_SOFTWARE_REPORTS_CUMULATIVE = 'software-reports-cumulative';
export const ROUTE_NAMES_SOFTWARE_REPORTS_CYCLE_TIME = 'software-reports-cycle-time';
export const ROUTE_NAMES_SOFTWARE_REPORTS_CYCLE_TIME_CLASSIC =
	'software-reports-cycle-time-classic';
export const ROUTE_NAMES_SOFTWARE_REPORTS_DEPLOYMENT_FREQUENCY =
	'software-reports-deployment-frequency';
export const ROUTE_NAMES_SOFTWARE_REPORTS_DEPLOYMENT_FREQUENCY_CLASSIC =
	'software-reports-deployment-frequency-classic';
export const ROUTE_NAMES_SOFTWARE_REPORTS_VELOCITY = 'software-reports-velocity';
export const ROUTE_NAMES_SOFTWARE_REQUESTS = 'software-requests';
export const ROUTE_NAMES_SOFTWARE_REQUESTS_CLASSIC = 'software-requests-classic';
export const ROUTE_NAMES_SOFTWARE_ROADMAP = 'software-roadmap';
export const ROUTE_NAMES_SOFTWARE_ROADMAP_CLASSIC = 'software-roadmap-classic';
export const ROUTE_NAMES_SOFTWARE_ROADMAP_REDIRECT = 'software-roadmap-redirect';
export const ROUTE_NAMES_SOFTWARE_SECURITY = 'software-security';
export const ROUTE_NAMES_SOFTWARE_SECURITY_CLASSIC = 'software-security-classic';
export const ROUTE_NAMES_SOFTWARE_TIMELINE = 'software-timeline';
export const ROUTE_NAMES_SOFTWARE_TIMELINE_CLASSIC = 'software-timeline-classic';
export const ROUTE_NAMES_SOFTWARE_VERSION_DETAIL = 'software-version-detail';
export const ROUTE_NAMES_SOFTWARE_VERSION_DETAIL_LEGACY_REDIRECT =
	'software-version-detail-legacy-redirect';
export const ROUTE_NAMES_SOFTWARE_VERSION_DETAIL_NO_TAB_PATH =
	'software-version-detail-no-tab-path';
export const ROUTE_NAMES_SYSTEM_CONFIGURATION = 'system-configuration-general';
export const ROUTE_NAMES_SYSTEM_DASHBOARD_EDIT = 'system-dashboard-edit';
export const ROUTE_NAMES_TRASHED_PLAN_DIRECT_LINK = 'trashed-plan-direct-link';
export const ROUTE_NAMES_TRASH_PLANS_DIRECTORY = 'trash-plans-directory';
export const ROUTE_NAMES_TRASH_PROJECTS_DIRECTORY = 'trash-projects-directory';
export const ROUTE_NAMES_USER_SEGMENTATION = 'user-segmentation';
export const ROUTE_NAMES_YOUR_WORK = 'your-work';
export type RouteNames =
	| typeof ROUTE_NAMES_ARCHIVED_ISSUES_DIRECTORY
	| typeof ROUTE_NAMES_ARCHIVED_PLANS_DIRECTORY
	| typeof ROUTE_NAMES_ARCHIVED_PROJECTS_DIRECTORY
	| typeof ROUTE_NAMES_ARCHIVED_PROJECT_DIRECT_LINK
	| typeof ROUTE_NAMES_BOARDS_DIRECTORY
	| typeof ROUTE_NAMES_BROWSE_PROJECT_REDIRECT
	| typeof ROUTE_NAMES_BUSINESS_BOARD
	| typeof ROUTE_NAMES_BUSINESS_CLASSIC_REPORTS
	| typeof ROUTE_NAMES_BUSINESS_REPORTS
	| typeof ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_CALENDAR
	| typeof ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_REDIRECT
	| typeof ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_SUMMARY
	| typeof ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_TIMELINE
	| typeof ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_APPROVALS
	| typeof ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_ATTACHMENTS
	| typeof ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_BOARD
	| typeof ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_BOARD_EMBED
	| typeof ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_CALENDAR
	| typeof ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_CALENDAR_EMBED
	| typeof ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FILES
	| typeof ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM
	| typeof ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM_BUILDER
	| typeof ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM_SUBMIT
	| typeof ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM_SUBMIT_EMBED
	| typeof ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_LIST
	| typeof ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_LIST_EMBED
	| typeof ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_SAMPLE_ONBOARDING
	| typeof ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_SHORTCUT
	| typeof ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_SUMMARY
	| typeof ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_SUMMARY_EMBED
	| typeof ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_TIMELINE
	| typeof ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_TIMELINE_EMBED
	| typeof ROUTE_NAMES_CLASSIC_BUSINESS_REDIRECT
	| typeof ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_CODE
	| typeof ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_CORE_PROFORMA_FORMS
	| typeof ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_DATA_CLASSIFICATIONS
	| typeof ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_DETAILS
	| typeof ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_FEATURES
	| typeof ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_ISSUE_LAYOUT
	| typeof ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_NOTIFICATION_EMAIL
	| typeof ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_OPSGENIE
	| typeof ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_SOFTWARE_DETAILS
	| typeof ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_SOFTWARE_NOTIFICATIONS
	| typeof ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_SOFTWARE_NOTIFICATIONS_ISSUE_LOGS
	| typeof ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_SOFTWARE_PROFORMA_EDIT_FORMS
	| typeof ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_SOFTWARE_PROFORMA_FORMS
	| typeof ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_TOOLCHAIN
	| typeof ROUTE_NAMES_COMPASS_PRODUCT_SETTINGS
	| typeof ROUTE_NAMES_CONFLUENCE_PRODUCT_SETTINGS
	| typeof ROUTE_NAMES_CONNECT_GENERAL
	| typeof ROUTE_NAMES_CONNECT_GENERAL_INVALID
	| typeof ROUTE_NAMES_CONNECT_GENERAL_SERVICE_DESK_PROJECT
	| typeof ROUTE_NAMES_CONNECT_PROJECT
	| typeof ROUTE_NAMES_CREATE_PROJECT
	| typeof ROUTE_NAMES_CUSTOMER_SERVICE_ACCOUNTS
	| typeof ROUTE_NAMES_DASHBOARD
	| typeof ROUTE_NAMES_DASHBOARDS_DIRECTORY
	| typeof ROUTE_NAMES_DASHBOARDS_DIRECTORY_SECURE
	| typeof ROUTE_NAMES_DASHBOARD_EDIT
	| typeof ROUTE_NAMES_DASHBOARD_SELECTED
	| typeof ROUTE_NAMES_DASHBOARD_VIEW
	| typeof ROUTE_NAMES_DASHBOARD_VIEW_EMBED
	| typeof ROUTE_NAMES_DASHBOARD_WALLBOARD
	| typeof ROUTE_NAMES_DEVOPS_CONNECTED_PROJECT
	| typeof ROUTE_NAMES_EOC_ALERTS
	| typeof ROUTE_NAMES_EOC_ALERT_DETAIL
	| typeof ROUTE_NAMES_EOC_ALERT_ISSUE_SYNCING
	| typeof ROUTE_NAMES_EOC_ALERT_ISSUE_SYNCING_CREATE
	| typeof ROUTE_NAMES_EOC_ALERT_ISSUE_SYNCING_DETAIL
	| typeof ROUTE_NAMES_EOC_API_KEY_MANAGEMENT
	| typeof ROUTE_NAMES_EOC_ASSIGN_CUSTOM_ROLES
	| typeof ROUTE_NAMES_EOC_AUTOMATIONS
	| typeof ROUTE_NAMES_EOC_CUSTOM_ROLES
	| typeof ROUTE_NAMES_EOC_GLOBAL_ALERTS
	| typeof ROUTE_NAMES_EOC_GLOBAL_ALERT_DETAIL
	| typeof ROUTE_NAMES_EOC_GLOBAL_GROUP_DETAIL
	| typeof ROUTE_NAMES_EOC_GLOBAL_MAINTENANCE
	| typeof ROUTE_NAMES_EOC_GLOBAL_REPORTS
	| typeof ROUTE_NAMES_EOC_HEARTBEATS
	| typeof ROUTE_NAMES_EOC_INCOMING_CALL
	| typeof ROUTE_NAMES_EOC_INCOMING_CALL_DETAIL
	| typeof ROUTE_NAMES_EOC_INTEGRATIONS
	| typeof ROUTE_NAMES_EOC_INTEGRATION_CREATE
	| typeof ROUTE_NAMES_EOC_INTEGRATION_DETAIL
	| typeof ROUTE_NAMES_EOC_INTEGRATION_LIST
	| typeof ROUTE_NAMES_EOC_INTEGRATION_LOG
	| typeof ROUTE_NAMES_EOC_MAINTENANCE
	| typeof ROUTE_NAMES_EOC_ON_CALL
	| typeof ROUTE_NAMES_EOC_OVERVIEW
	| typeof ROUTE_NAMES_EOC_PERMISSIONS
	| typeof ROUTE_NAMES_EOC_POLICIES
	| typeof ROUTE_NAMES_EOC_REPORTS
	| typeof ROUTE_NAMES_EOC_REPORTS_TEAM
	| typeof ROUTE_NAMES_EOC_REPORTS_UNSUBSCRIBE
	| typeof ROUTE_NAMES_EOC_ROLE_BASED_NOTIFICATIONS
	| typeof ROUTE_NAMES_EOC_ROLE_BASED_NOTIFICATIONS_DETAIL
	| typeof ROUTE_NAMES_EOC_SETTINGS
	| typeof ROUTE_NAMES_EOC_TEAM_ALERTS
	| typeof ROUTE_NAMES_EOC_TEAM_ALERT_DETAILS
	| typeof ROUTE_NAMES_EOC_TEAM_ALERT_ISSUE_SYNCING
	| typeof ROUTE_NAMES_EOC_TEAM_ALERT_ISSUE_SYNCING_CREATE
	| typeof ROUTE_NAMES_EOC_TEAM_ALERT_ISSUE_SYNCING_DETAIL
	| typeof ROUTE_NAMES_EOC_TEAM_CUSTOMER_LOGS
	| typeof ROUTE_NAMES_EOC_TEAM_CUSTOMER_LOGS_DOWNLOAD
	| typeof ROUTE_NAMES_EOC_TEAM_GROUP_DETAIL
	| typeof ROUTE_NAMES_EOC_TEAM_INTEGRATIONS
	| typeof ROUTE_NAMES_EOC_TEAM_INTEGRATION_CREATE
	| typeof ROUTE_NAMES_EOC_TEAM_INTEGRATION_DETAIL
	| typeof ROUTE_NAMES_EOC_TEAM_INTEGRATION_LIST
	| typeof ROUTE_NAMES_EOC_TEAM_INTEGRATION_LOG
	| typeof ROUTE_NAMES_EOC_TEAM_POLICIES
	| typeof ROUTE_NAMES_EOC_UNLOCK_OPERATIONS
	| typeof ROUTE_NAMES_EOC_WHO_IS_ON_CALL
	| typeof ROUTE_NAMES_ERRORS_NOT_FOUND
	| typeof ROUTE_NAMES_EXTERNAL_IMPORT
	| typeof ROUTE_NAMES_FILTERS_DIRECTORY
	| typeof ROUTE_NAMES_FILTERS_DIRECTORY_SECURE
	| typeof ROUTE_NAMES_FORGE_ADMIN
	| typeof ROUTE_NAMES_FORGE_ADMIN_SETTINGS
	| typeof ROUTE_NAMES_FORGE_FIELD_CONTEXTUAL_CONFIGURATION
	| typeof ROUTE_NAMES_FORGE_GLOBAL
	| typeof ROUTE_NAMES_FORGE_PROJECT
	| typeof ROUTE_NAMES_FORGE_SERVICEDESK_QUEUE
	| typeof ROUTE_NAMES_GLOBAL_ADMIN_HELPER_EMAIL_NOTIFICATIONS_LOG
	| typeof ROUTE_NAMES_GLOBAL_ISSUE_NAVIGATOR
	| typeof ROUTE_NAMES_GLOBAL_ISSUE_NAVIGATOR_BROWSE_ISSUE_REDIRECT
	| typeof ROUTE_NAMES_GLOBAL_MAIL_SETTINGS
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_CONTACT_ADMINISTRATOR
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_CUSTOM_FIELDS
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_CUSTOM_FIELDS_ASSOCIATE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_CUSTOM_FIELDS_CONFIGURE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_CUSTOM_FIELDS_DELETE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_CUSTOM_FIELDS_EDIT
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_CUSTOM_FIELDS_MANAGE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_CUSTOM_FIELDS_TRANSLATE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_EDIT_PERMISSION_SCHEMES
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_FIELD_LAYOUTS
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_FIELD_LAYOUTS_CONFIGURE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_FIELD_LAYOUTS_COPY
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_FIELD_LAYOUT_SCHEMES
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_FIELD_LAYOUT_SCHEMES_CONFIGURE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_FIELD_LAYOUT_SCHEMES_COPY
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_FIELD_LAYOUT_SCHEMES_DELETE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_FIELD_LAYOUT_SCHEMES_EDIT
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_FIELD_LAYOUT_SCHEMES_EDIT_ENTRY
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_FIELD_LAYOUT_SCHEMES_VIEW
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_FORM_DATA_CONNECTIONS
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_GLOBAL_PERMISSIONS
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_LINK_TYPES
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_LINK_TYPES_ACTIVATE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_LINK_TYPES_DEACTIVATE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_LINK_TYPES_DELETE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_LINK_TYPES_EDIT
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_NEW_ISSUE_SEARCH
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_NEW_ISSUE_VIEW
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_NOTIFICATION_ADD
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_NOTIFICATION_DELETE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_NOTIFICATION_EDIT
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_NOTIFICATION_SCHEME_ADD
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_NOTIFICATION_SCHEME_DELETE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_NOTIFICATION_SCHEME_EDIT
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_NOTIFICATION_VIEW
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_PERMISSIONS_SCHEMES
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_PERMISSIONS_SCHEMES_ADD
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_PERMISSIONS_SCHEMES_DELETE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_PERMISSIONS_SCHEMES_EDIT
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_PRIORITIES
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_PRIORITIES_DELETE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_PRIORITIES_EDIT
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_PRIORITIES_TRANSLATE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_PRIORITY_SCHEMES
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_RESOLUTIONS
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_RESOLUTIONS_DEFAULT
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_RESOLUTIONS_DELETE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_RESOLUTIONS_EDIT
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_RESOLUTIONS_TRANSLATE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_SECURITY_EDIT
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_SECURITY_LEVEL_ADD
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_SECURITY_LEVEL_DELETE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_SECURITY_LEVEL_EDIT
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_SECURITY_SCHEMES
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_SECURITY_SCHEMES_ADD
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_SECURITY_SCHEMES_DELETE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_SECURITY_SCHEMES_EDIT
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_STATUSES
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_STATUSES_TRANSLATE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_FIELD_CONFIGURE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_FIELD_CONFIGURE_EDIT
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_FIELD_SCHEMES
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_FIELD_SCHEMES_ADD
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_FIELD_SCHEMES_CONFIGURE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_FIELD_SCHEMES_COPY
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_FIELD_SCHEMES_DELETE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_FIELD_SCHEMES_EDIT
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_FIELD_SCHEMES_EDIT_ITEM
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_FIELD_SCREENS
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_FIELD_SCREENS_COPY
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_FIELD_SCREENS_DELETE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_FIELD_SCREENS_EDIT
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_HIERARCHY
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_HIERARCHY_REDIRECT
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_SUBTASKS
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_SUBTASKS_DISABLE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_SUBTASKS_EDIT
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_SUBTASKS_TRANSLATE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TIME_TRACKING
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES_DELETE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES_EDIT
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES_SCHEMES
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES_SCHEMES_ADD_OR_COPY
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES_SCHEMES_ASSOCIATE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES_SCHEMES_DELETE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES_SCREEN_SCHEMES
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES_SCREEN_SCHEMES_CONFIGURE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES_SCREEN_SCHEMES_COPY
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES_SCREEN_SCHEMES_DELETE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES_SCREEN_SCHEMES_EDIT
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES_SCREEN_SCHEMES_ENTITY
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES_TRANSLATE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_WORKFLOW
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_WORKFLOW_EDIT
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_WORKFLOW_SCHEMES
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_WORKFLOW_SCHEMES_EDIT
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_WORKFLOW_TRANSITION
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_WORKFLOW_TRANSITION_META_ATTRIBUTES
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_MIGRATIONS_DASHBOARD
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_MIGRATIONS_FOCUS_PAGE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_MIGRATIONS_HOME
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_PERFORMANCE_CUSTOM_FIELD
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_PERFORMANCE_ISSUE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_PERFORMANCE_METRIC_DETAIL
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_PERFORMANCE_OVERVIEW
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_PERFORMANCE_PROJECT_CLEANUP
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_PERFORMANCE_PROJECT_ROLE_ACTOR
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_PERMISSION_SCHEMES
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_PRODUCTS_APPLICATION_LINKS
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_PRODUCTS_DVCS
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_PRODUCTS_SDMAIL
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_PRODUCTS_STATUS_PAGE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_PROJECTS_CATEGORIES_DELETE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_PROJECTS_CATEGORIES_EDIT
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_PROJECTS_CATEGORIES_VIEW
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SHARED_DASHBOARDS
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_ADMIN_NOTIFICATION_HELPER
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_ADMIN_PERMISSION_HELPER
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_ADVANCED_ATTACHMENTS
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_ADVANCED_EVENTS
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_ADVANCED_EVENTS_ADD
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_ADVANCED_EVENTS_DELETE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_ADVANCED_EVENTS_EDIT
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_ADVANCED_LEXORANK_MANAGEMENT
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_ADVANCED_SERVICES
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_ADVANCED_WEBHOOKS
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_BULK_MAIL
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_GENERAL_CONFIGURATION_ADVANCED_SETTINGS
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_GENERAL_CONFIGURATION_EDIT_SETTINGS
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_GLOBAL_PERMISSIONS
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_IMPORT_EXPORT_BACKUP_MANAGER
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_IMPORT_EXPORT_CLOUD_OR_SERVER_IMPORT
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_IMPORT_EXPORT_EXTERNAL_IMPORT
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_IMPORT_EXPORT_EXTERNAL_IMPORT_CSV
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_IMPORT_EXPORT_EXTERNAL_IMPORT_JSON
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_IMPORT_EXPORT_EXTERNAL_IMPORT_TRELLO
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_ISSUE_COLLECTORS
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_MAIL_DELETE_MAIL_HANDLER
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_MAIL_INCOMING_MAIL
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_MAIL_OUTGOING_MAIL
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_MAIL_SEND_MAIL
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_SECURITY_GLOBAL_PERMISSIONS_OLD
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_SECURITY_PROJECT_ROLES
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_SECURITY_PROJECT_ROLES_ACTORS
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_SECURITY_PROJECT_ROLES_ACTORS_GROUPS
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_SECURITY_PROJECT_ROLES_ACTORS_USERS
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_SECURITY_PROJECT_ROLES_DELETE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_SECURITY_PROJECT_ROLES_EDIT
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_SECURITY_PROJECT_ROLES_USAGE
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_SHARED_FILTERS
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_TROUBLESHOOTING_AUDIT_LOG
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_UI_ANNOUNCEMENT_BANNER
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_UI_DASHBOARD_EDIT
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_UI_LOOK_AND_FEEL
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_UI_PREFERENCES_APPLY
	| typeof ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_UI_USER_DEFAULTS
	| typeof ROUTE_NAMES_HELP_CENTER_SETTINGS
	| typeof ROUTE_NAMES_HELP_CENTER_SETTINGS_CUSTOMER_NOTIFICATIONS
	| typeof ROUTE_NAMES_HELP_CENTER_SETTINGS_EMAIL
	| typeof ROUTE_NAMES_HELP_CENTER_SETTINGS_KNOWLEDGE_BASE
	| typeof ROUTE_NAMES_HELP_CENTER_SETTINGS_REQUEST_TYPES
	| typeof ROUTE_NAMES_HELP_CENTER_SETTINGS_REQUEST_TYPE_FIELDS
	| typeof ROUTE_NAMES_HOME_ONBOARDING_REDIRECT
	| typeof ROUTE_NAMES_HOME_REDIRECT
	| typeof ROUTE_NAMES_ISSUE
	| typeof ROUTE_NAMES_ISSUE_CREATE_EMBED
	| typeof ROUTE_NAMES_ISSUE_EMBED
	| typeof ROUTE_NAMES_JIRA_SEARCH
	| typeof ROUTE_NAMES_KNOWLEDGE_HUB_ALL_ARTICLES
	| typeof ROUTE_NAMES_KNOWLEDGE_HUB_ARTICLE_EMBED_EDITOR
	| typeof ROUTE_NAMES_KNOWLEDGE_HUB_ARTICLE_IN_CATEGORY
	| typeof ROUTE_NAMES_KNOWLEDGE_HUB_BROWSE_ALL_ARTICLES
	| typeof ROUTE_NAMES_KNOWLEDGE_HUB_BROWSE_ALL_CATEGORIES
	| typeof ROUTE_NAMES_KNOWLEDGE_HUB_BROWSE_VIEW_ARTICLE
	| typeof ROUTE_NAMES_KNOWLEDGE_HUB_CATEGORY
	| typeof ROUTE_NAMES_KNOWLEDGE_HUB_CATEGORY_PAGE
	| typeof ROUTE_NAMES_KNOWLEDGE_HUB_DRAFTS
	| typeof ROUTE_NAMES_KNOWLEDGE_HUB_KNOWLEDGE_GAPS
	| typeof ROUTE_NAMES_KNOWLEDGE_HUB_LANDING
	| typeof ROUTE_NAMES_KNOWLEDGE_HUB_SEARCH
	| typeof ROUTE_NAMES_KNOWLEDGE_HUB_SEARCH_ARTICLES
	| typeof ROUTE_NAMES_KNOWLEDGE_HUB_SETUP
	| typeof ROUTE_NAMES_LEGACY_ALL_REPORTS
	| typeof ROUTE_NAMES_LEGACY_BACKLOG
	| typeof ROUTE_NAMES_LEGACY_BOARD
	| typeof ROUTE_NAMES_LEGACY_CONNECT_GENERAL
	| typeof ROUTE_NAMES_LEGACY_CONNECT_PROJECT
	| typeof ROUTE_NAMES_LEGACY_CORE_REPORTS
	| typeof ROUTE_NAMES_LEGACY_CUSTOM_REPORT
	| typeof ROUTE_NAMES_LEGACY_FALLBACK
	| typeof ROUTE_NAMES_LEGACY_GIN
	| typeof ROUTE_NAMES_LEGACY_GIN_ISSUE
	| typeof ROUTE_NAMES_LEGACY_GIN_QUICKSEARCH
	| typeof ROUTE_NAMES_LEGACY_GIN_SUBSCRIPTIONS
	| typeof ROUTE_NAMES_LEGACY_ISSUE
	| typeof ROUTE_NAMES_LEGACY_JSD_CUSTOMERS
	| typeof ROUTE_NAMES_LEGACY_JSD_CUSTOMER_PERMISSIONS
	| typeof ROUTE_NAMES_LEGACY_JSD_GETTING_STARTED
	| typeof ROUTE_NAMES_LEGACY_JSD_JIRA_AUTOMATION
	| typeof ROUTE_NAMES_LEGACY_JSD_KNOWLEDGE
	| typeof ROUTE_NAMES_LEGACY_JSD_QUEUES
	| typeof ROUTE_NAMES_LEGACY_JSD_QUEUES_MANAGE
	| typeof ROUTE_NAMES_LEGACY_JSD_REPORTS
	| typeof ROUTE_NAMES_LEGACY_JSD_REPORTS_CUSTOM
	| typeof ROUTE_NAMES_LEGACY_JSD_SETTINGS_FALLBACK
	| typeof ROUTE_NAMES_LEGACY_JSD_SETTINGS_LANGUAGE
	| typeof ROUTE_NAMES_LEGACY_JSD_SETTINGS_LANGUAGE_PAGES
	| typeof ROUTE_NAMES_LEGACY_JSD_SETTINGS_PORTAL
	| typeof ROUTE_NAMES_LEGACY_JSD_WIDGETS
	| typeof ROUTE_NAMES_LEGACY_PIN
	| typeof ROUTE_NAMES_LEGACY_PROJECT_RELEASES
	| typeof ROUTE_NAMES_LEGACY_PROJECT_RELEASES_BAMBOO
	| typeof ROUTE_NAMES_LEGACY_PROJECT_RELEASES_NOTE
	| typeof ROUTE_NAMES_LEGACY_PROJECT_RELEASES_NOTE_CONFIG
	| typeof ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_COLLECTORS
	| typeof ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_COLLECTORS_ADD
	| typeof ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_COLLECTORS_HELP
	| typeof ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_COLLECTORS_VIEW
	| typeof ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_CONFIG
	| typeof ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_DATA_CLASSIFICATIONS
	| typeof ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_DELETE
	| typeof ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_DEV
	| typeof ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_EDIT
	| typeof ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_FIELDS
	| typeof ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_FIELDS_ASSOCIATE
	| typeof ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_LAYOUT
	| typeof ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_SECURITY
	| typeof ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_SECURITY_ASSOCIATE
	| typeof ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_TYPES
	| typeof ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_TYPES_ASSOCIATE
	| typeof ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_NOTIFICATIONS
	| typeof ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_NOTIFICATIONS_ASSOCIATE
	| typeof ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_OPSGENIE
	| typeof ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_PEOPLE
	| typeof ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_PERMISSIONS
	| typeof ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_PERMISSIONS_ASSOCIATE
	| typeof ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_SCREENS
	| typeof ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_SCREENS_ASSOCIATE
	| typeof ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_SUMMARY
	| typeof ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_WORKFLOWS
	| typeof ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_WORKFLOWS_ASSOCIATE
	| typeof ROUTE_NAMES_LEGACY_REDIRECT
	| typeof ROUTE_NAMES_LEGACY_REPORTS_AVERAGE_AGE
	| typeof ROUTE_NAMES_LEGACY_REPORTS_CONFIG
	| typeof ROUTE_NAMES_LEGACY_REPORTS_CREATED_VS_RESOLVED
	| typeof ROUTE_NAMES_LEGACY_REPORTS_DEVELOPER_WORKLOAD
	| typeof ROUTE_NAMES_LEGACY_REPORTS_PIE
	| typeof ROUTE_NAMES_LEGACY_REPORTS_RECENTLY_CREATED
	| typeof ROUTE_NAMES_LEGACY_REPORTS_RESOLUTION_TIME
	| typeof ROUTE_NAMES_LEGACY_REPORTS_SINGLE_LEVEL_GROUP_BY
	| typeof ROUTE_NAMES_LEGACY_REPORTS_TIME_SINCE
	| typeof ROUTE_NAMES_LEGACY_REPORTS_TIME_TRACKING_REPORT
	| typeof ROUTE_NAMES_LEGACY_REPORTS_VERSION_WORKLOAD
	| typeof ROUTE_NAMES_LEGACY_REPORTS_WORKLOAD_PIE
	| typeof ROUTE_NAMES_LOGIN_EMBED
	| typeof ROUTE_NAMES_MARKETPLACE_ADDONS_EMCEE
	| typeof ROUTE_NAMES_MARKETPLACE_ADDONS_EMCEE_PUBLIC
	| typeof ROUTE_NAMES_MARKETPLACE_APP_REQUESTS
	| typeof ROUTE_NAMES_MARKETPLACE_INDEX
	| typeof ROUTE_NAMES_MARKETPLACE_MANAGE_APPS
	| typeof ROUTE_NAMES_MARKETPLACE_PROMOTIONS
	| typeof ROUTE_NAMES_NOTIFICATION_LOGS
	| typeof ROUTE_NAMES_OPSGENIE_SCHEDULE
	| typeof ROUTE_NAMES_PEOPLE_INDEX
	| typeof ROUTE_NAMES_PERSONAL_SETTINGS
	| typeof ROUTE_NAMES_PERSONAL_SETTINGS_GENERAL
	| typeof ROUTE_NAMES_PERSONAL_SETTINGS_NOTIFICATIONS
	| typeof ROUTE_NAMES_PERSONAL_SETTINGS_OPS_NOTIFICATIONS
	| typeof ROUTE_NAMES_PLANS_DIRECTORY
	| typeof ROUTE_NAMES_PLAYBOOKS_EDIT
	| typeof ROUTE_NAMES_PLAYBOOKS_LIST
	| typeof ROUTE_NAMES_PLAYBOOKS_LOGS
	| typeof ROUTE_NAMES_POLARIS_COLLECTIONS
	| typeof ROUTE_NAMES_POLARIS_IDEAS
	| typeof ROUTE_NAMES_POLARIS_INSIGHTS
	| typeof ROUTE_NAMES_POLARIS_ONBOARDING
	| typeof ROUTE_NAMES_POLARIS_PLAYS
	| typeof ROUTE_NAMES_POLARIS_ROOMS
	| typeof ROUTE_NAMES_POLARIS_SETTINGS_ACCESS
	| typeof ROUTE_NAMES_POLARIS_SETTINGS_GLOBAL_FIELDS
	| typeof ROUTE_NAMES_POLARIS_UNLICENSED
	| typeof ROUTE_NAMES_PRODUCTS_JSM_AUTHENTICATION_SETTINGS
	| typeof ROUTE_NAMES_PRODUCTS_JSM_CHATOPS_AND_VIDEOS_TOOLS
	| typeof ROUTE_NAMES_PRODUCTS_JSM_COMPLIANCE
	| typeof ROUTE_NAMES_PRODUCTS_JSM_CONFIGURATION
	| typeof ROUTE_NAMES_PRODUCTS_JSM_CUSTOMER_ACCESS
	| typeof ROUTE_NAMES_PRODUCTS_JSM_EMAIL_REQUESTS
	| typeof ROUTE_NAMES_PRODUCTS_JSM_FEATURE_USAGE
	| typeof ROUTE_NAMES_PRODUCTS_JSM_GLOBAL_OPS_REPORTS
	| typeof ROUTE_NAMES_PRODUCTS_JSM_INCIDENT_MANAGEMENT_TOOLS
	| typeof ROUTE_NAMES_PRODUCTS_JSM_KB_PERMISSIONS
	| typeof ROUTE_NAMES_PRODUCTS_JSM_ORGANIZATIONS
	| typeof ROUTE_NAMES_PRODUCTS_JSW_CONFIGURATION
	| typeof ROUTE_NAMES_PRODUCTS_JSW_STORAGE_MANAGEMENT
	| typeof ROUTE_NAMES_PROFORMA_EXPORT
	| typeof ROUTE_NAMES_PROFORMA_ISSUE_FORMS_DIRECT
	| typeof ROUTE_NAMES_PROFORMA_ISSUE_FORMS_DIRECT_EMBED
	| typeof ROUTE_NAMES_PROFORMA_ISSUE_FORMS_GENERAL
	| typeof ROUTE_NAMES_PROJECTS_DIRECTORY
	| typeof ROUTE_NAMES_PROJECTS_DIRECTORY_LEGACY_PATH_SPA
	| typeof ROUTE_NAMES_PROJECTS_DIRECTORY_MANAGE
	| typeof ROUTE_NAMES_PROJECTS_REDIRECT
	| typeof ROUTE_NAMES_PROJECT_COMPONENTS
	| typeof ROUTE_NAMES_PROJECT_DIRECTORY_REDIRECT
	| typeof ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR
	| typeof ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR_CLASSIC
	| typeof ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR_CORE
	| typeof ROUTE_NAMES_PROJECT_PAGES
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_AUTOMATION
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_COMPONENTS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_CORE_ACCESS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_CORE_ACCESS_REDIRECT
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_CORE_APPROVALS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_CORE_APPS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_CORE_APPS_FIELDS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_CORE_AUTOMATION
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_CORE_DATA_CLASSIFICATIONS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_CORE_DEFAULT_REDIRECT
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_CORE_DETAILS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_CORE_FIELDS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_CORE_FORGE_APPS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_CORE_ISSUE_TYPES
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_CORE_ISSUE_TYPES_WORKFLOW
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_CORE_NOTIFICATIONS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_CORE_NOTIFICATIONS_ISSUE_LOGS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_CORE_NOTIFICATION_EMAIL
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_CORE_PROFORMA_JIRA_FORM_BUILDER
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_CORE_PROFORMA_PROJECT_FORMS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_FORGE_APPS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_FORGE_APPS_NEXTGEN
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_APPS_FIELDS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_CHAT
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_CLASSIC_CUSTOMER_NOTIFICATIONS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_CLASSIC_EMAIL_SETTINGS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_DETAILS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_FORGE_APPS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_ITSM_REQUEST_TYPES
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_ITSM_REQUEST_TYPES_FIELDS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_NOTIFICATIONS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_NOTIFICATIONS_NOTIFICATION_EMAIL
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_PORTAL_SETTINGS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_REQUEST_TYPES
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_REQUEST_TYPES_FIELDS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_UNASSIGNED_REQUEST_TYPES
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_UNASSIGNED_REQUEST_TYPES_FIELDS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_ACCESS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_APPS_FIELDS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_AUTOMATION
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_BOARDS_CARD
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_BOARDS_CSM
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_BOARDS_CUSTOM_FILTERS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_BOARDS_DETAILS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_BOARDS_FEATURES
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_BOARDS_INSIGHTS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_BOARDS_REDIRECT
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_BOARDS_ROADMAP
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_BOARDS_SWIMLANES
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_BOARDS_TIMELINE
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_DATA_CLASSIFICATIONS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_DETAILS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_FEATURES
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_ISSUE_TYPES
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_NOTIFICATIONS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_NOTIFICATIONS_ISSUE_LOGS
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_NOTIFICATION_EMAIL
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_OPSGENIE
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_TOOLCHAIN
	| typeof ROUTE_NAMES_PROJECT_SETTINGS_VERSIONS
	| typeof ROUTE_NAMES_PROJECT_VERSIONS
	| typeof ROUTE_NAMES_RAPIDBOARD
	| typeof ROUTE_NAMES_RAPIDBOARD_BACKLOG
	| typeof ROUTE_NAMES_RAPIDBOARD_BOARD
	| typeof ROUTE_NAMES_RAPIDBOARD_BOARD_PRETTY_URL_REDIRECT
	| typeof ROUTE_NAMES_RAPIDBOARD_LAST_VISITED_PROJECT_REDIRECT
	| typeof ROUTE_NAMES_RAPIDBOARD_REPORT
	| typeof ROUTE_NAMES_RAPIDBOARD_UIF_BACKLOG
	| typeof ROUTE_NAMES_RAPIDBOARD_USER_BACKLOG
	| typeof ROUTE_NAMES_RAPIDBOARD_USER_BOARD
	| typeof ROUTE_NAMES_RAPIDBOARD_USER_REPORT
	| typeof ROUTE_NAMES_SERVICEDESK_ATLASSIAN_ANALYTICS
	| typeof ROUTE_NAMES_SERVICEDESK_BOARDS
	| typeof ROUTE_NAMES_SERVICEDESK_CALENDAR
	| typeof ROUTE_NAMES_SERVICEDESK_CALENDAR_EAP
	| typeof ROUTE_NAMES_SERVICEDESK_CHANGE_CALENDAR
	| typeof ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_CONFIGURE
	| typeof ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT
	| typeof ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT_SCHEMA
	| typeof ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT_SCHEMA_CONFIGURATION
	| typeof ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT_TYPE_CONFIGURATION
	| typeof ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_REPORTS
	| typeof ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_REPORTS_DEFAULT_REDIRECT
	| typeof ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_REPORTS_UNSUBSCRIBE
	| typeof ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT
	| typeof ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_CONFIGURE
	| typeof ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_LANDING_PAGE
	| typeof ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT
	| typeof ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT_SCHEMA
	| typeof ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT_SCHEMA_CONFIGURATION
	| typeof ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT_TYPE_CONFIGURATION
	| typeof ROUTE_NAMES_SERVICEDESK_CONNECT_PROJECT
	| typeof ROUTE_NAMES_SERVICEDESK_CREATE_PROJECT
	| typeof ROUTE_NAMES_SERVICEDESK_CUSTOMERS_V2
	| typeof ROUTE_NAMES_SERVICEDESK_CUSTOMER_DETAILS
	| typeof ROUTE_NAMES_SERVICEDESK_CUSTOMER_SERVICE_ESCALATIONS
	| typeof ROUTE_NAMES_SERVICEDESK_CUSTOMER_SERVICE_PRODUCTS
	| typeof ROUTE_NAMES_SERVICEDESK_EARLY_ACCESS_ITSM
	| typeof ROUTE_NAMES_SERVICEDESK_GETTING_STARTED_PAGE
	| typeof ROUTE_NAMES_SERVICEDESK_INCIDENTS
	| typeof ROUTE_NAMES_SERVICEDESK_INDEX
	| typeof ROUTE_NAMES_SERVICEDESK_ISSUE
	| typeof ROUTE_NAMES_SERVICEDESK_ISSUES
	| typeof ROUTE_NAMES_SERVICEDESK_ITSM_SAMPLE_SPACE
	| typeof ROUTE_NAMES_SERVICEDESK_JIRA_AUTOMATION
	| typeof ROUTE_NAMES_SERVICEDESK_KB_REQUESTS_REPORTS
	| typeof ROUTE_NAMES_SERVICEDESK_KB_REQUESTS_REPORTS_ISSUE
	| typeof ROUTE_NAMES_SERVICEDESK_KB_REQUESTS_REPORTS_TIME_SCALE
	| typeof ROUTE_NAMES_SERVICEDESK_ONCALL_SCHEDULE
	| typeof ROUTE_NAMES_SERVICEDESK_ORGANIZATIONS
	| typeof ROUTE_NAMES_SERVICEDESK_ORGANIZATION_DETAILS
	| typeof ROUTE_NAMES_SERVICEDESK_ORGANIZATION_V2
	| typeof ROUTE_NAMES_SERVICEDESK_PRACTICE_CUSTOM_AND_ISSUE
	| typeof ROUTE_NAMES_SERVICEDESK_PRACTICE_EDIT
	| typeof ROUTE_NAMES_SERVICEDESK_PRACTICE_ISSUE
	| typeof ROUTE_NAMES_SERVICEDESK_PRACTICE_LANDING
	| typeof ROUTE_NAMES_SERVICEDESK_PRACTICE_MANAGE_VIEW
	| typeof ROUTE_NAMES_SERVICEDESK_PRACTICE_NEW
	| typeof ROUTE_NAMES_SERVICEDESK_PROJECT_CONVERSION
	| typeof ROUTE_NAMES_SERVICEDESK_QUEUES_ADDON
	| typeof ROUTE_NAMES_SERVICEDESK_QUEUES_CUSTOM_AND_ISSUE
	| typeof ROUTE_NAMES_SERVICEDESK_QUEUES_EDIT
	| typeof ROUTE_NAMES_SERVICEDESK_QUEUES_ISSUE
	| typeof ROUTE_NAMES_SERVICEDESK_QUEUES_LANDING
	| typeof ROUTE_NAMES_SERVICEDESK_QUEUES_MANAGE_VIEW
	| typeof ROUTE_NAMES_SERVICEDESK_QUEUES_NEW
	| typeof ROUTE_NAMES_SERVICEDESK_REDIRECT
	| typeof ROUTE_NAMES_SERVICEDESK_REPORTS
	| typeof ROUTE_NAMES_SERVICEDESK_REPORTS_CUSTOM
	| typeof ROUTE_NAMES_SERVICEDESK_REPORTS_CUSTOM_CREATE
	| typeof ROUTE_NAMES_SERVICEDESK_REPORTS_CUSTOM_EDIT
	| typeof ROUTE_NAMES_SERVICEDESK_REPORTS_CUSTOM_ISSUE
	| typeof ROUTE_NAMES_SERVICEDESK_REPORTS_CUSTOM_TIME_SCALE
	| typeof ROUTE_NAMES_SERVICEDESK_REPORTS_SATISFACTION
	| typeof ROUTE_NAMES_SERVICEDESK_REPORTS_SATISFACTION_ISSUE
	| typeof ROUTE_NAMES_SERVICEDESK_REPORTS_SATISFACTION_TIME_SCALE
	| typeof ROUTE_NAMES_SERVICEDESK_REPORTS_SUMMARY
	| typeof ROUTE_NAMES_SERVICEDESK_REPORTS_TYPE
	| typeof ROUTE_NAMES_SERVICEDESK_REPORTS_WORKLOAD
	| typeof ROUTE_NAMES_SERVICEDESK_SERVICES
	| typeof ROUTE_NAMES_SERVICEDESK_SERVICES_NEW
	| typeof ROUTE_NAMES_SERVICEDESK_SERVICES_WITHOUT_PROJECT
	| typeof ROUTE_NAMES_SERVICEDESK_SERVICE_DETAILS
	| typeof ROUTE_NAMES_SERVICEDESK_SERVICE_DETAILS_NEW
	| typeof ROUTE_NAMES_SERVICEDESK_SERVICE_DETAILS_WITHOUT_PROJECT
	| typeof ROUTE_NAMES_SERVICEDESK_SERVICE_MODAL_EDIT
	| typeof ROUTE_NAMES_SERVICEDESK_SERVICE_MODAL_NEW
	| typeof ROUTE_NAMES_SERVICEDESK_SLA_SETTINGS
	| typeof ROUTE_NAMES_SERVICE_HUB_SERVICES
	| typeof ROUTE_NAMES_SERVICE_HUB_SERVICE_DETAILS
	| typeof ROUTE_NAMES_SETTINGS_AUTOMATION
	| typeof ROUTE_NAMES_SETTINGS_CMDB_FIELD_CONFIG
	| typeof ROUTE_NAMES_SETTINGS_OAUTH_CREDENTIALS
	| typeof ROUTE_NAMES_SETTINGS_RECOMMENDATIONS
	| typeof ROUTE_NAMES_SETTINGS_VIEW_CUSTOM_FIELDS
	| typeof ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS
	| typeof ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS_GETTING_STARTED_REDIRECT
	| typeof ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS_REDIRECT
	| typeof ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS_SETUP
	| typeof ROUTE_NAMES_SOFTWARE_ARJ_SETTINGS_DEPENDENCIES
	| typeof ROUTE_NAMES_SOFTWARE_ARJ_SETTINGS_DEPENDENCIES_REDIRECT
	| typeof ROUTE_NAMES_SOFTWARE_ARJ_SETTINGS_FINANCIAL_YEAR
	| typeof ROUTE_NAMES_SOFTWARE_ARJ_SETTINGS_LOADING_LIMITS
	| typeof ROUTE_NAMES_SOFTWARE_ARJ_SETTINGS_LOADING_LIMITS_REDIRECT
	| typeof ROUTE_NAMES_SOFTWARE_ARJ_SETTINGS_PERMISSIONS
	| typeof ROUTE_NAMES_SOFTWARE_ARJ_SETTINGS_PERMISSIONS_REDIRECT
	| typeof ROUTE_NAMES_SOFTWARE_BACKLOG
	| typeof ROUTE_NAMES_SOFTWARE_BOARDS
	| typeof ROUTE_NAMES_SOFTWARE_CALENDAR
	| typeof ROUTE_NAMES_SOFTWARE_CALENDAR_CLASSIC
	| typeof ROUTE_NAMES_SOFTWARE_CALENDAR_CLASSIC_EMBED
	| typeof ROUTE_NAMES_SOFTWARE_CALENDAR_EMBED
	| typeof ROUTE_NAMES_SOFTWARE_CLASSIC_PROFORMA_ISSUE_FORMS_DIRECT
	| typeof ROUTE_NAMES_SOFTWARE_CLASSIC_PROFORMA_ISSUE_FORMS_DIRECT_EMBED
	| typeof ROUTE_NAMES_SOFTWARE_CODE
	| typeof ROUTE_NAMES_SOFTWARE_CODE_CLASSIC
	| typeof ROUTE_NAMES_SOFTWARE_DEPLOYMENTS
	| typeof ROUTE_NAMES_SOFTWARE_DEPLOYMENTS_CLASSIC
	| typeof ROUTE_NAMES_SOFTWARE_GOALS
	| typeof ROUTE_NAMES_SOFTWARE_GOALS_CLASSIC
	| typeof ROUTE_NAMES_SOFTWARE_INCIDENTS
	| typeof ROUTE_NAMES_SOFTWARE_INCIDENTS_CLASSIC
	| typeof ROUTE_NAMES_SOFTWARE_ISSUE_NAVIGATOR
	| typeof ROUTE_NAMES_SOFTWARE_LIST
	| typeof ROUTE_NAMES_SOFTWARE_LIST_CLASSIC
	| typeof ROUTE_NAMES_SOFTWARE_LIST_CLASSIC_EMBED
	| typeof ROUTE_NAMES_SOFTWARE_LIST_EMBED
	| typeof ROUTE_NAMES_SOFTWARE_NEXTGEN_REDIRECT
	| typeof ROUTE_NAMES_SOFTWARE_ONBOARDING
	| typeof ROUTE_NAMES_SOFTWARE_PERISCOPE
	| typeof ROUTE_NAMES_SOFTWARE_PERISCOPE_CLASSIC
	| typeof ROUTE_NAMES_SOFTWARE_PLAN_CALENDAR
	| typeof ROUTE_NAMES_SOFTWARE_PLAN_DEPENDENCIES
	| typeof ROUTE_NAMES_SOFTWARE_PLAN_INCREMENT
	| typeof ROUTE_NAMES_SOFTWARE_PLAN_INCREMENTS
	| typeof ROUTE_NAMES_SOFTWARE_PLAN_RELEASES
	| typeof ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_AUTO_SCHEDULER
	| typeof ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_CUSTOM_FIELDS
	| typeof ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_EXCLUSION_RULES
	| typeof ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_FIND_YOUR_ISSUE
	| typeof ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_ISSUE_SOURCES
	| typeof ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_PERMISSIONS
	| typeof ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_PLAN_INFO
	| typeof ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_PROGRAM_BOARDS
	| typeof ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_REMOVED_ISSUES
	| typeof ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_SAVED_VIEWS
	| typeof ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_SCENARIOS
	| typeof ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_SCHEDULING
	| typeof ROUTE_NAMES_SOFTWARE_PLAN_SUMMARY
	| typeof ROUTE_NAMES_SOFTWARE_PLAN_TEAMS
	| typeof ROUTE_NAMES_SOFTWARE_PLAN_TIMELINE
	| typeof ROUTE_NAMES_SOFTWARE_PRESENTATION_TOOLKIT_REPORT
	| typeof ROUTE_NAMES_SOFTWARE_RELEASE_NOTES
	| typeof ROUTE_NAMES_SOFTWARE_REPORTS
	| typeof ROUTE_NAMES_SOFTWARE_REPORTS_BURNDOWN
	| typeof ROUTE_NAMES_SOFTWARE_REPORTS_BURNUP
	| typeof ROUTE_NAMES_SOFTWARE_REPORTS_CUMULATIVE
	| typeof ROUTE_NAMES_SOFTWARE_REPORTS_CYCLE_TIME
	| typeof ROUTE_NAMES_SOFTWARE_REPORTS_CYCLE_TIME_CLASSIC
	| typeof ROUTE_NAMES_SOFTWARE_REPORTS_DEPLOYMENT_FREQUENCY
	| typeof ROUTE_NAMES_SOFTWARE_REPORTS_DEPLOYMENT_FREQUENCY_CLASSIC
	| typeof ROUTE_NAMES_SOFTWARE_REPORTS_VELOCITY
	| typeof ROUTE_NAMES_SOFTWARE_REQUESTS
	| typeof ROUTE_NAMES_SOFTWARE_REQUESTS_CLASSIC
	| typeof ROUTE_NAMES_SOFTWARE_ROADMAP
	| typeof ROUTE_NAMES_SOFTWARE_ROADMAP_CLASSIC
	| typeof ROUTE_NAMES_SOFTWARE_ROADMAP_REDIRECT
	| typeof ROUTE_NAMES_SOFTWARE_SECURITY
	| typeof ROUTE_NAMES_SOFTWARE_SECURITY_CLASSIC
	| typeof ROUTE_NAMES_SOFTWARE_TIMELINE
	| typeof ROUTE_NAMES_SOFTWARE_TIMELINE_CLASSIC
	| typeof ROUTE_NAMES_SOFTWARE_VERSION_DETAIL
	| typeof ROUTE_NAMES_SOFTWARE_VERSION_DETAIL_LEGACY_REDIRECT
	| typeof ROUTE_NAMES_SOFTWARE_VERSION_DETAIL_NO_TAB_PATH
	| typeof ROUTE_NAMES_SYSTEM_CONFIGURATION
	| typeof ROUTE_NAMES_SYSTEM_DASHBOARD_EDIT
	| typeof ROUTE_NAMES_TRASHED_PLAN_DIRECT_LINK
	| typeof ROUTE_NAMES_TRASH_PLANS_DIRECTORY
	| typeof ROUTE_NAMES_TRASH_PROJECTS_DIRECTORY
	| typeof ROUTE_NAMES_USER_SEGMENTATION
	| typeof ROUTE_NAMES_YOUR_WORK;
export type RouteGroups =
	| typeof ROUTE_GROUPS_ARCHIVE_ISSUES
	| typeof ROUTE_GROUPS_ARJ_SETTINGS
	| typeof ROUTE_GROUPS_CLASSIC_BUSINESS
	| typeof ROUTE_GROUPS_CLASSIC_SOFTWARE
	| typeof ROUTE_GROUPS_COMPONENTS
	| typeof ROUTE_GROUPS_CONNECT
	| typeof ROUTE_GROUPS_DASHBOARD
	| typeof ROUTE_GROUPS_DASHBOARDS
	| typeof ROUTE_GROUPS_DEVOPS
	| typeof ROUTE_GROUPS_DIRECTORIES
	| typeof ROUTE_GROUPS_EOC
	| typeof ROUTE_GROUPS_ERRORS
	| typeof ROUTE_GROUPS_GLOBAL_SETTINGS
	| typeof ROUTE_GROUPS_HELP_CENTER_SETTINGS
	| typeof ROUTE_GROUPS_HOME
	| typeof ROUTE_GROUPS_ISSUE
	| typeof ROUTE_GROUPS_ISSUE_NAVIGATOR
	| typeof ROUTE_GROUPS_LEGACY
	| typeof ROUTE_GROUPS_LEGACY_SERVICEDESK_SETTINGS
	| typeof ROUTE_GROUPS_MARKETPLACE
	| typeof ROUTE_GROUPS_PEOPLE_PROFILES
	| typeof ROUTE_GROUPS_PERSONAL_SETTINGS
	| typeof ROUTE_GROUPS_PLAN_SETTINGS
	| typeof ROUTE_GROUPS_POLARIS
	| typeof ROUTE_GROUPS_PROFORMA
	| typeof ROUTE_GROUPS_PROJECT_SETTINGS_CLASSIC
	| typeof ROUTE_GROUPS_PROJECT_SETTINGS_CORE
	| typeof ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK
	| typeof ROUTE_GROUPS_PROJECT_SETTINGS_SOFTWARE
	| typeof ROUTE_GROUPS_PROJECT_SETTINGS_SOFTWARE_BOARDS
	| typeof ROUTE_GROUPS_REDIRECTS
	| typeof ROUTE_GROUPS_SERVICEDESK
	| typeof ROUTE_GROUPS_SERVICES
	| typeof ROUTE_GROUPS_SOFTWARE
	| typeof ROUTE_GROUPS_VERSIONS;
