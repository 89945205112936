import { GraphQLErrors } from '@atlassian/jira-business-graphql-errors';
import { log } from '@atlassian/jira-common-util-logging';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { performPostRequest } from '@atlassian/jira-fetch';
import { type ApplicationEditions, FREE_EDITION } from '@atlassian/jira-shared-types';
import { QUERY } from './gql';
import type { Response } from './types';

export const fetchStorageDetails = async (
	appEditions: ApplicationEditions,
): Promise<number | null> => {
	try {
		if (appEditions?.core !== FREE_EDITION) {
			return null;
		}

		const response = await performPostRequest<Response>('/rest/gira/latest?query', {
			body: JSON.stringify({
				query: QUERY,
				variables: {
					productKey: 'jira-core',
				},
			}),
			headers: {
				'content-type': 'application/json',
			},
		});

		if (response.errors != null) {
			const error = response.errors?.[0];
			const isClientError =
				error?.extensions?.statusCode >= 400 && error?.extensions?.statusCode < 500;

			if (!isClientError) {
				throw new GraphQLErrors(response.errors);
			}
		}

		const usageLimit = response?.data?.attachmentStorage;

		if (usageLimit && usageLimit?.allowed) {
			const percentageUsed = Math.round((usageLimit?.used / usageLimit.allowed) * 100);

			return percentageUsed;
		}

		return null;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		log.safeErrorWithoutCustomerData(
			'getStorageAmountService',
			'Something went wrong while fetching storage amount',
		);

		fireErrorAnalytics({
			meta: {
				id: 'getStorageAmountService',
				packageName: 'jiraRouterResourcesBusinessStorageDetails',
				teamName: 'jira-warepil',
			},
			error,
			sendToPrivacyUnsafeSplunk: true,
		});

		throw error;
	}
};
