import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type OpsTeamAutomationType from '@atlassian/jira-spa-apps-ops-team-scope-automation';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyOpsTeamAutomation = lazyForPaint<typeof OpsTeamAutomationType>(
	() =>
		import(
			/* webpackChunkName: "async-ops-team-scope-automation", jiraSpaEntry: "async-ops-team-scope-automation" */ '@atlassian/jira-spa-apps-ops-team-scope-automation'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazyOpsTeamAutomation} pageId="ops-team-automation" shouldShowSpinner />
);
