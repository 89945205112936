import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	viewAllProjectsLabel: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX - ENABLING FLAT LINT CONFIG
		id: 'spa-apps-sidebar-core.multi-project-menu.project-group.view-all-projects.view-all-projects-label',
		defaultMessage: 'View all projects',
		description: 'The label for the view all projects button.',
	},
});
