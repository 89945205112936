// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => `attachments(orderDirection: DESC, orderField: CREATED, maxResults: 12) {
        nodes {
          id
          filename
          mimetype
          mediaApiFileId
          createdDate
          filesize
          parentName
          author {
              accountId
          }
          parentId
          hasRestrictedParent
        }
        totalCount
        deletableCount
      }
`;
