// eslint-disable-next-line jira/restricted/@atlassian/jira-router
import { createResource, type Match, type Query } from '@atlassian/jira-router';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils';
import { fetchProjectSidebarConnectNavigationInfo } from '../../../services/sidebar/project-sidebar-connect-addons-navigation';
import type { ProjectSidebarAddonNavItem } from '../../../services/sidebar/project-sidebar-connect-addons-navigation/types';

const getIdentifier = (match: Match, query: Query): string => {
	const { projectKey, projectId } = getProjectKeyId(match, query);
	return projectKey || projectId;
};

export const staticJSMProjectConnectAddonsNavigationResource = createResource<
	ProjectSidebarAddonNavItem[] | null
>({
	type: 'NAVIGATION_SIDEBAR_JSM_PROJECT_CONNECT_ADDONS_QUEUES_RESOURCE',
	getKey: ({ match, query }) => getIdentifier(match, query) ?? 'none',
	getData: async ({ match, query }) => {
		const projectKeyOrId = getIdentifier(match, query);
		if (projectKeyOrId == null) {
			return null;
		}
		const navInfo = await fetchProjectSidebarConnectNavigationInfo(projectKeyOrId, 'jsm_queues');
		return navInfo;
	},
	maxAge: 5 * 60 * 1000,
});
