import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type OAuthCredentialsAdminType from '@atlassian/jira-spa-apps-admin-oauth-credentials';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyOAuthCredentialsAdmin = lazyForPaint<typeof OAuthCredentialsAdminType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-admin-oauth-credentials", jiraSpaEntry: "async-admin-oauth-credentials" */ '@atlassian/jira-spa-apps-admin-oauth-credentials'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazyOAuthCredentialsAdmin} pageId="admin-oauth-credentials" shouldShowSpinner />
);
