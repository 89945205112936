import React, { type ReactNode } from 'react';
import { Box, xcss } from '@atlaskit/primitives';

type Props = {
	children: ReactNode;
};

export const ScrollContainer = ({ children }: Props) => (
	<Box
		testId="navigation-apps-sidebar-nav4-common.ui.scroll-container"
		xcss={scrollContainerStyles}
	>
		{children}
	</Box>
);

const scrollContainerStyles = xcss({
	marginBlock: 'space.negative.100',
	paddingBlock: 'space.100',
	marginInline: 'space.negative.200',
	paddingInline: 'space.200',
	overflowY: 'auto',
	flexGrow: 1,
});
