import { createResource } from '@atlassian/jira-router';
import { RESOURCE_TYPE_SYSTEM_CONFIGURATION_GENERAL } from './constants';

export const systemConfigurationGeneralResource = createResource({
	type: RESOURCE_TYPE_SYSTEM_CONFIGURATION_GENERAL,
	getKey: () => 'state',
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-system-configuration-general", jiraSpaEntry: "async-resource-system-configuration-general" */ './controllers/get-data'
		),
});
