import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin';
import parameters from '@atlassian/jira-relay/src/__generated__/uiNotificationLogsPageQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const notificationLogsEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-notifications-log" */ './src'),
	),
	getPreloadProps: ({ context, tenantContext }: EntryPointRouteParams) => ({
		queries: {
			notificationLogs: {
				options: {
					fetchPolicy: 'network-only' as const,
				},
				parameters,
				variables: {
					cloudId: tenantContext.cloudId,
					projectIds: context?.query?.projectId,
					notificationType: 'INTERNAL_NOTIFICATION',
					notificationActionType: 'USER_INVITE',
					recipientId: '',
				},
			},
		},
		extraProps: {
			accountId: tenantContext.atlassianAccountId,
		},
	}),
});
