import { useCallback } from 'react';
import { graphql, useMutation } from 'react-relay';
import type { RecordSourceProxy } from 'relay-runtime';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import type {
	setRightPanelState_jiraGrowthRecommendationsInProjectList_Mutation,
	JiraProjectListRightPanelState,
} from '@atlassian/jira-relay/src/__generated__/setRightPanelState_jiraGrowthRecommendationsInProjectList_Mutation.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller';

const PROJECT_LIST_RIGHT_PANEL_STATE = 'projectListRightPanelState';

const relayStoreUpdater = (
	store: RecordSourceProxy,
	userPreferencesRefId: string | undefined,
	state: JiraProjectListRightPanelState,
) => {
	if (typeof userPreferencesRefId === 'string') {
		const record = store.get(userPreferencesRefId);
		record?.setValue(state, PROJECT_LIST_RIGHT_PANEL_STATE);
	}
};

export const useSetProjectListRightPanelState = (userPreferencesRefId: string | undefined) => {
	const cloudId = useCloudId();

	const [commit] = useMutation<setRightPanelState_jiraGrowthRecommendationsInProjectList_Mutation>(
		graphql`
			mutation setRightPanelState_jiraGrowthRecommendationsInProjectList_Mutation(
				$cloudId: ID!
				$state: JiraProjectListRightPanelState!
			) {
				jira {
					userPreferences(cloudId: $cloudId) @optIn(to: "JiraUserPreferences") {
						setProjectListRightPanelState(state: $state)
							@optIn(to: "JiraProjectListRightPanelState") {
							success
							errors {
								extensions {
									statusCode
								}
								message
							}
							projectListRightPanelState
						}
					}
				}
			}
		`,
	);

	const setRightPanelState = useCallback(
		(state: JiraProjectListRightPanelState) => {
			commit({
				variables: { cloudId, state },
				optimisticUpdater: (store) => relayStoreUpdater(store, userPreferencesRefId, state),
				updater: (
					store,
					{
						// @ts-expect-error TS-2339 - Property 'jira' does not exist on type 'setRightPanelState_jiraGrowthRecommendationsInProjectList_Mutation$data | null | undefined'.
						jira: {
							userPreferences: {
								setProjectListRightPanelState: { projectListRightPanelState },
							},
						},
					},
				) => relayStoreUpdater(store, userPreferencesRefId, projectListRightPanelState ?? state),
				onCompleted: ({
					jira: {
						// @ts-expect-error(Suppressed for relay bump) - Property 'userPreferences' does not exist on type '{ readonly userPreferences: { readonly setProjectListRightPanelState: { readonly errors: readonly { readonly extensions: { readonly statusCode: number | null | undefined; } | null | undefined; readonly message: string | null | undefined; }[] | null | undefined; readonly projectListRightPanelState: JiraProjectListRig...'.
						userPreferences: {
							setProjectListRightPanelState: { success, errors },
						},
					},
				}) => {
					const error = (errors ?? [])[0];
					if (!success || error) {
						const errorMessage = `error on set project list right panel state: ${error.message}`;
						const statusCode = error.extensions?.statusCode;

						fireErrorAnalytics({
							error: new Error(`status code: ${statusCode ?? 'unknown'}, ${errorMessage}`),
							meta: {
								id: 'jiraSetProjectListRightPanelStateError',
								packageName: 'jiraGrowthRecommendationsInProjectList',
							},
						});
					}
				},
				onError: (error: Error) => {
					fireErrorAnalytics({
						error: new Error(
							`jiraSetProjectListRightPanelStateMutationError with ${error.message}`,
						),
						meta: {
							id: 'jiraSetProjectListRightPanelStateMutationError',
							packageName: 'jiraGrowthRecommendationsInProjectList',
						},
					});
				},
			});
		},
		[cloudId, commit, userPreferencesRefId],
	);

	return setRightPanelState;
};
