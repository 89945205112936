import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const softwareReportsBurnupRoute = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_REPORTS_BURNUP,
	path: '/jira/software/projects/:projectKey/boards/:boardId/reports/burnup',
};

export const softwareReportsVelocityRoute = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_REPORTS_VELOCITY,
	path: '/jira/software/projects/:projectKey/boards/:boardId/reports/velocity',
};

export const softwareReportsCumulativeRoute = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_REPORTS_CUMULATIVE,
	path: '/jira/software/projects/:projectKey/boards/:boardId/reports/cumulative',
};

export const softwareReportsBurndownRoute = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_REPORTS_BURNDOWN,
	path: '/jira/software/projects/:projectKey/boards/:boardId/reports/burndown',
};

export const softwareReportsCycleTimeRoute = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_REPORTS_CYCLE_TIME,
	path: '/jira/software/projects/:projectKey/boards/:boardId/reports/cycle-time',
};

export const softwareReportsDeploymentFrequencyRoute = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_REPORTS_DEPLOYMENT_FREQUENCY,
	path: '/jira/software/projects/:projectKey/boards/:boardId/reports/deployment-frequency',
};

export const softwareReportsRoute = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_REPORTS,
	path: '/jira/software/projects/:projectKey/boards/:boardId/reports',
};

export const softwareNextgenRedirectRoute = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_NEXTGEN_REDIRECT,
	path: '/secure/NextGen.jspa',
};

export const softwareCodeRoute = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_CODE,
	path: '/jira/software/projects/:projectKey/code',
};

export const softwareRoadmapRedirectRoute = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_ROADMAP_REDIRECT,
	path: '/secure/Roadmap.jspa',
};
