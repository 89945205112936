import React, { memo } from 'react';
import { NestableNavigationContent, Section } from '@atlaskit/side-navigation';
import { useIntl } from '@atlassian/jira-intl';
import {
	Issues,
	IssuesContextualAnalyticsData,
} from '@atlassian/jira-navigation-apps-sidebar-common';
import type { Project } from '../../../../../common/types';
import { useAutoExpandSidebar } from '../../../../../controllers';
import messages from './messages';

const EMPTY_STACK: string[] = [];

type Props = {
	project: Project;
};

const IssuesMenu = ({ project }: Props) => {
	useAutoExpandSidebar();
	const { formatMessage } = useIntl();

	return (
		<NestableNavigationContent initialStack={EMPTY_STACK}>
			<Section>
				<IssuesContextualAnalyticsData navigatorScope="project">
					<Issues
						projectKey={project.key}
						pinUrl={`/jira/core/projects/${project.key}/issues/?filter=`}
						titleHeaderText={formatMessage(messages.issues)}
					/>
				</IssuesContextualAnalyticsData>
			</Section>
		</NestableNavigationContent>
	);
};

export default memo<Props>(IssuesMenu);
