import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';
import type { Route } from '@atlassian/jira-router';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const maintenanceRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_MAINTENANCE,
	path: '/jira/ops/teams/:teamId/maintenance',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const globalMaintenanceRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_GLOBAL_MAINTENANCE,
	path: '/jira/settings/products/ops/maintenance',
} as const as Route;
