// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getMetaNode = (name: any) => document.querySelector(`meta[name="${name}"]`);

const getHead = () => document.head || document.querySelector('head');
/**
 * add the meta tag element if it does not exist, or replace the old one if it exist
 */
export const addThemeColor = (content: string) => {
	if (!content || typeof document === 'undefined' || __SERVER__) {
		return;
	}
	const existingMeta = getMetaNode('theme-color');
	const meta = document.createElement('meta');
	meta.name = 'theme-color';
	meta.content = content;
	if (existingMeta) {
		existingMeta.replaceWith?.(meta);
	} else {
		const head = getHead();
		if (head) {
			head.appendChild?.(meta);
		}
	}
};
