/**
 * @generated SignedSource<<8b9db613230b8c02fa94e90fcf513d0f>>
 * @relayHash 7789a29bbeeff44939203994b8461e0c
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b187afdc70072a0b79a42592327d800ae9c660a7599b8452ad2d639998c01e64

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { ui_jiraCalendarQuery } from './ui_jiraCalendarQuery.graphql';

const node: PreloadableConcreteRequest<ui_jiraCalendarQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "b187afdc70072a0b79a42592327d800ae9c660a7599b8452ad2d639998c01e64",
    "metadata": {},
    "name": "ui_jiraCalendarQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
