import { ROUTE_NAMES } from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const servicedeskCustomersV2Route = {
	name: ROUTE_NAMES.SERVICEDESK_CUSTOMERS_V2,
	path: '/jira/servicedesk/projects/:projectKey/customers',
};

export const servicedeskCustomerDetailsRoute = {
	name: ROUTE_NAMES.SERVICEDESK_CUSTOMER_DETAILS,
	path: '/jira/servicedesk/projects/:projectKey/customers/:customerId',
};
