import { safeStringify } from '@atlassian/jira-errors-handling';
import { safeErrorWithoutCustomerData, SHOULD_LOG_ERROR } from './shared';

export type LoadBridgeBailOptions = {
	/**
	 * AMD name of the resource being loaded.
	 */
	name: string;
	shouldLogError?: boolean;
};

/**
 * Load an AMD module if the system has already loaded it, otherwise throw.
 * This is a weak load without retries.
 * It is only safe to use where there is some previous load of WRM that includes the required AMD
 * module.
 *
 * IMPORTANT - Does *not* support modules with nested definitions.
 * @see loadBridgeRace
 * @throws Error
 */
export const loadBridgeBail = async <T,>({
	name,
	shouldLogError = SHOULD_LOG_ERROR,
}: LoadBridgeBailOptions): Promise<T> => {
	try {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return window.require(name) as T;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		if (shouldLogError) {
			safeErrorWithoutCustomerData(
				'common.bridge',
				`Unable to load bridge "${name}". ${safeStringify(error) ?? ''}`,
				{
					isSpa: __SPA__,
					name,
					error: safeStringify(error),
				},
			);
		}
		throw new Error(`Unable to load bridge "${name}"`, { cause: error });
	}
};
