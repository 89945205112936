import React, { memo, useCallback } from 'react';
import { styled } from '@compiled/react';
import Lozenge from '@atlaskit/lozenge';
import { ButtonItem, PopupMenuGroup, Section } from '@atlaskit/menu';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { isFedRamp } from '@atlassian/atl-context';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize } from '@atlassian/jira-common-styles/src';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { OpenDrawerMethod } from '@atlassian/jira-project-create-drawer';
import { useIsAdmin, useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller';
import { LOCATION } from '../../../../../common/constants';
import type {
	ProjectTemplatesDataType,
	ProjectTypeTemplates,
} from '../../../../../services/project-templates/types';
import messages from './messages';

const POPUP_WIDTH = 272;

type Props = {
	data: ProjectTemplatesDataType;
	loading: boolean;
	openProjectCreateDrawer: OpenDrawerMethod;
};

export const TemplatePickerFallback = () => (
	<SpinnerWrapper>
		<Spinner size="medium" />
	</SpinnerWrapper>
);

const TemplatePicker = ({
	data: { templates, recommendationSessionId },
	loading,
	openProjectCreateDrawer,
}: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isSiteAdmin = useIsSiteAdmin();
	const isAdmin = useIsAdmin();

	// Exposure fired in BE
	const [expConfig] = UNSAFE_noExposureExp('cb_jira_template_recs_with_for_all_products');
	const shouldUseNewRecommendations = expConfig.get('useNewRecommendations', false);

	const handleTemplateClick = useCallback(
		(
			projectTypeTemplates: ProjectTypeTemplates,
			isLastUsed: boolean,
			isRecommended: boolean,
			templateType: string,
		) =>
			() => {
				openProjectCreateDrawer({
					isNextGen: true,
					selectedProjectType: 'business',
					showExperienceSelection: false,
					defaultSelectedTemplateKey:
						projectTypeTemplates?.teamManaged?.key || projectTypeTemplates?.companyManaged?.key,
					source: LOCATION,
					...(shouldUseNewRecommendations && { recommendationSessionId }),
				});
				fireUIAnalytics(
					createAnalyticsEvent({}),
					'button clicked',
					'leftNavOneClickProjectCreateTemplate',
					{
						selectedTemplateKey:
							projectTypeTemplates?.teamManaged?.key || projectTypeTemplates?.companyManaged?.key,
						isLastUsed,
						isRecommended,
						templateType,
						recommendationSessionId,
					},
				);
			},
		[
			createAnalyticsEvent,
			openProjectCreateDrawer,
			recommendationSessionId,
			shouldUseNewRecommendations,
		],
	);

	const handleMoreTemplatesClick = useCallback(() => {
		openProjectCreateDrawer({
			isNextGen: true,
			showExperienceSelection: false,
			source: LOCATION,
		});
		fireUIAnalytics(
			createAnalyticsEvent({}),
			'button clicked',
			'leftNavOneClickProjectCreateMoreTemplates',
		);
	}, [createAnalyticsEvent, openProjectCreateDrawer]);

	const handleImportButtonClick = useCallback(() => {
		openProjectCreateDrawer({
			isNextGen: true,
			showExperienceSelection: false,
			source: LOCATION,
			isImportProjectOpenInitially: true,
		});
		fireUIAnalytics(
			createAnalyticsEvent({}),
			'button clicked',
			'leftNavOneClickProjectCreateImport',
		);
	}, [createAnalyticsEvent, openProjectCreateDrawer]);

	return (
		<PopupMenuGroup
			minWidth={POPUP_WIDTH}
			testId="navigation-apps-sidebar-core.ui.multi-project-menu.projects-header.one-click-project.template-picker.popup-menu-group"
		>
			{loading ? (
				<TemplatePickerFallback />
			) : (
				<>
					<Section title={formatMessage(messages.sectionTitle)}>
						{Array.isArray(templates) &&
							templates.map(
								({
									iconUrl,
									key,
									projectTypeTemplates,
									title,
									isLastUsed = false,
									isRecommended = false,
								}) => (
									<ButtonItem
										testId="navigation-apps-sidebar-core.ui.multi-project-menu.projects-header.one-click-project.template-picker.button-item"
										iconBefore={<StyledImage src={iconUrl} alt={`${title.label} icon`} />}
										key={key}
										onClick={handleTemplateClick(
											projectTypeTemplates,
											isLastUsed,
											isRecommended,
											key,
										)}
									>
										<ButtonText>{title.label}</ButtonText>
										{isLastUsed && <Lozenge>{formatMessage(messages.lastCreated)}</Lozenge>}
									</ButtonItem>
								),
							)}
					</Section>
					<Section>
						<ButtonItem
							data-testid="navigation-apps-sidebar-core.ui.multi-project-menu.projects-header.one-click-project.template-picker.more-templates-button"
							onClick={handleMoreTemplatesClick}
						>
							{formatMessage(messages.moreTemplatesButtonText)}
						</ButtonItem>
						{(isSiteAdmin ||
							(isAdmin && ff('enable-product-admin-for-x2c-migration_zmlsv')) ||
							!isAdmin) &&
							!isFedRamp() && (
								<ButtonItem
									data-testid="navigation-apps-sidebar-core.ui.multi-project-menu.projects-header.one-click-project.template-picker.import-button"
									onClick={handleImportButtonClick}
								>
									{formatMessage(messages.importButtonText)}
								</ButtonItem>
							)}
					</Section>
				</>
			)}
		</PopupMenuGroup>
	);
};

export default memo<Props>(TemplatePicker);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledImage = styled.img({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 3}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 4}px`,
	objectFit: 'cover',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerWrapper = styled.div({
	width: `${POPUP_WIDTH}px`,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	padding: `${token('space.200', '16px')} 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonText = styled.span({
	marginRight: token('space.150', '12px'),
});
