import relayLogHandler, { requiredFieldLogger } from '@atlassian/jira-relay-log';
import { getNetwork } from '@atlassian/jira-relay-network';
import { getDefaultRelayEnvironmentConfig } from '@atlassian/relay-environment-config';

export const getRelayEnvironmentConfig = () =>
	getDefaultRelayEnvironmentConfig({
		network: getNetwork(),
		log: relayLogHandler,
		requiredFieldLogger,
	});
