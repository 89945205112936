import React from 'react';
// eslint-disable-next-line jira/import/no-restricted-paths
import { useIsFullyExpanded } from '../../../controllers/is-fully-expanded';
import { withNestingOptimisation } from '../with-nesting-optimisation';
import type { QuickstartNudgeProps } from './types';

export const QuickstartNudge = withNestingOptimisation<QuickstartNudgeProps>(
	({ renderWrapper, children }: QuickstartNudgeProps) => {
		const isFullyExpanded = useIsFullyExpanded();

		return isFullyExpanded ? renderWrapper(children) : <>{children}</>;
	},
);
