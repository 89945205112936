import { ff } from '@atlassian/jira-feature-flagging';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	get(): boolean {
		return ff(
			'handle-404-filter-errors-gracefully-in-the-ui-by-showing-filter-not-found-view_t3d2m',
		);
	},
};
