import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type { RouteContext } from '@atlassian/jira-router';
import type ServicedeskQueuesAddonViewType from '@atlassian/jira-spa-apps-servicedesk-queues-addon-view';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyServicedeskQueuesAddonView = lazyForPaint<typeof ServicedeskQueuesAddonViewType>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-queues-addon-view", jiraSpaEntry: "async-servicedesk-queues-addon-view" */ '@atlassian/jira-spa-apps-servicedesk-queues-addon-view'
		),
);

type Props = RouteContext;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ match }: Props) => {
	const addonKey = (match.params && match.params.addonKey) || '';
	return (
		<LazyPage
			Page={LazyServicedeskQueuesAddonView}
			pageId={`spa-apps/servicedesk/queues/addon/${addonKey}`}
			shouldShowSpinner
		/>
	);
};
