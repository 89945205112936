import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ServicedeskServiceDetailsWithoutProjectContextType from '@atlassian/jira-spa-apps-servicedesk-service-details-without-project-context';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyServicedeskServiceDetailsWithoutProjectContext = lazyForPaint<
	typeof ServicedeskServiceDetailsWithoutProjectContextType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-servicedesk-service-details-without-project-context", jiraSpaEntry: "async-servicedesk-service-details-without-project-context" */ '@atlassian/jira-spa-apps-servicedesk-service-details-without-project-context'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyServicedeskServiceDetailsWithoutProjectContext}
		pageId="spa-apps/servicedesk/service-details"
		shouldShowSpinner
	/>
);
