export enum ConditionType {
	featureFlag = 'featureFlag',
	featureGate = 'featureGate',
	buildRange = 'buildRange',
}

type ConditionBase = {
	type: ConditionType;
};

export type FeatureFlagCondition = ConditionBase & {
	featureFlagKey: string;
	affectedFeatureFlagValue: string | boolean;
};

export type FeatureGateCondition = ConditionBase & {
	featureGateKey: string;
	affectedFeatureGateValue: boolean;
};

export type BuildRangeCondition = ConditionBase & {
	buildRange: [string, string];
};

export type Condition = FeatureFlagCondition | FeatureGateCondition | BuildRangeCondition;

export type RemoteAction = {
	id: string;
	description: string;
	name: string;
	enabled: boolean;
	lastUpdatedAt: number;
	conditions: Condition[];
};

export type RemoteActionsResponse = {
	lastUpdatedAt: number;
	remoteActions: RemoteAction[];
};

export type GetFeatureFlagValue = (key: string) => unknown;
export type GetFeatureGateValue = (key: string) => boolean;

export type ProductData = {
	getFeatureFlagValue: GetFeatureFlagValue;
	getFeatureGateValue?: GetFeatureGateValue;
	getBuildKey: () => string;
	getBuildNumberFromKey: (buildKey: string) => number;
	getBuildInfoFromKey?: (buildKey: string) => {
		buildNumber: number;
		buildType: string;
	};
};

export type Product = string;

export type RemoteActionMetas = {
	lastPerformAttemptedAt: number;
	attemptCounts: number;
};

export type RemoteActionsMetas = {
	[id: string]: RemoteActionMetas;
};

export type RemoteActionsIndexedById = {
	[id: string]: RemoteAction;
};

export type RemoteActionCallback = (action: RemoteAction, meta: RemoteActionMetadata) => void;

export type RemoteActionMetadata = {
	attempts: number;
	lastAttemptedAt?: number;
};
