/**
 * @generated SignedSource<<78ca456cd6160a4ce6ec83f2e6433d00>>
 * @relayHash aed7569699aee5dd3ad2ebc1c66c6544
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 10c3f4debb1d42f2d9c4150d990a7fa92c6fdae9d8a3ed579a56d5027e1facbd

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcVirtualAgentViewStandardFlowQuery } from './srcVirtualAgentViewStandardFlowQuery.graphql';

const node: PreloadableConcreteRequest<srcVirtualAgentViewStandardFlowQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "10c3f4debb1d42f2d9c4150d990a7fa92c6fdae9d8a3ed579a56d5027e1facbd",
    "metadata": {},
    "name": "srcVirtualAgentViewStandardFlowQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
