import { ff } from '@atlassian/jira-feature-flagging';
import { type RouterContext, createResource } from '@atlassian/jira-router';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils';
import {
	RESOURCE_TYPE_PRODUCT_JSM_ORGANIZATION_LIST,
	RESOURCE_TYPE_PRODUCT_JSM_ORGANIZATION_LIST_LEGACY,
} from './constants';

const TEN_SECONDS_IN_MILLIS = 1000 * 10;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const productsJsmOrganizationListResourceLegacy = createResource<any>({
	type: RESOURCE_TYPE_PRODUCT_JSM_ORGANIZATION_LIST_LEGACY,
	getKey: (routerContext: RouterContext) => routerContext.query.page,
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-products-jsm-organizations-list-legacy", jiraSpaEntry: "async-resource-products-jsm-organizations-list-legacy" */ './services/get-data'
		),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const productsJsmOrganizationListResourceNew = createResource<any>({
	type: RESOURCE_TYPE_PRODUCT_JSM_ORGANIZATION_LIST,
	getKey: (routerContext: RouterContext) => routerContext.query.page,
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-products-jsm-organizations-list", jiraSpaEntry: "async-resource-products-jsm-organizations-list" */ './services/get-data'
		),
	maxAge: TEN_SECONDS_IN_MILLIS,
});

export const productsJsmOrganizationListResource = resourceWithCondition2(
	() => ff('jsm-fission-optimistic-update-in-product-admin-organization-page_2jltb'),
	productsJsmOrganizationListResourceNew,
	productsJsmOrganizationListResourceLegacy,
);
