import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { getGlobalPermissionPageResources } from '@atlassian/jira-router-resources-advanced-roadmaps-settings/src/services/permissions';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation';
import {
	softwareArjSettingsPermissionsRoute,
	softwareArjSettingsPermissionsRedirectRoute,
} from '@atlassian/jira-router-routes-advanced-roadmaps-routes';
import type ArjPermissionsSettingsType from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/settings/permissions';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import { redirectTo } from '../../../common/ui/redirect/settings';

/**
 * Plans Settings - Permissions
 */
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyArjSettingsPermissions = lazyForPaint<typeof ArjPermissionsSettingsType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-arj-settings-permissions", jiraSpaEntry: "async-arj-settings-permissions" */ '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/settings/permissions'
			),
		),
	{ ssr: false },
);

export const ArjSettingsPermissions = () => (
	<LazyPage Page={LazyArjSettingsPermissions} pageId="arj-settings-permissions" shouldShowSpinner />
);

export const arjSettingsPermissionsRouteEntry: Route = createEntry(
	softwareArjSettingsPermissionsRoute,
	{
		component: ArjSettingsPermissions,
		layout: globalSettingsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProductsSettingResource(),
			...getGlobalPermissionPageResources(),
		],
		forPaint: [LazyAtlassianNavigation, LazyArjSettingsPermissions],
	},
);

export const arjSettingsPermissionsRouteLegacyRedirectRouteEntry: Route = createEntry(
	softwareArjSettingsPermissionsRedirectRoute,
	{
		component: redirectTo('/jira/plans/settings/permissions'),
		isRedirect: true,
		layout: globalSettingsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProductsSettingResource(),
			...getGlobalPermissionPageResources(),
		],
		forPaint: [LazyAtlassianNavigation, LazyArjSettingsPermissions],
	},
);
