import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarEocTeamDashboard, {
	LazyTeamDashboardSidebar,
} from '@atlassian/jira-navigation-apps-sidebar-eoc-team-dashboard/src/async';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import {
	AsyncHorizontalOperationsConfigurationNav,
	LazyHorizontalOperationsConfigurationNav,
} from '@atlassian/jira-operations-configurations-horizontal-nav';
import {
	AsyncHorizontalOperationsNav,
	LazyHorizontalOperationsNav,
} from '@atlassian/jira-operations-horizontal-nav';
import { opsTeamLayout } from '@atlassian/jira-ops-team-layout';
import { createEntry } from '@atlassian/jira-route-entry';
import { resources as alertIssueSyncingResources } from '@atlassian/jira-router-resources-eoc-alert-issue-syncing';
import { resources as teamDashboardResources } from '@atlassian/jira-router-resources-eoc-team-dashboard';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation';
import {
	eocAlertIssueSyncingRoute,
	eocAlertIssueSyncingCreateRoute,
	eocAlertIssueSyncingDetailRoute,
	eocTeamAlertIssueSyncingRoute,
	eocTeamAlertIssueSyncingCreateRoute,
	eocTeamAlertIssueSyncingDetailRoute,
} from '@atlassian/jira-router-routes-eoc-alert-issue-syncing-routes';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import { LazyAlertIssueSync, LazyAlertIssueSyncDetail, LazyAlertIssueSyncCreate } from './async';

export const AlertIssueSync = () => (
	<LazyPage
		Page={LazyAlertIssueSync}
		pageId="async-jira-eoc-alert-issue-syncing"
		shouldShowSpinner
	/>
);

export const AlertIssueSyncDetail = () => (
	<LazyPage
		Page={LazyAlertIssueSyncDetail}
		pageId="async-jira-eoc-alert-issue-syncing"
		shouldShowSpinner
	/>
);

export const AlertIssueSyncCreate = () => (
	<LazyPage
		Page={LazyAlertIssueSyncCreate}
		pageId="async-jira-eoc-alert-issue-syncing"
		shouldShowSpinner
	/>
);

export const eocAlertIssueSyncingRouteEntry = createEntry(eocAlertIssueSyncingRoute, {
	component: AlertIssueSync,
	// @ts-expect-error - 'slot' and 'basePath' do not exist in type Route
	slot: null,
	basePath: '/jira/settings/products/ops',
	exact: true,
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		alertIssueSyncingResources.configuredSyncsResource,
		alertIssueSyncingResources.userAccessRightsResource,
	],
	navigation: {
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
		horizontal: AsyncHorizontalOperationsConfigurationNav,
		horizontalFeatureFlag: 'platform.50cent.operations-general-configurations-sidebar-item_dqyta',
	},
	forPaint: [LazyHorizontalOperationsConfigurationNav, LazyAtlassianNavigation, LazyAlertIssueSync],
});

export const eocAlertIssueSyncingDetailRouteEntry = createEntry(eocAlertIssueSyncingDetailRoute, {
	component: AlertIssueSync,
	// @ts-expect-error - 'slot' and 'basePath' do not exist in type Route
	slot: AlertIssueSyncDetail,
	basePath: '/jira/settings/products/ops',
	exact: true,
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		alertIssueSyncingResources.configuredSyncsResource,
		alertIssueSyncingResources.userAccessRightsResource,
	],
	navigation: {
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
		horizontal: AsyncHorizontalOperationsConfigurationNav,
		horizontalFeatureFlag: 'platform.50cent.operations-general-configurations-sidebar-item_dqyta',
	},
	forPaint: [LazyHorizontalOperationsConfigurationNav, LazyAtlassianNavigation, LazyAlertIssueSync],
});

export const eocAlertIssueSyncingCreateRouteEntry = createEntry(eocAlertIssueSyncingCreateRoute, {
	component: AlertIssueSync,
	// @ts-expect-error - 'slot' and 'basePath' do not exist in type Route
	slot: AlertIssueSyncCreate,
	basePath: '/jira/settings/products/ops',
	exact: true,
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		alertIssueSyncingResources.configuredSyncsResource,
		alertIssueSyncingResources.userAccessRightsResource,
	],
	navigation: {
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
		horizontal: AsyncHorizontalOperationsConfigurationNav,
		horizontalFeatureFlag: 'platform.50cent.operations-general-configurations-sidebar-item_dqyta',
	},
	forPaint: [LazyHorizontalOperationsConfigurationNav, LazyAtlassianNavigation, LazyAlertIssueSync],
});

export const eocTeamAlertIssueSyncingRouteEntry = createEntry(eocTeamAlertIssueSyncingRoute, {
	component: AlertIssueSync,
	layout: opsTeamLayout,
	// @ts-expect-error - 'slot' and 'basePath' do not exist in type Route
	slot: null,
	basePath: '/jira/ops/teams',
	exact: true,
	resources: [
		...getNavigationResources(),
		...teamDashboardResources,
		getConsolidationStateResource(),
		alertIssueSyncingResources.configuredSyncsResource,
		alertIssueSyncingResources.userAccessRightsResource,
	],
	navigation: {
		menuId: MENU_ID.PEOPLE,
		sidebar: AsyncSidebarEocTeamDashboard,
		horizontal: AsyncHorizontalOperationsNav,
		horizontalFeatureFlag: 'alx-8775-operations-horizontal-nav_fr1tg',
	},
	forPaint: [
		LazyHorizontalOperationsNav,
		LazyAtlassianNavigation,
		LazyAlertIssueSync,
		LazyTeamDashboardSidebar,
	],
});

export const eocTeamAlertIssueSyncingDetailRouteEntry = createEntry(
	eocTeamAlertIssueSyncingDetailRoute,
	{
		component: AlertIssueSync,
		layout: opsTeamLayout,
		// @ts-expect-error - 'slot' and 'basePath' do not exist in type Route
		slot: AlertIssueSyncDetail,
		basePath: '/jira/ops/teams',
		exact: true,
		resources: [
			...getNavigationResources(),
			...teamDashboardResources,
			getConsolidationStateResource(),
			alertIssueSyncingResources.configuredSyncsResource,
			alertIssueSyncingResources.userAccessRightsResource,
		],
		navigation: {
			menuId: MENU_ID.PEOPLE,
			sidebar: AsyncSidebarEocTeamDashboard,
			horizontal: AsyncHorizontalOperationsNav,
			horizontalFeatureFlag: 'alx-8775-operations-horizontal-nav_fr1tg',
		},
		forPaint: [
			LazyHorizontalOperationsNav,
			LazyAtlassianNavigation,
			LazyAlertIssueSync,
			LazyTeamDashboardSidebar,
		],
	},
);

export const eocTeamAlertIssueSyncingCreateRouteEntry = createEntry(
	eocTeamAlertIssueSyncingCreateRoute,
	{
		component: AlertIssueSync,
		layout: opsTeamLayout,
		// @ts-expect-error - 'slot' and 'basePath' do not exist in type Route
		slot: AlertIssueSyncCreate,
		basePath: '/jira/ops/teams',
		exact: true,
		resources: [
			...getNavigationResources(),
			...teamDashboardResources,
			getConsolidationStateResource(),
			alertIssueSyncingResources.configuredSyncsResource,
			alertIssueSyncingResources.userAccessRightsResource,
			alertIssueSyncingResources.getTeamResource,
		],
		navigation: {
			menuId: MENU_ID.PEOPLE,
			sidebar: AsyncSidebarEocTeamDashboard,
			horizontal: AsyncHorizontalOperationsNav,
			horizontalFeatureFlag: 'alx-8775-operations-horizontal-nav_fr1tg',
		},
		forPaint: [
			LazyHorizontalOperationsNav,
			LazyAtlassianNavigation,
			LazyAlertIssueSync,
			LazyTeamDashboardSidebar,
		],
	},
);

export const eocAlertIssueSyncingRouteEntries = [
	eocTeamAlertIssueSyncingRouteEntry,
	eocTeamAlertIssueSyncingCreateRouteEntry,
	eocTeamAlertIssueSyncingDetailRouteEntry,
	eocAlertIssueSyncingRouteEntry,
	eocAlertIssueSyncingCreateRouteEntry,
	eocAlertIssueSyncingDetailRouteEntry,
];
