const incrementResourceStats = (
	resource: string,
	stats: {
		[key: string]: number;
	},
) => {
	if (stats[resource] == null) {
		// eslint-disable-next-line no-param-reassign
		stats[resource] = 1;
	} else {
		// eslint-disable-next-line no-param-reassign
		stats[resource] += 1;
	}
};
const getEmptyLoadBridgeStats = () => ({
	requests: 0,
	amdModules: {},
	wrmKeys: {},
});
const initStats = (): {
	requests: number;
	amdModules: {
		[amdModuleName: string]: number;
	};
	wrmKeys: {
		[wrmKey: string]: number;
	};
} => {
	if (window.__WRM_INTERNAL_STATS__ == null) {
		window.__WRM_INTERNAL_STATS__ = {
			loadBridge: getEmptyLoadBridgeStats(),
		};
	} else if (window.__WRM_INTERNAL_STATS__.loadBridge == null) {
		window.__WRM_INTERNAL_STATS__.loadBridge = getEmptyLoadBridgeStats();
	}
	return window.__WRM_INTERNAL_STATS__.loadBridge;
};
const isPageInteractive = () => window.__WRM_INTERNAL_STATS__?.isPageInteractive;
/**
 * Manages the accumulation and recording of statistics related to resource
 * loading. It increments the count of requests and updates the stats for AMD
 * modules and WRM keys. The function ceases to accumulate stats once the page
 * becomes interactive to ensure performance metrics are not adversely affected.
 */
export const saveStats = (amdModuleName: string, wrmKeys?: string | string[]) => {
	try {
		const stats = initStats();

		// stop accumulating stats at TTI mark
		if (isPageInteractive()) {
			return;
		}
		stats.requests += 1;
		incrementResourceStats(amdModuleName, stats.amdModules);
		if (Array.isArray(wrmKeys)) {
			wrmKeys.forEach((wrmKey) => incrementResourceStats(wrmKey, stats.wrmKeys));
		} else if (wrmKeys != null) {
			incrementResourceStats(wrmKeys, stats.wrmKeys);
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		// not critical functionality
	}
};
