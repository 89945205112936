import { showBanner, dismissCurrentBanner } from './banner';
import {
	registerBottomRightCornerContainer,
	insertBottomRightCornerPortal,
	removeBottomRightCornerPortal,
} from './bottom-right-corner';
import { setIsChromeless } from './chromeless';
import { setLeftSidebarState } from './left-sidebar';
import {
	setRightSidebarState,
	openRightSidebar,
	closeRightSidebar,
	closeAllRightSidebar,
	closeCurrentRightSidebar,
	minimiseRightSidebar,
} from './right-sidebar';

export type { RightSidebarControllerType } from './right-sidebar';

export const actions = {
	// global
	setIsChromeless,

	// banner
	showBanner,
	dismissCurrentBanner,

	// left-sidebar
	setLeftSidebarState,

	// right-sidebar
	setRightSidebarState,
	openRightSidebar,
	closeRightSidebar,
	closeAllRightSidebar,
	closeCurrentRightSidebar,
	minimiseRightSidebar,

	// bottom-right-corner
	registerBottomRightCornerContainer,
	insertBottomRightCornerPortal,
	removeBottomRightCornerPortal,
} as const;

export type LayoutActions = typeof actions;
