import {
	ResourceDependencyError,
	createResource,
	type CreateResourceArgBase,
	type ResourceStoreContext,
	type RouterDataContext,
	type RouteResource,
	type RouteResourceResponse,
} from '@atlassian/jira-router';

export type DataOrErrorOrNull<T> = T | Error | null;
export type AsyncDataOrErrorOrNull<T> = Promise<T | null> | T | Error | null;
export type TupleDataOrErrorOrNull<T> = Flow.TupleMap<T, <U>(arg1: U) => DataOrErrorOrNull<U>>;

export const withEitherDataOrError =
	<T extends unknown[], U>(
		dataFn: (...args: T) => U,
		errorFn: (error: Error | null) => U,
	): ((...args: TupleDataOrErrorOrNull<T>) => U) =>
	(...args: TupleDataOrErrorOrNull<T>): U => {
		const invalidArgs = args.filter((v) => v == null || v instanceof Error);
		return invalidArgs.length
			? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				errorFn(invalidArgs[0] as Error | null)
			: // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				dataFn(...(args as T));
	};

export const decodeSharedExisingData = <T,>(
	dependencies: RouterDataContext['dependencies'],
	type: string,
): DataOrErrorOrNull<T> => {
	// the standard in react-resource-router is to fail getData() for dependency errors
	const { [type]: resourceSlice } = dependencies;
	if (!resourceSlice) {
		throw new ResourceDependencyError(
			`Cannot decode dependency "${type}", it may be missing from resource depends field`,
		);
	}

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return resourceSlice.data as T | null;
};

export const decodeSharedValue = <T,>(
	dependencies: RouterDataContext['dependencies'],
	type: string,
): AsyncDataOrErrorOrNull<T> => {
	// the standard in react-resource-router is to fail getData() for dependency errors
	const { [type]: resourceSlice } = dependencies;
	if (!resourceSlice) {
		throw new Error(
			`Cannot decode dependency: "${type}" must be included in the resource depends field`,
		);
	}

	// prefer previous value rather than error
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const { loading, data, promise, error } = resourceSlice as RouteResourceResponse<T>;
	return loading && promise
		? promise.then(
				(v) => v,
				(e) => data ?? e,
			)
		: // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			data ?? (error as Error);
};

export type TupleOfDataOrErrorOrNull<T> = Flow.TupleMap<T, <U>(arg1: U) => DataOrErrorOrNull<U>>;

export type CreateDependentResourceArg<T, U extends unknown[], V> = CreateResourceArgBase & {
	depends: NonNullable<CreateResourceArgBase['depends']>;
	mapToSharedValue: (...depDataOrErrorOrNull: TupleOfDataOrErrorOrNull<U>) => DataOrErrorOrNull<V>;
	shouldUpdate: (prevData: T, sharedValue: DataOrErrorOrNull<V>) => boolean;
	getData: (
		maybePrevData: DataOrErrorOrNull<T>,
		sharedValue: DataOrErrorOrNull<V>,
	) => (dataContext: RouterDataContext, routerContext: ResourceStoreContext) => Promise<T> | T;
};

export const createDependentResource = <T, U extends unknown[], V>({
	type,
	depends,
	mapToSharedValue,
	shouldUpdate,
	getData,
	...rest
}: CreateDependentResourceArg<T, U, V>): RouteResource<T> =>
	createResource<T>({
		...rest,
		type,
		depends: [...depends, type],
		getData: (
			dataContext: RouterDataContext,
			storeContext: ResourceStoreContext,
		): T | Promise<T> => {
			const { dependencies } = dataContext;
			const prevValue = decodeSharedExisingData(dependencies, type);
			const valuesOrPromises = depends.map((d) => decodeSharedValue<U>(dependencies, d));

			const implementation = (
				depDataOrErrorOrNull: TupleOfDataOrErrorOrNull<U>,
			): T | Promise<T> => {
				const newShared = mapToSharedValue(...depDataOrErrorOrNull);
				return prevValue == null ||
					prevValue instanceof Error ||
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					shouldUpdate(prevValue as T, newShared)
					? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						getData(prevValue as DataOrErrorOrNull<T>, newShared)(
							dataContext,
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							storeContext as ResourceStoreContext,
						)
					: // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						(prevValue as T);
			};

			return valuesOrPromises.some((v) => v instanceof Promise)
				? Promise.allSettled(valuesOrPromises)
						.then(
							(arr) =>
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								arr.map((v) =>
									v.status === 'fulfilled' ? v.value : v.reason,
								) as TupleOfDataOrErrorOrNull<U>,
						)
						.then(implementation)
				: // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					implementation(valuesOrPromises as TupleOfDataOrErrorOrNull<U>);
		},
	});

export class SharedValueAggregateError extends Error {
	skipSentry: boolean;

	constructor({
		name,
		isPrefetch,
		sharedValue,
	}: {
		name: string;
		isPrefetch: boolean;
		sharedValue: Error | null;
	}) {
		super(`${name} is missing dependency, prefetch [${isPrefetch}] sharedValue [${sharedValue}]`);
		this.skipSentry = true;
	}
}
