import type { Navigation } from '@atlassian/jira-router';

export const ADDON_ID = {
	DISCOVER: 'com.atlassian.jira.emcee__enduser-discover',
} as const;

export const MENU_ID = {
	ADDONS: 'ADDONS',
	APP_SWITCHER: 'APP_SWITCHER',
	DASHBOARDS: 'DASHBOARDS',
	FILTERS: 'FILTERS',
	HELP: 'HELP',
	HOME: 'HOME',
	INSIGHT: 'INSIGHT',
	NOTIFICATIONS: 'NOTIFICATIONS',
	PEOPLE: 'PEOPLE',
	PLANS: 'PLANS',
	PROFILE: 'PROFILE',
	PROJECTS: 'PROJECTS',
	ROADMAPS: 'ROADMAPS',
	SEARCH: 'SEARCH',
	SERVICE_HUB: 'SERVICE_HUB',
	SETTINGS: 'SETTINGS',
	HELP_CENTER_SETTINGS: 'HELP_CENTER_SETTINGS',
} as const;

export const SIDEBAR_ID: Record<
	NonNullable<Navigation['sidebarId']>,
	NonNullable<Navigation['sidebarId']>
> = {
	BOARD_SETTINGS: 'BOARD_SETTINGS',
	GLOBAL_APPS_SETTINGS: 'GLOBAL_APPS_SETTINGS',
	GLOBAL_ISSUES_SETTINGS: 'GLOBAL_ISSUES_SETTINGS',
	GLOBAL_PRODUCTS_SETTINGS: 'GLOBAL_PRODUCTS_SETTINGS',
	GLOBAL_PROJECTS_SETTINGS: 'GLOBAL_PROJECTS_SETTINGS',
	GLOBAL_SYSTEM_SETTINGS: 'GLOBAL_SYSTEM_SETTINGS',
	HELP_CENTER_SETTINGS: 'HELP_CENTER_SETTINGS',
	PERSONAL_SETTINGS: 'PERSONAL_SETTINGS',
	PLAN_SETTINGS: 'PLAN_SETTINGS',
	PROJECT_SETTINGS: 'PROJECT_SETTINGS',
};
