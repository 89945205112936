import uuid from 'uuid';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { FORGE_SAMPLING_RATE } from '../../../constants';
import { forgeModulesFetchTime, forgeModulesConcurrentFetchTime } from '../performance-analytics';

export const measureExecutionTimeMetrics = async <T,>(action: () => Promise<T>): Promise<T> => {
	const concurrentId = uuid();
	const shouldSendTimeMetric =
		!ff('fetch-forge-modules-sampling_1e415') || Math.random() * 100 < FORGE_SAMPLING_RATE;

	if (fg('ditto-fcf_forge_fetch_time_metric_fix')) {
		try {
			if (shouldSendTimeMetric)
				forgeModulesConcurrentFetchTime(concurrentId).start({ startTime: performance.now() });
			const result = await action();

			if (shouldSendTimeMetric)
				forgeModulesConcurrentFetchTime(concurrentId).stop({ stopTime: performance.now() });
			return result;
		} catch (error) {
			if (shouldSendTimeMetric) forgeModulesConcurrentFetchTime(concurrentId).cancel();
			throw error;
		}
	} else {
		try {
			if (shouldSendTimeMetric) forgeModulesFetchTime.start({ startTime: performance.now() });
			const result = await action();

			if (shouldSendTimeMetric) forgeModulesFetchTime.stop({ stopTime: performance.now() });
			return result;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			if (shouldSendTimeMetric) forgeModulesFetchTime.cancel();
			throw error;
		}
	}
};
