/**
 * @generated SignedSource<<f53a98a51a39c3edbd8eaaddbe5e4adc>>
 * @relayHash ae2ffcd3af6d8ccb37f5a8b7a88c77e5
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 5836e71a5928db09b057e4fc51eba99a9a32ad499bf64f03bc2b600cf24667d0

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { pageLayout_projectsDirectoryLayout_RootQuery } from './pageLayout_projectsDirectoryLayout_RootQuery.graphql';

const node: PreloadableConcreteRequest<pageLayout_projectsDirectoryLayout_RootQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "5836e71a5928db09b057e4fc51eba99a9a32ad499bf64f03bc2b600cf24667d0",
    "metadata": {},
    "name": "pageLayout_projectsDirectoryLayout_RootQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
