import {
	ROUTE_NAMES_LEGACY_ALL_REPORTS,
	ROUTE_NAMES_LEGACY_REPORTS_CONFIG,
	ROUTE_NAMES_LEGACY_REPORTS_AVERAGE_AGE,
	ROUTE_NAMES_LEGACY_REPORTS_CREATED_VS_RESOLVED,
	ROUTE_NAMES_LEGACY_REPORTS_PIE,
	ROUTE_NAMES_LEGACY_REPORTS_RECENTLY_CREATED,
	ROUTE_NAMES_LEGACY_REPORTS_RESOLUTION_TIME,
	ROUTE_NAMES_LEGACY_REPORTS_SINGLE_LEVEL_GROUP_BY,
	ROUTE_NAMES_LEGACY_REPORTS_TIME_SINCE,
	ROUTE_NAMES_LEGACY_REPORTS_TIME_TRACKING_REPORT,
	ROUTE_NAMES_LEGACY_REPORTS_DEVELOPER_WORKLOAD,
	ROUTE_NAMES_LEGACY_REPORTS_VERSION_WORKLOAD,
	ROUTE_NAMES_LEGACY_REPORTS_WORKLOAD_PIE,
	ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_CORE_PROFORMA_FORMS,
	ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_ISSUE_LAYOUT,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_EDIT,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_CONFIG,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_DATA_CLASSIFICATIONS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_FIELDS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_LAYOUT,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_SECURITY,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_TYPES,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_NOTIFICATIONS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_OPSGENIE,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_PERMISSIONS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_SCREENS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_SUMMARY,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_WORKFLOWS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_COLLECTORS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_PEOPLE,
	ROUTE_NAMES_PROJECT_SETTINGS_AUTOMATION,
	ROUTE_NAMES_PROJECT_SETTINGS_VERSIONS,
	ROUTE_NAMES_PROJECT_SETTINGS_COMPONENTS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_DEV,
	ROUTE_NAMES_LEGACY_PIN,
	ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR,
	ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR_CORE,
	ROUTE_NAMES_FORGE_PROJECT,
	ROUTE_NAMES_PROJECT_SETTINGS_CORE_FORGE_APPS,
} from '@atlassian/jira-common-constants/src/spa-routes';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize } from '@atlassian/jira-common-styles/src';

export const PACKAGE_NAME = 'jira.navigation.sidebar.core';

export const MENU_ID = 'project/core';

export const SECTION_ID = {
	ISSUES: 'issues',
	REPORTS: 'reports',
	SETTINGS: 'settings',
	ISSUE_TYPES: 'issuetypes',
} as const;

export const REPORTS_ROUTES_SET = new Set<string>([
	ROUTE_NAMES_LEGACY_ALL_REPORTS,
	ROUTE_NAMES_LEGACY_REPORTS_CONFIG,
	ROUTE_NAMES_LEGACY_REPORTS_AVERAGE_AGE,
	ROUTE_NAMES_LEGACY_REPORTS_CREATED_VS_RESOLVED,
	ROUTE_NAMES_LEGACY_REPORTS_PIE,
	ROUTE_NAMES_LEGACY_REPORTS_RECENTLY_CREATED,
	ROUTE_NAMES_LEGACY_REPORTS_RESOLUTION_TIME,
	ROUTE_NAMES_LEGACY_REPORTS_SINGLE_LEVEL_GROUP_BY,
	ROUTE_NAMES_LEGACY_REPORTS_TIME_SINCE,
	ROUTE_NAMES_LEGACY_REPORTS_TIME_TRACKING_REPORT,
	ROUTE_NAMES_LEGACY_REPORTS_DEVELOPER_WORKLOAD,
	ROUTE_NAMES_LEGACY_REPORTS_VERSION_WORKLOAD,
	ROUTE_NAMES_LEGACY_REPORTS_WORKLOAD_PIE,
]);

export const SETTINGS_ROUTES_SET = new Set<string>([
	ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_CORE_PROFORMA_FORMS,
	ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_ISSUE_LAYOUT,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_EDIT,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_CONFIG,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_DATA_CLASSIFICATIONS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_FIELDS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_LAYOUT,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_SECURITY,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_TYPES,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_NOTIFICATIONS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_OPSGENIE,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_PERMISSIONS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_SCREENS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_SUMMARY,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_WORKFLOWS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_COLLECTORS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_PEOPLE,
	ROUTE_NAMES_PROJECT_SETTINGS_AUTOMATION,
	ROUTE_NAMES_PROJECT_SETTINGS_VERSIONS,
	ROUTE_NAMES_PROJECT_SETTINGS_COMPONENTS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_DEV,
]);

export const ISSUES_ROUTES_SET = new Set<string>([
	ROUTE_NAMES_LEGACY_PIN,
	ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR,
	ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR_CORE,
]);

export const FORGE_ROUTES_SET = new Set<string>([
	ROUTE_NAMES_FORGE_PROJECT,
	ROUTE_NAMES_PROJECT_SETTINGS_CORE_FORGE_APPS,
]);

export const JIRA_AUTOMATION_ADDON_KEY = 'com.codebarrel.addons.automation';

export const MENU_ITEM_HEIGHT = gridSize * 5;

export const PROJECT_ICON_SIZE = 24;

export const LOCATION = 'jwmNavigationCreateProject';

export const OVERVIEW_MAX_LIMIT = 10;
