import { getMultivariateFeatureFlag } from '@atlassian/jira-feature-flagging-using-meta';

const getConsistencyCheckFeatureFlag = (featureFlagName: string, defaultValue = 'OLD'): string => {
	const possibleValues = ['NEW', 'CHECK_RETURN_NEW', 'CHECK', 'OLD'];
	return getMultivariateFeatureFlag(featureFlagName, defaultValue, possibleValues);
};

/**
 * Check if the value of a consistency check feature flag is OLD, CHECK or CHECK_RETURN_NEW.
 *
 * @param {string} featureFlagName the name of the feature flag
 * @returns boolean
 */
export const getIsOldValueRequested = (featureFlagName: string): boolean => {
	const featureFlagValue = getConsistencyCheckFeatureFlag(featureFlagName);

	return (
		featureFlagValue === 'OLD' ||
		featureFlagValue === 'CHECK_RETURN_NEW' ||
		featureFlagValue === 'CHECK'
	);
};

/**
 * Check if the value of a consistency check feature flag is OLD, CHECK or CHECK_RETURN_NEW.
 *
 * @param {string} featureFlagName the name of the feature flag
 * @returns boolean
 */
export const getIsNewValueRequested = (featureFlagName: string): boolean => {
	const featureFlagValue = getConsistencyCheckFeatureFlag(featureFlagName);

	return (
		featureFlagValue === 'NEW' ||
		featureFlagValue === 'CHECK_RETURN_NEW' ||
		featureFlagValue === 'CHECK'
	);
};
