import { createResource } from 'react-resource-router';

import { assert, AssertionError } from '@atlassian/eoc-common';

import { type Member, type MembersQueryResponseV2 } from '../../common/types';
import {
	getCustomTeamRolesEndpoint,
	getJSMInvitationStatusInfoEndpoint,
	getMemberRolesPageAccess,
	getMembers,
	getRoleAndJSMLicenseInfoEndpoint,
	getTeam,
} from '../endpoints';

const mapStatus = (status: string | undefined, userAccessLevel: string | undefined) => {
	if (userAccessLevel === 'INTERNAL_INACTIVE') {
		return 'suspended';
	}
	if (
		status === 'REQUEST_ACCESS' ||
		status === 'DENIED_REQUEST_EXISTS' ||
		status === 'FORBIDDEN' ||
		status === 'DIRECT_ACCESS'
	) {
		return 'invite';
	}
	if (status === 'ACCESS_EXISTS') {
		return 'member';
	}
	if (status === 'PENDING_REQUEST_EXISTS') {
		return 'pending';
	}
	if (status === '' || status === undefined) {
		return 'member';
	}
	return 'invite';
};

export const memberRolesResource = createResource({
	type: 'memberRolesResource',
	getKey: () => 'memberRolesResource',
	getData: async (routerContext) => {
		const teamId = routerContext.match.params?.teamId;
		assert(
			!!teamId,
			new AssertionError('memberRolesResource', 'teamId should be set to fetch member-roles'),
		);
		const { productOrTeamAdmin: isAuthorizedToSeeMemberRolesPage } =
			await getMemberRolesPageAccess(teamId);

		assert(
			!!isAuthorizedToSeeMemberRolesPage,
			new AssertionError(
				'Member Role Unauthorized Page Access',
				'You have to have product or team admin permissions to see this page.',
			),
		);

		const gqlResponse = await getMembers(teamId);
		let members: Member[] = [];

		members = (gqlResponse as MembersQueryResponseV2)?.data.team.teamV2.members.edges.map(
			(edge) => edge.node.member,
		);

		const teamMembers = await getRoleAndJSMLicenseInfoEndpoint({
			platformTeamId: teamId,
			accountIds: members.map((member) => member.accountId),
		}).then((res) => {
			const users = members.map((user) => {
				const userWithRole = res.teamMembers.find(
					(userWithRole) => userWithRole.accountId === user.accountId,
				);

				return {
					...user,
					role: userWithRole?.roleId || 'user',
					jsmLicense: userWithRole?.jsmLicense || false,
					jiraAdmin: userWithRole?.jiraAdmin || false,
					invitationStatus: 'member',
				};
			});
			return users;
		});

		const membersWithoutJSMLicense = teamMembers.filter((teamMembers) => !teamMembers.jsmLicense);
		if (membersWithoutJSMLicense.length > 0) {
			const accountIds = membersWithoutJSMLicense.map((member) => member.accountId).join(',');
			const membersWithFullInfo = await getJSMInvitationStatusInfoEndpoint({
				accountIds,
			})
				.then((res) => {
					const users = teamMembers.map((user) => {
						const userWithInvitationStatus = res.embeddedValue.find(
							(userWithInvitationStatus) => userWithInvitationStatus.accountId === user.accountId,
						);
						return {
							...user,
							invitationStatus: mapStatus(
								userWithInvitationStatus?.accessMode,
								userWithInvitationStatus?.userAccessLevel,
							),
						};
					});
					return users;
				})
				.catch((err) => {
					return teamMembers;
				});
			return membersWithFullInfo;
		}
		return teamMembers;
	},
	isBrowserOnly: true,
});

export const getTeamResource = createResource({
	type: 'getTeamResource',
	getKey: () => 'getTeamResource',
	getData: async (routerContext) => {
		const teamId = routerContext.match.params?.teamId;

		assert(
			!!teamId,
			new AssertionError(
				'getTeamResource',
				'teamId should be set to fetch team info on member roles',
			),
		);

		return getTeam(teamId);
	},
	isBrowserOnly: true,
});

export const customTeamRolesResource = createResource({
	type: 'customTeamRolesResource',
	getKey: () => 'customRolesResource',
	getData: async (routerContext) => {
		const teamId = routerContext.match.params?.teamId;
		assert(
			!!teamId,
			new AssertionError(
				'customTeamRolesResource',
				'teamId should be set to fetch custom-team-roles',
			),
		);
		return getCustomTeamRolesEndpoint(teamId);
	},
	isBrowserOnly: true,
});

export const resources = [memberRolesResource, getTeamResource, customTeamRolesResource];
