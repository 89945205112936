import { useMemo } from 'react';
import type { AnyEntryPoint, ParamsOfEntryPoint } from '../../common/types';
import { DEFAULT_OPTIONS } from '../constants';
import type { Metric, Options } from '../types';
import type { EntryPointActions } from '../utils/types';
import { useEntryPointActions } from '../utils/use-entry-point-actions';
import {
	useEntryPointLoadManager,
	type EntryPointReferenceSubject,
} from '../utils/use-entry-point-load-manager';
import { useMetricLogger, type StopMetric } from '../utils/use-metric-logger';

export type UseEntryPoint<TEntryPoint extends AnyEntryPoint> = {
	entryPointActions: EntryPointActions;
	entryPointReferenceSubject: EntryPointReferenceSubject<TEntryPoint>;
	stopMetric: StopMetric;
};

export const useEntryPoint = <TEntryPoint extends AnyEntryPoint>(
	entryPoint: TEntryPoint,
	entryPointParams: ParamsOfEntryPoint<TEntryPoint>,
	metric?: Metric,
	options: Options = DEFAULT_OPTIONS,
): UseEntryPoint<TEntryPoint> => {
	const { entryPointReferenceSubject, ...loadActions } = useEntryPointLoadManager<TEntryPoint>(
		entryPoint,
		entryPointParams,
	);

	const { startMetric, stopMetric } = useMetricLogger(metric);
	const entryPointActions = useEntryPointActions(loadActions, options, startMetric);

	return useMemo(
		() => ({
			entryPointActions,
			entryPointReferenceSubject,
			stopMetric,
		}),
		[entryPointActions, entryPointReferenceSubject, stopMetric],
	);
};
