import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ProjectSettingsServiceDeskKnowledgeBaseV2Type from '@atlassian/jira-spa-apps-knowledge-settings';
import type SettingsServiceDeskKnowledgeBaseType from '@atlassian/jira-spa-apps-project-settings-service-desk-knowledge-base';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const NEXTGEN_SETTINGS = 'project/service-desk/settings/index';
export const CLASSIC_SETTINGS = 'project/service-desk/settings';

export const LazySettingsServiceDeskKnowledgeBase = lazyForPaint<
	typeof SettingsServiceDeskKnowledgeBaseType
>(
	() =>
		import(
			/* webpackChunkName: "async-project-settings-service-desk-knowledge-base", jiraSpaEntry: "async-project-settings-service-desk-knowledge-base" */ '@atlassian/jira-spa-apps-project-settings-service-desk-knowledge-base'
		),
);

export const LazyProjectSettingsServiceDeskKnowledgeBaseV2 = lazyForPaint<
	typeof ProjectSettingsServiceDeskKnowledgeBaseV2Type
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-project-settings-service-desk-knowledge-base-v2", jiraSpaEntry: "async-project-settings-service-desk-knowledge-base-v2" */ '@atlassian/jira-spa-apps-knowledge-settings'
		),
	),
);

export const ServiceDeskKnowledgeBaseV2 = () => (
	<LazyPage
		Page={LazyProjectSettingsServiceDeskKnowledgeBaseV2}
		pageId="project-settings-knowledge-base"
		shouldShowSpinner
	/>
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazySettingsServiceDeskKnowledgeBase}
		pageId="project-settings-knowledge-base"
		shouldShowSpinner
	/>
);
