/**
 * @generated SignedSource<<f7b1895c9ab9e5e3d95988fadd58409c>>
 * @relayHash a62980e6f93cb5f2ad31fdebd70c38b1
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2b594593303f12c91ba380135535d6cde24ab3377ce6133c443e711b338621c8

import type { ConcreteRequest, Query } from 'relay-runtime';
export type sidebarSelectorQuery$variables = {
  cloudId: string;
};
export type sidebarSelectorQuery$data = {
  readonly jira: {
    readonly navigationUIState: {
      readonly isLeftSidebarCollapsed: boolean;
      readonly leftSidebarWidth: number;
    };
  };
};
export type sidebarSelectorQuery = {
  response: sidebarSelectorQuery$data;
  variables: sidebarSelectorQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "isLeftSidebarCollapsed"
},
v3 = {
  "kind": "ScalarField",
  "name": "leftSidebarWidth"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "sidebarSelectorQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v1/*: any*/),
                "concreteType": "JiraNavigationUIStateUserProperty",
                "kind": "LinkedField",
                "name": "navigationUIState",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v2/*: any*/),
                    "action": "THROW",
                    "path": "jira.navigationUIState.isLeftSidebarCollapsed"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v3/*: any*/),
                    "action": "THROW",
                    "path": "jira.navigationUIState.leftSidebarWidth"
                  }
                ]
              },
              "action": "THROW",
              "path": "jira.navigationUIState"
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "sidebarSelectorQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraNavigationUIStateUserProperty",
            "kind": "LinkedField",
            "name": "navigationUIState",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "name": "id"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "2b594593303f12c91ba380135535d6cde24ab3377ce6133c443e711b338621c8",
    "metadata": {},
    "name": "sidebarSelectorQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "b04768d36946f1c08e071147d201a1ba";

export default node;
