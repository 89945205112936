import {
	ROUTE_NAMES,
	ROUTE_GROUPS,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const SERVICE_DESK_PRODUCT = 'servicedesk';
const projectSettingsUrl = (product: string) => `/jira/${product}/projects/:projectKey/settings`;
export const servicedeskSlaSettingsRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_SLA_SETTINGS,
	path: `${projectSettingsUrl(SERVICE_DESK_PRODUCT)}/sla/(custom|new)?/:id?/(edit)?`,
};
