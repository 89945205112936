import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import { calendarEapEntryPoint } from '@atlassian/jira-spa-apps-servicedesk-calendar-eap/entrypoint';
import { calendarEntryPoint } from '@atlassian/jira-spa-apps-servicedesk-calendar/entrypoint';

export const calendarEapPageEntryPoint = createPageEntryPoint({
	main: calendarEapEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

export const calendarPageEntryPoint = createPageEntryPoint({
	main: calendarEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});
