import React, { type ReactElement } from 'react';
import { selectUnit } from '@formatjs/intl-utils';
import { FormattedRelativeTime as FormattedRelativeTimeV5 } from 'react-intl-next';
import type { DateParseable } from './types';

type Units = 'second' | 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year';
type RelativeFormatOptions = {
	style?: 'best fit' | 'numeric';
	units?: Units;
};
type FormattedRelativeProps = RelativeFormatOptions & {
	value: DateParseable;
	format?: string;
	// Once used in JFE and passed a number in milliseconds
	updateInterval?: number;
	initialNow?: DateParseable;
	children?: (formattedDate: string) => ReactElement;
};
const getTimeInUnits = (unitValue: number) => {
	const seconds = unitValue / 1000;
	const minutes = seconds / 60;
	const hours = minutes / 60;
	const days = hours / 24;
	const weeks = days / 7;
	const months = weeks / 4;
	const years = months / 12;
	return {
		seconds,
		minutes,
		hours,
		days,
		weeks,
		months,
		years,
	};
};
/**
 * Provides a human-readable representation of time relative to the current moment. It calculates the difference between a given date and the current time, then determines the most appropriate unit of time (e.g., seconds, minutes, hours) to express this difference. This function is ideal for applications requiring a user-friendly display of time intervals.
 */
export const getRelativeTimeAndUnit = (value: DateParseable, units?: Units) => {
	const timeInMilliseconds = new Date(value);
	// @ts-expect-error - TS2362 - The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type.
	const delta = timeInMilliseconds - Date.now();
	let valueInUnit: number;
	if (!units) {
		const { value: time, unit } = selectUnit(timeInMilliseconds, new Date());
		return {
			time,
			unit,
		};
	}
	const { seconds, minutes, hours, days, weeks, months, years } = getTimeInUnits(delta);
	switch (units) {
		case 'second':
			valueInUnit = seconds;
			break;
		case 'minute':
			valueInUnit = minutes;
			break;
		case 'hour':
			valueInUnit = hours;
			break;
		case 'day':
			valueInUnit = days;
			break;
		case 'week':
			valueInUnit = weeks;
			break;
		case 'month':
			valueInUnit = months;
			break;
		case 'year':
			valueInUnit = years;
			break;
		default:
			valueInUnit = 0;
			break;
	}
	return {
		time: Math.ceil(valueInUnit),
		unit: units,
	};
};
/**
 * Adjusted based on https://formatjs.io/docs/react-intl/upgrade-guide-3x/#formattedrelativetime
 * and /node_modules/react-intl/lib/index.es.js
 *
 * units: As we use only a subset of the unit values, we can just pass units prop to unit
 * value: Is now specified as delta in the provided unit
 *
 * @deprecated use FormattedRelative
 */
export const FormattedRelativeV2 = ({
	value,
	units,
	children,
	style,
	format,
	updateInterval,
}: FormattedRelativeProps): ReactElement<typeof FormattedRelativeTimeV5> => {
	const { time, unit } = getRelativeTimeAndUnit(value, units);
	return (
		<FormattedRelativeTimeV5
			value={time}
			unit={unit}
			numeric={style === 'numeric' ? 'always' : 'auto'}
			format={format}
			updateIntervalInSeconds={updateInterval}
		>
			{children}
		</FormattedRelativeTimeV5>
	);
};
FormattedRelativeV2.displayName = 'FormattedRelative';
