import React, { type ReactNode } from 'react';
import { Stack, xcss } from '@atlaskit/primitives';

type Props = {
	children: ReactNode;
};

export const SidebarContainer = ({ children }: Props) => (
	<Stack space="space.200" xcss={containerStyles}>
		{children}
	</Stack>
);

const containerStyles = xcss({
	height: '100%',
	padding: 'space.200',
});
