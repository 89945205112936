// eslint-disable-next-line jira/wrm/no-load-bridge
import { loadBridge } from '@atlassian/jira-common-bridge/src';

const amdModuleName = 'jira-agile/sidebar/create-board-adg3';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default async (): Promise<void> => {
	// eslint-disable-next-line jira/wrm/no-load-bridge
	const module = await loadBridge({ name: amdModuleName });
	// @ts-expect-error - TS2571 - Object is of type 'unknown'.
	return module.openCreateBoardModal();
};
