import { ISSUE_SEARCH_DEFAULT_COLUMNS } from '@atlassian/jira-dashboard-common';
import type { ItemProperties } from '@atlassian/jira-dashboard-user-preference';
import { getSortFieldAndDirection } from './get-sort-field-and-direction';
import { isFilterId } from './is-filter-id';

export const transformToValidUserPrefs = (rawUserPrefs: ItemProperties) => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const pageSize = parseInt(rawUserPrefs.num as string, 10);
	const columnNames =
		typeof rawUserPrefs.columnNames === 'string' ? rawUserPrefs.columnNames.split('|') : [];
	const fieldSetIds = columnNames[0] === '--Default--' ? ISSUE_SEARCH_DEFAULT_COLUMNS : columnNames;
	const stringifiedFilterId = `${rawUserPrefs.filterId}`;

	const { sortDirection, sortField } = getSortFieldAndDirection(rawUserPrefs.sortBy);
	const showResolved = !!rawUserPrefs.showResolved;
	return {
		filterId: isFilterId(stringifiedFilterId) ? stringifiedFilterId : undefined,
		columnNames: fieldSetIds,
		num: !Number.isNaN(pageSize) ? pageSize : 10,
		sortDirection,
		sortField,
		showResolved,
	};
};
