import type { JiraWorkManagementNavData } from '@atlassian/jira-navigation-apps-resources';
import type { Features, ReportFeatures, SettingFeatures } from '../../../common/types';

export const getExtraData = (data: JiraWorkManagementNavData, isPremium: boolean): Features => {
	const { permissionInfo, hasComponents = false, project } = data;
	const { isAdmin, projectPermissionsQueried } = permissionInfo;
	const isProjectOrJiraAdmin = projectPermissionsQueried.ADMINISTER_PROJECTS === true || isAdmin;
	const isSimplifiedProject = project.simplified;

	return {
		hasVersions: true,
		hasSettings: isProjectOrJiraAdmin,
		hasBoard: true,
		hasReports: true,
		hasAddItem: isProjectOrJiraAdmin,
		hasComponents,
		hasIssues: true,
		hasSummary: true,
		hasPages: true,
		hasChangeTheme: isProjectOrJiraAdmin,
		hasApprovals: isSimplifiedProject && (isPremium || isProjectOrJiraAdmin),
	};
};

export const getExtraSettingsData = (
	data: JiraWorkManagementNavData,
	hasActiveSoftwareAccess: boolean,
): SettingFeatures => {
	const { isAdmin, projectPermissionsQueried } = data.permissionInfo;
	const isProjectAdmin = projectPermissionsQueried.ADMINISTER_PROJECTS === true;
	const isProjectOrJiraAdmin = isProjectAdmin || isAdmin;

	const hasDetails = isProjectOrJiraAdmin;
	const hasPeople = isProjectOrJiraAdmin;
	const hasSummary = true;

	const hasIssueTypes = isAdmin;

	const hasWorkflows = true;
	const hasScreens = true;
	const hasFields = true;

	const hasVersions = true;

	const hasComponents = true;

	const hasPermissions = true;
	const hasIssueSecurity = true;
	const hasNotifications = true;

	const hasIssueCollectors = true;

	const hasDevtools = isProjectAdmin && hasActiveSoftwareAccess;

	return {
		hasProjectGroupOneSection: hasDetails || hasPeople || hasSummary,
		hasIssueConfigSection: hasIssueTypes,
		hasProjectGroupTwoSection: hasWorkflows || hasScreens || hasFields,
		hasProjectGroupThreeSection: hasVersions || hasComponents,
		hasProjectGroupFourSection: hasPermissions || hasIssueSecurity || hasNotifications,
		hasgetIssueCollectorSection: hasIssueCollectors,
		hasComponents,
		hasDetails,
		hasFields,
		hasIssueCollectors,
		hasIssueSecurity,
		hasIssueTypes,
		hasNotifications,
		hasPeople,
		hasPermissions,
		hasScreens,
		hasSummary,
		hasVersions,
		hasWorkflows,
		hasDevtools,
	};
};

export const getExtraReportData = (hasSoftwareAccess: boolean): ReportFeatures => ({
	hasDeveloperWorkload: true,
	hasForcastSection: true,
	hasTimeTracking: true,
	hasVersionWorkloadReport: hasSoftwareAccess,
});
