// eslint-disable-next-line jira/restricted/@atlassian/jira-router
import { createResource, type Match, type Query } from '@atlassian/jira-router';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils';
import { fetchShortcuts } from '../../../services/sidebar';
import type { ProjectShortcut } from '../../../types';

const getIdentifier = (match: Match, query: Query): string => {
	const { projectKey, projectId } = getProjectKeyId(match, query);
	return projectKey || projectId;
};

export const staticSidebarShortcutsResource = createResource<ProjectShortcut[] | null>({
	type: 'NAVIGATION_SIDEBAR_PROJECT_SHORTCUTS',
	getKey: ({ match, query }) => getIdentifier(match, query) ?? 'none',
	getData: async ({ match, query }) => {
		const projectKeyOrId = getIdentifier(match, query);
		if (projectKeyOrId == null) {
			return null;
		}

		const shortcuts = await fetchShortcuts(projectKeyOrId);
		return shortcuts;
	},
	maxAge: 5 * 60 * 1000,
});
