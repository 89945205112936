import isArray from 'lodash/isArray';
import type { ProjectId, ProjectKey, IssueKey } from '@atlassian/jira-shared-types';

export type Scope = {
	key: string;
};

export type QueryIncludeConfig = {
	scopes?: boolean;
};

export type QueryExtras = {
	include?: QueryIncludeConfig;
};

export type PrefetchedDataClassification = { tags: string[] };
export type DeferredDataClassificationForProject = { projectIdOrKey: ProjectId | ProjectKey };
export type DeferredDataClassificationForIssue = { issueKey: IssueKey };

export type DeferredDataClassification =
	| DeferredDataClassificationForProject
	| DeferredDataClassificationForIssue;

export type DataClassificationProps =
	| PrefetchedDataClassification
	| DeferredDataClassification
	| null;

export const hasPrefetchedData = (
	props: DataClassificationProps,
): props is PrefetchedDataClassification =>
	props !== null && 'tags' in props && isArray(props.tags);

export const hasProjectIdOrKey = (
	props: DeferredDataClassification,
): props is DeferredDataClassificationForProject => props !== null && 'projectIdOrKey' in props;

export const hasIssueKey = (
	props: DeferredDataClassification,
): props is DeferredDataClassificationForIssue => props !== null && 'issueKey' in props;
