import type { JiraProject } from '@atlassian/jira-business-entity-common';
import { createResource, useResource, type RouteResourceError } from '@atlassian/jira-router';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils';
import { fetchProjectDetails } from './services/project-details';
import type { ProjectDetailsResource, MediaConfiguration } from './types';

export type { ProjectDetailsResource } from './types';

type ProjectResource = {
	data: JiraProject | null;
	loading: boolean;
	error?: RouteResourceError | null;
};

const RESOURCE_TYPE_KEY = 'RESOURCE_TYPE_BUSINESS_PROJECT_DETAILS';

export const projectDetailsResource = createResource<ProjectDetailsResource>({
	type: RESOURCE_TYPE_KEY,
	getKey: (matchedRoute) => {
		const { projectKey, projectId } = getProjectKeyId(matchedRoute.match, matchedRoute.query);
		const projectIdOrKey = projectKey || projectId;
		return `${RESOURCE_TYPE_KEY}_${projectIdOrKey}`;
	},
	getData: async ({ match, query }, { tenantContext: { cloudId } }) => {
		const { projectKey, projectId } = getProjectKeyId(match, query);

		return fetchProjectDetails(cloudId, projectKey, projectId);
	},
	maxAge: 60 * 60 * 1000, // 1 hour
});

export const useProject = (): ProjectResource => {
	const { data, loading, error } = useResource<ProjectDetailsResource>(projectDetailsResource);

	return { data: data?.currentProject ?? null, loading, error };
};

/**
 * Returns data for media configuration, which is relevant for media upload and download.
 * @returns {clientId: string, externalEndpointUrl: string} | null
 */
export const useMediaConfiguration = (): MediaConfiguration | null => {
	const { data } = useResource<ProjectDetailsResource>(projectDetailsResource);

	return data?.media ?? null;
};
