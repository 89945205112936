import { parse } from 'query-string';
import {
	defaultOrderFields,
	DEFAULT_ORDER_FIELD,
	DEFAULT_SORT_DIRECTION,
} from '@atlassian/jira-issue-navigator/src/common/constants';
import type { SortDirection } from '@atlassian/jira-issue-navigator/src/common/types';
import type { Match } from '@atlassian/jira-router';
import type { ProjectKey } from '@atlassian/jira-shared-types';
import { Filters, StatusCategoryValues } from './common/constants';

export const isFilterKey = (filter?: unknown): filter is keyof typeof Filters =>
	typeof filter === 'string' && filter in Filters;

export const mapFilterToJql = (projectKey: string, filter?: string): string | undefined => {
	if (!isFilterKey(filter)) {
		return undefined;
	}

	const clauses: string[] = [`project = "${projectKey}"`];
	let order: string = DEFAULT_ORDER_FIELD;
	const direction: SortDirection = DEFAULT_SORT_DIRECTION;

	switch (Filters[filter]) {
		case Filters.myopenissues:
			clauses.push('assignee IN (currentUser())');

			clauses.push(
				`statusCategory IN (${StatusCategoryValues.TO_DO}, ${StatusCategoryValues.IN_PROGRESS})`,
			);
			break;
		case Filters.reportedbyme:
			clauses.push('reporter IN (currentUser())');
			break;
		case Filters.allopenissues:
			clauses.push(
				`statusCategory IN (${StatusCategoryValues.TO_DO}, ${StatusCategoryValues.IN_PROGRESS})`,
			);
			order = defaultOrderFields.updated;
			break;
		case Filters.doneissues:
			clauses.push(`statusCategory = ${StatusCategoryValues.DONE}`);
			break;
		case Filters.recentlyviewed:
			order = defaultOrderFields.lastviewed;
			break;
		case Filters.resolvedrecently:
			clauses.push('resolved >= -1w');
			order = defaultOrderFields.updated;
			break;
		case Filters.updatedrecently:
			order = defaultOrderFields.updated;
			break;
		case Filters.allissues:
			order = defaultOrderFields.created;
			break;
		default:
	}

	return `${clauses.join(' AND ')} ORDER BY ${order} ${direction}`.trim();
};

export const getDefaultJql = (projectKey: ProjectKey) =>
	`project = "${projectKey}" ORDER BY ${DEFAULT_ORDER_FIELD} ${DEFAULT_SORT_DIRECTION}`;

type ParsedParams = {
	[key: string]: string | string[] | undefined;
	projectKey: string | undefined;
	issueKey: string | undefined;
	filter: string | undefined;
	jql: string | undefined;
};

export const mergeParams = (search: string, match?: Match | null): ParsedParams => ({
	...parse(search),
	...(match && match.params),
});

export const getUrlPrefix = (url: string) => {
	const match = url.match(/^(.*)\/projects.*$/);
	return (match && match[1]) ?? '';
};
