import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ServicedeskKnowledgeHubSetupType from '@atlassian/jira-spa-apps-servicedesk-knowledge-hub-setup';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyServicedeskKnowledgeHubSetup = lazyForPaint<
	typeof ServicedeskKnowledgeHubSetupType
>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-knowledge-hub-setup", jiraSpaEntry: "async-servicedesk-knowledge-hub-setup" */ '@atlassian/jira-spa-apps-servicedesk-knowledge-hub-setup'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyServicedeskKnowledgeHubSetup}
		pageId="spa-apps/servicedesk/knowledge-hub/setup"
		shouldShowSpinner
	/>
);
