import React, { type ElementType, memo, type ReactNode, useCallback } from 'react';
import { styled } from '@compiled/react';
import isEqual from 'lodash/isEqual';
import { usePreloadedQuery, type PreloadedQuery } from 'react-relay';
import { graphql } from 'relay-runtime';
import { Flex, Box, xcss } from '@atlaskit/primitives';
import {
	NavSidebarSSRPerformanceMark,
	NAVIGATION_SIDEBAR_MARKS,
} from '@atlassian/jira-atlassian-navigation-performance-metrics';
import { layers } from '@atlassian/jira-common-styles/src';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl';
import { useLeftSidebarState } from '@atlassian/jira-layout-controller';
import { useLayoutStoreActions } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/store';
import { sidebarNav4UiStateResource } from '@atlassian/jira-navigation-apps-resources';
import { SidebarStart } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/metrics';
import { SidebarNav4ContextProvider } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/controllers/sidebar-context';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout';
import { SidebarSelector } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebar-selector';
import { SidebarSelectorProvider } from '@atlassian/jira-navigation-apps-sidebar-selector';
// @ts-expect-error - TS2305 - Module '"@atlassian/jira-navigation-system"' has no exported member 'ResizeButtonProps'.
import { LeftSidebar, type ResizeButtonProps } from '@atlassian/jira-navigation-system';
import { PanelSplitter, SideNav } from '@atlassian/jira-navigation-system/src/v4';
import Placeholder from '@atlassian/jira-placeholder';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils';
import { useRelayResource } from '@atlassian/jira-relay-utils';
import type { sidebarSelectorQuery } from '@atlassian/jira-relay/src/__generated__/sidebarSelectorQuery.graphql';
import { RenderTracerMark } from '@atlassian/jira-render-tracer-analytics';
import { SidebarFeedbackButton } from '@atlassian/jira-sidebar-nav4-feedback-button';
import UFOSegment from '@atlassian/jira-ufo-segment';
import { SideNavCollapseButton } from '@atlassian/navigation-system/layout/side-nav';
import { LeftSidebarSkipLink } from '../page-container/page-container-skip-links';
import { messages } from './messages';
import { AsyncResizeButtonTooltip } from './resize-button-tooltip';

const SidebarNew = () => {
	const { queryReference } = useRelayResource(sidebarNav4UiStateResource);

	return (
		<UFOSegment name="nav4.sidebar">
			<Placeholder name="sidebar">
				{queryReference ? <SidebarInternal queryReference={queryReference} /> : null}
			</Placeholder>
		</UFOSegment>
	);
};

/**
 * This test ID is used in pollinator tests. Do not change lightly.
 */
const TEST_ID = 'page-layout.sidebar';

const SidebarInternal = ({
	queryReference,
}: {
	queryReference: PreloadedQuery<sidebarSelectorQuery>;
}) => {
	const { formatMessage } = useIntl();

	const data = usePreloadedQuery<sidebarSelectorQuery>(
		graphql`
			query sidebarSelectorQuery($cloudId: ID!) {
				jira @required(action: THROW) {
					navigationUIState(cloudId: $cloudId)
						@required(action: THROW)
						@optIn(to: "JiraEntityProperty") {
						isLeftSidebarCollapsed @required(action: THROW)
						leftSidebarWidth @required(action: THROW)
					}
				}
			}
		`,
		queryReference,
	);

	const defaultCollapsed = data.jira.navigationUIState.isLeftSidebarCollapsed;
	const defaultWidth = data.jira.navigationUIState.leftSidebarWidth;

	const [, { setLeftSidebarState }] = useLayoutStoreActions();
	const onExpand = useCallback(
		({ screen }: { screen: 'desktop' | 'mobile' }) => {
			if (screen === 'desktop') {
				setLeftSidebarState({ isCollapsed: false });
			}
		},
		[setLeftSidebarState],
	);

	const onCollapse = useCallback(
		({ screen }: { screen: 'desktop' | 'mobile' }) => {
			if (screen === 'desktop') {
				setLeftSidebarState({ isCollapsed: true });
			}
		},
		[setLeftSidebarState],
	);

	const onResizeEnd = useCallback(
		({ finalWidth }: { finalWidth: number }) => {
			setLeftSidebarState({ width: finalWidth });
		},
		[setLeftSidebarState],
	);

	return (
		<SidebarNav4ContextProvider>
			<SideNav
				testId={TEST_ID}
				defaultCollapsed={defaultCollapsed}
				defaultWidth={defaultWidth}
				onExpand={onExpand}
				onCollapse={onCollapse}
				collapseButton={<SideNavCollapseButton />}
			>
				<Flex direction="column" xcss={sidebarContainerStyles} justifyContent="space-between">
					<Box xcss={sidebarContentStyles}>
						<SidebarSelector />
					</Box>
					<SidebarFeedbackButton />
				</Flex>
				<PanelSplitter
					label={formatMessage(messages.sideBarPanelSplitterLabel)}
					onResizeEnd={onResizeEnd}
				/>
			</SideNav>
		</SidebarNav4ContextProvider>
	);
};

const SIDEBAR_TEST_ID = 'ContextualNavigation';
const SIDEBAR_TRACE_KEY = 'sidebar';

const sidebarOverrides = {
	ResizeButton: {
		render: (Component: ElementType<ResizeButtonProps>, props: ResizeButtonProps) => (
			<JSErrorBoundary
				id="sidebar-resize-tooltip"
				packageName="page-container"
				fallback={() => <Component {...props} />}
			>
				<Placeholder name="side_bar_button" fallback={<Component {...props} />}>
					<AsyncResizeButtonTooltip isLeftSidebarCollapsed={props.isLeftSidebarCollapsed}>
						<Component {...props} />
					</AsyncResizeButtonTooltip>
				</Placeholder>
			</JSErrorBoundary>
		),
	},
} as const;

type SidebarProps = {
	sidebar: ReactNode;
};

const SidebarInternalOld = ({ sidebar }: SidebarProps) => {
	const { width, isCollapsed } = useLeftSidebarState();
	const route = useCurrentRoute();
	const intl = useIntl();

	const shouldNotRenderSidebarInServerSide = __SERVER__ && isCollapsed;

	return (
		<UFOSegment name="left-sidebar">
			<SidebarStyleOverrides>
				<JSErrorBoundary
					id="leftSidebarSkipLink"
					packageName="jiraPageContainer"
					fallback="unmount"
				>
					<LeftSidebarSkipLink />
				</JSErrorBoundary>
				<LeftSidebar
					isFixed
					width={width}
					testId={SIDEBAR_TEST_ID}
					resizeButtonLabel={intl.formatMessage(messages.sidebarNavigation)}
					collapsedState={isCollapsed ? 'collapsed' : 'expanded'}
					overrides={sidebarOverrides}
					id="ak-side-navigation"
				>
					{!shouldNotRenderSidebarInServerSide && (
						<>
							{route !== null && (
								<>
									<SidebarStart />
									<RenderTracerMark type="start" traceKey={SIDEBAR_TRACE_KEY} />
								</>
							)}
							{sidebar}
							{route !== null && (
								<>
									<NavSidebarSSRPerformanceMark metricKey={NAVIGATION_SIDEBAR_MARKS.renderSsrEnd} />
									<RenderTracerMark type="end" traceKey={SIDEBAR_TRACE_KEY} />
								</>
							)}
						</>
					)}
				</LeftSidebar>
			</SidebarStyleOverrides>
		</UFOSegment>
	);
};

const SidebarInternalOldMemo = memo(SidebarInternalOld, isEqual);

// moved children as function outside the `Sidebar` so that the function is not re-created every time `Sidebar` is re-rendered.
const renderChildren = (sidebar: ReactNode) =>
	sidebar !== null ? <SidebarInternalOldMemo sidebar={sidebar} /> : null;

const SidebarOld = () => {
	const { shouldRender } = useLeftSidebarState();
	const route = useCurrentRoute();

	if (!shouldRender) return null;

	return (
		<SidebarSelectorProvider sidebar={route.navigation?.sidebar ?? null}>
			{renderChildren}
		</SidebarSelectorProvider>
	);
};

export const Sidebar = componentWithCondition(getWillShowNav4, SidebarNew, SidebarOld);

/**
 * In the @atlaskit/page-layout, resize control uses button for
 * the grab area. At the same time, legacy batch.css reset all
 * the button to have a box-shadow when focused. So when user
 * resizing the sidebar, they will see two blue lines around
 * the grab area.
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SidebarStyleOverrides = styled.div<{}>({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: layers.leftSidebar,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"& button[data-testid='ContextualNavigation-grab-area']:focus": {
		boxShadow: 'none',
	},
});

const sidebarContainerStyles = xcss({
	height: '100%',
});

const sidebarContentStyles = xcss({
	flex: '1',
	minHeight: '0',
	overflowX: 'auto',
});
