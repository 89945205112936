import { createResource } from 'react-resource-router';

import { assert, AssertionError } from '@atlassian/eoc-common';
import { eocCommonResources } from '@atlassian/eoc-resources';

import {
	getAlertDetail,
	getAlertLogTypesEndpoint,
	getEntityRelationTypesEndpoint,
	getResponderStatesEndpoint,
} from '../endpoints';

export const alertDetailResource = createResource({
	type: 'alertDetailResource',
	getKey: (routerContext) => `alertDetailResource#${routerContext.match.params.id}`,
	getData: async (routerContext) => {
		const alertId = routerContext.match.params?.id;

		assert(
			!!alertId,
			new AssertionError('alertDetailResource', 'alertId should be set to fetch alert-detail'),
		);

		return getAlertDetail(alertId);
	},
	isBrowserOnly: true,
});

export const logTypesResource = createResource({
	type: 'logTypesResource',
	getKey: (routerContext) => `logTypesResource#${routerContext.match.params.id}`,
	getData: async () => {
		return getAlertLogTypesEndpoint();
	},
	isBrowserOnly: true,
});

export const entityRelationTypesResource = createResource({
	type: 'entityRelationTypesResource',
	getKey: (routerContext) => `entityRelationTypesResource#${routerContext.match.params.id}`,
	getData: async (routerContext) => {
		const alertId = routerContext.match.params?.id;

		assert(
			!!alertId,
			new AssertionError(
				'alertDetaentityRelationTypesResourceilResource',
				'alertId should be set to fetch alert-detail',
			),
		);

		return getEntityRelationTypesEndpoint(alertId);
	},
	isBrowserOnly: true,
});

export const responderStatesResource = createResource({
	type: 'responderStatesResource',
	getKey: (routerContext) => `responderStatesResource#${routerContext.match.params.id}`,
	getData: async (routerContext) => {
		const alertId = routerContext.match.params?.id;

		assert(
			!!alertId,
			new AssertionError(
				'responderStatesResource',
				'alertId should be set to fetch responder states',
			),
		);

		return getResponderStatesEndpoint(alertId);
	},
	isBrowserOnly: true,
});

export const resources = [
	alertDetailResource,
	logTypesResource,
	responderStatesResource,
	entityRelationTypesResource,
	...eocCommonResources(),
];
