import React from 'react';
import { styled } from '@compiled/react';
import { N0, N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { JSErrorPageAsync } from '@atlassian/jira-error-pages';

export const ErrorPage = ({ error }: { error: Error }) => (
	<ErrorWrapper>
		<JSErrorPageAsync error={error} />
	</ErrorWrapper>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorWrapper = styled.div({
	backgroundColor: token('elevation.surface', N0),
	color: token('color.text', N800),
});
