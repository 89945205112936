import { ff } from '@atlassian/jira-feature-flagging';

export const getSystemNavigationPaths = () => ({
	generalConfiguration: '/jira/settings/system/general-configuration',
	darkFeatures: '/secure/admin/SiteDarkFeatures!default.jspa',
	issueCollectors: '/secure/admin/ViewGlobalCollectors!default.jspa',
	globalAutomation: '/jira/settings/automation',

	// troubleshooting
	troubleshootingAuditLog: '/auditing/view',
	troubleshootingPluginViewer: '/plugins/servlet/depview/plugins',
	troubleshootingModulesViewer: '/plugins/servlet/depview/modules',
	troubleshootingSystemInfo: '/secure/admin/ViewSystemInfo.jspa',
	troubleshootingIntegrityChecker: '/secure/admin/IntegrityChecker!default.jspa',
	troubleshootingLoggingProfiling: '/secure/admin/ViewLogging.jspa',
	troubleshootingSchedulerDetails: '/secure/admin/SchedulerAdmin.jspa',
	troubleshootingPerformanceAndScale: '/jira/settings/system/performance/overview',

	// security
	securityProjectRoles: '/secure/project/ViewProjectRoles.jspa',
	securityGlobalPermissions: ff('everest-global-permissions-fe-modernisation_vvy6j')
		? '/secure/admin/global-permissions'
		: '/secure/admin/GlobalPermissions!default.jspa',

	// user interface
	userInterfacesUserDefaults: '/secure/admin/ViewUserDefaultSettings.jspa',
	userInterfacesEditDefaultDashboard: '/secure/admin/EditDefaultDashboard!default.jspa',
	userInterfacesLookAndFeel: '/secure/admin/LookAndFeel!default.jspa',
	userInterfacesPasAdmin: '/plugins/servlet/pas/admin',
	userInterfacesSiteAdmin: '/jira/settings/system/ui/announcement-banner',

	// import and export
	importSectionBackupManager: '/secure/admin/CloudExport.jspa',
	importSectionExternalImport: '/secure/admin/ExternalImport1.jspa',
	importSectionExternalImportNew: '/jira/settings/system/external-import',
	importSectionCloudImport: '/secure/admin/CloudImport!start.jspa?source=import_cloud',
	importSectionServerImport: '/secure/admin/CloudImport!start.jspa?source=import_server',
	importSectionMigrateCloud: '/jira/settings/system/migration/home',

	// mail
	mailSectionGlobalMailSettings: '/secure/admin/GlobalMailSettings.jspa',
	mailSectionOutgoingMail: '/secure/admin/OutgoingMailServers.jspa',
	mailSectionIncomingMail: '/secure/admin/IncomingMailServers.jspa',
	mailSectionSendMail: '/secure/admin/SendBulkMail!default.jspa',

	// admin
	adminHelperPermissionHelper: '/secure/admin/PermissionHelperAdmin.jspa',
	adminHelperNotificationHelper: '/secure/admin/NotificationHelperAdmin.jspa',
	adminHelperEmailNotificationsLogHelper: '/jira/settings/system/notification-logs',

	// shared
	sharedSectionManageFilters: '/secure/admin/filters/ViewSharedFilters.jspa',
	sharedManageDashboards: '/secure/admin/dashboards/ViewSharedDashboards.jspa',

	// advanced
	advancedSectionAttachments: '/secure/admin/ViewAttachmentSettings.jspa',
	advancedSectionEvents: '/secure/admin/ListEventTypes.jspa',
	advancedSectionWebHooks: '/plugins/servlet/webhooks',
	advancedSectionServices: '/secure/admin/ViewServices!default.jspa',
	advancedLexoRankManagement: '/secure/GHLexoRankManage.jspa?decorator=admin',
});
