import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin';
import parameters from '@atlassian/jira-relay/src/__generated__/ui_NotificationsPageAndProjectQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const personalSettingsNotificationsEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-personal-settings-notifications" */ './src'),
	),
	getPreloadProps: ({ context, tenantContext }: EntryPointRouteParams) => ({
		// Queries your async component uses
		queries: {
			pageAndProject: {
				options: {
					fetchPolicy: 'network-only' as const,
				},
				parameters,
				variables: {
					cloudId: tenantContext.cloudId,
					projectIds: context.query?.projectIds ? context.query.projectIds.split(',') : [],
				},
			},
		},
		// Optional - Anything extra you wish to pass to your component
		// Something from `EntryPointParams` for example
		extraProps: {
			accountId: tenantContext.atlassianAccountId,
		},
	}),
});
