import React, { type ComponentType } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { ReactGadgetProps } from '@atlassian/jira-react-gadgets-common';
import { FilterResultsEditSkeleton } from '@atlassian/jira-skeletons';
import type { IssueTableGadgetEditData } from '../../../types';

const LazyVotedIssuesGadgetEdit = lazyForPaint<
	ComponentType<ReactGadgetProps<IssueTableGadgetEditData>>
>(() => import(/* webpackChunkName: "async-react-voted-issues-gadget-edit" */ './main'));

export const VotedIssuesGadgetEdit = (props: ReactGadgetProps<IssueTableGadgetEditData>) => (
	<Placeholder name="voted-issues-gadget-edit" fallback={<FilterResultsEditSkeleton />}>
		<LazyVotedIssuesGadgetEdit {...props} />
	</Placeholder>
);

export const { preload } = LazyVotedIssuesGadgetEdit;
