import {
	TODO,
	IN_PROGRESS,
	DONE,
	type StatusCategory,
} from '@atlassian/jira-common-constants/src/status-categories';
import type { AccountId, IssueId } from '@atlassian/jira-shared-types';
import type {
	QUERY_PARAM_TEXT,
	QUERY_PARAM_EPIC,
	QUERY_PARAM_STATUS_CATEGORY,
	QUERY_PARAM_WARNINGS,
	QUERY_PARAM_ASSIGNEE,
	QUERY_PARAM_SORT_BY,
	QUERY_PARAM_SORT_ORDER,
} from './constants';

// This is returned type from useQueryParams hook
export interface SearchQueryParams {
	[QUERY_PARAM_TEXT]?: string;
	[QUERY_PARAM_EPIC]?: string;
	[QUERY_PARAM_STATUS_CATEGORY]?: string;
	[QUERY_PARAM_WARNINGS]?: string;
	[QUERY_PARAM_ASSIGNEE]?: string;
	[QUERY_PARAM_SORT_BY]?: string;
	[QUERY_PARAM_SORT_ORDER]?: string;
}

export type VersionStatusCategory = typeof TODO | typeof IN_PROGRESS | typeof DONE;
export type WarningsCategory =
	| 'OPEN_PULL_REQUEST'
	| 'UNREVIEWED_CODE'
	| 'FAILING_BUILD'
	| 'OPEN_REVIEW';
export type SortOrder = 'ASC' | 'DESC';
export type SortBy = 'ASSIGNEE' | 'CREATED' | 'KEY' | 'PRIORITY' | 'STATUS' | 'TYPE';

export const isWarningsFilterValue = (val: string): val is WarningsCategory =>
	val === 'OPEN_PULL_REQUEST' ||
	val === 'UNREVIEWED_CODE' ||
	val === 'FAILING_BUILD' ||
	val === 'OPEN_REVIEW';
export const toWarningsFilterValue = (statusCategoryFromQueryParam: string[]): WarningsCategory[] =>
	statusCategoryFromQueryParam.filter(isWarningsFilterValue);

export const isVersionStatusCategory = (val: string): val is VersionStatusCategory =>
	val === TODO || val === IN_PROGRESS || val === DONE;

export const isSortBy = (val: string): val is SortBy =>
	val === 'ASSIGNEE' ||
	val === 'CREATED' ||
	val === 'KEY' ||
	val === 'PRIORITY' ||
	val === 'STATUS' ||
	val === 'TYPE';

export const isSortOrder = (val: string): val is SortOrder => val === 'ASC' || val === 'DESC';

export interface ParsedQueryParams {
	[QUERY_PARAM_TEXT]?: string;
	[QUERY_PARAM_EPIC]?: IssueId[];
	[QUERY_PARAM_STATUS_CATEGORY]?: StatusCategory[];
	[QUERY_PARAM_WARNINGS]?: WarningsCategory[];
	[QUERY_PARAM_ASSIGNEE]?: AccountId[];
	[QUERY_PARAM_SORT_BY]?: SortBy;
	[QUERY_PARAM_SORT_ORDER]?: SortOrder;
}
