import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	adminHelper: {
		id: 'spa-apps-sidebar-global-settings-system.menu.admin-helper.admin-helper',
		defaultMessage: 'Admin helper',
		description: 'Navigation group heading for admin helper settings items',
	},
	permissionHelper: {
		id: 'spa-apps-sidebar-global-settings-system.menu.admin-helper.permission-helper',
		defaultMessage: 'Permission helper',
		description: 'Navigation item which links to a permission helper settings page',
	},
	notificationHelper: {
		id: 'spa-apps-sidebar-global-settings-system.menu.admin-helper.notification-helper',
		defaultMessage: 'Notification helper',
		description: 'Navigation item which links to a notification helper settings page',
	},
	emailNotificationsLog: {
		id: 'spa-apps-sidebar-global-settings-system.menu.admin-helper.email-notifications-log',
		defaultMessage: 'Unblock user invites',
		description: 'Navigation item which links to a Email notifications log settings page',
	},
	adminEmaiAuditlNotificationsLog: {
		id: 'spa-apps-sidebar-global-settings-system.menu.admin-helper.admin-email-audit-notifications-log',
		defaultMessage: 'Admin email audit',
		description: 'Navigation item which links to a Email notifications log settings page',
	},
	newLozenge: {
		id: 'spa-apps-sidebar-global-settings-system.menu.admin-helper.new-lozenge',
		defaultMessage: 'New',
		description: 'This text is to show a new feature is available',
	},
});
