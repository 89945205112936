export { getContentPanelsQuery } from './content-panels';
export { getEcosystemQuery, getLegacyRightWebPanelsQuery } from './ecosystem';
export { getIsArchivedQuery } from './lifecycle-state';
export { default as getActivitySortOrder } from './activity-sort-order';
export { default as getAttachmentsQuery } from './attachments';
export { default as getCommentsQuery } from './comments';
export { default as getWorklogsQuery } from './worklogs';
export { getJiraSettingsQuery } from './jira-settings';
export { default as getMediaContextQuery } from './media-context';
export { default as getPermissionsQuery } from './permissions';
export { default as getProject } from './project';
export { getContainersByTypeQuery } from './layout';
export { getRemoteLinksQuery } from './remote-links';
export { default as getIssueKeyQuery } from './issue-key';

export const NUM_INITIAL_COMMENTS_TO_LOAD = 5;
export const CREATED_ORDER_DESCENDING = '-created';

export const NUM_INITIAL_WORKLOGS_TO_LOAD = 5;
export const STARTED_ORDER_DESCENDING = '-started';

/**
 * GrqphQL template literal tag similar to `graphql-tag`'s `gql` function
 * which tells `eslint-plugin-graphql` to validate the Gira query string.
 *
 * For more information see the [GraphQL playbook](https://stash.atlassian.com/projects/ATLASSIAN/repos/atlassian-frontend-monorepo/browse/jira/docs/playbook/graphql.md).
 *
 * This function itself does nothing but merge the string back together to
 * a normal string.
 *
 * */
export const gqlTagGira = (
	literals: TemplateStringsArray,
	...substitutions: ReadonlyArray<string | number>
): string => {
	let result = '';

	// run the loop only for the substitution count
	substitutions.forEach((key, i) => {
		result += literals[i];
		result += key.toString();
	});

	// add the last literal
	result += literals[literals.length - 1];

	return result;
};
