import {
	isProjectRoute,
	isPlanRoute,
	isAppsRoute,
	isOperationsRoute,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-common';
import { MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants';
import { getInitialSelectedPath } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/utils/getInitialSelectedPath';
import { isSelected } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/utils/isSelected';
// It's a temp solution because the route entryPoint hasn't been fully applied to Jira
// The UIP will drive the migration and let us know once its ready
// eslint-disable-next-line jira/restricted/@atlassian+jira-relay-utils
import { RELAY_RESOURCE_TYPE, createRelayResource } from '@atlassian/jira-relay-utils';
import SidebarNav4Query, {
	type L1MenuItemsQuery,
} from '@atlassian/jira-relay/src/__generated__/L1MenuItemsQuery.graphql';
import SidebarNav4UiStateQuery, {
	type sidebarSelectorQuery,
} from '@atlassian/jira-relay/src/__generated__/sidebarSelectorQuery.graphql';

const RESOURCE_TYPE_SIDEBAR_V2_RELAY = 'SIDEBAR_V2_RELAY';

export const sidebarNav4Resource = createRelayResource<L1MenuItemsQuery>({
	type: `${RELAY_RESOURCE_TYPE}_${RESOURCE_TYPE_SIDEBAR_V2_RELAY}`,
	getQuery: (routerContext, { tenantContext: { cloudId } }) => {
		// TODO: BLU-3620: Uncomment this line, and then use `initialSelectedPath[0] === MENU_ID.<entity>,` for each route that migrated
		const initialSelectedPath = getInitialSelectedPath(routerContext);

		// This console log helps to identify routes that we need to match in jira/src/packages/navigation-apps/sidebar-nav4/context/src/common/utils.tsx
		// console.log(routerContext.route.name, routerContext.match.params);

		return {
			parameters: SidebarNav4Query,
			variables: {
				cloudId,
				isAppsExpanded: isAppsRoute(routerContext),
				isDashboardsExpanded: isSelected(initialSelectedPath, MENU_ID.DASHBOARDS),
				isFiltersExpanded: isSelected(initialSelectedPath, MENU_ID.FILTERS),
				isPlansExpanded: isPlanRoute(routerContext),
				isProjectsExpanded: isProjectRoute(routerContext),
				isOperationsExpanded: isOperationsRoute(routerContext),
			},
			options: { fetchPolicy: 'store-and-network' },
		};
	},
});

export const sidebarNav4UiStateResource = createRelayResource<sidebarSelectorQuery>({
	type: `${RELAY_RESOURCE_TYPE}_SIDEBAR_V2_UI_STATE_RELAY`,
	getQuery: (_, { tenantContext: { cloudId } }) => ({
		parameters: SidebarNav4UiStateQuery,
		variables: { cloudId },
		options: { fetchPolicy: 'store-and-network' },
	}),
});
