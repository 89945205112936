import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const eocTeamReportsHomePageRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_REPORTS,
	path: '/jira/ops/teams/:teamId/reports',
	exact: true,
};

export const eocGlobalReportsHomePageRouteNav4 = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_GLOBAL_REPORTS,
	path: '/jira/ops/reports',
	exact: true,
};

export const eocReportsUnsubscribeRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_REPORTS_UNSUBSCRIBE,
	path: '/jira/ops/reports/unsubscribe/:templateId/:subscriptionId',
	exact: true,
};

export const eocGlobalReportsNav4Route = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_GLOBAL_REPORTS,
	path: '/jira/ops/reports/:dashboardId',
	exact: true,
};

export const eocReportsTeamRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_REPORTS_TEAM,
	path: '/jira/ops/teams/:teamId/reports/:dashboardId',
	exact: true,
};
