import React, { memo } from 'react';
import type { Store } from 'redux';
import { lazyAfterPaint } from 'react-loosely-lazy';
import { SidebarContainer } from '@atlassian/jira-navigation-apps-sidebar-nav4-common/src/ui/sidebar-container';
import Placeholder from '@atlassian/jira-placeholder';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { Skeleton } from './skeleton';

const LazyPlanSettingsSidebar = lazyAfterPaint(() =>
	import(
		/* webpackChunkName: "async-jira-navigation-apps-sidebar-nav4-sidebar-plan-settings" */ './main'
	).then(({ PlanSettingsSidebar }) => PlanSettingsSidebar),
);

type Props = {
	store?: Store<State>;
};

const AsyncSidebar = ({ store }: Props) => (
	<SidebarContainer>
		<Placeholder name="asyncSidebarPlanSettings" fallback={<Skeleton />}>
			<LazyPlanSettingsSidebar store={store} />
		</Placeholder>
	</SidebarContainer>
);

export default memo(AsyncSidebar);
