import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const softwareListRoute = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_LIST,
	path: '/jira/software/projects/:projectKey/list',
};

export const softwareListEmbedRoute = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_LIST_EMBED,
	path: '/jira/software/projects/:projectKey/list/embed',
};
