import { useEffect } from 'react';
import { useSpaStatePageReady, useSpaStateTransition } from '@atlassian/jira-spa-state-controller';
/**
 * Ensures the web application's interactive state is accurately tracked and managed across single-page application (SPA) transitions.
 * This involves marking the page as interactive when ready and resetting performance stats after each SPA transition to maintain accurate performance monitoring.
 */
export const useWrmStatsEffects = () => {
	const [{ isReady }] = useSpaStatePageReady();
	const [{ transitionsCount }] = useSpaStateTransition();
	useEffect(() => {
		if (
			isReady &&
			window.__WRM_INTERNAL_STATS__ &&
			!window.__WRM_INTERNAL_STATS__.isPageInteractive
		) {
			window.__WRM_INTERNAL_STATS__.isPageInteractive = true;
		}
	}, [isReady]);
	useEffect(() => {
		if (transitionsCount > 0) {
			// clear stats on transition
			window.__WRM_INTERNAL_STATS__ = {};
		}
	}, [transitionsCount]);
};
