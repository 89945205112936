import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ServicedeskCmdbGlobalConfigureType from '@atlassian/jira-spa-apps-servicedesk-cmdb-global-configure';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyServicedeskCmdbGlobalConfigure = lazyForPaint<
	typeof ServicedeskCmdbGlobalConfigureType
>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-servicedesk-cmdb-global-configure", jiraSpaEntry: "async-servicedesk-servicedesk-cmdb-global-configure" */ '@atlassian/jira-spa-apps-servicedesk-cmdb-global-configure'
		),
);

const GlobalConfigure = () => (
	<LazyPage
		Page={LazyServicedeskCmdbGlobalConfigure}
		pageId="spa-apps/servicedesk/insight/cmdb-configure"
		shouldShowSpinner
	/>
);

export default GlobalConfigure;
