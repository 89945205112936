import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type { RouteContext } from '@atlassian/jira-router';
import type ServicedeskReportsCustomEditType from '@atlassian/jira-spa-apps-servicedesk-reports-custom-edit';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyServicedeskReportsCustomEdit = lazyForPaint<
	typeof ServicedeskReportsCustomEditType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-servicedesk-reports-custom-edit", jiraSpaEntry: "async-servicedesk-reports-custom-edit" */ '@atlassian/jira-spa-apps-servicedesk-reports-custom-edit'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ match }: RouteContext) => {
	const { reportId } = match.params;
	return (
		<LazyPage
			Page={LazyServicedeskReportsCustomEdit}
			pageId={`spa-apps/servicedesk/reports/custom/${reportId || ''}`}
			shouldShowSpinner
		/>
	);
};
