// This doesn't use @atlassian/jira-navigation-system as it results in a hard-to-debug cycle.
// We need to move away from using these variables directly as it results in a brittle hard to evolve system.
// eslint-disable-next-line jira/restricted/@atlaskit+page-layout
import {
	BANNER_HEIGHT as UNSAFE_DO_NOT_USE_BANNER_HEIGHT,
	TOP_NAVIGATION_HEIGHT as UNSAFE_DO_NOT_USE_TOP_NAVIGATION_HEIGHT,
} from '@atlaskit/page-layout';

export const PAGE_LAYOUT_OFFSET_TOP = `(${UNSAFE_DO_NOT_USE_TOP_NAVIGATION_HEIGHT} + ${UNSAFE_DO_NOT_USE_BANNER_HEIGHT})`;

export const HORIZONTAL_GLOBAL_NAV_HEIGHT = 56;
