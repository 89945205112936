import { log } from '@atlassian/jira-common-util-logging';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { performPostRequest } from '@atlassian/jira-fetch';
import { createAri } from '@atlassian/jira-platform-ari';
import type { ProjectDetailsResource } from '../../types';
import { QUERY } from './gql';
import type { ProjectDetailsResponse } from './types';
import { transformData } from './utils';

const EMPTY_VALUE: ProjectDetailsResource = { themeSetting: null, media: null };

export const fetchProjectDetails = async (
	cloudId: string,
	projectKey?: string,
	projectId?: string,
): Promise<ProjectDetailsResource> => {
	const projectIdOrKey = projectId || projectKey;

	if (!projectIdOrKey) {
		return null;
	}

	try {
		const response = await performPostRequest<ProjectDetailsResponse>(
			'/rest/gira/1/?operation=JiraBusinessProjectDetails',
			{
				body: JSON.stringify({
					query: QUERY,
					variables: {
						cloudId,
						projectIdOrKey,
						usingProjectKey: projectId == null,
						projectId:
							projectId != null
								? createAri({
										resourceOwner: 'jira',
										cloudId,
										resourceType: 'project',
										resourceId: projectId,
									})
								: '',
					},
				}),
			},
		);

		return transformData(response.data, projectIdOrKey);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		log.safeErrorWithoutCustomerData(
			'jiraRouterResourcesBusinessProjectDetails',
			'Something went wrong while fetching business project details',
		);

		const isClientError = error?.statusCode >= 400 && error?.statusCode < 500;
		if (!isClientError) {
			fireErrorAnalytics({
				meta: {
					id: 'projectDetailsResource',
					packageName: 'jiraRouterResourcesBusinessProjectDetails',
					teamName: 'wanjel',
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});

			throw error;
		}
	}

	return EMPTY_VALUE;
};
