import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const eocGlobalGroupDetailRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_GLOBAL_GROUP_DETAIL,
	path: '/jira/ops/group-detail/:id',
	exact: true,
};

export const eocTeamGroupDetailRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_TEAM_GROUP_DETAIL,
	path: '/jira/ops/teams/:teamId/group-detail/:id',
	exact: true,
};
