import type { ComponentType } from 'react';
import { AsyncHorizontalProjectNav as AsyncHorizontalNavCore } from '@atlassian/jira-business-horizontal-nav';
import { CORE_PROJECT, SOFTWARE_PROJECT } from '@atlassian/jira-common-constants';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIsChromeless } from '@atlassian/jira-layout-controller';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav';
import {
	GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
	GENERIC_PROJECT_HORIZONTAL_NAV,
} from '../../constants';

/** "header" component above the page's content
 * It contains:
 * - Heading
 * - Breadcrumb
 * - misc buttons such as share, feedback, etc.
 * - tab navigation (if nav v4)
 */
export const useHorizontalNavComponent = (): ComponentType | null => {
	const route = useCurrentRoute();
	const { data: projectData } = useProjectContext();

	// chromeless mode is handled in page-container package with nav4
	if (!getWillShowNav4()) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const [isChromeless] = useIsChromeless();
		if (isChromeless) {
			return null;
		}
	}

	if (route.navigation?.horizontal == null) {
		return null;
	}

	// Shared horizontal navigation routes that currently live in business projects on prod
	// but we want to remove horizontal navigation on with the navigation redesign
	if (route.navigation.horizontal === GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV) {
		if (getWillShowNav4()) {
			return null;
		}
		switch (projectData?.projectType) {
			case CORE_PROJECT:
				return AsyncHorizontalNavCore;
			default:
				return null;
		}
	}

	if (route.navigation.horizontal === GENERIC_PROJECT_HORIZONTAL_NAV) {
		switch (projectData?.projectType) {
			case CORE_PROJECT:
				return AsyncHorizontalNavCore;
			case SOFTWARE_PROJECT:
				if (ff('sea-2744-jsw-tabs-nav')) {
					return AsyncHorizontalSoftwareProjectNav;
				}
				return null;
			default:
				return null;
		}
	}

	// TODO: Remove this during jira_nav4 cleanup
	if ('onlyShowHorziontalOnNav4' in route.navigation && route.navigation.onlyShowHorziontalOnNav4) {
		return getWillShowNav4() ? route.navigation.horizontal : null;
	}

	if ('horizontalFeatureFlag' in route.navigation) {
		switch (route.navigation.horizontalFeatureFlag) {
			// Society: "Use a constant!"
			// Me: no! I was told to inline my feature flags!
			case 'sea-2744-jsw-tabs-nav':
				return ff('sea-2744-jsw-tabs-nav') ? route.navigation.horizontal : null;
			case 'jet-plans-nav-refresh_f9025':
				return ff('jet-plans-nav-refresh_f9025') ? route.navigation.horizontal : null; // Society: "Use a constant!"
			case 'alx-8775-operations-horizontal-nav_fr1tg':
				return ff('alx-8775-operations-horizontal-nav_fr1tg') ? route.navigation.horizontal : null; // Society: "Use a constant!"
			case 'platform.50cent.operations-general-configurations-sidebar-item_dqyta':
				return ff('platform.50cent.operations-general-configurations-sidebar-item_dqyta')
					? route.navigation.horizontal
					: null; // Society: "Use a constant!"
			case 'jsm-views-of-work-phase-1_6wljy':
				return ff('jsm-views-of-work-phase-1_6wljy') ? route.navigation.horizontal : null;
			default: {
				// a little bit of magic to ensure that we handle all feature flags defined in
				// HorizontalFeatureFlags - if the switch statement is missing a case, this line
				// will cause a compile error.
				const exhaustiveCheck: never = route.navigation.horizontalFeatureFlag;
				return exhaustiveCheck;
			}
		}
	}

	return route.navigation.horizontal;
};
