import { ff } from '@atlassian/jira-feature-flagging';
import { createRelayResource } from '@atlassian/jira-relay-utils';
// eslint-disable-next-line jira/import-whitelist -- Unclear how to change the config whilst we transition to flat config
import QUERY from '@atlassian/jira-relay/src/__generated__/mainVirtualAgentSidebarQuery$parameters';
import type { mainVirtualAgentSidebarQuery } from '@atlassian/jira-relay/src/__generated__/mainVirtualAgentSidebarQuery.graphql';

export const virtualAgentOnboardingResource = createRelayResource<mainVirtualAgentSidebarQuery>({
	type: 'SERVICEDESK_VIRTUAL_AGENT_ONBOARDING',
	isBrowserOnly: true,
	getQuery: ({ match }, { tenantContext }) => ({
		parameters: QUERY,
		variables: {
			cloudId: tenantContext.cloudId,
			key: match.params.projectKey || '',
			checkForAiEnabledFF: ff('check-for-ai-enabling-in-get-premium-banner_oxh6r'),
		},
	}),
});
