import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	migration: {
		id: 'spa-apps-sidebar-global-settings-system.menu.import-export.migrate-to-cloud-site.migration',
		defaultMessage: 'Migrate cloud site',
		description: 'Navigation item which links to a Cloud to cloud migration dashboard',
	},
	migrationBetaLabel: {
		id: 'spa-apps-sidebar-global-settings-system.menu.import-export.migrate-to-cloud-site.migration-beta-label',
		defaultMessage: 'Beta',
		description: '"Beta" label for migration navigation link item',
	},
	itemBack: {
		id: 'spa-apps-sidebar-global-settings-system.menu.import-export.migrate-to-cloud-site.item-back',
		defaultMessage: 'Back to system',
		description: '"Back to System" menu item.',
	},
});
