import { fetchJson } from '@atlassian/jira-fetch';
import type { ItemProperties, GadgetUserPreferences, UserPreferenceType } from '../../types';

const isBooleanString = (str: string) => ['true', 'false'].includes(str.toLowerCase().trim());
const stringToBoolean = (str: string) => str.toLowerCase().trim() === 'true';

export const getUserPreferences = (action: string): Promise<GadgetUserPreferences> =>
	fetchJson<GadgetUserPreferences>(action);

export const decodeUserPreferences = (userPreferences: UserPreferenceType[] = []): ItemProperties =>
	userPreferences.reduce(
		(output, { value, name }) => ({
			// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
			...output,
			[name]: isBooleanString(value) ? stringToBoolean(value) : value,
		}),
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		{} as ItemProperties,
	);
