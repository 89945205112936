import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	title: {
		id: 'spa-apps-sidebar-global-settings-system.menu.import-export.migrate-to-cloud-site.migrate-to-cloud-site-menu.title',
		defaultMessage: 'Migrate cloud site',
		description: 'Navigation section title.',
	},
	migrationOverview: {
		id: 'spa-apps-sidebar-global-settings-system.menu.import-export.migrate-to-cloud-site.migrate-to-cloud-site-menu.migration-overview',
		defaultMessage: 'Migration overview',
		description: 'Migration overview link item.',
	},
	manageMigrations: {
		id: 'spa-apps-sidebar-global-settings-system.menu.import-export.migrate-to-cloud-site.migrate-to-cloud-site-menu.manage-migrations',
		defaultMessage: 'Migration dashboard',
		description: 'Migration dashboard link item.',
	},
	planYourMigration: {
		id: 'spa-apps-sidebar-global-settings-system.menu.import-export.migrate-to-cloud-site.migrate-to-cloud-site-menu.plan-your-migration',
		defaultMessage: 'Plan your migration',
		description: 'Plan your migration link item.',
	},
});
