import { createResource, type RouteResource } from 'react-resource-router';

import type { GetTimezonesResponse } from '../../common/types';
import { endpoints as timezoneEndpoints } from '../endpoints/timezone';

const getTimezonesResource: RouteResource<GetTimezonesResponse> = createResource({
	type: 'getEocTimezonesResource',
	getKey: () => 'getEocTimezonesResource',
	isBrowserOnly: true,
	getData: async () => timezoneEndpoints.getTimezones(),
});

const resources = [getTimezonesResource];

export type { GetTimezonesResponse };

export { getTimezonesResource, resources };
