import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const globalSettingsIssueFieldScreensRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.GLOBAL_SETTINGS_ISSUE_FIELD_SCREENS,
	path: '/jira/settings/issues/screens',
};

export const globalSettingsIssueFieldSchemesRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.GLOBAL_SETTINGS_ISSUE_FIELD_SCHEMES,
	path: '/jira/settings/issues/screen-schemes',
};

export const globalSettingsIssueTypesScreenSchemesRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.GLOBAL_SETTINGS_ISSUE_TYPES_SCREEN_SCHEMES,
	path: '/jira/settings/issues/issue-type-screen-schemes',
};
