import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const settingsAutomationRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.SETTINGS_AUTOMATION,
	path: '/jira/settings/automation',
	exact: true,
};
