import React from 'react';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types';
import { getNextgenSettingsUrl } from '@atlassian/jira-project-settings-apps-common/src/urls';
import { Redirect, type RouteContext } from '@atlassian/jira-router';

type ChannelsUrlFragments = 'customer-permissions' | 'email' | 'portal-settings';

/**
 * Higher order component that generates a redirect to a specific route
 * These redirects to not push to history
 *
 * Currently this is used to redirect existing routes to the new channel routes
 */
export const channelsRedirect =
	(pageUrlFragment: ChannelsUrlFragments) =>
	({ match }: RouteContext) => {
		const { projectKey } = match.params;
		const url = `${getNextgenSettingsUrl(
			SERVICE_DESK_PROJECT,
			String(projectKey),
		)}/channels/${pageUrlFragment}`;

		return <Redirect to={url} push={false} />;
	};
