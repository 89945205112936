import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { globalLayout } from '@atlassian/jira-global-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import { createEntry } from '@atlassian/jira-route-entry';
import { layoutWithCondition } from '@atlassian/jira-route-layout';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { peopleProfilesRoute } from '@atlassian/jira-router-routes-people-profiles-routes';
import { rightSidebarsLayout } from '@atlassian/jira-service-project-layout';
import type PeopleProfilesType from '@atlassian/jira-spa-apps-people-profiles';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyPeopleProfiles = lazyForPaint<typeof PeopleProfilesType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-people-profiles", jiraSpaEntry: "async-people-profiles" */ '@atlassian/jira-spa-apps-people-profiles'
			),
		),
	{
		// becasue `@atlassian/ptc-embeddable-directory` package is not ready for SSR
		ssr: false,
	},
);

export const PeopleProfilesComponent = () => (
	<LazyPage Page={LazyPeopleProfiles} pageId="people-profiles" shouldShowSpinner />
);

export const peopleProfilesRouteEntry = createEntry(peopleProfilesRoute, {
	component: PeopleProfilesComponent,
	layout: layoutWithCondition(
		() => expValEquals('jsm_getting_started_page_for_nple_exp', 'cohort', 'variation'),
		rightSidebarsLayout,
		globalLayout,
	),
	navigation: {
		menuId: MENU_ID.PEOPLE,
	},
	resources: [...getNavigationResources(), getConsolidationStateResource()],
	forPaint: [LazyAtlassianNavigation, LazyPeopleProfiles],
	ufoName: 'people-profiles',
});
