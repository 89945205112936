import { useCallback, useEffect, useMemo, useRef } from 'react';
import killswitch from '@atlassian/jira-killswitch';
import { DEFAULT_OPTIONS } from '../../constants';
import type { Options } from '../../types';
import type { EntryPointActions, LoadActions } from '../types';

const PRELOAD_KILLSWITCH = 'entry_point_preload';

export const useEntryPointActions = (
	{ loadEntryPoint, unloadEntryPoint, commitPreload }: LoadActions,
	{
		preloadMaxAge = DEFAULT_OPTIONS.preloadMaxAge,
		timeToIntent = DEFAULT_OPTIONS.timeToIntent,
	}: Options,
	startMetric: (preloadTime?: number) => void,
) => {
	const preloadIntentTimeout = useRef<NodeJS.Timeout>();
	const preloadMaxAgeTimeout = useRef<NodeJS.Timeout>();
	const preloadLastRequested = useRef<number>(0);

	const cancelPreload = useCallback(() => {
		if (!killswitch(PRELOAD_KILLSWITCH)) {
			clearTimeout(preloadIntentTimeout.current);
			clearTimeout(preloadMaxAgeTimeout.current);
		}
	}, []);

	const load = useCallback(() => {
		cancelPreload();
		startMetric(preloadLastRequested.current);

		if (preloadLastRequested.current > 0) {
			commitPreload();
		} else {
			loadEntryPoint();
		}

		preloadLastRequested.current = 0;
	}, [cancelPreload, commitPreload, loadEntryPoint, startMetric]);

	const preload = useCallback(() => {
		if (killswitch(PRELOAD_KILLSWITCH)) {
			return;
		}

		cancelPreload();

		preloadIntentTimeout.current = setTimeout(() => {
			preloadLastRequested.current = Date.now();
			loadEntryPoint({ isPreload: true });

			preloadMaxAgeTimeout.current = setTimeout(() => {
				preloadLastRequested.current = 0;
			}, preloadMaxAge);
		}, timeToIntent);
	}, [cancelPreload, loadEntryPoint, preloadMaxAge, timeToIntent]);

	useEffect(() => cancelPreload, [cancelPreload]);

	return useMemo<EntryPointActions>(
		() => ({
			cancelPreload,
			load,
			preload,
			unload: unloadEntryPoint,
		}),
		[cancelPreload, load, preload, unloadEntryPoint],
	);
};
