import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common';
import { GENERIC_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector';
import {
	forgeGlobalModuleResource,
	forgeProjectModuleResource,
	forgeCustomFieldTypeResource,
	getNavigationSidebarGlobalSettingsResources,
} from '@atlassian/jira-navigation-apps-resources';
import AsyncSidebarForgeGlobalPage from '@atlassian/jira-navigation-apps-sidebar-forge-global-page/src/async';
import { genericProjectSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars';
import AsyncSidebarGlobalSettingsApps from '@atlassian/jira-navigation-apps-sidebar-global-settings-apps/src/async';
import AsyncSidebarGlobalSettingsIssues from '@atlassian/jira-navigation-apps-sidebar-global-settings-issues/src/async';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { createLayout } from '@atlassian/jira-route-layout';
import type { Route } from '@atlassian/jira-router';
import { getBusinessProjectResources } from '@atlassian/jira-router-resources-business-common';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info';
import { fieldContextConfigPageResource } from '@atlassian/jira-router-resources-forge-field-context-config';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { projectContextResourceWithCache } from '@atlassian/jira-router-resources-project-context';
import {
	getServiceDeskNavigationResources,
	getServiceDeskSharedNavigationSingleResources,
} from '@atlassian/jira-router-resources-service-desk-common';
import {
	forgeAdminSettingsRoute,
	forgeAdminRoute,
	forgeProjectRoute,
	forgeServicedeskQueueRoute,
	forgeFieldContextualConfigurationRoute,
	forgeGlobalRoute,
} from '@atlassian/jira-router-routes-forge-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import ForgeAdminPage, { LazyForgeAdminPage } from './ui/admin';
import ForgeFieldContextConfigPage, {
	LazyForgeFieldContextConfigPage,
} from './ui/field-context-config';
import ForgeGlobalPage, { LazyForgeGlobalPage } from './ui/global';
import ForgeProjectPage, { LazyForgeProjectPage } from './ui/project';
import ForgeServiceDeskQueuePage, { LazyForgeServiceDeskQueuePage } from './ui/servicedesk-queue';

export const getNavigationForgeGlobalPageResources = () => [forgeGlobalModuleResource];

export const getForgeAdminPageRoute = (): Partial<Route> => ({
	component: ForgeAdminPage,
	layout: globalSettingsLayout,
	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_APPS_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsApps,
	},
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarGlobalSettingsResources(),
		navigationSidebarGlobalResource,
	],
	forPaint: [LazyAtlassianNavigation, LazyForgeAdminPage],
});

export const getForgeProjectPageRoute = (): Partial<Route> => ({
	component: ForgeProjectPage,
	layout: genericProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: genericProjectSidebars,
		horizontal: GENERIC_PROJECT_HORIZONTAL_NAV,
	},
	resources: [
		...getBusinessProjectResources(),
		...getServiceDeskSharedNavigationSingleResources(),
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		forgeProjectModuleResource,
		projectContextResourceWithCache,
		getConsolidationStateResource(),
	],
	forPaint: [LazyAtlassianNavigation, LazyForgeProjectPage],
});

export const getForgeFieldContextConfigPageRoute = (): Partial<Route> => ({
	component: ForgeFieldContextConfigPage,
	layout: globalSettingsLayout,
	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsIssues,
	},
	resources: [
		...getNavigationResources(),
		forgeCustomFieldTypeResource,
		fieldContextConfigPageResource,
		navigationSidebarGlobalResource,
	],
	forPaint: [LazyAtlassianNavigation, LazyForgeFieldContextConfigPage],
});

export const getForgeServiceDeskQueuePageRoute = (): Partial<Route> => ({
	component: ForgeServiceDeskQueuePage,
	layout: serviceProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
	},
	resources: [...getServiceDeskNavigationResources(), getConsolidationStateResource()],
	forPaint: [LazyAtlassianNavigation, LazyForgeServiceDeskQueuePage],
});

export const getForgeGlobalPageRoute = (): Partial<Route> => ({
	component: ForgeGlobalPage,
	layout: createLayout(),
	navigation: {
		menuId: MENU_ID.ADDONS,
		sidebar: AsyncSidebarForgeGlobalPage,
	},
	resources: [...getNavigationResources(), ...getNavigationForgeGlobalPageResources()],
	forPaint: [LazyAtlassianNavigation, LazyForgeGlobalPage],
});

export const forgeRouteEntries: Route[] = [
	createEntry(forgeAdminSettingsRoute, getForgeAdminPageRoute()),
	createEntry(forgeAdminRoute, getForgeAdminPageRoute()),
	createEntry(forgeProjectRoute, getForgeProjectPageRoute()),
	createEntry(forgeServicedeskQueueRoute, getForgeServiceDeskQueuePageRoute()),
	createEntry(forgeFieldContextualConfigurationRoute, getForgeFieldContextConfigPageRoute()),
	createEntry(forgeGlobalRoute, getForgeGlobalPageRoute()),
];
