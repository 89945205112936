import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ProjectSettingsServiceDeskLanguageTranslationsType from '@atlassian/jira-spa-apps-project-settings-service-desk-language-translations';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyProjectSettingsServiceDeskLanguageTranslations = lazyForPaint<
	typeof ProjectSettingsServiceDeskLanguageTranslationsType
>(
	() =>
		import(
			/* webpackChunkName: "async-project-settings-service-desk-language-translations", jiraSpaEntry: "async-project-settings-service-desk-language-translations" */ '@atlassian/jira-spa-apps-project-settings-service-desk-language-translations'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyProjectSettingsServiceDeskLanguageTranslations}
		pageId="project-settings-language-translations"
		shouldShowSpinner
	/>
);
