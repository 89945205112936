import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ConnectAppsSettingsType from '@atlassian/jira-spa-apps-project-settings-connect-apps';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyConnectAppsSettings = lazyForPaint<typeof ConnectAppsSettingsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-project-settings-connect-apps", jiraSpaEntry: "async-project-settings-connect-apps" */ '@atlassian/jira-spa-apps-project-settings-connect-apps'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyConnectAppsSettings}
		pageId="project-settings-connect-apps"
		shouldShowSpinner={false}
	/>
);
