import React from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import Placeholder from '@atlassian/jira-placeholder';
import type { MailExpiryReminder as MailExpiryBannerType } from './view';

const LazyMailExpiryBanner = lazyAfterPaint<typeof MailExpiryBannerType>(() =>
	import(/* webpackChunkName: "async-mail-expiry-banner" */ './view').then(
		({ MailExpiryReminder }) => MailExpiryReminder,
	),
);

export const MailExpiryBannerAsync = () => (
	<JSErrorBoundary
		id="async-mail-expiry-banner"
		packageName="jiraPlatformUiMailExpiryReminder"
		fallback="flag"
	>
		<Placeholder name="async-mail-expiry-banner" fallback={null}>
			<LazyMailExpiryBanner />
		</Placeholder>
	</JSErrorBoundary>
);
