import { setInitialPageLoadTimingFromPerformanceMarks } from '../performance-marks-tools';
/**
 * Captures and sets the timings for key stages during the initial page load using performance marks.
 * This function is designed for client-side execution and aids in performance analysis by tracking stages such as
 * early flush, CSS loading, server-side rendering, and more.
 */
export const addInitialPageLoadTimings = () => {
	if (__SERVER__) {
		return;
	}
	setInitialPageLoadTimingFromPerformanceMarks(
		'early-flush',
		'ssr.spa.early-common-flush:start',
		'ssr.spa.early-route-flush:end',
	);
	setInitialPageLoadTimingFromPerformanceMarks(
		'early-flush/common-preload',
		'ssr.spa.early-common-flush:start',
		'ssr.spa.metatags:start',
	);
	setInitialPageLoadTimingFromPerformanceMarks(
		'early-flush/metatags',
		'ssr.spa.metatags:start',
		'ssr.spa.metatags:end',
	);
	setInitialPageLoadTimingFromPerformanceMarks(
		'early-flush/feature-flags',
		'ssr.spa.feature-flags:start',
		'ssr.spa.feature-flags:end',
	);
	setInitialPageLoadTimingFromPerformanceMarks(
		'early-flush/inline-scripts',
		'ssr.spa.feature-flags:end',
		'ssr.spa.early-common-flush:end',
	);
	setInitialPageLoadTimingFromPerformanceMarks(
		'early-flush/route-preload',
		'ssr.spa.early-route-flush:start',
		'ssr.spa.early-route-flush:end',
	);
	setInitialPageLoadTimingFromPerformanceMarks(
		'css-assets',
		'ssr.spa.css-assets:start',
		'ssr.spa.css-assets:end',
	);
	setInitialPageLoadTimingFromPerformanceMarks('ssr-output', 'ssr.spa.start', 'ssr.spa.state');
	setInitialPageLoadTimingFromPerformanceMarks(
		'ssr-output/styles',
		'ssr.spa.start',
		'ssr.spa.styles',
	);
	setInitialPageLoadTimingFromPerformanceMarks('ssr-output/html', 'ssr.spa.styles', 'ssr.spa.html');
	setInitialPageLoadTimingFromPerformanceMarks('ssr-output/state', 'ssr.spa.html', 'ssr.spa.state');
	setInitialPageLoadTimingFromPerformanceMarks(
		'pre-spa',
		'vendor-entry.js:eval-start',
		'commons-entry.js:eval-stop',
	);
	setInitialPageLoadTimingFromPerformanceMarks(
		'pre-spa/vendor',
		'vendor-entry.js:eval-start',
		'vendor-entry.js:eval-stop',
	);
	setInitialPageLoadTimingFromPerformanceMarks(
		'pre-spa/commons',
		'vendor-entry.js:eval-stop',
		'commons-entry.js:eval-stop',
	);
	setInitialPageLoadTimingFromPerformanceMarks(
		'pre-spa/commons/bundle-ready',
		'vendor-entry.js:eval-stop',
		'commons-entry.js:eval-start',
	);
	setInitialPageLoadTimingFromPerformanceMarks(
		'pre-spa/commons/eval',
		'commons-entry.js:eval-start',
		'commons-entry.js:eval-stop',
	);
	setInitialPageLoadTimingFromPerformanceMarks(
		'jira-spa/bundle-ready',
		'commons-entry.js:eval-stop',
		'jira-spa.js:eval-start',
	);
	setInitialPageLoadTimingFromPerformanceMarks(
		'jira-spa/language-pack',
		'jira-spa/language-pack.start',
		'jira-spa/language-pack.end',
		true,
		true,
	);
	setInitialPageLoadTimingFromPerformanceMarks(
		'batch-js-heritage.eval',
		'jira.heritage_batch_file_eval:start',
		'jira.heritage_batch_file_eval:end',
	);
};
