import {
	getUserProperty,
	setUserProperty,
} from '@atlassian/jira-admin-pages-performance/src/services/user-properties';

const PROPERTY_KEY = 'jira.user.settings.system.site-optimizer-spotlight';

export const hasSeenSiteOptimiserSpotlight = async (accountId: string) => {
	const response = await getUserProperty({
		accountId,
		propertyKey: PROPERTY_KEY,
		defaultValue: false,
	});
	// If they have not seen the spotlight, return true and mark as shown
	if (!response.value) {
		await setUserProperty({
			accountId,
			propertyKey: PROPERTY_KEY,
			propertyValue: true,
		});
		return false;
	}
	return true;
};
