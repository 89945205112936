import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LazyOpsReports = lazyForPaint<any>(
	() =>
		import(
			/* webpackChunkName: "async-jira-eoc-ops-reports", jiraSpaEntry: "async-jira-eoc-ops-reports" */ '@atlassian/jira-eoc-ops-reports'
		),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LazyOpsReportsRedirect = lazyForPaint<any>(() =>
	import(
		/* webpackChunkName: "async-jira-eoc-ops-reports-redirect", jiraSpaEntry: "async-jira-eoc-ops-reports" */ '@atlassian/jira-eoc-ops-reports'
	).then((exportedMembers) => exportedMembers.OpsReportsRedirect),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LazyGlobalOpsReports = lazyForPaint<any>(() =>
	import(
		/* webpackChunkName: "async-jira-eoc-global-ops-reports-redirect", jiraSpaEntry: "async-jira-eoc-ops-reports" */ '@atlassian/jira-eoc-ops-reports'
	).then((exportedMembers) => exportedMembers.GlobalOpsReports),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LazyOpsReportsUnsubscribe = lazyForPaint<any>(() =>
	import(
		/* webpackChunkName: "async-jira-eoc-ops-reports-unsubscribe", jiraSpaEntry: "async-jira-eoc-ops-reports" */ '@atlassian/jira-eoc-ops-reports'
	).then((exportedMembers) => exportedMembers.OpsReportsUnsubscribe),
);

export const OpsReportsRedirect = () => (
	<LazyPage
		Page={LazyOpsReportsRedirect}
		pageId="async-jira-eoc-ops-reports-redirect"
		shouldShowSpinner={false}
	/>
);

export const GlobalOpsReports = () => (
	<LazyPage
		Page={LazyGlobalOpsReports}
		pageId="async-jira-eoc-global-ops-reports"
		shouldShowSpinner={false}
	/>
);

export const OpsReports = () => (
	<LazyPage Page={LazyOpsReports} pageId="async-jira-eoc-ops-reports" shouldShowSpinner={false} />
);

export const OpsReportsUnsubscribe = () => (
	<LazyPage
		Page={LazyOpsReportsUnsubscribe}
		pageId="async-jira-eoc-ops-reports-unsubscribe"
		shouldShowSpinner={false}
	/>
);
