import type { createResource as CreateResourceType, RouteResource } from '@atlassian/jira-router';

export const MIN_MAX_AGE = 5 * 1000;

type CreateResourceShape<T> = Parameters<typeof CreateResourceType>[0] & {
	getData?: RouteResource<T>['getData'];
	getDataLoader?: () => Promise<{
		default: RouteResource<T>['getData'];
	}>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withResourceEarlyData = <T extends CreateResourceShape<any>>({
	type,
	getData: next,
	getDataLoader,
	...rest
}: T) => {
	const getData = (...args: Parameters<RouteResource['getData']>) => {
		performance.mark(`issueViewEarlyScripts:${type}:withResourceEarlyData:called`);

		if (!__SERVER__ && type in window) {
			// @ts-expect-error - Element implicitly has an 'any' type because index expression is not of type 'number'
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			const promise = window[type] as Promise<any>;
			// @ts-expect-error - Element implicitly has an 'any' type because index expression is not of type 'number'
			delete window[type];
			getDataLoader && promise.then(getDataLoader);
			return promise;
		}

		if (getDataLoader) {
			return getDataLoader().then(({ default: getDataPromise }) => getDataPromise(...args));
		}
		return next && next(...args);
	};
	return { ...rest, type, getData };
};
