import { AriGraphSortOrderInput } from '@atlassian/jira-devops-ari-graph-filters';
import {
	QUERY_PARAM_SEVERITY,
	QUERY_PARAM_SORT_KEY,
	QUERY_PARAM_SORT_ORDER,
	QUERY_PARAM_VULN_STATUS,
	QUERY_PARAM_CONTAINER,
	initialVulnerabilityTableOptions,
	NO_FILTER_VULN_STATUS,
} from '../../common/constants';
import type { AriGraphVulnerabilitiesFilter, QueryParams } from '../../common/types';
import {
	arrayQueryParamToFilter,
	connectionQueryParamToFilter,
	parseSeverity,
	parseSortDirection,
	parseStatus,
} from '../../common/utils';

const getFilterValue = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	values: any[],
	fieldStortKey: string,
	sortKey: string | null | undefined = initialVulnerabilityTableOptions.sortKey,
	sortOrder: string | null | undefined = initialVulnerabilityTableOptions.sortOrder,
) => ({
	...(values.length > 0 ? { values } : {}),
	...(sortKey === fieldStortKey && {
		sort: {
			priority: 2,
			order: parseSortDirection(sortOrder, AriGraphSortOrderInput.DESC),
		},
	}),
});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getOrDefault = (input: any[], defaultArray: any[]) => (input.length ? input : defaultArray);

export const getGqlFiltersFromQueryParams = (
	queryParams: QueryParams,
	connectionAri: string,
): AriGraphVulnerabilitiesFilter => {
	const containerValues = getOrDefault(
		connectionQueryParamToFilter(queryParams[QUERY_PARAM_CONTAINER], connectionAri).filter(Boolean),
		initialVulnerabilityTableOptions.connectionFilter,
	);
	const statusQueryParams: string[] = arrayQueryParamToFilter(queryParams[QUERY_PARAM_VULN_STATUS]);
	return {
		and: [
			{
				lastUpdated: { sort: { priority: 1, order: AriGraphSortOrderInput.DESC } },
				to_severity: getFilterValue(
					getOrDefault(
						arrayQueryParamToFilter(queryParams[QUERY_PARAM_SEVERITY])
							.map(parseSeverity)
							.filter(Boolean),
						initialVulnerabilityTableOptions.severityFilter,
					),
					'severity',
					queryParams[QUERY_PARAM_SORT_KEY],
					queryParams[QUERY_PARAM_SORT_ORDER],
				),
				to_status: getFilterValue(
					statusQueryParams.includes(NO_FILTER_VULN_STATUS)
						? []
						: getOrDefault(
								statusQueryParams.map(parseStatus).filter(Boolean),
								initialVulnerabilityTableOptions.statusFilter,
							),
					'status',
					queryParams[QUERY_PARAM_SORT_KEY],
					queryParams[QUERY_PARAM_SORT_ORDER],
				),
				createdAt: {
					...(queryParams[QUERY_PARAM_SORT_KEY] === 'introduced' && {
						sort: {
							priority: 2,
							order: parseSortDirection(
								queryParams[QUERY_PARAM_SORT_ORDER],
								AriGraphSortOrderInput.DESC,
							),
						},
					}),
				},
				to_container: {
					containerAri: {
						value: {
							...(containerValues.length > 0 ? { values: containerValues } : {}),
						},
					},
				},
			},
		],
	};
};
