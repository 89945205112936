import { createStore } from 'react-sweet-state';
import { actions, type Actions } from './actions';
import type { State } from './types';

export const initialState: State = {
	data: null,
	loading: false,
	error: null,
	hasFirstError: false,
};

export default createStore<State, Actions>({
	name: 'connect-apps',
	initialState,
	actions,
});
