import React, { useMemo } from 'react';
import {
	JSErrorBoundary as JSErrorBoundaryOriginal,
	type JSErrorBoundaryProps,
} from '@atlassian/jira-error-boundaries';
import { fireErrorAnalytics, type AnalyticsPayload } from '@atlassian/jira-errors-handling';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithFF } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import type { AnalyticsAttributes } from '@atlassian/jira-product-analytics-bridge';

// ugly API due to strict CI forbidding dynamic FF checks
export function getRelevantFFlagsForObservability(): Array<[string, boolean]> {
	return [
		// to be kept up-to-date
		// last update 2024/08/14
		// reference: https://hello.atlassian.net/wiki/spaces/JNR/pages/4001046109/Coding+for+the+nav4+rollout
		['sea-2744-jsw-tabs-nav', ff('sea-2744-jsw-tabs-nav')],
		['jira_nav4', fg('jira_nav4')],
		['jira_nav4_dogfooding', fg('jira_nav4_dogfooding')],
		['jira_nav4_dogfooding_phase-1', fg('jira_nav4_dogfooding_phase-1')],
		['jira_nav4_dogfooding_phase-2', fg('jira_nav4_dogfooding_phase-2')],
	];
}

export interface NavErrorBoundaryProps
	extends Omit<JSErrorBoundaryProps, 'onError' | 'attributes' | 'sendToPrivacyUnsafeSplunk'> {
	navRevision?: 'v3' | 'v4';
}

// copied from jira/src/packages/forge/forge-ui-analytics/src/common/utils/environment/index.tsx
export const isOverridingFragments = (): boolean => {
	if (typeof window !== 'undefined') {
		return /^(JF-TEST|JF-BB\d+-\d+)$/.test(window?.BUILD_KEY);
	}

	if (typeof globalThis !== 'undefined') {
		return /^(JF-TEST|JF-BB\d+-\d+)$/.test(globalThis.BUILD_KEY);
	}

	return false;
};

function inferNavRevision(): NavErrorBoundaryProps['navRevision'] {
	// This is brittle since we have several FFs https://hello.atlassian.net/wiki/spaces/SEA/pages/3841892327/Jira+Nav+v4+Feature+Flags
	// if possible, try to pass the revision explicitly as a prop
	return ff('sea-2744-jsw-tabs-nav') ? 'v4' : 'v3';
}

function getExtraAttributes(_error: unknown): AnalyticsAttributes {
	const attributes: AnalyticsAttributes = {
		ffs: getRelevantFFlagsForObservability()
			.filter(([, value]) => value)
			.map(([key]) => key)
			.join(),
		rev: inferNavRevision(),
	};

	return attributes;
}

function getAnalyticsPayload(
	error: Error,
	meta: Partial<AnalyticsPayload['meta']>,
	navRevision?: NavErrorBoundaryProps['navRevision'],
): AnalyticsPayload {
	const payload: AnalyticsPayload = {
		error,
		meta: {
			// intentionally fixed metas
			// so that we generate a unique event signature
			// so that we can register it in the analytics portal: https://data-portal.internal.atlassian.com/analytics/registry/67955
			// so that we get the attributes to show up in Splunk: https://splunk.paas-inf.net/en-GB/app/search/2024_sea2744_jira_nav_v4_container_navigation
			id: 'logic',
			packageName: 'JiraNav',
		},
		attributes: {
			...getExtraAttributes(error),
			// forward the original meta as attributes to identify the location
			pkg: meta.packageName,
			id: meta.id,
			// if explicitly passed, override the rev
			...(navRevision && { rev: navRevision }),
		},
		skipSentry: true, // this event is on top of what is already sent by the JSErrorBoundary, which includes Sentry
	};

	return payload;
}

/** Wrapper around <JSErrorBoundary> adding targeted features useful to the Navigation team
 */
export const JSErrorBoundaryWithExtraReporting = (props: NavErrorBoundaryProps) => {
	const { id, packageName, navRevision } = props;

	const onError: JSErrorBoundaryProps['onError'] = useMemo((): JSErrorBoundaryProps['onError'] => {
		const meta: Partial<AnalyticsPayload['meta']> = {
			id,
			packageName,
		};

		const _onError: JSErrorBoundaryProps['onError'] = (...args): void => {
			if (isOverridingFragments()) {
				// this is a local test environment, don't send anything
				return;
			}

			const [_, error] = args;
			fireErrorAnalytics(getAnalyticsPayload(error, meta, navRevision));
		};
		return _onError;
	}, [id, packageName, navRevision]);

	return <JSErrorBoundaryOriginal {...props} onError={onError} />;
};

export const NavErrorBoundary = componentWithFF(
	'sea-2744-jsw-tabs-nav',
	(props: NavErrorBoundaryProps) => <JSErrorBoundaryWithExtraReporting {...props} />,
	(props: NavErrorBoundaryProps) => <JSErrorBoundaryOriginal {...props} />,
);
