import { log } from '@atlassian/jira-common-util-logging';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fetchJson } from '@atlassian/jira-fetch';
// eslint-disable-next-line jira/restricted/@atlassian/jira-router
import { createResource, useResource } from '@atlassian/jira-router';
import { getEdition, JIRA_SOFTWARE, FREE_EDITION } from '@atlassian/jira-shared-types';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller';
import { RESOURCE_TYPE_NAVIGATION_PLANS_VISIBILITY, LOCATION } from './constants';
import type {
	UseNavigationPlansVisibility,
	UseNavigationViewPlansPermission,
	NavigationPlansVisibilityResponseData,
	UseNavigationEditPlansPermission,
	UseIsAdvancedRoadmapsTrial,
} from './types';

export const navigationPlansVisibilityResource = createResource<
	NavigationPlansVisibilityResponseData | false
>({
	type: RESOURCE_TYPE_NAVIGATION_PLANS_VISIBILITY,
	getKey: () => 'state',
	getData: async (_, { tenantContext }): Promise<NavigationPlansVisibilityResponseData | false> => {
		try {
			if (tenantContext.isAnonymous) {
				return false;
			}

			const response = await fetchJson(`${tenantContext.baseUrl}/rest/jpo/1.0/navigation/simple`);

			return response;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			log.safeErrorWithoutCustomerData(LOCATION, 'Error while fetching plans visibility', error);
		}
		return false;
	},
	maxAge: Infinity,
});

export const useNavigationPlansVisibility: UseNavigationPlansVisibility = () => {
	const { data, loading, error } = useResource(navigationPlansVisibilityResource);
	const appEditions = useAppEditions();
	const jswEdition = getEdition(JIRA_SOFTWARE, appEditions);

	let isVisible = false;

	if (!error && !loading && data !== null && typeof data === 'object') {
		isVisible = data.isVisible;
	}

	if (isVisible && jswEdition === FREE_EDITION) {
		const shouldHidePlans = expVal('hide_plans_tab_for_jira_free', 'shouldHidePlansTab', false);
		return [!shouldHidePlans];
	}

	return [isVisible];
};

export const useNavigationViewPlansPermission: UseNavigationViewPlansPermission = () => {
	const { data, loading, error } = useResource(navigationPlansVisibilityResource);

	let hasEditOrViewPermissions = false;

	if (!error && !loading && data !== null && typeof data === 'object') {
		hasEditOrViewPermissions = data.hasEditOrViewPermissions;
	}

	return [hasEditOrViewPermissions];
};

export const useNavigationEditPlansPermission: UseNavigationEditPlansPermission = () => {
	const { data, loading, error } = useResource(navigationPlansVisibilityResource);

	let canEditPlans = false;

	if (!error && !loading && data !== null && typeof data === 'object') {
		canEditPlans = data.hasEditPermissions || false;
	}

	return canEditPlans;
};

export const useIsAdvancedRoadmapsTrial: UseIsAdvancedRoadmapsTrial = () => {
	const { data, loading, error } = useResource(navigationPlansVisibilityResource);

	let isAdvancedRoadmapsTrial = false;

	if (!error && !loading && data !== null && typeof data === 'object') {
		isAdvancedRoadmapsTrial = data.isAdvancedRoadmapsTrial || false;
	}

	return isAdvancedRoadmapsTrial;
};

export type {
	UseNavigationPlansVisibility,
	UseNavigationViewPlansPermission,
	UseIsAdvancedRoadmapsTrial,
};
