import React, { memo } from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import {
	setPerformanceMarkLoadStart,
	setPerformanceMarkLoadEnd,
} from '@atlassian/jira-navigation-apps-sidebar-common';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-common/async';
import type GlobalIssuesSettingsSidebarType from './index';

const LazyGlobalIssuesSettingsSidebar = lazyAfterPaint<typeof GlobalIssuesSettingsSidebarType>(
	() => {
		setPerformanceMarkLoadStart();
		return import(/* webpackChunkName: "async-sidebar-global-settings-issues" */ './index').then(
			(bundle) => {
				setPerformanceMarkLoadEnd();
				return bundle;
			},
		);
	},
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const GlobalIssuesSettingsSidebar = memo<Record<any, any>>(() => (
	<SidebarBoundary id="globalIssuesSettings">
		<LazyGlobalIssuesSettingsSidebar />
	</SidebarBoundary>
));

export default GlobalIssuesSettingsSidebar;
