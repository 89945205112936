import log from '@atlassian/jira-common-util-logging/src/log';

export const { safeErrorWithoutCustomerData, safeInfoWithoutCustomerData } = log;

export type Options = {
	/** Retries */
	tentatives: number;
	timeout: number;
	shouldLogError: boolean;
};

export type BatchDetailsResponseType = {
	resources: {
		url: string;
		key: string;
		batchType: string;
		resourcesType: string;
		media?: string;
	};
	unparsedData: Map<string, string>;
	unparsedErrors: Map<string, string>;
};

export const MAX_RETRIES = 40;
export const DEFAULT_TIMEOUT = 500;
export const SHOULD_LOG_ERROR = true;
