/**
 * @generated SignedSource<<739047044a04affb48020f16b589a67b>>
 * @relayHash 3caa9fde75ad61c402696bca56249a4b
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 8dee2355fbdc129ba049a79fdc979156b50f2dc51a33bc3b2a89b3dc09884f25

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcVirtualAgentBrowseStandardFlowsQuery } from './srcVirtualAgentBrowseStandardFlowsQuery.graphql';

const node: PreloadableConcreteRequest<srcVirtualAgentBrowseStandardFlowsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "8dee2355fbdc129ba049a79fdc979156b50f2dc51a33bc3b2a89b3dc09884f25",
    "metadata": {},
    "name": "srcVirtualAgentBrowseStandardFlowsQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
