/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, {
	createContext,
	type CSSProperties,
	Fragment,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';

import { cssMap, jsx } from '@compiled/react';
import { bind } from 'bind-event-listener';
import { useUID } from 'react-uid';

import type { StrictXCSSProp } from '@atlaskit/css';
import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';

import { InteractionSurface } from '../../components/interaction-surface';
import { SkipLinksContainer } from '../../components/skip-links/skip-links-container';
import { useSkipLink } from '../../context/skip-links/skip-links-context';
import { SkipLinksProvider } from '../../context/skip-links/skip-links-provider';

import { DangerouslyHoistCssVarToDocumentRoot, HoistCssVarToLocalGrid } from './hoist-utils';
import { PanelSplitterProvider, type ResizeBounds } from './panel-splitter';
import {
	SideNavVisibilityProvider,
	useSideNavVisibility,
	useToggleSideNav,
} from './side-nav-visibility';
import {
	useSideNavVisibilityCallbacks,
	type VisibilityCallback,
} from './side-nav/use-side-nav-visibility-callbacks';

// These are "compressed" to discourage usage.
const sideNavVar = '--n_sNvw';
const asideVar = '--n_asDw';
const panelVar = '--n_pnlW';
const bannerMountedVar = '--n_bnrM';
const topBarMountedVar = '--n_tbrM';

// We aren't using template literals here because Compiled can't compiled them in platform ATM.
const contentHeightWhenFixed = `calc(100vh - var(--n_bnrM, 0px) - var(--n_tbrM, 0px))`;
const contentInsetBlockStart = `calc(var(--n_bnrM, 0px) + var(--n_tbrM, 0px))`;

// We define variables as they can change their size depending on the viewport width. That isn't needed for
// general grid item slots because, well, they just take up the size of the grid item! In this instance since
// the UNSAFE absolutely positioned sibling isn't on the grid it needs extra layout information.
// Note: THESE ARE THE SAME VALUES AS THE LEGACY PAGE LAYOUT.
// WHEN WE ELIMINATE USAGE OF THOSE VARIABLES THESE CAN BE RENAMED TO BE HASHED.
const UNSAFE_topBarVar = '--topNavigationHeight';
const UNSAFE_bannerVar = '--bannerHeight';
const UNSAFE_sideNavLayoutVar = '--leftSidebarWidth';
const UNSAFE_asideLayoutVar = '--rightSidebarWidth';
const UNSAFE_panelLayoutVar = '--rightPanelWidth';

// The following UNSAFE variables are used to absolutely position elements that aren't a child of page layout.
// Known use cases: Legacy pages rendered inside Confluence and Jira.
export const UNSAFE_MAIN_BLOCK_START_FOR_LEGACY_PAGES_ONLY = `calc(var(${UNSAFE_bannerVar}, 0px) + var(${UNSAFE_topBarVar}, 0px))`;
export const UNSAFE_MAIN_INLINE_START_FOR_LEGACY_PAGES_ONLY = `var(${UNSAFE_sideNavLayoutVar}, 0px)`;
export const UNSAFE_MAIN_INLINE_END_FOR_LEGACY_PAGES_ONLY = `calc(var(${UNSAFE_asideLayoutVar}, 0px) + var(${UNSAFE_panelLayoutVar}, 0px))`;

const panelSplitterResizingVar = '--localPanelSplitterResizingWidth';
const sideNavWidthResizeBounds: ResizeBounds = { min: '160px', max: '50vw' };

// ID of the root element that the banner and top bar slots hoist their sizes to. Only internally exported.
export const gridRootId = 'unsafe-design-system-page-layout-root';

// useUID generates a number which cannot be used as an Id for html elements as is, therefore we need to prefix it with text.
// TODO: Change to useId when we switch to React 18
export const usePrefixedUID = (prefix: string = 'element') => `${prefix}-${useUID()}`;

/**
 * We define the z-indexes here so each page slot can be locally layered against each other.
 * For globally defined values such as flag, modal, etc, we can continue to
 * rely on accessing them through global means.
 */
const localSlotLayers = {
	topBarBorder: 4,
	topBar: 3,
	banner: 3,
	sideNav: 2,
	contentBlockBorder: 2,
	contentInlineBorder: 1,
	panel: 1,
};

const styles = cssMap({
	root: {
		display: 'grid',
		minHeight: '100vh',
		gridTemplateAreas: `
            "banner"
            "top-bar"
            "main"
            "aside"
       `,
		gridTemplateColumns: 'minmax(0, 1fr)',
		gridTemplateRows: 'auto auto 1fr auto',
		'@media (min-width: 64rem)': {
			gridTemplateAreas: `
            "banner banner banner"
            "top-bar top-bar top-bar"
            "side-nav main aside"
       `,
			gridTemplateRows: 'auto auto 3fr',
			gridTemplateColumns: 'auto minmax(0,1fr) auto',
		},
		// Panel is only shown as a separate column on large viewports
		'@media (min-width: 90rem)': {
			gridTemplateAreas: `
                "banner banner banner banner"
                "top-bar top-bar top-bar top-bar"
                "side-nav main aside panel"
           `,
			gridTemplateRows: 'auto auto 3fr',
			gridTemplateColumns: 'auto minmax(0,1fr) auto auto',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
		'> :not([data-layout-slot])': {
			// This hides any non-layout components that would otherwise be added to an implicit grid
			// track and break the page layout grid in unexpected and hilarious ways. Adding anything
			// as a child to page layout that is not a layout component is not supported.
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
			display: 'none !important',
		},
	},
	banner: {
		gridArea: 'banner',
		height: `var(${bannerMountedVar})`,
		insetBlockStart: 0,
		position: 'sticky',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		zIndex: localSlotLayers.banner,
		overflow: 'hidden',
	},
	topBar: {
		display: 'grid',
		gridTemplateColumns: 'auto 1fr auto',
		paddingInline: token('space.150'),
		gap: token('space.200'),
		alignItems: 'center',
		backgroundColor: token('elevation.surface'),
		boxSizing: 'border-box',
		gridArea: 'top-bar',
		height: `var(${topBarMountedVar})`,
		// This sets the sticky point to be just below banner. It's needed to ensure the stick
		// point is exactly where this element is rendered to with no wiggle room. Unfortunately the CSS
		// spec for sticky doesn't support "stick to where I'm initially rendered" so we need to tell it.
		insetBlockStart: `var(${bannerMountedVar}, 0px)`,
		position: 'sticky',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		zIndex: localSlotLayers.topBar,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		' > span[data-ep-placeholder-id="top_navigation_skeleton"]': {
			// TODO: BLU-3336 This is needed as a workaround for the JIRA issue (it places a placeholder span as a direct child of TopBar which breaks the grid layout). Please remove when the proper fix is applied.
			// We get the span that is immediately nested under TopNav and ignore how it affects the layout. We do this so our Grid layout ignores the `span` and applies to it's children.
			// This is only applicable to the JIRA pages that use EntryPoint such as /jira/projects or /jira/software/projects/.../calendar
			display: 'contents',
		},
	},
	sideNav: {
		backgroundColor: token('elevation.surface.overlay'),
		boxShadow: token('elevation.shadow.overlay'),
		gridArea: 'main / aside / aside / aside',
		// Height is set so it takes up all of the available viewport space minus top bar + banner.
		// Since the side nav is always rendered ontop of other grid items across all viewports height is
		// always set.
		height: contentHeightWhenFixed,
		// This sets the sticky point to be just below top bar + banner. It's needed to ensure the stick
		// point is exactly where this element is rendered to with no wiggle room. Unfortunately the CSS
		// spec for sticky doesn't support "stick to where I'm initially rendered" so we need to tell it.
		insetBlockStart: contentInsetBlockStart,
		position: 'sticky',
		// For mobile viewports, the side nav will take up 90% of the screen width, up to a maximum of 320px.
		width: 'min(90%, 320px)',
		// On small viewports the side nav is displayed above other slots so we create a stacking context.
		// We keep the side nav with a stacking context always so it is rendered above main content.
		// This comes with a caveat that main is rendered underneath the side nav content so for any
		// menu dialogs rendered with "shouldRenderToParent" they could be cut off unintentionally.
		// Unfortunately this is the best of bad solutions.
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		zIndex: localSlotLayers.sideNav,
		'@media (min-width: 48rem)': {
			width: `var(${panelSplitterResizingVar}, var(${sideNavVar}))`,
		},
		'@media (min-width: 64rem)': {
			backgroundColor: token('elevation.surface'),
			boxShadow: 'initial',
			gridArea: 'side-nav',
		},
	},
	sideNavInteractionSurface: {
		// Taking full space of container to capture hover interactions on the entire side nav
		width: '100%',
		height: '100%',
	},
	sideNavHiddenMobileAndDesktop: {
		display: 'none',
	},
	sideNavHiddenMobileOnly: {
		display: 'none',
		'@media (min-width: 64rem)': {
			display: 'initial',
		},
	},
	sideNavHiddenDesktopOnly: {
		'@media (min-width: 64rem)': {
			display: 'none',
		},
	},
	main: {
		gridArea: 'main',
	},
	mainCurvedCorner: {
		'@media (min-width: 64rem)': {
			borderStartStartRadius: '6px',
		},
	},
	aside: {
		gridArea: 'aside',
		boxSizing: 'border-box',
		'@media (min-width: 64rem)': {
			width: `var(${asideVar})`,
			justifySelf: 'end',
		},
	},
	panel: {
		gridArea: 'main / aside / aside / aside',
		justifySelf: 'end',
		width: `var(${panelVar})`,
		boxSizing: 'border-box',
		// On small viewports the panel is displayed above other slots so we set its zindex.
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
		zIndex: localSlotLayers.panel,
		// Height is set so it takes up all of the available viewport space minus top bar + banner.
		// Since panel is always rendered ontop of other grid items across all viewports height is
		// always set.
		height: contentHeightWhenFixed,
		overflow: 'auto',
		position: 'sticky',
		// This sets the sticky point to be just below top bar + banner. It's needed to ensure the stick
		// point is exactly where this element is rendered to with no wiggle room. Unfortunately the CSS
		// spec for sticky doesn't support "stick to where I'm initially rendered" so we need to tell it.
		insetBlockStart: contentInsetBlockStart,
		'@media (min-width: 90rem)': {
			gridArea: 'panel',
			// On large viewports the panel is displayed next to other slots so we reset the zindex.
			zIndex: 'auto',
		},
	},
	fixedContentArea: {
		// This sets the sticky point to be just below top bar + banner. It's needed to ensure the stick
		// point is exactly where this element is rendered to with no wiggle room. Unfortunately the CSS
		// spec for sticky doesn't support "stick to where I'm initially rendered" so we need to tell it.
		insetBlockStart: contentInsetBlockStart,
		overflow: 'auto',
		'@media (min-width: 64rem)': {
			// Height is set so it takes up all of the available viewport space minus top bar + banner.
			// This is only set on larger viewports meaning stickiness only occurs on them.
			// On small viewports it is not sticky.
			height: contentHeightWhenFixed,
			position: 'sticky',
		},
	},
});

const DangerouslyHoistSlotSizes = createContext(false);

const contentBorderStyles = cssMap({
	largeViewportTop: {
		boxSizing: 'border-box',
		borderBlockStart: `1px solid ${token('color.border')}`,
		display: 'none',
		gridArea: 'main',
		// This makes this grid item span all available columns so if aside or panel are mounted it spans to them.
		gridColumnEnd: -1,
		// Height is set so it takes up all of the available viewport space minus top bar + banner.
		height: contentHeightWhenFixed,
		// This sets the sticky point to be just below top bar + banner. It's needed to ensure the stick
		// point is exactly where this element is rendered to with no wiggle room. Unfortunately the CSS
		// spec for sticky doesn't support "stick to where I'm initially rendered" so we need to tell it.
		insetBlockStart: contentInsetBlockStart,
		// Because we are rendering this ontop of all content grid areas we set pointer events to none so
		// any content rendered inside the top bar remains interactive.
		pointerEvents: 'none',
		position: 'sticky',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
		zIndex: localSlotLayers.contentBlockBorder,
		'@media (min-width: 64rem)': {
			display: 'initial',
		},
	},
	largeViewportLeft: {
		boxSizing: 'border-box',
		borderBlock: `1px solid transparent`,
		borderInlineStart: `1px solid ${token('color.border')}`,
		borderStartStartRadius: '6px',
		display: 'none',
		gridArea: 'main',
		// This makes this grid item span all available columns so if aside or panel are mounted it spans to them.
		gridColumnEnd: -1,
		// Height is set so it takes up all of the available viewport space minus top bar + banner.
		height: contentHeightWhenFixed,
		// This sets the sticky point to be just below top bar + banner. It's needed to ensure the stick
		// point is exactly where this element is rendered to with no wiggle room. Unfortunately the CSS
		// spec for sticky doesn't support "stick to where I'm initially rendered" so we need to tell it.
		insetBlockStart: contentInsetBlockStart,
		// Because we are rendering this ontop of all content grid areas we set pointer events to none so
		// any content rendered inside the top bar remains interactive.
		pointerEvents: 'none',
		position: 'sticky',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
		zIndex: localSlotLayers.contentInlineBorder,
		'@media (min-width: 64rem)': {
			display: 'initial',
		},
	},
	curvedBorder: {
		borderStartStartRadius: '6px',
		borderInline: `1px solid transparent`,
	},
	smallViewportTop: {
		borderBlockEnd: `1px solid ${token('color.border')}`,
		// We are rendering the
		gridArea: 'top-bar',
		// This sets the sticky point to be just below banner. It's needed to ensure the stick
		// point is exactly where this element is rendered to with no wiggle room. Unfortunately the CSS
		// spec for sticky doesn't support "stick to where I'm initially rendered" so we need to tell it.
		insetBlockStart: `var(${bannerMountedVar}, 0px)`,
		// Because we are rendering this ontop of the top bar grid area we set pointer events to none so
		// any content rendered inside the top bar remains interactive.
		pointerEvents: 'none',
		position: 'sticky',
		// To keep this small viewport border visually aligned with the large viewport border we translate this down by 1px.
		transform: 'translateY(1px)',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
		zIndex: localSlotLayers.topBarBorder,
		'@media (min-width: 64rem)': {
			display: 'none',
		},
	},
});

/**
 * A root component of the navigation system. It wraps the undelying components with the necessary contexts allowing to use certain data and hooks
 * @param skipLinksLabel - The very first element of the layout is a skip links container that can be accessed by pressing Tab button and holds the links to the other sections of the layout thus improving accessibility. This parameter defines the header text for this container
 */
export function Root({
	children,
	xcss,
	UNSAFE_dangerouslyHoistSlotSizes = false,
	skipLinksLabel = 'Skip to:',
	testId,
}: {
	children: React.ReactNode;
	xcss?: StrictXCSSProp<'backgroundColor', never>;
	UNSAFE_dangerouslyHoistSlotSizes?: boolean;
	skipLinksLabel?: string;
	testId?: string;
}) {
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (process.env.NODE_ENV !== 'production') {
			const IGNORED_ELEMENTS = ['SCRIPT', 'STYLE'];

			if (ref.current) {
				Array.from(ref.current.children).forEach((child) => {
					if (
						!IGNORED_ELEMENTS.includes(child.tagName) &&
						!child.hasAttribute('data-layout-slot')
					) {
						// eslint-disable-next-line no-console
						console.error(
							`Page Layout Error

This element has been forcibly hidden:

`,
							child,
							`

An element was rendered as a child of the page layout root that isn't a page layout component! Resolve this error by moving it into a page layout component.

This message will not be displayed in production.
`,
						);
					}
				});
			}
		}
	}, []);

	return (
		<SideNavVisibilityProvider>
			<DangerouslyHoistSlotSizes.Provider value={UNSAFE_dangerouslyHoistSlotSizes}>
				<SkipLinksProvider>
					<SkipLinksContainer label={skipLinksLabel} />
					<div
						ref={ref}
						css={styles.root}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={xcss}
						id={gridRootId}
						data-testid={testId}
					>
						{children}
					</div>
				</SkipLinksProvider>
			</DangerouslyHoistSlotSizes.Provider>
		</SideNavVisibilityProvider>
	);
}

export function Banner({
	children,
	xcss,
	UNSAFE_height = 64,
	label = 'Banner',
	testId,
	id,
}: {
	children: React.ReactNode;
	xcss?: StrictXCSSProp<'backgroundColor', never>;
	/**
	 * Not intended for long term use. This is added to support the migration to the new page layout.
	 * We intend to replace this with a banner height prop on `<Root>`.
	 */
	UNSAFE_height?: number;
	label?: string;
	testId?: string;
	id?: string;
}) {
	const dangerouslyHoistSlotSizes = useContext(DangerouslyHoistSlotSizes);
	const UID = usePrefixedUID();
	const CID: string = id ? id : UID;
	useSkipLink(CID, label);

	return (
		<div
			id={CID}
			role="banner"
			data-layout-slot
			css={styles.banner}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={xcss}
			data-testid={testId}
		>
			<HoistCssVarToLocalGrid variableName={bannerMountedVar} value={UNSAFE_height} />
			{dangerouslyHoistSlotSizes && (
				// ------ START UNSAFE STYLES ------
				// These styles are only needed for the UNSAFE legacy use case for Jira + Confluence.
				// When they aren't needed anymore we can delete them wholesale.
				<DangerouslyHoistCssVarToDocumentRoot
					variableName={UNSAFE_bannerVar}
					value={UNSAFE_height}
				/>
				// ------ END UNSAFE STYLES ------
			)}
			{children}
		</div>
	);
}

export function TopBar({
	children,
	xcss,
	UNSAFE_height = 48,
	label = 'Top Bar',
	testId,
	id,
}: {
	children: React.ReactNode;
	xcss?: StrictXCSSProp<'backgroundColor', never>;
	/**
	 * Not intended for long term use. This is added to support the migration to the new page layout.
	 * We intend to replace this with a top bar height prop on `<Root>`.
	 */
	UNSAFE_height?: number;
	label?: string;
	testId?: string;
	id?: string;
}) {
	const dangerouslyHoistSlotSizes = useContext(DangerouslyHoistSlotSizes);
	const UID = usePrefixedUID();
	const CID: string = id ? id : UID;
	useSkipLink(CID, label);

	return (
		<Fragment>
			<header
				id={CID}
				data-layout-slot
				css={styles.topBar}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={xcss}
				data-testid={testId}
			>
				<HoistCssVarToLocalGrid variableName={topBarMountedVar} value={UNSAFE_height} />
				{dangerouslyHoistSlotSizes && (
					// ------ START UNSAFE STYLES ------
					// These styles are only needed for the UNSAFE legacy use case for Jira + Confluence.
					// When they aren't needed anymore we can delete them wholesale.
					<DangerouslyHoistCssVarToDocumentRoot
						variableName={UNSAFE_topBarVar}
						value={UNSAFE_height}
					/>
					// ------ END UNSAFE STYLES ------
				)}
				{children}
			</header>
			<div
				// CALL OUT: If this component is NOT mounted to a page then the border between
				// the top bar and main content will not be rendered.
				data-layout-slot
				css={contentBorderStyles.smallViewportTop}
			/>
		</Fragment>
	);
}

export function Panel({
	children,
	defaultWidth = 365,
	label = 'Panel',
	testId,
	id,
	xcss,
}: {
	children: React.ReactNode;
	defaultWidth?: number;
	label?: string;
	testId?: string;
	id?: string;
	xcss?: StrictXCSSProp<'backgroundColor' | 'padding' | 'paddingBlock' | 'paddingInline', never>;
}) {
	const dangerouslyHoistSlotSizes = useContext(DangerouslyHoistSlotSizes);
	const UID = usePrefixedUID();
	const CID: string = id ? id : UID;
	useSkipLink(CID, label);

	return (
		<section
			id={CID}
			data-layout-slot
			aria-label={label}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
			className={xcss}
			css={styles.panel}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
			style={{ [panelVar]: `${defaultWidth}px` } as CSSProperties}
			data-testid={testId}
		>
			{dangerouslyHoistSlotSizes && (
				// ------ START UNSAFE STYLES ------
				// These styles are only needed for the UNSAFE legacy use case for Jira + Confluence.
				// When they aren't needed anymore we can delete them wholesale.
				<DangerouslyHoistCssVarToDocumentRoot
					variableName={UNSAFE_panelLayoutVar}
					value="0px"
					mediaQuery={media.above.lg}
					responsiveValue={defaultWidth}
				/>
				// ------ END UNSAFE STYLES ------
			)}
			{children}
		</section>
	);
}

const MainStickyContext = createContext(false);

export function Main({
	children,
	isFixed,
	xcss,
	label = 'Main Content',
	testId,
	id,
}: {
	xcss?: StrictXCSSProp<'backgroundColor', never>;
	children: React.ReactNode;
	isFixed?: boolean;
	label?: string;
	testId?: string;
	id?: string;
}) {
	const UID = usePrefixedUID();
	const CID: string = id ? id : UID;
	const { visibleOnDesktop: isSideNavVisibleOnDesktop } = useSideNavVisibility();

	useSkipLink(CID, label);

	return (
		<Fragment>
			<div
				id={CID}
				data-layout-slot
				aria-label={label}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={xcss}
				role="main"
				css={[
					styles.main,
					isSideNavVisibleOnDesktop && styles.mainCurvedCorner,
					isFixed && styles.fixedContentArea,
				]}
				data-testid={testId}
			>
				<MainStickyContext.Provider value={Boolean(isFixed)}>{children}</MainStickyContext.Provider>
			</div>
			{isSideNavVisibleOnDesktop && (
				<div
					// CALL OUT: If this component is NOT mounted then the border around the
					// content area on large viewports will NOT be rendered.
					data-layout-slot
					css={contentBorderStyles.largeViewportLeft}
				/>
			)}
			<div
				data-layout-slot
				css={[
					contentBorderStyles.largeViewportTop,
					isSideNavVisibleOnDesktop && contentBorderStyles.curvedBorder,
				]}
			/>
		</Fragment>
	);
}

const stickyHeaderStyles = cssMap({
	root: {
		position: 'sticky',
	},
	stickyInMain: {
		insetBlockStart: 0,
	},
	stickyInBody: {
		insetBlockStart: contentInsetBlockStart,
	},
});

export function MainStickyHeader({
	children,
	xcss,
	testId,
}: {
	testId?: string;
	children?: React.ReactNode;
	xcss?: StrictXCSSProp<'backgroundColor' | 'padding' | 'paddingBlock' | 'paddingInline', never>;
}) {
	const isMainFixed = useContext(MainStickyContext);

	return (
		<div
			data-testid={testId}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
			className={xcss}
			css={[
				stickyHeaderStyles.root,
				isMainFixed && stickyHeaderStyles.stickyInMain,
				!isMainFixed && stickyHeaderStyles.stickyInBody,
			]}
		>
			{children}
		</div>
	);
}

export function SideNav({
	children,
	defaultCollapsed,
	defaultWidth = 320,
	testId,
	label = 'Side Navigation',
	collapseButton,
	onExpand,
	onCollapse,
	id,
}: {
	children: React.ReactNode;
	/**
	 * Whether the side nav should be collapsed by default __on desktop screens__.
	 *
	 * It is always collapsed by default for mobile screens.
	 *
	 * __Note:__ If using this prop, ensure that it is also provided to the `SideNavToggleButton`.
	 * This is to ensure the state is in sync before post-SSR hydration.
	 */
	defaultCollapsed?: boolean;
	defaultWidth?: number;
	testId?: string;
	label?: string;
	/**
	 * The slot for the side nav collapse button. This is used to toggle the visibility of the side nav.
	 */
	collapseButton?: React.ReactNode;
	/**
	 * Called when the side nav is expanded.
	 */
	onExpand?: VisibilityCallback;
	/**
	 * Called when the side nav is collapsed.
	 */
	onCollapse?: VisibilityCallback;
	id?: string;
}) {
	const UID = usePrefixedUID();
	const CID: string = id ? id : UID;
	useSkipLink(CID, label);
	const { visibleOnDesktop, visibleOnMobile, setVisibleOnDesktop } = useSideNavVisibility({
		defaultCollapsed,
	});
	const [width, setWidth] = useState(defaultWidth);
	const dangerouslyHoistSlotSizes = useContext(DangerouslyHoistSlotSizes);
	const panelSplitterParentRef = useRef<HTMLDivElement | null>(null);
	const sideNavVariableWidth = `clamp(${sideNavWidthResizeBounds.min}, ${width}px, ${sideNavWidthResizeBounds.max})`;
	const devTimeOnlyAttributes: Record<string, string | boolean> = {};

	// Sync the visibility in context with the local state value after SSR hydration, as it contains the default value from props
	useEffect(() => {
		setVisibleOnDesktop(visibleOnDesktop);
	}, [setVisibleOnDesktop, visibleOnDesktop]);

	useSideNavVisibilityCallbacks({
		onExpand,
		onCollapse,
		visibleOnDesktop,
		visibleOnMobile,
	});

	if (process.env.NODE_ENV !== 'production') {
		const visible: string[] = [];
		if (visibleOnMobile) {
			visible.push('small');
		}
		if (visibleOnDesktop) {
			visible.push('large');
		}
		devTimeOnlyAttributes['data-visible'] = visible.length ? visible.join(',') : 'false';
	}

	const toggleVisibility = useToggleSideNav();

	useEffect(() => {
		const mediaQueryList = window.matchMedia('(min-width: 64rem)');
		return bind(mediaQueryList, {
			type: 'change',
			listener() {
				if (mediaQueryList.matches) {
					// We're transitioning from tablet to desktop viewport size.
					// We forcibly show the side nav if it was shown on mobile.
					if (visibleOnMobile && !visibleOnDesktop) {
						toggleVisibility();
					}
				}
			},
		});
	}, [toggleVisibility, visibleOnDesktop, visibleOnMobile]);

	return (
		<nav
			id={CID}
			{...devTimeOnlyAttributes}
			data-layout-slot
			aria-label={label}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={
				{
					[sideNavVar]: sideNavVariableWidth,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				} as CSSProperties
			}
			ref={panelSplitterParentRef}
			css={[
				styles.sideNav,
				!visibleOnMobile && visibleOnDesktop && styles.sideNavHiddenMobileOnly,
				visibleOnMobile && !visibleOnDesktop && styles.sideNavHiddenDesktopOnly,
				!visibleOnMobile && !visibleOnDesktop && styles.sideNavHiddenMobileAndDesktop,
			]}
			data-testid={testId}
		>
			{dangerouslyHoistSlotSizes && (
				// ------ START UNSAFE STYLES ------
				// These styles are only needed for the UNSAFE legacy use case for Jira + Confluence.
				// When they aren't needed anymore we can delete them wholesale.
				<DangerouslyHoistCssVarToDocumentRoot
					variableName={UNSAFE_sideNavLayoutVar}
					value="0px"
					mediaQuery={media.above.md}
					responsiveValue={visibleOnDesktop ? sideNavVariableWidth : 0}
				/>
				// ------ END UNSAFE STYLES ------
			)}
			<PanelSplitterProvider
				panelRef={panelSplitterParentRef}
				panelWidth={width}
				onCompleteResize={setWidth}
				onClick={toggleVisibility}
				resizeBounds={sideNavWidthResizeBounds}
				resizingCssVar={panelSplitterResizingVar}
			>
				<InteractionSurface xcss={styles.sideNavInteractionSurface}>
					{collapseButton}
					{children}
				</InteractionSurface>
			</PanelSplitterProvider>
		</nav>
	);
}

export function Aside({
	children,
	xcss,
	isFixed,
	defaultWidth = 330,
	label = 'Aside',
	testId,
	id,
}: {
	children: React.ReactNode;
	isFixed?: boolean;
	xcss?: StrictXCSSProp<'backgroundColor' | 'padding' | 'paddingBlock' | 'paddingInline', never>;
	defaultWidth?: number;
	label?: string;
	id?: string;
	testId?: string;
}) {
	const dangerouslyHoistSlotSizes = useContext(DangerouslyHoistSlotSizes);
	const UID = usePrefixedUID();
	const CID: string = id ? id : UID;
	useSkipLink(CID, label);

	return (
		<aside
			id={CID}
			data-layout-slot
			aria-label={label}
			css={[styles.aside, isFixed && styles.fixedContentArea]}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={xcss}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
			style={{ [asideVar]: `${defaultWidth}px` } as CSSProperties}
			data-testid={testId}
		>
			{dangerouslyHoistSlotSizes && (
				// ------ START UNSAFE STYLES ------
				// These styles are only needed for the UNSAFE legacy use case for Jira + Confluence.
				// When they aren't needed anymore we can delete them wholesale.
				<DangerouslyHoistCssVarToDocumentRoot
					variableName={UNSAFE_asideLayoutVar}
					value="0px"
					mediaQuery={media.above.md}
					responsiveValue={defaultWidth}
				/>
				// ------ END UNSAFE STYLES ------
			)}
			{children}
		</aside>
	);
}
