import { ROUTE_NAMES } from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';
import type { Match, Route } from '@atlassian/jira-router';
import type { State as SpaState } from '../types';

// We are looking to calculate the flow of urls in case of LVP or board links.
// We do this by keeping track of the path to a rapid board url.The main reason for this function is the existence of the useStoredSettings url.
// If not for that, we would ignore the browse_project path and record the next transition, but we need to account for this extra path.
// During each of these flows, we look to dispatch a single reset action.
// During LVP (Last visited page), we dispatch on occurence of useStoredSettings. This is because by then the transition check has been kicked off.
// During board links also we dispatch on occurence of useStoredSettings.
// If the url loaded is one of the rapid board urls , then we directly dispatch the reset.
export const calcRapidBoardResetState = (
	boardPaths: string[],
	routeName: string | undefined,
	useStoredSettings: boolean,
): [string[], boolean] => {
	let rapidBoardPaths: string[] = [...boardPaths];
	let resetDone = false;
	switch (routeName) {
		case ROUTE_NAMES.BROWSE_PROJECT_REDIRECT: {
			rapidBoardPaths = [ROUTE_NAMES.BROWSE_PROJECT_REDIRECT];
			resetDone = true;
			break;
		}
		case ROUTE_NAMES.RAPIDBOARD_BOARD:
		case ROUTE_NAMES.RAPIDBOARD_BACKLOG:
		case ROUTE_NAMES.RAPIDBOARD_REPORT: {
			if (useStoredSettings) {
				rapidBoardPaths = [...rapidBoardPaths, 'useStoredSettings'];
				resetDone = false;
				break;
			}
			if (
				(!useStoredSettings &&
					rapidBoardPaths.indexOf(ROUTE_NAMES.BROWSE_PROJECT_REDIRECT) > -1 &&
					rapidBoardPaths.indexOf('useStoredSettings') > -1) ||
				rapidBoardPaths.indexOf('useStoredSettings') > -1
			) {
				resetDone = true;
			}
			rapidBoardPaths = [];
			break;
		}
		default: {
			resetDone = false;
		}
	}
	return [rapidBoardPaths, resetDone];
};
/**
 * Prepares a match object specifically for Apdex score calculations by sanitizing its parameters and queries.
 * It selectively ignores parameters, adjusts specific query values, and ensures the object's final form is suitable
 * for accurate Apdex measurement. This includes handling edge cases like specific 'view' query adjustments.
 */
export const getApdexMatchObject = (match: Match, route: Route) => {
	let cleanMatch = {
		...match,
		params: {
			...match.params,
		},
		query: {
			...match.query,
		},
	};
	if (route.apdexIgnoreParams && route.apdexIgnoreParams.length > 0) {
		route.apdexIgnoreParams.forEach((apdexIgnoreParam) => {
			const { params, query } = match;
			const paramKeys = params && Object.keys(params);
			const queryKeys = query && Object.keys(query);
			const isIgnoreInParams =
				paramKeys && paramKeys.length > 0 && paramKeys.indexOf(apdexIgnoreParam) >= 0;
			const isIgnoreInQuery =
				queryKeys && queryKeys.length > 0 && queryKeys.indexOf(apdexIgnoreParam) >= 0;
			if (isIgnoreInParams) {
				cleanMatch.params[apdexIgnoreParam] = apdexIgnoreParam;
			}
			if (isIgnoreInQuery) {
				cleanMatch.query[apdexIgnoreParam] = apdexIgnoreParam;
			}
		});
	}
	if (match.query && match.query.view && match.query.view === 'detail') {
		// @ts-expect-error - TS2322 - Type 'undefined' is not assignable to type 'string'.
		cleanMatch.query.view = undefined;
	}
	if (match.query && match.query.view && match.query.view === 'planning.nodetail') {
		cleanMatch.query.view = 'planning';
	}
	cleanMatch = {
		...cleanMatch,
		url: '',
	};
	return cleanMatch;
};
/**
 * Determines the new navigation state of a rapid board by evaluating the current route and user settings.
 * It decides whether to perform a reset of the board's path array, facilitating navigation control within the application.
 * The function's outcome guides the application on how to update the user's view effectively.
 */
export const calcRapidBoardResetStateV2 = (
	boardPaths: string[],
	routeName?: string,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	useStoredSettings: boolean,
): [string[], boolean] => {
	let rapidBoardPaths: string[] = [...boardPaths];
	let resetDone = false;
	switch (routeName) {
		case ROUTE_NAMES.BROWSE_PROJECT_REDIRECT: {
			rapidBoardPaths = [];
			resetDone = true;
			break;
		}
		case ROUTE_NAMES.RAPIDBOARD_BACKLOG:
		case ROUTE_NAMES.RAPIDBOARD_BOARD:
		case ROUTE_NAMES.RAPIDBOARD_REPORT: {
			// use search to navigate to board
			if (
				rapidBoardPaths.includes(ROUTE_NAMES.BROWSE_PROJECT_REDIRECT) ||
				rapidBoardPaths.includes('useStoredSettings')
			) {
				resetDone = true;
				rapidBoardPaths = [];
				break;
			} else if (useStoredSettings) {
				rapidBoardPaths = [...rapidBoardPaths, 'useStoredSettings'];
				break;
			} else {
				rapidBoardPaths = [];
				break;
			}
		}
		default: {
			if (rapidBoardPaths.includes(ROUTE_NAMES.BROWSE_PROJECT_REDIRECT)) {
				resetDone = true;
				rapidBoardPaths = [];
			}
		}
	}
	return [rapidBoardPaths, resetDone];
};
/**
 * Checks if a given app ID is present in the current page state or within a specified
 * depth of visited pages.
 */
export const hasAppInState = (appId: string, state: SpaState | null, searchDepth = 0) => {
	if (state) {
		const { currentPage, visitedPages } = state;
		const visitedPageLength = visitedPages.length;
		if (currentPage && currentPage.apps[appId]) {
			return true;
		}
		if (visitedPageLength === 0 || searchDepth <= 0) {
			return false;
		}
		for (let i = 1; i <= Math.min(searchDepth, visitedPageLength); i += 1) {
			if (visitedPages[visitedPageLength - i].apps[appId]) {
				return true;
			}
		}
	}
	return false;
};
/**
 * Validates if a specified route is among a set of predefined routes considered valid for navigation.
 * This check promotes consistent navigation flow and enhances application security by preventing unintended route access.
 */
export const isValidRoute = (routeToCheck: Route) => {
	switch (routeToCheck.name) {
		case ROUTE_NAMES.ISSUE:
		case ROUTE_NAMES.ISSUE_EMBED:
		case ROUTE_NAMES.PROJECT_ISSUE_NAVIGATOR:
		case ROUTE_NAMES.PROJECT_ISSUE_NAVIGATOR_CLASSIC:
		case ROUTE_NAMES.PROJECT_ISSUE_NAVIGATOR_CORE:
		case ROUTE_NAMES.SERVICEDESK_ISSUES:
			return true;
		default:
			return false;
	}
};
