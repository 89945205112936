import { useEffect, useState } from 'react';
import type {
	AnyEntryPoint,
	EntryPointReferenceSubject,
	ReferenceFromEntryPoint,
} from '@atlassian/jira-entry-point';

export const useEntryPointSubscription = <TEntryPoint extends AnyEntryPoint>(
	subject: EntryPointReferenceSubject<TEntryPoint>,
): ReferenceFromEntryPoint<TEntryPoint> => {
	const [entryPointReference, setEntryPointReference] = useState<
		ReferenceFromEntryPoint<TEntryPoint>
	>(subject.getValue);

	useEffect(() => {
		const unsubscribe = subject.subscribe(setEntryPointReference);
		setEntryPointReference(subject.getValue());

		return unsubscribe;
	}, [subject]);

	return entryPointReference;
};
