import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context';
import { slaMetricsDataResource } from '@atlassian/jira-router-resources-service-desk-sla-settings';
import { servicedeskSlaSettingsRoute } from '@atlassian/jira-router-routes-project-settings-service-desk-sla-settings-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import { APP_NAMES, toPackageName } from '@atlassian/jira-servicedesk-common/src/utils/app-names';
import { getSettingsNavigationResources, SERVICE_DESK_PRODUCT } from './ui/product-routes/common';
import {
	ServiceDeskSlaSettings,
	LazyProjectSettingsServiceDeskSlaSettings,
} from './ui/service-desk-sla-settings';

export { ServiceDeskSlaSettings };

export const slaSettingsRouteEntries: Route[] = [
	createEntry(servicedeskSlaSettingsRoute, {
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},
		meta: {
			reporting: {
				id: APP_NAMES.SLA_SETTINGS,
				packageName: toPackageName(APP_NAMES.SLA_SETTINGS),
				teamName: 'bumblebee',
			},
		},
		perfMetricKey: 'jsm-sla-settings',
		component: ServiceDeskSlaSettings,
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			projectContextResource,
			slaMetricsDataResource,
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskSlaSettings],
	}),
];
