import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation';
import { confluenceProductSettingsRoute } from '@atlassian/jira-router-routes-confluence-product-settings';
import type ConfluenceProductSettingsType from '@atlassian/jira-spa-apps-confluence-product-settings';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

const LazyConfluenceProductSettingsPage = lazyForPaint<typeof ConfluenceProductSettingsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-confluence-product-settings", jiraSpaEntry: "async-confluence-product-settings" */ '@atlassian/jira-spa-apps-confluence-product-settings'
		),
	),
);

export const confluenceProductSettingsComponent = () => (
	<LazyPage
		Page={LazyConfluenceProductSettingsPage}
		pageId="async-confluence-product-settings"
		shouldShowSpinner
	/>
);

export const confluenceProductSettingsRouteEntry = createEntry(confluenceProductSettingsRoute, {
	component: confluenceProductSettingsComponent,
	layout: globalSettingsLayout,
	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
	},
	resources: [...getNavigationResources(), ...getNavigationSidebarProductsSettingResource()],
	forPaint: [LazyAtlassianNavigation, LazyConfluenceProductSettingsPage],
});
