import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const globalSettingsContactAdministratorRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.GLOBAL_SETTINGS_CONTACT_ADMINISTRATOR,
	path: '/jira/contact-administrator',
};
