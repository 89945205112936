import React from 'react';
import { qs } from 'url-parse';
import { FireOperationalAnalytics as FireOperationalAnalyticsDI } from '@atlassian/jira-product-analytics-bridge';
import {
	Redirect as RedirectDI,
	useResource as useResourceDI,
	useRouter as useRouterDI,
} from '@atlassian/jira-router';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context';
import type { ProjectKeyRedirectProps } from './types';

/**
 * Ensures users are redirected to the correct project URL when there's a mismatch in the project key.
 * It preserves query parameters during redirection and logs the event for analytics.
 */
const ProjectKeyRedirect = ({
	FireOperationalAnalytics = FireOperationalAnalyticsDI,
	Redirect = RedirectDI,
	useResource = useResourceDI,
	useRouter = useRouterDI,
}: ProjectKeyRedirectProps) => {
	const [
		{
			location,
			match: { params, url },
			query,
		},
	] = useRouter();
	const projectKey = params.projectKey ?? query.projectKey;
	const { data: projectContext } = useResource(projectContextResource);
	if (!projectKey || !projectContext?.projectKey) {
		return null;
	}
	if (projectKey === projectContext.projectKey) {
		return null;
	}
	const search = {
		...query,
		...(query.projectKey
			? {
					projectKey: projectContext.projectKey,
				}
			: {}),
	};

	// @ts-expect-error - TS2554 - Expected 1 arguments, but got 2.
	const queryString = qs.stringify(search, Object.entries(search).length > 0);

	// Just a slight risk mitigation - only do the string replace if the match param is there
	const path = params.projectKey != null ? url.replace(projectKey, projectContext.projectKey) : url;
	const to = `${path}${queryString}`;
	return (
		<>
			<FireOperationalAnalytics
				eventName="ProjectKeyRedirect loaded"
				componentName="ProjectKeyRedirect"
				attributes={{
					location,
					projectContext,
				}}
			/>
			<Redirect to={to} />
		</>
	);
};
export default ProjectKeyRedirect;
