import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type JiraBusinessShortcutAppType from '@atlassian/jira-spa-apps-business-shortcut';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyShortcutsView = lazyForPaint<typeof JiraBusinessShortcutAppType>(
	() =>
		import(
			/* webpackChunkName: "async-business-shortcut", jiraSpaEntry: "async-business-shortcut" */ '@atlassian/jira-spa-apps-business-shortcut'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazyShortcutsView} pageId="async-business-shortcuts" shouldShowSpinner />
);
