import {
	type BoundActions,
	createStore,
	createHook,
	createSubscriber,
	type Action,
	type SubscriberComponent,
} from 'react-sweet-state';

type State = {
	isActive: boolean;
};

const initialState: State = {
	isActive: false,
};

const openAddIssueTypeModal =
	(): Action<State> =>
	({ setState }) => {
		setState({ isActive: true });
	};

const closeAddIssueTypeModal =
	(): Action<State> =>
	({ setState }) => {
		setState({ isActive: false });
	};

const actions = {
	openAddIssueTypeModal,
	closeAddIssueTypeModal,
} as const;

const STORE_NAME = 'jira-project-settings-apps-add-issue-type-context';

const Store = createStore<State, typeof actions>({
	name: STORE_NAME,
	initialState,
	actions,
});

export const useAddIssueTypeContext = createHook(Store);

export const AddIssueTypeContextSubscriber: SubscriberComponent<State, typeof actions> =
	createSubscriber(Store);

// instead of utilisling defaultRegistry, we will grab it from the render tree to have this singleton
// call useStoreReference in render tree before accessing the store instance
// https://github.com/atlassian/react-sweet-state/issues/139#issuecomment-890439621
const storeInstance: {
	state: State;
	actions: BoundActions<State, typeof actions> | null;
} = { state: initialState, actions: null };

// ensure this gets called to update the store instance on render tree
export const useAddIssueTypeContextUpdateStoreReference = () => {
	const [issueTypeContextState, issueTypeContextActions] = useAddIssueTypeContext();
	storeInstance.state = issueTypeContextState;
	storeInstance.actions = issueTypeContextActions;
	return null;
};

export const addIssueTypeModalActions = () => storeInstance.actions;
