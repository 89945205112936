import {
	createRouterContext,
	type RouteResource,
	useResource,
	type Route,
	type UseResourceHookResponse,
} from '@atlassian/jira-router';
import type { RouteParamsWithProjectKey, UseResourceCustomContext } from '../types';
import { useInitFetchResource } from '../use-init-fetch-resource';

/**
 * DUMMY_ROUTE needs to be used by default, because `route` must not be undefined, it is required/used internally by RRR, e.g. in findRouterContext() on createRouterContext() call.
 * If `route` is undefined, those will throw runtime exceptions.
 * `project-sidebar-navigation-resource/software-cmp-nav-synchronizer/index.tsx` uses dummy route as well.
 *
 * Jfyi, createProjectRouteRouterContext() function in JSW uses dummy route, with :projectKey in its path (that resource fetch function uses)
 */
const DUMMY_ROUTE: Route = {
	name: 'DUMMY_ROUTE_NAME',
	path: '/projects/:projectKey/dummyroute',
};

/**
 * Reusable hook, to pass *custom context* to the React router resource
 */
export const useResourceWithCustomRouterContext = <
	RouteResourceData,
	RouteParams = RouteParamsWithProjectKey,
>(
	resource: RouteResource<RouteResourceData>,
	customRouterContext?: UseResourceCustomContext<RouteParams>,
): UseResourceHookResponse<RouteResourceData> => {
	let useResourceArgCustomContext;
	if (customRouterContext) {
		// NOTE: `route` must include /.../:paramKey/... in its url for all `matchParams` passed to the `createRouterContext`, otherwise they will be ignored. createRouterContext will throw an error if route is undefined: `route.path` is used in the library code at least.
		const { route = DUMMY_ROUTE, matchParams } = customRouterContext;
		if (matchParams && Object.keys(matchParams).length > 0) {
			useResourceArgCustomContext = {
				routerContext: createRouterContext(route, { params: matchParams }),
			};
		}
	}

	const resourceResponse = useResource<RouteResourceData>(resource, useResourceArgCustomContext);
	// we must call "fetch data" / refresh() manually, because react-resource-router will not fetch resources which are not defined in the current route's required resources.
	useInitFetchResource(resourceResponse, !customRouterContext);

	return resourceResponse;
};
