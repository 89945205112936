import { ROUTE_NAMES } from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';
import type { RouterContext } from '@atlassian/jira-router';
import { MENU_ID } from '../../../constants';
import type { SelectedPath } from '../../../types';

const routes = new Set<string>([ROUTE_NAMES.YOUR_WORK, ROUTE_NAMES.PEOPLE_INDEX]);

function convert(routeContext: RouterContext): SelectedPath {
	const routeName = routeContext.route.name;
	const path: SelectedPath = [];

	switch (routeName) {
		case ROUTE_NAMES.YOUR_WORK:
			path.push(MENU_ID.YOUR_WORK);
			break;

		case ROUTE_NAMES.PEOPLE_INDEX:
			path.push(MENU_ID.TEAMS);
			break;
		default:
	}

	return path;
}

export const topLevelConverter = { routes, convert };
