import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type IssueTypeSchemesDirectory from '@atlassian/jira-spa-apps-admin-pages-issue-type-schemes-directory';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyIssueTypeSchemesDirectory = lazyForPaint<typeof IssueTypeSchemesDirectory>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-admin-pages-issue-type-schemes-directory", jiraSpaEntry: "async-admin-pages-issue-type-schemes-directory" */ '@atlassian/jira-spa-apps-admin-pages-issue-type-schemes-directory'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyIssueTypeSchemesDirectory}
		pageId="jira-admin-pages-issue-type-schemes-directory"
		shouldShowSpinner
	/>
);
