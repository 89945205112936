import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type JiraBusinessTimelineAppType from '@atlassian/jira-spa-apps-business-timeline';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyTimelineView = lazyForPaint<typeof JiraBusinessTimelineAppType>(
	() =>
		import(
			/* webpackChunkName: "async-business-timeline", jiraSpaEntry: "async-business-timeline" */ '@atlassian/jira-spa-apps-business-timeline'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazyTimelineView} pageId="async-business-timeline" shouldShowSpinner />
);
