import { fg } from '@atlassian/jira-feature-gating';
import { performGetRequest } from '@atlassian/jira-fetch';
import type { SidebarType, ProjectSidebarAddonNavItem } from './types';

export const fetchProjectSidebarConnectNavigationInfo = (
	projectKeyOrId: string,
	sidebarType: SidebarType,
): Promise<ProjectSidebarAddonNavItem[]> => {
	if (projectKeyOrId == null) {
		throw new Error('invalid identifier specified');
	}

	if (fg('blu-3702_-_legacy_connect_nav_item_filtering')) {
		return performGetRequest(
			`/rest/navigation/4/sidebar/project/${projectKeyOrId}/addons?type=${sidebarType}&excludeLegacy=true`,
		);
	}

	return performGetRequest(
		`/rest/navigation/4/sidebar/project/${projectKeyOrId}/addons?type=${sidebarType}`,
	);
};
