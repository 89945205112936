import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarEocTeamDashboard, {
	LazyTeamDashboardSidebar,
} from '@atlassian/jira-navigation-apps-sidebar-eoc-team-dashboard/src/async';
import {
	AsyncHorizontalOperationsNav,
	LazyHorizontalOperationsNav,
} from '@atlassian/jira-operations-horizontal-nav';
import { opsTeamLayout } from '@atlassian/jira-ops-team-layout';
import { createEntry } from '@atlassian/jira-route-entry';
import { resources as onCallResources } from '@atlassian/jira-router-resources-eoc-on-call';
import { resources as teamDashboardResources } from '@atlassian/jira-router-resources-eoc-team-dashboard';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import {
	routeNamesEocOnCallRedirectRoute,
	eocOnCallRoute,
} from '@atlassian/jira-router-routes-eoc-on-call-routes';
import OnCall, { LazyOnCall, OnCallRedirect } from './ui/spa/eoc/on-call';

export const eocOnCallRouteEntry = createEntry(routeNamesEocOnCallRedirectRoute, {
	component: OnCallRedirect,
	layout: opsTeamLayout,
	resources: [...getNavigationResources(), ...teamDashboardResources],
	navigation: {
		menuId: MENU_ID.PEOPLE,
		sidebar: AsyncSidebarEocTeamDashboard,
	},
	forPaint: [LazyAtlassianNavigation, LazyTeamDashboardSidebar],
	isRedirect: true,
});

export const eocOnCall1RouteEntry = createEntry(eocOnCallRoute, {
	component: OnCall,
	layout: opsTeamLayout,
	resources: [
		...getNavigationResources(),
		...teamDashboardResources,
		...onCallResources,
		getConsolidationStateResource(),
	],
	navigation: {
		menuId: MENU_ID.PEOPLE,
		sidebar: AsyncSidebarEocTeamDashboard,
		horizontal: AsyncHorizontalOperationsNav,
		horizontalFeatureFlag: 'alx-8775-operations-horizontal-nav_fr1tg',
	},
	forPaint: [
		LazyHorizontalOperationsNav,
		LazyAtlassianNavigation,
		LazyOnCall,
		LazyTeamDashboardSidebar,
	],
});
