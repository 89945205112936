import queryString from 'query-string';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { performGetRequest } from '@atlassian/jira-fetch';
import { PACKAGE_NAME } from '../../../constants';
import type { Page, IssueTypeScheme } from '../../../types';
import { toApiUrlTransformer, fromUrlTransformer } from '../../../utils/transformers';

export const getIssueTypeSchemes = async (qs: string): Promise<Page<IssueTypeScheme>> => {
	const filter = fromUrlTransformer(queryString.parse(qs));
	const query = queryString.stringify(toApiUrlTransformer(filter));

	try {
		return await performGetRequest(`/rest/api/3/issuetypescheme?${query}`);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			error,
			meta: {
				id: 'getIssueTypesRequest',
				packageName: PACKAGE_NAME,
				teamName: 'morphinecake',
			},
			sendToPrivacyUnsafeSplunk: true,
		});

		throw error;
	}
};
