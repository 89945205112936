// eslint-disable-next-line @typescript-eslint/no-unused-vars -- PLEASE FIX - ENABLING FLAT LINT CONFIG
import { ff } from '@atlassian/jira-feature-flagging';
import { createResource, useResource } from '@atlassian/jira-router';
// eslint-disable-next-line @typescript-eslint/no-unused-vars -- PLEASE FIX - ENABLING FLAT LINT CONFIG
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils';
import { fetchStorageDetails } from './services/storage-details';
import type { StorageDetailsResource } from './types';

const RESOURCE_TYPE_KEY = 'RESOURCE_TYPE_BUSINESS_INSTANCE_STORAGE';

export const storageDetailsResource = createResource<StorageDetailsResource>({
	type: RESOURCE_TYPE_KEY,
	getKey: () => RESOURCE_TYPE_KEY,
	getData: async (_, { tenantContext: { appEditions } }) => fetchStorageDetails(appEditions),
	maxAge: Infinity,
	isBrowserOnly: true, // not loaded from SSR to not delay TTI
});

export const useStorageDetails = () => useResource<StorageDetailsResource>(storageDetailsResource);
