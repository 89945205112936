// eoc-fetch is already being used in eoc resources, so there is no reason not
// to override the whitelist here.

// eslint-disable-next-line jira/import-whitelist
import { fetch } from '@atlassian/eoc-fetch';
// eslint-disable-next-line jira/import-whitelist
import { checkIsTransitioning } from '@atlassian/jira-eoc-tenant-info';
import { ff } from '@atlassian/jira-feature-flagging-using-meta';
import { createResource } from '@atlassian/jira-router';
import {
	CONSOLIDATION_STATE_RESOURCE_TYPE,
	type TenantInfo,
} from '@atlassian/jira-router-resources-eoc-tenant-info';
import { CHECK_API_KEY_RESOURCE_TYPE } from './constants';
import type { ApiCheckResponse } from './types';

const fetchCheckApiKeyManagementKeys = async (
	accountId: string | null,
	isSiteAdmin: boolean,
	isTransitioning: boolean,
	appPermissions?: {
		hasServiceDeskAccess: boolean;
	},
): Promise<boolean> => {
	if (
		!ff('eoc-api-key-management-feature-jira_8ox4z') ||
		!isTransitioning ||
		!isSiteAdmin ||
		(appPermissions && appPermissions.hasServiceDeskAccess === false)
	) {
		return Promise.resolve(false);
	}

	return fetch<ApiCheckResponse>(`/api-keys/web/check?accountId=${accountId}`).then(
		({ data }) => data,
	);
};

export const checkApiKeyManagementKeysResource = () =>
	createResource<boolean>({
		type: CHECK_API_KEY_RESOURCE_TYPE,
		getKey: () => 'check-api-key-management-keys',
		getData: async (
			{ dependencies },
			{ tenantContext: { atlassianAccountId, appPermissions, isSiteAdmin } },
		) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const tenantInfo = (await dependencies[CONSOLIDATION_STATE_RESOURCE_TYPE]
				?.promise) as TenantInfo;
			const isTransitioning = checkIsTransitioning(tenantInfo);

			return fetchCheckApiKeyManagementKeys(
				atlassianAccountId,
				isSiteAdmin,
				isTransitioning,
				appPermissions,
			);
		},
		maxAge: Infinity,
		isBrowserOnly: true,
		depends: [CONSOLIDATION_STATE_RESOURCE_TYPE],
	});
