import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from '@atlassian/jira-router';
import type { SidebarNav4ContextType } from '../../common/types';
import { getInitialSelectedPath } from '../../common/utils/getInitialSelectedPath';
import { isSelected } from '../../common/utils/isSelected';
import { routeToSelectedPath } from '../../common/utils/routeToSelectedPath';

/**
 * This context is designed to allow the correct menu item to display during
 * SSR and when navigating.
 *
 * The context is used by the JiraExpandableMenuItem and JiraMenuLink components.
 */
const SidebarNav4Context = createContext<SidebarNav4ContextType | null>(null);

type Props = {
	children: React.ReactNode;
	isInitialSelectedPath?(menuId: string): boolean; // By passing a function, we have the ability to make ALL routes initially selected, or none, or just one.
};

export function SidebarNav4ContextProvider(props: Props) {
	const [routerContext] = useRouter();
	const { children, isInitialSelectedPath: isInitialSelectedPathFn } = props;
	const initialSelectedPath = getInitialSelectedPath(routerContext);
	const [selectedPath, setSelectedPath] = useState(initialSelectedPath);

	// When the routerContext changes, we need to recalculate the selected path
	useEffect(() => {
		setSelectedPath((prev) => {
			const newPath = routeToSelectedPath(routerContext);
			return prev.toString() === newPath.toString() ? prev : newPath;
		});
	}, [routerContext]);

	const getSelectedPath = useCallback(() => [...selectedPath], [selectedPath]);

	const isSelectedCallback = useCallback(
		(menuId: string) => isSelected(selectedPath, menuId),
		[selectedPath],
	);
	const isInitialSelectedPathCallback = useCallback(
		(menuId: string) =>
			isInitialSelectedPathFn
				? isInitialSelectedPathFn(menuId)
				: isSelected(initialSelectedPath, menuId),
		[isInitialSelectedPathFn, initialSelectedPath],
	);

	const sidebarNav4Controller = useMemo(
		() => ({
			getSelectedPath,
			isInitialSelectedPath: isInitialSelectedPathCallback,
			isSelected: isSelectedCallback,
		}),
		[getSelectedPath, isInitialSelectedPathCallback, isSelectedCallback],
	);

	return (
		<SidebarNav4Context.Provider value={sidebarNav4Controller}>
			{children}
		</SidebarNav4Context.Provider>
	);
}

export function useSidebarNav4() {
	const context = useContext(SidebarNav4Context);

	if (context == null) {
		throw new Error('useSidebarNav4 must be used inside a SidebarNav4ContextProvider');
	}

	return context;
}
