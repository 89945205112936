import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { chromelessLayout } from '@atlassian/jira-chromeless-layout';
import { createEntry } from '@atlassian/jira-route-entry';
import { loginEmbedRoute } from '@atlassian/jira-router-routes-login-embed-routes';
import type LoginEmbedType from '@atlassian/jira-spa-apps-login-embed';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyLoginEmbed = lazyForPaint<typeof LoginEmbedType>(
	() =>
		import(
			/* webpackChunkName: "async-login-embed", jiraSpaEntry: "async-login-embed" */ '@atlassian/jira-spa-apps-login-embed'
		),
);

export const LoginEmbed = () => (
	<LazyPage Page={LazyLoginEmbed} pageId="spa-apps/login-embed" shouldShowSpinner />
);

export const loginEmbedRouteEntry = createEntry(loginEmbedRoute, {
	component: LoginEmbed,
	layout: chromelessLayout,
});
