import { ROUTE_NAMES } from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';
import type { RouterContext } from '@atlassian/jira-router';
import { ENTITY_ID, MENU_ID } from '../../../constants';
import type { SelectedPath } from '../../../types';

const routes = new Set<string>([
	ROUTE_NAMES.FILTERS_DIRECTORY,
	// ROUTE_NAMES.FILTERS_DIRECTORY_SECURE // No route definition in SPA
	ROUTE_NAMES.GLOBAL_ISSUE_NAVIGATOR,
	ROUTE_NAMES.GLOBAL_ISSUE_NAVIGATOR_BROWSE_ISSUE_REDIRECT,
	ROUTE_NAMES.ISSUE,
	// ROUTE_NAMES.ISSUE_CREATE_EMBED, // Full screen, no sidebar
	// ROUTE_NAMES.ISSUE_EMBED, // Full screen, no sidebar
	ROUTE_NAMES.LEGACY_GIN, // Same as ROUTE_NAMES.ISSUE but without filter querystring
	ROUTE_NAMES.LEGACY_GIN_ISSUE,
	ROUTE_NAMES.LEGACY_GIN_SUBSCRIPTIONS,
	// ROUTE_NAMES.LEGACY_GIN_QUICKSEARCH, // Redirects to ROUTE_NAMES.ISSUE
	// ROUTE_NAMES.LEGACY_ISSUE, // No route defined!
]);

function convert(routeContext: RouterContext): SelectedPath {
	const routeName = routeContext.route.name;
	const { query, match } = routeContext;
	const path: SelectedPath = [MENU_ID.FILTERS];

	switch (routeName) {
		case ROUTE_NAMES.FILTERS_DIRECTORY:
			path.push(MENU_ID.FILTERS_VIEW_ALL);
			break;

		case ROUTE_NAMES.ISSUE: {
			const filterId = query.filter || query.filterId;

			if (filterId) {
				path.push(ENTITY_ID.FILTER(filterId));
			} else if (match.params?.issueKey) {
				const [projectKey] = (match.params?.issueKey || '').split('-'); // For this route to match, the issueKey will never be blank
				return [MENU_ID.PROJECTS, ENTITY_ID.PROJECT(projectKey)];
			}
			break;
		}
		// case ROUTE_NAMES.GLOBAL_ISSUE_NAVIGATOR:
		// case ROUTE_NAMES.GLOBAL_ISSUE_NAVIGATOR_BROWSE_ISSUE_REDIRECT:
		// case ROUTE_NAMES.LEGACY_GIN:
		// case ROUTE_NAMES.LEGACY_GIN_ISSUE:
		// case ROUTE_NAMES.LEGACY_GIN_SUBSCRIPTIONS:

		default: {
			const filterId = query.filter || query.filterId;

			// Search filters URL
			if (filterId === undefined) {
				path.push(MENU_ID.FILTERS_SEARCH);
			} else {
				// The rest of the URLs are using a filter ID.
				// Test this case: https://buglow-jsw.jira-dev.com/secure/ViewSubscriptions.jspa?filterId=10007
				path.push(ENTITY_ID.FILTER(filterId));
			}

			break;
		}
	}

	return path;
}

export const filterConverter = { routes, convert };
