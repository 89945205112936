import React from 'react';
import { lazy } from 'react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import Placeholder from '@atlassian/jira-placeholder';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyJsmCustomDomainChangeBoardingFlag = lazy(
	() => import(/* webpackChunkName: "async-jsm-custom-domain-change-boarding-flag" */ './index'),
);

export const AsyncJsmCustomDomainChangeBoardingFlag = () => (
	<JSErrorBoundary
		id="async-jsm-custom-domain-change-boarding-flag"
		packageName="jira-help-center-custom-domain-change-boarding"
	>
		<Placeholder name="jsm-custom-domain-change-boarding-flag" fallback={null}>
			<LazyJsmCustomDomainChangeBoardingFlag />
		</Placeholder>
	</JSErrorBoundary>
);
