/**
 * @generated SignedSource<<e3eab9dc48e78232b58ca36ccbd7e2bf>>
 * @relayHash fdc5592049c0ea96909080c227e87701
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f9db9d32de25d47d462e6f5f49764018b72cec5ae15d29fa80d8e306fb084cb4

import type { ConcreteRequest, Query } from 'relay-runtime';
export type transformToVariables_filterResultFilterQuery$variables = {
  cloudId: string;
  fieldSetIds: ReadonlyArray<string>;
  filterAri: string;
};
export type transformToVariables_filterResultFilterQuery$data = {
  readonly jira: {
    readonly fieldSetsById: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly aliases: ReadonlyArray<string> | null | undefined;
          readonly fieldSetId: string;
          readonly isSortable: boolean | null | undefined;
          readonly jqlTerm: string;
        };
      } | null | undefined>;
    };
    readonly filter: {
      readonly jql: string;
    };
  };
};
export type transformToVariables_filterResultFilterQuery = {
  response: transformToVariables_filterResultFilterQuery$data;
  variables: transformToVariables_filterResultFilterQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterAri"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "filterAri"
  }
],
v4 = {
  "kind": "ScalarField",
  "name": "jql"
},
v5 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "fieldSetIds",
    "variableName": "fieldSetIds"
  }
],
v6 = {
  "kind": "ScalarField",
  "name": "fieldSetId"
},
v7 = {
  "kind": "ScalarField",
  "name": "jqlTerm"
},
v8 = {
  "kind": "ScalarField",
  "name": "aliases"
},
v9 = {
  "kind": "ScalarField",
  "name": "isSortable"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "transformToVariables_filterResultFilterQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v3/*: any*/),
                "kind": "LinkedField",
                "name": "filter",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ]
              },
              "action": "THROW",
              "path": "jira.filter"
            },
            {
              "kind": "RequiredField",
              "field": {
                "args": (v5/*: any*/),
                "concreteType": "JiraIssueSearchFieldSetConnection",
                "kind": "LinkedField",
                "name": "fieldSetsById",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "JiraIssueSearchFieldSetEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "JiraIssueSearchFieldSet",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v6/*: any*/),
                                "action": "THROW",
                                "path": "jira.fieldSetsById.edges.node.fieldSetId"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v7/*: any*/),
                                "action": "THROW",
                                "path": "jira.fieldSetsById.edges.node.jqlTerm"
                              },
                              (v8/*: any*/),
                              (v9/*: any*/)
                            ]
                          },
                          "action": "THROW",
                          "path": "jira.fieldSetsById.edges.node"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "jira.fieldSetsById.edges"
                  }
                ]
              },
              "action": "THROW",
              "path": "jira.fieldSetsById"
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "transformToVariables_filterResultFilterQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "kind": "LinkedField",
            "name": "filter",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "__typename"
              },
              (v4/*: any*/),
              {
                "kind": "ScalarField",
                "name": "id"
              }
            ]
          },
          {
            "args": (v5/*: any*/),
            "concreteType": "JiraIssueSearchFieldSetConnection",
            "kind": "LinkedField",
            "name": "fieldSetsById",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueSearchFieldSetEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraIssueSearchFieldSet",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "f9db9d32de25d47d462e6f5f49764018b72cec5ae15d29fa80d8e306fb084cb4",
    "metadata": {},
    "name": "transformToVariables_filterResultFilterQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "e82055db84a076837d07832e0e60c025";

export default node;
