import { useEffect, useMemo } from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import { createResource, useResource, type RouterContext } from '@atlassian/jira-router';
import { getProjectKeyOrId } from '@atlassian/jira-router-resources-utils';
import type { GitLabServiceTokenResponse } from '../services/get-gitlab-service-token/types';

const getDataLoader = () =>
	import(
		/*
			webpackChunkName: "async-resource-jsm-change-management-settings-gitlab-gating",
			jiraSpaEntry: "async-resource-jsm-change-management-settings-gitlab-gating"
		*/
		'./get-gitlab-gating-data'
	);

const getKey = ({ match }: RouterContext): string =>
	`change-management-settings-gitlab-gating-${getProjectKeyOrId(match)}`;

export const jsmChangeManagementSettingsGitLabGatingResource = createResource<
	GitLabServiceTokenResponse | undefined
>({
	type: 'JSM_CHANGE_MANAGEMENT_SETTINGS_GITLAB_GATING_RESOURCE',
	getKey,
	getDataLoader,
	maxAge: Infinity,
	isBrowserOnly: true,
});

export const useGitLabGatingResource = () => {
	const { data, loading, error, refresh } = useResource(
		jsmChangeManagementSettingsGitLabGatingResource,
	);

	useEffect(() => {
		if (!ff('enable-gitlab-gating-frontend_k8rha')) return;

		if (!data && !loading && !error) {
			refresh();
		}
	}, [data, loading, error, refresh]);

	return useMemo(
		() => ({
			data: data?.data,
			pending: loading,
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			error: data?.error || (error as Error),
		}),
		[data, loading, error],
	);
};
