import { BOARD_ID, PROJECT_KEY, PROJECT_ID, type IdentifierType } from '../get-scope-data/types';
import type { ScopeIdentifier, Scope } from './types';

export const getScopeIdentifier = (scope: Scope): ScopeIdentifier => {
	// We check for identifiers in order of specificity first. A board ID is more specific than a project key and so on
	const identifierValue = scope.boardId || scope.projectKey || scope.projectId;

	let identifierType: IdentifierType = null;
	if (scope.boardId) {
		identifierType = BOARD_ID;
	} else if (scope.projectKey) {
		identifierType = PROJECT_KEY;
	} else if (scope.projectId) {
		identifierType = PROJECT_ID;
	}

	return {
		identifierValue,
		identifierType,
	};
};
