import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type JiraBusinessListEmbedAppType from '@atlassian/jira-spa-apps-business-list-embed';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyListEmbedView = lazyForPaint<typeof JiraBusinessListEmbedAppType>(
	() =>
		import(
			/* webpackChunkName: "async-business-list-embed", jiraSpaEntry: "async-business-list-embed" */ '@atlassian/jira-spa-apps-business-list-embed'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazyListEmbedView} pageId="async-business-list-embed" shouldShowSpinner />
);
