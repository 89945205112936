import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { performGetRequest } from '@atlassian/jira-fetch';
import { createResource } from '@atlassian/jira-router';
import { ANALYTICS_ID, PACKAGE_NAME, RESOURCE_KEY, RESOURCE_TYPE, STORE_KEY } from './constants';
import type { NextBestTaskFeatureResourceData, NextBestTaskMyPreferencesResponse } from './types';

export const nextBestTaskFeatureResource = createResource<NextBestTaskFeatureResourceData>({
	type: RESOURCE_TYPE,
	getKey: () => RESOURCE_KEY,
	getData: async (): Promise<NextBestTaskFeatureResourceData> => {
		try {
			return await performGetRequest<NextBestTaskMyPreferencesResponse>(
				`/rest/api/3/mypreferences?key=${STORE_KEY}`,
			);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			const initialValueNeverSet = error.statusCode === 404;
			if (initialValueNeverSet) {
				/**
				 * If the user has never set the preference, we auto enroll them and set the initial value to true.
				 */
				return {
					hasUserOptedIn: true,
				};
			}

			fireErrorAnalytics({
				error,
				meta: {
					id: ANALYTICS_ID,
					packageName: PACKAGE_NAME,
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		}

		/* on error return false,
		 * this ensures if user is in the experiment cohort but later opted out, they don't revert to having the feature enabled */
		return {
			hasUserOptedIn: false,
		};
	},
	maxAge: Number.MAX_VALUE,
});
