import { fg } from '@atlassian/jira-feature-gating';

// We don't inline usage of feature flags/gates as our logic to show or no show will become complex at times and should not be duplicated.
// See https://hello.atlassian.net/wiki/spaces/JNR/pages/3699574590/Rollout+technical+design+draft#Decision-points
export const getWillShowNav4UserOptOut = () =>
	fg('jira_nav4') &&
	(getWillShowNav4UserOptOutForDogfooding() ||
		fg('jira_nav4_eap') ||
		getWillShowNav4UserOptOutForExistingCustomers());

const getWillShowNav4UserOptOutForDogfooding = () =>
	fg('jira_nav4_dogfooding') &&
	(fg('jira_nav4_dogfooding_phase-1') || fg('jira_nav4_dogfooding_phase-2'));

// Update this when the existing customers stage gets closer.
// See https://hello.atlassian.net/wiki/spaces/JNR/pages/3699574590/Rollout+technical+design+draft#User-opt-out-checkbox
const getWillShowNav4UserOptOutForExistingCustomers = () => false;
