import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const classicProjectSettingsDataClassificationsRoute = {
	group: ROUTE_GROUPS.PROJECT_SETTINGS_CLASSIC,
	name: ROUTE_NAMES.CLASSIC_PROJECT_SETTINGS_DATA_CLASSIFICATIONS,
	path: '/plugins/servlet/project-config/:projectKey/data-classifications',
	exact: true,
};
