import React from 'react';
import { render } from 'react-dom';
import { StrictMode } from '@atlassian/jira-react-strict-mode';
import DwellApp from '@atlassian/jira-session-tracker/src/dwell-time';

const getPlaceholder = (placeholderId: string) => {
	const existingPlaceholder = document.getElementById(placeholderId);
	if (existingPlaceholder) {
		return existingPlaceholder;
	}
	const div = document.createElement('div');
	div.id = placeholderId;
	if (document.body) {
		document.body.appendChild(div);
	} else {
		throw new Error(
			'document.body was not found whilst trying to create the dwell component placeholder.',
		);
	}
	return div;
};

const renderDwellTimeComponent = (
	placeholder: string | null,
	sessionId: string | null,
	data: any = {},
) => {
	if (placeholder && sessionId) {
		render(
			<StrictMode>
				<DwellApp sessionId={sessionId} data={data} />
			</StrictMode>,
			getPlaceholder(placeholder),
		);
	}
};

export default renderDwellTimeComponent;
