import { useEffect } from 'react';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useSpaStatePageReady } from '@atlassian/jira-spa-state-controller';

const cleanUrl = (pathname: string) => {
	let url = pathname.replace(/\/$/, '');

	// /browse redirects or sw classic redirects
	if (/\/browse\/\w+$/.test(url)) {
		url = 'browse';
	}

	// replaces any occurrence of two or more capital letters,
	// or one capital letter followed by a number and also
	// two or more capital letters followed by a dash and any amount of numbers
	// (should catch all project and issue keys)
	if (/[A-Z][A-Z0-9]+(-[\d]+)*/g.test(url)) {
		url = url.replace(/[A-Z][A-Z0-9]+(-[\d]+)*/g, 'XX');
	}
	return url;
};
type Item = {
	dataId: string;
	currentUrl: string;
	bundleId: string;
	time: string;
	redirectUrl: string;
};
/**
 * Handles the collection and processing of user interactions that occur before the page becomes fully interactive. This enhances the understanding of user behavior during the initial loading phase.
 */
const TrackClicksBeforeTTI = () => {
	const [{ isReady }] = useSpaStatePageReady();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	useEffect(() => {
		if (!isReady) return;
		try {
			const sessionStorageItems = sessionStorage.getItem('clicks-before-tti');
			if (sessionStorageItems == null || sessionStorageItems === '') return;
			const items: Item[] = JSON.parse(sessionStorageItems);
			items.forEach((item) => {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				let extras: Record<any, any> = {
					elementId: item.dataId,
					currentUrl: cleanUrl(item.currentUrl),
					bundleId: item.bundleId,
					time: item.time,
				};
				if (item.redirectUrl !== '') {
					extras = {
						...extras,
						redirectUrl: cleanUrl(item.redirectUrl),
					};
				}
				fireOperationalAnalytics(
					createAnalyticsEvent({
						action: 'clicked',
					}),
					'nonInteractiveElement clicked',
					'',
					extras,
				);
			});
			sessionStorage.removeItem('clicks-before-tti');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			// eslint-disable-next-line no-console
			console.error(error);
		}
	}, [createAnalyticsEvent, isReady]);
	return null;
};
export { TrackClicksBeforeTTI };
