import { loadWrmResources } from './load-wrm-resources';
import { SHOULD_LOG_ERROR } from './shared';

/**
 * A version of loadBridge where wrm modules may be loaded without then looking for a mdn module inside.
 */
export const wrmRequire = async (
	wrmKeys: string[],
	shouldLogError: boolean = SHOULD_LOG_ERROR,
): Promise<void> => loadWrmResources({ wrmKeys, shouldLogError });
