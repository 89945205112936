import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const eocAssignCustomRolesRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_ASSIGN_CUSTOM_ROLES,
	path: '/jira/settings/products/ops/role-assignment',
	exact: true,
};
