import { isFedRamp } from '@atlassian/atl-context';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarServiceDesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info';
import {
	browseConversationsRoute,
	browseIntentsRoute,
	browseStandardFlowsRoute,
	landingPageRoute,
	settingsRoute,
	viewIntentRoute,
	viewStandardFlowRoute,
} from '@atlassian/jira-router-routes-project-settings-service-desk-virtual-agent-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import { APP_NAMES, toPackageName } from '@atlassian/jira-servicedesk-common/src/utils/app-names';
import { browseIntentsLayout } from '@atlassian/jira-servicedesk-virtual-agent-browse-intents-layout';
import {
	VirtualAgentBrowseConversationsSkeleton,
	VirtualAgentBrowseIntentsSkeleton,
	VirtualAgentBrowseStandardFlowsSkeleton,
	VirtualAgentLandingSkeleton,
	VirtualAgentSettingsSkeleton,
	VirtualAgentViewIntentSkeleton,
	VirtualAgentViewStandardFlowSkeleton,
} from '@atlassian/jira-skeletons';
import { browseConversationsEntryPoint } from '@atlassian/jira-spa-apps-virtual-agent-browse-conversations/entrypoint';
import { browseIntentsEntryPoint } from '@atlassian/jira-spa-apps-virtual-agent-browse-intents/entrypoint';
import { browseStandardFlowsEntryPoint } from '@atlassian/jira-spa-apps-virtual-agent-browse-standard-flows/entrypoint';
import { landingEntryPoint } from '@atlassian/jira-spa-apps-virtual-agent-landing/entrypoint';
import { settingsEntryPoint } from '@atlassian/jira-spa-apps-virtual-agent-settings/entrypoint';
import { viewIntentEntryPoint } from '@atlassian/jira-spa-apps-virtual-agent-view-intent/entrypoint';
import { viewStandardFlowEntryPoint } from '@atlassian/jira-spa-apps-virtual-agent-view-standard-flow/entrypoint';
import {
	defaultRedirectForSettings,
	getSettingsNavigationResources,
	SERVICE_DESK_PRODUCT,
} from './ui/product-routes/common';

const browseConversationsPageEntryPoint = createPageEntryPoint({
	main: browseConversationsEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

const browseIntentsPageEntryPoint = createPageEntryPoint({
	main: browseIntentsEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

const browseStandardFlowsPageEntryPoint = createPageEntryPoint({
	main: browseStandardFlowsEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

const landingPageEntryPoint = createPageEntryPoint({
	main: landingEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

const settingsPageEntryPoint = createPageEntryPoint({
	main: settingsEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

const viewStandardFlowPageEntryPoint = createPageEntryPoint({
	main: viewStandardFlowEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

const viewIntentPageEntryPoint = createPageEntryPoint({
	main: viewIntentEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

const getVirtualAgentRoutesCommon = (): Pick<Route, 'layout' | 'navigation'> => ({
	layout: serviceProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServiceDesk,
	},
});

const FedRAMPFallbackRedirectToProjectSettings = defaultRedirectForSettings(SERVICE_DESK_PRODUCT);

export const virtualAgentRouteEntries: Route[] = [
	createEntry(landingPageRoute, {
		...getVirtualAgentRoutesCommon(),
		entryPoint() {
			if (isFedRamp()) {
				return undefined;
			}
			return landingPageEntryPoint;
		},
		component: FedRAMPFallbackRedirectToProjectSettings, // this is only used when entryPoint() returns undefined, which is only the case in FedRAMP
		skeleton: VirtualAgentLandingSkeleton,
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		meta: {
			reporting: {
				id: APP_NAMES.VIRTUAL_AGENT_SETTINGS,
				packageName: toPackageName(APP_NAMES.VIRTUAL_AGENT_SETTINGS),
				teamName: 'jsd-flux-capacitors',
			},
		},
	}),
	createEntry(browseIntentsRoute, {
		...getVirtualAgentRoutesCommon(),
		entryPoint() {
			if (isFedRamp()) {
				return undefined;
			}
			return browseIntentsPageEntryPoint;
		},
		component: FedRAMPFallbackRedirectToProjectSettings, // this is only used when entryPoint() returns undefined, which is only the case in FedRAMP
		layout: browseIntentsLayout,
		skeleton: VirtualAgentBrowseIntentsSkeleton,
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		meta: {
			reporting: {
				id: APP_NAMES.VIRTUAL_AGENT_BROWSE_INTENTS,
				packageName: toPackageName(APP_NAMES.VIRTUAL_AGENT_BROWSE_INTENTS),
				teamName: 'jsd-flux-capacitors',
			},
		},
	}),
	createEntry(viewIntentRoute, {
		...getVirtualAgentRoutesCommon(),
		entryPoint() {
			if (isFedRamp()) {
				return undefined;
			}
			return viewIntentPageEntryPoint;
		},
		component: FedRAMPFallbackRedirectToProjectSettings, // this is only used when entryPoint() returns undefined, which is only the case in FedRAMP
		skeleton: VirtualAgentViewIntentSkeleton,
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		meta: {
			reporting: {
				id: APP_NAMES.VIRTUAL_AGENT_VIEW_INTENT,
				packageName: toPackageName(APP_NAMES.VIRTUAL_AGENT_VIEW_INTENT),
				teamName: 'jsd-flux-capacitors',
			},
		},
	}),
	createEntry(browseStandardFlowsRoute, {
		...getVirtualAgentRoutesCommon(),
		entryPoint() {
			if (isFedRamp()) {
				return undefined;
			}
			return browseStandardFlowsPageEntryPoint;
		},
		component: FedRAMPFallbackRedirectToProjectSettings, // this is only used when entryPoint() returns undefined, which is only the case in FedRAMP
		skeleton: VirtualAgentBrowseStandardFlowsSkeleton,
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		meta: {
			reporting: {
				id: APP_NAMES.VIRTUAL_AGENT_BROWSE_STANDARD_FLOWS,
				packageName: toPackageName(APP_NAMES.VIRTUAL_AGENT_BROWSE_STANDARD_FLOWS),
				teamName: 'jsd-flux-capacitors',
			},
		},
	}),
	createEntry(viewStandardFlowRoute, {
		...getVirtualAgentRoutesCommon(),
		entryPoint() {
			if (isFedRamp()) {
				return undefined;
			}
			return viewStandardFlowPageEntryPoint;
		},
		component: FedRAMPFallbackRedirectToProjectSettings, // this is only used when entryPoint() returns undefined, which is only the case in FedRAMP
		skeleton: VirtualAgentViewStandardFlowSkeleton,
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		meta: {
			reporting: {
				id: APP_NAMES.VIRTUAL_AGENT_VIEW_STANDARD_FLOW,
				packageName: toPackageName(APP_NAMES.VIRTUAL_AGENT_VIEW_STANDARD_FLOW),
				teamName: 'jsd-flux-capacitors',
			},
		},
	}),
	createEntry(browseConversationsRoute, {
		...getVirtualAgentRoutesCommon(),
		entryPoint() {
			if (isFedRamp()) {
				return undefined;
			}
			return browseConversationsPageEntryPoint;
		},
		component: FedRAMPFallbackRedirectToProjectSettings, // this is only used when entryPoint() returns undefined, which is only the case in FedRAMP
		skeleton: VirtualAgentBrowseConversationsSkeleton,
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		meta: {
			reporting: {
				id: APP_NAMES.VIRTUAL_AGENT_BROWSE_CONVERSATIONS,
				packageName: toPackageName(APP_NAMES.VIRTUAL_AGENT_BROWSE_CONVERSATIONS),
				teamName: 'jsd-flux-capacitors',
			},
		},
	}),
	createEntry(settingsRoute, {
		...getVirtualAgentRoutesCommon(),
		entryPoint() {
			if (isFedRamp()) {
				return undefined;
			}
			return settingsPageEntryPoint;
		},
		component: FedRAMPFallbackRedirectToProjectSettings, // this is only used when entryPoint() returns undefined, which is only the case in FedRAMP
		skeleton: VirtualAgentSettingsSkeleton,
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		meta: {
			reporting: {
				id: APP_NAMES.VIRTUAL_AGENT_SETTINGS,
				packageName: toPackageName(APP_NAMES.VIRTUAL_AGENT_SETTINGS),
				teamName: 'jsd-flux-capacitors',
			},
		},
	}),
];
