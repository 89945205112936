import type { OgConsolidationUserState } from './types';

export const OG_CONSOLIDATION_USER_STATE_STORAGE_KEY = 'eoc_consolidation_user_state' as const;

export const DEFAULT_CONSOLIDATION_USER_STATE: OgConsolidationUserState = {
	isPromotionBannerDismissed: false,
	isWelcomeModalDisplayed: false,
	isTransitionHomeHidden: false,
	isTransitionBannerDismissed: false,
	isBroadcastWidgetDismissed: false,
	transitionBannerDismissedAt: null,
	isBenefitModalDismissed: false,
};

export const ERROR_CONSOLIDATION_USER_STATE: OgConsolidationUserState = {
	isPromotionBannerDismissed: true,
	isWelcomeModalDisplayed: true,
	isTransitionHomeHidden: true,
	isTransitionBannerDismissed: true,
	isBroadcastWidgetDismissed: true,
	transitionBannerDismissedAt: null,
	isBenefitModalDismissed: true,
};
