import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';
import type { Route } from '@atlassian/jira-router';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const escalationsRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_CUSTOMER_SERVICE_ESCALATIONS,
	path: '/jira/servicedesk/projects/:projectKey/escalations',
	exact: true,
} as const as Route;
