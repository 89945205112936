// Api

export const API_ORIGIN_LOCAL = 'http://localhost:8001';
export const API_PATHNAME_LOCAL = '';

// Remote module

export const REMOTE_MODULE_URI_LOCAL = 'http://localhost:9009';

export const REMOTE_MODULE_URI_STG =
	'https://post-office-rml-frontend.stg-east.frontend.public.atl-paas.net';

export const REMOTE_MODULE_URI_PROD =
	'https://post-office-rml-frontend.prod-east.frontend.public.atl-paas.net';
