import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { performGetRequest, performPostRequest } from '@atlassian/jira-fetch';
import type { SettingsV4Get, SettingsV4Set } from '../../common/types';

export const get = async (): Promise<SettingsV4Get> => {
	try {
		return await performGetRequest('/rest/internal/4/announcement-banner');
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		// 403 - banner is set to private and user is anonymous
		if (error.statusCode === 403) {
			return {
				content: '',
				bgColor: 'grey',
				isEnabled: false,
				isDismissable: false,
				visibility: 'private',
				hashId: '',
			};
		}

		fireErrorAnalytics({
			meta: {
				id: 'settingsV4Get',
				packageName: 'jiraAdminPagesSystemUiAnnouncementBannerResources',
				teamName: 'jira-cosmos',
			},
			error,
		});
		throw error;
	}
};

export const set = async (body: SettingsV4Set): Promise<SettingsV4Get> => {
	try {
		return await performPostRequest('/rest/internal/4/announcement-banner', {
			body: JSON.stringify(body),
		});
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			meta: {
				id: 'settingsV4Set',
				packageName: 'jiraAdminPagesSystemUiAnnouncementBannerResources',
				teamName: 'jira-cosmos',
			},
			error,
		});
		throw error;
	}
};
