import {
	type SortDirection,
	ASC,
	DESC,
} from '@atlassian/jira-common-constants/src/sort-directions';
import type { IntlShape } from '@atlassian/jira-intl';
import type { JiraVersionSortField } from '@atlassian/jira-relay/src/__generated__/projectVersion_PaginationQuery.graphql';
import { VERSION_DATE_FORMAT_OPTION as DATE_FORMAT_OPTION } from '@atlassian/jira-software-releases-version-date-utils';
import type { Status } from '@atlassian/jira-version-modals/src/common/model';
import {
	ARCHIVED,
	RELEASED,
	UNRELEASED,
} from '@atlassian/jira-version-modals/src/common/model/status';
import {
	DESCRIPTION,
	NAME,
	RELEASE_DATE,
	START_DATE,
	SEQUENCE,
	NUM_SUPPORTD_STATUSES,
} from './constants';

export const transformStatusFromParam = (input?: string): Status[] => {
	switch (input) {
		case 'no-filter':
			return [RELEASED, UNRELEASED, ARCHIVED];
		case 'all':
			return [RELEASED, UNRELEASED, ARCHIVED];
		default: {
			const params = (input || '').split('-');
			const result = [
				...(params.includes('released') ? [RELEASED] : []),
				...(params.includes('unreleased') ? [UNRELEASED] : []),
				...(params.includes('archived') ? [ARCHIVED] : []),
			];
			return result.length ? result : [UNRELEASED];
		}
	}
};
export const transformStatusToParam = (input?: Status[]): string | undefined => {
	if (input) {
		if (input.length === 0) {
			return 'no-filter';
		}
		if (input.length > 2) {
			return 'all';
		}
		return [
			input.includes(RELEASED) && ['released'],
			input.includes(UNRELEASED) && ['unreleased'],
			input.includes(ARCHIVED) && ['archived'],
		]
			.filter(Boolean)
			.join('-');
	}
	return undefined;
};

export const transformSortOrderFromParam = (input?: string): SortDirection => {
	switch (input) {
		case 'ASC':
			return ASC;
		case 'DESC':
			return DESC;
		default:
			return DESC;
	}
};

export const transformSortFieldFromParam = (input?: string): JiraVersionSortField => {
	switch (input) {
		case 'NAME':
			return NAME;
		case 'RELEASEDATE':
			return RELEASE_DATE;
		case 'STARTDATE':
			return START_DATE;
		case 'DESCRIPTION':
			return DESCRIPTION;
		case 'SEQUENCE':
			return SEQUENCE;
		default:
			return SEQUENCE;
	}
};

export const transformStatusToDropdownSelection = (input?: string): Status[] => {
	switch (input) {
		case 'no-filter':
			return [];
		case 'all':
			return [RELEASED, UNRELEASED, ARCHIVED];
		default: {
			const params = (input || '').split('-');
			const result = [
				...(params.includes('released') ? [RELEASED] : []),
				...(params.includes('unreleased') ? [UNRELEASED] : []),
				...(params.includes('archived') ? [ARCHIVED] : []),
			];
			return result.length ? result : [UNRELEASED];
		}
	}
};

export const getUpdatedDisplayedStatuses = (
	displayedStatuses: Status[],
	selectedStatuses: Status[],
	newStatus: Status,
): Status[] => {
	// Handle the case when a status is selected after deselection of all in dropdown where versions in all statuses are displayed despite empty selection & deselecting a status from a full selection.
	if (displayedStatuses.length === NUM_SUPPORTD_STATUSES) {
		if (!selectedStatuses.length) {
			return [newStatus];
		}
		return displayedStatuses.filter((status) => status !== newStatus);
	}

	return displayedStatuses.includes(newStatus)
		? displayedStatuses.filter((item) => item !== newStatus)
		: [...displayedStatuses, newStatus];
};

export const getFormattedVersionDate = (
	versionDate: string | null | undefined,
	formatDate: IntlShape['formatDate'],
): string =>
	versionDate != null
		? formatDate(new Date(versionDate), {
				timeZone: DATE_FORMAT_OPTION.timeZone,
				dateStyle: 'long',
			})
		: '';
