import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { chromelessLayout } from '@atlassian/jira-chromeless-layout';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { composeLayouts } from '@atlassian/jira-route-layout';
import type { Route } from '@atlassian/jira-router';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference';
import { LazyIssueApp } from '@atlassian/jira-router-routes-common';
import {
	softwareCalendarRoute,
	softwareCalendarEmbedRoute,
} from '@atlassian/jira-router-routes-software-calendar-routes';
import { CalendarSkeleton, CalendarEmbedSkeleton } from '@atlassian/jira-skeletons';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav';
import { softwareProjectLayout } from '@atlassian/jira-software-project-layout';
import { calendarEntryPoint } from '@atlassian/jira-spa-apps-software-calendar/entrypoint';

export const softwareCalendarEmbedRouteEntry: Route = createEntry(softwareCalendarEmbedRoute, {
	skeleton: CalendarEmbedSkeleton,

	component: CalendarEmbedSkeleton,

	entryPoint() {
		if (__SERVER__) {
			return undefined;
		}
		return calendarRouteEntry;
	},

	layout: chromelessLayout,

	resources: [...getNavigationSidebarProjectResource(), themePreferenceResource],

	ufoName: 'next-gen-calendar-embed',
});

export const calendarRouteEntry = createPageEntryPoint({
	main: calendarEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

export const softwareCalendarRouteEntry: Route = createEntry(softwareCalendarRoute, {
	skeleton: CalendarSkeleton,

	component: CalendarSkeleton,

	entryPoint() {
		if (__SERVER__) {
			return undefined;
		}
		return calendarRouteEntry;
	},

	layout: composeLayouts(
		softwareProjectLayout,
		embeddedIssuePreloadLayoutBuilder([{ query: ['selectedIssue'] }]),
	),

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarNextGen,
		horizontal: AsyncHorizontalSoftwareProjectNav,
		horizontalFeatureFlag: 'sea-2744-jsw-tabs-nav',
	},

	resources: [...getNavigationResources(), ...getNavigationSidebarProjectResource()],

	afterPaint: [LazyIssueApp],
	forPaint: [LazyAtlassianNavigation],
	ufoName: 'next-gen-calendar',
});
