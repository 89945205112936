import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const globalSettingsSystemUiAnnouncementBannerRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.GLOBAL_SETTINGS_SYSTEM_UI_ANNOUNCEMENT_BANNER,
	path: '/jira/settings/system/ui/announcement-banner',
};
