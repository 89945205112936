import get from 'lodash/get';
import { createResource } from '@atlassian/jira-router';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils';
import {
	ONE_DAY_IN_MILLIS,
	RESOURCE_TYPE_SERVICEDESK_QUEUES_NAVIGATION_ITEMS,
	RESOURCE_TYPE_SERVICEDESK_QUEUES_PRIORITY_GROUPS,
} from '../constants';
import type { CategorizedGroupData } from '../types';

const getDataLoader = () =>
	import(
		/* webpackChunkName: "async-resource-servicedesk-queues-nav-items", jiraSpaEntry: "async-resource-servicedesk-queues-nav-items" */ './get-data'
	);

const getPriorityGroupDataLoader = () =>
	import(
		/* webpackChunkName: "async-resource-servicedesk-queues-priority-group-data-loader", jiraSpaEntry: "async-resource-servicedesk-queues-priority-group-data-loader" */ './get-priority-group-data'
	);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const queuesNavigationItems = createResource<any>({
	type: RESOURCE_TYPE_SERVICEDESK_QUEUES_NAVIGATION_ITEMS,
	getKey: ({ match, query }) =>
		// projectKey from url path/query or browse/{issueKey}
		getProjectKeyId(match, query).projectKey || get(match, 'params.issueKey', '').split('-')[0],
	getDataLoader,
	maxAge: ONE_DAY_IN_MILLIS,
});

export const priorityGroupResource = createResource<CategorizedGroupData>({
	type: RESOURCE_TYPE_SERVICEDESK_QUEUES_PRIORITY_GROUPS,
	getKey: ({ match, query }) =>
		// projectKey from url path or browse/{issueKey}
		getProjectKeyId(match, query).projectKey || get(match, 'params.issueKey', '').split('-')[0],
	getDataLoader: getPriorityGroupDataLoader,
	maxAge: ONE_DAY_IN_MILLIS,
	isBrowserOnly: true,
});
