import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type SoftwareClassicFeaturesType from '@atlassian/jira-spa-apps-software-classic-features';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazySoftwareClassicFeatures = lazyForPaint<typeof SoftwareClassicFeaturesType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-classic-features", jiraSpaEntry:  "async-classic-features" */ '@atlassian/jira-spa-apps-software-classic-features'
		),
	),
);

export const ClassicFeaturesPage = () => (
	<LazyPage Page={LazySoftwareClassicFeatures} pageId="classic-features" shouldShowSpinner />
);
