import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const globalSettingsIssueWorkflowEditRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.GLOBAL_SETTINGS_ISSUE_WORKFLOW_EDIT,
	path: '/jira/settings/issues/workflows/:workflowId',
};

export const globalSettingsIssueWorkflow1Route = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.GLOBAL_SETTINGS_ISSUE_WORKFLOW,
	path: '/jira/settings/issues/workflows',
};
