import React, { memo } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import {
	setPerformanceMarkLoadStart,
	setPerformanceMarkLoadEnd,
} from '@atlassian/jira-navigation-apps-sidebar-common';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-common/async';
import type PlanSidebarType from './index';

const LazyPlanSidebar = lazyForPaint<typeof PlanSidebarType>(() => {
	setPerformanceMarkLoadStart();
	return import(/* webpackChunkName: "async-sidebar-plan" */ './index').then((bundle) => {
		setPerformanceMarkLoadEnd();
		return bundle;
	});
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PlanSidebar = memo<Record<any, any>>(() => (
	<SidebarBoundary id="Plan">
		<LazyPlanSidebar />
	</SidebarBoundary>
));

PlanSidebar.displayName = 'PlanSidebar';

export default PlanSidebar;
