import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type LinkOpsgeniePageType from '@atlassian/jira-spa-apps-project-settings-link-opsgenie-v2';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyLinkOpsgeniePage = lazyForPaint<typeof LinkOpsgeniePageType>(
	() =>
		import(
			/* webpackChunkName: "async-project-settings-link-opsgenie-v2", jiraSpaEntry: "async-project-settings-link-opsgenie-v2" */ '@atlassian/jira-spa-apps-project-settings-link-opsgenie-v2'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazyLinkOpsgeniePage} pageId="link-opsgenie-page" shouldShowSpinner={false} />
);
