// eslint-disable-next-line jira/restricted/react-component-props
import { useMemo, type ComponentType, type ReactElement, type ComponentProps } from 'react';
import {
	type JiraUserBoardNavigationData,
	type ProjectSidebarNavInfo,
	staticJSWUserBoardSidebarNavigationResource,
	staticProjectSidebarNavigationResource,
} from '@atlassian/jira-navigation-apps-resources';
import { useResource } from '@atlassian/jira-router';
import { getProjectContext, getUserContext } from './common/utils';
import type { UseContainerContextReturn, ContainerContextData } from './types';

const useProjectContext = (): UseContainerContextReturn => {
	const { data, loading, error, refresh } = useResource<ProjectSidebarNavInfo | null>(
		staticProjectSidebarNavigationResource,
	);
	const projectContext = useMemo(() => data && getProjectContext(data), [data]);
	return [
		{
			data: projectContext,
			error,
			loading,
		},
		{ fetch: refresh, refetch: refresh },
	];
};

const useUserBoardContext = (): UseContainerContextReturn => {
	const { data, loading, error, refresh } = useResource<JiraUserBoardNavigationData | null>(
		staticJSWUserBoardSidebarNavigationResource,
	);
	const userContext = useMemo(() => data && getUserContext(data), [data]);
	return [
		{
			data: userContext,
			error,
			loading,
		},
		{ fetch: refresh, refetch: refresh },
	];
};

/**
 * @deprecated This hook returns ProjectContext | UserContext.
 * Please use alternative hooks like useProject, useBoard, useJiraSoftwareProjectNavigation, useJiraUserBoardNavigationResource
 * Use this hook only if you are unable to achieve the functionality using above hooks.
 */
export const useContainerContext = (): UseContainerContextReturn => {
	const projectContext = useProjectContext();
	const userBoardContext = useUserBoardContext();

	if (userBoardContext && userBoardContext[0] && userBoardContext[0].data != null) {
		return userBoardContext;
	}
	return projectContext;
};

type ContainerContextSubscriberProps = {
	children: (
		arg1: ContainerContextData,
		arg2: {
			fetch: () => void;
			refetch: () => void;
		},
	) => ReactElement;
};

export const ContainerContextSubscriber = (props: ContainerContextSubscriberProps) => {
	const [data, actions] = useContainerContext();
	// @ts-expect-error - TS2345 - Argument of type '{ readonly data: ContainerContext | null; readonly error: Error | Record<string, any> | (Error & Record<string, any>) | (Record<string, any> & Error) | null; readonly loading: boolean; }' is not assignable to parameter of type 'ContainerContextData'.
	return props.children(data, actions);
};
ContainerContextSubscriber.displayName = 'ContainerContextSubscriber';

export type UseContainerContextType = typeof useContainerContext;
export type ContainerContextSubscriberType = ComponentType<
	JSX.LibraryManagedAttributes<
		typeof ContainerContextSubscriber,
		ComponentProps<typeof ContainerContextSubscriber>
	>
>;
