import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { AsyncViewsNav } from '@atlassian/jira-horizontal-nav-jsm-views';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import {
	getRapidBoardDataResource,
	getRapidboardResources,
	uifBoardResource,
} from '@atlassian/jira-router-resources-classic-projects';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils';
import { jsmBoardRoute } from '@atlassian/jira-router-routes-servicedesk-board-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import { ClassicBoardSkeleton } from '@atlassian/jira-skeletons';
import { LazyServicedeskBoard, LazyServicedeskLegacyBoardSettings, ServicedeskBoard } from './view';

export const jsmBoardEntry = createEntry(jsmBoardRoute, {
	component: ServicedeskBoard,
	layout: serviceProjectLayout,
	skeleton: ClassicBoardSkeleton,
	forPaint: [LazyAtlassianNavigation, LazyServicedeskBoard, LazyServicedeskLegacyBoardSettings],
	perfMetricKey: 'servicedesk-boards-load',
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
		horizontal: AsyncViewsNav,
		horizontalFeatureFlag: 'jsm-views-of-work-phase-1_6wljy',
	},
	exact: true,
	resources: [
		...getServiceDeskNavigationResources(),
		...getServicedeskPageContextResources(),
		getConsolidationStateResource(),
		...getRapidboardResources(),
		getRapidBoardDataResource(),
		resourceWithCondition2(() => !__SERVER__, uifBoardResource),
		projectContextResource,
	],
});

export const boardsRouteEntries: Route[] = [jsmBoardEntry];
