import { getUserTiming } from 'uxm';
import { JS_EVAL_START, JS_EVAL_STOP } from '@atlassian/jira-common-constants/src/analytics-marks';

export const getMarks = (data: { start: number }) => {
	const userTiming = getUserTiming();
	if (userTiming === null) return null;

	const sanitizeName = (name: string) => name.replace(/[\W_]+/g, '_');
	// @ts-expect-error - TS7031 - Binding element 'baseName' implicitly has an 'any' type. | TS7031 - Binding element 'collection' implicitly has an 'any' type.
	const getNextAvailableName = ({ baseName, collection }) => {
		let candidateName = baseName;
		// If <name> is taken, we'll try <name>_2 and increment from there
		let counter = 2;
		let validName;

		while (!validName) {
			if (candidateName in collection) {
				candidateName = `${baseName}_${counter}`;
				counter += 1;
			} else {
				validName = candidateName;
			}
		}
		return validName;
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const marks: Record<string, any> = {};
	// Excludes mark starting with '_'. This allows marks to opt-out from the report.
	userTiming
		.filter(
			// @ts-expect-error - TS7006 - Parameter 'item' implicitly has an 'any' type.
			(item) =>
				item.type === 'mark' &&
				!item.name.startsWith('_') &&
				!item.name.endsWith(JS_EVAL_START) &&
				!item.name.endsWith(JS_EVAL_STOP) &&
				item.startTime >= data.start,
		)
		// @ts-expect-error - TS7006 - Parameter 'item' implicitly has an 'any' type.
		.forEach((item) => {
			const name = getNextAvailableName({
				baseName: `mark__${sanitizeName(item.name)}`,
				collection: marks,
			});
			marks[name] = Math.round(item.startTime - data.start);
		});

	return marks;
};
