import { createResource } from '@atlassian/jira-router';
import { RESOURCE_TYPE_KB_CONFLUENCE_CONTEXT } from '../constants';

const getDataLoader = () =>
	import(
		/* webpackChunkName: "async-resource-kb-confluence-context", jiraSpaEntry: "async-resource-kb-confluence-context" */ './get-data'
	);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const kbConfluenceContextResource = createResource<any>({
	type: RESOURCE_TYPE_KB_CONFLUENCE_CONTEXT,
	getKey: ({ match }) => match.params.projectKey || '',
	getDataLoader,
	maxAge: Infinity,
});

export type { PrefetchResult } from './types';
