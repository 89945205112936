import log, { type Event } from '@atlassian/jira-common-util-logging/src/log';
import { PACKAGE_NAME_GASV3 } from '../../../constants';

export const ENV_MAPPING_UTIL = 'environment-mapping';
export const ANALYTICS_UTIL = 'analytics';
export const CUSTOM_FIELD_OBJECT_TYPE = 'custom-field-object-type';

/**
 * Consumer prefix mean the error is logged by the part of Jira that integrates Forge
 * If the error is logged by the Forge package itself it shouldn't have that prefix
 */
export const locationsMap = {
	[ANALYTICS_UTIL]: `${PACKAGE_NAME_GASV3}.error.utils.analytics`,
	[ENV_MAPPING_UTIL]: `${PACKAGE_NAME_GASV3}.error.utils.environment-mapping`,
	[CUSTOM_FIELD_OBJECT_TYPE]: `${PACKAGE_NAME_GASV3}.error.custom-field.object-type`,
} as const;

export const logError = (key: keyof typeof locationsMap, message: string, event?: Event) => {
	log.safeErrorWithoutCustomerData(locationsMap[key], message, event);
};
