import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyPrioritiesPage = lazyForPaint(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-admin-pages-priorities", jiraSpaEntry: "async-admin-pages-priorities" */ '@atlassian/jira-admin-pages-priorities'
		),
	),
);

const PrioritiesPage = () => (
	<LazyPage Page={LazyPrioritiesPage} pageId="jira-admin-pages-priorities" shouldShowSpinner />
);

export default PrioritiesPage;
