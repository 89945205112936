import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';
import type { Route } from '@atlassian/jira-router';

// Team dashboard routes
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const teamIntegrationsRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_TEAM_INTEGRATIONS,
	path: '/jira/ops/teams/:teamId/integrations',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const teamIntegrationListRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_TEAM_INTEGRATION_LIST,
	path: '/jira/ops/teams/:teamId/integrations/list',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const teamIntegrationCreateRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_TEAM_INTEGRATION_CREATE,
	path: '/jira/ops/teams/:teamId/integrations/create/:type',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const teamIntegrationDetailRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_TEAM_INTEGRATION_DETAIL,
	path: '/jira/ops/teams/:teamId/integrations/:type/:integrationId',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const teamIntegrationLogRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_TEAM_INTEGRATION_LOG,
	path: '/jira/ops/teams/:teamId/integrations/:type/:integrationId/logs',
} as const as Route;

// Global routes
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const globalIntegrationsRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_INTEGRATIONS,
	path: '/jira/settings/products/ops/integrations',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const globalIntegrationListRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_INTEGRATION_LIST,
	path: '/jira/settings/products/ops/integrations/list',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const globalIntegrationCreateRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_INTEGRATION_CREATE,
	path: '/jira/settings/products/ops/integrations/create/:type',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const globalIntegrationDetailRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_INTEGRATION_DETAIL,
	path: '/jira/settings/products/ops/integrations/:type/:integrationId',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const globalIntegrationLogRoute = {
	group: ROUTE_GROUPS.EOC,
	name: ROUTE_NAMES.EOC_INTEGRATION_LOG,
	path: '/jira/settings/products/ops/integrations/:type/:integrationId/logs',
} as const as Route;
