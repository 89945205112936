import React, { useMemo } from 'react';
import { Section, SkeletonItem } from '@atlaskit/side-navigation';
import {
	CORE_PROJECT,
	SOFTWARE_PROJECT,
	projectTypeToCanonicalId,
} from '@atlassian/jira-common-constants/src/project-types';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import {
	matchUrlPathname,
	matchUrlExact,
	MenuItem,
	shouldRenderNesting,
	getSystemNavigationPaths,
	type MatcherLocation,
} from '@atlassian/jira-navigation-apps-sidebar-common';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller';
import { SECTION_ID } from '../../../common/constants';
import { useFeatures } from '../../../controllers/features';
import messages from './messages';
import { MigrateCloudSiteMenuItem } from './migrate-to-cloud-site';

export type ImportExportSectionProps = {
	stack: string[];
};

/**
 *  Returns Menu navigation respecting the current user permissions
 *  When updating this file, make sure to also update the following files if necessary:
 * - src/packages/navigation-apps/sidebar/common/src/controllers/use-navigation-path/system
 */
export const ImportExportSection = ({ stack }: ImportExportSectionProps) => {
	const { data: features } = useFeatures();
	const { formatMessage } = useIntl();
	const { isSiteAdmin, isAdmin, licensedProducts } = useTenantContext();

	const navigationPath = useMemo(() => getSystemNavigationPaths(), []);

	if (features == null) return <SkeletonItem />;

	const hasSupportedLicense =
		licensedProducts[projectTypeToCanonicalId(SOFTWARE_PROJECT)] ||
		licensedProducts[projectTypeToCanonicalId(CORE_PROJECT)];
	const showNewExternalImport =
		hasSupportedLicense &&
		(isSiteAdmin || !isAdmin || ff('enable-product-admin-for-x2c-migration_zmlsv')) &&
		ff('x2c-jim-home-screen-replacement_eikwe');

	return (
		<Section title={formatMessage(messages.importExportGroup)}>
			{features.hasBackupManager && (
				<MenuItem
					analytics={{ itemId: 'cloud-export' }}
					href={navigationPath.importSectionBackupManager}
					selectedOn={matchUrlPathname()}
				>
					{formatMessage(messages.backupManager)}
				</MenuItem>
			)}
			{features.hasExternalImport && (
				<MenuItem
					analytics={{ itemId: 'external-import' }}
					href={
						showNewExternalImport
							? navigationPath.importSectionExternalImportNew
							: navigationPath.importSectionExternalImport
					}
					selectedOn={(currentLocation: MatcherLocation, itemLocation: MatcherLocation) =>
						matchUrlExact()(currentLocation, itemLocation) ||
						(showNewExternalImport &&
							currentLocation.pathname.includes(navigationPath.importSectionExternalImport))
					}
				>
					{formatMessage(messages.externalImport)}
				</MenuItem>
			)}
			{features.hasCloudImport && (
				<MenuItem
					analytics={{ itemId: 'cloud-import' }}
					href={navigationPath.importSectionCloudImport}
					selectedOn={matchUrlExact()}
				>
					{formatMessage(messages.importJiraCloud)}
				</MenuItem>
			)}
			{features.hasServerImport && (
				<MenuItem
					analytics={{ itemId: 'server-import' }}
					href={navigationPath.importSectionServerImport}
					selectedOn={matchUrlExact()}
				>
					{formatMessage(messages.importJiraServer)}
				</MenuItem>
			)}
			{shouldRenderNesting(features.hasC2CMigration, stack, SECTION_ID.MIGRATE_TO_CLOUD_SITE) && (
				<MigrateCloudSiteMenuItem />
			)}
		</Section>
	);
};
