import type { AnyEntryPoint, ReferenceFromEntryPoint } from '@atlassian/jira-entry-point';
import type { PageLayoutField } from '@atlassian/jira-entry-point-page-layout-fields';
import { useRouteEntryPoint } from '@atlassian/jira-entry-points-plugin';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils';

export const usePageLayoutEntryPoint = <TEntryPoint extends AnyEntryPoint>(
	layoutField: PageLayoutField,
) => {
	const route = useCurrentRoute();
	const entryPointReference = useRouteEntryPoint({ route });

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return entryPointReference?.entryPoints[layoutField] as ReferenceFromEntryPoint<TEntryPoint>;
};
