import { ROUTE_NAMES } from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';
// eslint-disable-next-line jira/import-whitelist
import {
	type DashboardConfigAndWRMResourceData,
	type DashboardConfigType,
	mapToReactGadgets,
} from '@atlassian/jira-dashboard-common';
import type { RouterDataContext } from '@atlassian/jira-router';
import getDashboardConfig, { getDashboardsConfig } from '../common/fetch-dashboard-config';
import fetchWrmBundleDescriptor from '../common/fetch-wrm-bundle-descriptor';
import generatePreloadLinkTags from '../common/generate-preload-link-tags';
import generateScriptTags from '../common/generate-script-tags';
import { getKey } from '../common/get-key';
import { gadgetsToUniqueSortedAmdModules } from '../utils';

const generateScriptTagAndPreloadedModuleNames = async (
	configs: DashboardConfigType[],
): Promise<DashboardConfigAndWRMResourceData[]> => {
	const gadgets = configs.flatMap((config) => config.gadgets);
	const preloadedModuleNames = gadgetsToUniqueSortedAmdModules(gadgets);
	const wrmBundleDescriptor = await fetchWrmBundleDescriptor(preloadedModuleNames);
	return configs.map((config) => {
		const scriptTags =
			[
				wrmBundleDescriptor ? generateScriptTags(wrmBundleDescriptor) : null,
				generatePreloadLinkTags(config),
			]
				.filter(Boolean)
				.join('\n') || null;

		return { ...config, scriptTags, preloadedModuleNames };
	});
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default async (context: RouterDataContext): Promise<DashboardConfigAndWRMResourceData[]> => {
	// this is either for single dashboard/wallboard or wallboard slide show with multiple dashboards
	const { route, query } = context;
	const originalConfigs: DashboardConfigType[] =
		route.name === ROUTE_NAMES.DASHBOARD_WALLBOARD
			? await getDashboardsConfig(query.dashboardId)
			: [await getDashboardConfig(getKey(context))];

	const configs = originalConfigs.map((config) => ({
		...config,
		gadgets: mapToReactGadgets(config.gadgets),
	}));

	if (!__SERVER__) {
		return configs.map((config) => ({ ...config, preloadedModuleNames: [] }));
	}

	return generateScriptTagAndPreloadedModuleNames(configs);
};
