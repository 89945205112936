import type { FetchOptions } from '@atlassian/jira-insights-common-constants';
import { fetchJson } from '@atlassian/jira-insights-fetch';
import type { BoardSettingsResponse } from '../../common/types';
import type { BoardSettingsParams } from './types';

export const fetchInsightsSettings = async (
	{ boardId }: BoardSettingsParams,
	options?: FetchOptions,
): Promise<BoardSettingsResponse> =>
	fetchJson<BoardSettingsResponse>(`/gateway/api/jira-insights/settings/board/${boardId}`, options);
