import React from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { IPBlocklistingBanner } from './main';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const IPBlocklistingBannerComp = lazyAfterPaint<typeof IPBlocklistingBanner>(
	() =>
		import(/* webpackChunkName: "async-ip-block-listing-banner" */ './main').then(
			(m) => m.IPBlocklistingBanner,
		),
	{ ssr: false },
);

export const IPBlocklistingBannerAsync = () => (
	<Placeholder name="async-ip-block-listing-banner" fallback={null}>
		<IPBlocklistingBannerComp />
	</Placeholder>
);
