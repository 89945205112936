import { REPORTS_IDS } from '@atlassian/jira-servicedesk-common/src/constants';

export const connectPrefix = 'com.atlassian.plugins.atlassian-connect-plugin:';
export const jsdConnectPrefix = 'com.atlassian.servicedesk.connect';

const legacyAddonPanelLinkId = 'com.atlassian.jira.jira-projects-plugin:tab-panel-link-parent';
const jsdQueuesConnectSection = 'sd-queues-addons';
export const connectSections = [
	legacyAddonPanelLinkId,
	REPORTS_IDS.REPORTS_ADDONS,
	jsdQueuesConnectSection,
] as const;
