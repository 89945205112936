import { createResource } from '@atlassian/jira-router';
import { RESOURCE_TYPE_PRODUCT_JSW_STORAGE_MANAGEMENT } from './constants';

type Data = {
	issues: Issue[];
};

type Issue = {
	key: string;
	size: number;
};

export const productsJswStorageManagementResource = createResource<Data>({
	type: RESOURCE_TYPE_PRODUCT_JSW_STORAGE_MANAGEMENT,
	getKey: () => 'state',
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-products-jsw-storage-management", jiraSpaEntry: "async-resource-products-jsw-storage-management" */ './controllers/get-data'
		),
});
