import { createHook } from 'react-sweet-state';
import type { TopNavigationState } from '../../../../common/types';
import { layoutStore } from '../../store';

const useTopNav = createHook(layoutStore, {
	selector: (state) => ({
		...state.topNavigation,
		shouldRender: !state.isChromeless,
	}),
});

type TopNavState = TopNavigationState & {
	shouldRender: boolean;
};

export const useTopNavState = (): TopNavState => {
	const [state] = useTopNav();
	return state;
};
