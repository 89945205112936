import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const globalSettingsIssuesPrioritySchemesRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.GLOBAL_SETTINGS_ISSUES_PRIORITY_SCHEMES,
	path: '/jira/settings/issues/priority-schemes',
};

export const globalSettingsIssuesPrioritiesRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.GLOBAL_SETTINGS_ISSUES_PRIORITIES,
	path: '/jira/settings/issues/priorities',
};
