/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { AppSkeletonImage } from '../../common/ui';
import periscopePage from './assets/periscope-page.tpl.svg';

const PeriscopePageSkeletonStyles = css({
	width: '100%',
	height: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	object: {
		border: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	img: {
		border: 'none',
	},
});

export const PeriscopePageSkeleton = () => (
	<div
		css={PeriscopePageSkeletonStyles}
		data-testid="skeletons.ui.periscope-page.app-skeleton-image-periscope-page"
	>
		<AppSkeletonImage src={periscopePage} />
	</div>
);
