import React from 'react';
import { Section } from '@atlaskit/side-navigation';
import { useIntl } from '@atlassian/jira-intl';
import { MenuItem } from '../../../common/ui/menu-item';
import { messages } from './messages';

interface FiltersFooterProps {
	hasFilters: boolean;
}

const analyticsPayload = {
	itemId: 'pin-manage-filters',
};

export const FiltersFooter = ({ hasFilters }: FiltersFooterProps) => {
	const { formatMessage } = useIntl();

	return (
		<Section hasSeparator={hasFilters}>
			<MenuItem analytics={analyticsPayload} href="/jira/filters">
				{formatMessage(messages.viewAllFilters)}
			</MenuItem>
		</Section>
	);
};
