import React, { useState, useCallback, type PropsWithChildren } from 'react';
import { createCtx } from '../utils/hooks/ctx';

export type ClickedArticleIds = string[];

export type FetchedArticleIds = string[];

export type QueryResults = {
	clickedArticleIds: ClickedArticleIds;
	fetchedArticleIds: FetchedArticleIds;
};
export interface SearchContextInterface {
	[query: string]: QueryResults;
}

export interface SearchContextProviderValue {
	searchContext: SearchContextInterface;
	addClickedArticleIds: (query: string, clickedArticleId: string) => void;
	addFetchedArticleIds: (query: string, fetchedArticles: string[]) => void;
}
export const [useSearchContext, CtxProvider] = createCtx<SearchContextProviderValue>();

export const SearchContextProvider = ({ children }: PropsWithChildren<{}>) => {
	const [searchContext, setSearchContext] = useState<SearchContextInterface>({});

	const addClickedArticleIds = useCallback((query: string, clickedArticleId: string) => {
		setSearchContext((prev) => ({
			...prev,
			[query]: {
				...prev[query],
				clickedArticleIds: prev[query]?.clickedArticleIds
					? [...prev[query].clickedArticleIds, clickedArticleId]
					: [clickedArticleId],
			},
		}));
	}, []);

	const addFetchedArticleIds = useCallback((query: string, fetchedArticles: string[]) => {
		setSearchContext((prev) => ({
			...prev,
			[query]: {
				...prev[query],
				fetchedArticleIds: fetchedArticles,
			},
		}));
	}, []);

	return (
		<CtxProvider
			value={{
				searchContext,
				addClickedArticleIds,
				addFetchedArticleIds,
			}}
		>
			{children}
		</CtxProvider>
	);
};
