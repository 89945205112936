import type { Route } from '@atlassian/jira-router';
import { servicedeskConnectProjectRouteEntry } from '@atlassian/jira-router-routes-connect-entries';
import { boardsRouteEntries } from '@atlassian/jira-router-routes-servicedesk-board-entries';
import { calendarRouteEntries } from '@atlassian/jira-router-routes-servicedesk-calendar-entries';
import { changeCalendarRouteEntries } from '@atlassian/jira-router-routes-servicedesk-change-calendar-entries';
import { insightRouteEntries } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries';
import { servicedeskCreateProjectRouteEntry } from '@atlassian/jira-router-routes-servicedesk-create-project-entries';
import { customersRouteEntries } from '@atlassian/jira-router-routes-servicedesk-customers-entries';
import { projectServiceDeskRouteEntry } from '@atlassian/jira-router-routes-servicedesk-default-entries';
import { gettingStartedPageRouteEntries } from '@atlassian/jira-router-routes-servicedesk-getting-started-page-entries';
import { servicedeskItsmSampleSpaceRouteEntry } from '@atlassian/jira-router-routes-servicedesk-itsm-sample-space-entries';
import { knowledgeHubRouteEntries } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-entries';
import { notificationLogsRouteEntry } from '@atlassian/jira-router-routes-servicedesk-notification-logs-entries';
import { oncallRouteEntries } from '@atlassian/jira-router-routes-servicedesk-oncall-entries';
import { organizationRouteEntries } from '@atlassian/jira-router-routes-servicedesk-organization-entries';
import { organizationsRouteEntries } from '@atlassian/jira-router-routes-servicedesk-organizations-entries';
import { queuesRouteEntries } from '@atlassian/jira-router-routes-servicedesk-queues-entries';
import { reportsRouteEntries } from '@atlassian/jira-router-routes-servicedesk-reports-entries';
import { servicesRouteEntries } from '@atlassian/jira-router-routes-servicedesk-services-entries';
import {
	serviceDeskServicesWithoutProjectRoute,
	serviceDeskServiceDetailsWithoutProjectRoute,
} from '@atlassian/jira-router-routes-servicedesk-services-routes';

export const servicedeskRouteEntries: Route[] = [
	...knowledgeHubRouteEntries,
	...reportsRouteEntries,
	...queuesRouteEntries,
	...boardsRouteEntries,
	...gettingStartedPageRouteEntries,
	...servicesRouteEntries,
	...customersRouteEntries,
	...organizationsRouteEntries,
	...organizationRouteEntries,
	...oncallRouteEntries,
	...changeCalendarRouteEntries,
	...calendarRouteEntries,
	...insightRouteEntries,
	notificationLogsRouteEntry,
	servicedeskConnectProjectRouteEntry,
	projectServiceDeskRouteEntry,
	servicedeskItsmSampleSpaceRouteEntry,
	servicedeskCreateProjectRouteEntry,
	serviceDeskServicesWithoutProjectRoute,
	serviceDeskServiceDetailsWithoutProjectRoute,
];
