import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type DevopsConnectionsSettingsType from '@atlassian/jira-spa-apps-project-settings-devops-connections';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyDevOpsConnectionsSettings = lazyForPaint<typeof DevopsConnectionsSettingsType>(
	() =>
		import(
			/* webpackChunkName: "async-project-settings-devops-connections", jiraSpaEntry: "async-project-settings-devops-connections" */ '@atlassian/jira-spa-apps-project-settings-devops-connections'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyDevOpsConnectionsSettings}
		pageId="devops-connections-settings"
		shouldShowSpinner={false}
	/>
);
