import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';
import type { Route } from '@atlassian/jira-router';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const gspPageRoute = {
	group: ROUTE_GROUPS.SERVICEDESK,
	name: ROUTE_NAMES.SERVICEDESK_GETTING_STARTED_PAGE,
	path: '/jira/servicedesk/projects/:projectKey/getting-started',
} as const as Route;
