import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type PlanSettingsExclusionRulesType from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-settings/exclusion-rules';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

/**
 * Plan Settings - exclusion-rules page
 */
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyPlanSettingsExclusionRules = lazyForPaint<typeof PlanSettingsExclusionRulesType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-plan-settings-plan-settings-exclusion-rules", jiraSpaEntry: "async-plan-settings-plan-settings-exclusion-rules" */ '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-settings/exclusion-rules'
			),
		),
	{ ssr: false },
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyPlanSettingsExclusionRules}
		pageId="plan-settings-exclusion-rules"
		shouldShowSpinner
	/>
);
