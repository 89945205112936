import React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import type { InterpolationValue } from 'styled-components';
import { graphql, useFragment } from 'react-relay';
import { Section } from '@atlaskit/side-navigation';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import type { starredFiltersList_navigationAppsSidebarCommon$key } from '@atlassian/jira-relay/src/__generated__/starredFiltersList_navigationAppsSidebarCommon.graphql';
// eslint-disable-next-line jira/import/no-restricted-paths
import { useFavouritesChangeEffect } from '../../../controllers/favourites-change-effect';
import { FilterItem } from '../filter-item';
import { messages } from './messages';

type Props = {
	baseHref: string;
	jiraQuery: starredFiltersList_navigationAppsSidebarCommon$key;
	sectionCustomCss?: InterpolationValue[];
	onFavouritesChanged: () => void;
};

export const StarredFiltersList = ({
	baseHref,
	jiraQuery,
	onFavouritesChanged,
	sectionCustomCss,
}: Props) => {
	const { formatMessage } = useIntl();

	const data = useFragment(
		graphql`
			fragment starredFiltersList_navigationAppsSidebarCommon on JiraQuery
			@argumentDefinitions(cloudId: { type: "ID!" }) {
				favouriteFilters(cloudId: $cloudId) {
					edges {
						node {
							filterId
							...filterItem_navigationAppsSidebarCommon
						}
					}
				}
			}
		`,
		jiraQuery,
	);

	useFavouritesChangeEffect('filters', (hasPendingChanges, previousHasPendingChanges) => {
		// refresh when transitioning from pending to NOT pending
		if (previousHasPendingChanges && !hasPendingChanges) {
			onFavouritesChanged();
		}
	});

	const starredFilterEdges = data.favouriteFilters?.edges || [];
	if (!starredFilterEdges.length) {
		return null;
	}

	return (
		<Section
			// TODO remove css prop once @atlaskit/section design gets updated
			// @ts-expect-error - TS2322: Index signature for type 'string' is missing in type 'StyledComponentClass<any, any, any>'.
			// eslint-disable-next-line @compiled/no-css-prop-without-css-function
			css={sectionCustomCss}
			title={formatMessage(
				ff('nin.global-scope_aqec8')
					? messages.newStarredFiltersHeader
					: messages.starredFiltersHeader,
			)}
		>
			{starredFilterEdges.map((edge) =>
				edge?.node ? (
					<FilterItem
						key={edge.node.filterId}
						baseHref={baseHref}
						filter={edge.node}
						customAnalytics={{ isStarred: true }}
					/>
				) : null,
			)}
		</Section>
	);
};
