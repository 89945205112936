import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import {
	NavSidebarPerformanceMark,
	NavSidebarPerformanceReport,
	NAVIGATION_SIDEBAR_MARKS,
} from '@atlassian/jira-atlassian-navigation-performance-metrics';
import {
	useExperienceStart,
	useExperienceSuccess,
	useExperienceFail,
} from '@atlassian/jira-experience-tracker';
import { createValueController } from '@atlassian/jira-platform-controllers-value';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useRouter } from '@atlassian/jira-router';
import { usePageId } from '@atlassian/jira-spa-state-controller';
import { SIDEBAR_EXPERIENCE } from '../../common/constants';
import type { SIDEBAR_ID } from './constants';

type SidebarId = (typeof SIDEBAR_ID)[keyof typeof SIDEBAR_ID];
type SidebarReadyProps = {
	id: SidebarId;
};

// @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
const { useValue: useSidebarId } = createValueController<SidebarId | undefined>('sidebar-id');

const EXPERIENCE_SUCCESS_ATTRIBUTES = {
	experience: SIDEBAR_EXPERIENCE,
	attributes: {
		sidebar: 'v3',
	},
} as const;

export const SidebarStart = () => {
	const [{ route, match }] = useRouter();
	const pageId = usePageId();
	const startRef = useRef(Date.now());
	const onStartExperience = useExperienceStart({
		experienceId: pageId,
		experience: SIDEBAR_EXPERIENCE,
		analyticsSource: 'Sidebar',
	});
	useMemo(() => {
		if (route.isRedirect ?? false) return;

		onStartExperience();
		startRef.current = Date.now();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [route, match]);

	return null;
};

export const SidebarRenderStart = () => (
	<NavSidebarPerformanceMark metricKey={NAVIGATION_SIDEBAR_MARKS.renderStart} />
);

export const SidebarReady = ({ id }: SidebarReadyProps) => {
	const [, setSidebarId] = useSidebarId();
	const route = useCurrentRoute();

	const routeName = route.name ?? route.group ?? 'UNKNOWN';
	const onSuccess = useExperienceSuccess(EXPERIENCE_SUCCESS_ATTRIBUTES);

	useEffect(() => {
		setSidebarId(id);
		// @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
		return () => setSidebarId();
	}, [setSidebarId, id]);

	useLayoutEffect(() => {
		if (route.isRedirect ?? false) return;
		onSuccess();
	}, [onSuccess, route]);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		// Why are we injecting the route name into the global window state?
		// We currently don't have any way to get the route name when the sidenav is rendered OUTSIDE of the SPA

		window.__POLLINATOR__ = { ...window.__POLLINATOR__, routeName };

		const event = createAnalyticsEvent({
			action: 'ready',
			actionSubject: 'sidebar',
			meta: { teamName: 'endeavour' },
		});

		fireUIAnalytics(event, 'Sidebar Ready', {
			route: routeName,
		});
	}, [createAnalyticsEvent, routeName]);

	const customData = useMemo(
		() => ({
			route: routeName,
			sidebarId: id,
		}),
		[routeName, id],
	);

	return (
		<>
			<NavSidebarPerformanceMark metricKey={NAVIGATION_SIDEBAR_MARKS.renderEnd} />
			<NavSidebarPerformanceReport customData={customData} />
		</>
	);
};

export const useSidebarFail = () => {
	const onFail = useExperienceFail({
		experience: SIDEBAR_EXPERIENCE,
	});

	return useCallback(
		(location: string, error: Error) => {
			onFail(location, error);
		},
		[onFail],
	);
};

export const useSidebarSuccess = () => useExperienceSuccess(EXPERIENCE_SUCCESS_ATTRIBUTES);
