/** Wrapper around <HeadingItem> adding custom theming
 * @see @atlaskit/side-navigation
 */
import React, { useMemo, type ReactNode } from 'react';
import { HeadingItem } from '@atlaskit/side-navigation';
// eslint-disable-next-line jira/import/no-restricted-paths
import { useSidebarTheme } from '../../../controllers';
import { generateCustomThemeCssFn } from './utils';

export type SectionHeaderProps = {
	children: ReactNode;
};

export const SectionHeader = ({ children }: SectionHeaderProps) => {
	const theme = useSidebarTheme();

	const cssFn = useMemo(() => generateCustomThemeCssFn(theme), [theme]);

	// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
	return <HeadingItem cssFn={cssFn}>{children}</HeadingItem>;
};
