import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const trashProjectsDirectoryRoute = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.TRASH_PROJECTS_DIRECTORY,
	path: '/secure/projects/trash',
	exact: true,
};

export const trashProjectsDirectory1Route = {
	group: ROUTE_GROUPS.GLOBAL_SETTINGS,
	name: ROUTE_NAMES.TRASH_PROJECTS_DIRECTORY,
	path: '/jira/settings/projects/trash',
	exact: true,
};
