import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ServicedeskKnowledgeHubBrowseAllArticlesType from '@atlassian/jira-spa-apps-servicedesk-knowledge-hub-browse-all-articles';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyServicedeskKnowledgeHubBrowseAllArticles = lazyForPaint<
	typeof ServicedeskKnowledgeHubBrowseAllArticlesType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-servicedesk-knowledge-hub-browse-all-articles", jiraSpaEntry: "async-servicedesk-knowledge-hub-browse-all-articles" */ '@atlassian/jira-spa-apps-servicedesk-knowledge-hub-browse-all-articles'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyServicedeskKnowledgeHubBrowseAllArticles}
		pageId="spa-apps/servicedesk/knowledge-hub/browse-all-articles"
		shouldShowSpinner
	/>
);
