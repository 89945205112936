import React, {
	useState,
	useContext,
	useMemo,
	createContext,
	type ReactNode,
	type Context,
	type ComponentType,
	useCallback,
} from 'react';
import noop from 'lodash/noop';
import { useRelayEnvironment, useMutation, graphql } from 'react-relay';
import { commitLocalUpdate, type RecordSourceSelectorProxy } from 'relay-runtime';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { createAri } from '@atlassian/jira-platform-ari';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { viewSetFavouriteMutation } from '@atlassian/jira-relay/src/__generated__/viewSetFavouriteMutation.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller';
import RelayDataID from '@atlassian/relay-data-id';
import {
	favouriteMutationToRestPayload,
	favouriteTypeToTypename,
} from '../common/utils/favourite-mutation-to-rest-payload';
import { publishFavourite } from '../common/utils/favourite-pub-sub';
import { PROJECTS_ITEM_TYPE } from '../model/constants';
import type {
	ChangeFavouritePayload,
	Item,
	Items,
	FavouriteChangeContextType,
	ChangeFavouriteMutationPayload,
	EmitChangedFavourite,
	EmitChangedFavouriteMutation,
} from '../model/types';
import updateFavourite from '../services/update-favourite';
import { errorFlag } from './flags/failure';

type State = FavouriteChangeContextType;

export type Props = {
	children: ReactNode;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onShowFlag?: any; // ShowFlagFn ('@atlaskit/flag')
};

export const updateItemFC = (item: Item) => (state: Items) => ({
	...state,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	[item.type as string]: {
		...state[item.type],
		[item.id]: item,
	},
});

export const updateItem = (item: Item) => (state: State) => ({
	...state,
	items: {
		...state.items,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		[item.type as string]: {
			...state.items[item.type],
			[item.id]: item,
		},
	},
});

export const defaultContextValue: FavouriteChangeContextType = {
	items: {
		boards: {},
		filters: {},
		issues: {},
		dashboards: {},
		projects: {},
		queues: {},
		plans: {},
	},
	changeFavourite: () => new Promise(noop),
	changeFavouriteMutation: noop,
	emitChangedFavourite: noop,
	emitChangedFavouriteMutation: noop,
	updateFavouriteState: noop,
};

export const FavouriteChangeContext: Context<FavouriteChangeContextType> =
	createContext(defaultContextValue);

// supports project types only for now
const useRelayFavouriteUpdate = () => {
	const environment = useRelayEnvironment();
	const cloudId = useCloudId();

	const commitUpdate = useCallback(
		({ id, type, value }: { id: string; type: string; value: boolean }) => {
			if (type === PROJECTS_ITEM_TYPE) {
				commitLocalUpdate(environment, (store) => {
					const ari = createAri({
						resourceOwner: 'jira',
						resourceType: 'project',
						resourceId: id,
						cloudId,
					});
					const projectRecordId = RelayDataID({ id: ari }, 'JiraProject');

					if (projectRecordId) {
						const projectRecord = store.get(projectRecordId);

						const favouriteRecord = projectRecord?.getLinkedRecord('favouriteValue');
						favouriteRecord?.setValue(value, 'isFavourite');
					}
				});
			}
		},
		[cloudId, environment],
	);

	return { commitUpdate };
};

export const FavouriteChangeProvider = ({ onShowFlag, children }: Props) => {
	const { commitUpdate } = useRelayFavouriteUpdate();
	const [items, setItems] = useState<Items>({
		boards: {},
		filters: {},
		dashboards: {},
		issues: {},
		projects: {},
		queues: {},
		plans: {},
	});

	const onChangeStart = useCallback((item: ChangeFavouritePayload) => {
		setItems(updateItemFC({ ...item, pending: true }));
	}, []);

	const onChangeSuccess = useCallback(
		(item: ChangeFavouritePayload, analyticsEvent?: UIAnalyticsEvent) => {
			setItems(updateItemFC({ ...item, pending: false }));
			if (analyticsEvent) {
				fireTrackAnalytics(
					analyticsEvent,
					// removing last letter to make item.type singular tense (i.e. boards => board)
					`${item.type.slice(0, item.type.length - 1)} ${item.value ? 'starred' : 'unstarred'}`,
					`${item.id}`,
				);
			}
		},
		[],
	);

	const onChangeFail = useCallback(
		(item: ChangeFavouritePayload, analyticsId: string, error?: Error) => {
			setItems(updateItemFC({ ...item, value: !item.value, pending: false }));

			if (onShowFlag) {
				onShowFlag(errorFlag());
			}

			fireErrorAnalytics({
				meta: {
					id: analyticsId,
					packageName: 'jiraFavouriteChangeProvider',
					teamName: 'empanada',
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});
		},
		[onShowFlag],
	);

	const changeFavourite = useCallback(
		async (item: ChangeFavouritePayload, analyticsEvent?: UIAnalyticsEvent) => {
			const performChange = async () => {
				onChangeStart(item);

				try {
					commitUpdate({ type: item.type, id: item.id, value: item.value });
					await updateFavourite(item);
					const typename = favouriteTypeToTypename(item.type, item.id);
					if (typename) {
						publishFavourite(typename, {
							type: 'REST',
							entityId: item.id,
							isFavourite: item.value,
						});
					}

					onChangeSuccess(item, analyticsEvent);
				} catch (error: unknown) {
					commitUpdate({ type: item.type, id: item.id, value: !item.value });
					onChangeFail(item, 'changeFavourite', error instanceof Error ? error : undefined);
				}
			};

			return performChange();
		},
		[commitUpdate, onChangeStart, onChangeSuccess, onChangeFail],
	);

	const [commitMutation] = useMutation<viewSetFavouriteMutation>(graphql`
		mutation viewSetFavouriteMutation($input: JiraSetIsFavouriteInput!) {
			jira {
				setEntityIsFavourite(input: $input) {
					success
					favouriteValue {
						id
						isFavourite
					}
				}
			}
		}
	`);

	const changeFavouriteMutation = useCallback(
		(payload: ChangeFavouriteMutationPayload, analyticsEvent?: UIAnalyticsEvent) => {
			const dataId = RelayDataID({ id: payload.id }, payload.typename);
			const restItem = favouriteMutationToRestPayload(payload);

			const onUpdate = (store: RecordSourceSelectorProxy, isOptimistic: boolean) => {
				if (!dataId) {
					return;
				}

				publishFavourite(payload.typename, {
					type: 'MUTATION',
					store,
					dataId,
					entityId: payload.id,
					isFavourite: payload.value,
					isOptimistic,
				});

				const record = store.get(dataId);
				if (record) {
					// Some favouritable entities provide an `isFavourite` field, e.g. `JiraFilter`, `JiraProject`.
					if (typeof record.getValue('isFavourite') === 'boolean') {
						record.setValue(payload.value, 'isFavourite');
					}
					// Optimistically update the `favouriteValue` node associated with the updated entity (if present).
					// We only need to do this during optimistic update as the node will be returned in a successful
					// mutation response.
					if (isOptimistic) {
						const favouriteRecord = record.getLinkedRecord('favouriteValue');
						favouriteRecord?.setValue(payload.value, 'isFavourite');
					}
				}
			};

			onChangeStart(restItem);

			commitMutation({
				variables: {
					input: {
						entityId: payload.id,
						isFavourite: payload.value,
						beforeEntityId: payload.beforeEntity?.id ?? null,
					},
				},
				optimisticUpdater: (store: RecordSourceSelectorProxy) => {
					onUpdate(store, true);
				},
				updater: (store: RecordSourceSelectorProxy, response) => {
					if (response?.jira?.setEntityIsFavourite?.success) {
						onUpdate(store, false);
					}
				},
				onCompleted: (response) => {
					if (response?.jira?.setEntityIsFavourite?.success) {
						onChangeSuccess(restItem, analyticsEvent);
					} else {
						onChangeFail(restItem, 'changeFavouriteMutation');
					}
				},
				onError: (error: Error) => onChangeFail(restItem, 'changeFavouriteMutation', error),
			});
		},
		[commitMutation, onChangeStart, onChangeSuccess, onChangeFail],
	);

	const updateFavouriteState = useCallback((item: ChangeFavouritePayload) => {
		setItems(updateItemFC({ ...item, pending: false }));
	}, []);

	const emitChangedFavourite = useCallback<EmitChangedFavourite>(
		(payload: ChangeFavouritePayload, analyticsEvent?: UIAnalyticsEvent) => {
			onChangeSuccess(payload, analyticsEvent);

			const typeName = favouriteTypeToTypename(payload.type, payload.id);

			if (typeName) {
				publishFavourite(typeName, {
					type: 'REST',
					entityId: payload.id,
					isFavourite: payload.value,
				});
			}
		},
		[onChangeSuccess],
	);

	const emitChangedFavouriteMutation = useCallback<EmitChangedFavouriteMutation>(
		(payload, store, analyticsEvent?: UIAnalyticsEvent) => {
			const dataId = RelayDataID({ id: payload.id }, payload.typename) ?? '';
			const restItem = favouriteMutationToRestPayload(payload);

			onChangeSuccess(restItem, analyticsEvent);

			publishFavourite(payload.typename, {
				type: 'MUTATION',
				store,
				dataId,
				entityId: payload.id,
				isFavourite: payload.value,
				isOptimistic: false,
			});
		},
		[onChangeSuccess],
	);

	const providerValue = useMemo(
		() => ({
			items,
			changeFavourite,
			changeFavouriteMutation,
			emitChangedFavourite,
			emitChangedFavouriteMutation,
			updateFavouriteState,
		}),
		[
			items,
			changeFavourite,
			changeFavouriteMutation,
			emitChangedFavourite,
			emitChangedFavouriteMutation,
			updateFavouriteState,
		],
	);

	return (
		<FavouriteChangeContext.Provider value={providerValue}>
			{children}
		</FavouriteChangeContext.Provider>
	);
};

export const FavouriteChangeConsumer = FavouriteChangeContext.Consumer;

export const withFavouriteChangeConsumer = <
	P,
	W extends P & {
		favouriteChangeContext: FavouriteChangeContextType;
	},
>(
	WrappedComponent: ComponentType<W>,
): ComponentType<P> => {
	const WithFavouriteChangeConsumer = (props: P) => (
		<FavouriteChangeConsumer>
			{(favouriteChangeContext) => (
				// @ts-expect-error - TS2322 - Type 'P & { favouriteChangeContext: FavouriteChangeContextType; }' is not assignable to type 'IntrinsicAttributes & W & { children?: ReactNode; }'.
				<WrappedComponent {...props} favouriteChangeContext={favouriteChangeContext} />
			)}
		</FavouriteChangeConsumer>
	);

	const wrappedDisplayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
	WithFavouriteChangeConsumer.displayName = `WithFavouriteChangeConsumer(${wrappedDisplayName})`;
	return WithFavouriteChangeConsumer;
};

export const useChangeFavourite = () => useContext(FavouriteChangeContext);
