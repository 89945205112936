import type { Action } from 'react-sweet-state';
import getData from './get-data';
import type { State, Props } from './types';
/**
 * Initiates data retrieval for a user preference, ensuring no duplicate requests are made.
 * Updates the application state with retrieved data or an error, accordingly.
 */
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (preferenceKey: string): Action<State, Props> =>
	async ({ setState, getState }, { baseUrl }) => {
		const currentState = getState();
		// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Readonly<State>'. | TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Readonly<State>'.
		if (currentState[preferenceKey] && currentState[preferenceKey].loading) return;
		setState({
			[preferenceKey]: {
				data: currentState.data,
				loading: true,
				error: null,
			},
		});
		try {
			const data = await getData({
				baseUrl,
				preferenceKey,
			});
			setState({
				[preferenceKey]: {
					data,
					loading: false,
					error: null,
				},
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			setState({
				[preferenceKey]: {
					data: getState().data,
					loading: false,
					error: err,
				},
			});
		}
	};
