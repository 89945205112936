import type { ElementType } from 'react';
import AppsIcon from '@atlaskit/icon/core/apps';
import ClockIcon from '@atlaskit/icon/core/clock';
import DashboardIcon from '@atlaskit/icon/core/dashboard';
import FilterIcon from '@atlaskit/icon/core/filter';
import GoalIcon from '@atlaskit/icon/core/goal';
import ProjectIcon from '@atlaskit/icon/core/project';
import RoadmapIcon from '@atlaskit/icon/core/roadmap';
import ShieldIcon from '@atlaskit/icon/core/shield';
import StarUnstarredIcon from '@atlaskit/icon/core/star-unstarred';
import TeamsIcon from '@atlaskit/icon/core/teams';
import JSMAssetsIcon from '@atlassian/jira-icons/src/ui/jsm-assets';
import type { MessageDescriptor } from '@atlassian/jira-intl';
import { CONFIGURABLE_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants';
import messages from './messages';
import { type ConfigurableMenuId, MENU_ID } from './types';

export const PACKAGE_NAME = 'jiraNavigationAppsSidebarNav4Common';
export const TEAM_NAME = 'jira-cosmos';

export const CONFIGURABLE_MENU_IDS = Object.values(CONFIGURABLE_MENU_ID);
export const CONFIGURABLE_MENU_IDS_AS_STRINGS: string[] = CONFIGURABLE_MENU_IDS;

export const CONFIGURABLE_MESSAGE_AND_ICON_MAP: {
	[K in ConfigurableMenuId]: { message: MessageDescriptor; Icon: ElementType };
} = {
	[MENU_ID.APPS]: { message: messages.apps, Icon: AppsIcon },
	[MENU_ID.GOALS]: { message: messages.goals, Icon: GoalIcon },
	[MENU_ID.PLANS]: { message: messages.plans, Icon: RoadmapIcon },
	[MENU_ID.PROJECTS]: { message: messages.projects, Icon: ProjectIcon },
	[MENU_ID.FILTERS]: { message: messages.filters, Icon: FilterIcon },
	[MENU_ID.DASHBOARDS]: { message: messages.dashboards, Icon: DashboardIcon },
	[MENU_ID.OPERATIONS]: { message: messages.operations, Icon: ShieldIcon },
	[MENU_ID.ASSETS]: { message: messages.assets, Icon: JSMAssetsIcon },
	[MENU_ID.TEAMS]: { message: messages.teams, Icon: TeamsIcon },
	[MENU_ID.RECENT]: { message: messages.recent, Icon: ClockIcon },
	[MENU_ID.STARRED]: { message: messages.starred, Icon: StarUnstarredIcon },
};

/**
 * To be used in examples, for example
 */
export const LEFT_SIDEBAR_EXPANDED_MIN_WIDTH = 240; // in pixels
export const LEFT_SIDEBAR_EXPANDED_DEFAULT_WIDTH = 320; // in pixels
export const TMP = 'TEAM_MANAGED_PROJECT';
export const CMP = 'COMPANY_MANAGED_PROJECT';

export const NAV4_OPT_OUT_FEEDBACK_GROUP_ID = 'nav4-opt-out';
export const NAV4_GENERAL_FEEDBACK_GROUP_ID = 'nav4-sidebar';
