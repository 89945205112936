import { createRelayResource } from '@atlassian/jira-relay-utils';
import QUERY, {
	type mainJiraEditPermissionSchemeAndGetPermissionSchemeByIdQuery,
} from '@atlassian/jira-relay/src/__generated__/mainJiraEditPermissionSchemeAndGetPermissionSchemeByIdQuery.graphql';

const RESOURCE_TYPE_EDIT_PERMISSION_SCHEME_PAGE = 'EDIT_PERMISSION_SCHEME_PAGE';

export const editPermissionSchemePageResource =
	createRelayResource<mainJiraEditPermissionSchemeAndGetPermissionSchemeByIdQuery>({
		type: RESOURCE_TYPE_EDIT_PERMISSION_SCHEME_PAGE,
		getQuery: ({ match }, { tenantContext }) => ({
			parameters: QUERY,
			variables: {
				// To Do: use ari-typescript library once it is approved
				// https://hello.atlassian.net/wiki/spaces/TGR/pages/1374189554/Dependency+review+atlassian+ari
				schemeId: `ari:cloud:jira:${tenantContext.cloudId}:permission-scheme/activation/${
					tenantContext.activationId
				}/${String(match.params.schemeId)}`,
				first: 50,
			},
			options: { fetchPolicy: 'network-only' },
		}),
	});
