import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { ROUTE_GROUPS } from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context';
import { servicedeskIssueTypesResource } from '@atlassian/jira-router-resources-service-desk-issue-types';
import { servicedeskPortalGroupsResource } from '@atlassian/jira-router-resources-service-desk-portal-groups';
import { servicedeskPracticesResource } from '@atlassian/jira-router-resources-service-desk-practices';
import {
	routeGroupsProjectSettingsServicedeskUnassignedTicketTypesConfigRoute,
	routeGroupsProjectSettingsServicedeskItsmTicketTypesConfigRoute,
	routeGroupsProjectSettingsServicedeskTicketTypesConfigRoute,
} from '@atlassian/jira-router-routes-project-settings-service-desk-ticket-types-config-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import { APP_NAMES, toPackageName } from '@atlassian/jira-servicedesk-common/src/utils/app-names';
import { getSettingsNavigationResources, SERVICE_DESK_PRODUCT } from './ui/product-routes/common';
import ServiceDeskTicketTypesConfig, {
	LazyProjectSettingsServiceDeskTicketTypesConfig,
} from './ui/service-desk-ticket-types-config';

const commonTicketTypesConfigRouteEntry: Partial<Route> = {
	exact: true,
	group: ROUTE_GROUPS.PROJECT_SETTINGS_SERVICEDESK,
	component: ServiceDeskTicketTypesConfig,
	layout: serviceProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
	},
	resources: [
		...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
		projectContextResource,
		servicedeskPracticesResource,
		servicedeskIssueTypesResource,
		servicedeskPortalGroupsResource,
		getConsolidationStateResource(),
	],
	forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskTicketTypesConfig],
	meta: {
		reporting: {
			id: APP_NAMES.REQUEST_TYPES_CONFIG,
			packageName: toPackageName(APP_NAMES.REQUEST_TYPES_CONFIG),
			teamName: 'jsd-shield',
		},
	},
	perfMetricKey: 'jsm-request-types-configuration',
	ufoName: 'jsm-request-types-configuration',
};

export const ticketTypesConfigRouteEntries: Route[] = [
	createEntry(routeGroupsProjectSettingsServicedeskUnassignedTicketTypesConfigRoute, {
		...commonTicketTypesConfigRouteEntry,
		component: ServiceDeskTicketTypesConfig,
	}),
	createEntry(routeGroupsProjectSettingsServicedeskItsmTicketTypesConfigRoute, {
		...commonTicketTypesConfigRouteEntry,
	}),
	createEntry(routeGroupsProjectSettingsServicedeskTicketTypesConfigRoute, {
		...commonTicketTypesConfigRouteEntry,
	}),
];
