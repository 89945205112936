import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type JiraBusinessFormSubmitEmbedAppType from '@atlassian/jira-spa-apps-business-form-submit-embed';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyFormSubmitEmbedView = lazyForPaint<typeof JiraBusinessFormSubmitEmbedAppType>(
	() =>
		import(
			/* webpackChunkName: "async-business-form-submit-embed", jiraSpaEntry: "async-business-form-submit-embed" */ '@atlassian/jira-spa-apps-business-form-submit-embed'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyFormSubmitEmbedView}
		pageId="async-business-form-submit-embed"
		shouldShowSpinner
	/>
);
