import { ROUTE_NAMES } from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const servicedeskOrganizationsRoute = {
	name: ROUTE_NAMES.SERVICEDESK_ORGANIZATIONS,
	path: '/jira/servicedesk/projects/:projectKey/organizations',
};

export const servicedeskOrganizationDetailsRoute = {
	name: ROUTE_NAMES.SERVICEDESK_ORGANIZATION_DETAILS,
	path: '/jira/servicedesk/projects/:projectKey/organizations/:organizationId',
};
