import { createRelayResource } from '@atlassian/jira-relay-utils';
import QUERY, {
	type uiFieldsPageQuery,
} from '@atlassian/jira-relay/src/__generated__/uiFieldsPageQuery.graphql';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils';

const RESOURCE_TYPE_PROJECT_SETTINGS_FIELDS = 'PROJECT_SETTINGS_FIELDS';

export const fieldSettingsRelayResource = createRelayResource<uiFieldsPageQuery>({
	type: RESOURCE_TYPE_PROJECT_SETTINGS_FIELDS,
	getQuery: ({ match, query }, { tenantContext }) => {
		const { projectKey } = getProjectKeyId(match, query);
		return {
			parameters: QUERY,
			variables: {
				projectKey: projectKey ?? '',
				cloudId: tenantContext.cloudId ?? '',
			},
			options: { fetchPolicy: 'network-only' },
		};
	},
});
