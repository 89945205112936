import React from 'react';
import { Flex, Box, Grid, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

export const Skeleton = () => (
	<>
		<Flex gap="space.100" alignItems="center">
			<Box xcss={backButton} />
			<Box xcss={headingSkeleton} />
		</Flex>

		<Grid
			columnGap="space.100"
			rowGap="space.050"
			alignItems="center"
			templateAreas={['avatar name', 'avatar type']}
			templateColumns="min-content 1fr"
			xcss={projectHeading}
		>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, jira/react/no-style-attribute -- Ignored via go/DSP-18766 */}
			<Box xcss={projectAvatar} style={{ gridArea: 'avatar' }} />
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, jira/react/no-style-attribute -- Ignored via go/DSP-18766 */}
			<Box xcss={projectName} style={{ gridArea: 'name' }} />
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, jira/react/no-style-attribute -- Ignored via go/DSP-18766 */}
			<Box xcss={projectType} style={{ gridArea: 'type' }} />
		</Grid>

		<Stack space="space.100" grow="hug">
			<Box xcss={menuItem} />
			<Box xcss={menuItem} />
			<Box xcss={menuItem} />

			<Box xcss={menuItem} />
			<Box xcss={menuItem} />
			<Box xcss={menuItem} />
			<Box xcss={menuItem} />
		</Stack>
	</>
);

const backButton = xcss({
	backgroundColor: 'color.skeleton',
	width: token('space.300'),
	height: token('space.300'),
	borderRadius: 'border.radius.100',
});

const headingSkeleton = xcss({
	backgroundColor: 'color.skeleton',
	height: token('space.300'),
	borderRadius: 'border.radius.100',
	flex: 1,
});

const projectHeading = xcss({
	marginTop: 'space.100',
	marginBottom: 'space.100',
});

const projectAvatar = xcss({
	backgroundColor: 'color.skeleton',
	width: token('space.300'),
	height: token('space.300'),
	borderRadius: 'border.radius.100',
});

const projectName = xcss({
	backgroundColor: 'color.skeleton',
	height: token('space.200'),
	borderRadius: 'border.radius.100',
});

const projectType = xcss({
	backgroundColor: 'color.skeleton',
	height: token('space.100'),
	borderRadius: 'border.radius.100',
});

const menuItem = xcss({
	backgroundColor: 'color.skeleton',
	height: token('space.400'),
	borderRadius: 'border.radius.100',
});
