import log from '@atlassian/jira-common-util-logging/src/log';
// eslint-disable-next-line jira/restricted/@atlassian/jira-router
import { createResource } from '@atlassian/jira-router';
import { getBoardId } from '@atlassian/jira-router-resources-utils';
import {
	fetchUserBoardNavigationData,
	type JiraUserBoardNavigationData,
} from '../../../services/sidebar/jsw-userboard-sidebar-navigation';

export const staticJSWUserBoardSidebarNavigationResource =
	createResource<JiraUserBoardNavigationData | null>({
		type: 'JIRA_USER_BOARD_NAVIGATION_RESOURCE',
		getKey: ({ match, query }) => {
			const boardId = getBoardId(match, query);
			return boardId ? `board-${boardId}` : 'none';
		},
		getData: async ({ match, query }) => {
			const boardId = getBoardId(match, query);
			if (boardId == null) {
				return null;
			}

			try {
				const response = await fetchUserBoardNavigationData(boardId);
				return response;
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (e: any) {
				log.safeErrorWithoutCustomerData(
					'navigation-apps.resources.static-jsw-userboard-sidebar',
					'Fail to fetch user board',
					e,
				);
				return null;
			}
		},
		maxAge: 5 * 60 * 1000,
	});
