import { createResource, useResource } from '@atlassian/jira-router';
import { RESOURCE_TYPE_CONNECT_GENERAL } from './constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const connectGeneralResources = createResource<any>({
	type: RESOURCE_TYPE_CONNECT_GENERAL,
	getKey: ({
		match: {
			params: { addonKey, moduleKey },
		},
	}) => {
		// spa-router/src/common/types.js:
		/** TODO we are supporting `undefined` here because we are currently using both
		 * this version of the `Match` type, and react-routers version (which allows for `undefined`)
		 * To fix this we should move `matchPath` to our own util so we can apply our own types, then
		 * decide if we want to support undefined types.
		 */
		if (
			addonKey !== null &&
			addonKey !== undefined &&
			moduleKey !== null &&
			moduleKey !== undefined
		) {
			return `${addonKey}-${moduleKey}`;
		}
		return 'state';
	},
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-connect-general", jiraSpaEntry: "async-resource-connect-general" */ './controllers/get-data'
		),
});

export const useConnectGeneralResource = () => useResource(connectGeneralResources);
