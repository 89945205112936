import React, { memo } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-nav4-common/src/ui/sidebar-boundary';
import { SidebarContainer } from '@atlassian/jira-navigation-apps-sidebar-nav4-common/src/ui/sidebar-container';
import { SidebarContent } from '@atlassian/jira-navigation-apps-sidebar-nav4-global-settings-common/src/ui/sidebar-content';
import { Skeleton } from '@atlassian/jira-navigation-apps-sidebar-nav4-global-settings-common/src/ui/skeleton';
import Placeholder from '@atlassian/jira-placeholder';

const LazySidebarContent = lazyForPaint(
	() =>
		import(
			/* webpackChunkName: "async-jira-navigation-apps-sidebar-nav4-sidebar-global-settings-products-settings" */ './main'
		),
);

const AsyncSidebar = () => (
	<SidebarBoundary
		id="nav4.sidebar.global-settings.products-settings"
		packageName="jiraNavigationAppsSidebarNav4GlobalSettingsProductsSettings"
	>
		<SidebarContainer>
			<SidebarContent currentPageName="products">
				<Placeholder name="nav4.sidebar.global-settings.products-settings" fallback={<Skeleton />}>
					<LazySidebarContent />
				</Placeholder>
			</SidebarContent>
		</SidebarContainer>
	</SidebarBoundary>
);

export default memo(AsyncSidebar);
