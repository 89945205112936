import React, { useEffect, useRef, useState } from 'react';
import { getUserProperty } from '@atlassian/jira-common-rest/src/api/latest/user-properties';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import { LazyHelpPanel, HELP_PANEL_ID } from '@atlassian/jira-help-panel';
import {
	useHelpPanel,
	RemoteStateUpdater,
	HELP_PANEL_UI_STORAGE_KEY,
} from '@atlassian/jira-help-panel-services';
import {
	useActiveRightSidebarState,
	useRightSidebarController,
} from '@atlassian/jira-layout-controller';
import { useAccountId } from '@atlassian/jira-tenant-context-controller';

const HelpPanel = () => {
	const [
		{ navigationData, releaseNotesNotifications },
		{ setNavigationData, setReleaseNotesNotifications },
	] = useHelpPanel();
	const { closeRightSidebar } = useRightSidebarController();
	const rightSidebarCurrentState = useActiveRightSidebarState();
	const accountId = useAccountId();

	useEffect(() => {
		const restoreReleaseNotesNotifications = async () => {
			if (accountId) {
				try {
					// @ts-expect-error - Property 'helpPanel' does not exist on type 'unknown'.
					const { helpPanel: userPropertiesHelpPanelValue } = await getUserProperty(
						accountId,
						HELP_PANEL_UI_STORAGE_KEY,
					);
					setReleaseNotesNotifications(userPropertiesHelpPanelValue.releaseNotesNotifications);
				} catch (err) {
					/* getUserProperty throws if not found */
					setReleaseNotesNotifications(0);
				}
			} else {
				setReleaseNotesNotifications(0);
			}
		};

		restoreReleaseNotesNotifications();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<RemoteStateUpdater />
			<LazyHelpPanel
				isOpen={!rightSidebarCurrentState?.isCollapsed}
				navigationData={navigationData}
				releaseNotesNotifications={releaseNotesNotifications}
				setNavigationData={setNavigationData}
				releaseNotesNotificationsSetter={setReleaseNotesNotifications}
				hasBeenRendered={!rightSidebarCurrentState?.isCollapsed}
				onCloseHelpPanel={closeRightSidebar}
			/>
		</>
	);
};

const HelpPanelInLayout = () => {
	const rightSidebarCurrentState = useActiveRightSidebarState();
	const [localPanelId, setLocalPanelId] = useState<unknown>();
	const prevPanelIdRef = useRef<unknown>();
	const delayRef = useRef<unknown>(null);

	const panelId = rightSidebarCurrentState?.panelId;

	/**
	 * This use effect is used to add (when is necessary) a delay
	 * in the panelId => localPanelId syncing
	 */
	useEffect(() => {
		// @ts-expect-error - TS2769 - No overload matches this call.
		clearTimeout(delayRef.current);

		if (prevPanelIdRef.current === HELP_PANEL_ID) {
			/**
			 * The right-sidebar is showing the help-panel...
			 */
			if (
				rightSidebarCurrentState &&
				panelId !== undefined &&
				!rightSidebarCurrentState.isCollapsed &&
				!rightSidebarCurrentState.isMinimised
			) {
				/**
				 * and is open, not collapsed and we want to show a panel
				 * with an ID !== HELP_PANEL_ID
				 */
				setLocalPanelId(panelId);
			} else {
				/**
				 * and we want to close it
				 */
				delayRef.current = setTimeout(() => {
					setLocalPanelId(panelId);
				}, 310);
			}
		} else {
			/**
			 * If the right sidebar is not showing the help-panel,
			 * we just keep localPanelId in sync with panelId
			 */
			setLocalPanelId(panelId);
		}

		prevPanelIdRef.current = panelId;

		// @ts-expect-error - TS2769 - No overload matches this call.
		return () => clearTimeout(delayRef.current);
	}, [panelId, rightSidebarCurrentState]);

	if (localPanelId !== HELP_PANEL_ID) return null;

	return <HelpPanel />;
};

const SafeHelpPanel = () => (
	<JSErrorBoundary
		fallback="unmount"
		id="right-side-help-panel"
		packageName="@atlassian/jira-help-panel-right-sidebar"
	>
		<HelpPanelInLayout />
	</JSErrorBoundary>
);

export { SafeHelpPanel };
